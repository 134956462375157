<template>
    <Top :fixedNobanner="true"></Top>
    <div class="no_banner" id="no_banner"></div>
    <div class="container mar_b">
        <router-view/>
    </div>
    <Bottom></Bottom>
</template>
<script setup lang="ts">
</script>
<style lang="scss" scoped>
</style>
