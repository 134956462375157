<template>
  <div class="select-container">
    <span class="label">{{ title }}</span>
    <el-select
        class="select"
        v-model="activeDeviceId"
        :placeholder="deviceType"
        @change="handleChange">
      <el-option
          v-for="item in deviceList"
          :key="item.deviceId"
          :label="item.label"
          :value="item.deviceId">
      </el-option>
    </el-select>
  </div>
</template>

<script>
import TRTC from 'trtc-sdk-v5';
export default {
  name: 'compDeviceSelect',
  props: {
    deviceType: {
      type: String,
    },
    title: String,
  },
  data() {
    return {
      deviceList: [],
      activeDevice: {},
      activeDeviceId: '',
    };
  },
  methods: {
    async getDeviceList() {
      switch (this.deviceType) {
        case 'camera':
          this.deviceList = await TRTC.getCameraList();

          break;
        case 'microphone':
          this.deviceList = await TRTC.getMicrophoneList();
          break;
        case 'speaker':
          this.deviceList = await TRTC.getSpeakerList();
          break;
        default:
          break;
      }
      [this.activeDevice] = this.deviceList;
      this.activeDeviceId = this.deviceList[0].deviceId;
      this.$emit('change', this.activeDeviceId);
      console.log(this.deviceList)
    },
    handleChange() {
      this.$emit('change', this.activeDeviceId);
    },
  },
  mounted() {
    navigator.mediaDevices.getUserMedia({ audio: true, video: true }).then((stream) => {
      stream.getTracks().forEach(track => track.stop());
      this.getDeviceList();
    });
    navigator.mediaDevices.addEventListener('devicechange', this.getDeviceList);
  },
  beforeDestroy() {
    navigator.mediaDevices.removeEventListener('devicechange', this.getDeviceList);
  },
};
</script>

<style scoped>
.select-container {
  display: flex;

}
.select-container .label {
  display: inline-block;
  padding: 0 20px;
  width: 120px;
  height: 40px;
  text-align: left;
  line-height: 40px;
  border-top: 1px solid #DCDFE6;
  border-left: 1px solid #DCDFE6;
  border-bottom: 1px solid #DCDFE6;
  border-radius: 4px 0 0 4px;
  color: #909399;
  background-color: #F5F7FA;
  font-weight: bold;
}
.select-container .select {
  flex-grow: 1;
}
.select input {
  border-radius: 0 4px 4px 0 !important;
  /*height: 40px !important;*/
}
/deep/ .el-input__inner {
  height: 38px !important;
}
/*/deep/.ant-select-selection--single {
  height: 40px !important;
}
/deep/.ant-select-selection__rendered {
  line-height: 40px !important;
}*/
</style>
