<template>
    <div class="t2-banner t6_sub_banner zjzmq_banner">
        <div class="container">
            <div class="t2_banner_name">
                <h1>走进自贸区</h1>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
</script>
<style lang="scss" scoped>
</style>
