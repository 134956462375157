<template>
  <div class="rtc-container">
    <p v-if="isHostMode" class="label">操作</p>
    <div class="control-container">
      <div class="rtc-control-container">
        <el-button
          class="button"
          type="primary"
          :loading="roomStatus === 'entering'"
          :disabled="roomStatus === 'entered'"
          @click="handleEnterRoom">进入房间</el-button>
        <el-button
          class="button"
          type="primary"
          :loading="roomStatus === 'exiting'"
          @click="handleExit">离开房间</el-button>
      </div>
      <div class="rtc-control-container">
        <el-button
          v-if="isHostMode"
          class="button"
          type="primary"
          :loading="micStatus === 'starting'"
          :disabled="micStatus === 'started'"
          @click="handleStartLocalAudio">开启麦克风
        </el-button>
        <el-button
          v-if="isHostMode"
          class="button"
          type="primary"
          :loading="camStatus === 'starting'"
          :disabled="camStatus === 'started'"
          @click="handleStartLocalVideo">开启摄像头
        </el-button>
        <el-button
          v-if="isHostMode"
          class="button"
          :loading="micStatus === 'stopping'"
          type="danger" @click="handleStopLocalAudio">关闭麦克风
        </el-button>
        <el-button
          v-if="isHostMode"
          class="button"
          :loading="camStatus === 'stopping'"
          type="danger" @click="handleStopLocalVideo">关闭摄像头
        </el-button>
      </div>
    </div>


    <div class="info-container">

      <div v-show="false" class="log-container" ref="logContainer">
        <p class="log-label">Log:</p>
        <div v-for="(item, index) in logList" :key="index">
          <span class="log-state" v-if="item.type === 'success'">🟩 </span>
          <span class="log-state" v-if="item.type === 'failed'">🟥 </span>
          <span>{{ item.log }}</span>
        </div>
      </div>
    <div style="display: flex">
      <div  v-show="camStatus === 'started'" style="display: inline-block;position: relative" class="local-stream-container">
        <div id="local" class="local-stream-content"></div>
        <div v-show="camStatus === 'started'" class="local-stream-control">.
          <div class="video-control control">
            <span v-if="!isMutedVideo" @click="muteVideo">
              <img style="width: 25px;height: 25px" :src="require('@/assets/icons/svg/video.svg')">

            </span>
            <span v-if="isMutedVideo"  @click="unmuteVideo">
              <img style="width: 25px;height: 25px"  :src="require('@/assets/icons/svg/video-muted.svg')">

            </span>
          </div>
          <div class="audio-control control">
            <span v-if="!isMutedAudio" @click="muteAudio">
              <img style="width: 25px;height: 25px"  :src="require('@/assets/icons/svg/audio.svg')">

            </span>
            <span v-if="isMutedAudio" @click="unmuteAudio">
              <img style="width: 25px;height: 25px"  :src="require('@/assets/icons/svg/audio-muted.svg')">

            </span>
          </div>
        </div>
      </div>
    </div>
      <div id="remote-container_id" style="display: inline-block" class="remote-container">
        <div
          v-for="(item) in remoteUsersViews"
          :key="item"
          :id="item"
          class="remote-stream-container">
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import rtc from '@/utils/audio/rtc.js';
import TRTC from 'trtc-sdk-v5';

export default {
  name: 'compRoom',
  mixins: [rtc],
  props: {
    type: String,
    sdkAppId: Number,
    sdkSecretKey: String,
    userId: String,
    roomId: Number,
    cameraId: String,
    microphoneId: String,
    inviteUserSig: String,
  },
  data() {
    return {
      logList: [],
      inviteLink: '',
      showCopiedTip: false,
    };
  },
  mounted() {
    this.trtc = TRTC.create();
  },
  computed: {
    isHostMode() {
      return this.type !== 'invite';
    },
    isEnLang() {
      // return this.$i18n.locale === 'en';
    },
    showInviteLink() {
      return this.isHostMode && this.roomStatus === 'entered' && this.inviteLink;
    },
  },
  watch: {
    cameraId(val) {
      this.switchDevice('video', val);
    },
    microphoneId(val) {
      this.switchDevice('audio', val);
    },
  },
  methods: {
    getVideo(){
      // 检测您当前的浏览器是否支持从 video 元素采集
      if (!HTMLAudioElement.prototype.captureStream) {
        console.log('your browser does not support capturing stream from audio element');
        return
      }
// 获取您页面在播放的 audio 标签
      const audio = document.getElementById('local');
      console.log(audio)
// 从播放的音频采集音频流
      const stream = audio.captureStream();
      const audioTrack = stream.getAudioTracks()[0];

      this.trtc.startLocalAudio({ option:{ audioTrack } });
    },
    generateInviteLink() {
      if (!this.isHostMode) {
        return;
      }
      const { sdkAppId, sdkSecretKey,inviteUserSig,userId, roomId } = this;
      // const inviteUserId = '1'
/*       const inviteUserId = `user_${parseInt(Math.random() * 100000000, 10)}`;
      const userSigGenerator = new LibGenerateTestUserSig(sdkAppId, sdkSecretKey, 604800);
      const inviteUserSig = userSigGenerator.genTestUserSig(inviteUserId); */

      this.inviteLink = encodeURI(`${location.origin}${location.pathname}#/invite?sdkAppId=${sdkAppId}&userSig=${inviteUserSig}&roomId=${roomId}&userId=${userId}`);
    },
    handleCopyInviteLink() {
      navigator.clipboard.writeText(this.inviteLink);
      this.showCopiedTip = true;
      setTimeout(() => {
        this.showCopiedTip = false;
      }, 1500);
      this.generateInviteLink();
    },
    async handleEnterRoom() {
      if (this.isHostMode) {
        console.log(this.inviteUserSig)
        if (!this.inviteUserSig) {
          this.$message.warning("")
          return;
        }
        if (!this.userId || !this.roomId) {
          this.$message.warning("请输入 userId 和 roomId")
          return;
        }
        this.userSig = this.inviteUserSig
      } else {
        if (!this.sdkAppId || !this.inviteUserSig || !this.userId || !this.roomId) {
          alert("请重新获取邀请链接");
          return;
        }
        this.userSig = this.inviteUserSig;
      }
      await this.enterRoom();
      this.handleStartLocalAudio();
      this.handleStartLocalVideo();
      this.generateInviteLink();
    },

    async handleExit() {
      await this.exitRoom();
    },

    async handleStartScreenShare() {
      if (!this.sdkAppId || !this.sdkSecretKey) {
        alert("请输入 sdkAppId 和 sdkSecretKey");
        return;
      }
      this.shareStatus = 'sharing';
      try {
        await this.trtc.startScreenShare();
        this.shareStatus = 'shared';
        this.$message.success('Start share screen success');
      } catch (error) {
        this.shareStatus = 'stopped';
        this.$message.error(`Start share error: ${error.message}`);
      }
    },

    async handleStopScreenShare() {
      if (this.shareStatus !== 'shared') {
        this.$message.error('The Share is not started');
        return;
      }
      this.shareStatus = 'stopping';
      try {
        await this.trtc.stopScreenShare();
        this.shareStatus = 'stopped';
        this.$message.success('Stop share screen success');
      } catch (error) {
        this.shareStatus = 'shared';
        this.$message.error(`Stop share error: ${error.message}`);
      }
    },

    addSuccessLog(log) {
      if (!this.isHostMode) {
        return;
      }
      this.logList.push({
        type: 'success',
        log,
      });
      const { scrollHeight } = this.$refs.logContainer;
      this.$refs.logContainer.scrollTop = scrollHeight;
    },

    addFailedLog(log) {
      if (!this.isHostMode) {
        return;
      }
      this.logList.push({
        type: 'failed',
        log,
      });
      const { scrollHeight } = this.$refs.logContainer;
      this.$refs.logContainer.scrollTop = scrollHeight;
    },
    reportSuccessEvent(name) {
      const ext3 = name === 'enterRoom' ? this.sdkAppId : 0;
      this.$aegis.reportEvent({
        name,
        ext1: `${name}-success`,
        ext2: this.$DEMOKEY,
        ext3,
      });
    },
    reportFailedEvent(name, error, type = 'rtc') {
      this.$aegis.reportEvent({
        name,
        ext1: `${name}-failed#${this.roomId}*${type === 'share' ? this.shareUserId : this.userId}*${error.message}`,
        ext2: this.$DEMOKEY,
        ext3: 0
      });
    },
  },
};
</script>

<style  scoped>
  .label {
    margin: 14px 0 6px;
    text-align: left;
    font-weight: bold;
  }

  .control-container {
    text-align: left;
    margin-bottom: 10px;

  }
  .control-container div:not(:nth-last-child(1)) {
    margin-bottom: 10px;
  }
  .control-container .button:not(:first-child) {
    margin-left: 2px;
  }

  .invite-link-container {
    width: 100%;
    color: #084298;
    background-color: #cfe2ff;
    position: relative;
    padding: 10px 16px;
    margin-bottom: 16px;
    border: 1px solid #b6d4fe;
    border-radius: 0.25rem;

  }
  .invite-link-container .invite-input {
    margin-top: 10px;
  }
  .invite-link-container .invite-btn {
    display: flex;
    cursor: pointer;
  }

  .info-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .log-container {
    flex-grow: 1;
    border: 1px solid #dddddd;
    height: 360px;
    padding: 10px;
    margin-right: 16px;
    overflow-y: scroll;

  }
  .log-label {
    margin: 0 0 6px;
    font-weight: bold;
  }
  .log-state {
    display: inline-block;
    margin-right: 6px;
  }
  > div {
    font-size: 12px;
  }
  .local-stream-container {
    width: 480px;
    height: 360px;
    position: relative;
    flex-shrink: 0;

  }
  .local-stream-content {
    width: 100%;
    height: 100%;
  }
  .local-stream-control {
    width: 100%;
    height: 30px;
    position: absolute;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 10px;

  }
  .control {
    margin-left: 10px;
  }
  .icon-class {
    color: #fff;
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
  .info-container-mobile {
    display: block;

  }
  .info-container-mobile .log-container {
    margin-right: 0;
  }
  .info-container-mobile .local-stream-container {
    width: 320px;
    height: 240px;
    margin-top: 10px;
  }

  .remote-container {
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    margin-left: 10px;

  }
  .remote-container .remote-stream-container {
    /*width: 480px;*/
    /*height: 360px;*/
    /*margin: 0 10px 10px 0;*/

    /*box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);*/

  }

</style>

