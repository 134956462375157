import request from './axios'
const preServiceListUrl = '/pre/service/list'
const getGovLoginUserUrl = '/govLogin/getGovLoginUser'  // 获取登录信息
const logOutUrl = '/govLogin/newLoginOut'               // 退出登录
const getOneThingItemListUrl = '/wt/ontThing/list'      // 一件事列表接口
const getOneThingGuidTreeUrl = '/wt/ontThing/getOnethingGuidTree' // 获取一件事问题指引
const getGuideItemListUrl = '/pre/service/getBindServiceCode'		  //	根据问题指引获取事项列表
const queryItemByServiceCodeUrl = '/newOneThing/queryItemByServiceCode' //	根据serviceCode集合获取事项集合
const queryByOneThingIdAndAreaCodeUrl = '/pre/service/queryByOneThingIdAndAreaCode' //	根据问题指引获取详情
const queryOneThingDataUrl = '/newOneThing/list'        // 根据modelCode 查询大类信息
const createProjidUrl = '/newOneThing/create-projid'    // 创建业务id
const getFormIdByGuideIdUrl = '/newOneThing/getFormIdByGuideId'	  //	通过问题引导id查询绑定的表单id
const onethingAnswerSubmitUrl = '/newOneThing/answerSubmit'       //  保存问题指引
const onetThingBaseinfoSaveUrl = '/newOneThing/onetThingBaseinfoSave'  //  一件事第一步保存
const getAesStrUrl = '/pre/service/getAesStr'   // aes加密
const getCloudHtmlFormDataIdUrl = '/newOneThing/getCloudHtmlFormDataId'   // 获取表单信息
const onetThingFormSaveUrl = '/newOneThing/onetThingFormSave'   // 保存表单信息
const preServiceMaterialListUrl = '/newOneThing/preServiceMaterialList2'   // 获取材料信息
const sendCodeVerifyUrl = '/wt/ontThing/sendCodeVerify'	//	发验证码短信(用于申报提交前的验证)
const onetThingSavePattrsUrl = '/newOneThing/savePattrs'  //  一件事第四步保存
const sendCodeUrl = '/pre/service/sendCode'	  //	发提示短信(调用业务接口发提示短信)
const getNewsListUrl = '/api/news/getNewsList'	  //	获取新闻主题列表
const getNewsTypeListUrl = '/api/news/getNewsTypeList'	  //	根据第一个接口返回的id查询对应的分类菜单名称
const getNewsContentListUrl = '/api/news/getNewsContentList'	  //	获取新闻具体内容
const getItemTagListByNameUrl = '/pre/service/getItemTagListByName'	  //	获取单事项分类
const queryListByTagUrl = '/pre/service/queryListByTag'	  //	获取单事项列表
const getServiceGuidUrl = '/pre/service/getServiceGuid'	  //	获取单事项办事指南
const getFormIdByServiceCodeUrl = '/newOneThing/getFormIdByServiceCode'	  //	单事项获取表单
const getLoginUserUrl = '/newOneThing/getLoginUser'   // 获取登录用户基本信息 这里返回固定值
const getServiceMaterialListUrl = '/newOneThing/materialinfoByServiceCode'   // 根据事项编码获取单事项申报材料
const preServiceMaterialCertificateListUrl = '/pre/service/preServiceMaterialCertificateList'   // 查询免证办材料对应的证照
const getZzListUrl = '/pre/service/license/getZzList'   // 获取电子证照列表
const licenseDownloadUrl ='/pre/service/license/licenseDownload'	// 下载证照图片
const upBase64FileUrl ='/pre/service/upBase64File'	// 上传base64文件
const saveSubmitUrl = '/newOneThing/saveSubmit'   // 单事项保存
const apasInfoListUrl = '/wt/apasInfo/list'       // 用户中心申报的单事项
const onetThingBussinessListUrl = '/newOneThing/onetThingBussinessList'       // 用户中心申报的一件事
const getOnethingDetailUrl = '/wt/ontThing/getOnethingDetail' // 获取一件事业务详情
const getDetailByProjidUrl = '/wt/ontThing/getDetailByProjid' // 获取单事项事业务详情
const getAllDeptByRegionCodeUrl = '/pre/service/getAllDeptByRegionCode'  //	根据区划查询部门列表
const preComplainAddUrl = '/pre/service/preComplainAdd'   //	添加咨询投诉信息
const preComplainListUrl = '/pre/service/preComplainList' //	查询咨询投诉信息
const queryOnethingPostListByUserUrl = '/pre/service/queryOnethingPostListByUser'   //	我的邮递-一件事
const querySingleListByUserUrl = '/newOneThing/querySingleListByUser' //	我的邮递-单事项
const oneCodeIntegrationUrl = '/newOneThing/ZMQQueryOnethingDetail' //	一码集成信息
const querybusinessCountDataUrl = '/wt/apasInfo/getBusinessCountData' //政务单事项电子监察
const queryComplainCountDataUrl = '/wt/apasInfo/getComplainCountData' //政务单事项电子监察
const queryPreServiceCountDataUrl = '/wt/apasInfo/queryPreServiceCountData' //政务单事项电子监察
const getEvaluteCountUrl = '/wt/apasInfo/getEvaluteCount'     // 获取好差评-评价数
const getEvaluatePublicUrl = '/wt/apasInfo/getEvaluatePublic' // 获取好差评-评价列表
const getEvaluateFromCountUrl = '/wt/apasInfo/getEvaluateFromCount'  // 获取好差评-各渠道评价总数
const getEvaluateDepartmentCountUrl = '/wt/apasInfo/getEvaluateDepartmentCount'  // 获取好差评-部门好差评分析
const getGroupsByDeptCodesUrl = '/appointment/GetGroupsByDeptCodes'  // 获取预约系统上所有的队列信息
const getBookableDateByDeptUrl = '/appointment/GetBookableDateByDept'  // 查询可以预约的日期
const getAppointmentSummaryUrl = '/appointment/GetAppointmentSummary'  // 查询可以预约的时间段
const appointmentUrl = '/appointment/Appointment'  // 预约提交
const getForeignCapitalUrl = '/pre/service/queryListByTag'  // 外资企业开办一站式服务体系
const getModelInfoListUrl = '/wt/ontThing/getModelInfoList'  // 查询事项中心配置的绑定的标签模板的一件事
const getCInsProjectListUrl = '/wt/ontThing/cInsProjectList'  // 获取承诺制项项目信息
const getCInsCorporateInformationLisrUrl = '/wt/ontThing/cInsCorporateInformationList'  // 获取承诺制项目企业信息
const saveCorporateAndItemInformationUrl = '/wt/ontThing/saveCorporateAndItemInformation'  // 保存企业与项目信息
const editCorporateAndItemInformationUrl = '/wt/ontThing/editCorporateAndItemInformation'  // 编辑企业与项目信息
const getCInsNodeListUrl = '/wt/ontThing/cInsNodeList'  // 获取承诺制项目月报项目进展
const getCInsNodeAddUrl = '/wt/ontThing/cInsNodeAdd'  // 保存承诺制项目月报项目进展
const getPublicNoticeListUrl = '/wt/ontThing/getPublicNoticeList'  // 获取承诺制项目公示内容
const getItemHandelLogListUrl = '/wt/ontThing/getItemHandelLogList'  // 获取承诺制项目事项办理日志
const cInsProjectBusinessRelationshipAddUrl = '/wt/ontThing/cInsProjectBusinessRelationshipAdd'  // 承诺制项目企业信息保存
const saveCloudExplorationModelRoomUrl = '/wt/ontThing/saveCloudExplorationModelRoom'  // 保存云勘探样板间
const getCloudExplorationModelRoomUrl = '/wt/ontThing/getCloudExplorationModelRoom'  // 获取云勘探样板间
const cloudExplorationVrListUrl = '/wt/ontThing/cloudExplorationVrList'  //



const getUrl = (url:string, params: any) => {
  return request(url, {
    method: 'get',
    responseType: 'json',
    params: {
      ...params
    },
  })
}
const postUrl = (url:string, data:object ) => {
  return request(url, {
    method: 'post',
    responseType: 'json',
    data: data,
  })
}
const uploadAction = (url:string, data:object ) => {
  return request(url, {
    method: 'post',
    responseType: 'json',
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data',  // 文件上传
    },
  })
}
const putUrl = (url:string, data:object ) => {
  return request(url, {
    method: 'put',
    responseType: 'json',
    data: data,
  })
}
const cloudExplorationVrList = (params: any) => getUrl(cloudExplorationVrListUrl, params)
const saveCloudExplorationModelRoom = (params: any) => postUrl(saveCloudExplorationModelRoomUrl, params)
const getCloudExplorationModelRoom = (params: any) => getUrl(getCloudExplorationModelRoomUrl, params)
const preServiceList = (params: any) => getUrl(preServiceListUrl, params)
const getGovLoginUser = (params: any) => getUrl(getGovLoginUserUrl, params)
const logOut = (params: any) => getUrl(logOutUrl, params)
const getOneThingItemList = (params: any) => getUrl(getOneThingItemListUrl, params)
const getOneThingGuidTree = (params: any) => getUrl(getOneThingGuidTreeUrl, params)
const getGuideItemList = (params: any) => postUrl(getGuideItemListUrl, params)
const getOntThingList = (params: any) => getOneThingItemList(params)
const queryItemByServiceCode = (params: any) => getUrl(queryItemByServiceCodeUrl, params)
const queryByOneThingIdAndAreaCode = (params: any) => postUrl(queryByOneThingIdAndAreaCodeUrl, params)
const queryOneThingData = (params: any) => getUrl(queryOneThingDataUrl, params)
const createProjid = (params: any) => getUrl(createProjidUrl, params)
const getFormIdByGuideId = (params: any) => getUrl(getFormIdByGuideIdUrl, params)
const onethingAnswerSubmit = (params: any) => postUrl(onethingAnswerSubmitUrl, params)
const onetThingBaseinfoSave = (params: any) => postUrl(onetThingBaseinfoSaveUrl, params)
const getAesStr = (params: any) => postUrl(getAesStrUrl, params)
const getCloudHtmlFormDataId = (params: any) => getUrl(getCloudHtmlFormDataIdUrl, params)
const onetThingFormSave = (params: any) => postUrl(onetThingFormSaveUrl, params)
const preServiceMaterialList = (params: any) => getUrl(preServiceMaterialListUrl, params)
const sendCodeVerify = (params: any) => postUrl(sendCodeVerifyUrl, params)
const onetThingSavePattrs = (params: any) => postUrl(onetThingSavePattrsUrl, params)
const sendCode = (params: any) => postUrl(sendCodeUrl, params)
const getNewsList = (params: any) => getUrl(getNewsListUrl, params)
const getNewsTypeList = (params: any) => getUrl(getNewsTypeListUrl, params)
const getNewsContentList = (params: any) => getUrl(getNewsContentListUrl, params)
const getItemTagListByName = (params: any) => getUrl(getItemTagListByNameUrl, params)
const queryListByTag = (params: any) => getUrl(queryListByTagUrl, params)
const getServiceGuid = (params: any) => getUrl(getServiceGuidUrl, params)
const getFormIdByServiceCode = (params: any) => getUrl(getFormIdByServiceCodeUrl, params)
const getLoginUser = (params: any) => getUrl(getLoginUserUrl, params)
const getServiceMaterialList = (params: any) => getUrl(getServiceMaterialListUrl, params)
const preServiceMaterialCertificateList = (params: any) => postUrl(preServiceMaterialCertificateListUrl, params)
const getZzList = (params: any) => postUrl(getZzListUrl, params)
const licenseDownload = (params: any) => postUrl(licenseDownloadUrl, params)
const upBase64File = (params: any) => postUrl(upBase64FileUrl, params)
const saveSubmit = (params: any) => postUrl(saveSubmitUrl, params)
const apasInfoList = (params: any) => getUrl(apasInfoListUrl, params)
const onetThingBussinessList = (params: any) => getUrl(onetThingBussinessListUrl, params)
const getOnethingDetail = (params: any) => postUrl(getOnethingDetailUrl, params)
const getDetailByProjid = (params: any) => postUrl(getDetailByProjidUrl, params)
const getAllDeptByRegionCode = (params: any) => postUrl(getAllDeptByRegionCodeUrl, params)
const preComplainAdd = (params: any) => postUrl(preComplainAddUrl, params)
const preComplainList = (params: any) => postUrl(preComplainListUrl, params)
const queryOnethingPostListByUser = (params: any) => postUrl(queryOnethingPostListByUserUrl, params)
const querySingleListByUser = (params: any) => getUrl(querySingleListByUserUrl, params)
const oneCodeIntegrationList = (params: any) => getUrl(oneCodeIntegrationUrl, params)
const queryPreServiceCountData = (params: any) => getUrl(queryPreServiceCountDataUrl, params)
const queryBusinessCountData = (params: any) => getUrl(querybusinessCountDataUrl,params)
const queryComplainCountData = (params: any) => getUrl(queryComplainCountDataUrl, params)
const getEvaluteCount = (params: any) => getUrl(getEvaluteCountUrl, params)
const getEvaluatePublic = (params: any) => getUrl(getEvaluatePublicUrl, params)
const getEvaluateFromCount = (params: any) => getUrl(getEvaluateFromCountUrl, params)
const getEvaluateDepartmentCount = (params: any) => getUrl(getEvaluateDepartmentCountUrl, params)
const getGroupsByDeptCodes = (params: any) => postUrl(getGroupsByDeptCodesUrl, params)
const getBookableDateByDept = (params: any) => postUrl(getBookableDateByDeptUrl, params)
const getAppointmentSummary = (params: any) => postUrl(getAppointmentSummaryUrl, params)
const appointment = (params: any) => postUrl(appointmentUrl, params)
const getForeignCapital = (params: any) => getUrl(getForeignCapitalUrl, params)
const getCInsProjectList = (params: any) => getUrl(getCInsProjectListUrl, params)
const saveCorporateAndItemInformation = (params: any) => postUrl(saveCorporateAndItemInformationUrl, params)
const editCorporateAndItemInformation = (params: any) => postUrl(editCorporateAndItemInformationUrl, params)
const getCInsCorporateInformationList = (params: any) => getUrl(getCInsCorporateInformationLisrUrl, params)
const getCInsNodeList = (params: any) => getUrl(getCInsNodeListUrl, params)
const getCInsNodeAdd = (params: any) => postUrl(getCInsNodeAddUrl, params)
const getPublicNoticeList = (params: any) => getUrl(getPublicNoticeListUrl, params)
const getItemHandelLogList = (params: any) => getUrl(getItemHandelLogListUrl, params)
const getModelInfoList = (params: any) => getUrl(getModelInfoListUrl, params)
const cInsProjectBusinessRelationshipAdd = (params: any) => postUrl(cInsProjectBusinessRelationshipAddUrl, params)


export {
  getUrl,
  postUrl,
  putUrl,
  uploadAction,
  preServiceList,
  getGovLoginUser,
  logOut,
  getOneThingItemList,
  getOneThingGuidTree,
  getGuideItemList,
  getOntThingList,
  queryItemByServiceCode,
  queryByOneThingIdAndAreaCode,
  queryOneThingData,
  createProjid,
  getFormIdByGuideId,
  onethingAnswerSubmit,
  onetThingBaseinfoSave,
  getAesStr,
  getCloudHtmlFormDataId,
  onetThingFormSave,
  preServiceMaterialList,
  sendCodeVerify,
  onetThingSavePattrs,
  sendCode,
  getNewsList,
  getNewsTypeList,
  getNewsContentList,
  getItemTagListByName,
  queryListByTag,
  getServiceGuid,
  getFormIdByServiceCode,
  getLoginUser,
  getServiceMaterialList,
  preServiceMaterialCertificateList,
  getZzList,
  licenseDownload,
  upBase64File,
  saveSubmit,
  apasInfoList,
  onetThingBussinessList,
  getOnethingDetail,
  getDetailByProjid,
  getAllDeptByRegionCode,
  preComplainAdd,
  preComplainList,
  oneCodeIntegrationList,
  queryOnethingPostListByUser,
  querySingleListByUser,
  queryPreServiceCountData,
  getEvaluteCount,
  getEvaluatePublic,
  getEvaluateFromCount,
  getEvaluateDepartmentCount,
  getGroupsByDeptCodes,
  getBookableDateByDept,
  getAppointmentSummary,
  appointment,
  getForeignCapital,
  getModelInfoList,
  getCInsProjectList,
  saveCorporateAndItemInformation,
  editCorporateAndItemInformation,
  getCInsCorporateInformationList,
  getCInsNodeList,
  getCInsNodeAdd,
  getPublicNoticeList,
  getItemHandelLogList,
  cInsProjectBusinessRelationshipAdd,
  saveCloudExplorationModelRoom,
  getCloudExplorationModelRoom,
  cloudExplorationVrList,
  queryBusinessCountData,
  queryComplainCountData
}
