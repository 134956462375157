<template>
  <div style="display: flex">
    <span class="label">{{ title }}</span>
    <el-input :placeholder="label" :type="type" v-model="infoValue">
    </el-input>
  </div>

</template>

<script>
import { getUrlParam } from '@/utils/audio/utils.js';
import Vue from 'vue'
import {randomNumber} from "@/utils/util";

export default {
  name: 'userIdInput',
  props: {
    label: String,
    title: String,
    roomId: Number
  },
  data() {
    return {
      type: 'string',
      infoValue: '',
    };
  },
  watch: {
    infoValue: {
      immediate: true,
      handler(val) {
          this.$emit('change', this.type === 'number' ? Number(val) : val);
      },
    },
    roomId: {
      immediate: true,
      handler(val) {
        this.infoValue = val
      },
    },

  },
  mounted() {
    switch (this.label) {
      case 'userId': {
        const userId = getUrlParam('userId');
        this.infoValue = userId
        break;
      }
      case 'roomId': {
        this.type = 'number';
        this.infoValue = this.roomId
        break;
      }
      case 'sdkAppId': {
        const sdkAppId = getUrlParam('sdkAppId');
        this.type = 'number';
        this.infoValue = sdkAppId ? sdkAppId : '';
        break;
      }
      case 'sdkSecretKey': {
        const sdkSecretKey = getUrlParam('sdkSecretKey');
        this.infoValue = sdkSecretKey ? sdkSecretKey : '';
        break;
      }
      default:
        break;
    }
  },
};
</script>

<style  scoped>
.label {
  display: inline-block;
  padding: 0 20px;
  width: 150px;
  height: 40px;
  text-align: left;
  line-height: 40px;
  border-top: 1px solid #DCDFE6;
  border-left: 1px solid #DCDFE6;
  border-bottom: 1px solid #DCDFE6;
  border-radius: 4px 0 0 4px;
  color: #909399;
  background-color: #F5F7FA;
  font-weight: bold;
}
</style>
