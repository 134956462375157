import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import 'element-plus/dist/index.css'
import Top from '@/components/top/index.vue'
import Bottom from '@/components/bottom/index.vue'
import VueQrcode from 'vue-qrcode'
import TRTC from 'trtc-sdk-v5'
import '@/utils/audio/aegis.js';
TRTC.setLogLevel(2);
import Aegis from 'aegis-web-sdk';
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
const isProd = location.origin === 'https://web.sdk.qcloud.com';
const DEMOKEY = isProd ? 'v5QuickDemoVue2' : 'v5QuickDemoVue2Dev';

const AEGIS_ID = {
    dev: 'iHWefAYqBEHVFrSxnV',
    prod: 'iHWefAYqVGQzlNLveU',
};

const aegis = new Aegis({
    id: isProd ? AEGIS_ID.prod : AEGIS_ID.dev,
    reportApiSpeed: false,
    reportAssetSpeed: false,
});

const app = createApp(App)
app.use(store)
app.use(router)
app.use(Viewer)
app.component('vue-qrcode', VueQrcode)
app.use(ElementPlus,{ locale: zhCn})
app.component('Top',Top)
app.component('Bottom',Bottom)
app.mount('#app')
app.config.globalProperties.$DEMOKEY = DEMOKEY;
app.config.globalProperties.$aegis = aegis;

//window.console.log = function () {};
