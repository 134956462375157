const AREA_ID = '410296000000'
const AREA_NAME = '自由贸易试验区开封片区'
//  展示表单接口
const FORM_URL = process.env.VUE_APP_FORM_URL + '/form_center/cloudForm/loadCloudHtmlForm'
//  图片展示接口
const IMG_PREVIEW = process.env.VUE_APP_IMG_PREVIEW + '/file/manager/downloadOne?fileId='

const IMAGE_PREVIEW = process.env.VUE_APP_IMG_PREVIEW + '/file/manager/preview?fileId='
//  文件上传接口
const FILE_URL = process.env.VUE_APP_FILE_URL + '/file/manager/uploadOne'
const UPLOAD_BASE64 = process.env.VUE_APP_FILE_URL + '/file/manager/uploadBase64'
//  省统一登录跳转地址
const LOGIN_URL = process.env.VUE_APP_URL +'/govLogin/newLogin?currentUrl='+ process.env.VUE_APP_THIS_IP +'/login-loading&finalUrl='

export {
    AREA_ID,
    AREA_NAME,
    FORM_URL,
    IMG_PREVIEW,
    FILE_URL,
    LOGIN_URL,
    IMAGE_PREVIEW,
    UPLOAD_BASE64
}
