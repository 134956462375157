<template>
  <div>
    <canvas
        ref="canvas"
        :height="height"
        :width="width"
        @mousedown="startDrawing"
        @mouseup="stopDrawing"
        @mousemove="draw"
        @mouseout="stopDrawing"
        @touchstart="startDrawing"
        @touchend="stopDrawing"
        @touchmove="drawTouch"
        style="box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px"
    ></canvas>
    <div>
      <el-button type="primary" @click="saveSignature">保存签名</el-button>
      <el-button type="danger" @click="clearCanvas">清除签名</el-button>
    </div>

  </div>
</template>

<script>
import {postUrl} from "@/config/request";
import {UPLOAD_BASE64} from "@/config/const";
import {useRoute} from 'vue-router'

export default {
  data() {
    return {
      isDrawing: false,
      context: null,
      lastX: 0,
      lastY: 0,
      height: window.innerWidth/2,
      width: window.innerWidth,
      drawingPaths: [],
      minioUploadBase64: UPLOAD_BASE64,
      saveHandleSignature:"/wt/ontThing/saveHandleSignature",
      formDataId:""
    };
  },
  mounted() {
    let route = useRoute();
    this.formDataId=route.query.formDataId
    this.initCanvas();
  },
  methods: {
    initCanvas() {
      const canvas = this.$refs.canvas;
      this.context = canvas.getContext('2d');
      this.context.strokeStyle = '#000000';
      this.context.lineWidth = 2;
      this.context.lineJoin = 'round';
      this.context.lineCap = 'round';
    },
    startDrawing(e) {
      this.isDrawing = true;
      this.lastX = e.offsetX || e.touches[0].clientX;
      this.lastY = e.offsetY || e.touches[0].clientY;
      this.drawingPaths.push([]);
    },
    stopDrawing() {
      this.isDrawing = false;
    },
    draw(e) {
      if (!this.isDrawing) return;
      this.context.beginPath();
      this.context.moveTo(this.lastX, this.lastY);
      this.context.lineTo(e.offsetX, e.offsetY);
      this.context.stroke();
      this.drawingPaths[this.drawingPaths.length - 1].push({
        x: e.offsetX,
        y: e.offsetY
      });
      this.lastX = e.offsetX;
      this.lastY = e.offsetY;
    },
    drawTouch(e) {
      if (!this.isDrawing) return;
      const touch = e.touches[0];
      this.context.beginPath();
      this.context.moveTo(this.lastX, this.lastY);
      this.context.lineTo(touch.clientX, touch.clientY);
      this.context.stroke();
      this.drawingPaths[this.drawingPaths.length - 1].push({
        x: touch.clientX,
        y: touch.clientY
      });
      this.lastX = touch.clientX;
      this.lastY = touch.clientY;
      e.preventDefault();
    },
    saveSignature() {
      const dataUrl = this.$refs.canvas.toDataURL('image/png');
      console.log(dataUrl); // 这里可以将签名数据发送到服务器

      let that = this
      let param = [{
        fileName:  "签名.png",
        fileType:  "png",
        base64 :  dataUrl.split(",")[1]
      }]
      let data = {userId: "123", data: param};
      postUrl( that.minioUploadBase64, data).then((res) => {
        if (res.success){
          let result = JSON.parse(res.result)
          // that.picList.push(result[0].fileId)
          console.log(result[0].fileId)
          that.saveSignatureInForm(result[0].fileId);
        }else {
          that.$message.error("签名保存失败！")
        }
      })
    },
    saveSignatureInForm(value){
      let param = {
        sign:value,
        nodeId: this.formDataId
        // nodeId:"1824333749538639874"
      }
      postUrl(this.saveHandleSignature,param).then(res=>{
        if (res.success) {
          this.$message.success("签名保存成功！")
        }else {
          this.$message.error("签名保存失败！")
        }
      })
    },
    clearCanvas() {
      this.context.clearRect(0, 0, this.width, this.height);
      this.drawingPaths = [];
    }
  }
};
</script>
