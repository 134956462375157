<template>
<!--  <div style="height: 100px">
    <img v-for="item in imgList" width="100px" height="100px"
         :src="item" alt="">
  </div>-->
  <div style="position: absolute;bottom: 20px;" >
    <img v-for="(item,index) in imgList" :key="index"
        style="margin-right: 10px;cursor: pointer;box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        display: inline-block;opacity: 0.7; width: 150px;height: 100px"
        :src="imgUrl+item"
        @click="changeImg(item)"
    />
  </div>
  <div ref="panoramaContainer" class="panorama-container"></div>
</template>

<script>
import * as THREE from 'three';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import {IMAGE_PREVIEW} from "@/config/const";
import {useRoute} from 'vue-router'
export default {
  name: 'PanoramaHouse',
  data(){
    return{
      imgList:[
/*        "ff80808191065a2c01915a3ffa471d8d",
        "ff80808191065a2c01915a4058ec1d8e"*/
      ],
      currentImg:"",
      imgUrl:IMAGE_PREVIEW
    }
  },
  mounted() {
    this.currentImg = ""
    this.imgList = []
    const route = useRoute()
    let value = route.query.files;
    this.imgList = JSON.parse(value)
    this.init();
  },
  methods: {
    changeImg(img){
      this.currentImg = img
      this.init()
    },
    init() {
      this.$refs.panoramaContainer.innerHTML =""
      const width = window.innerWidth
      const height = window.innerHeight;

      // 场景
      const scene = new THREE.Scene();

      // 相机
      const camera = new THREE.PerspectiveCamera(75, width / height, 1, 1000);
      camera.position.z = 5;
      camera.lookAt(new THREE.Vector3(1, 0, 0)); //让相机指向原点
      // 渲染器
      const renderer = new THREE.WebGLRenderer();
      renderer.setSize(width, height);
      this.$refs.panoramaContainer.appendChild(renderer.domElement);

      // 加载全景图
      const loader = new THREE.TextureLoader();
      const texture = loader.load(this.currentImg?this.imgUrl+this.currentImg:this.imgUrl+this.imgList[0], () => {
      // const texture = loader.load(require("./ceshi.jpg"), () => {
        renderer.render(scene, camera);
      });

      // 创建一个球体作为全景图的载体
      const geometry = new THREE.SphereGeometry(500, 60, 40);
      const material = new THREE.MeshBasicMaterial({ map: texture });
      // const sphere = new THREE.Mesh(geometry, material);
      const skyBox = new THREE.Mesh(new THREE.SphereBufferGeometry(100, 100, 100), material);
      skyBox.geometry.scale(1, 1, -1);
      scene.add(skyBox)
      // scene.add(sphere);

      // 拖拽控制（可选）
      const controls = new OrbitControls(camera, renderer.domElement);
      controls.target = new THREE.Vector3(0, 0, 0);
      controls.minDistance = 18; // 相机最近
      controls.maxDistance = 500; // 相机最远
      controls.autoRotate = false; // 图片自动旋转
      controls.enableDamping = true; // 使动画循环使用时阻尼或自转 意思是否有惯性
      // 阻尼系数
      controls.dampingFactor = 0.05;
      controls.enablePan = true; // 是否开启右键拖拽
      controls.autoRotateSpeed = 0.5; // 阻尼系数
      // 动画循环
      function animate() {
        requestAnimationFrame(animate);
        controls.update(); // 如果使用了 OrbitControls
        renderer.render(scene, camera);
      }
      animate();
    }
  }
}
</script>

<style scoped>
.panorama-container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
