<template>
  <div>
    <div style="margin: 0 auto;width: 80%">

    <comp-home ref="compHome"></comp-home>

    <el-card v-for="item in formUrl" style="margin: 10px auto;">
        <iframe style="margin: 10px auto"  :src="item" frameborder="0" width="100%" height="400px" scrolling="auto"></iframe>
    </el-card>

    <div style="margin: 10px auto;">
      <div v-show="qrVisible" id="qrcode" style="width: 200px; height: 200px;"></div>

      <div style="margin: 10px 0 10px 0">
        <el-button type="primary" @click="updateSignStatus" >签字确认发起</el-button>
        <el-button
          type="primary"
          style="width: 100px; margin-left: 10px"
          @click="screenshot">
          截图
        </el-button>
      </div>
      <div>
        <viewer :images="picList">

          <div style="display: inline-block;position: relative" v-for="(item,index) in picList" :key="index">
              <img
                   style="margin-right: 10px;box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; width: 200px;height: 140px"
                   :src="fileURL+item"/>
              <span class="removePic" @click="removeItem(item)">×</span>
          </div>
        </viewer>
      </div>
    </div>

    <div style="margin: 20px auto;">
      <el-button style="margin-right: 10px" type="primary" @click="startRecording">开始录制</el-button>
      <el-button style="margin-right: 10px" type="danger" @click="stopRecording" >停止录制</el-button>
      <div v-if="playback" style="margin-top: 10px">
        <video ref="video" style="width: 1000px;height: 500px;background-color: #cccccc" controls></video>
      </div>
    </div>
    <div style="float: right;">
      <el-button type="primary" @click="checkOk('已勘验')">通过</el-button>
      <el-button type="danger" @click="checkOk('待复查')">不通过</el-button>
    </div>
<!--    <el-modal title="请选择所需要的表单" v-model="modalVisible" :mask-closable="false" :width="600" :footer="false" >
      <template v-for="item in formList">
        <span class="formName" @click="getAesStr(item.formId)">
          {{item.formName}}
        </span>
      </template>
    </el-modal>-->
    <el-dialog
        title="请选择所需要的表单"
        v-model="modalVisible"

    >
<!--      <div style="display: flex;" v-for="item in formList">
        <span class="formName" @click="getAesStr(item.formId)">
          {{item.formName}}
        </span>
      </div>-->
      <el-checkbox-group v-model="checkedForms"  @change="handleCheckedCitiesChange">
        <el-checkbox style="display: flex;width: 100%;margin-top: 20px" v-for="item in formList" :label="item.formId" :key="item.formId">
          <span class="formName">
            {{item.formName}}
          </span>
        </el-checkbox>
      </el-checkbox-group>
      <div style="width: 220px;margin: auto">
        <el-button style="margin: 30px auto 0px;width: 200px;height: 40px;line-height: 40px"
                   type="primary" @click="operationForm()">
          确定
        </el-button>
      </div>
    </el-dialog>
    </div>
  </div>

</template>

<script>
import CompHome from './comp-Home'
// import { getAction, postAction, uploadAction,putAction } from '@api/manage'
import {getUrl, postUrl, putUrl,uploadAction} from "@/config/request";
import html2canvas from 'html2canvas';
import { randomNumber } from '@/utils/util'
import {UPLOAD_BASE64, IMAGE_PREVIEW, FILE_URL,FORM_URL} from "@/config/const";
import {useRoute} from 'vue-router'
import signature from "@/views/ykt/signature";
import QRCode from 'qrcodejs2';
// let value = useRoute.query.value;
  export default {
    name: 'CloudExplorationHandle',
    mixins:[],
    components: {
      CompHome,signature
    },
    data () {
      return {
        checkedForms:[],
        qrVisible:false,
        modalVisible: false,
        title:'远程勘探',
        width:800,
        visible: false,
        disableSubmit: false,
        record:{},
        sdkAppId:'1600047555',
        formList:[],
        formId:"",
        formUrl:[],
        fileURL: IMAGE_PREVIEW,
        url:{
          getFormIdByServiceCode:"/newOneThing/getFormIdByServiceCode",//获取事项所关联的云勘探表单
          getAesStr:"/pre/service/getAesList",//表单信息aes加密
          desEncrypt:"/pre/service/desEncrypt",//aes解密
          getCloudHtmlFormDataId:"/newOneThing/getCloudHtmlFormDataId",//
          cloudExplorationNodeAdd:"/wt/ontThing/cloudExplorationNodeAdd",//
          cloudExplorationEdit:"/wt/ontThing/cloudExplorationEdit",//
          minioUploadBase64: UPLOAD_BASE64,
          uploadOne: FILE_URL,
        },
        handleData:{},
        picList:[],

        events:[],
        recorder: null,
        recordingData: null,
        isRecording: false,
        playback: false,
        recordedChunks:[],
        selectForms:[],
        formProjids:[],//查询表单用的projid
        FormAndProjid:[],
        videoUploadStatus:false,//判断视频是否上传完成
        whetherToUpload: false //判断是否上传视频
      }
    },

    mounted () {
      const route = useRoute()
      if (!route.query.value){
        this.openWindow()
      }
      let value = route.query.value;

      this.desEncrypt(value)
      console.log(value,"value==========")
      this.video = document.querySelector('video')
/*      this.record.servicecode='725849853QT48884002'
      this.record.roomId=randomNumber(6)*/
    },
    methods: {
      handleCheckedCitiesChange(value) {
        this.selectForms = value
      },
      generateQRCode() {
        // 创建一个新的QRCode实例，并指定容器ID和二维码内容
        new QRCode(document.getElementById('qrcode'), {
          text: "https://ftz.kaifeng.gov.cn/signature?formDataId="+this.handleData.formDataId, // 你要生成的二维码内容
          width: 200, // 二维码宽度
          height: 200, // 二维码高度
          colorDark: '#000000', // 二维码深色部分的颜色
          colorLight: '#ffffff', // 二维码浅色部分的颜色
          correctLevel: QRCode.CorrectLevel.H // 容错率
        });
      },
      openWindow(){
        window.location.href="https://ftz.kaifeng.gov.cn/"
      },
      edit (record) {
        //清除缓存
        this.clearAll()

        this.record = record

        this.visible=true
        this.getFormIdByServiceCode(record.servicecode)
        this.$nextTick(()=>{
          this.$refs.compHome.getCloudExplorationUserSig(this.sdkAppId,record.roomId)
        })

      },
      desEncrypt(data){
        postUrl(this.url.desEncrypt,{paramData:data}).then(res=>{
          this.record = res.result
          if (!this.record.servicecode){
            this.openWindow()
          }

          this.edit(this.record)

        })
      },
      updateSignStatus(){
        let that = this

        let flat = true
        let formDataId = []
        this.FormAndProjid.forEach(item=>{
          getUrl(this.url.getCloudHtmlFormDataId,item).then(res=>{
            if (res.success){
              formDataId.push(res.result.id)
            }else{
              flat = false
            }
          }).catch(res=>{
            flat = false
          })
        })
        setTimeout(() => {
          if (flat){
            console.log("flat",flat)
            that.record.sign = '1'
            that.record.currentNodeId = formDataId.join(",")
            this.handleData.formDataId = formDataId.join(",")
            putUrl(that.url.cloudExplorationEdit,that.record).then(res=>{
              if (res.success){
                that.$message.success("签名发起成功！")
                that.qrVisible = true
                that.generateQRCode()
              }
            })
          } else {
            this.$message.error("请先提交表单！")
          }
        }, 1500)


      },
      removeItem(value){
        this.picList = this.picList.filter(item=>{
         return item !== value
        })
        this.$message.success("删除成功！")
      },
      async startRecording() {
        this.whetherToUpload = true
        this.recordedChunks = []
        const stream = await navigator.mediaDevices.getDisplayMedia({
          video: true,
          audio: true
        });
        this.mediaRecorder = new MediaRecorder(stream,
          {
            audioBitsPerSecond : 128000,
            videoBitsPerSecond : 2500000,
            mimeType : 'video/webm'
          });
        this.mediaRecorder.addEventListener('dataavailable', event => {
          this.recordedChunks.push(event.data);
        });
        this.mediaRecorder.start();
      },
      stopRecording() {
        let that = this
        this.mediaRecorder.stop();
        this.playback = true
        this.$message.success("停止录制成功！")
        setTimeout(function() {
          that.replay()
        },2000)
      },

      replay() {
        let that =this
          const blob = new Blob(this.recordedChunks, {
            audioBitsPerSecond : 128000,
            videoBitsPerSecond : 2500000,
            type : 'video/webm'
          });
        // Blob 转 File
        let file = new File([blob], "视频.webm", {type: blob.type});
        this.blobToBase64(file, (base64) => {
          console.log(JSON.parse(JSON.stringify(base64)).split(","))
          that.saveVideo(base64)
          // 在这里，你可以根据需要使用这个Base64字符串
        });
          const url = URL.createObjectURL(blob);
          this.$refs.video.src = url;
      },
      blobToBase64(blob, callback) {
        // 创建一个FileReader实例
        const reader = new FileReader();

        // 当FileReader读取完成时触发
        reader.onload = function(e) {
          // 读取结果是一个data: URL格式的Base64编码字符串
          const base64String = e.target.result;
          // 调用回调函数，将Base64字符串作为参数传递
          if (typeof callback === 'function') {
            callback(base64String);
          }
        };

        // 以DataURL的形式读取Blob对象
        reader.readAsDataURL(blob);
      },
      saveVideo(file){
        console.log("++++++++++++",file.split(",")[1])
      let that = this
      let param = [{
        fileName:  "截图.png",
        fileType:  "png",
        base64 :  file.split(",")[1]
      }]
      let data = {userId: "123", data: param};
      postUrl( that.url.minioUploadBase64, data).then((res) => {
        if (res.success){
          let result = JSON.parse(res.result)
          // that.picList.push(result[0].fileId)
          this.handleData.videoFile = result[0].fileId
          that.$message.success("视频保存成功！")
          that.videoUploadStatus = true
          console.log(this.handleData.videoFile)
        }
      })
     /* console.log(file)
      let formData = {'file': file}
      // formData.('file', file);
      console.log(formData)
      uploadAction( this.url.uploadOne,formData).then(res=>{
        if (res.success){
          let result = JSON.parse(res.result)
          this.handleData.videoFile = result.fileId
        }
      })*/
    },

    screenshot(){
        let that = this
        var result = {};
        // remote-container_id
        // var htmlContent = document.body
        var htmlContent = document.getElementById("remote-container_id")
        html2canvas(htmlContent,{
          background:`${htmlContent.style.backgroundImage}`,
          useCORS: true,
          // height:'100%',
          // scale: 2, // 处理模糊问题
          // dpi: 300, // 处理模糊问题
        }).then(function (canvas) {
          var imgData = canvas.toDataURL('image/png');

          var img = document.createElement("img");
          img.src = imgData;
          let param = [{
             fileName:  "截图.png",
             fileType:  "png",
             base64 :  imgData.split(",")[1]
          }]
          let data = {userId: "123", data: param};
          postUrl( that.url.minioUploadBase64, data).then((res) => {
            if (res.success){
              let result = JSON.parse(res.result)
              that.picList.push(result[0].fileId)
              that.$message.success("截图成功！")
              console.log(that.picList)
            }
          })
        })

      },
      getFormIdByServiceCode(value){
        let param =  {
          serviceCode:value,
          formType:'ykt',
        }
        getUrl(this.url.getFormIdByServiceCode,param).then(res=>{
          console.log(res)
          if (res.success){
            if(res.result.records.length > 0){
              this.formList = res.result.records
/*               this.formId = res.result.records[0].formId;
              this.getAesStr(res.result.records[0].formId) */
              this.modalVisible = true
            }else {
              this.$message.error("该事项未绑定云勘探表单！")
            }
          }
        })
      },
      operationForm(){
        let that = this
        console.log(this.selectForms)
        this.selectForms.forEach(item =>{
          let projid = randomNumber(18)
          let data = {
            projid:projid,
            formId:item
          }
          that.formProjids.push(projid)
          that.FormAndProjid.push(data)
        })
        this.getAesStr()
      },
      getAesStr(formId){
        let that =this
        this.formId = this.selectForms
        // this.handleData.id = projid
        let param = {
          FormAndProjid: JSON.stringify(this.FormAndProjid),
          projid: "",
          formId: "",
          applyCardnumber:this.record.applyCardnumber,
          serviceCode: this.record.servicecode
        }
        postUrl(this.url.getAesStr,{"paramData":JSON.stringify(param)}).then(res=>{
          if (res.success) {
            res.result.forEach(item =>{
              let formUrl = FORM_URL+('?jm=' + item);
              that.formUrl.push(encodeURI(formUrl))
            })
            this.modalVisible = false

          } else {
            that.$message.error("表单加载失败！")
          }

        })
      },
      getCloudHtmlFormDataId(){
        let that = this
        this.handleData.formDataId = ''
        this.handleData.form = ''
        let formDataId = []
        let form = []
        let flat = true
        this.FormAndProjid.forEach(item=>{
          getUrl(this.url.getCloudHtmlFormDataId,item).then(res=>{
            if (res.success){
              formDataId.push(res.result.id)
              form.push(res.result.id)
            }else{
              flat = false
              this.$message.error("请先提交表单！")
            }
          })
        })
        setTimeout(() => {
          if (flat){
            that.handleData.formDataId = formDataId.join(",")
            that.handleData.form = form.join(",")
            //先判断是否录制视频，然后判断视频是否上传完成
            if (!that.whetherToUpload){
              that.handleOk()
            } else if (that.videoUploadStatus){
              that.handleOk()
            }else {
              that.$message.error("视频还未上传完成请稍后操作！")
            }
          } else {
            that.$message.error("请先提交表单！")
          }
        }, 1500)


      },
      clearAll(){
        this.formId = ''
        this.handleData = {}
        this.picList = []
        this.events=[]
        this.recorder = null
        this.recordingData = null
        this.isRecording = false
        this.playback =false
        this.formUrl = []
        this.formList =[]
        this.selectForms = []
        this.formProjids=[]  //查询表单用的projid
        this.FormAndProjid=[]
        this.videoUploadStatus = false
        this.whetherToUpload = false
      },

      close () {
        this.$emit('close');
        this.visible = false;
      },
      checkOk(result){
        this.handleData.type = '1'
        this.handleData.result = result
        this.getCloudHtmlFormDataId()
      },
      handleOk () {
        this.handleData.ceId = this.record.id
        if (this.picList.length>0){
          this.handleData.imgFile = JSON.stringify(this.picList)
        }
        postUrl(this.url.cloudExplorationNodeAdd,this.handleData).then(res=>{
          if (res.success){
            this.$message.success("操作成功！")
            this.$emit('ok');
            this.visible = false;
          }else {
            this.$message.error(res.message)
          }
        })

      },
      handleCancel () {
        this.close()
      }
    }
  }
</script>
<style scoped>
.removePic {
  font-size: 18px;
  position: absolute;
  right: 20px;
}
.removePic:hover {
  color: black;
  cursor: pointer;
}
.formName {
  display: inline-block;
  margin: 20px 10px;
  height: 40px;
  width: 100%;
  line-height:40px;
  text-align: center;
  padding: 0px 10px;
  flex: 1;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;

}
/deep/.el-checkbox-group .el-checkbox .el-checkbox__label {
  width: 90% !important;
}
.formName:hover {
/*  background-color: black;
  color: aliceblue;*/
  cursor: pointer;
  color: black;
  background-color: #cddcfc;

}
</style>
