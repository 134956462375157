<template>
    <div class="right_cont">
        <div class="t6_title_h1">
            我的证照
        </div>
        <div class="content">
            <div>
                <el-empty description="暂无数据" />
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">

</script>
<style lang="scss" scoped>

</style>
