<template>
  <div>
    <div class="content">
      <p>判断当前浏览器环境是否满足使用 TRTC，您可以访问 <a
        target="_blank" href="https://web.sdk.qcloud.com/trtc/webrtc/demo/detect/index.html">TRTC 检测页面</a>
      </p>
      <p class="label">参数</p>
      <div style="display: flex" >
<!--        <div >
          <span class="label1">用户</span>
          <el-input style="width: 80%" placeholder="用户" v-model="userId"></el-input>
        </div>-->
          <span class="label1">房间号</span>
          <el-input style="width: 50%" placeholder="房间号" type="number"  v-model="roomId"></el-input>

<!--        <comp-info-input
          label="userId" title="用户" @change="handleValueChange($event, 'userId')"></comp-info-input>
        <comp-info-input
          label="roomId" title="房间号" :roomId="roomId" type="number" @change="handleValueChange($event, 'roomId')"></comp-info-input>-->
      </div>
      <p class="label">设备选择</p>
      <div class="param-container" :class=" 'param-container-mobile'">
        <comp-device-select
          deviceType="camera" title="摄像头" @change="handleValueChange($event, 'cameraId')"></comp-device-select>
        <comp-device-select
          deviceType="microphone" title="麦克风" @change="handleValueChange($event, 'microphoneId')"></comp-device-select>
      </div>
      <comp-room
        :sdkAppId="Number(sdkAppId)"
        :sdkSecretKey="sdkSecretKey"
        :userId="userId"
        :roomId="Number(roomId)"
        :cameraId="cameraId"
        :microphoneId="microphoneId"
        :inviteUserSig="inviteUserSig"></comp-room>

    </div>
  </div>
</template>

<script>
import compInfoInput from './comp-info-input';
import compDeviceSelect from './comp-device-select';
import compRoom from './comp-room';
import {getUrl} from "@/config/request";
import {randomNumber, randomUUID} from "@/utils/util";

export default {
  name: 'App',
  components: {
    compInfoInput,
    compDeviceSelect,
    compRoom,
  },
  data() {
    return {
      sdkAppId: 0,
      sdkSecretKey: '',
      userId: "",
      roomId: 0,
      cameraId: '',
      microphoneId: '',
      inviteUserSig:'',
    };
  },
  methods: {
    handleValueChange(value, key) {
      console.log(value,key)
      this[key] = value;
    },
    getCloudExplorationUserSig(sdkAppId,roomId){
      this.userId = randomUUID()
      this.roomId = parseInt(roomId)
      console.log("roomId",this.roomId)

      this.sdkAppId = sdkAppId
      let param= {
        sdkAppId: sdkAppId,
        userId: this.userId
      }
      getUrl("/wt/ontThing/getCloudExplorationUserSig",param).then(res=>{
        if (res.success){
          this.inviteUserSig = res.result
        }else
          this.$message.error(res.message)
      })
      // this.getCloudExplorationUserSigZDY()
    },
    getCloudExplorationUserSigZDY(){
      let param= {
        sdkAppId: this.sdkAppId,
        userId: "administrator"
      }
      getUrl("/wt/ontThing/getCloudExplorationUserSigZDY",param).then(res=>{
        if (res.success){
          // this.inviteUserSig = res.result
        }else
          this.$message.error(res.message)
      })
    }
  },
  mounted() {
    // clearUrlParam();
  },
};
</script>

<style  scoped>
.content {
  margin: 40px auto;
}
.alert {
  padding-top: 20px;
  font-size: 16px !important;
}
.content-mobile {
  width: 100%;
  padding: 0 16px 20px;
}
.label {
  margin: 14px 0 6px;
  text-align: left;
  font-weight: bold;
}
.param-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.param-container div {
  width: calc((100% - 20px) / 2);
  margin-bottom: 10px;
}
.param-container div:nth-last-child(2), div:nth-last-child(1) {
  margin-bottom: 0;
}
.param-container .param-container-mobile div {
    width: 100%;
    margin-bottom: 10px;
  }
.label1 {
  display: inline-block;
  padding: 0 20px;
  width: 120px;
  height: 40px;
  text-align: left;
  line-height: 40px;
  border-top: 1px solid #DCDFE6;
  border-left: 1px solid #DCDFE6;
  border-bottom: 1px solid #DCDFE6;
  border-radius: 4px 0 0 4px;
  color: #909399;
  background-color: #F5F7FA;
  font-weight: bold;
}
</style>


