const cityMap = [
    {
        "cityCode": "100000",
        "cityName": "中国",
        "parentId": "0",
        "provinceType": "0",
        "province": "",
        "city": "",
        "county": "",
        "counties": [
            {
                "cityCode": "110000",
                "cityName": "北京市",
                "parentId": "100000",
                "provinceType": "1",
                "province": "北京市",
                "city": "",
                "county": "",
                "counties": [
                    {
                        "cityCode": "110100",
                        "cityName": "北京市",
                        "parentId": "110000",
                        "provinceType": "2",
                        "province": "北京市",
                        "city": "北京市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "110101",
                                "cityName": "东城区",
                                "parentId": "110100",
                                "provinceType": "3",
                                "province": "北京市",
                                "city": "北京市",
                                "county": "东城区",

                            },
                            {
                                "cityCode": "110102",
                                "cityName": "西城区",
                                "parentId": "110100",
                                "provinceType": "3",
                                "province": "北京市",
                                "city": "北京市",
                                "county": "西城区",

                            },
                            {
                                "cityCode": "110105",
                                "cityName": "朝阳区",
                                "parentId": "110100",
                                "provinceType": "3",
                                "province": "北京市",
                                "city": "北京市",
                                "county": "朝阳区",

                            },
                            {
                                "cityCode": "110106",
                                "cityName": "丰台区",
                                "parentId": "110100",
                                "provinceType": "3",
                                "province": "北京市",
                                "city": "北京市",
                                "county": "丰台区",

                            },
                            {
                                "cityCode": "110107",
                                "cityName": "石景山区",
                                "parentId": "110100",
                                "provinceType": "3",
                                "province": "北京市",
                                "city": "北京市",
                                "county": "石景山区",

                            },
                            {
                                "cityCode": "110108",
                                "cityName": "海淀区",
                                "parentId": "110100",
                                "provinceType": "3",
                                "province": "北京市",
                                "city": "北京市",
                                "county": "海淀区",

                            },
                            {
                                "cityCode": "110109",
                                "cityName": "门头沟区",
                                "parentId": "110100",
                                "provinceType": "3",
                                "province": "北京市",
                                "city": "北京市",
                                "county": "门头沟区",

                            },
                            {
                                "cityCode": "110111",
                                "cityName": "房山区",
                                "parentId": "110100",
                                "provinceType": "3",
                                "province": "北京市",
                                "city": "北京市",
                                "county": "房山区",

                            },
                            {
                                "cityCode": "110112",
                                "cityName": "通州区",
                                "parentId": "110100",
                                "provinceType": "3",
                                "province": "北京市",
                                "city": "北京市",
                                "county": "通州区",

                            },
                            {
                                "cityCode": "110113",
                                "cityName": "顺义区",
                                "parentId": "110100",
                                "provinceType": "3",
                                "province": "北京市",
                                "city": "北京市",
                                "county": "顺义区",

                            },
                            {
                                "cityCode": "110114",
                                "cityName": "昌平区",
                                "parentId": "110100",
                                "provinceType": "3",
                                "province": "北京市",
                                "city": "北京市",
                                "county": "昌平区",

                            },
                            {
                                "cityCode": "110115",
                                "cityName": "大兴区",
                                "parentId": "110100",
                                "provinceType": "3",
                                "province": "北京市",
                                "city": "北京市",
                                "county": "大兴区",

                            },
                            {
                                "cityCode": "110116",
                                "cityName": "怀柔区",
                                "parentId": "110100",
                                "provinceType": "3",
                                "province": "北京市",
                                "city": "北京市",
                                "county": "怀柔区",

                            },
                            {
                                "cityCode": "110117",
                                "cityName": "平谷区",
                                "parentId": "110100",
                                "provinceType": "3",
                                "province": "北京市",
                                "city": "北京市",
                                "county": "平谷区",

                            },
                            {
                                "cityCode": "110118",
                                "cityName": "密云区",
                                "parentId": "110100",
                                "provinceType": "3",
                                "province": "北京市",
                                "city": "北京市",
                                "county": "密云区",

                            },
                            {
                                "cityCode": "110119",
                                "cityName": "延庆区",
                                "parentId": "110100",
                                "provinceType": "3",
                                "province": "北京市",
                                "city": "北京市",
                                "county": "延庆区",

                            },
                            {
                                "cityCode": "110120",
                                "cityName": "中关村科技园区",
                                "parentId": "110100",
                                "provinceType": "3",
                                "province": "北京市",
                                "city": "北京市",
                                "county": "中关村科技园区",

                            }
                        ]
                    },
                    {
                        "cityCode": "110103",
                        "cityName": "崇文区",
                        "parentId": "110000",
                        "provinceType": "3",
                        "province": "北京市",
                        "city": "北京市",
                        "county": "崇文区",

                    },
                    {
                        "cityCode": "110104",
                        "cityName": "宣武区",
                        "parentId": "110000",
                        "provinceType": "3",
                        "province": "北京市",
                        "city": "北京市",
                        "county": "宣武区",

                    },
                    {
                        "cityCode": "110228",
                        "cityName": "密云县",
                        "parentId": "110000",
                        "provinceType": "3",
                        "province": "北京市",
                        "city": "北京市",
                        "county": "密云县",

                    },
                    {
                        "cityCode": "110229",
                        "cityName": "延庆县",
                        "parentId": "110000",
                        "provinceType": "3",
                        "province": "北京市",
                        "city": "北京市",
                        "county": "延庆县",

                    }
                ]
            },
            {
                "cityCode": "120000",
                "cityName": "天津市",
                "parentId": "100000",
                "provinceType": "1",
                "province": "天津市",
                "city": "",
                "county": "",
                "counties": [
                    {
                        "cityCode": "120100",
                        "cityName": "天津市",
                        "parentId": "120000",
                        "provinceType": "2",
                        "province": "天津市",
                        "city": "天津市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "120101",
                                "cityName": "和平区",
                                "parentId": "120100",
                                "provinceType": "3",
                                "province": "天津市",
                                "city": "天津市",
                                "county": "和平区",

                            },
                            {
                                "cityCode": "120102",
                                "cityName": "河东区",
                                "parentId": "120100",
                                "provinceType": "3",
                                "province": "天津市",
                                "city": "天津市",
                                "county": "河东区",

                            },
                            {
                                "cityCode": "120103",
                                "cityName": "河西区",
                                "parentId": "120100",
                                "provinceType": "3",
                                "province": "天津市",
                                "city": "天津市",
                                "county": "河西区",

                            },
                            {
                                "cityCode": "120104",
                                "cityName": "南开区",
                                "parentId": "120100",
                                "provinceType": "3",
                                "province": "天津市",
                                "city": "天津市",
                                "county": "南开区",

                            },
                            {
                                "cityCode": "120105",
                                "cityName": "河北区",
                                "parentId": "120100",
                                "provinceType": "3",
                                "province": "天津市",
                                "city": "天津市",
                                "county": "河北区",

                            },
                            {
                                "cityCode": "120106",
                                "cityName": "红桥区",
                                "parentId": "120100",
                                "provinceType": "3",
                                "province": "天津市",
                                "city": "天津市",
                                "county": "红桥区",

                            },
                            {
                                "cityCode": "120107",
                                "cityName": "塘沽区",
                                "parentId": "120100",
                                "provinceType": "3",
                                "province": "天津市",
                                "city": "天津市",
                                "county": "塘沽区",

                            },
                            {
                                "cityCode": "120109",
                                "cityName": "大港区",
                                "parentId": "120100",
                                "provinceType": "3",
                                "province": "天津市",
                                "city": "天津市",
                                "county": "大港区",

                            },
                            {
                                "cityCode": "120110",
                                "cityName": "东丽区",
                                "parentId": "120100",
                                "provinceType": "3",
                                "province": "天津市",
                                "city": "天津市",
                                "county": "东丽区",

                            },
                            {
                                "cityCode": "120111",
                                "cityName": "西青区",
                                "parentId": "120100",
                                "provinceType": "3",
                                "province": "天津市",
                                "city": "天津市",
                                "county": "西青区",

                            },
                            {
                                "cityCode": "120112",
                                "cityName": "津南区",
                                "parentId": "120100",
                                "provinceType": "3",
                                "province": "天津市",
                                "city": "天津市",
                                "county": "津南区",

                            },
                            {
                                "cityCode": "120113",
                                "cityName": "北辰区",
                                "parentId": "120100",
                                "provinceType": "3",
                                "province": "天津市",
                                "city": "天津市",
                                "county": "北辰区",

                            },
                            {
                                "cityCode": "120114",
                                "cityName": "武清区",
                                "parentId": "120100",
                                "provinceType": "3",
                                "province": "天津市",
                                "city": "天津市",
                                "county": "武清区",

                            },
                            {
                                "cityCode": "120115",
                                "cityName": "宝坻区",
                                "parentId": "120100",
                                "provinceType": "3",
                                "province": "天津市",
                                "city": "天津市",
                                "county": "宝坻区",

                            },
                            {
                                "cityCode": "120116",
                                "cityName": "滨海新区",
                                "parentId": "120100",
                                "provinceType": "3",
                                "province": "天津市",
                                "city": "天津市",
                                "county": "滨海新区",

                            },
                            {
                                "cityCode": "120117",
                                "cityName": "宁河区",
                                "parentId": "120100",
                                "provinceType": "3",
                                "province": "天津市",
                                "city": "天津市",
                                "county": "宁河区",

                            },
                            {
                                "cityCode": "120118",
                                "cityName": "静海区",
                                "parentId": "120100",
                                "provinceType": "3",
                                "province": "天津市",
                                "city": "天津市",
                                "county": "静海区",

                            },
                            {
                                "cityCode": "120119",
                                "cityName": "蓟州区",
                                "parentId": "120100",
                                "provinceType": "3",
                                "province": "天津市",
                                "city": "天津市",
                                "county": "蓟州区",

                            },
                            {
                                "cityCode": "120225",
                                "cityName": "蓟州区",
                                "parentId": "120100",
                                "provinceType": "3",
                                "province": "天津市",
                                "city": "天津市",
                                "county": "蓟州区",

                            },
                            {
                                "cityCode": "120226",
                                "cityName": "滨海高新区",
                                "parentId": "120100",
                                "provinceType": "3",
                                "province": "天津市",
                                "city": "天津市",
                                "county": "滨海高新区",

                            }
                        ]
                    },
                    {
                        "cityCode": "120108",
                        "cityName": "汉沽区",
                        "parentId": "120000",
                        "provinceType": "3",
                        "province": "天津市",
                        "city": "天津市",
                        "county": "汉沽区",

                    },
                    {
                        "cityCode": "120221",
                        "cityName": "宁河县",
                        "parentId": "120000",
                        "provinceType": "3",
                        "province": "天津市",
                        "city": "天津市",
                        "county": "宁河县",

                    },
                    {
                        "cityCode": "120223",
                        "cityName": "静海县",
                        "parentId": "120000",
                        "provinceType": "3",
                        "province": "天津市",
                        "city": "天津市",
                        "county": "静海县",

                    }
                ]
            },
            {
                "cityCode": "130000",
                "cityName": "河北省",
                "parentId": "100000",
                "provinceType": "1",
                "province": "河北省",
                "city": "",
                "county": "",
                "counties": [
                    {
                        "cityCode": "130100",
                        "cityName": "石家庄市",
                        "parentId": "130000",
                        "provinceType": "2",
                        "province": "河北省",
                        "city": "石家庄市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "130101",
                                "cityName": "石家庄市",
                                "parentId": "130100",
                                "provinceType": "2",
                                "province": "河北省",
                                "city": "石家庄市",
                                "county": "",

                            },
                            {
                                "cityCode": "130102",
                                "cityName": "长安区",
                                "parentId": "130100",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "石家庄市",
                                "county": "长安区",

                            },
                            {
                                "cityCode": "130103",
                                "cityName": "桥东区",
                                "parentId": "130100",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "石家庄市",
                                "county": "桥东区",

                            },
                            {
                                "cityCode": "130104",
                                "cityName": "桥西区",
                                "parentId": "130100",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "石家庄市",
                                "county": "桥西区",

                            },
                            {
                                "cityCode": "130105",
                                "cityName": "新华区",
                                "parentId": "130100",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "石家庄市",
                                "county": "新华区",

                            },
                            {
                                "cityCode": "130107",
                                "cityName": "井陉矿区",
                                "parentId": "130100",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "石家庄市",
                                "county": "井陉矿区",

                            },
                            {
                                "cityCode": "130108",
                                "cityName": "裕华区",
                                "parentId": "130100",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "石家庄市",
                                "county": "裕华区",

                            },
                            {
                                "cityCode": "130109",
                                "cityName": "藁城区",
                                "parentId": "130100",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "石家庄市",
                                "county": "藁城区",

                            },
                            {
                                "cityCode": "130110",
                                "cityName": "鹿泉区",
                                "parentId": "130100",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "石家庄市",
                                "county": "鹿泉区",

                            },
                            {
                                "cityCode": "130111",
                                "cityName": "栾城区",
                                "parentId": "130100",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "石家庄市",
                                "county": "栾城区",

                            },
                            {
                                "cityCode": "130121",
                                "cityName": "井陉县",
                                "parentId": "130100",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "石家庄市",
                                "county": "井陉县",

                            },
                            {
                                "cityCode": "130123",
                                "cityName": "正定县",
                                "parentId": "130100",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "石家庄市",
                                "county": "正定县",

                            },
                            {
                                "cityCode": "130124",
                                "cityName": "栾城县",
                                "parentId": "130100",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "石家庄市",
                                "county": "栾城县",

                            },
                            {
                                "cityCode": "130125",
                                "cityName": "行唐县",
                                "parentId": "130100",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "石家庄市",
                                "county": "行唐县",

                            },
                            {
                                "cityCode": "130126",
                                "cityName": "灵寿县",
                                "parentId": "130100",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "石家庄市",
                                "county": "灵寿县",

                            },
                            {
                                "cityCode": "130127",
                                "cityName": "高邑县",
                                "parentId": "130100",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "石家庄市",
                                "county": "高邑县",

                            },
                            {
                                "cityCode": "130128",
                                "cityName": "深泽县",
                                "parentId": "130100",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "石家庄市",
                                "county": "深泽县",

                            },
                            {
                                "cityCode": "130129",
                                "cityName": "赞皇县",
                                "parentId": "130100",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "石家庄市",
                                "county": "赞皇县",

                            },
                            {
                                "cityCode": "130130",
                                "cityName": "无极县",
                                "parentId": "130100",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "石家庄市",
                                "county": "无极县",

                            },
                            {
                                "cityCode": "130131",
                                "cityName": "平山县",
                                "parentId": "130100",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "石家庄市",
                                "county": "平山县",

                            },
                            {
                                "cityCode": "130132",
                                "cityName": "元氏县",
                                "parentId": "130100",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "石家庄市",
                                "county": "元氏县",

                            },
                            {
                                "cityCode": "130133",
                                "cityName": "赵县",
                                "parentId": "130100",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "石家庄市",
                                "county": "赵县",

                            },
                            {
                                "cityCode": "130171",
                                "cityName": "石家庄高新技术产业开发区",
                                "parentId": "130100",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "石家庄市",
                                "county": "石家庄高新技术产业开发区",

                            },
                            {
                                "cityCode": "130172",
                                "cityName": "石家庄循环化工园区",
                                "parentId": "130100",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "石家庄市",
                                "county": "石家庄循环化工园区",

                            },
                            {
                                "cityCode": "130181",
                                "cityName": "辛集市",
                                "parentId": "130100",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "石家庄市",
                                "county": "辛集市",

                            },
                            {
                                "cityCode": "130182",
                                "cityName": "藁城市",
                                "parentId": "130100",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "石家庄市",
                                "county": "藁城市",

                            },
                            {
                                "cityCode": "130183",
                                "cityName": "晋州市",
                                "parentId": "130100",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "石家庄市",
                                "county": "晋州市",

                            },
                            {
                                "cityCode": "130184",
                                "cityName": "新乐市",
                                "parentId": "130100",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "石家庄市",
                                "county": "新乐市",

                            },
                            {
                                "cityCode": "130185",
                                "cityName": "高新区",
                                "parentId": "130100",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "石家庄市",
                                "county": "高新区",

                            },
                            {
                                "cityCode": "130186",
                                "cityName": "经济技术开发区",
                                "parentId": "130100",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "石家庄市",
                                "county": "经济技术开发区",

                            }
                        ]
                    },
                    {
                        "cityCode": "130200",
                        "cityName": "唐山市",
                        "parentId": "130000",
                        "provinceType": "2",
                        "province": "河北省",
                        "city": "唐山市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "130201",
                                "cityName": "唐山市",
                                "parentId": "130200",
                                "provinceType": "2",
                                "province": "河北省",
                                "city": "唐山市",
                                "county": "",

                            },
                            {
                                "cityCode": "130202",
                                "cityName": "路南区",
                                "parentId": "130200",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "唐山市",
                                "county": "路南区",

                            },
                            {
                                "cityCode": "130203",
                                "cityName": "路北区",
                                "parentId": "130200",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "唐山市",
                                "county": "路北区",

                            },
                            {
                                "cityCode": "130204",
                                "cityName": "古冶区",
                                "parentId": "130200",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "唐山市",
                                "county": "古冶区",

                            },
                            {
                                "cityCode": "130205",
                                "cityName": "开平区",
                                "parentId": "130200",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "唐山市",
                                "county": "开平区",

                            },
                            {
                                "cityCode": "130207",
                                "cityName": "丰南区",
                                "parentId": "130200",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "唐山市",
                                "county": "丰南区",

                            },
                            {
                                "cityCode": "130208",
                                "cityName": "丰润区",
                                "parentId": "130200",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "唐山市",
                                "county": "丰润区",

                            },
                            {
                                "cityCode": "130209",
                                "cityName": "曹妃甸区",
                                "parentId": "130200",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "唐山市",
                                "county": "曹妃甸区",

                            },
                            {
                                "cityCode": "130223",
                                "cityName": "滦县",
                                "parentId": "130200",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "唐山市",
                                "county": "滦县",

                            },
                            {
                                "cityCode": "130224",
                                "cityName": "滦南县",
                                "parentId": "130200",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "唐山市",
                                "county": "滦南县",

                            },
                            {
                                "cityCode": "130225",
                                "cityName": "乐亭县",
                                "parentId": "130200",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "唐山市",
                                "county": "乐亭县",

                            },
                            {
                                "cityCode": "130227",
                                "cityName": "迁西县",
                                "parentId": "130200",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "唐山市",
                                "county": "迁西县",

                            },
                            {
                                "cityCode": "130229",
                                "cityName": "玉田县",
                                "parentId": "130200",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "唐山市",
                                "county": "玉田县",

                            },
                            {
                                "cityCode": "130230",
                                "cityName": "曹妃甸区",
                                "parentId": "130200",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "唐山市",
                                "county": "曹妃甸区",

                            },
                            {
                                "cityCode": "130271",
                                "cityName": "唐山市芦台经济技术开发区",
                                "parentId": "130200",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "唐山市",
                                "county": "唐山市芦台经济技术开发区",

                            },
                            {
                                "cityCode": "130272",
                                "cityName": "唐山市汉沽管理区",
                                "parentId": "130200",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "唐山市",
                                "county": "唐山市汉沽管理区",

                            },
                            {
                                "cityCode": "130273",
                                "cityName": "唐山高新技术产业开发区",
                                "parentId": "130200",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "唐山市",
                                "county": "唐山高新技术产业开发区",

                            },
                            {
                                "cityCode": "130274",
                                "cityName": "河北唐山海港经济开发区",
                                "parentId": "130200",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "唐山市",
                                "county": "河北唐山海港经济开发区",

                            },
                            {
                                "cityCode": "130281",
                                "cityName": "遵化市",
                                "parentId": "130200",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "唐山市",
                                "county": "遵化市",

                            },
                            {
                                "cityCode": "130283",
                                "cityName": "迁安市",
                                "parentId": "130200",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "唐山市",
                                "county": "迁安市",

                            },
                            {
                                "cityCode": "130284",
                                "cityName": "滦州市",
                                "parentId": "130200",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "唐山市",
                                "county": "滦州市",

                            }
                        ]
                    },
                    {
                        "cityCode": "130300",
                        "cityName": "秦皇岛市",
                        "parentId": "130000",
                        "provinceType": "2",
                        "province": "河北省",
                        "city": "秦皇岛市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "130301",
                                "cityName": "秦皇岛市",
                                "parentId": "130300",
                                "provinceType": "2",
                                "province": "河北省",
                                "city": "秦皇岛市",
                                "county": "",

                            },
                            {
                                "cityCode": "130302",
                                "cityName": "海港区",
                                "parentId": "130300",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "秦皇岛市",
                                "county": "海港区",

                            },
                            {
                                "cityCode": "130303",
                                "cityName": "山海关区",
                                "parentId": "130300",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "秦皇岛市",
                                "county": "山海关区",

                            },
                            {
                                "cityCode": "130304",
                                "cityName": "北戴河区",
                                "parentId": "130300",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "秦皇岛市",
                                "county": "北戴河区",

                            },
                            {
                                "cityCode": "130305",
                                "cityName": "经济技术开发区",
                                "parentId": "130300",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "秦皇岛市",
                                "county": "经济技术开发区",

                            },
                            {
                                "cityCode": "130306",
                                "cityName": "抚宁区",
                                "parentId": "130300",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "秦皇岛市",
                                "county": "抚宁区",

                            },
                            {
                                "cityCode": "130321",
                                "cityName": "青龙满族自治县",
                                "parentId": "130300",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "秦皇岛市",
                                "county": "青龙满族自治县",

                            },
                            {
                                "cityCode": "130322",
                                "cityName": "昌黎县",
                                "parentId": "130300",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "秦皇岛市",
                                "county": "昌黎县",

                            },
                            {
                                "cityCode": "130324",
                                "cityName": "卢龙县",
                                "parentId": "130300",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "秦皇岛市",
                                "county": "卢龙县",

                            },
                            {
                                "cityCode": "130325",
                                "cityName": "北戴河新区",
                                "parentId": "130300",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "秦皇岛市",
                                "county": "北戴河新区",

                            },
                            {
                                "cityCode": "130371",
                                "cityName": "秦皇岛市经济技术开发区",
                                "parentId": "130300",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "秦皇岛市",
                                "county": "秦皇岛市经济技术开发区",

                            },
                            {
                                "cityCode": "130372",
                                "cityName": "北戴河新区",
                                "parentId": "130300",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "秦皇岛市",
                                "county": "北戴河新区",

                            }
                        ]
                    },
                    {
                        "cityCode": "130400",
                        "cityName": "邯郸市",
                        "parentId": "130000",
                        "provinceType": "2",
                        "province": "河北省",
                        "city": "邯郸市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "130401",
                                "cityName": "邯郸市",
                                "parentId": "130400",
                                "provinceType": "2",
                                "province": "河北省",
                                "city": "邯郸市",
                                "county": "",

                            },
                            {
                                "cityCode": "130402",
                                "cityName": "邯山区",
                                "parentId": "130400",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "邯郸市",
                                "county": "邯山区",

                            },
                            {
                                "cityCode": "130403",
                                "cityName": "丛台区",
                                "parentId": "130400",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "邯郸市",
                                "county": "丛台区",

                            },
                            {
                                "cityCode": "130404",
                                "cityName": "复兴区",
                                "parentId": "130400",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "邯郸市",
                                "county": "复兴区",

                            },
                            {
                                "cityCode": "130406",
                                "cityName": "峰峰矿区",
                                "parentId": "130400",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "邯郸市",
                                "county": "峰峰矿区",

                            },
                            {
                                "cityCode": "130407",
                                "cityName": "肥乡区",
                                "parentId": "130400",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "邯郸市",
                                "county": "肥乡区",

                            },
                            {
                                "cityCode": "130408",
                                "cityName": "永年区",
                                "parentId": "130400",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "邯郸市",
                                "county": "永年区",

                            },
                            {
                                "cityCode": "130421",
                                "cityName": "邯郸县",
                                "parentId": "130400",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "邯郸市",
                                "county": "邯郸县",

                            },
                            {
                                "cityCode": "130423",
                                "cityName": "临漳县",
                                "parentId": "130400",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "邯郸市",
                                "county": "临漳县",

                            },
                            {
                                "cityCode": "130424",
                                "cityName": "成安县",
                                "parentId": "130400",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "邯郸市",
                                "county": "成安县",

                            },
                            {
                                "cityCode": "130425",
                                "cityName": "大名县",
                                "parentId": "130400",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "邯郸市",
                                "county": "大名县",

                            },
                            {
                                "cityCode": "130426",
                                "cityName": "涉县",
                                "parentId": "130400",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "邯郸市",
                                "county": "涉县",

                            },
                            {
                                "cityCode": "130427",
                                "cityName": "磁县",
                                "parentId": "130400",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "邯郸市",
                                "county": "磁县",

                            },
                            {
                                "cityCode": "130428",
                                "cityName": "肥乡区",
                                "parentId": "130400",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "邯郸市",
                                "county": "肥乡区",

                            },
                            {
                                "cityCode": "130429",
                                "cityName": "永年区",
                                "parentId": "130400",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "邯郸市",
                                "county": "永年区",

                            },
                            {
                                "cityCode": "130430",
                                "cityName": "邱县",
                                "parentId": "130400",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "邯郸市",
                                "county": "邱县",

                            },
                            {
                                "cityCode": "130431",
                                "cityName": "鸡泽县",
                                "parentId": "130400",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "邯郸市",
                                "county": "鸡泽县",

                            },
                            {
                                "cityCode": "130432",
                                "cityName": "广平县",
                                "parentId": "130400",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "邯郸市",
                                "county": "广平县",

                            },
                            {
                                "cityCode": "130433",
                                "cityName": "馆陶县",
                                "parentId": "130400",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "邯郸市",
                                "county": "馆陶县",

                            },
                            {
                                "cityCode": "130434",
                                "cityName": "魏县",
                                "parentId": "130400",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "邯郸市",
                                "county": "魏县",

                            },
                            {
                                "cityCode": "130435",
                                "cityName": "曲周县",
                                "parentId": "130400",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "邯郸市",
                                "county": "曲周县",

                            },
                            {
                                "cityCode": "130471",
                                "cityName": "邯郸经济技术开发区",
                                "parentId": "130400",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "邯郸市",
                                "county": "邯郸经济技术开发区",

                            },
                            {
                                "cityCode": "130473",
                                "cityName": "邯郸冀南新区",
                                "parentId": "130400",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "邯郸市",
                                "county": "邯郸冀南新区",

                            },
                            {
                                "cityCode": "130481",
                                "cityName": "武安市",
                                "parentId": "130400",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "邯郸市",
                                "county": "武安市",

                            },
                            {
                                "cityCode": "130482",
                                "cityName": "高新技术产业开发区",
                                "parentId": "130400",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "邯郸市",
                                "county": "高新技术产业开发区",

                            }
                        ]
                    },
                    {
                        "cityCode": "130500",
                        "cityName": "邢台市",
                        "parentId": "130000",
                        "provinceType": "2",
                        "province": "河北省",
                        "city": "邢台市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "130501",
                                "cityName": "邢台市",
                                "parentId": "130500",
                                "provinceType": "2",
                                "province": "河北省",
                                "city": "邢台市",
                                "county": "",

                            },
                            {
                                "cityCode": "130502",
                                "cityName": "襄都区",
                                "parentId": "130500",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "邢台市",
                                "county": "襄都区",

                            },
                            {
                                "cityCode": "130503",
                                "cityName": "信都区",
                                "parentId": "130500",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "邢台市",
                                "county": "信都区",

                            },
                            {
                                "cityCode": "130505",
                                "cityName": "任泽区",
                                "parentId": "130500",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "邢台市",
                                "county": "任泽区",

                            },
                            {
                                "cityCode": "130506",
                                "cityName": "南和区",
                                "parentId": "130500",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "邢台市",
                                "county": "南和区",

                            },
                            {
                                "cityCode": "130521",
                                "cityName": "邢台县",
                                "parentId": "130500",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "邢台市",
                                "county": "邢台县",

                            },
                            {
                                "cityCode": "130522",
                                "cityName": "临城县",
                                "parentId": "130500",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "邢台市",
                                "county": "临城县",

                            },
                            {
                                "cityCode": "130523",
                                "cityName": "内丘县",
                                "parentId": "130500",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "邢台市",
                                "county": "内丘县",

                            },
                            {
                                "cityCode": "130524",
                                "cityName": "柏乡县",
                                "parentId": "130500",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "邢台市",
                                "county": "柏乡县",

                            },
                            {
                                "cityCode": "130525",
                                "cityName": "隆尧县",
                                "parentId": "130500",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "邢台市",
                                "county": "隆尧县",

                            },
                            {
                                "cityCode": "130526",
                                "cityName": "任县",
                                "parentId": "130500",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "邢台市",
                                "county": "任县",

                            },
                            {
                                "cityCode": "130527",
                                "cityName": "南和县",
                                "parentId": "130500",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "邢台市",
                                "county": "南和县",

                            },
                            {
                                "cityCode": "130528",
                                "cityName": "宁晋县",
                                "parentId": "130500",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "邢台市",
                                "county": "宁晋县",

                            },
                            {
                                "cityCode": "130529",
                                "cityName": "巨鹿县",
                                "parentId": "130500",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "邢台市",
                                "county": "巨鹿县",

                            },
                            {
                                "cityCode": "130530",
                                "cityName": "新河县",
                                "parentId": "130500",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "邢台市",
                                "county": "新河县",

                            },
                            {
                                "cityCode": "130531",
                                "cityName": "广宗县",
                                "parentId": "130500",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "邢台市",
                                "county": "广宗县",

                            },
                            {
                                "cityCode": "130532",
                                "cityName": "平乡县",
                                "parentId": "130500",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "邢台市",
                                "county": "平乡县",

                            },
                            {
                                "cityCode": "130533",
                                "cityName": "威县",
                                "parentId": "130500",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "邢台市",
                                "county": "威县",

                            },
                            {
                                "cityCode": "130534",
                                "cityName": "清河县",
                                "parentId": "130500",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "邢台市",
                                "county": "清河县",

                            },
                            {
                                "cityCode": "130535",
                                "cityName": "临西县",
                                "parentId": "130500",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "邢台市",
                                "county": "临西县",

                            },
                            {
                                "cityCode": "130571",
                                "cityName": "河北邢台经济开发区",
                                "parentId": "130500",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "邢台市",
                                "county": "河北邢台经济开发区",

                            },
                            {
                                "cityCode": "130581",
                                "cityName": "南宫市",
                                "parentId": "130500",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "邢台市",
                                "county": "南宫市",

                            },
                            {
                                "cityCode": "130582",
                                "cityName": "沙河市",
                                "parentId": "130500",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "邢台市",
                                "county": "沙河市",

                            }
                        ]
                    },
                    {
                        "cityCode": "130600",
                        "cityName": "保定市",
                        "parentId": "130000",
                        "provinceType": "2",
                        "province": "河北省",
                        "city": "保定市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "130601",
                                "cityName": "保定市",
                                "parentId": "130600",
                                "provinceType": "2",
                                "province": "河北省",
                                "city": "保定市",
                                "county": "",

                            },
                            {
                                "cityCode": "130602",
                                "cityName": "竞秀区",
                                "parentId": "130600",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "保定市",
                                "county": "竞秀区",

                            },
                            {
                                "cityCode": "130603",
                                "cityName": "北市区",
                                "parentId": "130600",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "保定市",
                                "county": "北市区",

                            },
                            {
                                "cityCode": "130604",
                                "cityName": "南市区",
                                "parentId": "130600",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "保定市",
                                "county": "南市区",

                            },
                            {
                                "cityCode": "130606",
                                "cityName": "莲池区",
                                "parentId": "130600",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "保定市",
                                "county": "莲池区",

                            },
                            {
                                "cityCode": "130607",
                                "cityName": "满城区",
                                "parentId": "130600",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "保定市",
                                "county": "满城区",

                            },
                            {
                                "cityCode": "130608",
                                "cityName": "清苑区",
                                "parentId": "130600",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "保定市",
                                "county": "清苑区",

                            },
                            {
                                "cityCode": "130609",
                                "cityName": "徐水区",
                                "parentId": "130600",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "保定市",
                                "county": "徐水区",

                            },
                            {
                                "cityCode": "130621",
                                "cityName": "满城县",
                                "parentId": "130600",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "保定市",
                                "county": "满城县",

                            },
                            {
                                "cityCode": "130622",
                                "cityName": "清苑县",
                                "parentId": "130600",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "保定市",
                                "county": "清苑县",

                            },
                            {
                                "cityCode": "130623",
                                "cityName": "涞水县",
                                "parentId": "130600",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "保定市",
                                "county": "涞水县",

                            },
                            {
                                "cityCode": "130624",
                                "cityName": "阜平县",
                                "parentId": "130600",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "保定市",
                                "county": "阜平县",

                            },
                            {
                                "cityCode": "130625",
                                "cityName": "徐水县",
                                "parentId": "130600",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "保定市",
                                "county": "徐水县",

                            },
                            {
                                "cityCode": "130626",
                                "cityName": "定兴县",
                                "parentId": "130600",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "保定市",
                                "county": "定兴县",

                            },
                            {
                                "cityCode": "130627",
                                "cityName": "唐县",
                                "parentId": "130600",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "保定市",
                                "county": "唐县",

                            },
                            {
                                "cityCode": "130628",
                                "cityName": "高阳县",
                                "parentId": "130600",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "保定市",
                                "county": "高阳县",

                            },
                            {
                                "cityCode": "130629",
                                "cityName": "容城县",
                                "parentId": "130600",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "保定市",
                                "county": "容城县",

                            },
                            {
                                "cityCode": "130630",
                                "cityName": "涞源县",
                                "parentId": "130600",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "保定市",
                                "county": "涞源县",

                            },
                            {
                                "cityCode": "130631",
                                "cityName": "望都县",
                                "parentId": "130600",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "保定市",
                                "county": "望都县",

                            },
                            {
                                "cityCode": "130632",
                                "cityName": "安新县",
                                "parentId": "130600",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "保定市",
                                "county": "安新县",

                            },
                            {
                                "cityCode": "130633",
                                "cityName": "易县",
                                "parentId": "130600",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "保定市",
                                "county": "易县",

                            },
                            {
                                "cityCode": "130634",
                                "cityName": "曲阳县",
                                "parentId": "130600",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "保定市",
                                "county": "曲阳县",

                            },
                            {
                                "cityCode": "130635",
                                "cityName": "蠡县",
                                "parentId": "130600",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "保定市",
                                "county": "蠡县",

                            },
                            {
                                "cityCode": "130636",
                                "cityName": "顺平县",
                                "parentId": "130600",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "保定市",
                                "county": "顺平县",

                            },
                            {
                                "cityCode": "130637",
                                "cityName": "博野县",
                                "parentId": "130600",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "保定市",
                                "county": "博野县",

                            },
                            {
                                "cityCode": "130638",
                                "cityName": "雄县",
                                "parentId": "130600",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "保定市",
                                "county": "雄县",

                            },
                            {
                                "cityCode": "130671",
                                "cityName": "保定高新技术产业开发区",
                                "parentId": "130600",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "保定市",
                                "county": "保定高新技术产业开发区",

                            },
                            {
                                "cityCode": "130672",
                                "cityName": "保定白沟新城",
                                "parentId": "130600",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "保定市",
                                "county": "保定白沟新城",

                            },
                            {
                                "cityCode": "130681",
                                "cityName": "涿州市",
                                "parentId": "130600",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "保定市",
                                "county": "涿州市",

                            },
                            {
                                "cityCode": "130682",
                                "cityName": "定州市",
                                "parentId": "130600",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "保定市",
                                "county": "定州市",

                            },
                            {
                                "cityCode": "130683",
                                "cityName": "安国市",
                                "parentId": "130600",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "保定市",
                                "county": "安国市",

                            },
                            {
                                "cityCode": "130684",
                                "cityName": "高碑店市",
                                "parentId": "130600",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "保定市",
                                "county": "高碑店市",

                            }
                        ]
                    },
                    {
                        "cityCode": "130700",
                        "cityName": "张家口市",
                        "parentId": "130000",
                        "provinceType": "2",
                        "province": "河北省",
                        "city": "张家口市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "130701",
                                "cityName": "张家口市",
                                "parentId": "130700",
                                "provinceType": "2",
                                "province": "河北省",
                                "city": "张家口市",
                                "county": "",

                            },
                            {
                                "cityCode": "130702",
                                "cityName": "桥东区",
                                "parentId": "130700",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "张家口市",
                                "county": "桥东区",

                            },
                            {
                                "cityCode": "130703",
                                "cityName": "桥西区",
                                "parentId": "130700",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "张家口市",
                                "county": "桥西区",

                            },
                            {
                                "cityCode": "130705",
                                "cityName": "宣化区",
                                "parentId": "130700",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "张家口市",
                                "county": "宣化区",

                            },
                            {
                                "cityCode": "130706",
                                "cityName": "下花园区",
                                "parentId": "130700",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "张家口市",
                                "county": "下花园区",

                            },
                            {
                                "cityCode": "130708",
                                "cityName": "万全区",
                                "parentId": "130700",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "张家口市",
                                "county": "万全区",

                            },
                            {
                                "cityCode": "130709",
                                "cityName": "崇礼区",
                                "parentId": "130700",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "张家口市",
                                "county": "崇礼区",

                            },
                            {
                                "cityCode": "130721",
                                "cityName": "宣化县",
                                "parentId": "130700",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "张家口市",
                                "county": "宣化县",

                            },
                            {
                                "cityCode": "130722",
                                "cityName": "张北县",
                                "parentId": "130700",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "张家口市",
                                "county": "张北县",

                            },
                            {
                                "cityCode": "130723",
                                "cityName": "康保县",
                                "parentId": "130700",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "张家口市",
                                "county": "康保县",

                            },
                            {
                                "cityCode": "130724",
                                "cityName": "沽源县",
                                "parentId": "130700",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "张家口市",
                                "county": "沽源县",

                            },
                            {
                                "cityCode": "130725",
                                "cityName": "尚义县",
                                "parentId": "130700",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "张家口市",
                                "county": "尚义县",

                            },
                            {
                                "cityCode": "130726",
                                "cityName": "蔚县",
                                "parentId": "130700",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "张家口市",
                                "county": "蔚县",

                            },
                            {
                                "cityCode": "130727",
                                "cityName": "阳原县",
                                "parentId": "130700",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "张家口市",
                                "county": "阳原县",

                            },
                            {
                                "cityCode": "130728",
                                "cityName": "怀安县",
                                "parentId": "130700",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "张家口市",
                                "county": "怀安县",

                            },
                            {
                                "cityCode": "130729",
                                "cityName": "万全县",
                                "parentId": "130700",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "张家口市",
                                "county": "万全县",

                            },
                            {
                                "cityCode": "130730",
                                "cityName": "怀来县",
                                "parentId": "130700",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "张家口市",
                                "county": "怀来县",

                            },
                            {
                                "cityCode": "130731",
                                "cityName": "涿鹿县",
                                "parentId": "130700",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "张家口市",
                                "county": "涿鹿县",

                            },
                            {
                                "cityCode": "130732",
                                "cityName": "赤城县",
                                "parentId": "130700",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "张家口市",
                                "county": "赤城县",

                            },
                            {
                                "cityCode": "130733",
                                "cityName": "崇礼县",
                                "parentId": "130700",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "张家口市",
                                "county": "崇礼县",

                            },
                            {
                                "cityCode": "130771",
                                "cityName": "张家口市高新技术产业开发区",
                                "parentId": "130700",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "张家口市",
                                "county": "张家口市高新技术产业开发区",

                            },
                            {
                                "cityCode": "130772",
                                "cityName": "张家口市察北管理区",
                                "parentId": "130700",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "张家口市",
                                "county": "张家口市察北管理区",

                            },
                            {
                                "cityCode": "130773",
                                "cityName": "张家口市塞北管理区",
                                "parentId": "130700",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "张家口市",
                                "county": "张家口市塞北管理区",

                            }
                        ]
                    },
                    {
                        "cityCode": "130800",
                        "cityName": "承德市",
                        "parentId": "130000",
                        "provinceType": "2",
                        "province": "河北省",
                        "city": "承德市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "130801",
                                "cityName": "承德市",
                                "parentId": "130800",
                                "provinceType": "2",
                                "province": "河北省",
                                "city": "承德市",
                                "county": "",

                            },
                            {
                                "cityCode": "130802",
                                "cityName": "双桥区",
                                "parentId": "130800",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "承德市",
                                "county": "双桥区",

                            },
                            {
                                "cityCode": "130803",
                                "cityName": "双滦区",
                                "parentId": "130800",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "承德市",
                                "county": "双滦区",

                            },
                            {
                                "cityCode": "130804",
                                "cityName": "鹰手营子矿区",
                                "parentId": "130800",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "承德市",
                                "county": "鹰手营子矿区",

                            },
                            {
                                "cityCode": "130821",
                                "cityName": "承德县",
                                "parentId": "130800",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "承德市",
                                "county": "承德县",

                            },
                            {
                                "cityCode": "130822",
                                "cityName": "兴隆县",
                                "parentId": "130800",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "承德市",
                                "county": "兴隆县",

                            },
                            {
                                "cityCode": "130823",
                                "cityName": "平泉县",
                                "parentId": "130800",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "承德市",
                                "county": "平泉县",

                            },
                            {
                                "cityCode": "130824",
                                "cityName": "滦平县",
                                "parentId": "130800",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "承德市",
                                "county": "滦平县",

                            },
                            {
                                "cityCode": "130825",
                                "cityName": "隆化县",
                                "parentId": "130800",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "承德市",
                                "county": "隆化县",

                            },
                            {
                                "cityCode": "130826",
                                "cityName": "丰宁满族自治县",
                                "parentId": "130800",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "承德市",
                                "county": "丰宁满族自治县",

                            },
                            {
                                "cityCode": "130827",
                                "cityName": "宽城满族自治县",
                                "parentId": "130800",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "承德市",
                                "county": "宽城满族自治县",

                            },
                            {
                                "cityCode": "130828",
                                "cityName": "围场满族蒙古族自治县",
                                "parentId": "130800",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "承德市",
                                "county": "围场满族蒙古族自治县",

                            },
                            {
                                "cityCode": "130871",
                                "cityName": "承德高新技术产业开发区",
                                "parentId": "130800",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "承德市",
                                "county": "承德高新技术产业开发区",

                            },
                            {
                                "cityCode": "130881",
                                "cityName": "平泉市",
                                "parentId": "130800",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "承德市",
                                "county": "平泉市",

                            }
                        ]
                    },
                    {
                        "cityCode": "130900",
                        "cityName": "沧州市",
                        "parentId": "130000",
                        "provinceType": "2",
                        "province": "河北省",
                        "city": "沧州市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "130901",
                                "cityName": "沧州市",
                                "parentId": "130900",
                                "provinceType": "2",
                                "province": "河北省",
                                "city": "沧州市",
                                "county": "",

                            },
                            {
                                "cityCode": "130902",
                                "cityName": "新华区",
                                "parentId": "130900",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "沧州市",
                                "county": "新华区",

                            },
                            {
                                "cityCode": "130903",
                                "cityName": "运河区",
                                "parentId": "130900",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "沧州市",
                                "county": "运河区",

                            },
                            {
                                "cityCode": "130921",
                                "cityName": "沧县",
                                "parentId": "130900",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "沧州市",
                                "county": "沧县",

                            },
                            {
                                "cityCode": "130922",
                                "cityName": "青县",
                                "parentId": "130900",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "沧州市",
                                "county": "青县",

                            },
                            {
                                "cityCode": "130923",
                                "cityName": "东光县",
                                "parentId": "130900",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "沧州市",
                                "county": "东光县",

                            },
                            {
                                "cityCode": "130924",
                                "cityName": "海兴县",
                                "parentId": "130900",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "沧州市",
                                "county": "海兴县",

                            },
                            {
                                "cityCode": "130925",
                                "cityName": "盐山县",
                                "parentId": "130900",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "沧州市",
                                "county": "盐山县",

                            },
                            {
                                "cityCode": "130926",
                                "cityName": "肃宁县",
                                "parentId": "130900",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "沧州市",
                                "county": "肃宁县",

                            },
                            {
                                "cityCode": "130927",
                                "cityName": "南皮县",
                                "parentId": "130900",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "沧州市",
                                "county": "南皮县",

                            },
                            {
                                "cityCode": "130928",
                                "cityName": "吴桥县",
                                "parentId": "130900",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "沧州市",
                                "county": "吴桥县",

                            },
                            {
                                "cityCode": "130929",
                                "cityName": "献县",
                                "parentId": "130900",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "沧州市",
                                "county": "献县",

                            },
                            {
                                "cityCode": "130930",
                                "cityName": "孟村回族自治县",
                                "parentId": "130900",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "沧州市",
                                "county": "孟村回族自治县",

                            },
                            {
                                "cityCode": "130971",
                                "cityName": "河北沧州经济开发区",
                                "parentId": "130900",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "沧州市",
                                "county": "河北沧州经济开发区",

                            },
                            {
                                "cityCode": "130972",
                                "cityName": "沧州高新技术产业开发区",
                                "parentId": "130900",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "沧州市",
                                "county": "沧州高新技术产业开发区",

                            },
                            {
                                "cityCode": "130973",
                                "cityName": "沧州渤海新区",
                                "parentId": "130900",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "沧州市",
                                "county": "沧州渤海新区",

                            },
                            {
                                "cityCode": "130981",
                                "cityName": "泊头市",
                                "parentId": "130900",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "沧州市",
                                "county": "泊头市",

                            },
                            {
                                "cityCode": "130982",
                                "cityName": "任丘市",
                                "parentId": "130900",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "沧州市",
                                "county": "任丘市",

                            },
                            {
                                "cityCode": "130983",
                                "cityName": "黄骅市",
                                "parentId": "130900",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "沧州市",
                                "county": "黄骅市",

                            },
                            {
                                "cityCode": "130984",
                                "cityName": "河间市",
                                "parentId": "130900",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "沧州市",
                                "county": "河间市",

                            },
                            {
                                "cityCode": "130985",
                                "cityName": "渤海新区",
                                "parentId": "130900",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "沧州市",
                                "county": "渤海新区",

                            },
                            {
                                "cityCode": "130986",
                                "cityName": "临港经济技术开发区",
                                "parentId": "130900",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "沧州市",
                                "county": "临港经济技术开发区",

                            }
                        ]
                    },
                    {
                        "cityCode": "131000",
                        "cityName": "廊坊市",
                        "parentId": "130000",
                        "provinceType": "2",
                        "province": "河北省",
                        "city": "廊坊市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "131001",
                                "cityName": "廊坊市",
                                "parentId": "131000",
                                "provinceType": "2",
                                "province": "河北省",
                                "city": "廊坊市",
                                "county": "",

                            },
                            {
                                "cityCode": "131002",
                                "cityName": "安次区",
                                "parentId": "131000",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "廊坊市",
                                "county": "安次区",

                            },
                            {
                                "cityCode": "131003",
                                "cityName": "广阳区",
                                "parentId": "131000",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "廊坊市",
                                "county": "广阳区",

                            },
                            {
                                "cityCode": "131022",
                                "cityName": "固安县",
                                "parentId": "131000",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "廊坊市",
                                "county": "固安县",

                            },
                            {
                                "cityCode": "131023",
                                "cityName": "永清县",
                                "parentId": "131000",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "廊坊市",
                                "county": "永清县",

                            },
                            {
                                "cityCode": "131024",
                                "cityName": "香河县",
                                "parentId": "131000",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "廊坊市",
                                "county": "香河县",

                            },
                            {
                                "cityCode": "131025",
                                "cityName": "大城县",
                                "parentId": "131000",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "廊坊市",
                                "county": "大城县",

                            },
                            {
                                "cityCode": "131026",
                                "cityName": "文安县",
                                "parentId": "131000",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "廊坊市",
                                "county": "文安县",

                            },
                            {
                                "cityCode": "131028",
                                "cityName": "大厂回族自治县",
                                "parentId": "131000",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "廊坊市",
                                "county": "大厂回族自治县",

                            },
                            {
                                "cityCode": "131071",
                                "cityName": "经济技术开发区",
                                "parentId": "131000",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "廊坊市",
                                "county": "经济技术开发区",

                            },
                            {
                                "cityCode": "131081",
                                "cityName": "霸州市",
                                "parentId": "131000",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "廊坊市",
                                "county": "霸州市",

                            },
                            {
                                "cityCode": "131082",
                                "cityName": "三河市",
                                "parentId": "131000",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "廊坊市",
                                "county": "三河市",

                            }
                        ]
                    },
                    {
                        "cityCode": "131100",
                        "cityName": "衡水市",
                        "parentId": "130000",
                        "provinceType": "2",
                        "province": "河北省",
                        "city": "衡水市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "131101",
                                "cityName": "衡水市",
                                "parentId": "131100",
                                "provinceType": "2",
                                "province": "河北省",
                                "city": "衡水市",
                                "county": "",

                            },
                            {
                                "cityCode": "131102",
                                "cityName": "桃城区",
                                "parentId": "131100",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "衡水市",
                                "county": "桃城区",

                            },
                            {
                                "cityCode": "131103",
                                "cityName": "冀州区",
                                "parentId": "131100",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "衡水市",
                                "county": "冀州区",

                            },
                            {
                                "cityCode": "131121",
                                "cityName": "枣强县",
                                "parentId": "131100",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "衡水市",
                                "county": "枣强县",

                            },
                            {
                                "cityCode": "131122",
                                "cityName": "武邑县",
                                "parentId": "131100",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "衡水市",
                                "county": "武邑县",

                            },
                            {
                                "cityCode": "131123",
                                "cityName": "武强县",
                                "parentId": "131100",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "衡水市",
                                "county": "武强县",

                            },
                            {
                                "cityCode": "131124",
                                "cityName": "饶阳县",
                                "parentId": "131100",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "衡水市",
                                "county": "饶阳县",

                            },
                            {
                                "cityCode": "131125",
                                "cityName": "安平县",
                                "parentId": "131100",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "衡水市",
                                "county": "安平县",

                            },
                            {
                                "cityCode": "131126",
                                "cityName": "故城县",
                                "parentId": "131100",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "衡水市",
                                "county": "故城县",

                            },
                            {
                                "cityCode": "131127",
                                "cityName": "景县",
                                "parentId": "131100",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "衡水市",
                                "county": "景县",

                            },
                            {
                                "cityCode": "131128",
                                "cityName": "阜城县",
                                "parentId": "131100",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "衡水市",
                                "county": "阜城县",

                            },
                            {
                                "cityCode": "131171",
                                "cityName": "河北衡水经济开发区",
                                "parentId": "131100",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "衡水市",
                                "county": "河北衡水经济开发区",

                            },
                            {
                                "cityCode": "131172",
                                "cityName": "衡水滨湖新区",
                                "parentId": "131100",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "衡水市",
                                "county": "衡水滨湖新区",

                            },
                            {
                                "cityCode": "131181",
                                "cityName": "冀州市",
                                "parentId": "131100",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "衡水市",
                                "county": "冀州市",

                            },
                            {
                                "cityCode": "131182",
                                "cityName": "深州市",
                                "parentId": "131100",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "衡水市",
                                "county": "深州市",

                            }
                        ]
                    },
                    {
                        "cityCode": "131200",
                        "cityName": "雄安新区",
                        "parentId": "130000",
                        "provinceType": "2",
                        "province": "河北省",
                        "city": "雄安新区",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "131201",
                                "cityName": "雄县",
                                "parentId": "131200",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "雄安新区",
                                "county": "雄县",

                            },
                            {
                                "cityCode": "131202",
                                "cityName": "容城县",
                                "parentId": "131200",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "雄安新区",
                                "county": "容城县",

                            },
                            {
                                "cityCode": "131203",
                                "cityName": "安新县",
                                "parentId": "131200",
                                "provinceType": "3",
                                "province": "河北省",
                                "city": "雄安新区",
                                "county": "安新县",

                            }
                        ]
                    }
                ]
            },
            {
                "cityCode": "140000",
                "cityName": "山西省",
                "parentId": "100000",
                "provinceType": "1",
                "province": "山西省",
                "city": "",
                "county": "",
                "counties": [
                    {
                        "cityCode": "140100",
                        "cityName": "太原市",
                        "parentId": "140000",
                        "provinceType": "2",
                        "province": "山西省",
                        "city": "太原市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "140101",
                                "cityName": "太原市",
                                "parentId": "140100",
                                "provinceType": "2",
                                "province": "山西省",
                                "city": "太原市",
                                "county": "",

                            },
                            {
                                "cityCode": "140105",
                                "cityName": "小店区",
                                "parentId": "140100",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "太原市",
                                "county": "小店区",

                            },
                            {
                                "cityCode": "140106",
                                "cityName": "迎泽区",
                                "parentId": "140100",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "太原市",
                                "county": "迎泽区",

                            },
                            {
                                "cityCode": "140107",
                                "cityName": "杏花岭区",
                                "parentId": "140100",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "太原市",
                                "county": "杏花岭区",

                            },
                            {
                                "cityCode": "140108",
                                "cityName": "尖草坪区",
                                "parentId": "140100",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "太原市",
                                "county": "尖草坪区",

                            },
                            {
                                "cityCode": "140109",
                                "cityName": "万柏林区",
                                "parentId": "140100",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "太原市",
                                "county": "万柏林区",

                            },
                            {
                                "cityCode": "140110",
                                "cityName": "晋源区",
                                "parentId": "140100",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "太原市",
                                "county": "晋源区",

                            },
                            {
                                "cityCode": "140121",
                                "cityName": "清徐县",
                                "parentId": "140100",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "太原市",
                                "county": "清徐县",

                            },
                            {
                                "cityCode": "140122",
                                "cityName": "阳曲县",
                                "parentId": "140100",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "太原市",
                                "county": "阳曲县",

                            },
                            {
                                "cityCode": "140123",
                                "cityName": "娄烦县",
                                "parentId": "140100",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "太原市",
                                "county": "娄烦县",

                            },
                            {
                                "cityCode": "140171",
                                "cityName": "山西转型综合改革示范区",
                                "parentId": "140100",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "太原市",
                                "county": "山西转型综合改革示范区",

                            },
                            {
                                "cityCode": "140181",
                                "cityName": "古交市",
                                "parentId": "140100",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "太原市",
                                "county": "古交市",

                            },
                            {
                                "cityCode": "140182",
                                "cityName": "高新阳曲园区",
                                "parentId": "140100",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "太原市",
                                "county": "高新阳曲园区",

                            },
                            {
                                "cityCode": "140183",
                                "cityName": "高新汾东园区",
                                "parentId": "140100",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "太原市",
                                "county": "高新汾东园区",

                            },
                            {
                                "cityCode": "140184",
                                "cityName": "高新姚村园区",
                                "parentId": "140100",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "太原市",
                                "county": "高新姚村园区",

                            }
                        ]
                    },
                    {
                        "cityCode": "140200",
                        "cityName": "大同市",
                        "parentId": "140000",
                        "provinceType": "2",
                        "province": "山西省",
                        "city": "大同市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "140201",
                                "cityName": "大同市",
                                "parentId": "140200",
                                "provinceType": "2",
                                "province": "山西省",
                                "city": "大同市",
                                "county": "",

                            },
                            {
                                "cityCode": "140202",
                                "cityName": "城区",
                                "parentId": "140200",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "大同市",
                                "county": "城区",

                            },
                            {
                                "cityCode": "140203",
                                "cityName": "矿区",
                                "parentId": "140200",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "大同市",
                                "county": "矿区",

                            },
                            {
                                "cityCode": "140211",
                                "cityName": "南郊区",
                                "parentId": "140200",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "大同市",
                                "county": "南郊区",

                            },
                            {
                                "cityCode": "140212",
                                "cityName": "新荣区",
                                "parentId": "140200",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "大同市",
                                "county": "新荣区",

                            },
                            {
                                "cityCode": "140213",
                                "cityName": "平城区",
                                "parentId": "140200",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "大同市",
                                "county": "平城区",

                            },
                            {
                                "cityCode": "140214",
                                "cityName": "云冈区",
                                "parentId": "140200",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "大同市",
                                "county": "云冈区",

                            },
                            {
                                "cityCode": "140215",
                                "cityName": "云州区",
                                "parentId": "140200",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "大同市",
                                "county": "云州区",

                            },
                            {
                                "cityCode": "140221",
                                "cityName": "阳高县",
                                "parentId": "140200",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "大同市",
                                "county": "阳高县",

                            },
                            {
                                "cityCode": "140222",
                                "cityName": "天镇县",
                                "parentId": "140200",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "大同市",
                                "county": "天镇县",

                            },
                            {
                                "cityCode": "140223",
                                "cityName": "广灵县",
                                "parentId": "140200",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "大同市",
                                "county": "广灵县",

                            },
                            {
                                "cityCode": "140224",
                                "cityName": "灵丘县",
                                "parentId": "140200",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "大同市",
                                "county": "灵丘县",

                            },
                            {
                                "cityCode": "140225",
                                "cityName": "浑源县",
                                "parentId": "140200",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "大同市",
                                "county": "浑源县",

                            },
                            {
                                "cityCode": "140226",
                                "cityName": "左云县",
                                "parentId": "140200",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "大同市",
                                "county": "左云县",

                            },
                            {
                                "cityCode": "140227",
                                "cityName": "大同县",
                                "parentId": "140200",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "大同市",
                                "county": "大同县",

                            },
                            {
                                "cityCode": "140228",
                                "cityName": "经济开发区",
                                "parentId": "140200",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "大同市",
                                "county": "经济开发区",

                            },
                            {
                                "cityCode": "140271",
                                "cityName": "山西大同经济开发区",
                                "parentId": "140200",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "大同市",
                                "county": "山西大同经济开发区",

                            }
                        ]
                    },
                    {
                        "cityCode": "140300",
                        "cityName": "阳泉市",
                        "parentId": "140000",
                        "provinceType": "2",
                        "province": "山西省",
                        "city": "阳泉市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "140301",
                                "cityName": "阳泉市",
                                "parentId": "140300",
                                "provinceType": "2",
                                "province": "山西省",
                                "city": "阳泉市",
                                "county": "",

                            },
                            {
                                "cityCode": "140302",
                                "cityName": "城区",
                                "parentId": "140300",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "阳泉市",
                                "county": "城区",

                            },
                            {
                                "cityCode": "140303",
                                "cityName": "矿区",
                                "parentId": "140300",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "阳泉市",
                                "county": "矿区",

                            },
                            {
                                "cityCode": "140311",
                                "cityName": "郊区",
                                "parentId": "140300",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "阳泉市",
                                "county": "郊区",

                            },
                            {
                                "cityCode": "140321",
                                "cityName": "平定县",
                                "parentId": "140300",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "阳泉市",
                                "county": "平定县",

                            },
                            {
                                "cityCode": "140322",
                                "cityName": "盂县",
                                "parentId": "140300",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "阳泉市",
                                "county": "盂县",

                            },
                            {
                                "cityCode": "140371",
                                "cityName": "山西阳泉经济开发区",
                                "parentId": "140300",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "阳泉市",
                                "county": "山西阳泉经济开发区",

                            }
                        ]
                    },
                    {
                        "cityCode": "140400",
                        "cityName": "长治市",
                        "parentId": "140000",
                        "provinceType": "2",
                        "province": "山西省",
                        "city": "长治市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "140401",
                                "cityName": "长治市",
                                "parentId": "140400",
                                "provinceType": "2",
                                "province": "山西省",
                                "city": "长治市",
                                "county": "",

                            },
                            {
                                "cityCode": "140402",
                                "cityName": "城区",
                                "parentId": "140400",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "长治市",
                                "county": "城区",

                            },
                            {
                                "cityCode": "140403",
                                "cityName": "潞州区",
                                "parentId": "140400",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "长治市",
                                "county": "潞州区",

                            },
                            {
                                "cityCode": "140404",
                                "cityName": "上党区",
                                "parentId": "140400",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "长治市",
                                "county": "上党区",

                            },
                            {
                                "cityCode": "140405",
                                "cityName": "屯留区",
                                "parentId": "140400",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "长治市",
                                "county": "屯留区",

                            },
                            {
                                "cityCode": "140406",
                                "cityName": "潞城区",
                                "parentId": "140400",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "长治市",
                                "county": "潞城区",

                            },
                            {
                                "cityCode": "140411",
                                "cityName": "郊区",
                                "parentId": "140400",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "长治市",
                                "county": "郊区",

                            },
                            {
                                "cityCode": "140421",
                                "cityName": "长治县",
                                "parentId": "140400",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "长治市",
                                "county": "长治县",

                            },
                            {
                                "cityCode": "140423",
                                "cityName": "襄垣县",
                                "parentId": "140400",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "长治市",
                                "county": "襄垣县",

                            },
                            {
                                "cityCode": "140424",
                                "cityName": "屯留县",
                                "parentId": "140400",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "长治市",
                                "county": "屯留县",

                            },
                            {
                                "cityCode": "140425",
                                "cityName": "平顺县",
                                "parentId": "140400",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "长治市",
                                "county": "平顺县",

                            },
                            {
                                "cityCode": "140426",
                                "cityName": "黎城县",
                                "parentId": "140400",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "长治市",
                                "county": "黎城县",

                            },
                            {
                                "cityCode": "140427",
                                "cityName": "壶关县",
                                "parentId": "140400",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "长治市",
                                "county": "壶关县",

                            },
                            {
                                "cityCode": "140428",
                                "cityName": "长子县",
                                "parentId": "140400",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "长治市",
                                "county": "长子县",

                            },
                            {
                                "cityCode": "140429",
                                "cityName": "武乡县",
                                "parentId": "140400",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "长治市",
                                "county": "武乡县",

                            },
                            {
                                "cityCode": "140430",
                                "cityName": "沁县",
                                "parentId": "140400",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "长治市",
                                "county": "沁县",

                            },
                            {
                                "cityCode": "140431",
                                "cityName": "沁源县",
                                "parentId": "140400",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "长治市",
                                "county": "沁源县",

                            },
                            {
                                "cityCode": "140471",
                                "cityName": "山西长治高新技术产业园区",
                                "parentId": "140400",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "长治市",
                                "county": "山西长治高新技术产业园区",

                            },
                            {
                                "cityCode": "140481",
                                "cityName": "潞城市",
                                "parentId": "140400",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "长治市",
                                "county": "潞城市",

                            }
                        ]
                    },
                    {
                        "cityCode": "140500",
                        "cityName": "晋城市",
                        "parentId": "140000",
                        "provinceType": "2",
                        "province": "山西省",
                        "city": "晋城市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "140501",
                                "cityName": "晋城市",
                                "parentId": "140500",
                                "provinceType": "2",
                                "province": "山西省",
                                "city": "晋城市",
                                "county": "",

                            },
                            {
                                "cityCode": "140502",
                                "cityName": "城区",
                                "parentId": "140500",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "晋城市",
                                "county": "城区",

                            },
                            {
                                "cityCode": "140521",
                                "cityName": "沁水县",
                                "parentId": "140500",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "晋城市",
                                "county": "沁水县",

                            },
                            {
                                "cityCode": "140522",
                                "cityName": "阳城县",
                                "parentId": "140500",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "晋城市",
                                "county": "阳城县",

                            },
                            {
                                "cityCode": "140524",
                                "cityName": "陵川县",
                                "parentId": "140500",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "晋城市",
                                "county": "陵川县",

                            },
                            {
                                "cityCode": "140525",
                                "cityName": "泽州县",
                                "parentId": "140500",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "晋城市",
                                "county": "泽州县",

                            },
                            {
                                "cityCode": "140581",
                                "cityName": "高平市",
                                "parentId": "140500",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "晋城市",
                                "county": "高平市",

                            },
                            {
                                "cityCode": "140582",
                                "cityName": "经济开发区",
                                "parentId": "140500",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "晋城市",
                                "county": "经济开发区",

                            }
                        ]
                    },
                    {
                        "cityCode": "140600",
                        "cityName": "朔州市",
                        "parentId": "140000",
                        "provinceType": "2",
                        "province": "山西省",
                        "city": "朔州市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "140601",
                                "cityName": "朔州市",
                                "parentId": "140600",
                                "provinceType": "2",
                                "province": "山西省",
                                "city": "朔州市",
                                "county": "",

                            },
                            {
                                "cityCode": "140602",
                                "cityName": "朔城区",
                                "parentId": "140600",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "朔州市",
                                "county": "朔城区",

                            },
                            {
                                "cityCode": "140603",
                                "cityName": "平鲁区",
                                "parentId": "140600",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "朔州市",
                                "county": "平鲁区",

                            },
                            {
                                "cityCode": "140621",
                                "cityName": "山阴县",
                                "parentId": "140600",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "朔州市",
                                "county": "山阴县",

                            },
                            {
                                "cityCode": "140622",
                                "cityName": "应县",
                                "parentId": "140600",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "朔州市",
                                "county": "应县",

                            },
                            {
                                "cityCode": "140623",
                                "cityName": "右玉县",
                                "parentId": "140600",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "朔州市",
                                "county": "右玉县",

                            },
                            {
                                "cityCode": "140624",
                                "cityName": "怀仁县",
                                "parentId": "140600",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "朔州市",
                                "county": "怀仁县",

                            },
                            {
                                "cityCode": "140671",
                                "cityName": "山西朔州经济开发区",
                                "parentId": "140600",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "朔州市",
                                "county": "山西朔州经济开发区",

                            },
                            {
                                "cityCode": "140681",
                                "cityName": "怀仁市",
                                "parentId": "140600",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "朔州市",
                                "county": "怀仁市",

                            }
                        ]
                    },
                    {
                        "cityCode": "140700",
                        "cityName": "晋中市",
                        "parentId": "140000",
                        "provinceType": "2",
                        "province": "山西省",
                        "city": "晋中市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "140701",
                                "cityName": "晋中市",
                                "parentId": "140700",
                                "provinceType": "2",
                                "province": "山西省",
                                "city": "晋中市",
                                "county": "",

                            },
                            {
                                "cityCode": "140702",
                                "cityName": "榆次区",
                                "parentId": "140700",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "晋中市",
                                "county": "榆次区",

                            },
                            {
                                "cityCode": "140703",
                                "cityName": "太谷区",
                                "parentId": "140700",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "晋中市",
                                "county": "太谷区",

                            },
                            {
                                "cityCode": "140721",
                                "cityName": "榆社县",
                                "parentId": "140700",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "晋中市",
                                "county": "榆社县",

                            },
                            {
                                "cityCode": "140722",
                                "cityName": "左权县",
                                "parentId": "140700",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "晋中市",
                                "county": "左权县",

                            },
                            {
                                "cityCode": "140723",
                                "cityName": "和顺县",
                                "parentId": "140700",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "晋中市",
                                "county": "和顺县",

                            },
                            {
                                "cityCode": "140724",
                                "cityName": "昔阳县",
                                "parentId": "140700",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "晋中市",
                                "county": "昔阳县",

                            },
                            {
                                "cityCode": "140725",
                                "cityName": "寿阳县",
                                "parentId": "140700",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "晋中市",
                                "county": "寿阳县",

                            },
                            {
                                "cityCode": "140726",
                                "cityName": "太谷县",
                                "parentId": "140700",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "晋中市",
                                "county": "太谷县",

                            },
                            {
                                "cityCode": "140727",
                                "cityName": "祁县",
                                "parentId": "140700",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "晋中市",
                                "county": "祁县",

                            },
                            {
                                "cityCode": "140728",
                                "cityName": "平遥县",
                                "parentId": "140700",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "晋中市",
                                "county": "平遥县",

                            },
                            {
                                "cityCode": "140729",
                                "cityName": "灵石县",
                                "parentId": "140700",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "晋中市",
                                "county": "灵石县",

                            },
                            {
                                "cityCode": "140781",
                                "cityName": "介休市",
                                "parentId": "140700",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "晋中市",
                                "county": "介休市",

                            }
                        ]
                    },
                    {
                        "cityCode": "140800",
                        "cityName": "运城市",
                        "parentId": "140000",
                        "provinceType": "2",
                        "province": "山西省",
                        "city": "运城市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "140801",
                                "cityName": "运城市",
                                "parentId": "140800",
                                "provinceType": "2",
                                "province": "山西省",
                                "city": "运城市",
                                "county": "",

                            },
                            {
                                "cityCode": "140802",
                                "cityName": "盐湖区",
                                "parentId": "140800",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "运城市",
                                "county": "盐湖区",

                            },
                            {
                                "cityCode": "140821",
                                "cityName": "临猗县",
                                "parentId": "140800",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "运城市",
                                "county": "临猗县",

                            },
                            {
                                "cityCode": "140822",
                                "cityName": "万荣县",
                                "parentId": "140800",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "运城市",
                                "county": "万荣县",

                            },
                            {
                                "cityCode": "140823",
                                "cityName": "闻喜县",
                                "parentId": "140800",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "运城市",
                                "county": "闻喜县",

                            },
                            {
                                "cityCode": "140824",
                                "cityName": "稷山县",
                                "parentId": "140800",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "运城市",
                                "county": "稷山县",

                            },
                            {
                                "cityCode": "140825",
                                "cityName": "新绛县",
                                "parentId": "140800",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "运城市",
                                "county": "新绛县",

                            },
                            {
                                "cityCode": "140826",
                                "cityName": "绛县",
                                "parentId": "140800",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "运城市",
                                "county": "绛县",

                            },
                            {
                                "cityCode": "140827",
                                "cityName": "垣曲县",
                                "parentId": "140800",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "运城市",
                                "county": "垣曲县",

                            },
                            {
                                "cityCode": "140828",
                                "cityName": "夏县",
                                "parentId": "140800",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "运城市",
                                "county": "夏县",

                            },
                            {
                                "cityCode": "140829",
                                "cityName": "平陆县",
                                "parentId": "140800",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "运城市",
                                "county": "平陆县",

                            },
                            {
                                "cityCode": "140830",
                                "cityName": "芮城县",
                                "parentId": "140800",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "运城市",
                                "county": "芮城县",

                            },
                            {
                                "cityCode": "140881",
                                "cityName": "永济市",
                                "parentId": "140800",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "运城市",
                                "county": "永济市",

                            },
                            {
                                "cityCode": "140882",
                                "cityName": "河津市",
                                "parentId": "140800",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "运城市",
                                "county": "河津市",

                            }
                        ]
                    },
                    {
                        "cityCode": "140900",
                        "cityName": "忻州市",
                        "parentId": "140000",
                        "provinceType": "2",
                        "province": "山西省",
                        "city": "忻州市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "140901",
                                "cityName": "忻州市",
                                "parentId": "140900",
                                "provinceType": "2",
                                "province": "山西省",
                                "city": "忻州市",
                                "county": "",

                            },
                            {
                                "cityCode": "140902",
                                "cityName": "忻府区",
                                "parentId": "140900",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "忻州市",
                                "county": "忻府区",

                            },
                            {
                                "cityCode": "140921",
                                "cityName": "定襄县",
                                "parentId": "140900",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "忻州市",
                                "county": "定襄县",

                            },
                            {
                                "cityCode": "140922",
                                "cityName": "五台县",
                                "parentId": "140900",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "忻州市",
                                "county": "五台县",

                            },
                            {
                                "cityCode": "140923",
                                "cityName": "代县",
                                "parentId": "140900",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "忻州市",
                                "county": "代县",

                            },
                            {
                                "cityCode": "140924",
                                "cityName": "繁峙县",
                                "parentId": "140900",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "忻州市",
                                "county": "繁峙县",

                            },
                            {
                                "cityCode": "140925",
                                "cityName": "宁武县",
                                "parentId": "140900",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "忻州市",
                                "county": "宁武县",

                            },
                            {
                                "cityCode": "140926",
                                "cityName": "静乐县",
                                "parentId": "140900",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "忻州市",
                                "county": "静乐县",

                            },
                            {
                                "cityCode": "140927",
                                "cityName": "神池县",
                                "parentId": "140900",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "忻州市",
                                "county": "神池县",

                            },
                            {
                                "cityCode": "140928",
                                "cityName": "五寨县",
                                "parentId": "140900",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "忻州市",
                                "county": "五寨县",

                            },
                            {
                                "cityCode": "140929",
                                "cityName": "岢岚县",
                                "parentId": "140900",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "忻州市",
                                "county": "岢岚县",

                            },
                            {
                                "cityCode": "140930",
                                "cityName": "河曲县",
                                "parentId": "140900",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "忻州市",
                                "county": "河曲县",

                            },
                            {
                                "cityCode": "140931",
                                "cityName": "保德县",
                                "parentId": "140900",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "忻州市",
                                "county": "保德县",

                            },
                            {
                                "cityCode": "140932",
                                "cityName": "偏关县",
                                "parentId": "140900",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "忻州市",
                                "county": "偏关县",

                            },
                            {
                                "cityCode": "140971",
                                "cityName": "五台山风景名胜区",
                                "parentId": "140900",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "忻州市",
                                "county": "五台山风景名胜区",

                            },
                            {
                                "cityCode": "140981",
                                "cityName": "原平市",
                                "parentId": "140900",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "忻州市",
                                "county": "原平市",

                            }
                        ]
                    },
                    {
                        "cityCode": "141000",
                        "cityName": "临汾市",
                        "parentId": "140000",
                        "provinceType": "2",
                        "province": "山西省",
                        "city": "临汾市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "141001",
                                "cityName": "临汾市",
                                "parentId": "141000",
                                "provinceType": "2",
                                "province": "山西省",
                                "city": "临汾市",
                                "county": "",

                            },
                            {
                                "cityCode": "141002",
                                "cityName": "尧都区",
                                "parentId": "141000",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "临汾市",
                                "county": "尧都区",

                            },
                            {
                                "cityCode": "141021",
                                "cityName": "曲沃县",
                                "parentId": "141000",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "临汾市",
                                "county": "曲沃县",

                            },
                            {
                                "cityCode": "141022",
                                "cityName": "翼城县",
                                "parentId": "141000",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "临汾市",
                                "county": "翼城县",

                            },
                            {
                                "cityCode": "141023",
                                "cityName": "襄汾县",
                                "parentId": "141000",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "临汾市",
                                "county": "襄汾县",

                            },
                            {
                                "cityCode": "141024",
                                "cityName": "洪洞县",
                                "parentId": "141000",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "临汾市",
                                "county": "洪洞县",

                            },
                            {
                                "cityCode": "141025",
                                "cityName": "古县",
                                "parentId": "141000",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "临汾市",
                                "county": "古县",

                            },
                            {
                                "cityCode": "141026",
                                "cityName": "安泽县",
                                "parentId": "141000",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "临汾市",
                                "county": "安泽县",

                            },
                            {
                                "cityCode": "141027",
                                "cityName": "浮山县",
                                "parentId": "141000",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "临汾市",
                                "county": "浮山县",

                            },
                            {
                                "cityCode": "141028",
                                "cityName": "吉县",
                                "parentId": "141000",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "临汾市",
                                "county": "吉县",

                            },
                            {
                                "cityCode": "141029",
                                "cityName": "乡宁县",
                                "parentId": "141000",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "临汾市",
                                "county": "乡宁县",

                            },
                            {
                                "cityCode": "141030",
                                "cityName": "大宁县",
                                "parentId": "141000",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "临汾市",
                                "county": "大宁县",

                            },
                            {
                                "cityCode": "141031",
                                "cityName": "隰县",
                                "parentId": "141000",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "临汾市",
                                "county": "隰县",

                            },
                            {
                                "cityCode": "141032",
                                "cityName": "永和县",
                                "parentId": "141000",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "临汾市",
                                "county": "永和县",

                            },
                            {
                                "cityCode": "141033",
                                "cityName": "蒲县",
                                "parentId": "141000",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "临汾市",
                                "county": "蒲县",

                            },
                            {
                                "cityCode": "141034",
                                "cityName": "汾西县",
                                "parentId": "141000",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "临汾市",
                                "county": "汾西县",

                            },
                            {
                                "cityCode": "141081",
                                "cityName": "侯马市",
                                "parentId": "141000",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "临汾市",
                                "county": "侯马市",

                            },
                            {
                                "cityCode": "141082",
                                "cityName": "霍州市",
                                "parentId": "141000",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "临汾市",
                                "county": "霍州市",

                            }
                        ]
                    },
                    {
                        "cityCode": "141100",
                        "cityName": "吕梁市",
                        "parentId": "140000",
                        "provinceType": "2",
                        "province": "山西省",
                        "city": "吕梁市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "141101",
                                "cityName": "吕梁市",
                                "parentId": "141100",
                                "provinceType": "2",
                                "province": "山西省",
                                "city": "吕梁市",
                                "county": "",

                            },
                            {
                                "cityCode": "141102",
                                "cityName": "离石区",
                                "parentId": "141100",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "吕梁市",
                                "county": "离石区",

                            },
                            {
                                "cityCode": "141121",
                                "cityName": "文水县",
                                "parentId": "141100",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "吕梁市",
                                "county": "文水县",

                            },
                            {
                                "cityCode": "141122",
                                "cityName": "交城县",
                                "parentId": "141100",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "吕梁市",
                                "county": "交城县",

                            },
                            {
                                "cityCode": "141123",
                                "cityName": "兴县",
                                "parentId": "141100",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "吕梁市",
                                "county": "兴县",

                            },
                            {
                                "cityCode": "141124",
                                "cityName": "临县",
                                "parentId": "141100",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "吕梁市",
                                "county": "临县",

                            },
                            {
                                "cityCode": "141125",
                                "cityName": "柳林县",
                                "parentId": "141100",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "吕梁市",
                                "county": "柳林县",

                            },
                            {
                                "cityCode": "141126",
                                "cityName": "石楼县",
                                "parentId": "141100",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "吕梁市",
                                "county": "石楼县",

                            },
                            {
                                "cityCode": "141127",
                                "cityName": "岚县",
                                "parentId": "141100",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "吕梁市",
                                "county": "岚县",

                            },
                            {
                                "cityCode": "141128",
                                "cityName": "方山县",
                                "parentId": "141100",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "吕梁市",
                                "county": "方山县",

                            },
                            {
                                "cityCode": "141129",
                                "cityName": "中阳县",
                                "parentId": "141100",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "吕梁市",
                                "county": "中阳县",

                            },
                            {
                                "cityCode": "141130",
                                "cityName": "交口县",
                                "parentId": "141100",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "吕梁市",
                                "county": "交口县",

                            },
                            {
                                "cityCode": "141181",
                                "cityName": "孝义市",
                                "parentId": "141100",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "吕梁市",
                                "county": "孝义市",

                            },
                            {
                                "cityCode": "141182",
                                "cityName": "汾阳市",
                                "parentId": "141100",
                                "provinceType": "3",
                                "province": "山西省",
                                "city": "吕梁市",
                                "county": "汾阳市",

                            }
                        ]
                    }
                ]
            },
            {
                "cityCode": "150000",
                "cityName": "内蒙古自治区",
                "parentId": "100000",
                "provinceType": "1",
                "province": "内蒙古自治区",
                "city": "",
                "county": "",
                "counties": [
                    {
                        "cityCode": "150100",
                        "cityName": "呼和浩特市",
                        "parentId": "150000",
                        "provinceType": "2",
                        "province": "内蒙古自治区",
                        "city": "呼和浩特市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "150102",
                                "cityName": "新城区",
                                "parentId": "150100",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "呼和浩特市",
                                "county": "新城区",

                            },
                            {
                                "cityCode": "150103",
                                "cityName": "回民区",
                                "parentId": "150100",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "呼和浩特市",
                                "county": "回民区",

                            },
                            {
                                "cityCode": "150104",
                                "cityName": "玉泉区",
                                "parentId": "150100",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "呼和浩特市",
                                "county": "玉泉区",

                            },
                            {
                                "cityCode": "150105",
                                "cityName": "赛罕区",
                                "parentId": "150100",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "呼和浩特市",
                                "county": "赛罕区",

                            },
                            {
                                "cityCode": "150121",
                                "cityName": "土默特左旗",
                                "parentId": "150100",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "呼和浩特市",
                                "county": "土默特左旗",

                            },
                            {
                                "cityCode": "150122",
                                "cityName": "托克托县",
                                "parentId": "150100",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "呼和浩特市",
                                "county": "托克托县",

                            },
                            {
                                "cityCode": "150123",
                                "cityName": "和林格尔县",
                                "parentId": "150100",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "呼和浩特市",
                                "county": "和林格尔县",

                            },
                            {
                                "cityCode": "150124",
                                "cityName": "清水河县",
                                "parentId": "150100",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "呼和浩特市",
                                "county": "清水河县",

                            },
                            {
                                "cityCode": "150125",
                                "cityName": "武川县",
                                "parentId": "150100",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "呼和浩特市",
                                "county": "武川县",

                            },
                            {
                                "cityCode": "150171",
                                "cityName": "呼和浩特金海工业园区",
                                "parentId": "150100",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "呼和浩特市",
                                "county": "呼和浩特金海工业园区",

                            },
                            {
                                "cityCode": "150172",
                                "cityName": "呼和浩特经济技术开发区",
                                "parentId": "150100",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "呼和浩特市",
                                "county": "呼和浩特经济技术开发区",

                            }
                        ]
                    },
                    {
                        "cityCode": "150200",
                        "cityName": "包头市",
                        "parentId": "150000",
                        "provinceType": "2",
                        "province": "内蒙古自治区",
                        "city": "包头市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "150201",
                                "cityName": "包头市",
                                "parentId": "150200",
                                "provinceType": "2",
                                "province": "内蒙古自治区",
                                "city": "包头市",
                                "county": "",

                            },
                            {
                                "cityCode": "150202",
                                "cityName": "东河区",
                                "parentId": "150200",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "包头市",
                                "county": "东河区",

                            },
                            {
                                "cityCode": "150203",
                                "cityName": "昆都仑区",
                                "parentId": "150200",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "包头市",
                                "county": "昆都仑区",

                            },
                            {
                                "cityCode": "150204",
                                "cityName": "青山区",
                                "parentId": "150200",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "包头市",
                                "county": "青山区",

                            },
                            {
                                "cityCode": "150205",
                                "cityName": "石拐区",
                                "parentId": "150200",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "包头市",
                                "county": "石拐区",

                            },
                            {
                                "cityCode": "150206",
                                "cityName": "白云鄂博矿区",
                                "parentId": "150200",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "包头市",
                                "county": "白云鄂博矿区",

                            },
                            {
                                "cityCode": "150207",
                                "cityName": "九原区",
                                "parentId": "150200",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "包头市",
                                "county": "九原区",

                            },
                            {
                                "cityCode": "150221",
                                "cityName": "土默特右旗",
                                "parentId": "150200",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "包头市",
                                "county": "土默特右旗",

                            },
                            {
                                "cityCode": "150222",
                                "cityName": "固阳县",
                                "parentId": "150200",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "包头市",
                                "county": "固阳县",

                            },
                            {
                                "cityCode": "150223",
                                "cityName": "达尔罕茂明安联合旗",
                                "parentId": "150200",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "包头市",
                                "county": "达尔罕茂明安联合旗",

                            },
                            {
                                "cityCode": "150271",
                                "cityName": "包头稀土高新技术产业开发区",
                                "parentId": "150200",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "包头市",
                                "county": "包头稀土高新技术产业开发区",

                            }
                        ]
                    },
                    {
                        "cityCode": "150300",
                        "cityName": "乌海市",
                        "parentId": "150000",
                        "provinceType": "2",
                        "province": "内蒙古自治区",
                        "city": "乌海市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "150302",
                                "cityName": "海勃湾区",
                                "parentId": "150300",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "乌海市",
                                "county": "海勃湾区",

                            },
                            {
                                "cityCode": "150303",
                                "cityName": "海南区",
                                "parentId": "150300",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "乌海市",
                                "county": "海南区",

                            },
                            {
                                "cityCode": "150304",
                                "cityName": "乌达区",
                                "parentId": "150300",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "乌海市",
                                "county": "乌达区",

                            }
                        ]
                    },
                    {
                        "cityCode": "150400",
                        "cityName": "赤峰市",
                        "parentId": "150000",
                        "provinceType": "2",
                        "province": "内蒙古自治区",
                        "city": "赤峰市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "150401",
                                "cityName": "赤峰市",
                                "parentId": "150400",
                                "provinceType": "2",
                                "province": "内蒙古自治区",
                                "city": "赤峰市",
                                "county": "",

                            },
                            {
                                "cityCode": "150402",
                                "cityName": "红山区",
                                "parentId": "150400",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "赤峰市",
                                "county": "红山区",

                            },
                            {
                                "cityCode": "150403",
                                "cityName": "元宝山区",
                                "parentId": "150400",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "赤峰市",
                                "county": "元宝山区",

                            },
                            {
                                "cityCode": "150404",
                                "cityName": "松山区",
                                "parentId": "150400",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "赤峰市",
                                "county": "松山区",

                            },
                            {
                                "cityCode": "150421",
                                "cityName": "阿鲁科尔沁旗",
                                "parentId": "150400",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "赤峰市",
                                "county": "阿鲁科尔沁旗",

                            },
                            {
                                "cityCode": "150422",
                                "cityName": "巴林左旗",
                                "parentId": "150400",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "赤峰市",
                                "county": "巴林左旗",

                            },
                            {
                                "cityCode": "150423",
                                "cityName": "巴林右旗",
                                "parentId": "150400",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "赤峰市",
                                "county": "巴林右旗",

                            },
                            {
                                "cityCode": "150424",
                                "cityName": "林西县",
                                "parentId": "150400",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "赤峰市",
                                "county": "林西县",

                            },
                            {
                                "cityCode": "150425",
                                "cityName": "克什克腾旗",
                                "parentId": "150400",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "赤峰市",
                                "county": "克什克腾旗",

                            },
                            {
                                "cityCode": "150426",
                                "cityName": "翁牛特旗",
                                "parentId": "150400",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "赤峰市",
                                "county": "翁牛特旗",

                            },
                            {
                                "cityCode": "150428",
                                "cityName": "喀喇沁旗",
                                "parentId": "150400",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "赤峰市",
                                "county": "喀喇沁旗",

                            },
                            {
                                "cityCode": "150429",
                                "cityName": "宁城县",
                                "parentId": "150400",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "赤峰市",
                                "county": "宁城县",

                            },
                            {
                                "cityCode": "150430",
                                "cityName": "敖汉旗",
                                "parentId": "150400",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "赤峰市",
                                "county": "敖汉旗",

                            }
                        ]
                    },
                    {
                        "cityCode": "150500",
                        "cityName": "通辽市",
                        "parentId": "150000",
                        "provinceType": "2",
                        "province": "内蒙古自治区",
                        "city": "通辽市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "150501",
                                "cityName": "通辽市",
                                "parentId": "150500",
                                "provinceType": "2",
                                "province": "内蒙古自治区",
                                "city": "通辽市",
                                "county": "",

                            },
                            {
                                "cityCode": "150502",
                                "cityName": "科尔沁区",
                                "parentId": "150500",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "通辽市",
                                "county": "科尔沁区",

                            },
                            {
                                "cityCode": "150521",
                                "cityName": "科尔沁左翼中旗",
                                "parentId": "150500",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "通辽市",
                                "county": "科尔沁左翼中旗",

                            },
                            {
                                "cityCode": "150522",
                                "cityName": "科尔沁左翼后旗",
                                "parentId": "150500",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "通辽市",
                                "county": "科尔沁左翼后旗",

                            },
                            {
                                "cityCode": "150523",
                                "cityName": "开鲁县",
                                "parentId": "150500",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "通辽市",
                                "county": "开鲁县",

                            },
                            {
                                "cityCode": "150524",
                                "cityName": "库伦旗",
                                "parentId": "150500",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "通辽市",
                                "county": "库伦旗",

                            },
                            {
                                "cityCode": "150525",
                                "cityName": "奈曼旗",
                                "parentId": "150500",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "通辽市",
                                "county": "奈曼旗",

                            },
                            {
                                "cityCode": "150526",
                                "cityName": "扎鲁特旗",
                                "parentId": "150500",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "通辽市",
                                "county": "扎鲁特旗",

                            },
                            {
                                "cityCode": "150571",
                                "cityName": "通辽经济技术开发区",
                                "parentId": "150500",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "通辽市",
                                "county": "通辽经济技术开发区",

                            },
                            {
                                "cityCode": "150581",
                                "cityName": "霍林郭勒市",
                                "parentId": "150500",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "通辽市",
                                "county": "霍林郭勒市",

                            }
                        ]
                    },
                    {
                        "cityCode": "150600",
                        "cityName": "鄂尔多斯市",
                        "parentId": "150000",
                        "provinceType": "2",
                        "province": "内蒙古自治区",
                        "city": "鄂尔多斯市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "150602",
                                "cityName": "东胜区",
                                "parentId": "150600",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "鄂尔多斯市",
                                "county": "东胜区",

                            },
                            {
                                "cityCode": "150603",
                                "cityName": "康巴什区",
                                "parentId": "150600",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "鄂尔多斯市",
                                "county": "康巴什区",

                            },
                            {
                                "cityCode": "150621",
                                "cityName": "达拉特旗",
                                "parentId": "150600",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "鄂尔多斯市",
                                "county": "达拉特旗",

                            },
                            {
                                "cityCode": "150622",
                                "cityName": "准格尔旗",
                                "parentId": "150600",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "鄂尔多斯市",
                                "county": "准格尔旗",

                            },
                            {
                                "cityCode": "150623",
                                "cityName": "鄂托克前旗",
                                "parentId": "150600",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "鄂尔多斯市",
                                "county": "鄂托克前旗",

                            },
                            {
                                "cityCode": "150624",
                                "cityName": "鄂托克旗",
                                "parentId": "150600",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "鄂尔多斯市",
                                "county": "鄂托克旗",

                            },
                            {
                                "cityCode": "150625",
                                "cityName": "杭锦旗",
                                "parentId": "150600",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "鄂尔多斯市",
                                "county": "杭锦旗",

                            },
                            {
                                "cityCode": "150626",
                                "cityName": "乌审旗",
                                "parentId": "150600",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "鄂尔多斯市",
                                "county": "乌审旗",

                            },
                            {
                                "cityCode": "150627",
                                "cityName": "伊金霍洛旗",
                                "parentId": "150600",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "鄂尔多斯市",
                                "county": "伊金霍洛旗",

                            },
                            {
                                "cityCode": "150628",
                                "cityName": "康巴什区",
                                "parentId": "150600",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "鄂尔多斯市",
                                "county": "康巴什区",

                            }
                        ]
                    },
                    {
                        "cityCode": "150700",
                        "cityName": "呼伦贝尔市",
                        "parentId": "150000",
                        "provinceType": "2",
                        "province": "内蒙古自治区",
                        "city": "呼伦贝尔市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "150701",
                                "cityName": "呼伦贝尔市",
                                "parentId": "150700",
                                "provinceType": "2",
                                "province": "内蒙古自治区",
                                "city": "呼伦贝尔市",
                                "county": "",

                            },
                            {
                                "cityCode": "150702",
                                "cityName": "海拉尔区",
                                "parentId": "150700",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "呼伦贝尔市",
                                "county": "海拉尔区",

                            },
                            {
                                "cityCode": "150703",
                                "cityName": "扎赉诺尔区",
                                "parentId": "150700",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "呼伦贝尔市",
                                "county": "扎赉诺尔区",

                            },
                            {
                                "cityCode": "150721",
                                "cityName": "阿荣旗",
                                "parentId": "150700",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "呼伦贝尔市",
                                "county": "阿荣旗",

                            },
                            {
                                "cityCode": "150722",
                                "cityName": "莫力达瓦达斡尔族自治旗",
                                "parentId": "150700",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "呼伦贝尔市",
                                "county": "莫力达瓦达斡尔族自治旗",

                            },
                            {
                                "cityCode": "150723",
                                "cityName": "鄂伦春自治旗",
                                "parentId": "150700",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "呼伦贝尔市",
                                "county": "鄂伦春自治旗",

                            },
                            {
                                "cityCode": "150724",
                                "cityName": "鄂温克族自治旗",
                                "parentId": "150700",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "呼伦贝尔市",
                                "county": "鄂温克族自治旗",

                            },
                            {
                                "cityCode": "150725",
                                "cityName": "陈巴尔虎旗",
                                "parentId": "150700",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "呼伦贝尔市",
                                "county": "陈巴尔虎旗",

                            },
                            {
                                "cityCode": "150726",
                                "cityName": "新巴尔虎左旗",
                                "parentId": "150700",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "呼伦贝尔市",
                                "county": "新巴尔虎左旗",

                            },
                            {
                                "cityCode": "150727",
                                "cityName": "新巴尔虎右旗",
                                "parentId": "150700",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "呼伦贝尔市",
                                "county": "新巴尔虎右旗",

                            },
                            {
                                "cityCode": "150781",
                                "cityName": "满洲里市",
                                "parentId": "150700",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "呼伦贝尔市",
                                "county": "满洲里市",

                            },
                            {
                                "cityCode": "150782",
                                "cityName": "牙克石市",
                                "parentId": "150700",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "呼伦贝尔市",
                                "county": "牙克石市",

                            },
                            {
                                "cityCode": "150783",
                                "cityName": "扎兰屯市",
                                "parentId": "150700",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "呼伦贝尔市",
                                "county": "扎兰屯市",

                            },
                            {
                                "cityCode": "150784",
                                "cityName": "额尔古纳市",
                                "parentId": "150700",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "呼伦贝尔市",
                                "county": "额尔古纳市",

                            },
                            {
                                "cityCode": "150785",
                                "cityName": "根河市",
                                "parentId": "150700",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "呼伦贝尔市",
                                "county": "根河市",

                            }
                        ]
                    },
                    {
                        "cityCode": "150800",
                        "cityName": "巴彦淖尔市",
                        "parentId": "150000",
                        "provinceType": "2",
                        "province": "内蒙古自治区",
                        "city": "巴彦淖尔市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "150801",
                                "cityName": "巴彦淖尔市",
                                "parentId": "150800",
                                "provinceType": "2",
                                "province": "内蒙古自治区",
                                "city": "巴彦淖尔市",
                                "county": "",

                            },
                            {
                                "cityCode": "150802",
                                "cityName": "临河区",
                                "parentId": "150800",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "巴彦淖尔市",
                                "county": "临河区",

                            },
                            {
                                "cityCode": "150821",
                                "cityName": "五原县",
                                "parentId": "150800",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "巴彦淖尔市",
                                "county": "五原县",

                            },
                            {
                                "cityCode": "150822",
                                "cityName": "磴口县",
                                "parentId": "150800",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "巴彦淖尔市",
                                "county": "磴口县",

                            },
                            {
                                "cityCode": "150823",
                                "cityName": "乌拉特前旗",
                                "parentId": "150800",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "巴彦淖尔市",
                                "county": "乌拉特前旗",

                            },
                            {
                                "cityCode": "150824",
                                "cityName": "乌拉特中旗",
                                "parentId": "150800",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "巴彦淖尔市",
                                "county": "乌拉特中旗",

                            },
                            {
                                "cityCode": "150825",
                                "cityName": "乌拉特后旗",
                                "parentId": "150800",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "巴彦淖尔市",
                                "county": "乌拉特后旗",

                            },
                            {
                                "cityCode": "150826",
                                "cityName": "杭锦后旗",
                                "parentId": "150800",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "巴彦淖尔市",
                                "county": "杭锦后旗",

                            }
                        ]
                    },
                    {
                        "cityCode": "150900",
                        "cityName": "乌兰察布市",
                        "parentId": "150000",
                        "provinceType": "2",
                        "province": "内蒙古自治区",
                        "city": "乌兰察布市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "150901",
                                "cityName": "乌兰察布市",
                                "parentId": "150900",
                                "provinceType": "2",
                                "province": "内蒙古自治区",
                                "city": "乌兰察布市",
                                "county": "",

                            },
                            {
                                "cityCode": "150902",
                                "cityName": "集宁区",
                                "parentId": "150900",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "乌兰察布市",
                                "county": "集宁区",

                            },
                            {
                                "cityCode": "150921",
                                "cityName": "卓资县",
                                "parentId": "150900",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "乌兰察布市",
                                "county": "卓资县",

                            },
                            {
                                "cityCode": "150922",
                                "cityName": "化德县",
                                "parentId": "150900",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "乌兰察布市",
                                "county": "化德县",

                            },
                            {
                                "cityCode": "150923",
                                "cityName": "商都县",
                                "parentId": "150900",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "乌兰察布市",
                                "county": "商都县",

                            },
                            {
                                "cityCode": "150924",
                                "cityName": "兴和县",
                                "parentId": "150900",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "乌兰察布市",
                                "county": "兴和县",

                            },
                            {
                                "cityCode": "150925",
                                "cityName": "凉城县",
                                "parentId": "150900",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "乌兰察布市",
                                "county": "凉城县",

                            },
                            {
                                "cityCode": "150926",
                                "cityName": "察哈尔右翼前旗",
                                "parentId": "150900",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "乌兰察布市",
                                "county": "察哈尔右翼前旗",

                            },
                            {
                                "cityCode": "150927",
                                "cityName": "察哈尔右翼中旗",
                                "parentId": "150900",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "乌兰察布市",
                                "county": "察哈尔右翼中旗",

                            },
                            {
                                "cityCode": "150928",
                                "cityName": "察哈尔右翼后旗",
                                "parentId": "150900",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "乌兰察布市",
                                "county": "察哈尔右翼后旗",

                            },
                            {
                                "cityCode": "150929",
                                "cityName": "四子王旗",
                                "parentId": "150900",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "乌兰察布市",
                                "county": "四子王旗",

                            },
                            {
                                "cityCode": "150981",
                                "cityName": "丰镇市",
                                "parentId": "150900",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "乌兰察布市",
                                "county": "丰镇市",

                            }
                        ]
                    },
                    {
                        "cityCode": "152200",
                        "cityName": "兴安盟",
                        "parentId": "150000",
                        "provinceType": "2",
                        "province": "内蒙古自治区",
                        "city": "兴安盟",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "152201",
                                "cityName": "乌兰浩特市",
                                "parentId": "152200",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "兴安盟",
                                "county": "乌兰浩特市",

                            },
                            {
                                "cityCode": "152202",
                                "cityName": "阿尔山市",
                                "parentId": "152200",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "兴安盟",
                                "county": "阿尔山市",

                            },
                            {
                                "cityCode": "152221",
                                "cityName": "科尔沁右翼前旗",
                                "parentId": "152200",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "兴安盟",
                                "county": "科尔沁右翼前旗",

                            },
                            {
                                "cityCode": "152222",
                                "cityName": "科尔沁右翼中旗",
                                "parentId": "152200",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "兴安盟",
                                "county": "科尔沁右翼中旗",

                            },
                            {
                                "cityCode": "152223",
                                "cityName": "扎赉特旗",
                                "parentId": "152200",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "兴安盟",
                                "county": "扎赉特旗",

                            },
                            {
                                "cityCode": "152224",
                                "cityName": "突泉县",
                                "parentId": "152200",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "兴安盟",
                                "county": "突泉县",

                            }
                        ]
                    },
                    {
                        "cityCode": "152500",
                        "cityName": "锡林郭勒盟",
                        "parentId": "150000",
                        "provinceType": "2",
                        "province": "内蒙古自治区",
                        "city": "锡林郭勒盟",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "152501",
                                "cityName": "二连浩特市",
                                "parentId": "152500",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "锡林郭勒盟",
                                "county": "二连浩特市",

                            },
                            {
                                "cityCode": "152502",
                                "cityName": "锡林浩特市",
                                "parentId": "152500",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "锡林郭勒盟",
                                "county": "锡林浩特市",

                            },
                            {
                                "cityCode": "152522",
                                "cityName": "阿巴嘎旗",
                                "parentId": "152500",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "锡林郭勒盟",
                                "county": "阿巴嘎旗",

                            },
                            {
                                "cityCode": "152523",
                                "cityName": "苏尼特左旗",
                                "parentId": "152500",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "锡林郭勒盟",
                                "county": "苏尼特左旗",

                            },
                            {
                                "cityCode": "152524",
                                "cityName": "苏尼特右旗",
                                "parentId": "152500",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "锡林郭勒盟",
                                "county": "苏尼特右旗",

                            },
                            {
                                "cityCode": "152525",
                                "cityName": "东乌珠穆沁旗",
                                "parentId": "152500",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "锡林郭勒盟",
                                "county": "东乌珠穆沁旗",

                            },
                            {
                                "cityCode": "152526",
                                "cityName": "西乌珠穆沁旗",
                                "parentId": "152500",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "锡林郭勒盟",
                                "county": "西乌珠穆沁旗",

                            },
                            {
                                "cityCode": "152527",
                                "cityName": "太仆寺旗",
                                "parentId": "152500",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "锡林郭勒盟",
                                "county": "太仆寺旗",

                            },
                            {
                                "cityCode": "152528",
                                "cityName": "镶黄旗",
                                "parentId": "152500",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "锡林郭勒盟",
                                "county": "镶黄旗",

                            },
                            {
                                "cityCode": "152529",
                                "cityName": "正镶白旗",
                                "parentId": "152500",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "锡林郭勒盟",
                                "county": "正镶白旗",

                            },
                            {
                                "cityCode": "152530",
                                "cityName": "正蓝旗",
                                "parentId": "152500",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "锡林郭勒盟",
                                "county": "正蓝旗",

                            },
                            {
                                "cityCode": "152531",
                                "cityName": "多伦县",
                                "parentId": "152500",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "锡林郭勒盟",
                                "county": "多伦县",

                            },
                            {
                                "cityCode": "152571",
                                "cityName": "乌拉盖管委会",
                                "parentId": "152500",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "锡林郭勒盟",
                                "county": "乌拉盖管委会",

                            }
                        ]
                    },
                    {
                        "cityCode": "152900",
                        "cityName": "阿拉善盟",
                        "parentId": "150000",
                        "provinceType": "2",
                        "province": "内蒙古自治区",
                        "city": "阿拉善盟",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "152921",
                                "cityName": "阿拉善左旗",
                                "parentId": "152900",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "阿拉善盟",
                                "county": "阿拉善左旗",

                            },
                            {
                                "cityCode": "152922",
                                "cityName": "阿拉善右旗",
                                "parentId": "152900",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "阿拉善盟",
                                "county": "阿拉善右旗",

                            },
                            {
                                "cityCode": "152923",
                                "cityName": "额济纳旗",
                                "parentId": "152900",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "阿拉善盟",
                                "county": "额济纳旗",

                            },
                            {
                                "cityCode": "152971",
                                "cityName": "内蒙古阿拉善经济开发区",
                                "parentId": "152900",
                                "provinceType": "3",
                                "province": "内蒙古自治区",
                                "city": "阿拉善盟",
                                "county": "内蒙古阿拉善经济开发区",

                            }
                        ]
                    }
                ]
            },
            {
                "cityCode": "210000",
                "cityName": "辽宁省",
                "parentId": "100000",
                "provinceType": "1",
                "province": "辽宁省",
                "city": "",
                "county": "",
                "counties": [
                    {
                        "cityCode": "210100",
                        "cityName": "沈阳市",
                        "parentId": "210000",
                        "provinceType": "2",
                        "province": "辽宁省",
                        "city": "沈阳市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "210101",
                                "cityName": "沈阳市",
                                "parentId": "210100",
                                "provinceType": "2",
                                "province": "辽宁省",
                                "city": "沈阳市",
                                "county": "",

                            },
                            {
                                "cityCode": "210102",
                                "cityName": "和平区",
                                "parentId": "210100",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "沈阳市",
                                "county": "和平区",

                            },
                            {
                                "cityCode": "210103",
                                "cityName": "沈河区",
                                "parentId": "210100",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "沈阳市",
                                "county": "沈河区",

                            },
                            {
                                "cityCode": "210104",
                                "cityName": "大东区",
                                "parentId": "210100",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "沈阳市",
                                "county": "大东区",

                            },
                            {
                                "cityCode": "210105",
                                "cityName": "皇姑区",
                                "parentId": "210100",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "沈阳市",
                                "county": "皇姑区",

                            },
                            {
                                "cityCode": "210106",
                                "cityName": "铁西区",
                                "parentId": "210100",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "沈阳市",
                                "county": "铁西区",

                            },
                            {
                                "cityCode": "210111",
                                "cityName": "苏家屯区",
                                "parentId": "210100",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "沈阳市",
                                "county": "苏家屯区",

                            },
                            {
                                "cityCode": "210112",
                                "cityName": "浑南新区",
                                "parentId": "210100",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "沈阳市",
                                "county": "浑南新区",

                            },
                            {
                                "cityCode": "210113",
                                "cityName": "沈北新区",
                                "parentId": "210100",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "沈阳市",
                                "county": "沈北新区",

                            },
                            {
                                "cityCode": "210114",
                                "cityName": "于洪区",
                                "parentId": "210100",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "沈阳市",
                                "county": "于洪区",

                            },
                            {
                                "cityCode": "210115",
                                "cityName": "辽中区",
                                "parentId": "210100",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "沈阳市",
                                "county": "辽中区",

                            },
                            {
                                "cityCode": "210122",
                                "cityName": "辽中县",
                                "parentId": "210100",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "沈阳市",
                                "county": "辽中县",

                            },
                            {
                                "cityCode": "210123",
                                "cityName": "康平县",
                                "parentId": "210100",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "沈阳市",
                                "county": "康平县",

                            },
                            {
                                "cityCode": "210124",
                                "cityName": "法库县",
                                "parentId": "210100",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "沈阳市",
                                "county": "法库县",

                            },
                            {
                                "cityCode": "210181",
                                "cityName": "新民市",
                                "parentId": "210100",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "沈阳市",
                                "county": "新民市",

                            },
                            {
                                "cityCode": "210182",
                                "cityName": "高新区",
                                "parentId": "210100",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "沈阳市",
                                "county": "高新区",

                            }
                        ]
                    },
                    {
                        "cityCode": "210200",
                        "cityName": "大连市",
                        "parentId": "210000",
                        "provinceType": "2",
                        "province": "辽宁省",
                        "city": "大连市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "210201",
                                "cityName": "大连市",
                                "parentId": "210200",
                                "provinceType": "2",
                                "province": "辽宁省",
                                "city": "大连市",
                                "county": "",

                            },
                            {
                                "cityCode": "210202",
                                "cityName": "中山区",
                                "parentId": "210200",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "大连市",
                                "county": "中山区",

                            },
                            {
                                "cityCode": "210203",
                                "cityName": "西岗区",
                                "parentId": "210200",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "大连市",
                                "county": "西岗区",

                            },
                            {
                                "cityCode": "210204",
                                "cityName": "沙河口区",
                                "parentId": "210200",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "大连市",
                                "county": "沙河口区",

                            },
                            {
                                "cityCode": "210211",
                                "cityName": "甘井子区",
                                "parentId": "210200",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "大连市",
                                "county": "甘井子区",

                            },
                            {
                                "cityCode": "210212",
                                "cityName": "旅顺口区",
                                "parentId": "210200",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "大连市",
                                "county": "旅顺口区",

                            },
                            {
                                "cityCode": "210213",
                                "cityName": "金州区",
                                "parentId": "210200",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "大连市",
                                "county": "金州区",

                            },
                            {
                                "cityCode": "210214",
                                "cityName": "普兰店区",
                                "parentId": "210200",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "大连市",
                                "county": "普兰店区",

                            },
                            {
                                "cityCode": "210224",
                                "cityName": "长海县",
                                "parentId": "210200",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "大连市",
                                "county": "长海县",

                            },
                            {
                                "cityCode": "210281",
                                "cityName": "瓦房店市",
                                "parentId": "210200",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "大连市",
                                "county": "瓦房店市",

                            },
                            {
                                "cityCode": "210282",
                                "cityName": "普兰店市",
                                "parentId": "210200",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "大连市",
                                "county": "普兰店市",

                            },
                            {
                                "cityCode": "210283",
                                "cityName": "庄河市",
                                "parentId": "210200",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "大连市",
                                "county": "庄河市",

                            },
                            {
                                "cityCode": "210284",
                                "cityName": "高新区",
                                "parentId": "210200",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "大连市",
                                "county": "高新区",

                            },
                            {
                                "cityCode": "210285",
                                "cityName": "经济开发区",
                                "parentId": "210200",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "大连市",
                                "county": "经济开发区",

                            },
                            {
                                "cityCode": "210286",
                                "cityName": "金普新区",
                                "parentId": "210200",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "大连市",
                                "county": "金普新区",

                            }
                        ]
                    },
                    {
                        "cityCode": "210300",
                        "cityName": "鞍山市",
                        "parentId": "210000",
                        "provinceType": "2",
                        "province": "辽宁省",
                        "city": "鞍山市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "210301",
                                "cityName": "鞍山市",
                                "parentId": "210300",
                                "provinceType": "2",
                                "province": "辽宁省",
                                "city": "鞍山市",
                                "county": "",

                            },
                            {
                                "cityCode": "210302",
                                "cityName": "铁东区",
                                "parentId": "210300",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "鞍山市",
                                "county": "铁东区",

                            },
                            {
                                "cityCode": "210303",
                                "cityName": "铁西区",
                                "parentId": "210300",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "鞍山市",
                                "county": "铁西区",

                            },
                            {
                                "cityCode": "210304",
                                "cityName": "立山区",
                                "parentId": "210300",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "鞍山市",
                                "county": "立山区",

                            },
                            {
                                "cityCode": "210311",
                                "cityName": "千山区",
                                "parentId": "210300",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "鞍山市",
                                "county": "千山区",

                            },
                            {
                                "cityCode": "210321",
                                "cityName": "台安县",
                                "parentId": "210300",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "鞍山市",
                                "county": "台安县",

                            },
                            {
                                "cityCode": "210323",
                                "cityName": "岫岩满族自治县",
                                "parentId": "210300",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "鞍山市",
                                "county": "岫岩满族自治县",

                            },
                            {
                                "cityCode": "210381",
                                "cityName": "海城市",
                                "parentId": "210300",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "鞍山市",
                                "county": "海城市",

                            },
                            {
                                "cityCode": "210382",
                                "cityName": "高新区",
                                "parentId": "210300",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "鞍山市",
                                "county": "高新区",

                            }
                        ]
                    },
                    {
                        "cityCode": "210400",
                        "cityName": "抚顺市",
                        "parentId": "210000",
                        "provinceType": "2",
                        "province": "辽宁省",
                        "city": "抚顺市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "210401",
                                "cityName": "抚顺市",
                                "parentId": "210400",
                                "provinceType": "2",
                                "province": "辽宁省",
                                "city": "抚顺市",
                                "county": "",

                            },
                            {
                                "cityCode": "210402",
                                "cityName": "新抚区",
                                "parentId": "210400",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "抚顺市",
                                "county": "新抚区",

                            },
                            {
                                "cityCode": "210403",
                                "cityName": "东洲区",
                                "parentId": "210400",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "抚顺市",
                                "county": "东洲区",

                            },
                            {
                                "cityCode": "210404",
                                "cityName": "望花区",
                                "parentId": "210400",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "抚顺市",
                                "county": "望花区",

                            },
                            {
                                "cityCode": "210411",
                                "cityName": "顺城区",
                                "parentId": "210400",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "抚顺市",
                                "county": "顺城区",

                            },
                            {
                                "cityCode": "210421",
                                "cityName": "抚顺县",
                                "parentId": "210400",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "抚顺市",
                                "county": "抚顺县",

                            },
                            {
                                "cityCode": "210422",
                                "cityName": "新宾满族自治县",
                                "parentId": "210400",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "抚顺市",
                                "county": "新宾满族自治县",

                            },
                            {
                                "cityCode": "210423",
                                "cityName": "清原满族自治县",
                                "parentId": "210400",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "抚顺市",
                                "county": "清原满族自治县",

                            }
                        ]
                    },
                    {
                        "cityCode": "210500",
                        "cityName": "本溪市",
                        "parentId": "210000",
                        "provinceType": "2",
                        "province": "辽宁省",
                        "city": "本溪市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "210501",
                                "cityName": "本溪市",
                                "parentId": "210500",
                                "provinceType": "2",
                                "province": "辽宁省",
                                "city": "本溪市",
                                "county": "",

                            },
                            {
                                "cityCode": "210502",
                                "cityName": "平山区",
                                "parentId": "210500",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "本溪市",
                                "county": "平山区",

                            },
                            {
                                "cityCode": "210503",
                                "cityName": "溪湖区",
                                "parentId": "210500",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "本溪市",
                                "county": "溪湖区",

                            },
                            {
                                "cityCode": "210504",
                                "cityName": "明山区",
                                "parentId": "210500",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "本溪市",
                                "county": "明山区",

                            },
                            {
                                "cityCode": "210505",
                                "cityName": "南芬区",
                                "parentId": "210500",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "本溪市",
                                "county": "南芬区",

                            },
                            {
                                "cityCode": "210521",
                                "cityName": "本溪满族自治县",
                                "parentId": "210500",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "本溪市",
                                "county": "本溪满族自治县",

                            },
                            {
                                "cityCode": "210522",
                                "cityName": "桓仁满族自治县",
                                "parentId": "210500",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "本溪市",
                                "county": "桓仁满族自治县",

                            }
                        ]
                    },
                    {
                        "cityCode": "210600",
                        "cityName": "丹东市",
                        "parentId": "210000",
                        "provinceType": "2",
                        "province": "辽宁省",
                        "city": "丹东市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "210601",
                                "cityName": "丹东市",
                                "parentId": "210600",
                                "provinceType": "2",
                                "province": "辽宁省",
                                "city": "丹东市",
                                "county": "",

                            },
                            {
                                "cityCode": "210602",
                                "cityName": "元宝区",
                                "parentId": "210600",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "丹东市",
                                "county": "元宝区",

                            },
                            {
                                "cityCode": "210603",
                                "cityName": "振兴区",
                                "parentId": "210600",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "丹东市",
                                "county": "振兴区",

                            },
                            {
                                "cityCode": "210604",
                                "cityName": "振安区",
                                "parentId": "210600",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "丹东市",
                                "county": "振安区",

                            },
                            {
                                "cityCode": "210624",
                                "cityName": "宽甸满族自治县",
                                "parentId": "210600",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "丹东市",
                                "county": "宽甸满族自治县",

                            },
                            {
                                "cityCode": "210681",
                                "cityName": "东港市",
                                "parentId": "210600",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "丹东市",
                                "county": "东港市",

                            },
                            {
                                "cityCode": "210682",
                                "cityName": "凤城市",
                                "parentId": "210600",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "丹东市",
                                "county": "凤城市",

                            }
                        ]
                    },
                    {
                        "cityCode": "210700",
                        "cityName": "锦州市",
                        "parentId": "210000",
                        "provinceType": "2",
                        "province": "辽宁省",
                        "city": "锦州市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "210701",
                                "cityName": "锦州市",
                                "parentId": "210700",
                                "provinceType": "2",
                                "province": "辽宁省",
                                "city": "锦州市",
                                "county": "",

                            },
                            {
                                "cityCode": "210702",
                                "cityName": "古塔区",
                                "parentId": "210700",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "锦州市",
                                "county": "古塔区",

                            },
                            {
                                "cityCode": "210703",
                                "cityName": "凌河区",
                                "parentId": "210700",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "锦州市",
                                "county": "凌河区",

                            },
                            {
                                "cityCode": "210711",
                                "cityName": "太和区",
                                "parentId": "210700",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "锦州市",
                                "county": "太和区",

                            },
                            {
                                "cityCode": "210726",
                                "cityName": "黑山县",
                                "parentId": "210700",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "锦州市",
                                "county": "黑山县",

                            },
                            {
                                "cityCode": "210727",
                                "cityName": "义县",
                                "parentId": "210700",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "锦州市",
                                "county": "义县",

                            },
                            {
                                "cityCode": "210781",
                                "cityName": "凌海市",
                                "parentId": "210700",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "锦州市",
                                "county": "凌海市",

                            },
                            {
                                "cityCode": "210782",
                                "cityName": "北镇市",
                                "parentId": "210700",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "锦州市",
                                "county": "北镇市",

                            },
                            {
                                "cityCode": "210783",
                                "cityName": "松山新区",
                                "parentId": "210700",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "锦州市",
                                "county": "松山新区",

                            },
                            {
                                "cityCode": "210784",
                                "cityName": "龙栖湾新区",
                                "parentId": "210700",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "锦州市",
                                "county": "龙栖湾新区",

                            },
                            {
                                "cityCode": "210785",
                                "cityName": "经济技术开发区",
                                "parentId": "210700",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "锦州市",
                                "county": "经济技术开发区",

                            }
                        ]
                    },
                    {
                        "cityCode": "210800",
                        "cityName": "营口市",
                        "parentId": "210000",
                        "provinceType": "2",
                        "province": "辽宁省",
                        "city": "营口市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "210801",
                                "cityName": "营口市",
                                "parentId": "210800",
                                "provinceType": "2",
                                "province": "辽宁省",
                                "city": "营口市",
                                "county": "",

                            },
                            {
                                "cityCode": "210802",
                                "cityName": "站前区",
                                "parentId": "210800",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "营口市",
                                "county": "站前区",

                            },
                            {
                                "cityCode": "210803",
                                "cityName": "西市区",
                                "parentId": "210800",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "营口市",
                                "county": "西市区",

                            },
                            {
                                "cityCode": "210804",
                                "cityName": "鲅鱼圈区",
                                "parentId": "210800",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "营口市",
                                "county": "鲅鱼圈区",

                            },
                            {
                                "cityCode": "210811",
                                "cityName": "老边区",
                                "parentId": "210800",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "营口市",
                                "county": "老边区",

                            },
                            {
                                "cityCode": "210881",
                                "cityName": "盖州市",
                                "parentId": "210800",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "营口市",
                                "county": "盖州市",

                            },
                            {
                                "cityCode": "210882",
                                "cityName": "大石桥市",
                                "parentId": "210800",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "营口市",
                                "county": "大石桥市",

                            }
                        ]
                    },
                    {
                        "cityCode": "210900",
                        "cityName": "阜新市",
                        "parentId": "210000",
                        "provinceType": "2",
                        "province": "辽宁省",
                        "city": "阜新市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "210901",
                                "cityName": "阜新市",
                                "parentId": "210900",
                                "provinceType": "2",
                                "province": "辽宁省",
                                "city": "阜新市",
                                "county": "",

                            },
                            {
                                "cityCode": "210902",
                                "cityName": "海州区",
                                "parentId": "210900",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "阜新市",
                                "county": "海州区",

                            },
                            {
                                "cityCode": "210903",
                                "cityName": "新邱区",
                                "parentId": "210900",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "阜新市",
                                "county": "新邱区",

                            },
                            {
                                "cityCode": "210904",
                                "cityName": "太平区",
                                "parentId": "210900",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "阜新市",
                                "county": "太平区",

                            },
                            {
                                "cityCode": "210905",
                                "cityName": "清河门区",
                                "parentId": "210900",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "阜新市",
                                "county": "清河门区",

                            },
                            {
                                "cityCode": "210911",
                                "cityName": "细河区",
                                "parentId": "210900",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "阜新市",
                                "county": "细河区",

                            },
                            {
                                "cityCode": "210921",
                                "cityName": "阜新蒙古族自治县",
                                "parentId": "210900",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "阜新市",
                                "county": "阜新蒙古族自治县",

                            },
                            {
                                "cityCode": "210922",
                                "cityName": "彰武县",
                                "parentId": "210900",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "阜新市",
                                "county": "彰武县",

                            }
                        ]
                    },
                    {
                        "cityCode": "211000",
                        "cityName": "辽阳市",
                        "parentId": "210000",
                        "provinceType": "2",
                        "province": "辽宁省",
                        "city": "辽阳市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "211001",
                                "cityName": "辽阳市",
                                "parentId": "211000",
                                "provinceType": "2",
                                "province": "辽宁省",
                                "city": "辽阳市",
                                "county": "",

                            },
                            {
                                "cityCode": "211002",
                                "cityName": "白塔区",
                                "parentId": "211000",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "辽阳市",
                                "county": "白塔区",

                            },
                            {
                                "cityCode": "211003",
                                "cityName": "文圣区",
                                "parentId": "211000",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "辽阳市",
                                "county": "文圣区",

                            },
                            {
                                "cityCode": "211004",
                                "cityName": "宏伟区",
                                "parentId": "211000",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "辽阳市",
                                "county": "宏伟区",

                            },
                            {
                                "cityCode": "211005",
                                "cityName": "弓长岭区",
                                "parentId": "211000",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "辽阳市",
                                "county": "弓长岭区",

                            },
                            {
                                "cityCode": "211011",
                                "cityName": "太子河区",
                                "parentId": "211000",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "辽阳市",
                                "county": "太子河区",

                            },
                            {
                                "cityCode": "211021",
                                "cityName": "辽阳县",
                                "parentId": "211000",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "辽阳市",
                                "county": "辽阳县",

                            },
                            {
                                "cityCode": "211081",
                                "cityName": "灯塔市",
                                "parentId": "211000",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "辽阳市",
                                "county": "灯塔市",

                            }
                        ]
                    },
                    {
                        "cityCode": "211100",
                        "cityName": "盘锦市",
                        "parentId": "210000",
                        "provinceType": "2",
                        "province": "辽宁省",
                        "city": "盘锦市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "211101",
                                "cityName": "盘锦市",
                                "parentId": "211100",
                                "provinceType": "2",
                                "province": "辽宁省",
                                "city": "盘锦市",
                                "county": "",

                            },
                            {
                                "cityCode": "211102",
                                "cityName": "双台子区",
                                "parentId": "211100",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "盘锦市",
                                "county": "双台子区",

                            },
                            {
                                "cityCode": "211103",
                                "cityName": "兴隆台区",
                                "parentId": "211100",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "盘锦市",
                                "county": "兴隆台区",

                            },
                            {
                                "cityCode": "211104",
                                "cityName": "大洼区",
                                "parentId": "211100",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "盘锦市",
                                "county": "大洼区",

                            },
                            {
                                "cityCode": "211121",
                                "cityName": "大洼区",
                                "parentId": "211100",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "盘锦市",
                                "county": "大洼区",

                            },
                            {
                                "cityCode": "211122",
                                "cityName": "盘山县",
                                "parentId": "211100",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "盘锦市",
                                "county": "盘山县",

                            }
                        ]
                    },
                    {
                        "cityCode": "211200",
                        "cityName": "铁岭市",
                        "parentId": "210000",
                        "provinceType": "2",
                        "province": "辽宁省",
                        "city": "铁岭市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "211201",
                                "cityName": "铁岭市",
                                "parentId": "211200",
                                "provinceType": "2",
                                "province": "辽宁省",
                                "city": "铁岭市",
                                "county": "",

                            },
                            {
                                "cityCode": "211202",
                                "cityName": "银州区",
                                "parentId": "211200",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "铁岭市",
                                "county": "银州区",

                            },
                            {
                                "cityCode": "211204",
                                "cityName": "清河区",
                                "parentId": "211200",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "铁岭市",
                                "county": "清河区",

                            },
                            {
                                "cityCode": "211221",
                                "cityName": "铁岭县",
                                "parentId": "211200",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "铁岭市",
                                "county": "铁岭县",

                            },
                            {
                                "cityCode": "211223",
                                "cityName": "西丰县",
                                "parentId": "211200",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "铁岭市",
                                "county": "西丰县",

                            },
                            {
                                "cityCode": "211224",
                                "cityName": "昌图县",
                                "parentId": "211200",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "铁岭市",
                                "county": "昌图县",

                            },
                            {
                                "cityCode": "211281",
                                "cityName": "调兵山市",
                                "parentId": "211200",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "铁岭市",
                                "county": "调兵山市",

                            },
                            {
                                "cityCode": "211282",
                                "cityName": "开原市",
                                "parentId": "211200",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "铁岭市",
                                "county": "开原市",

                            }
                        ]
                    },
                    {
                        "cityCode": "211300",
                        "cityName": "朝阳市",
                        "parentId": "210000",
                        "provinceType": "2",
                        "province": "辽宁省",
                        "city": "朝阳市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "211301",
                                "cityName": "朝阳市",
                                "parentId": "211300",
                                "provinceType": "2",
                                "province": "辽宁省",
                                "city": "朝阳市",
                                "county": "",

                            },
                            {
                                "cityCode": "211302",
                                "cityName": "双塔区",
                                "parentId": "211300",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "朝阳市",
                                "county": "双塔区",

                            },
                            {
                                "cityCode": "211303",
                                "cityName": "龙城区",
                                "parentId": "211300",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "朝阳市",
                                "county": "龙城区",

                            },
                            {
                                "cityCode": "211321",
                                "cityName": "朝阳县",
                                "parentId": "211300",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "朝阳市",
                                "county": "朝阳县",

                            },
                            {
                                "cityCode": "211322",
                                "cityName": "建平县",
                                "parentId": "211300",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "朝阳市",
                                "county": "建平县",

                            },
                            {
                                "cityCode": "211324",
                                "cityName": "喀喇沁左翼蒙古族自治县",
                                "parentId": "211300",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "朝阳市",
                                "county": "喀喇沁左翼蒙古族自治县",

                            },
                            {
                                "cityCode": "211381",
                                "cityName": "北票市",
                                "parentId": "211300",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "朝阳市",
                                "county": "北票市",

                            },
                            {
                                "cityCode": "211382",
                                "cityName": "凌源市",
                                "parentId": "211300",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "朝阳市",
                                "county": "凌源市",

                            }
                        ]
                    },
                    {
                        "cityCode": "211400",
                        "cityName": "葫芦岛市",
                        "parentId": "210000",
                        "provinceType": "2",
                        "province": "辽宁省",
                        "city": "葫芦岛市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "211401",
                                "cityName": "葫芦岛市",
                                "parentId": "211400",
                                "provinceType": "2",
                                "province": "辽宁省",
                                "city": "葫芦岛市",
                                "county": "",

                            },
                            {
                                "cityCode": "211402",
                                "cityName": "连山区",
                                "parentId": "211400",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "葫芦岛市",
                                "county": "连山区",

                            },
                            {
                                "cityCode": "211403",
                                "cityName": "龙港区",
                                "parentId": "211400",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "葫芦岛市",
                                "county": "龙港区",

                            },
                            {
                                "cityCode": "211404",
                                "cityName": "南票区",
                                "parentId": "211400",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "葫芦岛市",
                                "county": "南票区",

                            },
                            {
                                "cityCode": "211421",
                                "cityName": "绥中县",
                                "parentId": "211400",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "葫芦岛市",
                                "county": "绥中县",

                            },
                            {
                                "cityCode": "211422",
                                "cityName": "建昌县",
                                "parentId": "211400",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "葫芦岛市",
                                "county": "建昌县",

                            },
                            {
                                "cityCode": "211481",
                                "cityName": "兴城市",
                                "parentId": "211400",
                                "provinceType": "3",
                                "province": "辽宁省",
                                "city": "葫芦岛市",
                                "county": "兴城市",

                            }
                        ]
                    }
                ]
            },
            {
                "cityCode": "220000",
                "cityName": "吉林省",
                "parentId": "100000",
                "provinceType": "1",
                "province": "吉林省",
                "city": "",
                "county": "",
                "counties": [
                    {
                        "cityCode": "220100",
                        "cityName": "长春市",
                        "parentId": "220000",
                        "provinceType": "2",
                        "province": "吉林省",
                        "city": "长春市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "220101",
                                "cityName": "长春市",
                                "parentId": "220100",
                                "provinceType": "2",
                                "province": "吉林省",
                                "city": "长春市",
                                "county": "",

                            },
                            {
                                "cityCode": "220102",
                                "cityName": "南关区",
                                "parentId": "220100",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "长春市",
                                "county": "南关区",

                            },
                            {
                                "cityCode": "220103",
                                "cityName": "宽城区",
                                "parentId": "220100",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "长春市",
                                "county": "宽城区",

                            },
                            {
                                "cityCode": "220104",
                                "cityName": "朝阳区",
                                "parentId": "220100",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "长春市",
                                "county": "朝阳区",

                            },
                            {
                                "cityCode": "220105",
                                "cityName": "二道区",
                                "parentId": "220100",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "长春市",
                                "county": "二道区",

                            },
                            {
                                "cityCode": "220106",
                                "cityName": "绿园区",
                                "parentId": "220100",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "长春市",
                                "county": "绿园区",

                            },
                            {
                                "cityCode": "220112",
                                "cityName": "双阳区",
                                "parentId": "220100",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "长春市",
                                "county": "双阳区",

                            },
                            {
                                "cityCode": "220113",
                                "cityName": "九台区",
                                "parentId": "220100",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "长春市",
                                "county": "九台区",

                            },
                            {
                                "cityCode": "220122",
                                "cityName": "农安县",
                                "parentId": "220100",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "长春市",
                                "county": "农安县",

                            },
                            {
                                "cityCode": "220171",
                                "cityName": "长春经济技术开发区",
                                "parentId": "220100",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "长春市",
                                "county": "长春经济技术开发区",

                            },
                            {
                                "cityCode": "220172",
                                "cityName": "长春净月高新技术产业开发区",
                                "parentId": "220100",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "长春市",
                                "county": "长春净月高新技术产业开发区",

                            },
                            {
                                "cityCode": "220173",
                                "cityName": "长春高新技术产业开发区",
                                "parentId": "220100",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "长春市",
                                "county": "长春高新技术产业开发区",

                            },
                            {
                                "cityCode": "220174",
                                "cityName": "长春汽车经济技术开发区",
                                "parentId": "220100",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "长春市",
                                "county": "长春汽车经济技术开发区",

                            },
                            {
                                "cityCode": "220181",
                                "cityName": "九台市",
                                "parentId": "220100",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "长春市",
                                "county": "九台市",

                            },
                            {
                                "cityCode": "220182",
                                "cityName": "榆树市",
                                "parentId": "220100",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "长春市",
                                "county": "榆树市",

                            },
                            {
                                "cityCode": "220183",
                                "cityName": "德惠市",
                                "parentId": "220100",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "长春市",
                                "county": "德惠市",

                            },
                            {
                                "cityCode": "220184",
                                "cityName": "公主岭市",
                                "parentId": "220100",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "四平市",
                                "county": "公主岭市",

                            },
                            {
                                "cityCode": "220185",
                                "cityName": "高新技术产业开发区",
                                "parentId": "220100",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "长春市",
                                "county": "高新技术产业开发区",

                            },
                            {
                                "cityCode": "220186",
                                "cityName": "经济技术开发区",
                                "parentId": "220100",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "长春市",
                                "county": "经济技术开发区",

                            },
                            {
                                "cityCode": "220187",
                                "cityName": "汽车产业开发区",
                                "parentId": "220100",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "长春市",
                                "county": "汽车产业开发区",

                            },
                            {
                                "cityCode": "220188",
                                "cityName": "兴隆综合保税区",
                                "parentId": "220100",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "长春市",
                                "county": "兴隆综合保税区",

                            }
                        ]
                    },
                    {
                        "cityCode": "220200",
                        "cityName": "吉林市",
                        "parentId": "220000",
                        "provinceType": "2",
                        "province": "吉林省",
                        "city": "吉林市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "220201",
                                "cityName": "吉林市",
                                "parentId": "220200",
                                "provinceType": "2",
                                "province": "吉林省",
                                "city": "吉林市",
                                "county": "",

                            },
                            {
                                "cityCode": "220202",
                                "cityName": "昌邑区",
                                "parentId": "220200",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "吉林市",
                                "county": "昌邑区",

                            },
                            {
                                "cityCode": "220203",
                                "cityName": "龙潭区",
                                "parentId": "220200",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "吉林市",
                                "county": "龙潭区",

                            },
                            {
                                "cityCode": "220204",
                                "cityName": "船营区",
                                "parentId": "220200",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "吉林市",
                                "county": "船营区",

                            },
                            {
                                "cityCode": "220211",
                                "cityName": "丰满区",
                                "parentId": "220200",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "吉林市",
                                "county": "丰满区",

                            },
                            {
                                "cityCode": "220221",
                                "cityName": "永吉县",
                                "parentId": "220200",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "吉林市",
                                "county": "永吉县",

                            },
                            {
                                "cityCode": "220271",
                                "cityName": "吉林经济开发区",
                                "parentId": "220200",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "吉林市",
                                "county": "吉林经济开发区",

                            },
                            {
                                "cityCode": "220272",
                                "cityName": "吉林高新技术产业开发区",
                                "parentId": "220200",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "吉林市",
                                "county": "吉林高新技术产业开发区",

                            },
                            {
                                "cityCode": "220273",
                                "cityName": "吉林中国新加坡食品区",
                                "parentId": "220200",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "吉林市",
                                "county": "吉林中国新加坡食品区",

                            },
                            {
                                "cityCode": "220281",
                                "cityName": "蛟河市",
                                "parentId": "220200",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "吉林市",
                                "county": "蛟河市",

                            },
                            {
                                "cityCode": "220282",
                                "cityName": "桦甸市",
                                "parentId": "220200",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "吉林市",
                                "county": "桦甸市",

                            },
                            {
                                "cityCode": "220283",
                                "cityName": "舒兰市",
                                "parentId": "220200",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "吉林市",
                                "county": "舒兰市",

                            },
                            {
                                "cityCode": "220284",
                                "cityName": "磐石市",
                                "parentId": "220200",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "吉林市",
                                "county": "磐石市",

                            },
                            {
                                "cityCode": "220285",
                                "cityName": "高新区",
                                "parentId": "220200",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "吉林市",
                                "county": "高新区",

                            }
                        ]
                    },
                    {
                        "cityCode": "220300",
                        "cityName": "四平市",
                        "parentId": "220000",
                        "provinceType": "2",
                        "province": "吉林省",
                        "city": "四平市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "220301",
                                "cityName": "四平市",
                                "parentId": "220300",
                                "provinceType": "2",
                                "province": "吉林省",
                                "city": "四平市",
                                "county": "",

                            },
                            {
                                "cityCode": "220302",
                                "cityName": "铁西区",
                                "parentId": "220300",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "四平市",
                                "county": "铁西区",

                            },
                            {
                                "cityCode": "220303",
                                "cityName": "铁东区",
                                "parentId": "220300",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "四平市",
                                "county": "铁东区",

                            },
                            {
                                "cityCode": "220322",
                                "cityName": "梨树县",
                                "parentId": "220300",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "四平市",
                                "county": "梨树县",

                            },
                            {
                                "cityCode": "220323",
                                "cityName": "伊通满族自治县",
                                "parentId": "220300",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "四平市",
                                "county": "伊通满族自治县",

                            },
                            {
                                "cityCode": "220381",
                                "cityName": "公主岭市",
                                "parentId": "220300",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "四平市",
                                "county": "公主岭市",

                            },
                            {
                                "cityCode": "220382",
                                "cityName": "双辽市",
                                "parentId": "220300",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "四平市",
                                "county": "双辽市",

                            }
                        ]
                    },
                    {
                        "cityCode": "220400",
                        "cityName": "辽源市",
                        "parentId": "220000",
                        "provinceType": "2",
                        "province": "吉林省",
                        "city": "辽源市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "220401",
                                "cityName": "辽源市",
                                "parentId": "220400",
                                "provinceType": "2",
                                "province": "吉林省",
                                "city": "辽源市",
                                "county": "",

                            },
                            {
                                "cityCode": "220402",
                                "cityName": "龙山区",
                                "parentId": "220400",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "辽源市",
                                "county": "龙山区",

                            },
                            {
                                "cityCode": "220403",
                                "cityName": "西安区",
                                "parentId": "220400",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "辽源市",
                                "county": "西安区",

                            },
                            {
                                "cityCode": "220421",
                                "cityName": "东丰县",
                                "parentId": "220400",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "辽源市",
                                "county": "东丰县",

                            },
                            {
                                "cityCode": "220422",
                                "cityName": "东辽县",
                                "parentId": "220400",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "辽源市",
                                "county": "东辽县",

                            }
                        ]
                    },
                    {
                        "cityCode": "220500",
                        "cityName": "通化市",
                        "parentId": "220000",
                        "provinceType": "2",
                        "province": "吉林省",
                        "city": "通化市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "220501",
                                "cityName": "通化市",
                                "parentId": "220500",
                                "provinceType": "2",
                                "province": "吉林省",
                                "city": "通化市",
                                "county": "",

                            },
                            {
                                "cityCode": "220502",
                                "cityName": "东昌区",
                                "parentId": "220500",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "通化市",
                                "county": "东昌区",

                            },
                            {
                                "cityCode": "220503",
                                "cityName": "二道江区",
                                "parentId": "220500",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "通化市",
                                "county": "二道江区",

                            },
                            {
                                "cityCode": "220521",
                                "cityName": "通化县",
                                "parentId": "220500",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "通化市",
                                "county": "通化县",

                            },
                            {
                                "cityCode": "220523",
                                "cityName": "辉南县",
                                "parentId": "220500",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "通化市",
                                "county": "辉南县",

                            },
                            {
                                "cityCode": "220524",
                                "cityName": "柳河县",
                                "parentId": "220500",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "通化市",
                                "county": "柳河县",

                            },
                            {
                                "cityCode": "220581",
                                "cityName": "梅河口市",
                                "parentId": "220500",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "通化市",
                                "county": "梅河口市",

                            },
                            {
                                "cityCode": "220582",
                                "cityName": "集安市",
                                "parentId": "220500",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "通化市",
                                "county": "集安市",

                            }
                        ]
                    },
                    {
                        "cityCode": "220600",
                        "cityName": "白山市",
                        "parentId": "220000",
                        "provinceType": "2",
                        "province": "吉林省",
                        "city": "白山市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "220601",
                                "cityName": "白山市",
                                "parentId": "220600",
                                "provinceType": "2",
                                "province": "吉林省",
                                "city": "白山市",
                                "county": "",

                            },
                            {
                                "cityCode": "220602",
                                "cityName": "浑江区",
                                "parentId": "220600",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "白山市",
                                "county": "浑江区",

                            },
                            {
                                "cityCode": "220605",
                                "cityName": "江源区",
                                "parentId": "220600",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "白山市",
                                "county": "江源区",

                            },
                            {
                                "cityCode": "220621",
                                "cityName": "抚松县",
                                "parentId": "220600",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "白山市",
                                "county": "抚松县",

                            },
                            {
                                "cityCode": "220622",
                                "cityName": "靖宇县",
                                "parentId": "220600",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "白山市",
                                "county": "靖宇县",

                            },
                            {
                                "cityCode": "220623",
                                "cityName": "长白朝鲜族自治县",
                                "parentId": "220600",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "白山市",
                                "county": "长白朝鲜族自治县",

                            },
                            {
                                "cityCode": "220625",
                                "cityName": "江源区",
                                "parentId": "220600",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "白山市",
                                "county": "江源区",

                            },
                            {
                                "cityCode": "220681",
                                "cityName": "临江市",
                                "parentId": "220600",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "白山市",
                                "county": "临江市",

                            }
                        ]
                    },
                    {
                        "cityCode": "220700",
                        "cityName": "松原市",
                        "parentId": "220000",
                        "provinceType": "2",
                        "province": "吉林省",
                        "city": "松原市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "220701",
                                "cityName": "松原市",
                                "parentId": "220700",
                                "provinceType": "2",
                                "province": "吉林省",
                                "city": "松原市",
                                "county": "",

                            },
                            {
                                "cityCode": "220702",
                                "cityName": "宁江区",
                                "parentId": "220700",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "松原市",
                                "county": "宁江区",

                            },
                            {
                                "cityCode": "220721",
                                "cityName": "前郭尔罗斯蒙古族自治县",
                                "parentId": "220700",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "松原市",
                                "county": "前郭尔罗斯蒙古族自治县",

                            },
                            {
                                "cityCode": "220722",
                                "cityName": "长岭县",
                                "parentId": "220700",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "松原市",
                                "county": "长岭县",

                            },
                            {
                                "cityCode": "220723",
                                "cityName": "乾安县",
                                "parentId": "220700",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "松原市",
                                "county": "乾安县",

                            },
                            {
                                "cityCode": "220724",
                                "cityName": "扶余县",
                                "parentId": "220700",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "松原市",
                                "county": "扶余县",

                            },
                            {
                                "cityCode": "220771",
                                "cityName": "吉林松原经济开发区",
                                "parentId": "220700",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "松原市",
                                "county": "吉林松原经济开发区",

                            },
                            {
                                "cityCode": "220781",
                                "cityName": "扶余市",
                                "parentId": "220700",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "松原市",
                                "county": "扶余市",

                            }
                        ]
                    },
                    {
                        "cityCode": "220800",
                        "cityName": "白城市",
                        "parentId": "220000",
                        "provinceType": "2",
                        "province": "吉林省",
                        "city": "白城市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "220801",
                                "cityName": "白城市",
                                "parentId": "220800",
                                "provinceType": "2",
                                "province": "吉林省",
                                "city": "白城市",
                                "county": "",

                            },
                            {
                                "cityCode": "220802",
                                "cityName": "洮北区",
                                "parentId": "220800",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "白城市",
                                "county": "洮北区",

                            },
                            {
                                "cityCode": "220821",
                                "cityName": "镇赉县",
                                "parentId": "220800",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "白城市",
                                "county": "镇赉县",

                            },
                            {
                                "cityCode": "220822",
                                "cityName": "通榆县",
                                "parentId": "220800",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "白城市",
                                "county": "通榆县",

                            },
                            {
                                "cityCode": "220871",
                                "cityName": "吉林白城经济开发区",
                                "parentId": "220800",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "白城市",
                                "county": "吉林白城经济开发区",

                            },
                            {
                                "cityCode": "220881",
                                "cityName": "洮南市",
                                "parentId": "220800",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "白城市",
                                "county": "洮南市",

                            },
                            {
                                "cityCode": "220882",
                                "cityName": "大安市",
                                "parentId": "220800",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "白城市",
                                "county": "大安市",

                            }
                        ]
                    },
                    {
                        "cityCode": "222400",
                        "cityName": "延边朝鲜族自治州",
                        "parentId": "220000",
                        "provinceType": "2",
                        "province": "吉林省",
                        "city": "延边朝鲜族自治州",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "222401",
                                "cityName": "延吉市",
                                "parentId": "222400",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "延边朝鲜族自治州",
                                "county": "延吉市",

                            },
                            {
                                "cityCode": "222402",
                                "cityName": "图们市",
                                "parentId": "222400",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "延边朝鲜族自治州",
                                "county": "图们市",

                            },
                            {
                                "cityCode": "222403",
                                "cityName": "敦化市",
                                "parentId": "222400",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "延边朝鲜族自治州",
                                "county": "敦化市",

                            },
                            {
                                "cityCode": "222404",
                                "cityName": "珲春市",
                                "parentId": "222400",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "延边朝鲜族自治州",
                                "county": "珲春市",

                            },
                            {
                                "cityCode": "222405",
                                "cityName": "龙井市",
                                "parentId": "222400",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "延边朝鲜族自治州",
                                "county": "龙井市",

                            },
                            {
                                "cityCode": "222406",
                                "cityName": "和龙市",
                                "parentId": "222400",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "延边朝鲜族自治州",
                                "county": "和龙市",

                            },
                            {
                                "cityCode": "222424",
                                "cityName": "汪清县",
                                "parentId": "222400",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "延边朝鲜族自治州",
                                "county": "汪清县",

                            },
                            {
                                "cityCode": "222426",
                                "cityName": "安图县",
                                "parentId": "222400",
                                "provinceType": "3",
                                "province": "吉林省",
                                "city": "延边朝鲜族自治州",
                                "county": "安图县",

                            }
                        ]
                    }
                ]
            },
            {
                "cityCode": "230000",
                "cityName": "黑龙江省",
                "parentId": "100000",
                "provinceType": "1",
                "province": "黑龙江省",
                "city": "",
                "county": "",
                "counties": [
                    {
                        "cityCode": "230100",
                        "cityName": "哈尔滨市",
                        "parentId": "230000",
                        "provinceType": "2",
                        "province": "黑龙江省",
                        "city": "哈尔滨市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "230101",
                                "cityName": "哈尔滨市",
                                "parentId": "230100",
                                "provinceType": "2",
                                "province": "黑龙江省",
                                "city": "哈尔滨市",
                                "county": "",

                            },
                            {
                                "cityCode": "230102",
                                "cityName": "道里区",
                                "parentId": "230100",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "哈尔滨市",
                                "county": "道里区",

                            },
                            {
                                "cityCode": "230103",
                                "cityName": "南岗区",
                                "parentId": "230100",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "哈尔滨市",
                                "county": "南岗区",

                            },
                            {
                                "cityCode": "230104",
                                "cityName": "道外区",
                                "parentId": "230100",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "哈尔滨市",
                                "county": "道外区",

                            },
                            {
                                "cityCode": "230108",
                                "cityName": "平房区",
                                "parentId": "230100",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "哈尔滨市",
                                "county": "平房区",

                            },
                            {
                                "cityCode": "230109",
                                "cityName": "松北区",
                                "parentId": "230100",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "哈尔滨市",
                                "county": "松北区",

                            },
                            {
                                "cityCode": "230110",
                                "cityName": "香坊区",
                                "parentId": "230100",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "哈尔滨市",
                                "county": "香坊区",

                            },
                            {
                                "cityCode": "230111",
                                "cityName": "呼兰区",
                                "parentId": "230100",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "哈尔滨市",
                                "county": "呼兰区",

                            },
                            {
                                "cityCode": "230112",
                                "cityName": "阿城区",
                                "parentId": "230100",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "哈尔滨市",
                                "county": "阿城区",

                            },
                            {
                                "cityCode": "230113",
                                "cityName": "双城区",
                                "parentId": "230100",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "哈尔滨市",
                                "county": "双城区",

                            },
                            {
                                "cityCode": "230123",
                                "cityName": "依兰县",
                                "parentId": "230100",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "哈尔滨市",
                                "county": "依兰县",

                            },
                            {
                                "cityCode": "230124",
                                "cityName": "方正县",
                                "parentId": "230100",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "哈尔滨市",
                                "county": "方正县",

                            },
                            {
                                "cityCode": "230125",
                                "cityName": "宾县",
                                "parentId": "230100",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "哈尔滨市",
                                "county": "宾县",

                            },
                            {
                                "cityCode": "230126",
                                "cityName": "巴彦县",
                                "parentId": "230100",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "哈尔滨市",
                                "county": "巴彦县",

                            },
                            {
                                "cityCode": "230127",
                                "cityName": "木兰县",
                                "parentId": "230100",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "哈尔滨市",
                                "county": "木兰县",

                            },
                            {
                                "cityCode": "230128",
                                "cityName": "通河县",
                                "parentId": "230100",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "哈尔滨市",
                                "county": "通河县",

                            },
                            {
                                "cityCode": "230129",
                                "cityName": "延寿县",
                                "parentId": "230100",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "哈尔滨市",
                                "county": "延寿县",

                            },
                            {
                                "cityCode": "230182",
                                "cityName": "双城市",
                                "parentId": "230100",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "哈尔滨市",
                                "county": "双城市",

                            },
                            {
                                "cityCode": "230183",
                                "cityName": "尚志市",
                                "parentId": "230100",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "哈尔滨市",
                                "county": "尚志市",

                            },
                            {
                                "cityCode": "230184",
                                "cityName": "五常市",
                                "parentId": "230100",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "哈尔滨市",
                                "county": "五常市",

                            },
                            {
                                "cityCode": "230185",
                                "cityName": "哈尔滨新区",
                                "parentId": "230100",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "哈尔滨市",
                                "county": "哈尔滨新区",

                            },
                            {
                                "cityCode": "230186",
                                "cityName": "高新区",
                                "parentId": "230100",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "哈尔滨市",
                                "county": "高新区",

                            },
                            {
                                "cityCode": "233000",
                                "cityName": "南岗区",
                                "parentId": "230100",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "哈尔滨市",
                                "county": "南岗区",

                            }
                        ]
                    },
                    {
                        "cityCode": "230200",
                        "cityName": "齐齐哈尔市",
                        "parentId": "230000",
                        "provinceType": "2",
                        "province": "黑龙江省",
                        "city": "齐齐哈尔市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "230201",
                                "cityName": "齐齐哈尔市",
                                "parentId": "230200",
                                "provinceType": "2",
                                "province": "黑龙江省",
                                "city": "齐齐哈尔市",
                                "county": "",

                            },
                            {
                                "cityCode": "230202",
                                "cityName": "龙沙区",
                                "parentId": "230200",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "齐齐哈尔市",
                                "county": "龙沙区",

                            },
                            {
                                "cityCode": "230203",
                                "cityName": "建华区",
                                "parentId": "230200",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "齐齐哈尔市",
                                "county": "建华区",

                            },
                            {
                                "cityCode": "230204",
                                "cityName": "铁锋区",
                                "parentId": "230200",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "齐齐哈尔市",
                                "county": "铁锋区",

                            },
                            {
                                "cityCode": "230205",
                                "cityName": "昂昂溪区",
                                "parentId": "230200",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "齐齐哈尔市",
                                "county": "昂昂溪区",

                            },
                            {
                                "cityCode": "230206",
                                "cityName": "富拉尔基区",
                                "parentId": "230200",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "齐齐哈尔市",
                                "county": "富拉尔基区",

                            },
                            {
                                "cityCode": "230207",
                                "cityName": "碾子山区",
                                "parentId": "230200",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "齐齐哈尔市",
                                "county": "碾子山区",

                            },
                            {
                                "cityCode": "230208",
                                "cityName": "梅里斯达斡尔族区",
                                "parentId": "230200",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "齐齐哈尔市",
                                "county": "梅里斯达斡尔族区",

                            },
                            {
                                "cityCode": "230221",
                                "cityName": "龙江县",
                                "parentId": "230200",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "齐齐哈尔市",
                                "county": "龙江县",

                            },
                            {
                                "cityCode": "230223",
                                "cityName": "依安县",
                                "parentId": "230200",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "齐齐哈尔市",
                                "county": "依安县",

                            },
                            {
                                "cityCode": "230224",
                                "cityName": "泰来县",
                                "parentId": "230200",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "齐齐哈尔市",
                                "county": "泰来县",

                            },
                            {
                                "cityCode": "230225",
                                "cityName": "甘南县",
                                "parentId": "230200",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "齐齐哈尔市",
                                "county": "甘南县",

                            },
                            {
                                "cityCode": "230227",
                                "cityName": "富裕县",
                                "parentId": "230200",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "齐齐哈尔市",
                                "county": "富裕县",

                            },
                            {
                                "cityCode": "230229",
                                "cityName": "克山县",
                                "parentId": "230200",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "齐齐哈尔市",
                                "county": "克山县",

                            },
                            {
                                "cityCode": "230230",
                                "cityName": "克东县",
                                "parentId": "230200",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "齐齐哈尔市",
                                "county": "克东县",

                            },
                            {
                                "cityCode": "230231",
                                "cityName": "拜泉县",
                                "parentId": "230200",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "齐齐哈尔市",
                                "county": "拜泉县",

                            },
                            {
                                "cityCode": "230281",
                                "cityName": "讷河市",
                                "parentId": "230200",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "齐齐哈尔市",
                                "county": "讷河市",

                            },
                            {
                                "cityCode": "230282",
                                "cityName": "高新区",
                                "parentId": "230200",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "齐齐哈尔市",
                                "county": "高新区",

                            }
                        ]
                    },
                    {
                        "cityCode": "230300",
                        "cityName": "鸡西市",
                        "parentId": "230000",
                        "provinceType": "2",
                        "province": "黑龙江省",
                        "city": "鸡西市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "230301",
                                "cityName": "鸡西市",
                                "parentId": "230300",
                                "provinceType": "2",
                                "province": "黑龙江省",
                                "city": "鸡西市",
                                "county": "",

                            },
                            {
                                "cityCode": "230302",
                                "cityName": "鸡冠区",
                                "parentId": "230300",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "鸡西市",
                                "county": "鸡冠区",

                            },
                            {
                                "cityCode": "230303",
                                "cityName": "恒山区",
                                "parentId": "230300",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "鸡西市",
                                "county": "恒山区",

                            },
                            {
                                "cityCode": "230304",
                                "cityName": "滴道区",
                                "parentId": "230300",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "鸡西市",
                                "county": "滴道区",

                            },
                            {
                                "cityCode": "230305",
                                "cityName": "梨树区",
                                "parentId": "230300",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "鸡西市",
                                "county": "梨树区",

                            },
                            {
                                "cityCode": "230306",
                                "cityName": "城子河区",
                                "parentId": "230300",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "鸡西市",
                                "county": "城子河区",

                            },
                            {
                                "cityCode": "230307",
                                "cityName": "麻山区",
                                "parentId": "230300",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "鸡西市",
                                "county": "麻山区",

                            },
                            {
                                "cityCode": "230321",
                                "cityName": "鸡东县",
                                "parentId": "230300",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "鸡西市",
                                "county": "鸡东县",

                            },
                            {
                                "cityCode": "230381",
                                "cityName": "虎林市",
                                "parentId": "230300",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "鸡西市",
                                "county": "虎林市",

                            },
                            {
                                "cityCode": "230382",
                                "cityName": "密山市",
                                "parentId": "230300",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "鸡西市",
                                "county": "密山市",

                            }
                        ]
                    },
                    {
                        "cityCode": "230400",
                        "cityName": "鹤岗市",
                        "parentId": "230000",
                        "provinceType": "2",
                        "province": "黑龙江省",
                        "city": "鹤岗市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "230401",
                                "cityName": "鹤岗市",
                                "parentId": "230400",
                                "provinceType": "2",
                                "province": "黑龙江省",
                                "city": "鹤岗市",
                                "county": "",

                            },
                            {
                                "cityCode": "230402",
                                "cityName": "向阳区",
                                "parentId": "230400",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "鹤岗市",
                                "county": "向阳区",

                            },
                            {
                                "cityCode": "230403",
                                "cityName": "工农区",
                                "parentId": "230400",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "鹤岗市",
                                "county": "工农区",

                            },
                            {
                                "cityCode": "230404",
                                "cityName": "南山区",
                                "parentId": "230400",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "鹤岗市",
                                "county": "南山区",

                            },
                            {
                                "cityCode": "230405",
                                "cityName": "兴安区",
                                "parentId": "230400",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "鹤岗市",
                                "county": "兴安区",

                            },
                            {
                                "cityCode": "230406",
                                "cityName": "东山区",
                                "parentId": "230400",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "鹤岗市",
                                "county": "东山区",

                            },
                            {
                                "cityCode": "230407",
                                "cityName": "兴山区",
                                "parentId": "230400",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "鹤岗市",
                                "county": "兴山区",

                            },
                            {
                                "cityCode": "230421",
                                "cityName": "萝北县",
                                "parentId": "230400",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "鹤岗市",
                                "county": "萝北县",

                            },
                            {
                                "cityCode": "230422",
                                "cityName": "绥滨县",
                                "parentId": "230400",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "鹤岗市",
                                "county": "绥滨县",

                            }
                        ]
                    },
                    {
                        "cityCode": "230500",
                        "cityName": "双鸭山市",
                        "parentId": "230000",
                        "provinceType": "2",
                        "province": "黑龙江省",
                        "city": "双鸭山市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "230501",
                                "cityName": "双鸭山市",
                                "parentId": "230500",
                                "provinceType": "2",
                                "province": "黑龙江省",
                                "city": "双鸭山市",
                                "county": "",

                            },
                            {
                                "cityCode": "230502",
                                "cityName": "尖山区",
                                "parentId": "230500",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "双鸭山市",
                                "county": "尖山区",

                            },
                            {
                                "cityCode": "230503",
                                "cityName": "岭东区",
                                "parentId": "230500",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "双鸭山市",
                                "county": "岭东区",

                            },
                            {
                                "cityCode": "230505",
                                "cityName": "四方台区",
                                "parentId": "230500",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "双鸭山市",
                                "county": "四方台区",

                            },
                            {
                                "cityCode": "230506",
                                "cityName": "宝山区",
                                "parentId": "230500",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "双鸭山市",
                                "county": "宝山区",

                            },
                            {
                                "cityCode": "230521",
                                "cityName": "集贤县",
                                "parentId": "230500",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "双鸭山市",
                                "county": "集贤县",

                            },
                            {
                                "cityCode": "230522",
                                "cityName": "友谊县",
                                "parentId": "230500",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "双鸭山市",
                                "county": "友谊县",

                            },
                            {
                                "cityCode": "230523",
                                "cityName": "宝清县",
                                "parentId": "230500",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "双鸭山市",
                                "county": "宝清县",

                            },
                            {
                                "cityCode": "230524",
                                "cityName": "饶河县",
                                "parentId": "230500",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "双鸭山市",
                                "county": "饶河县",

                            }
                        ]
                    },
                    {
                        "cityCode": "230600",
                        "cityName": "大庆市",
                        "parentId": "230000",
                        "provinceType": "2",
                        "province": "黑龙江省",
                        "city": "大庆市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "230601",
                                "cityName": "大庆市",
                                "parentId": "230600",
                                "provinceType": "2",
                                "province": "黑龙江省",
                                "city": "大庆市",
                                "county": "",

                            },
                            {
                                "cityCode": "230602",
                                "cityName": "萨尔图区",
                                "parentId": "230600",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "大庆市",
                                "county": "萨尔图区",

                            },
                            {
                                "cityCode": "230603",
                                "cityName": "龙凤区",
                                "parentId": "230600",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "大庆市",
                                "county": "龙凤区",

                            },
                            {
                                "cityCode": "230604",
                                "cityName": "让胡路区",
                                "parentId": "230600",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "大庆市",
                                "county": "让胡路区",

                            },
                            {
                                "cityCode": "230605",
                                "cityName": "红岗区",
                                "parentId": "230600",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "大庆市",
                                "county": "红岗区",

                            },
                            {
                                "cityCode": "230606",
                                "cityName": "大同区",
                                "parentId": "230600",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "大庆市",
                                "county": "大同区",

                            },
                            {
                                "cityCode": "230621",
                                "cityName": "肇州县",
                                "parentId": "230600",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "大庆市",
                                "county": "肇州县",

                            },
                            {
                                "cityCode": "230622",
                                "cityName": "肇源县",
                                "parentId": "230600",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "大庆市",
                                "county": "肇源县",

                            },
                            {
                                "cityCode": "230623",
                                "cityName": "林甸县",
                                "parentId": "230600",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "大庆市",
                                "county": "林甸县",

                            },
                            {
                                "cityCode": "230624",
                                "cityName": "杜尔伯特蒙古族自治县",
                                "parentId": "230600",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "大庆市",
                                "county": "杜尔伯特蒙古族自治县",

                            },
                            {
                                "cityCode": "230625",
                                "cityName": "高新区",
                                "parentId": "230600",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "大庆市",
                                "county": "高新区",

                            },
                            {
                                "cityCode": "230671",
                                "cityName": "大庆高新技术产业开发区",
                                "parentId": "230600",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "大庆市",
                                "county": "大庆高新技术产业开发区",

                            }
                        ]
                    },
                    {
                        "cityCode": "230700",
                        "cityName": "伊春市",
                        "parentId": "230000",
                        "provinceType": "2",
                        "province": "黑龙江省",
                        "city": "伊春市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "230701",
                                "cityName": "伊春市",
                                "parentId": "230700",
                                "provinceType": "2",
                                "province": "黑龙江省",
                                "city": "伊春市",
                                "county": "",

                            },
                            {
                                "cityCode": "230702",
                                "cityName": "伊春区",
                                "parentId": "230700",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "伊春市",
                                "county": "伊春区",

                            },
                            {
                                "cityCode": "230703",
                                "cityName": "南岔区",
                                "parentId": "230700",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "伊春市",
                                "county": "南岔区",

                            },
                            {
                                "cityCode": "230704",
                                "cityName": "友好区",
                                "parentId": "230700",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "伊春市",
                                "county": "友好区",

                            },
                            {
                                "cityCode": "230705",
                                "cityName": "西林区",
                                "parentId": "230700",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "伊春市",
                                "county": "西林区",

                            },
                            {
                                "cityCode": "230706",
                                "cityName": "翠峦区",
                                "parentId": "230700",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "伊春市",
                                "county": "翠峦区",

                            },
                            {
                                "cityCode": "230707",
                                "cityName": "新青区",
                                "parentId": "230700",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "伊春市",
                                "county": "新青区",

                            },
                            {
                                "cityCode": "230708",
                                "cityName": "美溪区",
                                "parentId": "230700",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "伊春市",
                                "county": "美溪区",

                            },
                            {
                                "cityCode": "230709",
                                "cityName": "金山屯区",
                                "parentId": "230700",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "伊春市",
                                "county": "金山屯区",

                            },
                            {
                                "cityCode": "230710",
                                "cityName": "五营区",
                                "parentId": "230700",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "伊春市",
                                "county": "五营区",

                            },
                            {
                                "cityCode": "230711",
                                "cityName": "乌马河区",
                                "parentId": "230700",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "伊春市",
                                "county": "乌马河区",

                            },
                            {
                                "cityCode": "230712",
                                "cityName": "汤旺河区",
                                "parentId": "230700",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "伊春市",
                                "county": "汤旺河区",

                            },
                            {
                                "cityCode": "230713",
                                "cityName": "带岭区",
                                "parentId": "230700",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "伊春市",
                                "county": "带岭区",

                            },
                            {
                                "cityCode": "230714",
                                "cityName": "乌伊岭区",
                                "parentId": "230700",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "伊春市",
                                "county": "乌伊岭区",

                            },
                            {
                                "cityCode": "230715",
                                "cityName": "红星区",
                                "parentId": "230700",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "伊春市",
                                "county": "红星区",

                            },
                            {
                                "cityCode": "230716",
                                "cityName": "上甘岭区",
                                "parentId": "230700",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "伊春市",
                                "county": "上甘岭区",

                            },
                            {
                                "cityCode": "230717",
                                "cityName": "伊美区",
                                "parentId": "230700",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "伊春市",
                                "county": "伊美区",

                            },
                            {
                                "cityCode": "230718",
                                "cityName": "乌翠区",
                                "parentId": "230700",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "伊春市",
                                "county": "乌翠区",

                            },
                            {
                                "cityCode": "230719",
                                "cityName": "友好区",
                                "parentId": "230700",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "伊春市",
                                "county": "友好区",

                            },
                            {
                                "cityCode": "230722",
                                "cityName": "嘉荫县",
                                "parentId": "230700",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "伊春市",
                                "county": "嘉荫县",

                            },
                            {
                                "cityCode": "230723",
                                "cityName": "汤旺县",
                                "parentId": "230700",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "伊春市",
                                "county": "汤旺县",

                            },
                            {
                                "cityCode": "230725",
                                "cityName": "大箐山县",
                                "parentId": "230700",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "伊春市",
                                "county": "大箐山县",

                            },
                            {
                                "cityCode": "230726",
                                "cityName": "南岔县",
                                "parentId": "230700",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "伊春市",
                                "county": "南岔县",

                            },
                            {
                                "cityCode": "230751",
                                "cityName": "金林区",
                                "parentId": "230700",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "伊春市",
                                "county": "金林区",

                            },
                            {
                                "cityCode": "230781",
                                "cityName": "铁力市",
                                "parentId": "230700",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "伊春市",
                                "county": "铁力市",

                            }
                        ]
                    },
                    {
                        "cityCode": "230800",
                        "cityName": "佳木斯市",
                        "parentId": "230000",
                        "provinceType": "2",
                        "province": "黑龙江省",
                        "city": "佳木斯市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "230801",
                                "cityName": "佳木斯市",
                                "parentId": "230800",
                                "provinceType": "2",
                                "province": "黑龙江省",
                                "city": "佳木斯市",
                                "county": "",

                            },
                            {
                                "cityCode": "230803",
                                "cityName": "向阳区",
                                "parentId": "230800",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "佳木斯市",
                                "county": "向阳区",

                            },
                            {
                                "cityCode": "230804",
                                "cityName": "前进区",
                                "parentId": "230800",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "佳木斯市",
                                "county": "前进区",

                            },
                            {
                                "cityCode": "230805",
                                "cityName": "东风区",
                                "parentId": "230800",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "佳木斯市",
                                "county": "东风区",

                            },
                            {
                                "cityCode": "230811",
                                "cityName": "郊区",
                                "parentId": "230800",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "佳木斯市",
                                "county": "郊区",

                            },
                            {
                                "cityCode": "230822",
                                "cityName": "桦南县",
                                "parentId": "230800",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "佳木斯市",
                                "county": "桦南县",

                            },
                            {
                                "cityCode": "230826",
                                "cityName": "桦川县",
                                "parentId": "230800",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "佳木斯市",
                                "county": "桦川县",

                            },
                            {
                                "cityCode": "230828",
                                "cityName": "汤原县",
                                "parentId": "230800",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "佳木斯市",
                                "county": "汤原县",

                            },
                            {
                                "cityCode": "230833",
                                "cityName": "抚远市",
                                "parentId": "230800",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "佳木斯市",
                                "county": "抚远市",

                            },
                            {
                                "cityCode": "230881",
                                "cityName": "同江市",
                                "parentId": "230800",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "佳木斯市",
                                "county": "同江市",

                            },
                            {
                                "cityCode": "230882",
                                "cityName": "富锦市",
                                "parentId": "230800",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "佳木斯市",
                                "county": "富锦市",

                            },
                            {
                                "cityCode": "230883",
                                "cityName": "抚远市",
                                "parentId": "230800",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "佳木斯市",
                                "county": "抚远市",

                            }
                        ]
                    },
                    {
                        "cityCode": "230900",
                        "cityName": "七台河市",
                        "parentId": "230000",
                        "provinceType": "2",
                        "province": "黑龙江省",
                        "city": "七台河市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "230901",
                                "cityName": "七台河市",
                                "parentId": "230900",
                                "provinceType": "2",
                                "province": "黑龙江省",
                                "city": "七台河市",
                                "county": "",

                            },
                            {
                                "cityCode": "230902",
                                "cityName": "新兴区",
                                "parentId": "230900",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "七台河市",
                                "county": "新兴区",

                            },
                            {
                                "cityCode": "230903",
                                "cityName": "桃山区",
                                "parentId": "230900",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "七台河市",
                                "county": "桃山区",

                            },
                            {
                                "cityCode": "230904",
                                "cityName": "茄子河区",
                                "parentId": "230900",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "七台河市",
                                "county": "茄子河区",

                            },
                            {
                                "cityCode": "230921",
                                "cityName": "勃利县",
                                "parentId": "230900",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "七台河市",
                                "county": "勃利县",

                            }
                        ]
                    },
                    {
                        "cityCode": "231000",
                        "cityName": "牡丹江市",
                        "parentId": "230000",
                        "provinceType": "2",
                        "province": "黑龙江省",
                        "city": "牡丹江市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "231001",
                                "cityName": "牡丹江市",
                                "parentId": "231000",
                                "provinceType": "2",
                                "province": "黑龙江省",
                                "city": "牡丹江市",
                                "county": "",

                            },
                            {
                                "cityCode": "231002",
                                "cityName": "东安区",
                                "parentId": "231000",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "牡丹江市",
                                "county": "东安区",

                            },
                            {
                                "cityCode": "231003",
                                "cityName": "阳明区",
                                "parentId": "231000",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "牡丹江市",
                                "county": "阳明区",

                            },
                            {
                                "cityCode": "231004",
                                "cityName": "爱民区",
                                "parentId": "231000",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "牡丹江市",
                                "county": "爱民区",

                            },
                            {
                                "cityCode": "231005",
                                "cityName": "西安区",
                                "parentId": "231000",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "牡丹江市",
                                "county": "西安区",

                            },
                            {
                                "cityCode": "231024",
                                "cityName": "东宁县",
                                "parentId": "231000",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "牡丹江市",
                                "county": "东宁县",

                            },
                            {
                                "cityCode": "231025",
                                "cityName": "林口县",
                                "parentId": "231000",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "牡丹江市",
                                "county": "林口县",

                            },
                            {
                                "cityCode": "231071",
                                "cityName": "牡丹江经济技术开发区",
                                "parentId": "231000",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "牡丹江市",
                                "county": "牡丹江经济技术开发区",

                            },
                            {
                                "cityCode": "231081",
                                "cityName": "绥芬河市",
                                "parentId": "231000",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "牡丹江市",
                                "county": "绥芬河市",

                            },
                            {
                                "cityCode": "231083",
                                "cityName": "海林市",
                                "parentId": "231000",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "牡丹江市",
                                "county": "海林市",

                            },
                            {
                                "cityCode": "231084",
                                "cityName": "宁安市",
                                "parentId": "231000",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "牡丹江市",
                                "county": "宁安市",

                            },
                            {
                                "cityCode": "231085",
                                "cityName": "穆棱市",
                                "parentId": "231000",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "牡丹江市",
                                "county": "穆棱市",

                            },
                            {
                                "cityCode": "231086",
                                "cityName": "东宁市",
                                "parentId": "231000",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "牡丹江市",
                                "county": "东宁市",

                            }
                        ]
                    },
                    {
                        "cityCode": "231100",
                        "cityName": "黑河市",
                        "parentId": "230000",
                        "provinceType": "2",
                        "province": "黑龙江省",
                        "city": "黑河市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "231101",
                                "cityName": "黑河市",
                                "parentId": "231100",
                                "provinceType": "2",
                                "province": "黑龙江省",
                                "city": "黑河市",
                                "county": "",

                            },
                            {
                                "cityCode": "231102",
                                "cityName": "爱辉区",
                                "parentId": "231100",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "黑河市",
                                "county": "爱辉区",

                            },
                            {
                                "cityCode": "231121",
                                "cityName": "嫩江县",
                                "parentId": "231100",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "黑河市",
                                "county": "嫩江县",

                            },
                            {
                                "cityCode": "231123",
                                "cityName": "逊克县",
                                "parentId": "231100",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "黑河市",
                                "county": "逊克县",

                            },
                            {
                                "cityCode": "231124",
                                "cityName": "孙吴县",
                                "parentId": "231100",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "黑河市",
                                "county": "孙吴县",

                            },
                            {
                                "cityCode": "231181",
                                "cityName": "北安市",
                                "parentId": "231100",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "黑河市",
                                "county": "北安市",

                            },
                            {
                                "cityCode": "231182",
                                "cityName": "五大连池市",
                                "parentId": "231100",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "黑河市",
                                "county": "五大连池市",

                            },
                            {
                                "cityCode": "231183",
                                "cityName": "嫩江市",
                                "parentId": "231100",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "黑河市",
                                "county": "嫩江市",

                            }
                        ]
                    },
                    {
                        "cityCode": "231200",
                        "cityName": "绥化市",
                        "parentId": "230000",
                        "provinceType": "2",
                        "province": "黑龙江省",
                        "city": "绥化市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "231201",
                                "cityName": "绥化市",
                                "parentId": "231200",
                                "provinceType": "2",
                                "province": "黑龙江省",
                                "city": "绥化市",
                                "county": "",

                            },
                            {
                                "cityCode": "231202",
                                "cityName": "北林区",
                                "parentId": "231200",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "绥化市",
                                "county": "北林区",

                            },
                            {
                                "cityCode": "231221",
                                "cityName": "望奎县",
                                "parentId": "231200",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "绥化市",
                                "county": "望奎县",

                            },
                            {
                                "cityCode": "231222",
                                "cityName": "兰西县",
                                "parentId": "231200",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "绥化市",
                                "county": "兰西县",

                            },
                            {
                                "cityCode": "231223",
                                "cityName": "青冈县",
                                "parentId": "231200",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "绥化市",
                                "county": "青冈县",

                            },
                            {
                                "cityCode": "231224",
                                "cityName": "庆安县",
                                "parentId": "231200",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "绥化市",
                                "county": "庆安县",

                            },
                            {
                                "cityCode": "231225",
                                "cityName": "明水县",
                                "parentId": "231200",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "绥化市",
                                "county": "明水县",

                            },
                            {
                                "cityCode": "231226",
                                "cityName": "绥棱县",
                                "parentId": "231200",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "绥化市",
                                "county": "绥棱县",

                            },
                            {
                                "cityCode": "231281",
                                "cityName": "安达市",
                                "parentId": "231200",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "绥化市",
                                "county": "安达市",

                            },
                            {
                                "cityCode": "231282",
                                "cityName": "肇东市",
                                "parentId": "231200",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "绥化市",
                                "county": "肇东市",

                            },
                            {
                                "cityCode": "231283",
                                "cityName": "海伦市",
                                "parentId": "231200",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "绥化市",
                                "county": "海伦市",

                            }
                        ]
                    },
                    {
                        "cityCode": "232700",
                        "cityName": "大兴安岭地区",
                        "parentId": "230000",
                        "provinceType": "2",
                        "province": "黑龙江省",
                        "city": "大兴安岭地区",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "232701",
                                "cityName": "漠河市",
                                "parentId": "232700",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "大兴安岭地区",
                                "county": "漠河市",

                            },
                            {
                                "cityCode": "232721",
                                "cityName": "呼玛县",
                                "parentId": "232700",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "大兴安岭地区",
                                "county": "呼玛县",

                            },
                            {
                                "cityCode": "232722",
                                "cityName": "塔河县",
                                "parentId": "232700",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "大兴安岭地区",
                                "county": "塔河县",

                            },
                            {
                                "cityCode": "232761",
                                "cityName": "加格达奇区",
                                "parentId": "232700",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "大兴安岭地区",
                                "county": "加格达奇区",

                            },
                            {
                                "cityCode": "232762",
                                "cityName": "松岭区",
                                "parentId": "232700",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "大兴安岭地区",
                                "county": "松岭区",

                            },
                            {
                                "cityCode": "232763",
                                "cityName": "新林区",
                                "parentId": "232700",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "大兴安岭地区",
                                "county": "新林区",

                            },
                            {
                                "cityCode": "232764",
                                "cityName": "呼中区",
                                "parentId": "232700",
                                "provinceType": "3",
                                "province": "黑龙江省",
                                "city": "大兴安岭地区",
                                "county": "呼中区",

                            }
                        ]
                    }
                ]
            },
            {
                "cityCode": "310000",
                "cityName": "上海市",
                "parentId": "100000",
                "provinceType": "1",
                "province": "上海市",
                "city": "",
                "county": "",
                "counties": [
                    {
                        "cityCode": "310100",
                        "cityName": "上海市",
                        "parentId": "310000",
                        "provinceType": "2",
                        "province": "上海市",
                        "city": "上海市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "310101",
                                "cityName": "黄浦区",
                                "parentId": "310100",
                                "provinceType": "3",
                                "province": "上海市",
                                "city": "上海市",
                                "county": "黄浦区",

                            },
                            {
                                "cityCode": "310104",
                                "cityName": "徐汇区",
                                "parentId": "310100",
                                "provinceType": "3",
                                "province": "上海市",
                                "city": "上海市",
                                "county": "徐汇区",

                            },
                            {
                                "cityCode": "310105",
                                "cityName": "长宁区",
                                "parentId": "310100",
                                "provinceType": "3",
                                "province": "上海市",
                                "city": "上海市",
                                "county": "长宁区",

                            },
                            {
                                "cityCode": "310106",
                                "cityName": "静安区",
                                "parentId": "310100",
                                "provinceType": "3",
                                "province": "上海市",
                                "city": "上海市",
                                "county": "静安区",

                            },
                            {
                                "cityCode": "310107",
                                "cityName": "普陀区",
                                "parentId": "310100",
                                "provinceType": "3",
                                "province": "上海市",
                                "city": "上海市",
                                "county": "普陀区",

                            },
                            {
                                "cityCode": "310109",
                                "cityName": "虹口区",
                                "parentId": "310100",
                                "provinceType": "3",
                                "province": "上海市",
                                "city": "上海市",
                                "county": "虹口区",

                            },
                            {
                                "cityCode": "310110",
                                "cityName": "杨浦区",
                                "parentId": "310100",
                                "provinceType": "3",
                                "province": "上海市",
                                "city": "上海市",
                                "county": "杨浦区",

                            },
                            {
                                "cityCode": "310112",
                                "cityName": "闵行区",
                                "parentId": "310100",
                                "provinceType": "3",
                                "province": "上海市",
                                "city": "上海市",
                                "county": "闵行区",

                            },
                            {
                                "cityCode": "310113",
                                "cityName": "宝山区",
                                "parentId": "310100",
                                "provinceType": "3",
                                "province": "上海市",
                                "city": "上海市",
                                "county": "宝山区",

                            },
                            {
                                "cityCode": "310114",
                                "cityName": "嘉定区",
                                "parentId": "310100",
                                "provinceType": "3",
                                "province": "上海市",
                                "city": "上海市",
                                "county": "嘉定区",

                            },
                            {
                                "cityCode": "310115",
                                "cityName": "浦东新区",
                                "parentId": "310100",
                                "provinceType": "3",
                                "province": "上海市",
                                "city": "上海市",
                                "county": "浦东新区",

                            },
                            {
                                "cityCode": "310116",
                                "cityName": "金山区",
                                "parentId": "310100",
                                "provinceType": "3",
                                "province": "上海市",
                                "city": "上海市",
                                "county": "金山区",

                            },
                            {
                                "cityCode": "310117",
                                "cityName": "松江区",
                                "parentId": "310100",
                                "provinceType": "3",
                                "province": "上海市",
                                "city": "上海市",
                                "county": "松江区",

                            },
                            {
                                "cityCode": "310118",
                                "cityName": "青浦区",
                                "parentId": "310100",
                                "provinceType": "3",
                                "province": "上海市",
                                "city": "上海市",
                                "county": "青浦区",

                            },
                            {
                                "cityCode": "310120",
                                "cityName": "奉贤区",
                                "parentId": "310100",
                                "provinceType": "3",
                                "province": "上海市",
                                "city": "上海市",
                                "county": "奉贤区",

                            },
                            {
                                "cityCode": "310151",
                                "cityName": "崇明区",
                                "parentId": "310100",
                                "provinceType": "3",
                                "province": "上海市",
                                "city": "上海市",
                                "county": "崇明区",

                            },
                            {
                                "cityCode": "310231",
                                "cityName": "张江高新区",
                                "parentId": "310100",
                                "provinceType": "3",
                                "province": "上海市",
                                "city": "上海市",
                                "county": "张江高新区",

                            },
                            {
                                "cityCode": "310232",
                                "cityName": "紫竹高新区",
                                "parentId": "310100",
                                "provinceType": "3",
                                "province": "上海市",
                                "city": "上海市",
                                "county": "紫竹高新区",

                            },
                            {
                                "cityCode": "310233",
                                "cityName": "漕河泾开发区",
                                "parentId": "310100",
                                "provinceType": "3",
                                "province": "上海市",
                                "city": "上海市",
                                "county": "漕河泾开发区",

                            }
                        ]
                    },
                    {
                        "cityCode": "310103",
                        "cityName": "卢湾区",
                        "parentId": "310000",
                        "provinceType": "3",
                        "province": "上海市",
                        "city": "上海市",
                        "county": "卢湾区",

                    },
                    {
                        "cityCode": "310108",
                        "cityName": "闸北区",
                        "parentId": "310000",
                        "provinceType": "3",
                        "province": "上海市",
                        "city": "上海市",
                        "county": "闸北区",

                    },
                    {
                        "cityCode": "310119",
                        "cityName": "南汇区",
                        "parentId": "310000",
                        "provinceType": "3",
                        "province": "上海市",
                        "city": "上海市",
                        "county": "南汇区",

                    },
                    {
                        "cityCode": "310230",
                        "cityName": "崇明县",
                        "parentId": "310000",
                        "provinceType": "3",
                        "province": "上海市",
                        "city": "上海市",
                        "county": "崇明县",

                    }
                ]
            },
            {
                "cityCode": "320000",
                "cityName": "江苏省",
                "parentId": "100000",
                "provinceType": "1",
                "province": "江苏省",
                "city": "",
                "county": "",
                "counties": [
                    {
                        "cityCode": "320100",
                        "cityName": "南京市",
                        "parentId": "320000",
                        "provinceType": "2",
                        "province": "江苏省",
                        "city": "南京市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "320101",
                                "cityName": "南京市",
                                "parentId": "320100",
                                "provinceType": "2",
                                "province": "江苏省",
                                "city": "南京市",
                                "county": "",

                            },
                            {
                                "cityCode": "320102",
                                "cityName": "玄武区",
                                "parentId": "320100",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "南京市",
                                "county": "玄武区",

                            },
                            {
                                "cityCode": "320103",
                                "cityName": "白下区",
                                "parentId": "320100",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "南京市",
                                "county": "白下区",

                            },
                            {
                                "cityCode": "320104",
                                "cityName": "秦淮区",
                                "parentId": "320100",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "南京市",
                                "county": "秦淮区",

                            },
                            {
                                "cityCode": "320105",
                                "cityName": "建邺区",
                                "parentId": "320100",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "南京市",
                                "county": "建邺区",

                            },
                            {
                                "cityCode": "320106",
                                "cityName": "鼓楼区",
                                "parentId": "320100",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "南京市",
                                "county": "鼓楼区",

                            },
                            {
                                "cityCode": "320107",
                                "cityName": "下关区",
                                "parentId": "320100",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "南京市",
                                "county": "下关区",

                            },
                            {
                                "cityCode": "320111",
                                "cityName": "浦口区",
                                "parentId": "320100",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "南京市",
                                "county": "浦口区",

                            },
                            {
                                "cityCode": "320113",
                                "cityName": "栖霞区",
                                "parentId": "320100",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "南京市",
                                "county": "栖霞区",

                            },
                            {
                                "cityCode": "320114",
                                "cityName": "雨花台区",
                                "parentId": "320100",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "南京市",
                                "county": "雨花台区",

                            },
                            {
                                "cityCode": "320115",
                                "cityName": "江宁区",
                                "parentId": "320100",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "南京市",
                                "county": "江宁区",

                            },
                            {
                                "cityCode": "320116",
                                "cityName": "六合区",
                                "parentId": "320100",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "南京市",
                                "county": "六合区",

                            },
                            {
                                "cityCode": "320117",
                                "cityName": "溧水区",
                                "parentId": "320100",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "南京市",
                                "county": "溧水区",

                            },
                            {
                                "cityCode": "320118",
                                "cityName": "高淳区",
                                "parentId": "320100",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "南京市",
                                "county": "高淳区",

                            },
                            {
                                "cityCode": "320119",
                                "cityName": "江北新区",
                                "parentId": "320100",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "南京市",
                                "county": "江北新区",

                            },
                            {
                                "cityCode": "320124",
                                "cityName": "溧水县",
                                "parentId": "320100",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "南京市",
                                "county": "溧水县",

                            },
                            {
                                "cityCode": "320125",
                                "cityName": "高淳县",
                                "parentId": "320100",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "南京市",
                                "county": "高淳县",

                            }
                        ]
                    },
                    {
                        "cityCode": "320200",
                        "cityName": "无锡市",
                        "parentId": "320000",
                        "provinceType": "2",
                        "province": "江苏省",
                        "city": "无锡市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "320201",
                                "cityName": "无锡市",
                                "parentId": "320200",
                                "provinceType": "2",
                                "province": "江苏省",
                                "city": "无锡市",
                                "county": "",

                            },
                            {
                                "cityCode": "320202",
                                "cityName": "崇安区",
                                "parentId": "320200",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "无锡市",
                                "county": "崇安区",

                            },
                            {
                                "cityCode": "320203",
                                "cityName": "南长区",
                                "parentId": "320200",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "无锡市",
                                "county": "南长区",

                            },
                            {
                                "cityCode": "320204",
                                "cityName": "北塘区",
                                "parentId": "320200",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "无锡市",
                                "county": "北塘区",

                            },
                            {
                                "cityCode": "320205",
                                "cityName": "锡山区",
                                "parentId": "320200",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "无锡市",
                                "county": "锡山区",

                            },
                            {
                                "cityCode": "320206",
                                "cityName": "惠山区",
                                "parentId": "320200",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "无锡市",
                                "county": "惠山区",

                            },
                            {
                                "cityCode": "320211",
                                "cityName": "滨湖区",
                                "parentId": "320200",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "无锡市",
                                "county": "滨湖区",

                            },
                            {
                                "cityCode": "320213",
                                "cityName": "梁溪区",
                                "parentId": "320200",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "无锡市",
                                "county": "梁溪区",

                            },
                            {
                                "cityCode": "320214",
                                "cityName": "新吴区",
                                "parentId": "320200",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "无锡市",
                                "county": "新吴区",

                            },
                            {
                                "cityCode": "320281",
                                "cityName": "江阴市",
                                "parentId": "320200",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "无锡市",
                                "county": "江阴市",

                            },
                            {
                                "cityCode": "320282",
                                "cityName": "宜兴市",
                                "parentId": "320200",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "无锡市",
                                "county": "宜兴市",

                            }
                        ]
                    },
                    {
                        "cityCode": "320300",
                        "cityName": "徐州市",
                        "parentId": "320000",
                        "provinceType": "2",
                        "province": "江苏省",
                        "city": "徐州市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "320301",
                                "cityName": "徐州市",
                                "parentId": "320300",
                                "provinceType": "2",
                                "province": "江苏省",
                                "city": "徐州市",
                                "county": "",

                            },
                            {
                                "cityCode": "320302",
                                "cityName": "鼓楼区",
                                "parentId": "320300",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "徐州市",
                                "county": "鼓楼区",

                            },
                            {
                                "cityCode": "320303",
                                "cityName": "云龙区",
                                "parentId": "320300",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "徐州市",
                                "county": "云龙区",

                            },
                            {
                                "cityCode": "320304",
                                "cityName": "九里区",
                                "parentId": "320300",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "徐州市",
                                "county": "九里区",

                            },
                            {
                                "cityCode": "320305",
                                "cityName": "贾汪区",
                                "parentId": "320300",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "徐州市",
                                "county": "贾汪区",

                            },
                            {
                                "cityCode": "320311",
                                "cityName": "泉山区",
                                "parentId": "320300",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "徐州市",
                                "county": "泉山区",

                            },
                            {
                                "cityCode": "320312",
                                "cityName": "铜山区",
                                "parentId": "320300",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "徐州市",
                                "county": "铜山区",

                            },
                            {
                                "cityCode": "320321",
                                "cityName": "丰县",
                                "parentId": "320300",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "徐州市",
                                "county": "丰县",

                            },
                            {
                                "cityCode": "320322",
                                "cityName": "沛县",
                                "parentId": "320300",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "徐州市",
                                "county": "沛县",

                            },
                            {
                                "cityCode": "320323",
                                "cityName": "铜山县",
                                "parentId": "320300",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "徐州市",
                                "county": "铜山县",

                            },
                            {
                                "cityCode": "320324",
                                "cityName": "睢宁县",
                                "parentId": "320300",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "徐州市",
                                "county": "睢宁县",

                            },
                            {
                                "cityCode": "320371",
                                "cityName": "徐州经济技术开发区",
                                "parentId": "320300",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "徐州市",
                                "county": "徐州经济技术开发区",

                            },
                            {
                                "cityCode": "320381",
                                "cityName": "新沂市",
                                "parentId": "320300",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "徐州市",
                                "county": "新沂市",

                            },
                            {
                                "cityCode": "320382",
                                "cityName": "邳州市",
                                "parentId": "320300",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "徐州市",
                                "county": "邳州市",

                            },
                            {
                                "cityCode": "320383",
                                "cityName": "经济技术开发区",
                                "parentId": "320300",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "徐州市",
                                "county": "经济技术开发区",

                            },
                            {
                                "cityCode": "320384",
                                "cityName": "高新技术产业开发区",
                                "parentId": "320300",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "徐州市",
                                "county": "高新技术产业开发区",

                            },
                            {
                                "cityCode": "320385",
                                "cityName": "软件园",
                                "parentId": "320300",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "徐州市",
                                "county": "软件园",

                            }
                        ]
                    },
                    {
                        "cityCode": "320400",
                        "cityName": "常州市",
                        "parentId": "320000",
                        "provinceType": "2",
                        "province": "江苏省",
                        "city": "常州市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "320401",
                                "cityName": "常州市",
                                "parentId": "320400",
                                "provinceType": "2",
                                "province": "江苏省",
                                "city": "常州市",
                                "county": "",

                            },
                            {
                                "cityCode": "320402",
                                "cityName": "天宁区",
                                "parentId": "320400",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "常州市",
                                "county": "天宁区",

                            },
                            {
                                "cityCode": "320404",
                                "cityName": "钟楼区",
                                "parentId": "320400",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "常州市",
                                "county": "钟楼区",

                            },
                            {
                                "cityCode": "320405",
                                "cityName": "戚墅堰区",
                                "parentId": "320400",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "常州市",
                                "county": "戚墅堰区",

                            },
                            {
                                "cityCode": "320411",
                                "cityName": "新北区",
                                "parentId": "320400",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "常州市",
                                "county": "新北区",

                            },
                            {
                                "cityCode": "320412",
                                "cityName": "武进区",
                                "parentId": "320400",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "常州市",
                                "county": "武进区",

                            },
                            {
                                "cityCode": "320413",
                                "cityName": "金坛区",
                                "parentId": "320400",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "常州市",
                                "county": "金坛区",

                            },
                            {
                                "cityCode": "320481",
                                "cityName": "溧阳市",
                                "parentId": "320400",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "常州市",
                                "county": "溧阳市",

                            },
                            {
                                "cityCode": "320482",
                                "cityName": "高新区",
                                "parentId": "320400",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "常州市",
                                "county": "高新区",

                            }
                        ]
                    },
                    {
                        "cityCode": "320500",
                        "cityName": "苏州市",
                        "parentId": "320000",
                        "provinceType": "2",
                        "province": "江苏省",
                        "city": "苏州市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "320501",
                                "cityName": "苏州市",
                                "parentId": "320500",
                                "provinceType": "2",
                                "province": "江苏省",
                                "city": "苏州市",
                                "county": "",

                            },
                            {
                                "cityCode": "320502",
                                "cityName": "沧浪区",
                                "parentId": "320500",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "苏州市",
                                "county": "沧浪区",

                            },
                            {
                                "cityCode": "320503",
                                "cityName": "平江区",
                                "parentId": "320500",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "苏州市",
                                "county": "平江区",

                            },
                            {
                                "cityCode": "320504",
                                "cityName": "金阊区",
                                "parentId": "320500",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "苏州市",
                                "county": "金阊区",

                            },
                            {
                                "cityCode": "320505",
                                "cityName": "虎丘区",
                                "parentId": "320500",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "苏州市",
                                "county": "虎丘区",

                            },
                            {
                                "cityCode": "320506",
                                "cityName": "吴中区",
                                "parentId": "320500",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "苏州市",
                                "county": "吴中区",

                            },
                            {
                                "cityCode": "320507",
                                "cityName": "相城区",
                                "parentId": "320500",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "苏州市",
                                "county": "相城区",

                            },
                            {
                                "cityCode": "320508",
                                "cityName": "姑苏区",
                                "parentId": "320500",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "苏州市",
                                "county": "姑苏区",

                            },
                            {
                                "cityCode": "320509",
                                "cityName": "吴江区",
                                "parentId": "320500",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "苏州市",
                                "county": "吴江区",

                            },
                            {
                                "cityCode": "320571",
                                "cityName": "苏州工业园区",
                                "parentId": "320500",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "苏州市",
                                "county": "苏州工业园区",

                            },
                            {
                                "cityCode": "320581",
                                "cityName": "常熟市",
                                "parentId": "320500",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "苏州市",
                                "county": "常熟市",

                            },
                            {
                                "cityCode": "320582",
                                "cityName": "张家港市",
                                "parentId": "320500",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "苏州市",
                                "county": "张家港市",

                            },
                            {
                                "cityCode": "320583",
                                "cityName": "昆山市",
                                "parentId": "320500",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "苏州市",
                                "county": "昆山市",

                            },
                            {
                                "cityCode": "320584",
                                "cityName": "吴江区",
                                "parentId": "320500",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "苏州市",
                                "county": "吴江区",

                            },
                            {
                                "cityCode": "320585",
                                "cityName": "太仓市",
                                "parentId": "320500",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "苏州市",
                                "county": "太仓市",

                            },
                            {
                                "cityCode": "320586",
                                "cityName": "苏州新区",
                                "parentId": "320500",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "苏州市",
                                "county": "苏州新区",

                            },
                            {
                                "cityCode": "320587",
                                "cityName": "工业园区",
                                "parentId": "320500",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "苏州市",
                                "county": "工业园区",

                            },
                            {
                                "cityCode": "320588",
                                "cityName": "高新区",
                                "parentId": "320500",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "苏州市",
                                "county": "高新区",

                            }
                        ]
                    },
                    {
                        "cityCode": "320600",
                        "cityName": "南通市",
                        "parentId": "320000",
                        "provinceType": "2",
                        "province": "江苏省",
                        "city": "南通市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "320601",
                                "cityName": "南通市",
                                "parentId": "320600",
                                "provinceType": "2",
                                "province": "江苏省",
                                "city": "南通市",
                                "county": "",

                            },
                            {
                                "cityCode": "320602",
                                "cityName": "崇川区",
                                "parentId": "320600",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "南通市",
                                "county": "崇川区",

                            },
                            {
                                "cityCode": "320611",
                                "cityName": "港闸区",
                                "parentId": "320600",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "南通市",
                                "county": "港闸区",

                            },
                            {
                                "cityCode": "320612",
                                "cityName": "通州区",
                                "parentId": "320600",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "南通市",
                                "county": "通州区",

                            },
                            {
                                "cityCode": "320621",
                                "cityName": "海安县",
                                "parentId": "320600",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "南通市",
                                "county": "海安县",

                            },
                            {
                                "cityCode": "320623",
                                "cityName": "如东县",
                                "parentId": "320600",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "南通市",
                                "county": "如东县",

                            },
                            {
                                "cityCode": "320671",
                                "cityName": "南通经济技术开发区",
                                "parentId": "320600",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "南通市",
                                "county": "南通经济技术开发区",

                            },
                            {
                                "cityCode": "320681",
                                "cityName": "启东市",
                                "parentId": "320600",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "南通市",
                                "county": "启东市",

                            },
                            {
                                "cityCode": "320682",
                                "cityName": "如皋市",
                                "parentId": "320600",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "南通市",
                                "county": "如皋市",

                            },
                            {
                                "cityCode": "320683",
                                "cityName": "通州市",
                                "parentId": "320600",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "南通市",
                                "county": "通州市",

                            },
                            {
                                "cityCode": "320684",
                                "cityName": "海门市",
                                "parentId": "320600",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "南通市",
                                "county": "海门市",

                            },
                            {
                                "cityCode": "320685",
                                "cityName": "经济技术开发区",
                                "parentId": "320600",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "南通市",
                                "county": "经济技术开发区",

                            }
                        ]
                    },
                    {
                        "cityCode": "320700",
                        "cityName": "连云港市",
                        "parentId": "320000",
                        "provinceType": "2",
                        "province": "江苏省",
                        "city": "连云港市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "320701",
                                "cityName": "连云港市",
                                "parentId": "320700",
                                "provinceType": "2",
                                "province": "江苏省",
                                "city": "连云港市",
                                "county": "",

                            },
                            {
                                "cityCode": "320703",
                                "cityName": "连云区",
                                "parentId": "320700",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "连云港市",
                                "county": "连云区",

                            },
                            {
                                "cityCode": "320705",
                                "cityName": "新浦区",
                                "parentId": "320700",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "连云港市",
                                "county": "新浦区",

                            },
                            {
                                "cityCode": "320706",
                                "cityName": "海州区",
                                "parentId": "320700",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "连云港市",
                                "county": "海州区",

                            },
                            {
                                "cityCode": "320707",
                                "cityName": "赣榆区",
                                "parentId": "320700",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "连云港市",
                                "county": "赣榆区",

                            },
                            {
                                "cityCode": "320721",
                                "cityName": "赣榆县",
                                "parentId": "320700",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "连云港市",
                                "county": "赣榆县",

                            },
                            {
                                "cityCode": "320722",
                                "cityName": "东海县",
                                "parentId": "320700",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "连云港市",
                                "county": "东海县",

                            },
                            {
                                "cityCode": "320723",
                                "cityName": "灌云县",
                                "parentId": "320700",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "连云港市",
                                "county": "灌云县",

                            },
                            {
                                "cityCode": "320724",
                                "cityName": "灌南县",
                                "parentId": "320700",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "连云港市",
                                "county": "灌南县",

                            },
                            {
                                "cityCode": "320771",
                                "cityName": "连云港经济技术开发区",
                                "parentId": "320700",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "连云港市",
                                "county": "连云港经济技术开发区",

                            },
                            {
                                "cityCode": "320772",
                                "cityName": "连云港高新技术产业开发区",
                                "parentId": "320700",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "连云港市",
                                "county": "连云港高新技术产业开发区",

                            }
                        ]
                    },
                    {
                        "cityCode": "320800",
                        "cityName": "淮安市",
                        "parentId": "320000",
                        "provinceType": "2",
                        "province": "江苏省",
                        "city": "淮安市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "320801",
                                "cityName": "淮安市",
                                "parentId": "320800",
                                "provinceType": "2",
                                "province": "江苏省",
                                "city": "淮安市",
                                "county": "",

                            },
                            {
                                "cityCode": "320802",
                                "cityName": "清河区",
                                "parentId": "320800",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "淮安市",
                                "county": "清河区",

                            },
                            {
                                "cityCode": "320803",
                                "cityName": "淮安区",
                                "parentId": "320800",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "淮安市",
                                "county": "淮安区",

                            },
                            {
                                "cityCode": "320804",
                                "cityName": "淮阴区",
                                "parentId": "320800",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "淮安市",
                                "county": "淮阴区",

                            },
                            {
                                "cityCode": "320811",
                                "cityName": "清江浦区",
                                "parentId": "320800",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "淮安市",
                                "county": "清江浦区",

                            },
                            {
                                "cityCode": "320812",
                                "cityName": "清江浦区",
                                "parentId": "320800",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "淮安市",
                                "county": "清江浦区",

                            },
                            {
                                "cityCode": "320813",
                                "cityName": "洪泽区",
                                "parentId": "320800",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "淮安市",
                                "county": "洪泽区",

                            },
                            {
                                "cityCode": "320826",
                                "cityName": "涟水县",
                                "parentId": "320800",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "淮安市",
                                "county": "涟水县",

                            },
                            {
                                "cityCode": "320829",
                                "cityName": "洪泽县",
                                "parentId": "320800",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "淮安市",
                                "county": "洪泽县",

                            },
                            {
                                "cityCode": "320830",
                                "cityName": "盱眙县",
                                "parentId": "320800",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "淮安市",
                                "county": "盱眙县",

                            },
                            {
                                "cityCode": "320831",
                                "cityName": "金湖县",
                                "parentId": "320800",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "淮安市",
                                "county": "金湖县",

                            },
                            {
                                "cityCode": "320832",
                                "cityName": "经济开发区",
                                "parentId": "320800",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "淮安市",
                                "county": "经济开发区",

                            },
                            {
                                "cityCode": "320871",
                                "cityName": "淮安经济开发区",
                                "parentId": "320800",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "淮安市",
                                "county": "淮安经济开发区",

                            }
                        ]
                    },
                    {
                        "cityCode": "320900",
                        "cityName": "盐城市",
                        "parentId": "320000",
                        "provinceType": "2",
                        "province": "江苏省",
                        "city": "盐城市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "320901",
                                "cityName": "盐城市",
                                "parentId": "320900",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "盐城市",
                                "county": "",

                            },
                            {
                                "cityCode": "320902",
                                "cityName": "亭湖区",
                                "parentId": "320900",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "盐城市",
                                "county": "亭湖区",

                            },
                            {
                                "cityCode": "320903",
                                "cityName": "盐都区",
                                "parentId": "320900",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "盐城市",
                                "county": "盐都区",

                            },
                            {
                                "cityCode": "320904",
                                "cityName": "大丰区",
                                "parentId": "320900",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "盐城市",
                                "county": "大丰区",

                            },
                            {
                                "cityCode": "320921",
                                "cityName": "响水县",
                                "parentId": "320900",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "盐城市",
                                "county": "响水县",

                            },
                            {
                                "cityCode": "320922",
                                "cityName": "滨海县",
                                "parentId": "320900",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "盐城市",
                                "county": "滨海县",

                            },
                            {
                                "cityCode": "320923",
                                "cityName": "阜宁县",
                                "parentId": "320900",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "盐城市",
                                "county": "阜宁县",

                            },
                            {
                                "cityCode": "320924",
                                "cityName": "射阳县",
                                "parentId": "320900",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "盐城市",
                                "county": "射阳县",

                            },
                            {
                                "cityCode": "320925",
                                "cityName": "建湖县",
                                "parentId": "320900",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "盐城市",
                                "county": "建湖县",

                            },
                            {
                                "cityCode": "320971",
                                "cityName": "盐城经济技术开发区",
                                "parentId": "320900",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "盐城市",
                                "county": "盐城经济技术开发区",

                            },
                            {
                                "cityCode": "320981",
                                "cityName": "东台市",
                                "parentId": "320900",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "盐城市",
                                "county": "东台市",

                            },
                            {
                                "cityCode": "320982",
                                "cityName": "大丰市",
                                "parentId": "320900",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "盐城市",
                                "county": "大丰市",

                            }
                        ]
                    },
                    {
                        "cityCode": "321000",
                        "cityName": "扬州市",
                        "parentId": "320000",
                        "provinceType": "2",
                        "province": "江苏省",
                        "city": "扬州市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "321001",
                                "cityName": "扬州市",
                                "parentId": "321000",
                                "provinceType": "2",
                                "province": "江苏省",
                                "city": "扬州市",
                                "county": "",

                            },
                            {
                                "cityCode": "321002",
                                "cityName": "广陵区",
                                "parentId": "321000",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "扬州市",
                                "county": "广陵区",

                            },
                            {
                                "cityCode": "321003",
                                "cityName": "邗江区",
                                "parentId": "321000",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "扬州市",
                                "county": "邗江区",

                            },
                            {
                                "cityCode": "321011",
                                "cityName": "维扬区",
                                "parentId": "321000",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "扬州市",
                                "county": "维扬区",

                            },
                            {
                                "cityCode": "321012",
                                "cityName": "江都区",
                                "parentId": "321000",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "扬州市",
                                "county": "江都区",

                            },
                            {
                                "cityCode": "321023",
                                "cityName": "宝应县",
                                "parentId": "321000",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "扬州市",
                                "county": "宝应县",

                            },
                            {
                                "cityCode": "321071",
                                "cityName": "扬州经济技术开发区",
                                "parentId": "321000",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "扬州市",
                                "county": "扬州经济技术开发区",

                            },
                            {
                                "cityCode": "321081",
                                "cityName": "仪征市",
                                "parentId": "321000",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "扬州市",
                                "county": "仪征市",

                            },
                            {
                                "cityCode": "321084",
                                "cityName": "高邮市",
                                "parentId": "321000",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "扬州市",
                                "county": "高邮市",

                            },
                            {
                                "cityCode": "321088",
                                "cityName": "江都市",
                                "parentId": "321000",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "扬州市",
                                "county": "江都市",

                            }
                        ]
                    },
                    {
                        "cityCode": "321100",
                        "cityName": "镇江市",
                        "parentId": "320000",
                        "provinceType": "2",
                        "province": "江苏省",
                        "city": "镇江市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "321101",
                                "cityName": "镇江市",
                                "parentId": "321100",
                                "provinceType": "2",
                                "province": "江苏省",
                                "city": "镇江市",
                                "county": "",

                            },
                            {
                                "cityCode": "321102",
                                "cityName": "京口区",
                                "parentId": "321100",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "镇江市",
                                "county": "京口区",

                            },
                            {
                                "cityCode": "321111",
                                "cityName": "润州区",
                                "parentId": "321100",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "镇江市",
                                "county": "润州区",

                            },
                            {
                                "cityCode": "321112",
                                "cityName": "丹徒区",
                                "parentId": "321100",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "镇江市",
                                "county": "丹徒区",

                            },
                            {
                                "cityCode": "321171",
                                "cityName": "镇江新区",
                                "parentId": "321100",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "镇江市",
                                "county": "镇江新区",

                            },
                            {
                                "cityCode": "321181",
                                "cityName": "丹阳市",
                                "parentId": "321100",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "镇江市",
                                "county": "丹阳市",

                            },
                            {
                                "cityCode": "321182",
                                "cityName": "扬中市",
                                "parentId": "321100",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "镇江市",
                                "county": "扬中市",

                            },
                            {
                                "cityCode": "321183",
                                "cityName": "句容市",
                                "parentId": "321100",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "镇江市",
                                "county": "句容市",

                            },
                            {
                                "cityCode": "321184",
                                "cityName": "镇江新区",
                                "parentId": "321100",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "镇江市",
                                "county": "镇江新区",

                            },
                            {
                                "cityCode": "321185",
                                "cityName": "镇江新区",
                                "parentId": "321100",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "镇江市",
                                "county": "镇江新区",

                            },
                            {
                                "cityCode": "321186",
                                "cityName": "经济开发区",
                                "parentId": "321100",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "镇江市",
                                "county": "经济开发区",

                            }
                        ]
                    },
                    {
                        "cityCode": "321200",
                        "cityName": "泰州市",
                        "parentId": "320000",
                        "provinceType": "2",
                        "province": "江苏省",
                        "city": "泰州市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "321201",
                                "cityName": "泰州市",
                                "parentId": "321200",
                                "provinceType": "2",
                                "province": "江苏省",
                                "city": "泰州市",
                                "county": "",

                            },
                            {
                                "cityCode": "321202",
                                "cityName": "海陵区",
                                "parentId": "321200",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "泰州市",
                                "county": "海陵区",

                            },
                            {
                                "cityCode": "321203",
                                "cityName": "高港区",
                                "parentId": "321200",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "泰州市",
                                "county": "高港区",

                            },
                            {
                                "cityCode": "321204",
                                "cityName": "姜堰区",
                                "parentId": "321200",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "泰州市",
                                "county": "姜堰区",

                            },
                            {
                                "cityCode": "321271",
                                "cityName": "泰州医药高新技术产业开发区",
                                "parentId": "321200",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "泰州市",
                                "county": "泰州医药高新技术产业开发区",

                            },
                            {
                                "cityCode": "321281",
                                "cityName": "兴化市",
                                "parentId": "321200",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "泰州市",
                                "county": "兴化市",

                            },
                            {
                                "cityCode": "321282",
                                "cityName": "靖江市",
                                "parentId": "321200",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "泰州市",
                                "county": "靖江市",

                            },
                            {
                                "cityCode": "321283",
                                "cityName": "泰兴市",
                                "parentId": "321200",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "泰州市",
                                "county": "泰兴市",

                            },
                            {
                                "cityCode": "321284",
                                "cityName": "姜堰市",
                                "parentId": "321200",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "泰州市",
                                "county": "姜堰市",

                            }
                        ]
                    },
                    {
                        "cityCode": "321300",
                        "cityName": "宿迁市",
                        "parentId": "320000",
                        "provinceType": "2",
                        "province": "江苏省",
                        "city": "宿迁市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "321301",
                                "cityName": "宿迁市",
                                "parentId": "321300",
                                "provinceType": "2",
                                "province": "江苏省",
                                "city": "宿迁市",
                                "county": "",

                            },
                            {
                                "cityCode": "321302",
                                "cityName": "宿城区",
                                "parentId": "321300",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "宿迁市",
                                "county": "宿城区",

                            },
                            {
                                "cityCode": "321311",
                                "cityName": "宿豫区",
                                "parentId": "321300",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "宿迁市",
                                "county": "宿豫区",

                            },
                            {
                                "cityCode": "321322",
                                "cityName": "沭阳县",
                                "parentId": "321300",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "宿迁市",
                                "county": "沭阳县",

                            },
                            {
                                "cityCode": "321323",
                                "cityName": "泗阳县",
                                "parentId": "321300",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "宿迁市",
                                "county": "泗阳县",

                            },
                            {
                                "cityCode": "321324",
                                "cityName": "泗洪县",
                                "parentId": "321300",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "宿迁市",
                                "county": "泗洪县",

                            },
                            {
                                "cityCode": "321325",
                                "cityName": "经济开发区",
                                "parentId": "321300",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "宿迁市",
                                "county": "经济开发区",

                            },
                            {
                                "cityCode": "321371",
                                "cityName": "宿迁经济技术开发区",
                                "parentId": "321300",
                                "provinceType": "3",
                                "province": "江苏省",
                                "city": "宿迁市",
                                "county": "宿迁经济技术开发区",

                            }
                        ]
                    }
                ]
            },
            {
                "cityCode": "330000",
                "cityName": "浙江省",
                "parentId": "100000",
                "provinceType": "1",
                "province": "浙江省",
                "city": "",
                "county": "",
                "counties": [
                    {
                        "cityCode": "330100",
                        "cityName": "杭州市",
                        "parentId": "330000",
                        "provinceType": "2",
                        "province": "浙江省",
                        "city": "杭州市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "330101",
                                "cityName": "杭州市",
                                "parentId": "330100",
                                "provinceType": "2",
                                "province": "浙江省",
                                "city": "杭州市",
                                "county": "",

                            },
                            {
                                "cityCode": "330102",
                                "cityName": "上城区",
                                "parentId": "330100",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "杭州市",
                                "county": "上城区",

                            },
                            {
                                "cityCode": "330103",
                                "cityName": "下城区",
                                "parentId": "330100",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "杭州市",
                                "county": "下城区",

                            },
                            {
                                "cityCode": "330104",
                                "cityName": "江干区",
                                "parentId": "330100",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "杭州市",
                                "county": "江干区",

                            },
                            {
                                "cityCode": "330105",
                                "cityName": "拱墅区",
                                "parentId": "330100",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "杭州市",
                                "county": "拱墅区",

                            },
                            {
                                "cityCode": "330106",
                                "cityName": "西湖区",
                                "parentId": "330100",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "杭州市",
                                "county": "西湖区",

                            },
                            {
                                "cityCode": "330108",
                                "cityName": "滨江区",
                                "parentId": "330100",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "杭州市",
                                "county": "滨江区",

                            },
                            {
                                "cityCode": "330109",
                                "cityName": "萧山区",
                                "parentId": "330100",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "杭州市",
                                "county": "萧山区",

                            },
                            {
                                "cityCode": "330110",
                                "cityName": "余杭区",
                                "parentId": "330100",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "杭州市",
                                "county": "余杭区",

                            },
                            {
                                "cityCode": "330111",
                                "cityName": "富阳区",
                                "parentId": "330100",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "杭州市",
                                "county": "富阳区",

                            },
                            {
                                "cityCode": "330112",
                                "cityName": "临安区",
                                "parentId": "330100",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "杭州市",
                                "county": "临安区",

                            },
                            {
                                "cityCode": "330122",
                                "cityName": "桐庐县",
                                "parentId": "330100",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "杭州市",
                                "county": "桐庐县",

                            },
                            {
                                "cityCode": "330127",
                                "cityName": "淳安县",
                                "parentId": "330100",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "杭州市",
                                "county": "淳安县",

                            },
                            {
                                "cityCode": "330182",
                                "cityName": "建德市",
                                "parentId": "330100",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "杭州市",
                                "county": "建德市",

                            },
                            {
                                "cityCode": "330183",
                                "cityName": "富阳市",
                                "parentId": "330100",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "杭州市",
                                "county": "富阳市",

                            },
                            {
                                "cityCode": "330185",
                                "cityName": "临安市",
                                "parentId": "330100",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "杭州市",
                                "county": "临安市",

                            },
                            {
                                "cityCode": "330186",
                                "cityName": "高新区",
                                "parentId": "330100",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "杭州市",
                                "county": "高新区",

                            }
                        ]
                    },
                    {
                        "cityCode": "330200",
                        "cityName": "宁波市",
                        "parentId": "330000",
                        "provinceType": "2",
                        "province": "浙江省",
                        "city": "宁波市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "330201",
                                "cityName": "宁波市",
                                "parentId": "330200",
                                "provinceType": "2",
                                "province": "浙江省",
                                "city": "宁波市",
                                "county": "",

                            },
                            {
                                "cityCode": "330203",
                                "cityName": "海曙区",
                                "parentId": "330200",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "宁波市",
                                "county": "海曙区",

                            },
                            {
                                "cityCode": "330204",
                                "cityName": "江东区",
                                "parentId": "330200",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "宁波市",
                                "county": "江东区",

                            },
                            {
                                "cityCode": "330205",
                                "cityName": "江北区",
                                "parentId": "330200",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "宁波市",
                                "county": "江北区",

                            },
                            {
                                "cityCode": "330206",
                                "cityName": "北仑区",
                                "parentId": "330200",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "宁波市",
                                "county": "北仑区",

                            },
                            {
                                "cityCode": "330211",
                                "cityName": "镇海区",
                                "parentId": "330200",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "宁波市",
                                "county": "镇海区",

                            },
                            {
                                "cityCode": "330212",
                                "cityName": "鄞州区",
                                "parentId": "330200",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "宁波市",
                                "county": "鄞州区",

                            },
                            {
                                "cityCode": "330213",
                                "cityName": "奉化区",
                                "parentId": "330200",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "宁波市",
                                "county": "奉化区",

                            },
                            {
                                "cityCode": "330225",
                                "cityName": "象山县",
                                "parentId": "330200",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "宁波市",
                                "county": "象山县",

                            },
                            {
                                "cityCode": "330226",
                                "cityName": "宁海县",
                                "parentId": "330200",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "宁波市",
                                "county": "宁海县",

                            },
                            {
                                "cityCode": "330281",
                                "cityName": "余姚市",
                                "parentId": "330200",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "宁波市",
                                "county": "余姚市",

                            },
                            {
                                "cityCode": "330282",
                                "cityName": "慈溪市",
                                "parentId": "330200",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "宁波市",
                                "county": "慈溪市",

                            },
                            {
                                "cityCode": "330283",
                                "cityName": "奉化区",
                                "parentId": "330200",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "宁波市",
                                "county": "奉化区",

                            },
                            {
                                "cityCode": "330284",
                                "cityName": "杭州湾新区",
                                "parentId": "330200",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "宁波市",
                                "county": "杭州湾新区",

                            },
                            {
                                "cityCode": "330285",
                                "cityName": "高新区",
                                "parentId": "330200",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "宁波市",
                                "county": "高新区",

                            }
                        ]
                    },
                    {
                        "cityCode": "330300",
                        "cityName": "温州市",
                        "parentId": "330000",
                        "provinceType": "2",
                        "province": "浙江省",
                        "city": "温州市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "330301",
                                "cityName": "温州市",
                                "parentId": "330300",
                                "provinceType": "2",
                                "province": "浙江省",
                                "city": "温州市",
                                "county": "",

                            },
                            {
                                "cityCode": "330302",
                                "cityName": "鹿城区",
                                "parentId": "330300",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "温州市",
                                "county": "鹿城区",

                            },
                            {
                                "cityCode": "330303",
                                "cityName": "龙湾区",
                                "parentId": "330300",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "温州市",
                                "county": "龙湾区",

                            },
                            {
                                "cityCode": "330304",
                                "cityName": "瓯海区",
                                "parentId": "330300",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "温州市",
                                "county": "瓯海区",

                            },
                            {
                                "cityCode": "330305",
                                "cityName": "洞头区",
                                "parentId": "330300",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "温州市",
                                "county": "洞头区",

                            },
                            {
                                "cityCode": "330322",
                                "cityName": "洞头县",
                                "parentId": "330300",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "温州市",
                                "county": "洞头县",

                            },
                            {
                                "cityCode": "330324",
                                "cityName": "永嘉县",
                                "parentId": "330300",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "温州市",
                                "county": "永嘉县",

                            },
                            {
                                "cityCode": "330326",
                                "cityName": "平阳县",
                                "parentId": "330300",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "温州市",
                                "county": "平阳县",

                            },
                            {
                                "cityCode": "330327",
                                "cityName": "苍南县",
                                "parentId": "330300",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "温州市",
                                "county": "苍南县",

                            },
                            {
                                "cityCode": "330328",
                                "cityName": "文成县",
                                "parentId": "330300",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "温州市",
                                "county": "文成县",

                            },
                            {
                                "cityCode": "330329",
                                "cityName": "泰顺县",
                                "parentId": "330300",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "温州市",
                                "county": "泰顺县",

                            },
                            {
                                "cityCode": "330371",
                                "cityName": "温州经济技术开发区",
                                "parentId": "330300",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "温州市",
                                "county": "温州经济技术开发区",

                            },
                            {
                                "cityCode": "330381",
                                "cityName": "瑞安市",
                                "parentId": "330300",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "温州市",
                                "county": "瑞安市",

                            },
                            {
                                "cityCode": "330382",
                                "cityName": "乐清市",
                                "parentId": "330300",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "温州市",
                                "county": "乐清市",

                            },
                            {
                                "cityCode": "330383",
                                "cityName": "龙港市",
                                "parentId": "330300",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "温州市",
                                "county": "龙港市",

                            }
                        ]
                    },
                    {
                        "cityCode": "330400",
                        "cityName": "嘉兴市",
                        "parentId": "330000",
                        "provinceType": "2",
                        "province": "浙江省",
                        "city": "嘉兴市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "330402",
                                "cityName": "南湖区",
                                "parentId": "330400",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "嘉兴市",
                                "county": "南湖区",

                            },
                            {
                                "cityCode": "330411",
                                "cityName": "秀洲区",
                                "parentId": "330400",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "嘉兴市",
                                "county": "秀洲区",

                            },
                            {
                                "cityCode": "330421",
                                "cityName": "嘉善县",
                                "parentId": "330400",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "嘉兴市",
                                "county": "嘉善县",

                            },
                            {
                                "cityCode": "330424",
                                "cityName": "海盐县",
                                "parentId": "330400",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "嘉兴市",
                                "county": "海盐县",

                            },
                            {
                                "cityCode": "330481",
                                "cityName": "海宁市",
                                "parentId": "330400",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "嘉兴市",
                                "county": "海宁市",

                            },
                            {
                                "cityCode": "330482",
                                "cityName": "平湖市",
                                "parentId": "330400",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "嘉兴市",
                                "county": "平湖市",

                            },
                            {
                                "cityCode": "330483",
                                "cityName": "桐乡市",
                                "parentId": "330400",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "嘉兴市",
                                "county": "桐乡市",

                            }
                        ]
                    },
                    {
                        "cityCode": "330500",
                        "cityName": "湖州市",
                        "parentId": "330000",
                        "provinceType": "2",
                        "province": "浙江省",
                        "city": "湖州市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "330501",
                                "cityName": "湖州市",
                                "parentId": "330500",
                                "provinceType": "2",
                                "province": "浙江省",
                                "city": "湖州市",
                                "county": "",

                            },
                            {
                                "cityCode": "330502",
                                "cityName": "吴兴区",
                                "parentId": "330500",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "湖州市",
                                "county": "吴兴区",

                            },
                            {
                                "cityCode": "330503",
                                "cityName": "南浔区",
                                "parentId": "330500",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "湖州市",
                                "county": "南浔区",

                            },
                            {
                                "cityCode": "330521",
                                "cityName": "德清县",
                                "parentId": "330500",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "湖州市",
                                "county": "德清县",

                            },
                            {
                                "cityCode": "330522",
                                "cityName": "长兴县",
                                "parentId": "330500",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "湖州市",
                                "county": "长兴县",

                            },
                            {
                                "cityCode": "330523",
                                "cityName": "安吉县",
                                "parentId": "330500",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "湖州市",
                                "county": "安吉县",

                            }
                        ]
                    },
                    {
                        "cityCode": "330600",
                        "cityName": "绍兴市",
                        "parentId": "330000",
                        "provinceType": "2",
                        "province": "浙江省",
                        "city": "绍兴市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "330601",
                                "cityName": "绍兴市",
                                "parentId": "330600",
                                "provinceType": "2",
                                "province": "浙江省",
                                "city": "绍兴市",
                                "county": "",

                            },
                            {
                                "cityCode": "330602",
                                "cityName": "越城区",
                                "parentId": "330600",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "绍兴市",
                                "county": "越城区",

                            },
                            {
                                "cityCode": "330603",
                                "cityName": "柯桥区",
                                "parentId": "330600",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "绍兴市",
                                "county": "柯桥区",

                            },
                            {
                                "cityCode": "330604",
                                "cityName": "上虞区",
                                "parentId": "330600",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "绍兴市",
                                "county": "上虞区",

                            },
                            {
                                "cityCode": "330621",
                                "cityName": "绍兴县",
                                "parentId": "330600",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "绍兴市",
                                "county": "绍兴县",

                            },
                            {
                                "cityCode": "330624",
                                "cityName": "新昌县",
                                "parentId": "330600",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "绍兴市",
                                "county": "新昌县",

                            },
                            {
                                "cityCode": "330681",
                                "cityName": "诸暨市",
                                "parentId": "330600",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "绍兴市",
                                "county": "诸暨市",

                            },
                            {
                                "cityCode": "330682",
                                "cityName": "上虞市",
                                "parentId": "330600",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "绍兴市",
                                "county": "上虞市",

                            },
                            {
                                "cityCode": "330683",
                                "cityName": "嵊州市",
                                "parentId": "330600",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "绍兴市",
                                "county": "嵊州市",

                            }
                        ]
                    },
                    {
                        "cityCode": "330700",
                        "cityName": "金华市",
                        "parentId": "330000",
                        "provinceType": "2",
                        "province": "浙江省",
                        "city": "金华市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "330701",
                                "cityName": "金华市",
                                "parentId": "330700",
                                "provinceType": "2",
                                "province": "浙江省",
                                "city": "金华市",
                                "county": "",

                            },
                            {
                                "cityCode": "330702",
                                "cityName": "婺城区",
                                "parentId": "330700",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "金华市",
                                "county": "婺城区",

                            },
                            {
                                "cityCode": "330703",
                                "cityName": "金东区",
                                "parentId": "330700",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "金华市",
                                "county": "金东区",

                            },
                            {
                                "cityCode": "330723",
                                "cityName": "武义县",
                                "parentId": "330700",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "金华市",
                                "county": "武义县",

                            },
                            {
                                "cityCode": "330726",
                                "cityName": "浦江县",
                                "parentId": "330700",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "金华市",
                                "county": "浦江县",

                            },
                            {
                                "cityCode": "330727",
                                "cityName": "磐安县",
                                "parentId": "330700",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "金华市",
                                "county": "磐安县",

                            },
                            {
                                "cityCode": "330781",
                                "cityName": "兰溪市",
                                "parentId": "330700",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "金华市",
                                "county": "兰溪市",

                            },
                            {
                                "cityCode": "330782",
                                "cityName": "义乌市",
                                "parentId": "330700",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "金华市",
                                "county": "义乌市",

                            },
                            {
                                "cityCode": "330783",
                                "cityName": "东阳市",
                                "parentId": "330700",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "金华市",
                                "county": "东阳市",

                            },
                            {
                                "cityCode": "330784",
                                "cityName": "永康市",
                                "parentId": "330700",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "金华市",
                                "county": "永康市",

                            }
                        ]
                    },
                    {
                        "cityCode": "330800",
                        "cityName": "衢州市",
                        "parentId": "330000",
                        "provinceType": "2",
                        "province": "浙江省",
                        "city": "衢州市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "330801",
                                "cityName": "衢州市",
                                "parentId": "330800",
                                "provinceType": "2",
                                "province": "浙江省",
                                "city": "衢州市",
                                "county": "",

                            },
                            {
                                "cityCode": "330802",
                                "cityName": "柯城区",
                                "parentId": "330800",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "衢州市",
                                "county": "柯城区",

                            },
                            {
                                "cityCode": "330803",
                                "cityName": "衢江区",
                                "parentId": "330800",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "衢州市",
                                "county": "衢江区",

                            },
                            {
                                "cityCode": "330822",
                                "cityName": "常山县",
                                "parentId": "330800",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "衢州市",
                                "county": "常山县",

                            },
                            {
                                "cityCode": "330824",
                                "cityName": "开化县",
                                "parentId": "330800",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "衢州市",
                                "county": "开化县",

                            },
                            {
                                "cityCode": "330825",
                                "cityName": "龙游县",
                                "parentId": "330800",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "衢州市",
                                "county": "龙游县",

                            },
                            {
                                "cityCode": "330881",
                                "cityName": "江山市",
                                "parentId": "330800",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "衢州市",
                                "county": "江山市",

                            }
                        ]
                    },
                    {
                        "cityCode": "330900",
                        "cityName": "舟山市",
                        "parentId": "330000",
                        "provinceType": "2",
                        "province": "浙江省",
                        "city": "舟山市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "330901",
                                "cityName": "舟山市",
                                "parentId": "330900",
                                "provinceType": "2",
                                "province": "浙江省",
                                "city": "舟山市",
                                "county": "",

                            },
                            {
                                "cityCode": "330902",
                                "cityName": "定海区",
                                "parentId": "330900",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "舟山市",
                                "county": "定海区",

                            },
                            {
                                "cityCode": "330903",
                                "cityName": "普陀区",
                                "parentId": "330900",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "舟山市",
                                "county": "普陀区",

                            },
                            {
                                "cityCode": "330921",
                                "cityName": "岱山县",
                                "parentId": "330900",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "舟山市",
                                "county": "岱山县",

                            },
                            {
                                "cityCode": "330922",
                                "cityName": "嵊泗县",
                                "parentId": "330900",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "舟山市",
                                "county": "嵊泗县",

                            }
                        ]
                    },
                    {
                        "cityCode": "331000",
                        "cityName": "台州市",
                        "parentId": "330000",
                        "provinceType": "2",
                        "province": "浙江省",
                        "city": "台州市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "331001",
                                "cityName": "台州市",
                                "parentId": "331000",
                                "provinceType": "2",
                                "province": "浙江省",
                                "city": "台州市",
                                "county": "",

                            },
                            {
                                "cityCode": "331002",
                                "cityName": "椒江区",
                                "parentId": "331000",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "台州市",
                                "county": "椒江区",

                            },
                            {
                                "cityCode": "331003",
                                "cityName": "黄岩区",
                                "parentId": "331000",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "台州市",
                                "county": "黄岩区",

                            },
                            {
                                "cityCode": "331004",
                                "cityName": "路桥区",
                                "parentId": "331000",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "台州市",
                                "county": "路桥区",

                            },
                            {
                                "cityCode": "331021",
                                "cityName": "玉环县",
                                "parentId": "331000",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "台州市",
                                "county": "玉环县",

                            },
                            {
                                "cityCode": "331022",
                                "cityName": "三门县",
                                "parentId": "331000",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "台州市",
                                "county": "三门县",

                            },
                            {
                                "cityCode": "331023",
                                "cityName": "天台县",
                                "parentId": "331000",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "台州市",
                                "county": "天台县",

                            },
                            {
                                "cityCode": "331024",
                                "cityName": "仙居县",
                                "parentId": "331000",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "台州市",
                                "county": "仙居县",

                            },
                            {
                                "cityCode": "331081",
                                "cityName": "温岭市",
                                "parentId": "331000",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "台州市",
                                "county": "温岭市",

                            },
                            {
                                "cityCode": "331082",
                                "cityName": "临海市",
                                "parentId": "331000",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "台州市",
                                "county": "临海市",

                            },
                            {
                                "cityCode": "331083",
                                "cityName": "玉环区",
                                "parentId": "331000",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "台州市",
                                "county": "玉环区",

                            }
                        ]
                    },
                    {
                        "cityCode": "331100",
                        "cityName": "丽水市",
                        "parentId": "330000",
                        "provinceType": "2",
                        "province": "浙江省",
                        "city": "丽水市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "331101",
                                "cityName": "丽水市",
                                "parentId": "331100",
                                "provinceType": "2",
                                "province": "浙江省",
                                "city": "丽水市",
                                "county": "",

                            },
                            {
                                "cityCode": "331102",
                                "cityName": "莲都区",
                                "parentId": "331100",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "丽水市",
                                "county": "莲都区",

                            },
                            {
                                "cityCode": "331121",
                                "cityName": "青田县",
                                "parentId": "331100",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "丽水市",
                                "county": "青田县",

                            },
                            {
                                "cityCode": "331122",
                                "cityName": "缙云县",
                                "parentId": "331100",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "丽水市",
                                "county": "缙云县",

                            },
                            {
                                "cityCode": "331123",
                                "cityName": "遂昌县",
                                "parentId": "331100",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "丽水市",
                                "county": "遂昌县",

                            },
                            {
                                "cityCode": "331124",
                                "cityName": "松阳县",
                                "parentId": "331100",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "丽水市",
                                "county": "松阳县",

                            },
                            {
                                "cityCode": "331125",
                                "cityName": "云和县",
                                "parentId": "331100",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "丽水市",
                                "county": "云和县",

                            },
                            {
                                "cityCode": "331126",
                                "cityName": "庆元县",
                                "parentId": "331100",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "丽水市",
                                "county": "庆元县",

                            },
                            {
                                "cityCode": "331127",
                                "cityName": "景宁畲族自治县",
                                "parentId": "331100",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "丽水市",
                                "county": "景宁畲族自治县",

                            },
                            {
                                "cityCode": "331181",
                                "cityName": "龙泉市",
                                "parentId": "331100",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "丽水市",
                                "county": "龙泉市",

                            }
                        ]
                    },
                    {
                        "cityCode": "331200",
                        "cityName": "舟山群岛新区",
                        "parentId": "330000",
                        "provinceType": "2",
                        "province": "浙江省",
                        "city": "舟山群岛新区",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "331201",
                                "cityName": "金塘岛",
                                "parentId": "331200",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "舟山群岛新区",
                                "county": "金塘岛",

                            },
                            {
                                "cityCode": "331202",
                                "cityName": "六横岛",
                                "parentId": "331200",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "舟山群岛新区",
                                "county": "六横岛",

                            },
                            {
                                "cityCode": "331203",
                                "cityName": "衢山岛",
                                "parentId": "331200",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "舟山群岛新区",
                                "county": "衢山岛",

                            },
                            {
                                "cityCode": "331204",
                                "cityName": "舟山本岛西北部",
                                "parentId": "331200",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "舟山群岛新区",
                                "county": "舟山本岛西北部",

                            },
                            {
                                "cityCode": "331205",
                                "cityName": "岱山岛西南部",
                                "parentId": "331200",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "舟山群岛新区",
                                "county": "岱山岛西南部",

                            },
                            {
                                "cityCode": "331206",
                                "cityName": "泗礁岛",
                                "parentId": "331200",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "舟山群岛新区",
                                "county": "泗礁岛",

                            },
                            {
                                "cityCode": "331207",
                                "cityName": "朱家尖岛",
                                "parentId": "331200",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "舟山群岛新区",
                                "county": "朱家尖岛",

                            },
                            {
                                "cityCode": "331208",
                                "cityName": "洋山岛",
                                "parentId": "331200",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "舟山群岛新区",
                                "county": "洋山岛",

                            },
                            {
                                "cityCode": "331209",
                                "cityName": "长涂岛",
                                "parentId": "331200",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "舟山群岛新区",
                                "county": "长涂岛",

                            },
                            {
                                "cityCode": "331210",
                                "cityName": "虾峙岛",
                                "parentId": "331200",
                                "provinceType": "3",
                                "province": "浙江省",
                                "city": "舟山群岛新区",
                                "county": "虾峙岛",

                            }
                        ]
                    }
                ]
            },
            {
                "cityCode": "340000",
                "cityName": "安徽省",
                "parentId": "100000",
                "provinceType": "1",
                "province": "安徽省",
                "city": "",
                "county": "",
                "counties": [
                    {
                        "cityCode": "340100",
                        "cityName": "合肥市",
                        "parentId": "340000",
                        "provinceType": "2",
                        "province": "安徽省",
                        "city": "合肥市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "340101",
                                "cityName": "合肥市",
                                "parentId": "340100",
                                "provinceType": "2",
                                "province": "安徽省",
                                "city": "合肥市",
                                "county": "",

                            },
                            {
                                "cityCode": "340102",
                                "cityName": "瑶海区",
                                "parentId": "340100",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "合肥市",
                                "county": "瑶海区",

                            },
                            {
                                "cityCode": "340103",
                                "cityName": "庐阳区",
                                "parentId": "340100",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "合肥市",
                                "county": "庐阳区",

                            },
                            {
                                "cityCode": "340104",
                                "cityName": "蜀山区",
                                "parentId": "340100",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "合肥市",
                                "county": "蜀山区",

                            },
                            {
                                "cityCode": "340111",
                                "cityName": "包河区",
                                "parentId": "340100",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "合肥市",
                                "county": "包河区",

                            },
                            {
                                "cityCode": "340121",
                                "cityName": "长丰县",
                                "parentId": "340100",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "合肥市",
                                "county": "长丰县",

                            },
                            {
                                "cityCode": "340122",
                                "cityName": "肥东县",
                                "parentId": "340100",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "合肥市",
                                "county": "肥东县",

                            },
                            {
                                "cityCode": "340123",
                                "cityName": "肥西县",
                                "parentId": "340100",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "合肥市",
                                "county": "肥西县",

                            },
                            {
                                "cityCode": "340124",
                                "cityName": "庐江县",
                                "parentId": "340100",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "合肥市",
                                "county": "庐江县",

                            },
                            {
                                "cityCode": "340171",
                                "cityName": "合肥高新技术产业开发区",
                                "parentId": "340100",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "合肥市",
                                "county": "合肥高新技术产业开发区",

                            },
                            {
                                "cityCode": "340172",
                                "cityName": "合肥经济技术开发区",
                                "parentId": "340100",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "合肥市",
                                "county": "合肥经济技术开发区",

                            },
                            {
                                "cityCode": "340173",
                                "cityName": "合肥新站高新技术产业开发区",
                                "parentId": "340100",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "合肥市",
                                "county": "合肥新站高新技术产业开发区",

                            },
                            {
                                "cityCode": "340181",
                                "cityName": "巢湖市",
                                "parentId": "340100",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "合肥市",
                                "county": "巢湖市",

                            },
                            {
                                "cityCode": "340184",
                                "cityName": "经济技术开发区",
                                "parentId": "340100",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "合肥市",
                                "county": "经济技术开发区",

                            },
                            {
                                "cityCode": "340185",
                                "cityName": "高新技术开发区",
                                "parentId": "340100",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "合肥市",
                                "county": "高新技术开发区",

                            },
                            {
                                "cityCode": "340186",
                                "cityName": "北城新区",
                                "parentId": "340100",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "合肥市",
                                "county": "北城新区",

                            },
                            {
                                "cityCode": "340187",
                                "cityName": "滨湖新区",
                                "parentId": "340100",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "合肥市",
                                "county": "滨湖新区",

                            },
                            {
                                "cityCode": "340188",
                                "cityName": "政务文化新区",
                                "parentId": "340100",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "合肥市",
                                "county": "政务文化新区",

                            },
                            {
                                "cityCode": "340189",
                                "cityName": "新站综合开发试验区",
                                "parentId": "340100",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "合肥市",
                                "county": "新站综合开发试验区",

                            },
                            {
                                "cityCode": "341400",
                                "cityName": "巢湖市*",
                                "parentId": "340100",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "合肥市",
                                "county": "巢湖市*",

                            }
                        ]
                    },
                    {
                        "cityCode": "340200",
                        "cityName": "芜湖市",
                        "parentId": "340000",
                        "provinceType": "2",
                        "province": "安徽省",
                        "city": "芜湖市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "340201",
                                "cityName": "芜湖市",
                                "parentId": "340200",
                                "provinceType": "2",
                                "province": "安徽省",
                                "city": "芜湖市",
                                "county": "",

                            },
                            {
                                "cityCode": "340202",
                                "cityName": "镜湖区",
                                "parentId": "340200",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "芜湖市",
                                "county": "镜湖区",

                            },
                            {
                                "cityCode": "340207",
                                "cityName": "鸠江区",
                                "parentId": "340200",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "芜湖市",
                                "county": "鸠江区",

                            },
                            {
                                "cityCode": "340208",
                                "cityName": "三山区",
                                "parentId": "340200",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "芜湖市",
                                "county": "三山区",

                            },
                            {
                                "cityCode": "340209",
                                "cityName": "弋江区",
                                "parentId": "340200",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "芜湖市",
                                "county": "弋江区",

                            },
                            {
                                "cityCode": "340210",
                                "cityName": "湾沚区",
                                "parentId": "340200",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "芜湖市",
                                "county": "湾沚区",

                            },
                            {
                                "cityCode": "340212",
                                "cityName": "繁昌区",
                                "parentId": "340200",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "芜湖市",
                                "county": "繁昌区",

                            },
                            {
                                "cityCode": "340221",
                                "cityName": "芜湖县",
                                "parentId": "340200",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "芜湖市",
                                "county": "芜湖县",

                            },
                            {
                                "cityCode": "340223",
                                "cityName": "南陵县",
                                "parentId": "340200",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "芜湖市",
                                "county": "南陵县",

                            },
                            {
                                "cityCode": "340225",
                                "cityName": "无为县",
                                "parentId": "340200",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "芜湖市",
                                "county": "无为县",

                            },
                            {
                                "cityCode": "340226",
                                "cityName": "经济技术开发区",
                                "parentId": "340200",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "芜湖市",
                                "county": "经济技术开发区",

                            },
                            {
                                "cityCode": "340271",
                                "cityName": "芜湖经济技术开发区",
                                "parentId": "340200",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "芜湖市",
                                "county": "芜湖经济技术开发区",

                            },
                            {
                                "cityCode": "340272",
                                "cityName": "安徽芜湖长江大桥经济开发区",
                                "parentId": "340200",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "芜湖市",
                                "county": "安徽芜湖长江大桥经济开发区",

                            },
                            {
                                "cityCode": "340281",
                                "cityName": "无为市",
                                "parentId": "340200",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "芜湖市",
                                "county": "无为市",

                            }
                        ]
                    },
                    {
                        "cityCode": "340300",
                        "cityName": "蚌埠市",
                        "parentId": "340000",
                        "provinceType": "2",
                        "province": "安徽省",
                        "city": "蚌埠市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "340301",
                                "cityName": "蚌埠市",
                                "parentId": "340300",
                                "provinceType": "2",
                                "province": "安徽省",
                                "city": "蚌埠市",
                                "county": "",

                            },
                            {
                                "cityCode": "340302",
                                "cityName": "龙子湖区",
                                "parentId": "340300",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "蚌埠市",
                                "county": "龙子湖区",

                            },
                            {
                                "cityCode": "340303",
                                "cityName": "蚌山区",
                                "parentId": "340300",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "蚌埠市",
                                "county": "蚌山区",

                            },
                            {
                                "cityCode": "340304",
                                "cityName": "禹会区",
                                "parentId": "340300",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "蚌埠市",
                                "county": "禹会区",

                            },
                            {
                                "cityCode": "340311",
                                "cityName": "淮上区",
                                "parentId": "340300",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "蚌埠市",
                                "county": "淮上区",

                            },
                            {
                                "cityCode": "340321",
                                "cityName": "怀远县",
                                "parentId": "340300",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "蚌埠市",
                                "county": "怀远县",

                            },
                            {
                                "cityCode": "340322",
                                "cityName": "五河县",
                                "parentId": "340300",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "蚌埠市",
                                "county": "五河县",

                            },
                            {
                                "cityCode": "340323",
                                "cityName": "固镇县",
                                "parentId": "340300",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "蚌埠市",
                                "county": "固镇县",

                            },
                            {
                                "cityCode": "340324",
                                "cityName": "高新区",
                                "parentId": "340300",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "蚌埠市",
                                "county": "高新区",

                            },
                            {
                                "cityCode": "340371",
                                "cityName": "蚌埠市高新技术开发区",
                                "parentId": "340300",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "蚌埠市",
                                "county": "蚌埠市高新技术开发区",

                            },
                            {
                                "cityCode": "340372",
                                "cityName": "蚌埠市经济开发区",
                                "parentId": "340300",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "蚌埠市",
                                "county": "蚌埠市经济开发区",

                            }
                        ]
                    },
                    {
                        "cityCode": "340400",
                        "cityName": "淮南市",
                        "parentId": "340000",
                        "provinceType": "2",
                        "province": "安徽省",
                        "city": "淮南市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "340401",
                                "cityName": "淮南市",
                                "parentId": "340400",
                                "provinceType": "2",
                                "province": "安徽省",
                                "city": "淮南市",
                                "county": "",

                            },
                            {
                                "cityCode": "340402",
                                "cityName": "大通区",
                                "parentId": "340400",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "淮南市",
                                "county": "大通区",

                            },
                            {
                                "cityCode": "340403",
                                "cityName": "田家庵区",
                                "parentId": "340400",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "淮南市",
                                "county": "田家庵区",

                            },
                            {
                                "cityCode": "340404",
                                "cityName": "谢家集区",
                                "parentId": "340400",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "淮南市",
                                "county": "谢家集区",

                            },
                            {
                                "cityCode": "340405",
                                "cityName": "八公山区",
                                "parentId": "340400",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "淮南市",
                                "county": "八公山区",

                            },
                            {
                                "cityCode": "340406",
                                "cityName": "潘集区",
                                "parentId": "340400",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "淮南市",
                                "county": "潘集区",

                            },
                            {
                                "cityCode": "340421",
                                "cityName": "凤台县",
                                "parentId": "340400",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "淮南市",
                                "county": "凤台县",

                            },
                            {
                                "cityCode": "340422",
                                "cityName": "寿县",
                                "parentId": "340400",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "淮南市",
                                "county": "寿县",

                            },
                            {
                                "cityCode": "340423",
                                "cityName": "山南新区",
                                "parentId": "340400",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "淮南市",
                                "county": "山南新区",

                            },
                            {
                                "cityCode": "340424",
                                "cityName": "毛集实验区",
                                "parentId": "340400",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "淮南市",
                                "county": "毛集实验区",

                            },
                            {
                                "cityCode": "340425",
                                "cityName": "经济开发区",
                                "parentId": "340400",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "淮南市",
                                "county": "经济开发区",

                            },
                            {
                                "cityCode": "341521",
                                "cityName": "寿县",
                                "parentId": "340400",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "淮南市",
                                "county": "寿县",

                            }
                        ]
                    },
                    {
                        "cityCode": "340500",
                        "cityName": "马鞍山市",
                        "parentId": "340000",
                        "provinceType": "2",
                        "province": "安徽省",
                        "city": "马鞍山市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "340501",
                                "cityName": "马鞍山市",
                                "parentId": "340500",
                                "provinceType": "2",
                                "province": "安徽省",
                                "city": "马鞍山市",
                                "county": "",

                            },
                            {
                                "cityCode": "340502",
                                "cityName": "金家庄区",
                                "parentId": "340500",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "马鞍山市",
                                "county": "金家庄区",

                            },
                            {
                                "cityCode": "340503",
                                "cityName": "花山区",
                                "parentId": "340500",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "马鞍山市",
                                "county": "花山区",

                            },
                            {
                                "cityCode": "340504",
                                "cityName": "雨山区",
                                "parentId": "340500",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "马鞍山市",
                                "county": "雨山区",

                            },
                            {
                                "cityCode": "340506",
                                "cityName": "博望区",
                                "parentId": "340500",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "马鞍山市",
                                "county": "博望区",

                            },
                            {
                                "cityCode": "340521",
                                "cityName": "当涂县",
                                "parentId": "340500",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "马鞍山市",
                                "county": "当涂县",

                            },
                            {
                                "cityCode": "340522",
                                "cityName": "含山县",
                                "parentId": "340500",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "马鞍山市",
                                "county": "含山县",

                            },
                            {
                                "cityCode": "340523",
                                "cityName": "和县",
                                "parentId": "340500",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "马鞍山市",
                                "county": "和县",

                            }
                        ]
                    },
                    {
                        "cityCode": "340600",
                        "cityName": "淮北市",
                        "parentId": "340000",
                        "provinceType": "2",
                        "province": "安徽省",
                        "city": "淮北市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "340601",
                                "cityName": "淮北市",
                                "parentId": "340600",
                                "provinceType": "2",
                                "province": "安徽省",
                                "city": "淮北市",
                                "county": "",

                            },
                            {
                                "cityCode": "340602",
                                "cityName": "杜集区",
                                "parentId": "340600",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "淮北市",
                                "county": "杜集区",

                            },
                            {
                                "cityCode": "340603",
                                "cityName": "相山区",
                                "parentId": "340600",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "淮北市",
                                "county": "相山区",

                            },
                            {
                                "cityCode": "340604",
                                "cityName": "烈山区",
                                "parentId": "340600",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "淮北市",
                                "county": "烈山区",

                            },
                            {
                                "cityCode": "340621",
                                "cityName": "濉溪县",
                                "parentId": "340600",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "淮北市",
                                "county": "濉溪县",

                            }
                        ]
                    },
                    {
                        "cityCode": "340700",
                        "cityName": "铜陵市",
                        "parentId": "340000",
                        "provinceType": "2",
                        "province": "安徽省",
                        "city": "铜陵市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "340701",
                                "cityName": "铜陵市",
                                "parentId": "340700",
                                "provinceType": "2",
                                "province": "安徽省",
                                "city": "铜陵市",
                                "county": "",

                            },
                            {
                                "cityCode": "340702",
                                "cityName": "铜官山区",
                                "parentId": "340700",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "铜陵市",
                                "county": "铜官山区",

                            },
                            {
                                "cityCode": "340703",
                                "cityName": "狮子山区",
                                "parentId": "340700",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "铜陵市",
                                "county": "狮子山区",

                            },
                            {
                                "cityCode": "340705",
                                "cityName": "铜官区",
                                "parentId": "340700",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "铜陵市",
                                "county": "铜官区",

                            },
                            {
                                "cityCode": "340706",
                                "cityName": "义安区",
                                "parentId": "340700",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "铜陵市",
                                "county": "义安区",

                            },
                            {
                                "cityCode": "340711",
                                "cityName": "郊区",
                                "parentId": "340700",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "铜陵市",
                                "county": "郊区",

                            },
                            {
                                "cityCode": "340721",
                                "cityName": "铜陵县",
                                "parentId": "340700",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "铜陵市",
                                "county": "铜陵县",

                            },
                            {
                                "cityCode": "340722",
                                "cityName": "枞阳县",
                                "parentId": "340700",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "铜陵市",
                                "county": "枞阳县",

                            },
                            {
                                "cityCode": "340823",
                                "cityName": "枞阳县",
                                "parentId": "340700",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "铜陵市",
                                "county": "枞阳县",

                            }
                        ]
                    },
                    {
                        "cityCode": "340800",
                        "cityName": "安庆市",
                        "parentId": "340000",
                        "provinceType": "2",
                        "province": "安徽省",
                        "city": "安庆市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "340801",
                                "cityName": "安庆市",
                                "parentId": "340800",
                                "provinceType": "2",
                                "province": "安徽省",
                                "city": "安庆市",
                                "county": "",

                            },
                            {
                                "cityCode": "340802",
                                "cityName": "迎江区",
                                "parentId": "340800",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "安庆市",
                                "county": "迎江区",

                            },
                            {
                                "cityCode": "340803",
                                "cityName": "大观区",
                                "parentId": "340800",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "安庆市",
                                "county": "大观区",

                            },
                            {
                                "cityCode": "340811",
                                "cityName": "宜秀区",
                                "parentId": "340800",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "安庆市",
                                "county": "宜秀区",

                            },
                            {
                                "cityCode": "340822",
                                "cityName": "怀宁县",
                                "parentId": "340800",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "安庆市",
                                "county": "怀宁县",

                            },
                            {
                                "cityCode": "340824",
                                "cityName": "潜山县",
                                "parentId": "340800",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "安庆市",
                                "county": "潜山县",

                            },
                            {
                                "cityCode": "340825",
                                "cityName": "太湖县",
                                "parentId": "340800",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "安庆市",
                                "county": "太湖县",

                            },
                            {
                                "cityCode": "340826",
                                "cityName": "宿松县",
                                "parentId": "340800",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "安庆市",
                                "county": "宿松县",

                            },
                            {
                                "cityCode": "340827",
                                "cityName": "望江县",
                                "parentId": "340800",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "安庆市",
                                "county": "望江县",

                            },
                            {
                                "cityCode": "340828",
                                "cityName": "岳西县",
                                "parentId": "340800",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "安庆市",
                                "county": "岳西县",

                            },
                            {
                                "cityCode": "340871",
                                "cityName": "安徽安庆经济开发区",
                                "parentId": "340800",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "安庆市",
                                "county": "安徽安庆经济开发区",

                            },
                            {
                                "cityCode": "340881",
                                "cityName": "桐城市",
                                "parentId": "340800",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "安庆市",
                                "county": "桐城市",

                            },
                            {
                                "cityCode": "340882",
                                "cityName": "潜山市",
                                "parentId": "340800",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "安庆市",
                                "county": "潜山市",

                            }
                        ]
                    },
                    {
                        "cityCode": "341000",
                        "cityName": "黄山市",
                        "parentId": "340000",
                        "provinceType": "2",
                        "province": "安徽省",
                        "city": "黄山市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "341001",
                                "cityName": "黄山市",
                                "parentId": "341000",
                                "provinceType": "2",
                                "province": "安徽省",
                                "city": "黄山市",
                                "county": "",

                            },
                            {
                                "cityCode": "341002",
                                "cityName": "屯溪区",
                                "parentId": "341000",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "黄山市",
                                "county": "屯溪区",

                            },
                            {
                                "cityCode": "341003",
                                "cityName": "黄山区",
                                "parentId": "341000",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "黄山市",
                                "county": "黄山区",

                            },
                            {
                                "cityCode": "341004",
                                "cityName": "徽州区",
                                "parentId": "341000",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "黄山市",
                                "county": "徽州区",

                            },
                            {
                                "cityCode": "341021",
                                "cityName": "歙县",
                                "parentId": "341000",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "黄山市",
                                "county": "歙县",

                            },
                            {
                                "cityCode": "341022",
                                "cityName": "休宁县",
                                "parentId": "341000",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "黄山市",
                                "county": "休宁县",

                            },
                            {
                                "cityCode": "341023",
                                "cityName": "黟县",
                                "parentId": "341000",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "黄山市",
                                "county": "黟县",

                            },
                            {
                                "cityCode": "341024",
                                "cityName": "祁门县",
                                "parentId": "341000",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "黄山市",
                                "county": "祁门县",

                            }
                        ]
                    },
                    {
                        "cityCode": "341100",
                        "cityName": "滁州市",
                        "parentId": "340000",
                        "provinceType": "2",
                        "province": "安徽省",
                        "city": "滁州市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "341101",
                                "cityName": "滁州市",
                                "parentId": "341100",
                                "provinceType": "2",
                                "province": "安徽省",
                                "city": "滁州市",
                                "county": "",

                            },
                            {
                                "cityCode": "341102",
                                "cityName": "琅琊区",
                                "parentId": "341100",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "滁州市",
                                "county": "琅琊区",

                            },
                            {
                                "cityCode": "341103",
                                "cityName": "南谯区",
                                "parentId": "341100",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "滁州市",
                                "county": "南谯区",

                            },
                            {
                                "cityCode": "341122",
                                "cityName": "来安县",
                                "parentId": "341100",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "滁州市",
                                "county": "来安县",

                            },
                            {
                                "cityCode": "341124",
                                "cityName": "全椒县",
                                "parentId": "341100",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "滁州市",
                                "county": "全椒县",

                            },
                            {
                                "cityCode": "341125",
                                "cityName": "定远县",
                                "parentId": "341100",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "滁州市",
                                "county": "定远县",

                            },
                            {
                                "cityCode": "341126",
                                "cityName": "凤阳县",
                                "parentId": "341100",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "滁州市",
                                "county": "凤阳县",

                            },
                            {
                                "cityCode": "341171",
                                "cityName": "苏滁现代产业园",
                                "parentId": "341100",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "滁州市",
                                "county": "苏滁现代产业园",

                            },
                            {
                                "cityCode": "341172",
                                "cityName": "滁州经济技术开发区",
                                "parentId": "341100",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "滁州市",
                                "county": "滁州经济技术开发区",

                            },
                            {
                                "cityCode": "341181",
                                "cityName": "天长市",
                                "parentId": "341100",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "滁州市",
                                "county": "天长市",

                            },
                            {
                                "cityCode": "341182",
                                "cityName": "明光市",
                                "parentId": "341100",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "滁州市",
                                "county": "明光市",

                            }
                        ]
                    },
                    {
                        "cityCode": "341200",
                        "cityName": "阜阳市",
                        "parentId": "340000",
                        "provinceType": "2",
                        "province": "安徽省",
                        "city": "阜阳市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "341201",
                                "cityName": "阜阳市",
                                "parentId": "341200",
                                "provinceType": "2",
                                "province": "安徽省",
                                "city": "阜阳市",
                                "county": "",

                            },
                            {
                                "cityCode": "341202",
                                "cityName": "颍州区",
                                "parentId": "341200",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "阜阳市",
                                "county": "颍州区",

                            },
                            {
                                "cityCode": "341203",
                                "cityName": "颍东区",
                                "parentId": "341200",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "阜阳市",
                                "county": "颍东区",

                            },
                            {
                                "cityCode": "341204",
                                "cityName": "颍泉区",
                                "parentId": "341200",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "阜阳市",
                                "county": "颍泉区",

                            },
                            {
                                "cityCode": "341221",
                                "cityName": "临泉县",
                                "parentId": "341200",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "阜阳市",
                                "county": "临泉县",

                            },
                            {
                                "cityCode": "341222",
                                "cityName": "太和县",
                                "parentId": "341200",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "阜阳市",
                                "county": "太和县",

                            },
                            {
                                "cityCode": "341225",
                                "cityName": "阜南县",
                                "parentId": "341200",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "阜阳市",
                                "county": "阜南县",

                            },
                            {
                                "cityCode": "341226",
                                "cityName": "颍上县",
                                "parentId": "341200",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "阜阳市",
                                "county": "颍上县",

                            },
                            {
                                "cityCode": "341271",
                                "cityName": "阜阳合肥现代产业园区",
                                "parentId": "341200",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "阜阳市",
                                "county": "阜阳合肥现代产业园区",

                            },
                            {
                                "cityCode": "341272",
                                "cityName": "阜阳经济技术开发区",
                                "parentId": "341200",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "阜阳市",
                                "county": "阜阳经济技术开发区",

                            },
                            {
                                "cityCode": "341282",
                                "cityName": "界首市",
                                "parentId": "341200",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "阜阳市",
                                "county": "界首市",

                            },
                            {
                                "cityCode": "341283",
                                "cityName": "经济开发区",
                                "parentId": "341200",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "阜阳市",
                                "county": "经济开发区",

                            }
                        ]
                    },
                    {
                        "cityCode": "341300",
                        "cityName": "宿州市",
                        "parentId": "340000",
                        "provinceType": "2",
                        "province": "安徽省",
                        "city": "宿州市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "341301",
                                "cityName": "宿州市",
                                "parentId": "341300",
                                "provinceType": "2",
                                "province": "安徽省",
                                "city": "宿州市",
                                "county": "",

                            },
                            {
                                "cityCode": "341302",
                                "cityName": "埇桥区",
                                "parentId": "341300",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "宿州市",
                                "county": "埇桥区",

                            },
                            {
                                "cityCode": "341321",
                                "cityName": "砀山县",
                                "parentId": "341300",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "宿州市",
                                "county": "砀山县",

                            },
                            {
                                "cityCode": "341322",
                                "cityName": "萧县",
                                "parentId": "341300",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "宿州市",
                                "county": "萧县",

                            },
                            {
                                "cityCode": "341323",
                                "cityName": "灵璧县",
                                "parentId": "341300",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "宿州市",
                                "county": "灵璧县",

                            },
                            {
                                "cityCode": "341324",
                                "cityName": "泗县",
                                "parentId": "341300",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "宿州市",
                                "county": "泗县",

                            },
                            {
                                "cityCode": "341325",
                                "cityName": "经济开发区",
                                "parentId": "341300",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "宿州市",
                                "county": "经济开发区",

                            },
                            {
                                "cityCode": "341371",
                                "cityName": "宿州马鞍山现代产业园区",
                                "parentId": "341300",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "宿州市",
                                "county": "宿州马鞍山现代产业园区",

                            },
                            {
                                "cityCode": "341372",
                                "cityName": "宿州经济技术开发区",
                                "parentId": "341300",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "宿州市",
                                "county": "宿州经济技术开发区",

                            }
                        ]
                    },
                    {
                        "cityCode": "341500",
                        "cityName": "六安市",
                        "parentId": "340000",
                        "provinceType": "2",
                        "province": "安徽省",
                        "city": "六安市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "341501",
                                "cityName": "六安市",
                                "parentId": "341500",
                                "provinceType": "2",
                                "province": "安徽省",
                                "city": "六安市",
                                "county": "",

                            },
                            {
                                "cityCode": "341502",
                                "cityName": "金安区",
                                "parentId": "341500",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "六安市",
                                "county": "金安区",

                            },
                            {
                                "cityCode": "341503",
                                "cityName": "裕安区",
                                "parentId": "341500",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "六安市",
                                "county": "裕安区",

                            },
                            {
                                "cityCode": "341504",
                                "cityName": "叶集区",
                                "parentId": "341500",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "六安市",
                                "county": "叶集区",

                            },
                            {
                                "cityCode": "341522",
                                "cityName": "霍邱县",
                                "parentId": "341500",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "六安市",
                                "county": "霍邱县",

                            },
                            {
                                "cityCode": "341523",
                                "cityName": "舒城县",
                                "parentId": "341500",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "六安市",
                                "county": "舒城县",

                            },
                            {
                                "cityCode": "341524",
                                "cityName": "金寨县",
                                "parentId": "341500",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "六安市",
                                "county": "金寨县",

                            },
                            {
                                "cityCode": "341525",
                                "cityName": "霍山县",
                                "parentId": "341500",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "六安市",
                                "county": "霍山县",

                            }
                        ]
                    },
                    {
                        "cityCode": "341600",
                        "cityName": "亳州市",
                        "parentId": "340000",
                        "provinceType": "2",
                        "province": "安徽省",
                        "city": "亳州市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "341601",
                                "cityName": "亳州市",
                                "parentId": "341600",
                                "provinceType": "2",
                                "province": "安徽省",
                                "city": "亳州市",
                                "county": "",

                            },
                            {
                                "cityCode": "341602",
                                "cityName": "谯城区",
                                "parentId": "341600",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "亳州市",
                                "county": "谯城区",

                            },
                            {
                                "cityCode": "341621",
                                "cityName": "涡阳县",
                                "parentId": "341600",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "亳州市",
                                "county": "涡阳县",

                            },
                            {
                                "cityCode": "341622",
                                "cityName": "蒙城县",
                                "parentId": "341600",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "亳州市",
                                "county": "蒙城县",

                            },
                            {
                                "cityCode": "341623",
                                "cityName": "利辛县",
                                "parentId": "341600",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "亳州市",
                                "county": "利辛县",

                            }
                        ]
                    },
                    {
                        "cityCode": "341700",
                        "cityName": "池州市",
                        "parentId": "340000",
                        "provinceType": "2",
                        "province": "安徽省",
                        "city": "池州市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "341701",
                                "cityName": "池州市",
                                "parentId": "341700",
                                "provinceType": "2",
                                "province": "安徽省",
                                "city": "池州市",
                                "county": "",

                            },
                            {
                                "cityCode": "341702",
                                "cityName": "贵池区",
                                "parentId": "341700",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "池州市",
                                "county": "贵池区",

                            },
                            {
                                "cityCode": "341721",
                                "cityName": "东至县",
                                "parentId": "341700",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "池州市",
                                "county": "东至县",

                            },
                            {
                                "cityCode": "341722",
                                "cityName": "石台县",
                                "parentId": "341700",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "池州市",
                                "county": "石台县",

                            },
                            {
                                "cityCode": "341723",
                                "cityName": "青阳县",
                                "parentId": "341700",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "池州市",
                                "county": "青阳县",

                            }
                        ]
                    },
                    {
                        "cityCode": "341800",
                        "cityName": "宣城市",
                        "parentId": "340000",
                        "provinceType": "2",
                        "province": "安徽省",
                        "city": "宣城市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "341801",
                                "cityName": "宣城市",
                                "parentId": "341800",
                                "provinceType": "2",
                                "province": "安徽省",
                                "city": "宣城市",
                                "county": "",

                            },
                            {
                                "cityCode": "341802",
                                "cityName": "宣州区",
                                "parentId": "341800",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "宣城市",
                                "county": "宣州区",

                            },
                            {
                                "cityCode": "341821",
                                "cityName": "郎溪县",
                                "parentId": "341800",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "宣城市",
                                "county": "郎溪县",

                            },
                            {
                                "cityCode": "341822",
                                "cityName": "广德县",
                                "parentId": "341800",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "宣城市",
                                "county": "广德县",

                            },
                            {
                                "cityCode": "341823",
                                "cityName": "泾县",
                                "parentId": "341800",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "宣城市",
                                "county": "泾县",

                            },
                            {
                                "cityCode": "341824",
                                "cityName": "绩溪县",
                                "parentId": "341800",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "宣城市",
                                "county": "绩溪县",

                            },
                            {
                                "cityCode": "341825",
                                "cityName": "旌德县",
                                "parentId": "341800",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "宣城市",
                                "county": "旌德县",

                            },
                            {
                                "cityCode": "341871",
                                "cityName": "宣城市经济开发区",
                                "parentId": "341800",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "宣城市",
                                "county": "宣城市经济开发区",

                            },
                            {
                                "cityCode": "341881",
                                "cityName": "宁国市",
                                "parentId": "341800",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "宣城市",
                                "county": "宁国市",

                            },
                            {
                                "cityCode": "341882",
                                "cityName": "广德市",
                                "parentId": "341800",
                                "provinceType": "3",
                                "province": "安徽省",
                                "city": "宣城市",
                                "county": "广德市",

                            }
                        ]
                    }
                ]
            },
            {
                "cityCode": "350000",
                "cityName": "福建省",
                "parentId": "100000",
                "provinceType": "1",
                "province": "福建省",
                "city": "",
                "county": "",
                "counties": [
                    {
                        "cityCode": "350100",
                        "cityName": "福州市",
                        "parentId": "350000",
                        "provinceType": "2",
                        "province": "福建省",
                        "city": "福州市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "350101",
                                "cityName": "福州市",
                                "parentId": "350100",
                                "provinceType": "2",
                                "province": "福建省",
                                "city": "福州市",
                                "county": "",

                            },
                            {
                                "cityCode": "350102",
                                "cityName": "鼓楼区",
                                "parentId": "350100",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "福州市",
                                "county": "鼓楼区",

                            },
                            {
                                "cityCode": "350103",
                                "cityName": "台江区",
                                "parentId": "350100",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "福州市",
                                "county": "台江区",

                            },
                            {
                                "cityCode": "350104",
                                "cityName": "仓山区",
                                "parentId": "350100",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "福州市",
                                "county": "仓山区",

                            },
                            {
                                "cityCode": "350105",
                                "cityName": "马尾区",
                                "parentId": "350100",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "福州市",
                                "county": "马尾区",

                            },
                            {
                                "cityCode": "350111",
                                "cityName": "晋安区",
                                "parentId": "350100",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "福州市",
                                "county": "晋安区",

                            },
                            {
                                "cityCode": "350112",
                                "cityName": "长乐市",
                                "parentId": "350100",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "福州市",
                                "county": "长乐市",

                            },
                            {
                                "cityCode": "350121",
                                "cityName": "闽侯县",
                                "parentId": "350100",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "福州市",
                                "county": "闽侯县",

                            },
                            {
                                "cityCode": "350122",
                                "cityName": "连江县",
                                "parentId": "350100",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "福州市",
                                "county": "连江县",

                            },
                            {
                                "cityCode": "350123",
                                "cityName": "罗源县",
                                "parentId": "350100",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "福州市",
                                "county": "罗源县",

                            },
                            {
                                "cityCode": "350124",
                                "cityName": "闽清县",
                                "parentId": "350100",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "福州市",
                                "county": "闽清县",

                            },
                            {
                                "cityCode": "350125",
                                "cityName": "永泰县",
                                "parentId": "350100",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "福州市",
                                "county": "永泰县",

                            },
                            {
                                "cityCode": "350128",
                                "cityName": "平潭县",
                                "parentId": "350100",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "福州市",
                                "county": "平潭县",

                            },
                            {
                                "cityCode": "350181",
                                "cityName": "福清市",
                                "parentId": "350100",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "福州市",
                                "county": "福清市",

                            },
                            {
                                "cityCode": "350182",
                                "cityName": "长乐市",
                                "parentId": "350100",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "福州市",
                                "county": "长乐市",

                            },
                            {
                                "cityCode": "350183",
                                "cityName": "福州新区",
                                "parentId": "350100",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "福州市",
                                "county": "福州新区",

                            },
                            {
                                "cityCode": "351000",
                                "cityName": "平潭县",
                                "parentId": "350100",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "福州市",
                                "county": "平潭县",

                            }
                        ]
                    },
                    {
                        "cityCode": "350200",
                        "cityName": "厦门市",
                        "parentId": "350000",
                        "provinceType": "2",
                        "province": "福建省",
                        "city": "厦门市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "350201",
                                "cityName": "厦门市",
                                "parentId": "350200",
                                "provinceType": "2",
                                "province": "福建省",
                                "city": "厦门市",
                                "county": "",

                            },
                            {
                                "cityCode": "350203",
                                "cityName": "思明区",
                                "parentId": "350200",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "厦门市",
                                "county": "思明区",

                            },
                            {
                                "cityCode": "350205",
                                "cityName": "海沧区",
                                "parentId": "350200",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "厦门市",
                                "county": "海沧区",

                            },
                            {
                                "cityCode": "350206",
                                "cityName": "湖里区",
                                "parentId": "350200",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "厦门市",
                                "county": "湖里区",

                            },
                            {
                                "cityCode": "350211",
                                "cityName": "集美区",
                                "parentId": "350200",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "厦门市",
                                "county": "集美区",

                            },
                            {
                                "cityCode": "350212",
                                "cityName": "同安区",
                                "parentId": "350200",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "厦门市",
                                "county": "同安区",

                            },
                            {
                                "cityCode": "350213",
                                "cityName": "翔安区",
                                "parentId": "350200",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "厦门市",
                                "county": "翔安区",

                            }
                        ]
                    },
                    {
                        "cityCode": "350300",
                        "cityName": "莆田市",
                        "parentId": "350000",
                        "provinceType": "2",
                        "province": "福建省",
                        "city": "莆田市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "350301",
                                "cityName": "莆田市",
                                "parentId": "350300",
                                "provinceType": "2",
                                "province": "福建省",
                                "city": "莆田市",
                                "county": "",

                            },
                            {
                                "cityCode": "350302",
                                "cityName": "城厢区",
                                "parentId": "350300",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "莆田市",
                                "county": "城厢区",

                            },
                            {
                                "cityCode": "350303",
                                "cityName": "涵江区",
                                "parentId": "350300",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "莆田市",
                                "county": "涵江区",

                            },
                            {
                                "cityCode": "350304",
                                "cityName": "荔城区",
                                "parentId": "350300",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "莆田市",
                                "county": "荔城区",

                            },
                            {
                                "cityCode": "350305",
                                "cityName": "秀屿区",
                                "parentId": "350300",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "莆田市",
                                "county": "秀屿区",

                            },
                            {
                                "cityCode": "350322",
                                "cityName": "仙游县",
                                "parentId": "350300",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "莆田市",
                                "county": "仙游县",

                            }
                        ]
                    },
                    {
                        "cityCode": "350400",
                        "cityName": "三明市",
                        "parentId": "350000",
                        "provinceType": "2",
                        "province": "福建省",
                        "city": "三明市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "350401",
                                "cityName": "三明市",
                                "parentId": "350400",
                                "provinceType": "2",
                                "province": "福建省",
                                "city": "三明市",
                                "county": "",

                            },
                            {
                                "cityCode": "350402",
                                "cityName": "梅列区",
                                "parentId": "350400",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "三明市",
                                "county": "梅列区",

                            },
                            {
                                "cityCode": "350404",
                                "cityName": "三元区",
                                "parentId": "350400",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "三明市",
                                "county": "三元区",

                            },
                            {
                                "cityCode": "350405",
                                "cityName": "沙县区",
                                "parentId": "350400",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "三明市",
                                "county": "沙县区",

                            },
                            {
                                "cityCode": "350421",
                                "cityName": "明溪县",
                                "parentId": "350400",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "三明市",
                                "county": "明溪县",

                            },
                            {
                                "cityCode": "350423",
                                "cityName": "清流县",
                                "parentId": "350400",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "三明市",
                                "county": "清流县",

                            },
                            {
                                "cityCode": "350424",
                                "cityName": "宁化县",
                                "parentId": "350400",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "三明市",
                                "county": "宁化县",

                            },
                            {
                                "cityCode": "350425",
                                "cityName": "大田县",
                                "parentId": "350400",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "三明市",
                                "county": "大田县",

                            },
                            {
                                "cityCode": "350426",
                                "cityName": "尤溪县",
                                "parentId": "350400",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "三明市",
                                "county": "尤溪县",

                            },
                            {
                                "cityCode": "350428",
                                "cityName": "将乐县",
                                "parentId": "350400",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "三明市",
                                "county": "将乐县",

                            },
                            {
                                "cityCode": "350429",
                                "cityName": "泰宁县",
                                "parentId": "350400",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "三明市",
                                "county": "泰宁县",

                            },
                            {
                                "cityCode": "350430",
                                "cityName": "建宁县",
                                "parentId": "350400",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "三明市",
                                "county": "建宁县",

                            },
                            {
                                "cityCode": "350481",
                                "cityName": "永安市",
                                "parentId": "350400",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "三明市",
                                "county": "永安市",

                            }
                        ]
                    },
                    {
                        "cityCode": "350500",
                        "cityName": "泉州市",
                        "parentId": "350000",
                        "provinceType": "2",
                        "province": "福建省",
                        "city": "泉州市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "350501",
                                "cityName": "泉州市",
                                "parentId": "350500",
                                "provinceType": "2",
                                "province": "福建省",
                                "city": "泉州市",
                                "county": "",

                            },
                            {
                                "cityCode": "350502",
                                "cityName": "鲤城区",
                                "parentId": "350500",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "泉州市",
                                "county": "鲤城区",

                            },
                            {
                                "cityCode": "350503",
                                "cityName": "丰泽区",
                                "parentId": "350500",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "泉州市",
                                "county": "丰泽区",

                            },
                            {
                                "cityCode": "350504",
                                "cityName": "洛江区",
                                "parentId": "350500",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "泉州市",
                                "county": "洛江区",

                            },
                            {
                                "cityCode": "350505",
                                "cityName": "泉港区",
                                "parentId": "350500",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "泉州市",
                                "county": "泉港区",

                            },
                            {
                                "cityCode": "350521",
                                "cityName": "惠安县",
                                "parentId": "350500",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "泉州市",
                                "county": "惠安县",

                            },
                            {
                                "cityCode": "350524",
                                "cityName": "安溪县",
                                "parentId": "350500",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "泉州市",
                                "county": "安溪县",

                            },
                            {
                                "cityCode": "350525",
                                "cityName": "永春县",
                                "parentId": "350500",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "泉州市",
                                "county": "永春县",

                            },
                            {
                                "cityCode": "350526",
                                "cityName": "德化县",
                                "parentId": "350500",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "泉州市",
                                "county": "德化县",

                            },
                            {
                                "cityCode": "350527",
                                "cityName": "金门县",
                                "parentId": "350500",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "泉州市",
                                "county": "金门县",

                            },
                            {
                                "cityCode": "350581",
                                "cityName": "石狮市",
                                "parentId": "350500",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "泉州市",
                                "county": "石狮市",

                            },
                            {
                                "cityCode": "350582",
                                "cityName": "晋江市",
                                "parentId": "350500",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "泉州市",
                                "county": "晋江市",

                            },
                            {
                                "cityCode": "350583",
                                "cityName": "南安市",
                                "parentId": "350500",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "泉州市",
                                "county": "南安市",

                            },
                            {
                                "cityCode": "350584",
                                "cityName": "台商投资区",
                                "parentId": "350500",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "泉州市",
                                "county": "台商投资区",

                            },
                            {
                                "cityCode": "350585",
                                "cityName": "经济技术开发区",
                                "parentId": "350500",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "泉州市",
                                "county": "经济技术开发区",

                            },
                            {
                                "cityCode": "350586",
                                "cityName": "高新技术开发区",
                                "parentId": "350500",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "泉州市",
                                "county": "高新技术开发区",

                            },
                            {
                                "cityCode": "350587",
                                "cityName": "综合保税区",
                                "parentId": "350500",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "泉州市",
                                "county": "综合保税区",

                            }
                        ]
                    },
                    {
                        "cityCode": "350600",
                        "cityName": "漳州市",
                        "parentId": "350000",
                        "provinceType": "2",
                        "province": "福建省",
                        "city": "漳州市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "350601",
                                "cityName": "漳州市",
                                "parentId": "350600",
                                "provinceType": "2",
                                "province": "福建省",
                                "city": "漳州市",
                                "county": "",

                            },
                            {
                                "cityCode": "350602",
                                "cityName": "芗城区",
                                "parentId": "350600",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "漳州市",
                                "county": "芗城区",

                            },
                            {
                                "cityCode": "350603",
                                "cityName": "龙文区",
                                "parentId": "350600",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "漳州市",
                                "county": "龙文区",

                            },
                            {
                                "cityCode": "350604",
                                "cityName": "龙海区",
                                "parentId": "350600",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "漳州市",
                                "county": "龙海区",

                            },
                            {
                                "cityCode": "350605",
                                "cityName": "长泰区",
                                "parentId": "350600",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "漳州市",
                                "county": "长泰区",

                            },
                            {
                                "cityCode": "350622",
                                "cityName": "云霄县",
                                "parentId": "350600",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "漳州市",
                                "county": "云霄县",

                            },
                            {
                                "cityCode": "350623",
                                "cityName": "漳浦县",
                                "parentId": "350600",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "漳州市",
                                "county": "漳浦县",

                            },
                            {
                                "cityCode": "350624",
                                "cityName": "诏安县",
                                "parentId": "350600",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "漳州市",
                                "county": "诏安县",

                            },
                            {
                                "cityCode": "350626",
                                "cityName": "东山县",
                                "parentId": "350600",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "漳州市",
                                "county": "东山县",

                            },
                            {
                                "cityCode": "350627",
                                "cityName": "南靖县",
                                "parentId": "350600",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "漳州市",
                                "county": "南靖县",

                            },
                            {
                                "cityCode": "350628",
                                "cityName": "平和县",
                                "parentId": "350600",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "漳州市",
                                "county": "平和县",

                            },
                            {
                                "cityCode": "350629",
                                "cityName": "华安县",
                                "parentId": "350600",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "漳州市",
                                "county": "华安县",

                            }
                        ]
                    },
                    {
                        "cityCode": "350700",
                        "cityName": "南平市",
                        "parentId": "350000",
                        "provinceType": "2",
                        "province": "福建省",
                        "city": "南平市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "350701",
                                "cityName": "南平市",
                                "parentId": "350700",
                                "provinceType": "2",
                                "province": "福建省",
                                "city": "南平市",
                                "county": "",

                            },
                            {
                                "cityCode": "350702",
                                "cityName": "延平区",
                                "parentId": "350700",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "南平市",
                                "county": "延平区",

                            },
                            {
                                "cityCode": "350703",
                                "cityName": "建阳区",
                                "parentId": "350700",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "南平市",
                                "county": "建阳区",

                            },
                            {
                                "cityCode": "350721",
                                "cityName": "顺昌县",
                                "parentId": "350700",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "南平市",
                                "county": "顺昌县",

                            },
                            {
                                "cityCode": "350722",
                                "cityName": "浦城县",
                                "parentId": "350700",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "南平市",
                                "county": "浦城县",

                            },
                            {
                                "cityCode": "350723",
                                "cityName": "光泽县",
                                "parentId": "350700",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "南平市",
                                "county": "光泽县",

                            },
                            {
                                "cityCode": "350724",
                                "cityName": "松溪县",
                                "parentId": "350700",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "南平市",
                                "county": "松溪县",

                            },
                            {
                                "cityCode": "350725",
                                "cityName": "政和县",
                                "parentId": "350700",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "南平市",
                                "county": "政和县",

                            },
                            {
                                "cityCode": "350781",
                                "cityName": "邵武市",
                                "parentId": "350700",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "南平市",
                                "county": "邵武市",

                            },
                            {
                                "cityCode": "350782",
                                "cityName": "武夷山市",
                                "parentId": "350700",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "南平市",
                                "county": "武夷山市",

                            },
                            {
                                "cityCode": "350783",
                                "cityName": "建瓯市",
                                "parentId": "350700",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "南平市",
                                "county": "建瓯市",

                            },
                            {
                                "cityCode": "350784",
                                "cityName": "建阳市",
                                "parentId": "350700",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "南平市",
                                "county": "建阳市",

                            }
                        ]
                    },
                    {
                        "cityCode": "350800",
                        "cityName": "龙岩市",
                        "parentId": "350000",
                        "provinceType": "2",
                        "province": "福建省",
                        "city": "龙岩市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "350801",
                                "cityName": "龙岩市",
                                "parentId": "350800",
                                "provinceType": "2",
                                "province": "福建省",
                                "city": "龙岩市",
                                "county": "",

                            },
                            {
                                "cityCode": "350802",
                                "cityName": "新罗区",
                                "parentId": "350800",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "龙岩市",
                                "county": "新罗区",

                            },
                            {
                                "cityCode": "350803",
                                "cityName": "永定区",
                                "parentId": "350800",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "龙岩市",
                                "county": "永定区",

                            },
                            {
                                "cityCode": "350821",
                                "cityName": "长汀县",
                                "parentId": "350800",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "龙岩市",
                                "county": "长汀县",

                            },
                            {
                                "cityCode": "350822",
                                "cityName": "永定区",
                                "parentId": "350800",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "龙岩市",
                                "county": "永定区",

                            },
                            {
                                "cityCode": "350823",
                                "cityName": "上杭县",
                                "parentId": "350800",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "龙岩市",
                                "county": "上杭县",

                            },
                            {
                                "cityCode": "350824",
                                "cityName": "武平县",
                                "parentId": "350800",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "龙岩市",
                                "county": "武平县",

                            },
                            {
                                "cityCode": "350825",
                                "cityName": "连城县",
                                "parentId": "350800",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "龙岩市",
                                "county": "连城县",

                            },
                            {
                                "cityCode": "350881",
                                "cityName": "漳平市",
                                "parentId": "350800",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "龙岩市",
                                "county": "漳平市",

                            }
                        ]
                    },
                    {
                        "cityCode": "350900",
                        "cityName": "宁德市",
                        "parentId": "350000",
                        "provinceType": "2",
                        "province": "福建省",
                        "city": "宁德市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "350901",
                                "cityName": "宁德市",
                                "parentId": "350900",
                                "provinceType": "2",
                                "province": "福建省",
                                "city": "宁德市",
                                "county": "",

                            },
                            {
                                "cityCode": "350902",
                                "cityName": "蕉城区",
                                "parentId": "350900",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "宁德市",
                                "county": "蕉城区",

                            },
                            {
                                "cityCode": "350921",
                                "cityName": "霞浦县",
                                "parentId": "350900",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "宁德市",
                                "county": "霞浦县",

                            },
                            {
                                "cityCode": "350922",
                                "cityName": "古田县",
                                "parentId": "350900",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "宁德市",
                                "county": "古田县",

                            },
                            {
                                "cityCode": "350923",
                                "cityName": "屏南县",
                                "parentId": "350900",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "宁德市",
                                "county": "屏南县",

                            },
                            {
                                "cityCode": "350924",
                                "cityName": "寿宁县",
                                "parentId": "350900",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "宁德市",
                                "county": "寿宁县",

                            },
                            {
                                "cityCode": "350925",
                                "cityName": "周宁县",
                                "parentId": "350900",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "宁德市",
                                "county": "周宁县",

                            },
                            {
                                "cityCode": "350926",
                                "cityName": "柘荣县",
                                "parentId": "350900",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "宁德市",
                                "county": "柘荣县",

                            },
                            {
                                "cityCode": "350981",
                                "cityName": "福安市",
                                "parentId": "350900",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "宁德市",
                                "county": "福安市",

                            },
                            {
                                "cityCode": "350982",
                                "cityName": "福鼎市",
                                "parentId": "350900",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "宁德市",
                                "county": "福鼎市",

                            },
                            {
                                "cityCode": "350983",
                                "cityName": "东侨开发区",
                                "parentId": "350900",
                                "provinceType": "3",
                                "province": "福建省",
                                "city": "宁德市",
                                "county": "东侨开发区",

                            }
                        ]
                    }
                ]
            },
            {
                "cityCode": "360000",
                "cityName": "江西省",
                "parentId": "100000",
                "provinceType": "1",
                "province": "江西省",
                "city": "",
                "county": "",
                "counties": [
                    {
                        "cityCode": "360100",
                        "cityName": "南昌市",
                        "parentId": "360000",
                        "provinceType": "2",
                        "province": "江西省",
                        "city": "南昌市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "360101",
                                "cityName": "南昌市",
                                "parentId": "360100",
                                "provinceType": "2",
                                "province": "江西省",
                                "city": "南昌市",
                                "county": "",

                            },
                            {
                                "cityCode": "360102",
                                "cityName": "东湖区",
                                "parentId": "360100",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "南昌市",
                                "county": "东湖区",

                            },
                            {
                                "cityCode": "360103",
                                "cityName": "西湖区",
                                "parentId": "360100",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "南昌市",
                                "county": "西湖区",

                            },
                            {
                                "cityCode": "360104",
                                "cityName": "青云谱区",
                                "parentId": "360100",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "南昌市",
                                "county": "青云谱区",

                            },
                            {
                                "cityCode": "360105",
                                "cityName": "湾里区",
                                "parentId": "360100",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "南昌市",
                                "county": "湾里区",

                            },
                            {
                                "cityCode": "360111",
                                "cityName": "青山湖区",
                                "parentId": "360100",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "南昌市",
                                "county": "青山湖区",

                            },
                            {
                                "cityCode": "360112",
                                "cityName": "新建区",
                                "parentId": "360100",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "南昌市",
                                "county": "新建区",

                            },
                            {
                                "cityCode": "360113",
                                "cityName": "红谷滩区",
                                "parentId": "360100",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "南昌市",
                                "county": "红谷滩区",

                            },
                            {
                                "cityCode": "360121",
                                "cityName": "南昌县",
                                "parentId": "360100",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "南昌市",
                                "county": "南昌县",

                            },
                            {
                                "cityCode": "360122",
                                "cityName": "新建县",
                                "parentId": "360100",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "南昌市",
                                "county": "新建县",

                            },
                            {
                                "cityCode": "360123",
                                "cityName": "安义县",
                                "parentId": "360100",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "南昌市",
                                "county": "安义县",

                            },
                            {
                                "cityCode": "360124",
                                "cityName": "进贤县",
                                "parentId": "360100",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "南昌市",
                                "county": "进贤县",

                            },
                            {
                                "cityCode": "360125",
                                "cityName": "红谷滩新区",
                                "parentId": "360100",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "南昌市",
                                "county": "红谷滩新区",

                            },
                            {
                                "cityCode": "360126",
                                "cityName": "高新区",
                                "parentId": "360100",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "南昌市",
                                "county": "高新区",

                            },
                            {
                                "cityCode": "360127",
                                "cityName": "经济开发区",
                                "parentId": "360100",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "南昌市",
                                "county": "经济开发区",

                            },
                            {
                                "cityCode": "360128",
                                "cityName": "小蓝开发区",
                                "parentId": "360100",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "南昌市",
                                "county": "小蓝开发区",

                            },
                            {
                                "cityCode": "360129",
                                "cityName": "桑海开发区",
                                "parentId": "360100",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "南昌市",
                                "county": "桑海开发区",

                            },
                            {
                                "cityCode": "360130",
                                "cityName": "望城新区",
                                "parentId": "360100",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "南昌市",
                                "county": "望城新区",

                            },
                            {
                                "cityCode": "360131",
                                "cityName": "赣江新区",
                                "parentId": "360100",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "南昌市",
                                "county": "赣江新区",

                            },
                            {
                                "cityCode": "360190",
                                "cityName": "南昌市直属分局",
                                "parentId": "360100",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "南昌市",
                                "county": "南昌市直属分局",

                            },
                            {
                                "cityCode": "360191",
                                "cityName": "南昌市水上分局",
                                "parentId": "360100",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "南昌市",
                                "county": "南昌市水上分局",

                            },
                            {
                                "cityCode": "360192",
                                "cityName": "红谷滩",
                                "parentId": "360100",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "南昌市",
                                "county": "红谷滩",

                            },
                            {
                                "cityCode": "360193",
                                "cityName": "南昌市桑海分局",
                                "parentId": "360100",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "南昌市",
                                "county": "南昌市桑海分局",

                            },
                            {
                                "cityCode": "360195",
                                "cityName": "南昌市江铃分局",
                                "parentId": "360100",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "南昌市",
                                "county": "南昌市江铃分局",

                            },
                            {
                                "cityCode": "360196",
                                "cityName": "南昌市齐城岗分局",
                                "parentId": "360100",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "南昌市",
                                "county": "南昌市齐城岗分局",

                            },
                            {
                                "cityCode": "360197",
                                "cityName": "南昌市洪都分局",
                                "parentId": "360100",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "南昌市",
                                "county": "南昌市洪都分局",

                            },
                            {
                                "cityCode": "360198",
                                "cityName": "江西省高新分局",
                                "parentId": "360100",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "南昌市",
                                "county": "江西省高新分局",

                            },
                            {
                                "cityCode": "360199",
                                "cityName": "南昌市经开分局",
                                "parentId": "360100",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "南昌市",
                                "county": "南昌市经开分局",

                            }
                        ]
                    },
                    {
                        "cityCode": "360200",
                        "cityName": "景德镇市",
                        "parentId": "360000",
                        "provinceType": "2",
                        "province": "江西省",
                        "city": "景德镇市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "360201",
                                "cityName": "景德镇市",
                                "parentId": "360200",
                                "provinceType": "2",
                                "province": "江西省",
                                "city": "景德镇市",
                                "county": "",

                            },
                            {
                                "cityCode": "360202",
                                "cityName": "昌江区",
                                "parentId": "360200",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "景德镇市",
                                "county": "昌江区",

                            },
                            {
                                "cityCode": "360203",
                                "cityName": "珠山区",
                                "parentId": "360200",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "景德镇市",
                                "county": "珠山区",

                            },
                            {
                                "cityCode": "360222",
                                "cityName": "浮梁县",
                                "parentId": "360200",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "景德镇市",
                                "county": "浮梁县",

                            },
                            {
                                "cityCode": "360281",
                                "cityName": "乐平市",
                                "parentId": "360200",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "景德镇市",
                                "county": "乐平市",

                            }
                        ]
                    },
                    {
                        "cityCode": "360300",
                        "cityName": "萍乡市",
                        "parentId": "360000",
                        "provinceType": "2",
                        "province": "江西省",
                        "city": "萍乡市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "360301",
                                "cityName": "萍乡市",
                                "parentId": "360300",
                                "provinceType": "2",
                                "province": "江西省",
                                "city": "萍乡市",
                                "county": "",

                            },
                            {
                                "cityCode": "360302",
                                "cityName": "安源区",
                                "parentId": "360300",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "萍乡市",
                                "county": "安源区",

                            },
                            {
                                "cityCode": "360313",
                                "cityName": "湘东区",
                                "parentId": "360300",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "萍乡市",
                                "county": "湘东区",

                            },
                            {
                                "cityCode": "360321",
                                "cityName": "莲花县",
                                "parentId": "360300",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "萍乡市",
                                "county": "莲花县",

                            },
                            {
                                "cityCode": "360322",
                                "cityName": "上栗县",
                                "parentId": "360300",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "萍乡市",
                                "county": "上栗县",

                            },
                            {
                                "cityCode": "360323",
                                "cityName": "芦溪县",
                                "parentId": "360300",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "萍乡市",
                                "county": "芦溪县",

                            }
                        ]
                    },
                    {
                        "cityCode": "360400",
                        "cityName": "九江市",
                        "parentId": "360000",
                        "provinceType": "2",
                        "province": "江西省",
                        "city": "九江市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "360401",
                                "cityName": "九江市",
                                "parentId": "360400",
                                "provinceType": "2",
                                "province": "江西省",
                                "city": "九江市",
                                "county": "",

                            },
                            {
                                "cityCode": "360402",
                                "cityName": "濂溪区",
                                "parentId": "360400",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "九江市",
                                "county": "濂溪区",

                            },
                            {
                                "cityCode": "360403",
                                "cityName": "浔阳区",
                                "parentId": "360400",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "九江市",
                                "county": "浔阳区",

                            },
                            {
                                "cityCode": "360404",
                                "cityName": "柴桑区",
                                "parentId": "360400",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "九江市",
                                "county": "柴桑区",

                            },
                            {
                                "cityCode": "360421",
                                "cityName": "九江县",
                                "parentId": "360400",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "九江市",
                                "county": "九江县",

                            },
                            {
                                "cityCode": "360423",
                                "cityName": "武宁县",
                                "parentId": "360400",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "九江市",
                                "county": "武宁县",

                            },
                            {
                                "cityCode": "360424",
                                "cityName": "修水县",
                                "parentId": "360400",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "九江市",
                                "county": "修水县",

                            },
                            {
                                "cityCode": "360425",
                                "cityName": "永修县",
                                "parentId": "360400",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "九江市",
                                "county": "永修县",

                            },
                            {
                                "cityCode": "360426",
                                "cityName": "德安县",
                                "parentId": "360400",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "九江市",
                                "county": "德安县",

                            },
                            {
                                "cityCode": "360427",
                                "cityName": "星子县",
                                "parentId": "360400",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "九江市",
                                "county": "星子县",

                            },
                            {
                                "cityCode": "360428",
                                "cityName": "都昌县",
                                "parentId": "360400",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "九江市",
                                "county": "都昌县",

                            },
                            {
                                "cityCode": "360429",
                                "cityName": "湖口县",
                                "parentId": "360400",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "九江市",
                                "county": "湖口县",

                            },
                            {
                                "cityCode": "360430",
                                "cityName": "彭泽县",
                                "parentId": "360400",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "九江市",
                                "county": "彭泽县",

                            },
                            {
                                "cityCode": "360481",
                                "cityName": "瑞昌市",
                                "parentId": "360400",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "九江市",
                                "county": "瑞昌市",

                            },
                            {
                                "cityCode": "360482",
                                "cityName": "共青城市",
                                "parentId": "360400",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "九江市",
                                "county": "共青城市",

                            },
                            {
                                "cityCode": "360483",
                                "cityName": "庐山市",
                                "parentId": "360400",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "九江市",
                                "county": "庐山市",

                            },
                            {
                                "cityCode": "360484",
                                "cityName": "经济技术开发区",
                                "parentId": "360400",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "九江市",
                                "county": "经济技术开发区",

                            },
                            {
                                "cityCode": "360485",
                                "cityName": "八里湖新区",
                                "parentId": "360400",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "九江市",
                                "county": "八里湖新区",

                            },
                            {
                                "cityCode": "360486",
                                "cityName": "庐山风景名胜区",
                                "parentId": "360400",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "九江市",
                                "county": "庐山风景名胜区",

                            },
                            {
                                "cityCode": "360499",
                                "cityName": "共青城",
                                "parentId": "360400",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "九江市",
                                "county": "共青城",

                            }
                        ]
                    },
                    {
                        "cityCode": "360500",
                        "cityName": "新余市",
                        "parentId": "360000",
                        "provinceType": "2",
                        "province": "江西省",
                        "city": "新余市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "360501",
                                "cityName": "新余市",
                                "parentId": "360500",
                                "provinceType": "2",
                                "province": "江西省",
                                "city": "新余市",
                                "county": "",

                            },
                            {
                                "cityCode": "360502",
                                "cityName": "渝水区",
                                "parentId": "360500",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "新余市",
                                "county": "渝水区",

                            },
                            {
                                "cityCode": "360521",
                                "cityName": "分宜县",
                                "parentId": "360500",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "新余市",
                                "county": "分宜县",

                            }
                        ]
                    },
                    {
                        "cityCode": "360600",
                        "cityName": "鹰潭市",
                        "parentId": "360000",
                        "provinceType": "2",
                        "province": "江西省",
                        "city": "鹰潭市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "360601",
                                "cityName": "鹰潭市",
                                "parentId": "360600",
                                "provinceType": "2",
                                "province": "江西省",
                                "city": "鹰潭市",
                                "county": "",

                            },
                            {
                                "cityCode": "360602",
                                "cityName": "月湖区",
                                "parentId": "360600",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "鹰潭市",
                                "county": "月湖区",

                            },
                            {
                                "cityCode": "360603",
                                "cityName": "余江区",
                                "parentId": "360600",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "鹰潭市",
                                "county": "余江区",

                            },
                            {
                                "cityCode": "360622",
                                "cityName": "余江县",
                                "parentId": "360600",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "鹰潭市",
                                "county": "余江县",

                            },
                            {
                                "cityCode": "360681",
                                "cityName": "贵溪市",
                                "parentId": "360600",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "鹰潭市",
                                "county": "贵溪市",

                            }
                        ]
                    },
                    {
                        "cityCode": "360700",
                        "cityName": "赣州市",
                        "parentId": "360000",
                        "provinceType": "2",
                        "province": "江西省",
                        "city": "赣州市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "360701",
                                "cityName": "赣州市",
                                "parentId": "360700",
                                "provinceType": "2",
                                "province": "江西省",
                                "city": "赣州市",
                                "county": "",

                            },
                            {
                                "cityCode": "360702",
                                "cityName": "章贡区",
                                "parentId": "360700",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "赣州市",
                                "county": "章贡区",

                            },
                            {
                                "cityCode": "360703",
                                "cityName": "南康区",
                                "parentId": "360700",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "赣州市",
                                "county": "南康区",

                            },
                            {
                                "cityCode": "360704",
                                "cityName": "赣县区",
                                "parentId": "360700",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "赣州市",
                                "county": "赣县区",

                            },
                            {
                                "cityCode": "360721",
                                "cityName": "赣县区",
                                "parentId": "360700",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "赣州市",
                                "county": "赣县区",

                            },
                            {
                                "cityCode": "360722",
                                "cityName": "信丰县",
                                "parentId": "360700",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "赣州市",
                                "county": "信丰县",

                            },
                            {
                                "cityCode": "360723",
                                "cityName": "大余县",
                                "parentId": "360700",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "赣州市",
                                "county": "大余县",

                            },
                            {
                                "cityCode": "360724",
                                "cityName": "上犹县",
                                "parentId": "360700",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "赣州市",
                                "county": "上犹县",

                            },
                            {
                                "cityCode": "360725",
                                "cityName": "崇义县",
                                "parentId": "360700",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "赣州市",
                                "county": "崇义县",

                            },
                            {
                                "cityCode": "360726",
                                "cityName": "安远县",
                                "parentId": "360700",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "赣州市",
                                "county": "安远县",

                            },
                            {
                                "cityCode": "360727",
                                "cityName": "龙南县",
                                "parentId": "360700",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "赣州市",
                                "county": "龙南县",

                            },
                            {
                                "cityCode": "360728",
                                "cityName": "定南县",
                                "parentId": "360700",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "赣州市",
                                "county": "定南县",

                            },
                            {
                                "cityCode": "360729",
                                "cityName": "全南县",
                                "parentId": "360700",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "赣州市",
                                "county": "全南县",

                            },
                            {
                                "cityCode": "360730",
                                "cityName": "宁都县",
                                "parentId": "360700",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "赣州市",
                                "county": "宁都县",

                            },
                            {
                                "cityCode": "360731",
                                "cityName": "于都县",
                                "parentId": "360700",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "赣州市",
                                "county": "于都县",

                            },
                            {
                                "cityCode": "360732",
                                "cityName": "兴国县",
                                "parentId": "360700",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "赣州市",
                                "county": "兴国县",

                            },
                            {
                                "cityCode": "360733",
                                "cityName": "会昌县",
                                "parentId": "360700",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "赣州市",
                                "county": "会昌县",

                            },
                            {
                                "cityCode": "360734",
                                "cityName": "寻乌县",
                                "parentId": "360700",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "赣州市",
                                "county": "寻乌县",

                            },
                            {
                                "cityCode": "360735",
                                "cityName": "石城县",
                                "parentId": "360700",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "赣州市",
                                "county": "石城县",

                            },
                            {
                                "cityCode": "360781",
                                "cityName": "瑞金市",
                                "parentId": "360700",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "赣州市",
                                "county": "瑞金市",

                            },
                            {
                                "cityCode": "360782",
                                "cityName": "章康新区",
                                "parentId": "360700",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "赣州市",
                                "county": "章康新区",

                            },
                            {
                                "cityCode": "360783",
                                "cityName": "龙南市",
                                "parentId": "360700",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "赣州市",
                                "county": "龙南市",

                            }
                        ]
                    },
                    {
                        "cityCode": "360800",
                        "cityName": "吉安市",
                        "parentId": "360000",
                        "provinceType": "2",
                        "province": "江西省",
                        "city": "吉安市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "360801",
                                "cityName": "吉安市",
                                "parentId": "360800",
                                "provinceType": "2",
                                "province": "江西省",
                                "city": "吉安市",
                                "county": "",

                            },
                            {
                                "cityCode": "360802",
                                "cityName": "吉州区",
                                "parentId": "360800",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "吉安市",
                                "county": "吉州区",

                            },
                            {
                                "cityCode": "360803",
                                "cityName": "青原区",
                                "parentId": "360800",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "吉安市",
                                "county": "青原区",

                            },
                            {
                                "cityCode": "360821",
                                "cityName": "吉安县",
                                "parentId": "360800",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "吉安市",
                                "county": "吉安县",

                            },
                            {
                                "cityCode": "360822",
                                "cityName": "吉水县",
                                "parentId": "360800",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "吉安市",
                                "county": "吉水县",

                            },
                            {
                                "cityCode": "360823",
                                "cityName": "峡江县",
                                "parentId": "360800",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "吉安市",
                                "county": "峡江县",

                            },
                            {
                                "cityCode": "360824",
                                "cityName": "新干县",
                                "parentId": "360800",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "吉安市",
                                "county": "新干县",

                            },
                            {
                                "cityCode": "360825",
                                "cityName": "永丰县",
                                "parentId": "360800",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "吉安市",
                                "county": "永丰县",

                            },
                            {
                                "cityCode": "360826",
                                "cityName": "泰和县",
                                "parentId": "360800",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "吉安市",
                                "county": "泰和县",

                            },
                            {
                                "cityCode": "360827",
                                "cityName": "遂川县",
                                "parentId": "360800",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "吉安市",
                                "county": "遂川县",

                            },
                            {
                                "cityCode": "360828",
                                "cityName": "万安县",
                                "parentId": "360800",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "吉安市",
                                "county": "万安县",

                            },
                            {
                                "cityCode": "360829",
                                "cityName": "安福县",
                                "parentId": "360800",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "吉安市",
                                "county": "安福县",

                            },
                            {
                                "cityCode": "360830",
                                "cityName": "永新县",
                                "parentId": "360800",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "吉安市",
                                "county": "永新县",

                            },
                            {
                                "cityCode": "360881",
                                "cityName": "井冈山市",
                                "parentId": "360800",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "吉安市",
                                "county": "井冈山市",

                            }
                        ]
                    },
                    {
                        "cityCode": "360900",
                        "cityName": "宜春市",
                        "parentId": "360000",
                        "provinceType": "2",
                        "province": "江西省",
                        "city": "宜春市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "360901",
                                "cityName": "宜春市",
                                "parentId": "360900",
                                "provinceType": "2",
                                "province": "江西省",
                                "city": "宜春市",
                                "county": "",

                            },
                            {
                                "cityCode": "360902",
                                "cityName": "袁州区",
                                "parentId": "360900",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "宜春市",
                                "county": "袁州区",

                            },
                            {
                                "cityCode": "360921",
                                "cityName": "奉新县",
                                "parentId": "360900",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "宜春市",
                                "county": "奉新县",

                            },
                            {
                                "cityCode": "360922",
                                "cityName": "万载县",
                                "parentId": "360900",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "宜春市",
                                "county": "万载县",

                            },
                            {
                                "cityCode": "360923",
                                "cityName": "上高县",
                                "parentId": "360900",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "宜春市",
                                "county": "上高县",

                            },
                            {
                                "cityCode": "360924",
                                "cityName": "宜丰县",
                                "parentId": "360900",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "宜春市",
                                "county": "宜丰县",

                            },
                            {
                                "cityCode": "360925",
                                "cityName": "靖安县",
                                "parentId": "360900",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "宜春市",
                                "county": "靖安县",

                            },
                            {
                                "cityCode": "360926",
                                "cityName": "铜鼓县",
                                "parentId": "360900",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "宜春市",
                                "county": "铜鼓县",

                            },
                            {
                                "cityCode": "360981",
                                "cityName": "丰城市",
                                "parentId": "360900",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "宜春市",
                                "county": "丰城市",

                            },
                            {
                                "cityCode": "360982",
                                "cityName": "樟树市",
                                "parentId": "360900",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "宜春市",
                                "county": "樟树市",

                            },
                            {
                                "cityCode": "360983",
                                "cityName": "高安市",
                                "parentId": "360900",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "宜春市",
                                "county": "高安市",

                            }
                        ]
                    },
                    {
                        "cityCode": "361000",
                        "cityName": "抚州市",
                        "parentId": "360000",
                        "provinceType": "2",
                        "province": "江西省",
                        "city": "抚州市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "361001",
                                "cityName": "抚州市",
                                "parentId": "361000",
                                "provinceType": "2",
                                "province": "江西省",
                                "city": "抚州市",
                                "county": "",

                            },
                            {
                                "cityCode": "361002",
                                "cityName": "临川区",
                                "parentId": "361000",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "抚州市",
                                "county": "临川区",

                            },
                            {
                                "cityCode": "361003",
                                "cityName": "东乡区",
                                "parentId": "361000",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "抚州市",
                                "county": "东乡区",

                            },
                            {
                                "cityCode": "361021",
                                "cityName": "南城县",
                                "parentId": "361000",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "抚州市",
                                "county": "南城县",

                            },
                            {
                                "cityCode": "361022",
                                "cityName": "黎川县",
                                "parentId": "361000",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "抚州市",
                                "county": "黎川县",

                            },
                            {
                                "cityCode": "361023",
                                "cityName": "南丰县",
                                "parentId": "361000",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "抚州市",
                                "county": "南丰县",

                            },
                            {
                                "cityCode": "361024",
                                "cityName": "崇仁县",
                                "parentId": "361000",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "抚州市",
                                "county": "崇仁县",

                            },
                            {
                                "cityCode": "361025",
                                "cityName": "乐安县",
                                "parentId": "361000",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "抚州市",
                                "county": "乐安县",

                            },
                            {
                                "cityCode": "361026",
                                "cityName": "宜黄县",
                                "parentId": "361000",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "抚州市",
                                "county": "宜黄县",

                            },
                            {
                                "cityCode": "361027",
                                "cityName": "金溪县",
                                "parentId": "361000",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "抚州市",
                                "county": "金溪县",

                            },
                            {
                                "cityCode": "361028",
                                "cityName": "资溪县",
                                "parentId": "361000",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "抚州市",
                                "county": "资溪县",

                            },
                            {
                                "cityCode": "361029",
                                "cityName": "东乡县",
                                "parentId": "361000",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "抚州市",
                                "county": "东乡县",

                            },
                            {
                                "cityCode": "361030",
                                "cityName": "广昌县",
                                "parentId": "361000",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "抚州市",
                                "county": "广昌县",

                            }
                        ]
                    },
                    {
                        "cityCode": "361100",
                        "cityName": "上饶市",
                        "parentId": "360000",
                        "provinceType": "2",
                        "province": "江西省",
                        "city": "上饶市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "361101",
                                "cityName": "上饶市",
                                "parentId": "361100",
                                "provinceType": "2",
                                "province": "江西省",
                                "city": "上饶市",
                                "county": "",

                            },
                            {
                                "cityCode": "361102",
                                "cityName": "信州区",
                                "parentId": "361100",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "上饶市",
                                "county": "信州区",

                            },
                            {
                                "cityCode": "361103",
                                "cityName": "广丰区",
                                "parentId": "361100",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "上饶市",
                                "county": "广丰区",

                            },
                            {
                                "cityCode": "361104",
                                "cityName": "广信区",
                                "parentId": "361100",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "上饶市",
                                "county": "广信区",

                            },
                            {
                                "cityCode": "361121",
                                "cityName": "上饶县",
                                "parentId": "361100",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "上饶市",
                                "county": "上饶县",

                            },
                            {
                                "cityCode": "361122",
                                "cityName": "广丰区",
                                "parentId": "361100",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "上饶市",
                                "county": "广丰区",

                            },
                            {
                                "cityCode": "361123",
                                "cityName": "玉山县",
                                "parentId": "361100",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "上饶市",
                                "county": "玉山县",

                            },
                            {
                                "cityCode": "361124",
                                "cityName": "铅山县",
                                "parentId": "361100",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "上饶市",
                                "county": "铅山县",

                            },
                            {
                                "cityCode": "361125",
                                "cityName": "横峰县",
                                "parentId": "361100",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "上饶市",
                                "county": "横峰县",

                            },
                            {
                                "cityCode": "361126",
                                "cityName": "弋阳县",
                                "parentId": "361100",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "上饶市",
                                "county": "弋阳县",

                            },
                            {
                                "cityCode": "361127",
                                "cityName": "余干县",
                                "parentId": "361100",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "上饶市",
                                "county": "余干县",

                            },
                            {
                                "cityCode": "361128",
                                "cityName": "鄱阳县",
                                "parentId": "361100",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "上饶市",
                                "county": "鄱阳县",

                            },
                            {
                                "cityCode": "361129",
                                "cityName": "万年县",
                                "parentId": "361100",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "上饶市",
                                "county": "万年县",

                            },
                            {
                                "cityCode": "361130",
                                "cityName": "婺源县",
                                "parentId": "361100",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "上饶市",
                                "county": "婺源县",

                            },
                            {
                                "cityCode": "361181",
                                "cityName": "德兴市",
                                "parentId": "361100",
                                "provinceType": "3",
                                "province": "江西省",
                                "city": "上饶市",
                                "county": "德兴市",

                            }
                        ]
                    }
                ]
            },
            {
                "cityCode": "370000",
                "cityName": "山东省",
                "parentId": "100000",
                "provinceType": "1",
                "province": "山东省",
                "city": "",
                "county": "",
                "counties": [
                    {
                        "cityCode": "370100",
                        "cityName": "济南市",
                        "parentId": "370000",
                        "provinceType": "2",
                        "province": "山东省",
                        "city": "济南市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "370101",
                                "cityName": "济南市",
                                "parentId": "370100",
                                "provinceType": "2",
                                "province": "山东省",
                                "city": "济南市",
                                "county": "",

                            },
                            {
                                "cityCode": "370102",
                                "cityName": "历下区",
                                "parentId": "370100",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "济南市",
                                "county": "历下区",

                            },
                            {
                                "cityCode": "370103",
                                "cityName": "市中区",
                                "parentId": "370100",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "济南市",
                                "county": "市中区",

                            },
                            {
                                "cityCode": "370104",
                                "cityName": "槐荫区",
                                "parentId": "370100",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "济南市",
                                "county": "槐荫区",

                            },
                            {
                                "cityCode": "370105",
                                "cityName": "天桥区",
                                "parentId": "370100",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "济南市",
                                "county": "天桥区",

                            },
                            {
                                "cityCode": "370112",
                                "cityName": "历城区",
                                "parentId": "370100",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "济南市",
                                "county": "历城区",

                            },
                            {
                                "cityCode": "370113",
                                "cityName": "长清区",
                                "parentId": "370100",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "济南市",
                                "county": "长清区",

                            },
                            {
                                "cityCode": "370114",
                                "cityName": "章丘区",
                                "parentId": "370100",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "济南市",
                                "county": "章丘区",

                            },
                            {
                                "cityCode": "370115",
                                "cityName": "济阳区",
                                "parentId": "370100",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "济南市",
                                "county": "济阳区",

                            },
                            {
                                "cityCode": "370116",
                                "cityName": "莱芜区",
                                "parentId": "370100",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "济南市",
                                "county": "莱芜区",

                            },
                            {
                                "cityCode": "370117",
                                "cityName": "钢城区",
                                "parentId": "370100",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "莱芜市",
                                "county": "钢城区",

                            },
                            {
                                "cityCode": "370124",
                                "cityName": "平阴县",
                                "parentId": "370100",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "济南市",
                                "county": "平阴县",

                            },
                            {
                                "cityCode": "370125",
                                "cityName": "济阳县",
                                "parentId": "370100",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "济南市",
                                "county": "济阳县",

                            },
                            {
                                "cityCode": "370126",
                                "cityName": "商河县",
                                "parentId": "370100",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "济南市",
                                "county": "商河县",

                            },
                            {
                                "cityCode": "370171",
                                "cityName": "济南高新技术产业开发区",
                                "parentId": "370100",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "济南市",
                                "county": "济南高新技术产业开发区",

                            },
                            {
                                "cityCode": "370181",
                                "cityName": "章丘区",
                                "parentId": "370100",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "济南市",
                                "county": "章丘区",

                            },
                            {
                                "cityCode": "370182",
                                "cityName": "高新区",
                                "parentId": "370100",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "济南市",
                                "county": "高新区",

                            }
                        ]
                    },
                    {
                        "cityCode": "370200",
                        "cityName": "青岛市",
                        "parentId": "370000",
                        "provinceType": "2",
                        "province": "山东省",
                        "city": "青岛市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "370201",
                                "cityName": "青岛市",
                                "parentId": "370200",
                                "provinceType": "2",
                                "province": "山东省",
                                "city": "青岛市",
                                "county": "",

                            },
                            {
                                "cityCode": "370202",
                                "cityName": "市南区",
                                "parentId": "370200",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "青岛市",
                                "county": "市南区",

                            },
                            {
                                "cityCode": "370203",
                                "cityName": "市北区",
                                "parentId": "370200",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "青岛市",
                                "county": "市北区",

                            },
                            {
                                "cityCode": "370205",
                                "cityName": "四方区",
                                "parentId": "370200",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "青岛市",
                                "county": "四方区",

                            },
                            {
                                "cityCode": "370211",
                                "cityName": "黄岛区",
                                "parentId": "370200",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "青岛市",
                                "county": "黄岛区",

                            },
                            {
                                "cityCode": "370212",
                                "cityName": "崂山区",
                                "parentId": "370200",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "青岛市",
                                "county": "崂山区",

                            },
                            {
                                "cityCode": "370213",
                                "cityName": "李沧区",
                                "parentId": "370200",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "青岛市",
                                "county": "李沧区",

                            },
                            {
                                "cityCode": "370214",
                                "cityName": "城阳区",
                                "parentId": "370200",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "青岛市",
                                "county": "城阳区",

                            },
                            {
                                "cityCode": "370215",
                                "cityName": "即墨区",
                                "parentId": "370200",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "青岛市",
                                "county": "即墨区",

                            },
                            {
                                "cityCode": "370271",
                                "cityName": "青岛高新技术产业开发区",
                                "parentId": "370200",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "青岛市",
                                "county": "青岛高新技术产业开发区",

                            },
                            {
                                "cityCode": "370281",
                                "cityName": "胶州市",
                                "parentId": "370200",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "青岛市",
                                "county": "胶州市",

                            },
                            {
                                "cityCode": "370282",
                                "cityName": "即墨市",
                                "parentId": "370200",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "青岛市",
                                "county": "即墨市",

                            },
                            {
                                "cityCode": "370283",
                                "cityName": "平度市",
                                "parentId": "370200",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "青岛市",
                                "county": "平度市",

                            },
                            {
                                "cityCode": "370284",
                                "cityName": "胶南市",
                                "parentId": "370200",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "青岛市",
                                "county": "胶南市",

                            },
                            {
                                "cityCode": "370285",
                                "cityName": "莱西市",
                                "parentId": "370200",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "青岛市",
                                "county": "莱西市",

                            },
                            {
                                "cityCode": "370286",
                                "cityName": "西海岸新区",
                                "parentId": "370200",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "青岛市",
                                "county": "西海岸新区",

                            },
                            {
                                "cityCode": "370287",
                                "cityName": "高新区",
                                "parentId": "370200",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "青岛市",
                                "county": "高新区",

                            }
                        ]
                    },
                    {
                        "cityCode": "370300",
                        "cityName": "淄博市",
                        "parentId": "370000",
                        "provinceType": "2",
                        "province": "山东省",
                        "city": "淄博市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "370301",
                                "cityName": "淄博市",
                                "parentId": "370300",
                                "provinceType": "2",
                                "province": "山东省",
                                "city": "淄博市",
                                "county": "",

                            },
                            {
                                "cityCode": "370302",
                                "cityName": "淄川区",
                                "parentId": "370300",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "淄博市",
                                "county": "淄川区",

                            },
                            {
                                "cityCode": "370303",
                                "cityName": "张店区",
                                "parentId": "370300",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "淄博市",
                                "county": "张店区",

                            },
                            {
                                "cityCode": "370304",
                                "cityName": "博山区",
                                "parentId": "370300",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "淄博市",
                                "county": "博山区",

                            },
                            {
                                "cityCode": "370305",
                                "cityName": "临淄区",
                                "parentId": "370300",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "淄博市",
                                "county": "临淄区",

                            },
                            {
                                "cityCode": "370306",
                                "cityName": "周村区",
                                "parentId": "370300",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "淄博市",
                                "county": "周村区",

                            },
                            {
                                "cityCode": "370321",
                                "cityName": "桓台县",
                                "parentId": "370300",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "淄博市",
                                "county": "桓台县",

                            },
                            {
                                "cityCode": "370322",
                                "cityName": "高青县",
                                "parentId": "370300",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "淄博市",
                                "county": "高青县",

                            },
                            {
                                "cityCode": "370323",
                                "cityName": "沂源县",
                                "parentId": "370300",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "淄博市",
                                "county": "沂源县",

                            },
                            {
                                "cityCode": "370324",
                                "cityName": "高新区",
                                "parentId": "370300",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "淄博市",
                                "county": "高新区",

                            }
                        ]
                    },
                    {
                        "cityCode": "370400",
                        "cityName": "枣庄市",
                        "parentId": "370000",
                        "provinceType": "2",
                        "province": "山东省",
                        "city": "枣庄市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "370401",
                                "cityName": "枣庄市",
                                "parentId": "370400",
                                "provinceType": "2",
                                "province": "山东省",
                                "city": "枣庄市",
                                "county": "",

                            },
                            {
                                "cityCode": "370402",
                                "cityName": "市中区",
                                "parentId": "370400",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "枣庄市",
                                "county": "市中区",

                            },
                            {
                                "cityCode": "370403",
                                "cityName": "薛城区",
                                "parentId": "370400",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "枣庄市",
                                "county": "薛城区",

                            },
                            {
                                "cityCode": "370404",
                                "cityName": "峄城区",
                                "parentId": "370400",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "枣庄市",
                                "county": "峄城区",

                            },
                            {
                                "cityCode": "370405",
                                "cityName": "台儿庄区",
                                "parentId": "370400",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "枣庄市",
                                "county": "台儿庄区",

                            },
                            {
                                "cityCode": "370406",
                                "cityName": "山亭区",
                                "parentId": "370400",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "枣庄市",
                                "county": "山亭区",

                            },
                            {
                                "cityCode": "370481",
                                "cityName": "滕州市",
                                "parentId": "370400",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "枣庄市",
                                "county": "滕州市",

                            },
                            {
                                "cityCode": "370482",
                                "cityName": "高新区",
                                "parentId": "370400",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "枣庄市",
                                "county": "高新区",

                            }
                        ]
                    },
                    {
                        "cityCode": "370500",
                        "cityName": "东营市",
                        "parentId": "370000",
                        "provinceType": "2",
                        "province": "山东省",
                        "city": "东营市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "370501",
                                "cityName": "东营市",
                                "parentId": "370500",
                                "provinceType": "2",
                                "province": "山东省",
                                "city": "东营市",
                                "county": "",

                            },
                            {
                                "cityCode": "370502",
                                "cityName": "东营区",
                                "parentId": "370500",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "东营市",
                                "county": "东营区",

                            },
                            {
                                "cityCode": "370503",
                                "cityName": "河口区",
                                "parentId": "370500",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "东营市",
                                "county": "河口区",

                            },
                            {
                                "cityCode": "370505",
                                "cityName": "垦利区",
                                "parentId": "370500",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "东营市",
                                "county": "垦利区",

                            },
                            {
                                "cityCode": "370521",
                                "cityName": "垦利县",
                                "parentId": "370500",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "东营市",
                                "county": "垦利县",

                            },
                            {
                                "cityCode": "370522",
                                "cityName": "利津县",
                                "parentId": "370500",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "东营市",
                                "county": "利津县",

                            },
                            {
                                "cityCode": "370523",
                                "cityName": "广饶县",
                                "parentId": "370500",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "东营市",
                                "county": "广饶县",

                            },
                            {
                                "cityCode": "370571",
                                "cityName": "东营经济技术开发区",
                                "parentId": "370500",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "东营市",
                                "county": "东营经济技术开发区",

                            },
                            {
                                "cityCode": "370572",
                                "cityName": "东营港经济开发区",
                                "parentId": "370500",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "东营市",
                                "county": "东营港经济开发区",

                            }
                        ]
                    },
                    {
                        "cityCode": "370600",
                        "cityName": "烟台市",
                        "parentId": "370000",
                        "provinceType": "2",
                        "province": "山东省",
                        "city": "烟台市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "370601",
                                "cityName": "烟台市",
                                "parentId": "370600",
                                "provinceType": "2",
                                "province": "山东省",
                                "city": "烟台市",
                                "county": "",

                            },
                            {
                                "cityCode": "370602",
                                "cityName": "芝罘区",
                                "parentId": "370600",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "烟台市",
                                "county": "芝罘区",

                            },
                            {
                                "cityCode": "370611",
                                "cityName": "福山区",
                                "parentId": "370600",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "烟台市",
                                "county": "福山区",

                            },
                            {
                                "cityCode": "370612",
                                "cityName": "牟平区",
                                "parentId": "370600",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "烟台市",
                                "county": "牟平区",

                            },
                            {
                                "cityCode": "370613",
                                "cityName": "莱山区",
                                "parentId": "370600",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "烟台市",
                                "county": "莱山区",

                            },
                            {
                                "cityCode": "370614",
                                "cityName": "蓬莱区",
                                "parentId": "370600",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "烟台市",
                                "county": "蓬莱区",

                            },
                            {
                                "cityCode": "370634",
                                "cityName": "长岛县",
                                "parentId": "370600",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "烟台市",
                                "county": "长岛县",

                            },
                            {
                                "cityCode": "370671",
                                "cityName": "烟台高新技术产业开发区",
                                "parentId": "370600",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "烟台市",
                                "county": "烟台高新技术产业开发区",

                            },
                            {
                                "cityCode": "370672",
                                "cityName": "烟台经济技术开发区",
                                "parentId": "370600",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "烟台市",
                                "county": "烟台经济技术开发区",

                            },
                            {
                                "cityCode": "370681",
                                "cityName": "龙口市",
                                "parentId": "370600",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "烟台市",
                                "county": "龙口市",

                            },
                            {
                                "cityCode": "370682",
                                "cityName": "莱阳市",
                                "parentId": "370600",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "烟台市",
                                "county": "莱阳市",

                            },
                            {
                                "cityCode": "370683",
                                "cityName": "莱州市",
                                "parentId": "370600",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "烟台市",
                                "county": "莱州市",

                            },
                            {
                                "cityCode": "370685",
                                "cityName": "招远市",
                                "parentId": "370600",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "烟台市",
                                "county": "招远市",

                            },
                            {
                                "cityCode": "370686",
                                "cityName": "栖霞市",
                                "parentId": "370600",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "烟台市",
                                "county": "栖霞市",

                            },
                            {
                                "cityCode": "370687",
                                "cityName": "海阳市",
                                "parentId": "370600",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "烟台市",
                                "county": "海阳市",

                            },
                            {
                                "cityCode": "370688",
                                "cityName": "高新区",
                                "parentId": "370600",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "烟台市",
                                "county": "高新区",

                            },
                            {
                                "cityCode": "370689",
                                "cityName": "经济开发区",
                                "parentId": "370600",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "烟台市",
                                "county": "经济开发区",

                            }
                        ]
                    },
                    {
                        "cityCode": "370700",
                        "cityName": "潍坊市",
                        "parentId": "370000",
                        "provinceType": "2",
                        "province": "山东省",
                        "city": "潍坊市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "370701",
                                "cityName": "潍坊市",
                                "parentId": "370700",
                                "provinceType": "2",
                                "province": "山东省",
                                "city": "潍坊市",
                                "county": "",

                            },
                            {
                                "cityCode": "370702",
                                "cityName": "潍城区",
                                "parentId": "370700",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "潍坊市",
                                "county": "潍城区",

                            },
                            {
                                "cityCode": "370703",
                                "cityName": "寒亭区",
                                "parentId": "370700",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "潍坊市",
                                "county": "寒亭区",

                            },
                            {
                                "cityCode": "370704",
                                "cityName": "坊子区",
                                "parentId": "370700",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "潍坊市",
                                "county": "坊子区",

                            },
                            {
                                "cityCode": "370705",
                                "cityName": "奎文区",
                                "parentId": "370700",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "潍坊市",
                                "county": "奎文区",

                            },
                            {
                                "cityCode": "370724",
                                "cityName": "临朐县",
                                "parentId": "370700",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "潍坊市",
                                "county": "临朐县",

                            },
                            {
                                "cityCode": "370725",
                                "cityName": "昌乐县",
                                "parentId": "370700",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "潍坊市",
                                "county": "昌乐县",

                            },
                            {
                                "cityCode": "370772",
                                "cityName": "潍坊滨海经济技术开发区",
                                "parentId": "370700",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "潍坊市",
                                "county": "潍坊滨海经济技术开发区",

                            },
                            {
                                "cityCode": "370781",
                                "cityName": "青州市",
                                "parentId": "370700",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "潍坊市",
                                "county": "青州市",

                            },
                            {
                                "cityCode": "370782",
                                "cityName": "诸城市",
                                "parentId": "370700",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "潍坊市",
                                "county": "诸城市",

                            },
                            {
                                "cityCode": "370783",
                                "cityName": "寿光市",
                                "parentId": "370700",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "潍坊市",
                                "county": "寿光市",

                            },
                            {
                                "cityCode": "370784",
                                "cityName": "安丘市",
                                "parentId": "370700",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "潍坊市",
                                "county": "安丘市",

                            },
                            {
                                "cityCode": "370785",
                                "cityName": "高密市",
                                "parentId": "370700",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "潍坊市",
                                "county": "高密市",

                            },
                            {
                                "cityCode": "370786",
                                "cityName": "昌邑市",
                                "parentId": "370700",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "潍坊市",
                                "county": "昌邑市",

                            },
                            {
                                "cityCode": "370787",
                                "cityName": "高新区",
                                "parentId": "370700",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "潍坊市",
                                "county": "高新区",

                            }
                        ]
                    },
                    {
                        "cityCode": "370800",
                        "cityName": "济宁市",
                        "parentId": "370000",
                        "provinceType": "2",
                        "province": "山东省",
                        "city": "济宁市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "370801",
                                "cityName": "济宁市",
                                "parentId": "370800",
                                "provinceType": "2",
                                "province": "山东省",
                                "city": "济宁市",
                                "county": "",

                            },
                            {
                                "cityCode": "370802",
                                "cityName": "市中区",
                                "parentId": "370800",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "济宁市",
                                "county": "市中区",

                            },
                            {
                                "cityCode": "370811",
                                "cityName": "任城区",
                                "parentId": "370800",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "济宁市",
                                "county": "任城区",

                            },
                            {
                                "cityCode": "370812",
                                "cityName": "兖州区",
                                "parentId": "370800",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "济宁市",
                                "county": "兖州区",

                            },
                            {
                                "cityCode": "370826",
                                "cityName": "微山县",
                                "parentId": "370800",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "济宁市",
                                "county": "微山县",

                            },
                            {
                                "cityCode": "370827",
                                "cityName": "鱼台县",
                                "parentId": "370800",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "济宁市",
                                "county": "鱼台县",

                            },
                            {
                                "cityCode": "370828",
                                "cityName": "金乡县",
                                "parentId": "370800",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "济宁市",
                                "county": "金乡县",

                            },
                            {
                                "cityCode": "370829",
                                "cityName": "嘉祥县",
                                "parentId": "370800",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "济宁市",
                                "county": "嘉祥县",

                            },
                            {
                                "cityCode": "370830",
                                "cityName": "汶上县",
                                "parentId": "370800",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "济宁市",
                                "county": "汶上县",

                            },
                            {
                                "cityCode": "370831",
                                "cityName": "泗水县",
                                "parentId": "370800",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "济宁市",
                                "county": "泗水县",

                            },
                            {
                                "cityCode": "370832",
                                "cityName": "梁山县",
                                "parentId": "370800",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "济宁市",
                                "county": "梁山县",

                            },
                            {
                                "cityCode": "370871",
                                "cityName": "济宁高新技术产业开发区",
                                "parentId": "370800",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "济宁市",
                                "county": "济宁高新技术产业开发区",

                            },
                            {
                                "cityCode": "370881",
                                "cityName": "曲阜市",
                                "parentId": "370800",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "济宁市",
                                "county": "曲阜市",

                            },
                            {
                                "cityCode": "370882",
                                "cityName": "兖州市",
                                "parentId": "370800",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "济宁市",
                                "county": "兖州市",

                            },
                            {
                                "cityCode": "370883",
                                "cityName": "邹城市",
                                "parentId": "370800",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "济宁市",
                                "county": "邹城市",

                            },
                            {
                                "cityCode": "370884",
                                "cityName": "高新区",
                                "parentId": "370800",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "济宁市",
                                "county": "高新区",

                            }
                        ]
                    },
                    {
                        "cityCode": "370900",
                        "cityName": "泰安市",
                        "parentId": "370000",
                        "provinceType": "2",
                        "province": "山东省",
                        "city": "泰安市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "370901",
                                "cityName": "泰安市",
                                "parentId": "370900",
                                "provinceType": "2",
                                "province": "山东省",
                                "city": "泰安市",
                                "county": "",

                            },
                            {
                                "cityCode": "370902",
                                "cityName": "泰山区",
                                "parentId": "370900",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "泰安市",
                                "county": "泰山区",

                            },
                            {
                                "cityCode": "370903",
                                "cityName": "岱岳区",
                                "parentId": "370900",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "泰安市",
                                "county": "岱岳区",

                            },
                            {
                                "cityCode": "370911",
                                "cityName": "岱岳区",
                                "parentId": "370900",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "泰安市",
                                "county": "岱岳区",

                            },
                            {
                                "cityCode": "370921",
                                "cityName": "宁阳县",
                                "parentId": "370900",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "泰安市",
                                "county": "宁阳县",

                            },
                            {
                                "cityCode": "370923",
                                "cityName": "东平县",
                                "parentId": "370900",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "泰安市",
                                "county": "东平县",

                            },
                            {
                                "cityCode": "370982",
                                "cityName": "新泰市",
                                "parentId": "370900",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "泰安市",
                                "county": "新泰市",

                            },
                            {
                                "cityCode": "370983",
                                "cityName": "肥城市",
                                "parentId": "370900",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "泰安市",
                                "county": "肥城市",

                            }
                        ]
                    },
                    {
                        "cityCode": "371000",
                        "cityName": "威海市",
                        "parentId": "370000",
                        "provinceType": "2",
                        "province": "山东省",
                        "city": "威海市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "371001",
                                "cityName": "威海市",
                                "parentId": "371000",
                                "provinceType": "2",
                                "province": "山东省",
                                "city": "威海市",
                                "county": "",

                            },
                            {
                                "cityCode": "371002",
                                "cityName": "环翠区",
                                "parentId": "371000",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "威海市",
                                "county": "环翠区",

                            },
                            {
                                "cityCode": "371003",
                                "cityName": "文登区",
                                "parentId": "371000",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "威海市",
                                "county": "文登区",

                            },
                            {
                                "cityCode": "371071",
                                "cityName": "威海火炬高技术产业开发区",
                                "parentId": "371000",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "威海市",
                                "county": "威海火炬高技术产业开发区",

                            },
                            {
                                "cityCode": "371072",
                                "cityName": "威海经济技术开发区",
                                "parentId": "371000",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "威海市",
                                "county": "威海经济技术开发区",

                            },
                            {
                                "cityCode": "371073",
                                "cityName": "威海临港经济技术开发区",
                                "parentId": "371000",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "威海市",
                                "county": "威海临港经济技术开发区",

                            },
                            {
                                "cityCode": "371081",
                                "cityName": "文登市",
                                "parentId": "371000",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "威海市",
                                "county": "文登市",

                            },
                            {
                                "cityCode": "371082",
                                "cityName": "荣成市",
                                "parentId": "371000",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "威海市",
                                "county": "荣成市",

                            },
                            {
                                "cityCode": "371083",
                                "cityName": "乳山市",
                                "parentId": "371000",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "威海市",
                                "county": "乳山市",

                            }
                        ]
                    },
                    {
                        "cityCode": "371100",
                        "cityName": "日照市",
                        "parentId": "370000",
                        "provinceType": "2",
                        "province": "山东省",
                        "city": "日照市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "371101",
                                "cityName": "日照市",
                                "parentId": "371100",
                                "provinceType": "2",
                                "province": "山东省",
                                "city": "日照市",
                                "county": "",

                            },
                            {
                                "cityCode": "371102",
                                "cityName": "东港区",
                                "parentId": "371100",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "日照市",
                                "county": "东港区",

                            },
                            {
                                "cityCode": "371103",
                                "cityName": "岚山区",
                                "parentId": "371100",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "日照市",
                                "county": "岚山区",

                            },
                            {
                                "cityCode": "371121",
                                "cityName": "五莲县",
                                "parentId": "371100",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "日照市",
                                "county": "五莲县",

                            },
                            {
                                "cityCode": "371122",
                                "cityName": "莒县",
                                "parentId": "371100",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "日照市",
                                "county": "莒县",

                            },
                            {
                                "cityCode": "371171",
                                "cityName": "日照经济技术开发区",
                                "parentId": "371100",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "日照市",
                                "county": "日照经济技术开发区",

                            },
                            {
                                "cityCode": "371172",
                                "cityName": "日照国际海洋城",
                                "parentId": "371100",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "日照市",
                                "county": "日照国际海洋城",

                            }
                        ]
                    },
                    {
                        "cityCode": "371200",
                        "cityName": "莱芜市",
                        "parentId": "370000",
                        "provinceType": "2",
                        "province": "山东省",
                        "city": "莱芜市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "371201",
                                "cityName": "莱芜市",
                                "parentId": "371200",
                                "provinceType": "2",
                                "province": "山东省",
                                "city": "莱芜市",
                                "county": "",

                            },
                            {
                                "cityCode": "371202",
                                "cityName": "莱城区",
                                "parentId": "371200",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "莱芜市",
                                "county": "莱城区",

                            },
                            {
                                "cityCode": "371203",
                                "cityName": "钢城区",
                                "parentId": "371200",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "莱芜市",
                                "county": "钢城区",

                            }
                        ]
                    },
                    {
                        "cityCode": "371300",
                        "cityName": "临沂市",
                        "parentId": "370000",
                        "provinceType": "2",
                        "province": "山东省",
                        "city": "临沂市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "371301",
                                "cityName": "临沂市",
                                "parentId": "371300",
                                "provinceType": "2",
                                "province": "山东省",
                                "city": "临沂市",
                                "county": "",

                            },
                            {
                                "cityCode": "371302",
                                "cityName": "兰山区",
                                "parentId": "371300",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "临沂市",
                                "county": "兰山区",

                            },
                            {
                                "cityCode": "371311",
                                "cityName": "罗庄区",
                                "parentId": "371300",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "临沂市",
                                "county": "罗庄区",

                            },
                            {
                                "cityCode": "371312",
                                "cityName": "河东区",
                                "parentId": "371300",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "临沂市",
                                "county": "河东区",

                            },
                            {
                                "cityCode": "371321",
                                "cityName": "沂南县",
                                "parentId": "371300",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "临沂市",
                                "county": "沂南县",

                            },
                            {
                                "cityCode": "371322",
                                "cityName": "郯城县",
                                "parentId": "371300",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "临沂市",
                                "county": "郯城县",

                            },
                            {
                                "cityCode": "371323",
                                "cityName": "沂水县",
                                "parentId": "371300",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "临沂市",
                                "county": "沂水县",

                            },
                            {
                                "cityCode": "371324",
                                "cityName": "兰陵县",
                                "parentId": "371300",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "临沂市",
                                "county": "兰陵县",

                            },
                            {
                                "cityCode": "371325",
                                "cityName": "费县",
                                "parentId": "371300",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "临沂市",
                                "county": "费县",

                            },
                            {
                                "cityCode": "371326",
                                "cityName": "平邑县",
                                "parentId": "371300",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "临沂市",
                                "county": "平邑县",

                            },
                            {
                                "cityCode": "371327",
                                "cityName": "莒南县",
                                "parentId": "371300",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "临沂市",
                                "county": "莒南县",

                            },
                            {
                                "cityCode": "371328",
                                "cityName": "蒙阴县",
                                "parentId": "371300",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "临沂市",
                                "county": "蒙阴县",

                            },
                            {
                                "cityCode": "371329",
                                "cityName": "临沭县",
                                "parentId": "371300",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "临沂市",
                                "county": "临沭县",

                            },
                            {
                                "cityCode": "371371",
                                "cityName": "临沂高新技术产业开发区",
                                "parentId": "371300",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "临沂市",
                                "county": "临沂高新技术产业开发区",

                            },
                            {
                                "cityCode": "371372",
                                "cityName": "临沂经济技术开发区",
                                "parentId": "371300",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "临沂市",
                                "county": "临沂经济技术开发区",

                            },
                            {
                                "cityCode": "371373",
                                "cityName": "临沂临港经济开发区",
                                "parentId": "371300",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "临沂市",
                                "county": "临沂临港经济开发区",

                            }
                        ]
                    },
                    {
                        "cityCode": "371400",
                        "cityName": "德州市",
                        "parentId": "370000",
                        "provinceType": "2",
                        "province": "山东省",
                        "city": "德州市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "371401",
                                "cityName": "德州市",
                                "parentId": "371400",
                                "provinceType": "2",
                                "province": "山东省",
                                "city": "德州市",
                                "county": "",

                            },
                            {
                                "cityCode": "371402",
                                "cityName": "德城区",
                                "parentId": "371400",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "德州市",
                                "county": "德城区",

                            },
                            {
                                "cityCode": "371403",
                                "cityName": "陵城区",
                                "parentId": "371400",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "德州市",
                                "county": "陵城区",

                            },
                            {
                                "cityCode": "371421",
                                "cityName": "陵县",
                                "parentId": "371400",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "德州市",
                                "county": "陵县",

                            },
                            {
                                "cityCode": "371422",
                                "cityName": "宁津县",
                                "parentId": "371400",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "德州市",
                                "county": "宁津县",

                            },
                            {
                                "cityCode": "371423",
                                "cityName": "庆云县",
                                "parentId": "371400",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "德州市",
                                "county": "庆云县",

                            },
                            {
                                "cityCode": "371424",
                                "cityName": "临邑县",
                                "parentId": "371400",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "德州市",
                                "county": "临邑县",

                            },
                            {
                                "cityCode": "371425",
                                "cityName": "齐河县",
                                "parentId": "371400",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "德州市",
                                "county": "齐河县",

                            },
                            {
                                "cityCode": "371426",
                                "cityName": "平原县",
                                "parentId": "371400",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "德州市",
                                "county": "平原县",

                            },
                            {
                                "cityCode": "371427",
                                "cityName": "夏津县",
                                "parentId": "371400",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "德州市",
                                "county": "夏津县",

                            },
                            {
                                "cityCode": "371428",
                                "cityName": "武城县",
                                "parentId": "371400",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "德州市",
                                "county": "武城县",

                            },
                            {
                                "cityCode": "371471",
                                "cityName": "德州经济技术开发区",
                                "parentId": "371400",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "德州市",
                                "county": "德州经济技术开发区",

                            },
                            {
                                "cityCode": "371472",
                                "cityName": "德州运河经济开发区",
                                "parentId": "371400",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "德州市",
                                "county": "德州运河经济开发区",

                            },
                            {
                                "cityCode": "371481",
                                "cityName": "乐陵市",
                                "parentId": "371400",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "德州市",
                                "county": "乐陵市",

                            },
                            {
                                "cityCode": "371482",
                                "cityName": "禹城市",
                                "parentId": "371400",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "德州市",
                                "county": "禹城市",

                            }
                        ]
                    },
                    {
                        "cityCode": "371500",
                        "cityName": "聊城市",
                        "parentId": "370000",
                        "provinceType": "2",
                        "province": "山东省",
                        "city": "聊城市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "371501",
                                "cityName": "聊城市",
                                "parentId": "371500",
                                "provinceType": "2",
                                "province": "山东省",
                                "city": "聊城市",
                                "county": "",

                            },
                            {
                                "cityCode": "371502",
                                "cityName": "东昌府区",
                                "parentId": "371500",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "聊城市",
                                "county": "东昌府区",

                            },
                            {
                                "cityCode": "371503",
                                "cityName": "茌平区",
                                "parentId": "371500",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "聊城市",
                                "county": "茌平区",

                            },
                            {
                                "cityCode": "371521",
                                "cityName": "阳谷县",
                                "parentId": "371500",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "聊城市",
                                "county": "阳谷县",

                            },
                            {
                                "cityCode": "371522",
                                "cityName": "莘县",
                                "parentId": "371500",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "聊城市",
                                "county": "莘县",

                            },
                            {
                                "cityCode": "371523",
                                "cityName": "茌平县",
                                "parentId": "371500",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "聊城市",
                                "county": "茌平县",

                            },
                            {
                                "cityCode": "371524",
                                "cityName": "东阿县",
                                "parentId": "371500",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "聊城市",
                                "county": "东阿县",

                            },
                            {
                                "cityCode": "371525",
                                "cityName": "冠县",
                                "parentId": "371500",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "聊城市",
                                "county": "冠县",

                            },
                            {
                                "cityCode": "371526",
                                "cityName": "高唐县",
                                "parentId": "371500",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "聊城市",
                                "county": "高唐县",

                            },
                            {
                                "cityCode": "371581",
                                "cityName": "临清市",
                                "parentId": "371500",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "聊城市",
                                "county": "临清市",

                            }
                        ]
                    },
                    {
                        "cityCode": "371600",
                        "cityName": "滨州市",
                        "parentId": "370000",
                        "provinceType": "2",
                        "province": "山东省",
                        "city": "滨州市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "371602",
                                "cityName": "滨城区",
                                "parentId": "371600",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "滨州市",
                                "county": "滨城区",

                            },
                            {
                                "cityCode": "371603",
                                "cityName": "沾化区",
                                "parentId": "371600",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "滨州市",
                                "county": "沾化区",

                            },
                            {
                                "cityCode": "371621",
                                "cityName": "惠民县",
                                "parentId": "371600",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "滨州市",
                                "county": "惠民县",

                            },
                            {
                                "cityCode": "371622",
                                "cityName": "阳信县",
                                "parentId": "371600",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "滨州市",
                                "county": "阳信县",

                            },
                            {
                                "cityCode": "371623",
                                "cityName": "无棣县",
                                "parentId": "371600",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "滨州市",
                                "county": "无棣县",

                            },
                            {
                                "cityCode": "371624",
                                "cityName": "沾化县",
                                "parentId": "371600",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "滨州市",
                                "county": "沾化县",

                            },
                            {
                                "cityCode": "371625",
                                "cityName": "博兴县",
                                "parentId": "371600",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "滨州市",
                                "county": "博兴县",

                            },
                            {
                                "cityCode": "371626",
                                "cityName": "邹平县",
                                "parentId": "371600",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "滨州市",
                                "county": "邹平县",

                            },
                            {
                                "cityCode": "371627",
                                "cityName": "北海新区",
                                "parentId": "371600",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "滨州市",
                                "county": "北海新区",

                            },
                            {
                                "cityCode": "371681",
                                "cityName": "邹平县",
                                "parentId": "371600",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "滨州市",
                                "county": "邹平县",

                            }
                        ]
                    },
                    {
                        "cityCode": "371700",
                        "cityName": "菏泽市",
                        "parentId": "370000",
                        "provinceType": "2",
                        "province": "山东省",
                        "city": "菏泽市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "371701",
                                "cityName": "菏泽市",
                                "parentId": "371700",
                                "provinceType": "2",
                                "province": "山东省",
                                "city": "菏泽市",
                                "county": "",

                            },
                            {
                                "cityCode": "371702",
                                "cityName": "牡丹区",
                                "parentId": "371700",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "菏泽市",
                                "county": "牡丹区",

                            },
                            {
                                "cityCode": "371703",
                                "cityName": "定陶区",
                                "parentId": "371700",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "菏泽市",
                                "county": "定陶区",

                            },
                            {
                                "cityCode": "371721",
                                "cityName": "曹县",
                                "parentId": "371700",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "菏泽市",
                                "county": "曹县",

                            },
                            {
                                "cityCode": "371722",
                                "cityName": "单县",
                                "parentId": "371700",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "菏泽市",
                                "county": "单县",

                            },
                            {
                                "cityCode": "371723",
                                "cityName": "成武县",
                                "parentId": "371700",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "菏泽市",
                                "county": "成武县",

                            },
                            {
                                "cityCode": "371724",
                                "cityName": "巨野县",
                                "parentId": "371700",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "菏泽市",
                                "county": "巨野县",

                            },
                            {
                                "cityCode": "371725",
                                "cityName": "郓城县",
                                "parentId": "371700",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "菏泽市",
                                "county": "郓城县",

                            },
                            {
                                "cityCode": "371726",
                                "cityName": "鄄城县",
                                "parentId": "371700",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "菏泽市",
                                "county": "鄄城县",

                            },
                            {
                                "cityCode": "371727",
                                "cityName": "定陶县",
                                "parentId": "371700",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "菏泽市",
                                "county": "定陶县",

                            },
                            {
                                "cityCode": "371728",
                                "cityName": "东明县",
                                "parentId": "371700",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "菏泽市",
                                "county": "东明县",

                            },
                            {
                                "cityCode": "371771",
                                "cityName": "菏泽经济技术开发区",
                                "parentId": "371700",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "菏泽市",
                                "county": "菏泽经济技术开发区",

                            },
                            {
                                "cityCode": "371772",
                                "cityName": "菏泽高新技术开发区",
                                "parentId": "371700",
                                "provinceType": "3",
                                "province": "山东省",
                                "city": "菏泽市",
                                "county": "菏泽高新技术开发区",

                            }
                        ]
                    }
                ]
            },
            {
                "cityCode": "410000",
                "cityName": "河南省",
                "parentId": "100000",
                "provinceType": "1",
                "province": "河南省",
                "city": "",
                "county": "",
                "counties": [
                    {
                        "cityCode": "410100",
                        "cityName": "郑州市",
                        "parentId": "410000",
                        "provinceType": "2",
                        "province": "河南省",
                        "city": "郑州市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "410101",
                                "cityName": "郑州市",
                                "parentId": "410100",
                                "provinceType": "2",
                                "province": "河南省",
                                "city": "郑州市",
                                "county": "",

                            },
                            {
                                "cityCode": "410102",
                                "cityName": "中原区",
                                "parentId": "410100",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "郑州市",
                                "county": "中原区",

                            },
                            {
                                "cityCode": "410103",
                                "cityName": "二七区",
                                "parentId": "410100",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "郑州市",
                                "county": "二七区",

                            },
                            {
                                "cityCode": "410104",
                                "cityName": "管城回族区",
                                "parentId": "410100",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "郑州市",
                                "county": "管城回族区",

                            },
                            {
                                "cityCode": "410105",
                                "cityName": "金水区",
                                "parentId": "410100",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "郑州市",
                                "county": "金水区",

                            },
                            {
                                "cityCode": "410106",
                                "cityName": "上街区",
                                "parentId": "410100",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "郑州市",
                                "county": "上街区",

                            },
                            {
                                "cityCode": "410108",
                                "cityName": "惠济区",
                                "parentId": "410100",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "郑州市",
                                "county": "惠济区",

                            },
                            {
                                "cityCode": "410122",
                                "cityName": "中牟县",
                                "parentId": "410100",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "郑州市",
                                "county": "中牟县",

                            },
                            {
                                "cityCode": "410171",
                                "cityName": "郑州经济技术开发区",
                                "parentId": "410100",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "郑州市",
                                "county": "郑州经济技术开发区",

                            },
                            {
                                "cityCode": "410172",
                                "cityName": "郑州高新技术产业开发区",
                                "parentId": "410100",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "郑州市",
                                "county": "郑州高新技术产业开发区",

                            },
                            {
                                "cityCode": "410173",
                                "cityName": "郑州航空港经济综合实验区",
                                "parentId": "410100",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "郑州市",
                                "county": "郑州航空港经济综合实验区",

                            },
                            {
                                "cityCode": "410181",
                                "cityName": "巩义市",
                                "parentId": "410100",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "郑州市",
                                "county": "巩义市",

                            },
                            {
                                "cityCode": "410182",
                                "cityName": "荥阳市",
                                "parentId": "410100",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "郑州市",
                                "county": "荥阳市",

                            },
                            {
                                "cityCode": "410183",
                                "cityName": "新密市",
                                "parentId": "410100",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "郑州市",
                                "county": "新密市",

                            },
                            {
                                "cityCode": "410184",
                                "cityName": "新郑市",
                                "parentId": "410100",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "郑州市",
                                "county": "新郑市",

                            },
                            {
                                "cityCode": "410185",
                                "cityName": "登封市",
                                "parentId": "410100",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "郑州市",
                                "county": "登封市",

                            },
                            {
                                "cityCode": "410186",
                                "cityName": "郑东新区",
                                "parentId": "410100",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "郑州市",
                                "county": "郑东新区",

                            },
                            {
                                "cityCode": "410187",
                                "cityName": "郑汴新区",
                                "parentId": "410100",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "郑州市",
                                "county": "郑汴新区",

                            },
                            {
                                "cityCode": "410188",
                                "cityName": "高新开发区",
                                "parentId": "410100",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "郑州市",
                                "county": "高新开发区",

                            },
                            {
                                "cityCode": "410189",
                                "cityName": "经济开发区",
                                "parentId": "410100",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "郑州市",
                                "county": "经济开发区",

                            }
                        ]
                    },
                    {
                        "cityCode": "410200",
                        "cityName": "开封市",
                        "parentId": "410000",
                        "provinceType": "2",
                        "province": "河南省",
                        "city": "开封市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "410201",
                                "cityName": "开封市",
                                "parentId": "410200",
                                "provinceType": "2",
                                "province": "河南省",
                                "city": "开封市",
                                "county": "",

                            },
                            {
                                "cityCode": "410202",
                                "cityName": "龙亭区",
                                "parentId": "410200",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "开封市",
                                "county": "龙亭区",

                            },
                            {
                                "cityCode": "410203",
                                "cityName": "顺河回族区",
                                "parentId": "410200",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "开封市",
                                "county": "顺河回族区",

                            },
                            {
                                "cityCode": "410204",
                                "cityName": "鼓楼区",
                                "parentId": "410200",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "开封市",
                                "county": "鼓楼区",

                            },
                            {
                                "cityCode": "410205",
                                "cityName": "禹王台区",
                                "parentId": "410200",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "开封市",
                                "county": "禹王台区",

                            },
                            {
                                "cityCode": "410211",
                                "cityName": "金明区",
                                "parentId": "410200",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "开封市",
                                "county": "金明区",

                            },
                            {
                                "cityCode": "410212",
                                "cityName": "祥符区",
                                "parentId": "410200",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "开封市",
                                "county": "祥符区",

                            },
                            {
                                "cityCode": "410221",
                                "cityName": "杞县",
                                "parentId": "410200",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "开封市",
                                "county": "杞县",

                            },
                            {
                                "cityCode": "410222",
                                "cityName": "通许县",
                                "parentId": "410200",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "开封市",
                                "county": "通许县",

                            },
                            {
                                "cityCode": "410223",
                                "cityName": "尉氏县",
                                "parentId": "410200",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "开封市",
                                "county": "尉氏县",

                            },
                            {
                                "cityCode": "410224",
                                "cityName": "祥符区",
                                "parentId": "410200",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "开封市",
                                "county": "祥符区",

                            },
                            {
                                "cityCode": "410225",
                                "cityName": "兰考县",
                                "parentId": "410200",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "开封市",
                                "county": "兰考县",

                            },
                            {
                                "cityCode": "410226",
                                "cityName": "经济技术开发区",
                                "parentId": "410200",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "开封市",
                                "county": "经济技术开发区",

                            }
                        ]
                    },
                    {
                        "cityCode": "410300",
                        "cityName": "洛阳市",
                        "parentId": "410000",
                        "provinceType": "2",
                        "province": "河南省",
                        "city": "洛阳市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "410301",
                                "cityName": "洛阳市",
                                "parentId": "410300",
                                "provinceType": "2",
                                "province": "河南省",
                                "city": "洛阳市",
                                "county": "",

                            },
                            {
                                "cityCode": "410302",
                                "cityName": "老城区",
                                "parentId": "410300",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "洛阳市",
                                "county": "老城区",

                            },
                            {
                                "cityCode": "410303",
                                "cityName": "西工区",
                                "parentId": "410300",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "洛阳市",
                                "county": "西工区",

                            },
                            {
                                "cityCode": "410304",
                                "cityName": "瀍河回族区",
                                "parentId": "410300",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "洛阳市",
                                "county": "瀍河回族区",

                            },
                            {
                                "cityCode": "410305",
                                "cityName": "涧西区",
                                "parentId": "410300",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "洛阳市",
                                "county": "涧西区",

                            },
                            {
                                "cityCode": "410306",
                                "cityName": "吉利区",
                                "parentId": "410300",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "洛阳市",
                                "county": "吉利区",

                            },
                            {
                                "cityCode": "410307",
                                "cityName": "洛龙区",
                                "parentId": "410300",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "洛阳市",
                                "county": "洛龙区",

                            },
                            {
                                "cityCode": "410311",
                                "cityName": "洛龙区",
                                "parentId": "410300",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "洛阳市",
                                "county": "洛龙区",

                            },
                            {
                                "cityCode": "410322",
                                "cityName": "孟津县",
                                "parentId": "410300",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "洛阳市",
                                "county": "孟津县",

                            },
                            {
                                "cityCode": "410323",
                                "cityName": "新安县",
                                "parentId": "410300",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "洛阳市",
                                "county": "新安县",

                            },
                            {
                                "cityCode": "410324",
                                "cityName": "栾川县",
                                "parentId": "410300",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "洛阳市",
                                "county": "栾川县",

                            },
                            {
                                "cityCode": "410325",
                                "cityName": "嵩县",
                                "parentId": "410300",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "洛阳市",
                                "county": "嵩县",

                            },
                            {
                                "cityCode": "410326",
                                "cityName": "汝阳县",
                                "parentId": "410300",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "洛阳市",
                                "county": "汝阳县",

                            },
                            {
                                "cityCode": "410327",
                                "cityName": "宜阳县",
                                "parentId": "410300",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "洛阳市",
                                "county": "宜阳县",

                            },
                            {
                                "cityCode": "410328",
                                "cityName": "洛宁县",
                                "parentId": "410300",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "洛阳市",
                                "county": "洛宁县",

                            },
                            {
                                "cityCode": "410329",
                                "cityName": "伊川县",
                                "parentId": "410300",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "洛阳市",
                                "county": "伊川县",

                            },
                            {
                                "cityCode": "410371",
                                "cityName": "洛阳高新技术产业开发区",
                                "parentId": "410300",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "洛阳市",
                                "county": "洛阳高新技术产业开发区",

                            },
                            {
                                "cityCode": "410381",
                                "cityName": "偃师市",
                                "parentId": "410300",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "洛阳市",
                                "county": "偃师市",

                            },
                            {
                                "cityCode": "410382",
                                "cityName": "洛阳新区",
                                "parentId": "410300",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "洛阳市",
                                "county": "洛阳新区",

                            },
                            {
                                "cityCode": "410383",
                                "cityName": "高新区",
                                "parentId": "410300",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "洛阳市",
                                "county": "高新区",

                            }
                        ]
                    },
                    {
                        "cityCode": "410400",
                        "cityName": "平顶山市",
                        "parentId": "410000",
                        "provinceType": "2",
                        "province": "河南省",
                        "city": "平顶山市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "410401",
                                "cityName": "平顶山市",
                                "parentId": "410400",
                                "provinceType": "2",
                                "province": "河南省",
                                "city": "平顶山市",
                                "county": "",

                            },
                            {
                                "cityCode": "410402",
                                "cityName": "新华区",
                                "parentId": "410400",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "平顶山市",
                                "county": "新华区",

                            },
                            {
                                "cityCode": "410403",
                                "cityName": "卫东区",
                                "parentId": "410400",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "平顶山市",
                                "county": "卫东区",

                            },
                            {
                                "cityCode": "410404",
                                "cityName": "石龙区",
                                "parentId": "410400",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "平顶山市",
                                "county": "石龙区",

                            },
                            {
                                "cityCode": "410411",
                                "cityName": "湛河区",
                                "parentId": "410400",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "平顶山市",
                                "county": "湛河区",

                            },
                            {
                                "cityCode": "410421",
                                "cityName": "宝丰县",
                                "parentId": "410400",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "平顶山市",
                                "county": "宝丰县",

                            },
                            {
                                "cityCode": "410422",
                                "cityName": "叶县",
                                "parentId": "410400",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "平顶山市",
                                "county": "叶县",

                            },
                            {
                                "cityCode": "410423",
                                "cityName": "鲁山县",
                                "parentId": "410400",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "平顶山市",
                                "county": "鲁山县",

                            },
                            {
                                "cityCode": "410425",
                                "cityName": "郏县",
                                "parentId": "410400",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "平顶山市",
                                "county": "郏县",

                            },
                            {
                                "cityCode": "410471",
                                "cityName": "平顶山高新技术产业开发区",
                                "parentId": "410400",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "平顶山市",
                                "county": "平顶山高新技术产业开发区",

                            },
                            {
                                "cityCode": "410472",
                                "cityName": "平顶山市新城区",
                                "parentId": "410400",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "平顶山市",
                                "county": "平顶山市新城区",

                            },
                            {
                                "cityCode": "410481",
                                "cityName": "舞钢市",
                                "parentId": "410400",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "平顶山市",
                                "county": "舞钢市",

                            },
                            {
                                "cityCode": "410482",
                                "cityName": "汝州市",
                                "parentId": "410400",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "平顶山市",
                                "county": "汝州市",

                            }
                        ]
                    },
                    {
                        "cityCode": "410500",
                        "cityName": "安阳市",
                        "parentId": "410000",
                        "provinceType": "2",
                        "province": "河南省",
                        "city": "安阳市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "410501",
                                "cityName": "安阳市",
                                "parentId": "410500",
                                "provinceType": "2",
                                "province": "河南省",
                                "city": "安阳市",
                                "county": "",

                            },
                            {
                                "cityCode": "410502",
                                "cityName": "文峰区",
                                "parentId": "410500",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "安阳市",
                                "county": "文峰区",

                            },
                            {
                                "cityCode": "410503",
                                "cityName": "北关区",
                                "parentId": "410500",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "安阳市",
                                "county": "北关区",

                            },
                            {
                                "cityCode": "410505",
                                "cityName": "殷都区",
                                "parentId": "410500",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "安阳市",
                                "county": "殷都区",

                            },
                            {
                                "cityCode": "410506",
                                "cityName": "龙安区",
                                "parentId": "410500",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "安阳市",
                                "county": "龙安区",

                            },
                            {
                                "cityCode": "410522",
                                "cityName": "安阳县",
                                "parentId": "410500",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "安阳市",
                                "county": "安阳县",

                            },
                            {
                                "cityCode": "410523",
                                "cityName": "汤阴县",
                                "parentId": "410500",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "安阳市",
                                "county": "汤阴县",

                            },
                            {
                                "cityCode": "410526",
                                "cityName": "滑县",
                                "parentId": "410500",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "安阳市",
                                "county": "滑县",

                            },
                            {
                                "cityCode": "410527",
                                "cityName": "内黄县",
                                "parentId": "410500",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "安阳市",
                                "county": "内黄县",

                            },
                            {
                                "cityCode": "410571",
                                "cityName": "安阳高新技术产业开发区",
                                "parentId": "410500",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "安阳市",
                                "county": "安阳高新技术产业开发区",

                            },
                            {
                                "cityCode": "410581",
                                "cityName": "林州市",
                                "parentId": "410500",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "安阳市",
                                "county": "林州市",

                            },
                            {
                                "cityCode": "410582",
                                "cityName": "安阳新区",
                                "parentId": "410500",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "安阳市",
                                "county": "安阳新区",

                            }
                        ]
                    },
                    {
                        "cityCode": "410600",
                        "cityName": "鹤壁市",
                        "parentId": "410000",
                        "provinceType": "2",
                        "province": "河南省",
                        "city": "鹤壁市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "410601",
                                "cityName": "鹤壁市",
                                "parentId": "410600",
                                "provinceType": "2",
                                "province": "河南省",
                                "city": "鹤壁市",
                                "county": "",

                            },
                            {
                                "cityCode": "410602",
                                "cityName": "鹤山区",
                                "parentId": "410600",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "鹤壁市",
                                "county": "鹤山区",

                            },
                            {
                                "cityCode": "410603",
                                "cityName": "山城区",
                                "parentId": "410600",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "鹤壁市",
                                "county": "山城区",

                            },
                            {
                                "cityCode": "410611",
                                "cityName": "淇滨区",
                                "parentId": "410600",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "鹤壁市",
                                "county": "淇滨区",

                            },
                            {
                                "cityCode": "410621",
                                "cityName": "浚县",
                                "parentId": "410600",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "鹤壁市",
                                "county": "浚县",

                            },
                            {
                                "cityCode": "410622",
                                "cityName": "淇县",
                                "parentId": "410600",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "鹤壁市",
                                "county": "淇县",

                            },
                            {
                                "cityCode": "410671",
                                "cityName": "鹤壁经济技术开发区",
                                "parentId": "410600",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "鹤壁市",
                                "county": "鹤壁经济技术开发区",

                            }
                        ]
                    },
                    {
                        "cityCode": "410700",
                        "cityName": "新乡市",
                        "parentId": "410000",
                        "provinceType": "2",
                        "province": "河南省",
                        "city": "新乡市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "410701",
                                "cityName": "新乡市",
                                "parentId": "410700",
                                "provinceType": "2",
                                "province": "河南省",
                                "city": "新乡市",
                                "county": "",

                            },
                            {
                                "cityCode": "410702",
                                "cityName": "红旗区",
                                "parentId": "410700",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "新乡市",
                                "county": "红旗区",

                            },
                            {
                                "cityCode": "410703",
                                "cityName": "卫滨区",
                                "parentId": "410700",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "新乡市",
                                "county": "卫滨区",

                            },
                            {
                                "cityCode": "410704",
                                "cityName": "凤泉区",
                                "parentId": "410700",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "新乡市",
                                "county": "凤泉区",

                            },
                            {
                                "cityCode": "410711",
                                "cityName": "牧野区",
                                "parentId": "410700",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "新乡市",
                                "county": "牧野区",

                            },
                            {
                                "cityCode": "410721",
                                "cityName": "新乡县",
                                "parentId": "410700",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "新乡市",
                                "county": "新乡县",

                            },
                            {
                                "cityCode": "410724",
                                "cityName": "获嘉县",
                                "parentId": "410700",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "新乡市",
                                "county": "获嘉县",

                            },
                            {
                                "cityCode": "410725",
                                "cityName": "原阳县",
                                "parentId": "410700",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "新乡市",
                                "county": "原阳县",

                            },
                            {
                                "cityCode": "410726",
                                "cityName": "延津县",
                                "parentId": "410700",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "新乡市",
                                "county": "延津县",

                            },
                            {
                                "cityCode": "410727",
                                "cityName": "封丘县",
                                "parentId": "410700",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "新乡市",
                                "county": "封丘县",

                            },
                            {
                                "cityCode": "410728",
                                "cityName": "长垣县",
                                "parentId": "410700",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "新乡市",
                                "county": "长垣县",

                            },
                            {
                                "cityCode": "410771",
                                "cityName": "新乡高新技术产业开发区",
                                "parentId": "410700",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "新乡市",
                                "county": "新乡高新技术产业开发区",

                            },
                            {
                                "cityCode": "410772",
                                "cityName": "新乡经济技术开发区",
                                "parentId": "410700",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "新乡市",
                                "county": "新乡经济技术开发区",

                            },
                            {
                                "cityCode": "410773",
                                "cityName": "新乡市平原城乡一体化示范区",
                                "parentId": "410700",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "新乡市",
                                "county": "新乡市平原城乡一体化示范区",

                            },
                            {
                                "cityCode": "410781",
                                "cityName": "卫辉市",
                                "parentId": "410700",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "新乡市",
                                "county": "卫辉市",

                            },
                            {
                                "cityCode": "410782",
                                "cityName": "辉县市",
                                "parentId": "410700",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "新乡市",
                                "county": "辉县市",

                            },
                            {
                                "cityCode": "410783",
                                "cityName": "长垣市",
                                "parentId": "410700",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "新乡市",
                                "county": "长垣市",

                            }
                        ]
                    },
                    {
                        "cityCode": "410800",
                        "cityName": "焦作市",
                        "parentId": "410000",
                        "provinceType": "2",
                        "province": "河南省",
                        "city": "焦作市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "410801",
                                "cityName": "焦作市",
                                "parentId": "410800",
                                "provinceType": "2",
                                "province": "河南省",
                                "city": "焦作市",
                                "county": "",

                            },
                            {
                                "cityCode": "410802",
                                "cityName": "解放区",
                                "parentId": "410800",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "焦作市",
                                "county": "解放区",

                            },
                            {
                                "cityCode": "410803",
                                "cityName": "中站区",
                                "parentId": "410800",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "焦作市",
                                "county": "中站区",

                            },
                            {
                                "cityCode": "410804",
                                "cityName": "马村区",
                                "parentId": "410800",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "焦作市",
                                "county": "马村区",

                            },
                            {
                                "cityCode": "410811",
                                "cityName": "山阳区",
                                "parentId": "410800",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "焦作市",
                                "county": "山阳区",

                            },
                            {
                                "cityCode": "410821",
                                "cityName": "修武县",
                                "parentId": "410800",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "焦作市",
                                "county": "修武县",

                            },
                            {
                                "cityCode": "410822",
                                "cityName": "博爱县",
                                "parentId": "410800",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "焦作市",
                                "county": "博爱县",

                            },
                            {
                                "cityCode": "410823",
                                "cityName": "武陟县",
                                "parentId": "410800",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "焦作市",
                                "county": "武陟县",

                            },
                            {
                                "cityCode": "410825",
                                "cityName": "温县",
                                "parentId": "410800",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "焦作市",
                                "county": "温县",

                            },
                            {
                                "cityCode": "410871",
                                "cityName": "焦作城乡一体化示范区",
                                "parentId": "410800",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "焦作市",
                                "county": "焦作城乡一体化示范区",

                            },
                            {
                                "cityCode": "410882",
                                "cityName": "沁阳市",
                                "parentId": "410800",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "焦作市",
                                "county": "沁阳市",

                            },
                            {
                                "cityCode": "410883",
                                "cityName": "孟州市",
                                "parentId": "410800",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "焦作市",
                                "county": "孟州市",

                            }
                        ]
                    },
                    {
                        "cityCode": "410900",
                        "cityName": "濮阳市",
                        "parentId": "410000",
                        "provinceType": "2",
                        "province": "河南省",
                        "city": "濮阳市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "410901",
                                "cityName": "濮阳市",
                                "parentId": "410900",
                                "provinceType": "2",
                                "province": "河南省",
                                "city": "濮阳市",
                                "county": "",

                            },
                            {
                                "cityCode": "410902",
                                "cityName": "华龙区",
                                "parentId": "410900",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "濮阳市",
                                "county": "华龙区",

                            },
                            {
                                "cityCode": "410922",
                                "cityName": "清丰县",
                                "parentId": "410900",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "濮阳市",
                                "county": "清丰县",

                            },
                            {
                                "cityCode": "410923",
                                "cityName": "南乐县",
                                "parentId": "410900",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "濮阳市",
                                "county": "南乐县",

                            },
                            {
                                "cityCode": "410926",
                                "cityName": "范县",
                                "parentId": "410900",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "濮阳市",
                                "county": "范县",

                            },
                            {
                                "cityCode": "410927",
                                "cityName": "台前县",
                                "parentId": "410900",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "濮阳市",
                                "county": "台前县",

                            },
                            {
                                "cityCode": "410928",
                                "cityName": "濮阳县",
                                "parentId": "410900",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "濮阳市",
                                "county": "濮阳县",

                            },
                            {
                                "cityCode": "410971",
                                "cityName": "河南濮阳工业园区",
                                "parentId": "410900",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "濮阳市",
                                "county": "河南濮阳工业园区",

                            },
                            {
                                "cityCode": "410972",
                                "cityName": "濮阳经济技术开发区",
                                "parentId": "410900",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "濮阳市",
                                "county": "濮阳经济技术开发区",

                            }
                        ]
                    },
                    {
                        "cityCode": "411000",
                        "cityName": "许昌市",
                        "parentId": "410000",
                        "provinceType": "2",
                        "province": "河南省",
                        "city": "许昌市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "411001",
                                "cityName": "许昌市",
                                "parentId": "411000",
                                "provinceType": "2",
                                "province": "河南省",
                                "city": "许昌市",
                                "county": "",

                            },
                            {
                                "cityCode": "411002",
                                "cityName": "魏都区",
                                "parentId": "411000",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "许昌市",
                                "county": "魏都区",

                            },
                            {
                                "cityCode": "411003",
                                "cityName": "建安区",
                                "parentId": "411000",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "许昌市",
                                "county": "建安区",

                            },
                            {
                                "cityCode": "411023",
                                "cityName": "许昌县",
                                "parentId": "411000",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "许昌市",
                                "county": "许昌县",

                            },
                            {
                                "cityCode": "411024",
                                "cityName": "鄢陵县",
                                "parentId": "411000",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "许昌市",
                                "county": "鄢陵县",

                            },
                            {
                                "cityCode": "411025",
                                "cityName": "襄城县",
                                "parentId": "411000",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "许昌市",
                                "county": "襄城县",

                            },
                            {
                                "cityCode": "411071",
                                "cityName": "许昌经济技术开发区",
                                "parentId": "411000",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "许昌市",
                                "county": "许昌经济技术开发区",

                            },
                            {
                                "cityCode": "411081",
                                "cityName": "禹州市",
                                "parentId": "411000",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "许昌市",
                                "county": "禹州市",

                            },
                            {
                                "cityCode": "411082",
                                "cityName": "长葛市",
                                "parentId": "411000",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "许昌市",
                                "county": "长葛市",

                            }
                        ]
                    },
                    {
                        "cityCode": "411100",
                        "cityName": "漯河市",
                        "parentId": "410000",
                        "provinceType": "2",
                        "province": "河南省",
                        "city": "漯河市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "411101",
                                "cityName": "漯河市",
                                "parentId": "411100",
                                "provinceType": "2",
                                "province": "河南省",
                                "city": "漯河市",
                                "county": "",

                            },
                            {
                                "cityCode": "411102",
                                "cityName": "源汇区",
                                "parentId": "411100",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "漯河市",
                                "county": "源汇区",

                            },
                            {
                                "cityCode": "411103",
                                "cityName": "郾城区",
                                "parentId": "411100",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "漯河市",
                                "county": "郾城区",

                            },
                            {
                                "cityCode": "411104",
                                "cityName": "召陵区",
                                "parentId": "411100",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "漯河市",
                                "county": "召陵区",

                            },
                            {
                                "cityCode": "411121",
                                "cityName": "舞阳县",
                                "parentId": "411100",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "漯河市",
                                "county": "舞阳县",

                            },
                            {
                                "cityCode": "411122",
                                "cityName": "临颍县",
                                "parentId": "411100",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "漯河市",
                                "county": "临颍县",

                            },
                            {
                                "cityCode": "411123",
                                "cityName": "郾城区",
                                "parentId": "411100",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "漯河市",
                                "county": "郾城区",

                            },
                            {
                                "cityCode": "411171",
                                "cityName": "漯河经济技术开发区",
                                "parentId": "411100",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "漯河市",
                                "county": "漯河经济技术开发区",

                            }
                        ]
                    },
                    {
                        "cityCode": "411200",
                        "cityName": "三门峡市",
                        "parentId": "410000",
                        "provinceType": "2",
                        "province": "河南省",
                        "city": "三门峡市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "411202",
                                "cityName": "湖滨区",
                                "parentId": "411200",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "三门峡市",
                                "county": "湖滨区",

                            },
                            {
                                "cityCode": "411203",
                                "cityName": "陕州区",
                                "parentId": "411200",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "三门峡市",
                                "county": "陕州区",

                            },
                            {
                                "cityCode": "411221",
                                "cityName": "渑池县",
                                "parentId": "411200",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "三门峡市",
                                "county": "渑池县",

                            },
                            {
                                "cityCode": "411222",
                                "cityName": "陕州区",
                                "parentId": "411200",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "三门峡市",
                                "county": "陕州区",

                            },
                            {
                                "cityCode": "411224",
                                "cityName": "卢氏县",
                                "parentId": "411200",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "三门峡市",
                                "county": "卢氏县",

                            },
                            {
                                "cityCode": "411271",
                                "cityName": "河南三门峡经济开发区",
                                "parentId": "411200",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "三门峡市",
                                "county": "河南三门峡经济开发区",

                            },
                            {
                                "cityCode": "411281",
                                "cityName": "义马市",
                                "parentId": "411200",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "三门峡市",
                                "county": "义马市",

                            },
                            {
                                "cityCode": "411282",
                                "cityName": "灵宝市",
                                "parentId": "411200",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "三门峡市",
                                "county": "灵宝市",

                            }
                        ]
                    },
                    {
                        "cityCode": "411300",
                        "cityName": "南阳市",
                        "parentId": "410000",
                        "provinceType": "2",
                        "province": "河南省",
                        "city": "南阳市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "411301",
                                "cityName": "南阳市",
                                "parentId": "411300",
                                "provinceType": "2",
                                "province": "河南省",
                                "city": "南阳市",
                                "county": "",

                            },
                            {
                                "cityCode": "411302",
                                "cityName": "宛城区",
                                "parentId": "411300",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "南阳市",
                                "county": "宛城区",

                            },
                            {
                                "cityCode": "411303",
                                "cityName": "卧龙区",
                                "parentId": "411300",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "南阳市",
                                "county": "卧龙区",

                            },
                            {
                                "cityCode": "411321",
                                "cityName": "南召县",
                                "parentId": "411300",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "南阳市",
                                "county": "南召县",

                            },
                            {
                                "cityCode": "411322",
                                "cityName": "方城县",
                                "parentId": "411300",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "南阳市",
                                "county": "方城县",

                            },
                            {
                                "cityCode": "411323",
                                "cityName": "西峡县",
                                "parentId": "411300",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "南阳市",
                                "county": "西峡县",

                            },
                            {
                                "cityCode": "411324",
                                "cityName": "镇平县",
                                "parentId": "411300",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "南阳市",
                                "county": "镇平县",

                            },
                            {
                                "cityCode": "411325",
                                "cityName": "内乡县",
                                "parentId": "411300",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "南阳市",
                                "county": "内乡县",

                            },
                            {
                                "cityCode": "411326",
                                "cityName": "淅川县",
                                "parentId": "411300",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "南阳市",
                                "county": "淅川县",

                            },
                            {
                                "cityCode": "411327",
                                "cityName": "社旗县",
                                "parentId": "411300",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "南阳市",
                                "county": "社旗县",

                            },
                            {
                                "cityCode": "411328",
                                "cityName": "唐河县",
                                "parentId": "411300",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "南阳市",
                                "county": "唐河县",

                            },
                            {
                                "cityCode": "411329",
                                "cityName": "新野县",
                                "parentId": "411300",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "南阳市",
                                "county": "新野县",

                            },
                            {
                                "cityCode": "411330",
                                "cityName": "桐柏县",
                                "parentId": "411300",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "南阳市",
                                "county": "桐柏县",

                            },
                            {
                                "cityCode": "411371",
                                "cityName": "南阳高新技术产业开发区",
                                "parentId": "411300",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "南阳市",
                                "county": "南阳高新技术产业开发区",

                            },
                            {
                                "cityCode": "411372",
                                "cityName": "南阳市城乡一体化示范区",
                                "parentId": "411300",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "南阳市",
                                "county": "南阳市城乡一体化示范区",

                            },
                            {
                                "cityCode": "411381",
                                "cityName": "邓州市",
                                "parentId": "411300",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "南阳市",
                                "county": "邓州市",

                            }
                        ]
                    },
                    {
                        "cityCode": "411400",
                        "cityName": "商丘市",
                        "parentId": "410000",
                        "provinceType": "2",
                        "province": "河南省",
                        "city": "商丘市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "411401",
                                "cityName": "商丘市",
                                "parentId": "411400",
                                "provinceType": "2",
                                "province": "河南省",
                                "city": "商丘市",
                                "county": "",

                            },
                            {
                                "cityCode": "411402",
                                "cityName": "梁园区",
                                "parentId": "411400",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "商丘市",
                                "county": "梁园区",

                            },
                            {
                                "cityCode": "411403",
                                "cityName": "睢阳区",
                                "parentId": "411400",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "商丘市",
                                "county": "睢阳区",

                            },
                            {
                                "cityCode": "411421",
                                "cityName": "民权县",
                                "parentId": "411400",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "商丘市",
                                "county": "民权县",

                            },
                            {
                                "cityCode": "411422",
                                "cityName": "睢县",
                                "parentId": "411400",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "商丘市",
                                "county": "睢县",

                            },
                            {
                                "cityCode": "411423",
                                "cityName": "宁陵县",
                                "parentId": "411400",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "商丘市",
                                "county": "宁陵县",

                            },
                            {
                                "cityCode": "411424",
                                "cityName": "柘城县",
                                "parentId": "411400",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "商丘市",
                                "county": "柘城县",

                            },
                            {
                                "cityCode": "411425",
                                "cityName": "虞城县",
                                "parentId": "411400",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "商丘市",
                                "county": "虞城县",

                            },
                            {
                                "cityCode": "411426",
                                "cityName": "夏邑县",
                                "parentId": "411400",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "商丘市",
                                "county": "夏邑县",

                            },
                            {
                                "cityCode": "411471",
                                "cityName": "豫东综合物流产业聚集区",
                                "parentId": "411400",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "商丘市",
                                "county": "豫东综合物流产业聚集区",

                            },
                            {
                                "cityCode": "411472",
                                "cityName": "河南商丘经济开发区",
                                "parentId": "411400",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "商丘市",
                                "county": "河南商丘经济开发区",

                            },
                            {
                                "cityCode": "411481",
                                "cityName": "永城市",
                                "parentId": "411400",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "商丘市",
                                "county": "永城市",

                            }
                        ]
                    },
                    {
                        "cityCode": "411500",
                        "cityName": "信阳市",
                        "parentId": "410000",
                        "provinceType": "2",
                        "province": "河南省",
                        "city": "信阳市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "411501",
                                "cityName": "信阳市",
                                "parentId": "411500",
                                "provinceType": "2",
                                "province": "河南省",
                                "city": "信阳市",
                                "county": "",

                            },
                            {
                                "cityCode": "411502",
                                "cityName": "浉河区",
                                "parentId": "411500",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "信阳市",
                                "county": "浉河区",

                            },
                            {
                                "cityCode": "411503",
                                "cityName": "平桥区",
                                "parentId": "411500",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "信阳市",
                                "county": "平桥区",

                            },
                            {
                                "cityCode": "411521",
                                "cityName": "罗山县",
                                "parentId": "411500",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "信阳市",
                                "county": "罗山县",

                            },
                            {
                                "cityCode": "411522",
                                "cityName": "光山县",
                                "parentId": "411500",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "信阳市",
                                "county": "光山县",

                            },
                            {
                                "cityCode": "411523",
                                "cityName": "新县",
                                "parentId": "411500",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "信阳市",
                                "county": "新县",

                            },
                            {
                                "cityCode": "411524",
                                "cityName": "商城县",
                                "parentId": "411500",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "信阳市",
                                "county": "商城县",

                            },
                            {
                                "cityCode": "411525",
                                "cityName": "固始县",
                                "parentId": "411500",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "信阳市",
                                "county": "固始县",

                            },
                            {
                                "cityCode": "411526",
                                "cityName": "潢川县",
                                "parentId": "411500",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "信阳市",
                                "county": "潢川县",

                            },
                            {
                                "cityCode": "411527",
                                "cityName": "淮滨县",
                                "parentId": "411500",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "信阳市",
                                "county": "淮滨县",

                            },
                            {
                                "cityCode": "411528",
                                "cityName": "息县",
                                "parentId": "411500",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "信阳市",
                                "county": "息县",

                            },
                            {
                                "cityCode": "411571",
                                "cityName": "信阳高新技术产业开发区",
                                "parentId": "411500",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "信阳市",
                                "county": "信阳高新技术产业开发区",

                            }
                        ]
                    },
                    {
                        "cityCode": "411600",
                        "cityName": "周口市",
                        "parentId": "410000",
                        "provinceType": "2",
                        "province": "河南省",
                        "city": "周口市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "411601",
                                "cityName": "周口市",
                                "parentId": "411600",
                                "provinceType": "2",
                                "province": "河南省",
                                "city": "周口市",
                                "county": "",

                            },
                            {
                                "cityCode": "411602",
                                "cityName": "川汇区",
                                "parentId": "411600",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "周口市",
                                "county": "川汇区",

                            },
                            {
                                "cityCode": "411603",
                                "cityName": "淮阳区",
                                "parentId": "411600",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "周口市",
                                "county": "淮阳区",

                            },
                            {
                                "cityCode": "411621",
                                "cityName": "扶沟县",
                                "parentId": "411600",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "周口市",
                                "county": "扶沟县",

                            },
                            {
                                "cityCode": "411622",
                                "cityName": "西华县",
                                "parentId": "411600",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "周口市",
                                "county": "西华县",

                            },
                            {
                                "cityCode": "411623",
                                "cityName": "商水县",
                                "parentId": "411600",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "周口市",
                                "county": "商水县",

                            },
                            {
                                "cityCode": "411624",
                                "cityName": "沈丘县",
                                "parentId": "411600",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "周口市",
                                "county": "沈丘县",

                            },
                            {
                                "cityCode": "411625",
                                "cityName": "郸城县",
                                "parentId": "411600",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "周口市",
                                "county": "郸城县",

                            },
                            {
                                "cityCode": "411626",
                                "cityName": "淮阳县",
                                "parentId": "411600",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "周口市",
                                "county": "淮阳县",

                            },
                            {
                                "cityCode": "411627",
                                "cityName": "太康县",
                                "parentId": "411600",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "周口市",
                                "county": "太康县",

                            },
                            {
                                "cityCode": "411628",
                                "cityName": "鹿邑县",
                                "parentId": "411600",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "周口市",
                                "county": "鹿邑县",

                            },
                            {
                                "cityCode": "411671",
                                "cityName": "河南周口经济开发区",
                                "parentId": "411600",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "周口市",
                                "county": "河南周口经济开发区",

                            },
                            {
                                "cityCode": "411681",
                                "cityName": "项城市",
                                "parentId": "411600",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "周口市",
                                "county": "项城市",

                            },
                            {
                                "cityCode": "411682",
                                "cityName": "东新区",
                                "parentId": "411600",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "周口市",
                                "county": "东新区",

                            },
                            {
                                "cityCode": "411683",
                                "cityName": "经济开发区",
                                "parentId": "411600",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "周口市",
                                "county": "经济开发区",

                            }
                        ]
                    },
                    {
                        "cityCode": "411700",
                        "cityName": "驻马店市",
                        "parentId": "410000",
                        "provinceType": "2",
                        "province": "河南省",
                        "city": "驻马店市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "411701",
                                "cityName": "驻马店市",
                                "parentId": "411700",
                                "provinceType": "2",
                                "province": "河南省",
                                "city": "驻马店市",
                                "county": "",

                            },
                            {
                                "cityCode": "411702",
                                "cityName": "驿城区",
                                "parentId": "411700",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "驻马店市",
                                "county": "驿城区",

                            },
                            {
                                "cityCode": "411721",
                                "cityName": "西平县",
                                "parentId": "411700",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "驻马店市",
                                "county": "西平县",

                            },
                            {
                                "cityCode": "411722",
                                "cityName": "上蔡县",
                                "parentId": "411700",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "驻马店市",
                                "county": "上蔡县",

                            },
                            {
                                "cityCode": "411723",
                                "cityName": "平舆县",
                                "parentId": "411700",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "驻马店市",
                                "county": "平舆县",

                            },
                            {
                                "cityCode": "411724",
                                "cityName": "正阳县",
                                "parentId": "411700",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "驻马店市",
                                "county": "正阳县",

                            },
                            {
                                "cityCode": "411725",
                                "cityName": "确山县",
                                "parentId": "411700",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "驻马店市",
                                "county": "确山县",

                            },
                            {
                                "cityCode": "411726",
                                "cityName": "泌阳县",
                                "parentId": "411700",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "驻马店市",
                                "county": "泌阳县",

                            },
                            {
                                "cityCode": "411727",
                                "cityName": "汝南县",
                                "parentId": "411700",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "驻马店市",
                                "county": "汝南县",

                            },
                            {
                                "cityCode": "411728",
                                "cityName": "遂平县",
                                "parentId": "411700",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "驻马店市",
                                "county": "遂平县",

                            },
                            {
                                "cityCode": "411729",
                                "cityName": "新蔡县",
                                "parentId": "411700",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "驻马店市",
                                "county": "新蔡县",

                            },
                            {
                                "cityCode": "411771",
                                "cityName": "河南驻马店经济开发区",
                                "parentId": "411700",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "驻马店市",
                                "county": "河南驻马店经济开发区",

                            }
                        ]
                    },
                    {
                        "cityCode": "419000",
                        "cityName": "省直辖县级行政区划",
                        "parentId": "410000",
                        "provinceType": "2",
                        "province": "河南省",
                        "city": "省直辖县级行政区划",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "419001",
                                "cityName": "济源市",
                                "parentId": "419000",
                                "provinceType": "3",
                                "province": "河南省",
                                "city": "济源市",
                                "county": "济源市",
                                "counties": [
                                    {
                                        "cityCode": "419011",
                                        "cityName": "沁园街道",
                                        "parentId": "419001",
                                        "provinceType": "3",
                                        "province": "河南省",
                                        "city": "济源市",
                                        "county": "沁园街道",

                                    },
                                    {
                                        "cityCode": "419012",
                                        "cityName": "济水街道",
                                        "parentId": "419001",
                                        "provinceType": "3",
                                        "province": "河南省",
                                        "city": "济源市",
                                        "county": "济水街道",

                                    },
                                    {
                                        "cityCode": "419013",
                                        "cityName": "北海街道",
                                        "parentId": "419001",
                                        "provinceType": "3",
                                        "province": "河南省",
                                        "city": "济源市",
                                        "county": "北海街道",

                                    },
                                    {
                                        "cityCode": "419014",
                                        "cityName": "天坛街道",
                                        "parentId": "419001",
                                        "provinceType": "3",
                                        "province": "河南省",
                                        "city": "济源市",
                                        "county": "天坛街道",

                                    },
                                    {
                                        "cityCode": "419015",
                                        "cityName": "玉泉街道",
                                        "parentId": "419001",
                                        "provinceType": "3",
                                        "province": "河南省",
                                        "city": "济源市",
                                        "county": "玉泉街道",

                                    },
                                    {
                                        "cityCode": "419016",
                                        "cityName": "克井镇",
                                        "parentId": "419001",
                                        "provinceType": "3",
                                        "province": "河南省",
                                        "city": "济源市",
                                        "county": "克井镇",

                                    },
                                    {
                                        "cityCode": "419017",
                                        "cityName": "五龙口镇",
                                        "parentId": "419001",
                                        "provinceType": "3",
                                        "province": "河南省",
                                        "city": "济源市",
                                        "county": "五龙口镇",

                                    },
                                    {
                                        "cityCode": "419018",
                                        "cityName": "梨林镇",
                                        "parentId": "419001",
                                        "provinceType": "3",
                                        "province": "河南省",
                                        "city": "济源市",
                                        "county": "梨林镇",

                                    },
                                    {
                                        "cityCode": "419019",
                                        "cityName": "轵城镇",
                                        "parentId": "419001",
                                        "provinceType": "3",
                                        "province": "河南省",
                                        "city": "济源市",
                                        "county": "轵城镇",

                                    },
                                    {
                                        "cityCode": "419020",
                                        "cityName": "承留镇",
                                        "parentId": "419001",
                                        "provinceType": "3",
                                        "province": "河南省",
                                        "city": "济源市",
                                        "county": "承留镇",

                                    },
                                    {
                                        "cityCode": "419021",
                                        "cityName": "坡头镇",
                                        "parentId": "419001",
                                        "provinceType": "3",
                                        "province": "河南省",
                                        "city": "济源市",
                                        "county": "坡头镇",

                                    },
                                    {
                                        "cityCode": "419022",
                                        "cityName": "大峪镇",
                                        "parentId": "419001",
                                        "provinceType": "3",
                                        "province": "河南省",
                                        "city": "济源市",
                                        "county": "大峪镇",

                                    },
                                    {
                                        "cityCode": "419023",
                                        "cityName": "邵原镇",
                                        "parentId": "419001",
                                        "provinceType": "3",
                                        "province": "河南省",
                                        "city": "济源市",
                                        "county": "邵原镇",

                                    },
                                    {
                                        "cityCode": "419024",
                                        "cityName": "思礼镇",
                                        "parentId": "419001",
                                        "provinceType": "3",
                                        "province": "河南省",
                                        "city": "济源市",
                                        "county": "思礼镇",

                                    },
                                    {
                                        "cityCode": "419025",
                                        "cityName": "王屋镇",
                                        "parentId": "419001",
                                        "provinceType": "3",
                                        "province": "河南省",
                                        "city": "济源市",
                                        "county": "王屋镇",

                                    },
                                    {
                                        "cityCode": "419026",
                                        "cityName": "下冶镇",
                                        "parentId": "419001",
                                        "provinceType": "3",
                                        "province": "河南省",
                                        "city": "济源市",
                                        "county": "下冶镇",

                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "cityCode": "420000",
                "cityName": "湖北省",
                "parentId": "100000",
                "provinceType": "1",
                "province": "湖北省",
                "city": "",
                "county": "",
                "counties": [
                    {
                        "cityCode": "420100",
                        "cityName": "武汉市",
                        "parentId": "420000",
                        "provinceType": "2",
                        "province": "湖北省",
                        "city": "武汉市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "420101",
                                "cityName": "武汉市",
                                "parentId": "420100",
                                "provinceType": "2",
                                "province": "湖北省",
                                "city": "武汉市",
                                "county": "",

                            },
                            {
                                "cityCode": "420102",
                                "cityName": "江岸区",
                                "parentId": "420100",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "武汉市",
                                "county": "江岸区",

                            },
                            {
                                "cityCode": "420103",
                                "cityName": "江汉区",
                                "parentId": "420100",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "武汉市",
                                "county": "江汉区",

                            },
                            {
                                "cityCode": "420104",
                                "cityName": "硚口区",
                                "parentId": "420100",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "武汉市",
                                "county": "硚口区",

                            },
                            {
                                "cityCode": "420105",
                                "cityName": "汉阳区",
                                "parentId": "420100",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "武汉市",
                                "county": "汉阳区",

                            },
                            {
                                "cityCode": "420106",
                                "cityName": "武昌区",
                                "parentId": "420100",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "武汉市",
                                "county": "武昌区",

                            },
                            {
                                "cityCode": "420107",
                                "cityName": "青山区",
                                "parentId": "420100",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "武汉市",
                                "county": "青山区",

                            },
                            {
                                "cityCode": "420111",
                                "cityName": "洪山区",
                                "parentId": "420100",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "武汉市",
                                "county": "洪山区",

                            },
                            {
                                "cityCode": "420112",
                                "cityName": "东西湖区",
                                "parentId": "420100",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "武汉市",
                                "county": "东西湖区",

                            },
                            {
                                "cityCode": "420113",
                                "cityName": "汉南区",
                                "parentId": "420100",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "武汉市",
                                "county": "汉南区",

                            },
                            {
                                "cityCode": "420114",
                                "cityName": "蔡甸区",
                                "parentId": "420100",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "武汉市",
                                "county": "蔡甸区",

                            },
                            {
                                "cityCode": "420115",
                                "cityName": "江夏区",
                                "parentId": "420100",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "武汉市",
                                "county": "江夏区",

                            },
                            {
                                "cityCode": "420116",
                                "cityName": "黄陂区",
                                "parentId": "420100",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "武汉市",
                                "county": "黄陂区",

                            },
                            {
                                "cityCode": "420117",
                                "cityName": "新洲区",
                                "parentId": "420100",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "武汉市",
                                "county": "新洲区",

                            },
                            {
                                "cityCode": "420118",
                                "cityName": "经济技术开发区",
                                "parentId": "420100",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "武汉市",
                                "county": "经济技术开发区",

                            }
                        ]
                    },
                    {
                        "cityCode": "420200",
                        "cityName": "黄石市",
                        "parentId": "420000",
                        "provinceType": "2",
                        "province": "湖北省",
                        "city": "黄石市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "420201",
                                "cityName": "黄石市",
                                "parentId": "420200",
                                "provinceType": "2",
                                "province": "湖北省",
                                "city": "黄石市",
                                "county": "",

                            },
                            {
                                "cityCode": "420202",
                                "cityName": "黄石港区",
                                "parentId": "420200",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "黄石市",
                                "county": "黄石港区",

                            },
                            {
                                "cityCode": "420203",
                                "cityName": "西塞山区",
                                "parentId": "420200",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "黄石市",
                                "county": "西塞山区",

                            },
                            {
                                "cityCode": "420204",
                                "cityName": "下陆区",
                                "parentId": "420200",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "黄石市",
                                "county": "下陆区",

                            },
                            {
                                "cityCode": "420205",
                                "cityName": "铁山区",
                                "parentId": "420200",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "黄石市",
                                "county": "铁山区",

                            },
                            {
                                "cityCode": "420206",
                                "cityName": "团城山开发区",
                                "parentId": "420200",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "黄石市",
                                "county": "团城山开发区",

                            },
                            {
                                "cityCode": "420222",
                                "cityName": "阳新县",
                                "parentId": "420200",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "黄石市",
                                "county": "阳新县",

                            },
                            {
                                "cityCode": "420281",
                                "cityName": "大冶市",
                                "parentId": "420200",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "黄石市",
                                "county": "大冶市",

                            },
                            {
                                "cityCode": "420282",
                                "cityName": "经济开发区",
                                "parentId": "420200",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "黄石市",
                                "county": "经济开发区",

                            }
                        ]
                    },
                    {
                        "cityCode": "420300",
                        "cityName": "十堰市",
                        "parentId": "420000",
                        "provinceType": "2",
                        "province": "湖北省",
                        "city": "十堰市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "420301",
                                "cityName": "十堰市",
                                "parentId": "420300",
                                "provinceType": "2",
                                "province": "湖北省",
                                "city": "十堰市",
                                "county": "",

                            },
                            {
                                "cityCode": "420302",
                                "cityName": "茅箭区",
                                "parentId": "420300",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "十堰市",
                                "county": "茅箭区",

                            },
                            {
                                "cityCode": "420303",
                                "cityName": "张湾区",
                                "parentId": "420300",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "十堰市",
                                "county": "张湾区",

                            },
                            {
                                "cityCode": "420304",
                                "cityName": "郧阳区",
                                "parentId": "420300",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "十堰市",
                                "county": "郧阳区",

                            },
                            {
                                "cityCode": "420321",
                                "cityName": "郧县",
                                "parentId": "420300",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "十堰市",
                                "county": "郧县",

                            },
                            {
                                "cityCode": "420322",
                                "cityName": "郧西县",
                                "parentId": "420300",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "十堰市",
                                "county": "郧西县",

                            },
                            {
                                "cityCode": "420323",
                                "cityName": "竹山县",
                                "parentId": "420300",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "十堰市",
                                "county": "竹山县",

                            },
                            {
                                "cityCode": "420324",
                                "cityName": "竹溪县",
                                "parentId": "420300",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "十堰市",
                                "county": "竹溪县",

                            },
                            {
                                "cityCode": "420325",
                                "cityName": "房县",
                                "parentId": "420300",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "十堰市",
                                "county": "房县",

                            },
                            {
                                "cityCode": "420381",
                                "cityName": "丹江口市",
                                "parentId": "420300",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "十堰市",
                                "county": "丹江口市",

                            }
                        ]
                    },
                    {
                        "cityCode": "420500",
                        "cityName": "宜昌市",
                        "parentId": "420000",
                        "provinceType": "2",
                        "province": "湖北省",
                        "city": "宜昌市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "420501",
                                "cityName": "宜昌市",
                                "parentId": "420500",
                                "provinceType": "2",
                                "province": "湖北省",
                                "city": "宜昌市",
                                "county": "",

                            },
                            {
                                "cityCode": "420502",
                                "cityName": "西陵区",
                                "parentId": "420500",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "宜昌市",
                                "county": "西陵区",

                            },
                            {
                                "cityCode": "420503",
                                "cityName": "伍家岗区",
                                "parentId": "420500",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "宜昌市",
                                "county": "伍家岗区",

                            },
                            {
                                "cityCode": "420504",
                                "cityName": "点军区",
                                "parentId": "420500",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "宜昌市",
                                "county": "点军区",

                            },
                            {
                                "cityCode": "420505",
                                "cityName": "猇亭区",
                                "parentId": "420500",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "宜昌市",
                                "county": "猇亭区",

                            },
                            {
                                "cityCode": "420506",
                                "cityName": "夷陵区",
                                "parentId": "420500",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "宜昌市",
                                "county": "夷陵区",

                            },
                            {
                                "cityCode": "420525",
                                "cityName": "远安县",
                                "parentId": "420500",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "宜昌市",
                                "county": "远安县",

                            },
                            {
                                "cityCode": "420526",
                                "cityName": "兴山县",
                                "parentId": "420500",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "宜昌市",
                                "county": "兴山县",

                            },
                            {
                                "cityCode": "420527",
                                "cityName": "秭归县",
                                "parentId": "420500",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "宜昌市",
                                "county": "秭归县",

                            },
                            {
                                "cityCode": "420528",
                                "cityName": "长阳土家族自治县",
                                "parentId": "420500",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "宜昌市",
                                "county": "长阳土家族自治县",

                            },
                            {
                                "cityCode": "420529",
                                "cityName": "五峰土家族自治县",
                                "parentId": "420500",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "宜昌市",
                                "county": "五峰土家族自治县",

                            },
                            {
                                "cityCode": "420581",
                                "cityName": "宜都市",
                                "parentId": "420500",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "宜昌市",
                                "county": "宜都市",

                            },
                            {
                                "cityCode": "420582",
                                "cityName": "当阳市",
                                "parentId": "420500",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "宜昌市",
                                "county": "当阳市",

                            },
                            {
                                "cityCode": "420583",
                                "cityName": "枝江市",
                                "parentId": "420500",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "宜昌市",
                                "county": "枝江市",

                            },
                            {
                                "cityCode": "420584",
                                "cityName": "宜昌新区",
                                "parentId": "420500",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "宜昌市",
                                "county": "宜昌新区",

                            }
                        ]
                    },
                    {
                        "cityCode": "420600",
                        "cityName": "襄阳市",
                        "parentId": "420000",
                        "provinceType": "2",
                        "province": "湖北省",
                        "city": "襄阳市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "420601",
                                "cityName": "襄阳市",
                                "parentId": "420600",
                                "provinceType": "2",
                                "province": "湖北省",
                                "city": "襄阳市",
                                "county": "",

                            },
                            {
                                "cityCode": "420602",
                                "cityName": "襄城区",
                                "parentId": "420600",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "襄阳市",
                                "county": "襄城区",

                            },
                            {
                                "cityCode": "420606",
                                "cityName": "樊城区",
                                "parentId": "420600",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "襄阳市",
                                "county": "樊城区",

                            },
                            {
                                "cityCode": "420607",
                                "cityName": "襄州区",
                                "parentId": "420600",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "襄阳市",
                                "county": "襄州区",

                            },
                            {
                                "cityCode": "420624",
                                "cityName": "南漳县",
                                "parentId": "420600",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "襄阳市",
                                "county": "南漳县",

                            },
                            {
                                "cityCode": "420625",
                                "cityName": "谷城县",
                                "parentId": "420600",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "襄阳市",
                                "county": "谷城县",

                            },
                            {
                                "cityCode": "420626",
                                "cityName": "保康县",
                                "parentId": "420600",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "襄阳市",
                                "county": "保康县",

                            },
                            {
                                "cityCode": "420682",
                                "cityName": "老河口市",
                                "parentId": "420600",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "襄阳市",
                                "county": "老河口市",

                            },
                            {
                                "cityCode": "420683",
                                "cityName": "枣阳市",
                                "parentId": "420600",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "襄阳市",
                                "county": "枣阳市",

                            },
                            {
                                "cityCode": "420684",
                                "cityName": "宜城市",
                                "parentId": "420600",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "襄阳市",
                                "county": "宜城市",

                            },
                            {
                                "cityCode": "420685",
                                "cityName": "高新区",
                                "parentId": "420600",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "襄阳市",
                                "county": "高新区",

                            },
                            {
                                "cityCode": "420686",
                                "cityName": "经济开发区",
                                "parentId": "420600",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "襄阳市",
                                "county": "经济开发区",

                            }
                        ]
                    },
                    {
                        "cityCode": "420700",
                        "cityName": "鄂州市",
                        "parentId": "420000",
                        "provinceType": "2",
                        "province": "湖北省",
                        "city": "鄂州市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "420701",
                                "cityName": "鄂州市",
                                "parentId": "420700",
                                "provinceType": "2",
                                "province": "湖北省",
                                "city": "鄂州市",
                                "county": "",

                            },
                            {
                                "cityCode": "420702",
                                "cityName": "梁子湖区",
                                "parentId": "420700",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "鄂州市",
                                "county": "梁子湖区",

                            },
                            {
                                "cityCode": "420703",
                                "cityName": "华容区",
                                "parentId": "420700",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "鄂州市",
                                "county": "华容区",

                            },
                            {
                                "cityCode": "420704",
                                "cityName": "鄂城区",
                                "parentId": "420700",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "鄂州市",
                                "county": "鄂城区",

                            }
                        ]
                    },
                    {
                        "cityCode": "420800",
                        "cityName": "荆门市",
                        "parentId": "420000",
                        "provinceType": "2",
                        "province": "湖北省",
                        "city": "荆门市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "420801",
                                "cityName": "荆门市",
                                "parentId": "420800",
                                "provinceType": "2",
                                "province": "湖北省",
                                "city": "荆门市",
                                "county": "",

                            },
                            {
                                "cityCode": "420802",
                                "cityName": "东宝区",
                                "parentId": "420800",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "荆门市",
                                "county": "东宝区",

                            },
                            {
                                "cityCode": "420804",
                                "cityName": "掇刀区",
                                "parentId": "420800",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "荆门市",
                                "county": "掇刀区",

                            },
                            {
                                "cityCode": "420821",
                                "cityName": "京山县",
                                "parentId": "420800",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "荆门市",
                                "county": "京山县",

                            },
                            {
                                "cityCode": "420822",
                                "cityName": "沙洋县",
                                "parentId": "420800",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "荆门市",
                                "county": "沙洋县",

                            },
                            {
                                "cityCode": "420881",
                                "cityName": "钟祥市",
                                "parentId": "420800",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "荆门市",
                                "county": "钟祥市",

                            },
                            {
                                "cityCode": "420882",
                                "cityName": "京山市",
                                "parentId": "420800",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "荆门市",
                                "county": "京山市",

                            }
                        ]
                    },
                    {
                        "cityCode": "420900",
                        "cityName": "孝感市",
                        "parentId": "420000",
                        "provinceType": "2",
                        "province": "湖北省",
                        "city": "孝感市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "420901",
                                "cityName": "孝感市",
                                "parentId": "420900",
                                "provinceType": "2",
                                "province": "湖北省",
                                "city": "孝感市",
                                "county": "",

                            },
                            {
                                "cityCode": "420902",
                                "cityName": "孝南区",
                                "parentId": "420900",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "孝感市",
                                "county": "孝南区",

                            },
                            {
                                "cityCode": "420921",
                                "cityName": "孝昌县",
                                "parentId": "420900",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "孝感市",
                                "county": "孝昌县",

                            },
                            {
                                "cityCode": "420922",
                                "cityName": "大悟县",
                                "parentId": "420900",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "孝感市",
                                "county": "大悟县",

                            },
                            {
                                "cityCode": "420923",
                                "cityName": "云梦县",
                                "parentId": "420900",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "孝感市",
                                "county": "云梦县",

                            },
                            {
                                "cityCode": "420981",
                                "cityName": "应城市",
                                "parentId": "420900",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "孝感市",
                                "county": "应城市",

                            },
                            {
                                "cityCode": "420982",
                                "cityName": "安陆市",
                                "parentId": "420900",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "孝感市",
                                "county": "安陆市",

                            },
                            {
                                "cityCode": "420984",
                                "cityName": "汉川市",
                                "parentId": "420900",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "孝感市",
                                "county": "汉川市",

                            }
                        ]
                    },
                    {
                        "cityCode": "421000",
                        "cityName": "荆州市",
                        "parentId": "420000",
                        "provinceType": "2",
                        "province": "湖北省",
                        "city": "荆州市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "421001",
                                "cityName": "荆州市",
                                "parentId": "421000",
                                "provinceType": "2",
                                "province": "湖北省",
                                "city": "荆州市",
                                "county": "",

                            },
                            {
                                "cityCode": "421002",
                                "cityName": "沙市区",
                                "parentId": "421000",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "荆州市",
                                "county": "沙市区",

                            },
                            {
                                "cityCode": "421003",
                                "cityName": "荆州区",
                                "parentId": "421000",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "荆州市",
                                "county": "荆州区",

                            },
                            {
                                "cityCode": "421022",
                                "cityName": "公安县",
                                "parentId": "421000",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "荆州市",
                                "county": "公安县",

                            },
                            {
                                "cityCode": "421024",
                                "cityName": "江陵县",
                                "parentId": "421000",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "荆州市",
                                "county": "江陵县",

                            },
                            {
                                "cityCode": "421071",
                                "cityName": "荆州经济技术开发区",
                                "parentId": "421000",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "荆州市",
                                "county": "荆州经济技术开发区",

                            },
                            {
                                "cityCode": "421081",
                                "cityName": "石首市",
                                "parentId": "421000",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "荆州市",
                                "county": "石首市",

                            },
                            {
                                "cityCode": "421083",
                                "cityName": "洪湖市",
                                "parentId": "421000",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "荆州市",
                                "county": "洪湖市",

                            },
                            {
                                "cityCode": "421087",
                                "cityName": "松滋市",
                                "parentId": "421000",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "荆州市",
                                "county": "松滋市",

                            },
                            {
                                "cityCode": "421088",
                                "cityName": "监利市",
                                "parentId": "421000",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "荆州市",
                                "county": "监利市",

                            }
                        ]
                    },
                    {
                        "cityCode": "421100",
                        "cityName": "黄冈市",
                        "parentId": "420000",
                        "provinceType": "2",
                        "province": "湖北省",
                        "city": "黄冈市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "421101",
                                "cityName": "黄冈市",
                                "parentId": "421100",
                                "provinceType": "2",
                                "province": "湖北省",
                                "city": "黄冈市",
                                "county": "",

                            },
                            {
                                "cityCode": "421102",
                                "cityName": "黄州区",
                                "parentId": "421100",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "黄冈市",
                                "county": "黄州区",

                            },
                            {
                                "cityCode": "421121",
                                "cityName": "团风县",
                                "parentId": "421100",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "黄冈市",
                                "county": "团风县",

                            },
                            {
                                "cityCode": "421122",
                                "cityName": "红安县",
                                "parentId": "421100",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "黄冈市",
                                "county": "红安县",

                            },
                            {
                                "cityCode": "421123",
                                "cityName": "罗田县",
                                "parentId": "421100",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "黄冈市",
                                "county": "罗田县",

                            },
                            {
                                "cityCode": "421124",
                                "cityName": "英山县",
                                "parentId": "421100",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "黄冈市",
                                "county": "英山县",

                            },
                            {
                                "cityCode": "421125",
                                "cityName": "浠水县",
                                "parentId": "421100",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "黄冈市",
                                "county": "浠水县",

                            },
                            {
                                "cityCode": "421126",
                                "cityName": "蕲春县",
                                "parentId": "421100",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "黄冈市",
                                "county": "蕲春县",

                            },
                            {
                                "cityCode": "421127",
                                "cityName": "黄梅县",
                                "parentId": "421100",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "黄冈市",
                                "county": "黄梅县",

                            },
                            {
                                "cityCode": "421171",
                                "cityName": "龙感湖管理区",
                                "parentId": "421100",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "黄冈市",
                                "county": "龙感湖管理区",

                            },
                            {
                                "cityCode": "421181",
                                "cityName": "麻城市",
                                "parentId": "421100",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "黄冈市",
                                "county": "麻城市",

                            },
                            {
                                "cityCode": "421182",
                                "cityName": "武穴市",
                                "parentId": "421100",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "黄冈市",
                                "county": "武穴市",

                            }
                        ]
                    },
                    {
                        "cityCode": "421200",
                        "cityName": "咸宁市",
                        "parentId": "420000",
                        "provinceType": "2",
                        "province": "湖北省",
                        "city": "咸宁市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "421201",
                                "cityName": "咸宁市",
                                "parentId": "421200",
                                "provinceType": "2",
                                "province": "湖北省",
                                "city": "咸宁市",
                                "county": "",

                            },
                            {
                                "cityCode": "421202",
                                "cityName": "咸安区",
                                "parentId": "421200",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "咸宁市",
                                "county": "咸安区",

                            },
                            {
                                "cityCode": "421221",
                                "cityName": "嘉鱼县",
                                "parentId": "421200",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "咸宁市",
                                "county": "嘉鱼县",

                            },
                            {
                                "cityCode": "421222",
                                "cityName": "通城县",
                                "parentId": "421200",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "咸宁市",
                                "county": "通城县",

                            },
                            {
                                "cityCode": "421223",
                                "cityName": "崇阳县",
                                "parentId": "421200",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "咸宁市",
                                "county": "崇阳县",

                            },
                            {
                                "cityCode": "421224",
                                "cityName": "通山县",
                                "parentId": "421200",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "咸宁市",
                                "county": "通山县",

                            },
                            {
                                "cityCode": "421281",
                                "cityName": "赤壁市",
                                "parentId": "421200",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "咸宁市",
                                "county": "赤壁市",

                            }
                        ]
                    },
                    {
                        "cityCode": "421300",
                        "cityName": "随州市",
                        "parentId": "420000",
                        "provinceType": "2",
                        "province": "湖北省",
                        "city": "随州市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "421301",
                                "cityName": "随州市",
                                "parentId": "421300",
                                "provinceType": "2",
                                "province": "湖北省",
                                "city": "随州市",
                                "county": "",

                            },
                            {
                                "cityCode": "421302",
                                "cityName": "曾都区",
                                "parentId": "421300",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "随州市",
                                "county": "曾都区",

                            },
                            {
                                "cityCode": "421303",
                                "cityName": "曾都区",
                                "parentId": "421300",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "随州市",
                                "county": "曾都区",

                            },
                            {
                                "cityCode": "421321",
                                "cityName": "随县",
                                "parentId": "421300",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "随州市",
                                "county": "随县",

                            },
                            {
                                "cityCode": "421381",
                                "cityName": "广水市",
                                "parentId": "421300",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "随州市",
                                "county": "广水市",

                            }
                        ]
                    },
                    {
                        "cityCode": "422800",
                        "cityName": "恩施土家族苗族自治州",
                        "parentId": "420000",
                        "provinceType": "2",
                        "province": "湖北省",
                        "city": "恩施土家族苗族自治州",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "422801",
                                "cityName": "恩施市",
                                "parentId": "422800",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "恩施土家族苗族自治州",
                                "county": "恩施市",

                            },
                            {
                                "cityCode": "422802",
                                "cityName": "利川市",
                                "parentId": "422800",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "恩施土家族苗族自治州",
                                "county": "利川市",

                            },
                            {
                                "cityCode": "422822",
                                "cityName": "建始县",
                                "parentId": "422800",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "恩施土家族苗族自治州",
                                "county": "建始县",

                            },
                            {
                                "cityCode": "422823",
                                "cityName": "巴东县",
                                "parentId": "422800",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "恩施土家族苗族自治州",
                                "county": "巴东县",

                            },
                            {
                                "cityCode": "422825",
                                "cityName": "宣恩县",
                                "parentId": "422800",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "恩施土家族苗族自治州",
                                "county": "宣恩县",

                            },
                            {
                                "cityCode": "422826",
                                "cityName": "咸丰县",
                                "parentId": "422800",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "恩施土家族苗族自治州",
                                "county": "咸丰县",

                            },
                            {
                                "cityCode": "422827",
                                "cityName": "来凤县",
                                "parentId": "422800",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "恩施土家族苗族自治州",
                                "county": "来凤县",

                            },
                            {
                                "cityCode": "422828",
                                "cityName": "鹤峰县",
                                "parentId": "422800",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "恩施土家族苗族自治州",
                                "county": "鹤峰县",

                            }
                        ]
                    },
                    {
                        "cityCode": "429000",
                        "cityName": "省直辖县级行政区划",
                        "parentId": "420000",
                        "provinceType": "2",
                        "province": "湖北省",
                        "city": "省直辖县级行政区划",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "429004",
                                "cityName": "仙桃市",
                                "parentId": "429000",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "仙桃市",
                                "county": "仙桃市",
                                "counties": [
                                    {
                                        "cityCode": "429401",
                                        "cityName": "沙嘴街道",
                                        "parentId": "429004",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "仙桃市",
                                        "county": "沙嘴街道",

                                    },
                                    {
                                        "cityCode": "429402",
                                        "cityName": "干河街道",
                                        "parentId": "429004",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "仙桃市",
                                        "county": "干河街道",

                                    },
                                    {
                                        "cityCode": "429403",
                                        "cityName": "龙华山街道",
                                        "parentId": "429004",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "仙桃市",
                                        "county": "龙华山街道",

                                    },
                                    {
                                        "cityCode": "429404",
                                        "cityName": "郑场镇",
                                        "parentId": "429004",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "仙桃市",
                                        "county": "郑场镇",

                                    },
                                    {
                                        "cityCode": "429405",
                                        "cityName": "毛嘴镇",
                                        "parentId": "429004",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "仙桃市",
                                        "county": "毛嘴镇",

                                    },
                                    {
                                        "cityCode": "429406",
                                        "cityName": "豆河镇",
                                        "parentId": "429004",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "仙桃市",
                                        "county": "豆河镇",

                                    },
                                    {
                                        "cityCode": "429407",
                                        "cityName": "三伏潭镇",
                                        "parentId": "429004",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "仙桃市",
                                        "county": "三伏潭镇",

                                    },
                                    {
                                        "cityCode": "429408",
                                        "cityName": "胡场镇",
                                        "parentId": "429004",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "仙桃市",
                                        "county": "胡场镇",

                                    },
                                    {
                                        "cityCode": "429409",
                                        "cityName": "长埫口镇",
                                        "parentId": "429004",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "仙桃市",
                                        "county": "长埫口镇",

                                    },
                                    {
                                        "cityCode": "429410",
                                        "cityName": "西流河镇",
                                        "parentId": "429004",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "仙桃市",
                                        "county": "西流河镇",

                                    },
                                    {
                                        "cityCode": "429411",
                                        "cityName": "沙湖镇",
                                        "parentId": "429004",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "仙桃市",
                                        "county": "沙湖镇",

                                    },
                                    {
                                        "cityCode": "429412",
                                        "cityName": "杨林尾镇",
                                        "parentId": "429004",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "仙桃市",
                                        "county": "杨林尾镇",

                                    },
                                    {
                                        "cityCode": "429413",
                                        "cityName": "彭场镇",
                                        "parentId": "429004",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "仙桃市",
                                        "county": "彭场镇",

                                    },
                                    {
                                        "cityCode": "429414",
                                        "cityName": "张沟镇",
                                        "parentId": "429004",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "仙桃市",
                                        "county": "张沟镇",

                                    },
                                    {
                                        "cityCode": "429415",
                                        "cityName": "郭河镇",
                                        "parentId": "429004",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "仙桃市",
                                        "county": "郭河镇",

                                    },
                                    {
                                        "cityCode": "429416",
                                        "cityName": "沔城镇",
                                        "parentId": "429004",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "仙桃市",
                                        "county": "沔城镇",

                                    },
                                    {
                                        "cityCode": "429417",
                                        "cityName": "通海口镇",
                                        "parentId": "429004",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "仙桃市",
                                        "county": "通海口镇",

                                    },
                                    {
                                        "cityCode": "429418",
                                        "cityName": "陈场镇",
                                        "parentId": "429004",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "仙桃市",
                                        "county": "陈场镇",

                                    },
                                    {
                                        "cityCode": "429419",
                                        "cityName": "高新区",
                                        "parentId": "429004",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "仙桃市",
                                        "county": "高新区",

                                    },
                                    {
                                        "cityCode": "429420",
                                        "cityName": "经济开发区",
                                        "parentId": "429004",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "仙桃市",
                                        "county": "经济开发区",

                                    },
                                    {
                                        "cityCode": "429421",
                                        "cityName": "工业园区",
                                        "parentId": "429004",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "仙桃市",
                                        "county": "工业园区",

                                    },
                                    {
                                        "cityCode": "429422",
                                        "cityName": "九合垸原种场",
                                        "parentId": "429004",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "仙桃市",
                                        "county": "九合垸原种场",

                                    },
                                    {
                                        "cityCode": "429423",
                                        "cityName": "沙湖原种场",
                                        "parentId": "429004",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "仙桃市",
                                        "county": "沙湖原种场",

                                    },
                                    {
                                        "cityCode": "429424",
                                        "cityName": "排湖渔场",
                                        "parentId": "429004",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "仙桃市",
                                        "county": "排湖渔场",

                                    },
                                    {
                                        "cityCode": "429425",
                                        "cityName": "五湖渔场",
                                        "parentId": "429004",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "仙桃市",
                                        "county": "五湖渔场",

                                    },
                                    {
                                        "cityCode": "429426",
                                        "cityName": "赵西垸林场",
                                        "parentId": "429004",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "仙桃市",
                                        "county": "赵西垸林场",

                                    },
                                    {
                                        "cityCode": "429427",
                                        "cityName": "刘家垸林场",
                                        "parentId": "429004",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "仙桃市",
                                        "county": "刘家垸林场",

                                    },
                                    {
                                        "cityCode": "429428",
                                        "cityName": "畜禽良种场",
                                        "parentId": "429004",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "仙桃市",
                                        "county": "畜禽良种场",

                                    }
                                ]
                            },
                            {
                                "cityCode": "429005",
                                "cityName": "潜江市",
                                "parentId": "429000",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "潜江市",
                                "county": "潜江市",
                                "counties": [
                                    {
                                        "cityCode": "429501",
                                        "cityName": "园林",
                                        "parentId": "429005",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "潜江市",
                                        "county": "园林",

                                    },
                                    {
                                        "cityCode": "429502",
                                        "cityName": "广华",
                                        "parentId": "429005",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "潜江市",
                                        "county": "广华",

                                    },
                                    {
                                        "cityCode": "429503",
                                        "cityName": "杨市",
                                        "parentId": "429005",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "潜江市",
                                        "county": "杨市",

                                    },
                                    {
                                        "cityCode": "429504",
                                        "cityName": "周矶",
                                        "parentId": "429005",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "潜江市",
                                        "county": "周矶",

                                    },
                                    {
                                        "cityCode": "429505",
                                        "cityName": "泽口",
                                        "parentId": "429005",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "潜江市",
                                        "county": "泽口",

                                    },
                                    {
                                        "cityCode": "429506",
                                        "cityName": "泰丰",
                                        "parentId": "429005",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "潜江市",
                                        "county": "泰丰",

                                    },
                                    {
                                        "cityCode": "429507",
                                        "cityName": "高场",
                                        "parentId": "429005",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "潜江市",
                                        "county": "高场",

                                    },
                                    {
                                        "cityCode": "429508",
                                        "cityName": "熊口镇",
                                        "parentId": "429005",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "潜江市",
                                        "county": "熊口镇",

                                    },
                                    {
                                        "cityCode": "429509",
                                        "cityName": "竹根滩镇",
                                        "parentId": "429005",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "潜江市",
                                        "county": "竹根滩镇",

                                    },
                                    {
                                        "cityCode": "429510",
                                        "cityName": "高石碑镇",
                                        "parentId": "429005",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "潜江市",
                                        "county": "高石碑镇",

                                    },
                                    {
                                        "cityCode": "429511",
                                        "cityName": "老新镇",
                                        "parentId": "429005",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "潜江市",
                                        "county": "老新镇",

                                    },
                                    {
                                        "cityCode": "429512",
                                        "cityName": "王场镇",
                                        "parentId": "429005",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "潜江市",
                                        "county": "王场镇",

                                    },
                                    {
                                        "cityCode": "429513",
                                        "cityName": "渔洋镇",
                                        "parentId": "429005",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "潜江市",
                                        "county": "渔洋镇",

                                    },
                                    {
                                        "cityCode": "429514",
                                        "cityName": "龙湾镇",
                                        "parentId": "429005",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "潜江市",
                                        "county": "龙湾镇",

                                    },
                                    {
                                        "cityCode": "429515",
                                        "cityName": "浩口镇",
                                        "parentId": "429005",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "潜江市",
                                        "county": "浩口镇",

                                    },
                                    {
                                        "cityCode": "429516",
                                        "cityName": "积玉口镇",
                                        "parentId": "429005",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "潜江市",
                                        "county": "积玉口镇",

                                    },
                                    {
                                        "cityCode": "429517",
                                        "cityName": "张金镇",
                                        "parentId": "429005",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "潜江市",
                                        "county": "张金镇",

                                    },
                                    {
                                        "cityCode": "429518",
                                        "cityName": "白鹭湖管理区",
                                        "parentId": "429005",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "潜江市",
                                        "county": "白鹭湖管理区",

                                    },
                                    {
                                        "cityCode": "429519",
                                        "cityName": "总口管理区",
                                        "parentId": "429005",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "潜江市",
                                        "county": "总口管理区",

                                    },
                                    {
                                        "cityCode": "429520",
                                        "cityName": "熊口农场管理区",
                                        "parentId": "429005",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "潜江市",
                                        "county": "熊口农场管理区",

                                    },
                                    {
                                        "cityCode": "429521",
                                        "cityName": "运粮湖管理区",
                                        "parentId": "429005",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "潜江市",
                                        "county": "运粮湖管理区",

                                    },
                                    {
                                        "cityCode": "429522",
                                        "cityName": "后湖管理区",
                                        "parentId": "429005",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "潜江市",
                                        "county": "后湖管理区",

                                    },
                                    {
                                        "cityCode": "429523",
                                        "cityName": "周矶管理区",
                                        "parentId": "429005",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "潜江市",
                                        "county": "周矶管理区",

                                    },
                                    {
                                        "cityCode": "429524",
                                        "cityName": "经济开发区",
                                        "parentId": "429005",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "潜江市",
                                        "county": "经济开发区",

                                    }
                                ]
                            },
                            {
                                "cityCode": "429006",
                                "cityName": "天门市",
                                "parentId": "429000",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "天门市",
                                "county": "天门市",
                                "counties": [
                                    {
                                        "cityCode": "429601",
                                        "cityName": "竟陵街道",
                                        "parentId": "429006",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "天门市",
                                        "county": "竟陵街道",

                                    },
                                    {
                                        "cityCode": "429602",
                                        "cityName": "杨林街道",
                                        "parentId": "429006",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "天门市",
                                        "county": "杨林街道",

                                    },
                                    {
                                        "cityCode": "429603",
                                        "cityName": "佛子山镇",
                                        "parentId": "429006",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "天门市",
                                        "county": "佛子山镇",

                                    },
                                    {
                                        "cityCode": "429604",
                                        "cityName": "多宝镇",
                                        "parentId": "429006",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "天门市",
                                        "county": "多宝镇",

                                    },
                                    {
                                        "cityCode": "429605",
                                        "cityName": "拖市镇",
                                        "parentId": "429006",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "天门市",
                                        "county": "拖市镇",

                                    },
                                    {
                                        "cityCode": "429606",
                                        "cityName": "张港镇",
                                        "parentId": "429006",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "天门市",
                                        "county": "张港镇",

                                    },
                                    {
                                        "cityCode": "429607",
                                        "cityName": "蒋场镇",
                                        "parentId": "429006",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "天门市",
                                        "county": "蒋场镇",

                                    },
                                    {
                                        "cityCode": "429608",
                                        "cityName": "汪场镇",
                                        "parentId": "429006",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "天门市",
                                        "county": "汪场镇",

                                    },
                                    {
                                        "cityCode": "429609",
                                        "cityName": "渔薪镇",
                                        "parentId": "429006",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "天门市",
                                        "county": "渔薪镇",

                                    },
                                    {
                                        "cityCode": "429610",
                                        "cityName": "黄潭镇",
                                        "parentId": "429006",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "天门市",
                                        "county": "黄潭镇",

                                    },
                                    {
                                        "cityCode": "429611",
                                        "cityName": "岳口镇",
                                        "parentId": "429006",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "天门市",
                                        "county": "岳口镇",

                                    },
                                    {
                                        "cityCode": "429612",
                                        "cityName": "横林镇",
                                        "parentId": "429006",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "天门市",
                                        "county": "横林镇",

                                    },
                                    {
                                        "cityCode": "429613",
                                        "cityName": "彭市镇",
                                        "parentId": "429006",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "天门市",
                                        "county": "彭市镇",

                                    },
                                    {
                                        "cityCode": "429614",
                                        "cityName": "麻洋镇",
                                        "parentId": "429006",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "天门市",
                                        "county": "麻洋镇",

                                    },
                                    {
                                        "cityCode": "429615",
                                        "cityName": "多祥镇",
                                        "parentId": "429006",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "天门市",
                                        "county": "多祥镇",

                                    },
                                    {
                                        "cityCode": "429616",
                                        "cityName": "干驿镇",
                                        "parentId": "429006",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "天门市",
                                        "county": "干驿镇",

                                    },
                                    {
                                        "cityCode": "429617",
                                        "cityName": "马湾镇",
                                        "parentId": "429006",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "天门市",
                                        "county": "马湾镇",

                                    },
                                    {
                                        "cityCode": "429618",
                                        "cityName": "卢市镇",
                                        "parentId": "429006",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "天门市",
                                        "county": "卢市镇",

                                    },
                                    {
                                        "cityCode": "429619",
                                        "cityName": "小板镇",
                                        "parentId": "429006",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "天门市",
                                        "county": "小板镇",

                                    },
                                    {
                                        "cityCode": "429620",
                                        "cityName": "九真镇",
                                        "parentId": "429006",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "天门市",
                                        "county": "九真镇",

                                    },
                                    {
                                        "cityCode": "429621",
                                        "cityName": "皂市镇",
                                        "parentId": "429006",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "天门市",
                                        "county": "皂市镇",

                                    },
                                    {
                                        "cityCode": "429622",
                                        "cityName": "胡市镇",
                                        "parentId": "429006",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "天门市",
                                        "county": "胡市镇",

                                    },
                                    {
                                        "cityCode": "429623",
                                        "cityName": "石河镇",
                                        "parentId": "429006",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "天门市",
                                        "county": "石河镇",

                                    },
                                    {
                                        "cityCode": "429624",
                                        "cityName": "净潭乡",
                                        "parentId": "429006",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "天门市",
                                        "county": "净潭乡",

                                    },
                                    {
                                        "cityCode": "429625",
                                        "cityName": "蒋湖农场",
                                        "parentId": "429006",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "天门市",
                                        "county": "蒋湖农场",

                                    },
                                    {
                                        "cityCode": "429626",
                                        "cityName": "白茅湖农场",
                                        "parentId": "429006",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "天门市",
                                        "county": "白茅湖农场",

                                    },
                                    {
                                        "cityCode": "429627",
                                        "cityName": "沉湖林业科技示范区",
                                        "parentId": "429006",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "天门市",
                                        "county": "沉湖林业科技示范区",

                                    },
                                    {
                                        "cityCode": "429628",
                                        "cityName": "天门工业园",
                                        "parentId": "429006",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "天门市",
                                        "county": "天门工业园",

                                    },
                                    {
                                        "cityCode": "429629",
                                        "cityName": "侨乡街道开发区",
                                        "parentId": "429006",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "天门市",
                                        "county": "侨乡街道开发区",

                                    }
                                ]
                            },
                            {
                                "cityCode": "429021",
                                "cityName": "神农架林区",
                                "parentId": "429000",
                                "provinceType": "3",
                                "province": "湖北省",
                                "city": "神农架林区",
                                "county": "神农架林区",
                                "counties": [
                                    {
                                        "cityCode": "429022",
                                        "cityName": "松柏镇",
                                        "parentId": "429021",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "神农架林区",
                                        "county": "松柏镇",

                                    },
                                    {
                                        "cityCode": "429023",
                                        "cityName": "阳日镇",
                                        "parentId": "429021",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "神农架林区",
                                        "county": "阳日镇",

                                    },
                                    {
                                        "cityCode": "429024",
                                        "cityName": "木鱼镇",
                                        "parentId": "429021",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "神农架林区",
                                        "county": "木鱼镇",

                                    },
                                    {
                                        "cityCode": "429025",
                                        "cityName": "红坪镇",
                                        "parentId": "429021",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "神农架林区",
                                        "county": "红坪镇",

                                    },
                                    {
                                        "cityCode": "429026",
                                        "cityName": "新华镇",
                                        "parentId": "429021",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "神农架林区",
                                        "county": "新华镇",

                                    },
                                    {
                                        "cityCode": "429027",
                                        "cityName": "大九湖",
                                        "parentId": "429021",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "神农架林区",
                                        "county": "大九湖",

                                    },
                                    {
                                        "cityCode": "429028",
                                        "cityName": "宋洛",
                                        "parentId": "429021",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "神农架林区",
                                        "county": "宋洛",

                                    },
                                    {
                                        "cityCode": "429029",
                                        "cityName": "下谷坪乡",
                                        "parentId": "429021",
                                        "provinceType": "3",
                                        "province": "湖北省",
                                        "city": "神农架林区",
                                        "county": "下谷坪乡",

                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "cityCode": "430000",
                "cityName": "湖南省",
                "parentId": "100000",
                "provinceType": "1",
                "province": "湖南省",
                "city": "",
                "county": "",
                "counties": [
                    {
                        "cityCode": "430100",
                        "cityName": "长沙市",
                        "parentId": "430000",
                        "provinceType": "2",
                        "province": "湖南省",
                        "city": "长沙市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "430101",
                                "cityName": "长沙市",
                                "parentId": "430100",
                                "provinceType": "2",
                                "province": "湖南省",
                                "city": "长沙市",
                                "county": "",

                            },
                            {
                                "cityCode": "430102",
                                "cityName": "芙蓉区",
                                "parentId": "430100",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "长沙市",
                                "county": "芙蓉区",

                            },
                            {
                                "cityCode": "430103",
                                "cityName": "天心区",
                                "parentId": "430100",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "长沙市",
                                "county": "天心区",

                            },
                            {
                                "cityCode": "430104",
                                "cityName": "岳麓区",
                                "parentId": "430100",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "长沙市",
                                "county": "岳麓区",

                            },
                            {
                                "cityCode": "430105",
                                "cityName": "开福区",
                                "parentId": "430100",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "长沙市",
                                "county": "开福区",

                            },
                            {
                                "cityCode": "430111",
                                "cityName": "雨花区",
                                "parentId": "430100",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "长沙市",
                                "county": "雨花区",

                            },
                            {
                                "cityCode": "430112",
                                "cityName": "望城区",
                                "parentId": "430100",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "长沙市",
                                "county": "望城区",

                            },
                            {
                                "cityCode": "430121",
                                "cityName": "长沙县",
                                "parentId": "430100",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "长沙市",
                                "county": "长沙县",

                            },
                            {
                                "cityCode": "430122",
                                "cityName": "望城县",
                                "parentId": "430100",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "长沙市",
                                "county": "望城县",

                            },
                            {
                                "cityCode": "430124",
                                "cityName": "宁乡县",
                                "parentId": "430100",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "长沙市",
                                "county": "宁乡县",

                            },
                            {
                                "cityCode": "430181",
                                "cityName": "浏阳市",
                                "parentId": "430100",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "长沙市",
                                "county": "浏阳市",

                            },
                            {
                                "cityCode": "430182",
                                "cityName": "宁乡市",
                                "parentId": "430100",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "长沙市",
                                "county": "宁乡市",

                            }
                        ]
                    },
                    {
                        "cityCode": "430200",
                        "cityName": "株洲市",
                        "parentId": "430000",
                        "provinceType": "2",
                        "province": "湖南省",
                        "city": "株洲市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "430201",
                                "cityName": "株洲市",
                                "parentId": "430200",
                                "provinceType": "2",
                                "province": "湖南省",
                                "city": "株洲市",
                                "county": "",

                            },
                            {
                                "cityCode": "430202",
                                "cityName": "荷塘区",
                                "parentId": "430200",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "株洲市",
                                "county": "荷塘区",

                            },
                            {
                                "cityCode": "430203",
                                "cityName": "芦淞区",
                                "parentId": "430200",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "株洲市",
                                "county": "芦淞区",

                            },
                            {
                                "cityCode": "430204",
                                "cityName": "石峰区",
                                "parentId": "430200",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "株洲市",
                                "county": "石峰区",

                            },
                            {
                                "cityCode": "430211",
                                "cityName": "天元区",
                                "parentId": "430200",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "株洲市",
                                "county": "天元区",

                            },
                            {
                                "cityCode": "430212",
                                "cityName": "渌口区",
                                "parentId": "430200",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "株洲市",
                                "county": "渌口区",

                            },
                            {
                                "cityCode": "430221",
                                "cityName": "株洲县",
                                "parentId": "430200",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "株洲市",
                                "county": "株洲县",

                            },
                            {
                                "cityCode": "430223",
                                "cityName": "攸县",
                                "parentId": "430200",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "株洲市",
                                "county": "攸县",

                            },
                            {
                                "cityCode": "430224",
                                "cityName": "茶陵县",
                                "parentId": "430200",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "株洲市",
                                "county": "茶陵县",

                            },
                            {
                                "cityCode": "430225",
                                "cityName": "炎陵县",
                                "parentId": "430200",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "株洲市",
                                "county": "炎陵县",

                            },
                            {
                                "cityCode": "430271",
                                "cityName": "云龙示范区",
                                "parentId": "430200",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "株洲市",
                                "county": "云龙示范区",

                            },
                            {
                                "cityCode": "430281",
                                "cityName": "醴陵市",
                                "parentId": "430200",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "株洲市",
                                "county": "醴陵市",

                            }
                        ]
                    },
                    {
                        "cityCode": "430300",
                        "cityName": "湘潭市",
                        "parentId": "430000",
                        "provinceType": "2",
                        "province": "湖南省",
                        "city": "湘潭市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "430301",
                                "cityName": "湘潭市",
                                "parentId": "430300",
                                "provinceType": "2",
                                "province": "湖南省",
                                "city": "湘潭市",
                                "county": "",

                            },
                            {
                                "cityCode": "430302",
                                "cityName": "雨湖区",
                                "parentId": "430300",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "湘潭市",
                                "county": "雨湖区",

                            },
                            {
                                "cityCode": "430304",
                                "cityName": "岳塘区",
                                "parentId": "430300",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "湘潭市",
                                "county": "岳塘区",

                            },
                            {
                                "cityCode": "430321",
                                "cityName": "湘潭县",
                                "parentId": "430300",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "湘潭市",
                                "county": "湘潭县",

                            },
                            {
                                "cityCode": "430371",
                                "cityName": "湖南湘潭高新技术产业园区",
                                "parentId": "430300",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "湘潭市",
                                "county": "湖南湘潭高新技术产业园区",

                            },
                            {
                                "cityCode": "430372",
                                "cityName": "湘潭昭山示范区",
                                "parentId": "430300",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "湘潭市",
                                "county": "湘潭昭山示范区",

                            },
                            {
                                "cityCode": "430373",
                                "cityName": "湘潭九华示范区",
                                "parentId": "430300",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "湘潭市",
                                "county": "湘潭九华示范区",

                            },
                            {
                                "cityCode": "430381",
                                "cityName": "湘乡市",
                                "parentId": "430300",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "湘潭市",
                                "county": "湘乡市",

                            },
                            {
                                "cityCode": "430382",
                                "cityName": "韶山市",
                                "parentId": "430300",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "湘潭市",
                                "county": "韶山市",

                            },
                            {
                                "cityCode": "430383",
                                "cityName": "高新区",
                                "parentId": "430300",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "湘潭市",
                                "county": "高新区",

                            }
                        ]
                    },
                    {
                        "cityCode": "430400",
                        "cityName": "衡阳市",
                        "parentId": "430000",
                        "provinceType": "2",
                        "province": "湖南省",
                        "city": "衡阳市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "430401",
                                "cityName": "衡阳市",
                                "parentId": "430400",
                                "provinceType": "2",
                                "province": "湖南省",
                                "city": "衡阳市",
                                "county": "",

                            },
                            {
                                "cityCode": "430405",
                                "cityName": "珠晖区",
                                "parentId": "430400",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "衡阳市",
                                "county": "珠晖区",

                            },
                            {
                                "cityCode": "430406",
                                "cityName": "雁峰区",
                                "parentId": "430400",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "衡阳市",
                                "county": "雁峰区",

                            },
                            {
                                "cityCode": "430407",
                                "cityName": "石鼓区",
                                "parentId": "430400",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "衡阳市",
                                "county": "石鼓区",

                            },
                            {
                                "cityCode": "430408",
                                "cityName": "蒸湘区",
                                "parentId": "430400",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "衡阳市",
                                "county": "蒸湘区",

                            },
                            {
                                "cityCode": "430412",
                                "cityName": "南岳区",
                                "parentId": "430400",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "衡阳市",
                                "county": "南岳区",

                            },
                            {
                                "cityCode": "430421",
                                "cityName": "衡阳县",
                                "parentId": "430400",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "衡阳市",
                                "county": "衡阳县",

                            },
                            {
                                "cityCode": "430422",
                                "cityName": "衡南县",
                                "parentId": "430400",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "衡阳市",
                                "county": "衡南县",

                            },
                            {
                                "cityCode": "430423",
                                "cityName": "衡山县",
                                "parentId": "430400",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "衡阳市",
                                "county": "衡山县",

                            },
                            {
                                "cityCode": "430424",
                                "cityName": "衡东县",
                                "parentId": "430400",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "衡阳市",
                                "county": "衡东县",

                            },
                            {
                                "cityCode": "430426",
                                "cityName": "祁东县",
                                "parentId": "430400",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "衡阳市",
                                "county": "祁东县",

                            },
                            {
                                "cityCode": "430471",
                                "cityName": "衡阳综合保税区",
                                "parentId": "430400",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "衡阳市",
                                "county": "衡阳综合保税区",

                            },
                            {
                                "cityCode": "430472",
                                "cityName": "湖南衡阳高新技术产业园区",
                                "parentId": "430400",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "衡阳市",
                                "county": "湖南衡阳高新技术产业园区",

                            },
                            {
                                "cityCode": "430473",
                                "cityName": "湖南衡阳松木经济开发区",
                                "parentId": "430400",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "衡阳市",
                                "county": "湖南衡阳松木经济开发区",

                            },
                            {
                                "cityCode": "430481",
                                "cityName": "耒阳市",
                                "parentId": "430400",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "衡阳市",
                                "county": "耒阳市",

                            },
                            {
                                "cityCode": "430482",
                                "cityName": "常宁市",
                                "parentId": "430400",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "衡阳市",
                                "county": "常宁市",

                            }
                        ]
                    },
                    {
                        "cityCode": "430500",
                        "cityName": "邵阳市",
                        "parentId": "430000",
                        "provinceType": "2",
                        "province": "湖南省",
                        "city": "邵阳市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "430501",
                                "cityName": "邵阳市",
                                "parentId": "430500",
                                "provinceType": "2",
                                "province": "湖南省",
                                "city": "邵阳市",
                                "county": "",

                            },
                            {
                                "cityCode": "430502",
                                "cityName": "双清区",
                                "parentId": "430500",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "邵阳市",
                                "county": "双清区",

                            },
                            {
                                "cityCode": "430503",
                                "cityName": "大祥区",
                                "parentId": "430500",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "邵阳市",
                                "county": "大祥区",

                            },
                            {
                                "cityCode": "430511",
                                "cityName": "北塔区",
                                "parentId": "430500",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "邵阳市",
                                "county": "北塔区",

                            },
                            {
                                "cityCode": "430521",
                                "cityName": "邵东市",
                                "parentId": "430500",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "邵阳市",
                                "county": "邵东市",

                            },
                            {
                                "cityCode": "430522",
                                "cityName": "新邵县",
                                "parentId": "430500",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "邵阳市",
                                "county": "新邵县",

                            },
                            {
                                "cityCode": "430523",
                                "cityName": "邵阳县",
                                "parentId": "430500",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "邵阳市",
                                "county": "邵阳县",

                            },
                            {
                                "cityCode": "430524",
                                "cityName": "隆回县",
                                "parentId": "430500",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "邵阳市",
                                "county": "隆回县",

                            },
                            {
                                "cityCode": "430525",
                                "cityName": "洞口县",
                                "parentId": "430500",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "邵阳市",
                                "county": "洞口县",

                            },
                            {
                                "cityCode": "430527",
                                "cityName": "绥宁县",
                                "parentId": "430500",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "邵阳市",
                                "county": "绥宁县",

                            },
                            {
                                "cityCode": "430528",
                                "cityName": "新宁县",
                                "parentId": "430500",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "邵阳市",
                                "county": "新宁县",

                            },
                            {
                                "cityCode": "430529",
                                "cityName": "城步苗族自治县",
                                "parentId": "430500",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "邵阳市",
                                "county": "城步苗族自治县",

                            },
                            {
                                "cityCode": "430581",
                                "cityName": "武冈市",
                                "parentId": "430500",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "邵阳市",
                                "county": "武冈市",

                            },
                            {
                                "cityCode": "430582",
                                "cityName": "邵东市",
                                "parentId": "430500",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "邵阳市",
                                "county": "邵东市",

                            }
                        ]
                    },
                    {
                        "cityCode": "430600",
                        "cityName": "岳阳市",
                        "parentId": "430000",
                        "provinceType": "2",
                        "province": "湖南省",
                        "city": "岳阳市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "430601",
                                "cityName": "岳阳市",
                                "parentId": "430600",
                                "provinceType": "2",
                                "province": "湖南省",
                                "city": "岳阳市",
                                "county": "",

                            },
                            {
                                "cityCode": "430602",
                                "cityName": "岳阳楼区",
                                "parentId": "430600",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "岳阳市",
                                "county": "岳阳楼区",

                            },
                            {
                                "cityCode": "430603",
                                "cityName": "云溪区",
                                "parentId": "430600",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "岳阳市",
                                "county": "云溪区",

                            },
                            {
                                "cityCode": "430611",
                                "cityName": "君山区",
                                "parentId": "430600",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "岳阳市",
                                "county": "君山区",

                            },
                            {
                                "cityCode": "430621",
                                "cityName": "岳阳县",
                                "parentId": "430600",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "岳阳市",
                                "county": "岳阳县",

                            },
                            {
                                "cityCode": "430623",
                                "cityName": "华容县",
                                "parentId": "430600",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "岳阳市",
                                "county": "华容县",

                            },
                            {
                                "cityCode": "430624",
                                "cityName": "湘阴县",
                                "parentId": "430600",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "岳阳市",
                                "county": "湘阴县",

                            },
                            {
                                "cityCode": "430626",
                                "cityName": "平江县",
                                "parentId": "430600",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "岳阳市",
                                "county": "平江县",

                            },
                            {
                                "cityCode": "430671",
                                "cityName": "岳阳市屈原管理区",
                                "parentId": "430600",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "岳阳市",
                                "county": "岳阳市屈原管理区",

                            },
                            {
                                "cityCode": "430681",
                                "cityName": "汨罗市",
                                "parentId": "430600",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "岳阳市",
                                "county": "汨罗市",

                            },
                            {
                                "cityCode": "430682",
                                "cityName": "临湘市",
                                "parentId": "430600",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "岳阳市",
                                "county": "临湘市",

                            }
                        ]
                    },
                    {
                        "cityCode": "430700",
                        "cityName": "常德市",
                        "parentId": "430000",
                        "provinceType": "2",
                        "province": "湖南省",
                        "city": "常德市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "430701",
                                "cityName": "常德市",
                                "parentId": "430700",
                                "provinceType": "2",
                                "province": "湖南省",
                                "city": "常德市",
                                "county": "",

                            },
                            {
                                "cityCode": "430702",
                                "cityName": "武陵区",
                                "parentId": "430700",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "常德市",
                                "county": "武陵区",

                            },
                            {
                                "cityCode": "430703",
                                "cityName": "鼎城区",
                                "parentId": "430700",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "常德市",
                                "county": "鼎城区",

                            },
                            {
                                "cityCode": "430721",
                                "cityName": "安乡县",
                                "parentId": "430700",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "常德市",
                                "county": "安乡县",

                            },
                            {
                                "cityCode": "430722",
                                "cityName": "汉寿县",
                                "parentId": "430700",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "常德市",
                                "county": "汉寿县",

                            },
                            {
                                "cityCode": "430723",
                                "cityName": "澧县",
                                "parentId": "430700",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "常德市",
                                "county": "澧县",

                            },
                            {
                                "cityCode": "430724",
                                "cityName": "临澧县",
                                "parentId": "430700",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "常德市",
                                "county": "临澧县",

                            },
                            {
                                "cityCode": "430725",
                                "cityName": "桃源县",
                                "parentId": "430700",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "常德市",
                                "county": "桃源县",

                            },
                            {
                                "cityCode": "430726",
                                "cityName": "石门县",
                                "parentId": "430700",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "常德市",
                                "county": "石门县",

                            },
                            {
                                "cityCode": "430771",
                                "cityName": "常德市西洞庭管理区",
                                "parentId": "430700",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "常德市",
                                "county": "常德市西洞庭管理区",

                            },
                            {
                                "cityCode": "430781",
                                "cityName": "津市市",
                                "parentId": "430700",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "常德市",
                                "county": "津市市",

                            }
                        ]
                    },
                    {
                        "cityCode": "430800",
                        "cityName": "张家界市",
                        "parentId": "430000",
                        "provinceType": "2",
                        "province": "湖南省",
                        "city": "张家界市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "430802",
                                "cityName": "永定区",
                                "parentId": "430800",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "张家界市",
                                "county": "永定区",

                            },
                            {
                                "cityCode": "430811",
                                "cityName": "武陵源区",
                                "parentId": "430800",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "张家界市",
                                "county": "武陵源区",

                            },
                            {
                                "cityCode": "430821",
                                "cityName": "慈利县",
                                "parentId": "430800",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "张家界市",
                                "county": "慈利县",

                            },
                            {
                                "cityCode": "430822",
                                "cityName": "桑植县",
                                "parentId": "430800",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "张家界市",
                                "county": "桑植县",

                            }
                        ]
                    },
                    {
                        "cityCode": "430900",
                        "cityName": "益阳市",
                        "parentId": "430000",
                        "provinceType": "2",
                        "province": "湖南省",
                        "city": "益阳市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "430901",
                                "cityName": "益阳市",
                                "parentId": "430900",
                                "provinceType": "2",
                                "province": "湖南省",
                                "city": "益阳市",
                                "county": "",

                            },
                            {
                                "cityCode": "430902",
                                "cityName": "资阳区",
                                "parentId": "430900",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "益阳市",
                                "county": "资阳区",

                            },
                            {
                                "cityCode": "430903",
                                "cityName": "赫山区",
                                "parentId": "430900",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "益阳市",
                                "county": "赫山区",

                            },
                            {
                                "cityCode": "430921",
                                "cityName": "南县",
                                "parentId": "430900",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "益阳市",
                                "county": "南县",

                            },
                            {
                                "cityCode": "430922",
                                "cityName": "桃江县",
                                "parentId": "430900",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "益阳市",
                                "county": "桃江县",

                            },
                            {
                                "cityCode": "430923",
                                "cityName": "安化县",
                                "parentId": "430900",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "益阳市",
                                "county": "安化县",

                            },
                            {
                                "cityCode": "430971",
                                "cityName": "益阳市大通湖管理区",
                                "parentId": "430900",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "益阳市",
                                "county": "益阳市大通湖管理区",

                            },
                            {
                                "cityCode": "430972",
                                "cityName": "湖南益阳高新技术产业园区",
                                "parentId": "430900",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "益阳市",
                                "county": "湖南益阳高新技术产业园区",

                            },
                            {
                                "cityCode": "430981",
                                "cityName": "沅江市",
                                "parentId": "430900",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "益阳市",
                                "county": "沅江市",

                            }
                        ]
                    },
                    {
                        "cityCode": "431000",
                        "cityName": "郴州市",
                        "parentId": "430000",
                        "provinceType": "2",
                        "province": "湖南省",
                        "city": "郴州市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "431001",
                                "cityName": "郴州市",
                                "parentId": "431000",
                                "provinceType": "2",
                                "province": "湖南省",
                                "city": "郴州市",
                                "county": "",

                            },
                            {
                                "cityCode": "431002",
                                "cityName": "北湖区",
                                "parentId": "431000",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "郴州市",
                                "county": "北湖区",

                            },
                            {
                                "cityCode": "431003",
                                "cityName": "苏仙区",
                                "parentId": "431000",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "郴州市",
                                "county": "苏仙区",

                            },
                            {
                                "cityCode": "431021",
                                "cityName": "桂阳县",
                                "parentId": "431000",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "郴州市",
                                "county": "桂阳县",

                            },
                            {
                                "cityCode": "431022",
                                "cityName": "宜章县",
                                "parentId": "431000",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "郴州市",
                                "county": "宜章县",

                            },
                            {
                                "cityCode": "431023",
                                "cityName": "永兴县",
                                "parentId": "431000",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "郴州市",
                                "county": "永兴县",

                            },
                            {
                                "cityCode": "431024",
                                "cityName": "嘉禾县",
                                "parentId": "431000",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "郴州市",
                                "county": "嘉禾县",

                            },
                            {
                                "cityCode": "431025",
                                "cityName": "临武县",
                                "parentId": "431000",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "郴州市",
                                "county": "临武县",

                            },
                            {
                                "cityCode": "431026",
                                "cityName": "汝城县",
                                "parentId": "431000",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "郴州市",
                                "county": "汝城县",

                            },
                            {
                                "cityCode": "431027",
                                "cityName": "桂东县",
                                "parentId": "431000",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "郴州市",
                                "county": "桂东县",

                            },
                            {
                                "cityCode": "431028",
                                "cityName": "安仁县",
                                "parentId": "431000",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "郴州市",
                                "county": "安仁县",

                            },
                            {
                                "cityCode": "431081",
                                "cityName": "资兴市",
                                "parentId": "431000",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "郴州市",
                                "county": "资兴市",

                            }
                        ]
                    },
                    {
                        "cityCode": "431100",
                        "cityName": "永州市",
                        "parentId": "430000",
                        "provinceType": "2",
                        "province": "湖南省",
                        "city": "永州市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "431101",
                                "cityName": "永州市",
                                "parentId": "431100",
                                "provinceType": "2",
                                "province": "湖南省",
                                "city": "永州市",
                                "county": "",

                            },
                            {
                                "cityCode": "431102",
                                "cityName": "零陵区",
                                "parentId": "431100",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "永州市",
                                "county": "零陵区",

                            },
                            {
                                "cityCode": "431103",
                                "cityName": "冷水滩区",
                                "parentId": "431100",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "永州市",
                                "county": "冷水滩区",

                            },
                            {
                                "cityCode": "431122",
                                "cityName": "东安县",
                                "parentId": "431100",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "永州市",
                                "county": "东安县",

                            },
                            {
                                "cityCode": "431123",
                                "cityName": "双牌县",
                                "parentId": "431100",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "永州市",
                                "county": "双牌县",

                            },
                            {
                                "cityCode": "431124",
                                "cityName": "道县",
                                "parentId": "431100",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "永州市",
                                "county": "道县",

                            },
                            {
                                "cityCode": "431125",
                                "cityName": "江永县",
                                "parentId": "431100",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "永州市",
                                "county": "江永县",

                            },
                            {
                                "cityCode": "431126",
                                "cityName": "宁远县",
                                "parentId": "431100",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "永州市",
                                "county": "宁远县",

                            },
                            {
                                "cityCode": "431127",
                                "cityName": "蓝山县",
                                "parentId": "431100",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "永州市",
                                "county": "蓝山县",

                            },
                            {
                                "cityCode": "431128",
                                "cityName": "新田县",
                                "parentId": "431100",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "永州市",
                                "county": "新田县",

                            },
                            {
                                "cityCode": "431129",
                                "cityName": "江华瑶族自治县",
                                "parentId": "431100",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "永州市",
                                "county": "江华瑶族自治县",

                            },
                            {
                                "cityCode": "431171",
                                "cityName": "永州经济技术开发区",
                                "parentId": "431100",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "永州市",
                                "county": "永州经济技术开发区",

                            },
                            {
                                "cityCode": "431172",
                                "cityName": "永州市金洞管理区",
                                "parentId": "431100",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "永州市",
                                "county": "永州市金洞管理区",

                            },
                            {
                                "cityCode": "431173",
                                "cityName": "永州市回龙圩管理区",
                                "parentId": "431100",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "永州市",
                                "county": "永州市回龙圩管理区",

                            },
                            {
                                "cityCode": "431181",
                                "cityName": "祁阳市",
                                "parentId": "431100",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "永州市",
                                "county": "祁阳市",

                            }
                        ]
                    },
                    {
                        "cityCode": "431200",
                        "cityName": "怀化市",
                        "parentId": "430000",
                        "provinceType": "2",
                        "province": "湖南省",
                        "city": "怀化市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "431201",
                                "cityName": "怀化市",
                                "parentId": "431200",
                                "provinceType": "2",
                                "province": "湖南省",
                                "city": "怀化市",
                                "county": "",

                            },
                            {
                                "cityCode": "431202",
                                "cityName": "鹤城区",
                                "parentId": "431200",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "怀化市",
                                "county": "鹤城区",

                            },
                            {
                                "cityCode": "431221",
                                "cityName": "中方县",
                                "parentId": "431200",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "怀化市",
                                "county": "中方县",

                            },
                            {
                                "cityCode": "431222",
                                "cityName": "沅陵县",
                                "parentId": "431200",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "怀化市",
                                "county": "沅陵县",

                            },
                            {
                                "cityCode": "431223",
                                "cityName": "辰溪县",
                                "parentId": "431200",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "怀化市",
                                "county": "辰溪县",

                            },
                            {
                                "cityCode": "431224",
                                "cityName": "溆浦县",
                                "parentId": "431200",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "怀化市",
                                "county": "溆浦县",

                            },
                            {
                                "cityCode": "431225",
                                "cityName": "会同县",
                                "parentId": "431200",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "怀化市",
                                "county": "会同县",

                            },
                            {
                                "cityCode": "431226",
                                "cityName": "麻阳苗族自治县",
                                "parentId": "431200",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "怀化市",
                                "county": "麻阳苗族自治县",

                            },
                            {
                                "cityCode": "431227",
                                "cityName": "新晃侗族自治县",
                                "parentId": "431200",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "怀化市",
                                "county": "新晃侗族自治县",

                            },
                            {
                                "cityCode": "431228",
                                "cityName": "芷江侗族自治县",
                                "parentId": "431200",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "怀化市",
                                "county": "芷江侗族自治县",

                            },
                            {
                                "cityCode": "431229",
                                "cityName": "靖州苗族侗族自治县",
                                "parentId": "431200",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "怀化市",
                                "county": "靖州苗族侗族自治县",

                            },
                            {
                                "cityCode": "431230",
                                "cityName": "通道侗族自治县",
                                "parentId": "431200",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "怀化市",
                                "county": "通道侗族自治县",

                            },
                            {
                                "cityCode": "431271",
                                "cityName": "怀化市洪江管理区",
                                "parentId": "431200",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "怀化市",
                                "county": "怀化市洪江管理区",

                            },
                            {
                                "cityCode": "431281",
                                "cityName": "洪江市",
                                "parentId": "431200",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "怀化市",
                                "county": "洪江市",

                            }
                        ]
                    },
                    {
                        "cityCode": "431300",
                        "cityName": "娄底市",
                        "parentId": "430000",
                        "provinceType": "2",
                        "province": "湖南省",
                        "city": "娄底市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "431301",
                                "cityName": "娄底市",
                                "parentId": "431300",
                                "provinceType": "2",
                                "province": "湖南省",
                                "city": "娄底市",
                                "county": "",

                            },
                            {
                                "cityCode": "431302",
                                "cityName": "娄星区",
                                "parentId": "431300",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "娄底市",
                                "county": "娄星区",

                            },
                            {
                                "cityCode": "431321",
                                "cityName": "双峰县",
                                "parentId": "431300",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "娄底市",
                                "county": "双峰县",

                            },
                            {
                                "cityCode": "431322",
                                "cityName": "新化县",
                                "parentId": "431300",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "娄底市",
                                "county": "新化县",

                            },
                            {
                                "cityCode": "431381",
                                "cityName": "冷水江市",
                                "parentId": "431300",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "娄底市",
                                "county": "冷水江市",

                            },
                            {
                                "cityCode": "431382",
                                "cityName": "涟源市",
                                "parentId": "431300",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "娄底市",
                                "county": "涟源市",

                            }
                        ]
                    },
                    {
                        "cityCode": "433100",
                        "cityName": "湘西土家族苗族自治州",
                        "parentId": "430000",
                        "provinceType": "2",
                        "province": "湖南省",
                        "city": "湘西土家族苗族自治州",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "433101",
                                "cityName": "吉首市",
                                "parentId": "433100",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "湘西土家族苗族自治州",
                                "county": "吉首市",

                            },
                            {
                                "cityCode": "433122",
                                "cityName": "泸溪县",
                                "parentId": "433100",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "湘西土家族苗族自治州",
                                "county": "泸溪县",

                            },
                            {
                                "cityCode": "433123",
                                "cityName": "凤凰县",
                                "parentId": "433100",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "湘西土家族苗族自治州",
                                "county": "凤凰县",

                            },
                            {
                                "cityCode": "433124",
                                "cityName": "花垣县",
                                "parentId": "433100",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "湘西土家族苗族自治州",
                                "county": "花垣县",

                            },
                            {
                                "cityCode": "433125",
                                "cityName": "保靖县",
                                "parentId": "433100",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "湘西土家族苗族自治州",
                                "county": "保靖县",

                            },
                            {
                                "cityCode": "433126",
                                "cityName": "古丈县",
                                "parentId": "433100",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "湘西土家族苗族自治州",
                                "county": "古丈县",

                            },
                            {
                                "cityCode": "433127",
                                "cityName": "永顺县",
                                "parentId": "433100",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "湘西土家族苗族自治州",
                                "county": "永顺县",

                            },
                            {
                                "cityCode": "433130",
                                "cityName": "龙山县",
                                "parentId": "433100",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "湘西土家族苗族自治州",
                                "county": "龙山县",

                            },
                            {
                                "cityCode": "433172",
                                "cityName": "湖南吉首经济开发区",
                                "parentId": "433100",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "湘西土家族苗族自治州",
                                "county": "湖南吉首经济开发区",

                            },
                            {
                                "cityCode": "433173",
                                "cityName": "湖南永顺经济开发区",
                                "parentId": "433100",
                                "provinceType": "3",
                                "province": "湖南省",
                                "city": "湘西土家族苗族自治州",
                                "county": "湖南永顺经济开发区",

                            }
                        ]
                    }
                ]
            },
            {
                "cityCode": "440000",
                "cityName": "广东省",
                "parentId": "100000",
                "provinceType": "1",
                "province": "广东省",
                "city": "",
                "county": "",
                "counties": [
                    {
                        "cityCode": "440100",
                        "cityName": "广州市",
                        "parentId": "440000",
                        "provinceType": "2",
                        "province": "广东省",
                        "city": "广州市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "440102",
                                "cityName": "东山区",
                                "parentId": "440100",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "广州市",
                                "county": "东山区",

                            },
                            {
                                "cityCode": "440103",
                                "cityName": "荔湾区",
                                "parentId": "440100",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "广州市",
                                "county": "荔湾区",

                            },
                            {
                                "cityCode": "440104",
                                "cityName": "越秀区",
                                "parentId": "440100",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "广州市",
                                "county": "越秀区",

                            },
                            {
                                "cityCode": "440105",
                                "cityName": "海珠区",
                                "parentId": "440100",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "广州市",
                                "county": "海珠区",

                            },
                            {
                                "cityCode": "440106",
                                "cityName": "天河区",
                                "parentId": "440100",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "广州市",
                                "county": "天河区",

                            },
                            {
                                "cityCode": "440107",
                                "cityName": "芳村区",
                                "parentId": "440100",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "广州市",
                                "county": "芳村区",

                            },
                            {
                                "cityCode": "440111",
                                "cityName": "白云区",
                                "parentId": "440100",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "广州市",
                                "county": "白云区",

                            },
                            {
                                "cityCode": "440112",
                                "cityName": "黄埔区",
                                "parentId": "440100",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "广州市",
                                "county": "黄埔区",

                            },
                            {
                                "cityCode": "440113",
                                "cityName": "番禺区",
                                "parentId": "440100",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "广州市",
                                "county": "番禺区",

                            },
                            {
                                "cityCode": "440114",
                                "cityName": "花都区",
                                "parentId": "440100",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "广州市",
                                "county": "花都区",

                            },
                            {
                                "cityCode": "440115",
                                "cityName": "南沙区",
                                "parentId": "440100",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "广州市",
                                "county": "南沙区",

                            },
                            {
                                "cityCode": "440116",
                                "cityName": "萝岗区",
                                "parentId": "440100",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "广州市",
                                "county": "萝岗区",

                            },
                            {
                                "cityCode": "440117",
                                "cityName": "从化区",
                                "parentId": "440100",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "广州市",
                                "county": "从化区",

                            },
                            {
                                "cityCode": "440118",
                                "cityName": "增城区",
                                "parentId": "440100",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "广州市",
                                "county": "增城区",

                            },
                            {
                                "cityCode": "440183",
                                "cityName": "增城区",
                                "parentId": "440100",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "广州市",
                                "county": "增城区",

                            },
                            {
                                "cityCode": "440184",
                                "cityName": "从化区",
                                "parentId": "440100",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "广州市",
                                "county": "从化区",

                            },
                            {
                                "cityCode": "440185",
                                "cityName": "萝岗区",
                                "parentId": "440100",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "广州市",
                                "county": "萝岗区",

                            }
                        ]
                    },
                    {
                        "cityCode": "440200",
                        "cityName": "韶关市",
                        "parentId": "440000",
                        "provinceType": "2",
                        "province": "广东省",
                        "city": "韶关市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "440201",
                                "cityName": "韶关市",
                                "parentId": "440200",
                                "provinceType": "2",
                                "province": "广东省",
                                "city": "韶关市",
                                "county": "",

                            },
                            {
                                "cityCode": "440202",
                                "cityName": "北江区",
                                "parentId": "440200",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "韶关市",
                                "county": "北江区",

                            },
                            {
                                "cityCode": "440203",
                                "cityName": "武江区",
                                "parentId": "440200",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "韶关市",
                                "county": "武江区",

                            },
                            {
                                "cityCode": "440204",
                                "cityName": "浈江区",
                                "parentId": "440200",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "韶关市",
                                "county": "浈江区",

                            },
                            {
                                "cityCode": "440205",
                                "cityName": "曲江区",
                                "parentId": "440200",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "韶关市",
                                "county": "曲江区",

                            },
                            {
                                "cityCode": "440222",
                                "cityName": "始兴县",
                                "parentId": "440200",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "韶关市",
                                "county": "始兴县",

                            },
                            {
                                "cityCode": "440224",
                                "cityName": "仁化县",
                                "parentId": "440200",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "韶关市",
                                "county": "仁化县",

                            },
                            {
                                "cityCode": "440229",
                                "cityName": "翁源县",
                                "parentId": "440200",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "韶关市",
                                "county": "翁源县",

                            },
                            {
                                "cityCode": "440232",
                                "cityName": "乳源瑶族自治县",
                                "parentId": "440200",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "韶关市",
                                "county": "乳源瑶族自治县",

                            },
                            {
                                "cityCode": "440233",
                                "cityName": "新丰县",
                                "parentId": "440200",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "韶关市",
                                "county": "新丰县",

                            },
                            {
                                "cityCode": "440281",
                                "cityName": "乐昌市",
                                "parentId": "440200",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "韶关市",
                                "county": "乐昌市",

                            },
                            {
                                "cityCode": "440282",
                                "cityName": "南雄市",
                                "parentId": "440200",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "韶关市",
                                "county": "南雄市",

                            }
                        ]
                    },
                    {
                        "cityCode": "440300",
                        "cityName": "深圳市",
                        "parentId": "440000",
                        "provinceType": "2",
                        "province": "广东省",
                        "city": "深圳市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "440301",
                                "cityName": "深圳市",
                                "parentId": "440300",
                                "provinceType": "2",
                                "province": "广东省",
                                "city": "深圳市",
                                "county": "",

                            },
                            {
                                "cityCode": "440303",
                                "cityName": "罗湖区",
                                "parentId": "440300",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "深圳市",
                                "county": "罗湖区",

                            },
                            {
                                "cityCode": "440304",
                                "cityName": "福田区",
                                "parentId": "440300",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "深圳市",
                                "county": "福田区",

                            },
                            {
                                "cityCode": "440305",
                                "cityName": "南山区",
                                "parentId": "440300",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "深圳市",
                                "county": "南山区",

                            },
                            {
                                "cityCode": "440306",
                                "cityName": "宝安区",
                                "parentId": "440300",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "深圳市",
                                "county": "宝安区",

                            },
                            {
                                "cityCode": "440307",
                                "cityName": "龙岗区",
                                "parentId": "440300",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "深圳市",
                                "county": "龙岗区",

                            },
                            {
                                "cityCode": "440308",
                                "cityName": "盐田区",
                                "parentId": "440300",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "深圳市",
                                "county": "盐田区",

                            },
                            {
                                "cityCode": "440309",
                                "cityName": "龙华区",
                                "parentId": "440300",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "深圳市",
                                "county": "龙华区",

                            },
                            {
                                "cityCode": "440310",
                                "cityName": "坪山区",
                                "parentId": "440300",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "深圳市",
                                "county": "坪山区",

                            },
                            {
                                "cityCode": "440311",
                                "cityName": "光明区",
                                "parentId": "440300",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "深圳市",
                                "county": "光明区",

                            },
                            {
                                "cityCode": "440312",
                                "cityName": "大鹏新区",
                                "parentId": "440300",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "深圳市",
                                "county": "大鹏新区",

                            }
                        ]
                    },
                    {
                        "cityCode": "440400",
                        "cityName": "珠海市",
                        "parentId": "440000",
                        "provinceType": "2",
                        "province": "广东省",
                        "city": "珠海市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "440402",
                                "cityName": "香洲区",
                                "parentId": "440400",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "珠海市",
                                "county": "香洲区",

                            },
                            {
                                "cityCode": "440403",
                                "cityName": "斗门区",
                                "parentId": "440400",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "珠海市",
                                "county": "斗门区",

                            },
                            {
                                "cityCode": "440404",
                                "cityName": "金湾区",
                                "parentId": "440400",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "珠海市",
                                "county": "金湾区",

                            },
                            {
                                "cityCode": "440405",
                                "cityName": "横琴新区",
                                "parentId": "440400",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "珠海市",
                                "county": "横琴新区",

                            },
                            {
                                "cityCode": "440406",
                                "cityName": "经济开发区",
                                "parentId": "440400",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "珠海市",
                                "county": "经济开发区",

                            }
                        ]
                    },
                    {
                        "cityCode": "440500",
                        "cityName": "汕头市",
                        "parentId": "440000",
                        "provinceType": "2",
                        "province": "广东省",
                        "city": "汕头市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "440507",
                                "cityName": "龙湖区",
                                "parentId": "440500",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "汕头市",
                                "county": "龙湖区",

                            },
                            {
                                "cityCode": "440511",
                                "cityName": "金平区",
                                "parentId": "440500",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "汕头市",
                                "county": "金平区",

                            },
                            {
                                "cityCode": "440512",
                                "cityName": "濠江区",
                                "parentId": "440500",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "汕头市",
                                "county": "濠江区",

                            },
                            {
                                "cityCode": "440513",
                                "cityName": "潮阳区",
                                "parentId": "440500",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "汕头市",
                                "county": "潮阳区",

                            },
                            {
                                "cityCode": "440514",
                                "cityName": "潮南区",
                                "parentId": "440500",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "汕头市",
                                "county": "潮南区",

                            },
                            {
                                "cityCode": "440515",
                                "cityName": "澄海区",
                                "parentId": "440500",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "汕头市",
                                "county": "澄海区",

                            },
                            {
                                "cityCode": "440523",
                                "cityName": "南澳县",
                                "parentId": "440500",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "汕头市",
                                "county": "南澳县",

                            }
                        ]
                    },
                    {
                        "cityCode": "440600",
                        "cityName": "佛山市",
                        "parentId": "440000",
                        "provinceType": "2",
                        "province": "广东省",
                        "city": "佛山市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "440604",
                                "cityName": "禅城区",
                                "parentId": "440600",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "佛山市",
                                "county": "禅城区",

                            },
                            {
                                "cityCode": "440605",
                                "cityName": "南海区",
                                "parentId": "440600",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "佛山市",
                                "county": "南海区",

                            },
                            {
                                "cityCode": "440606",
                                "cityName": "顺德区",
                                "parentId": "440600",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "佛山市",
                                "county": "顺德区",

                            },
                            {
                                "cityCode": "440607",
                                "cityName": "三水区",
                                "parentId": "440600",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "佛山市",
                                "county": "三水区",

                            },
                            {
                                "cityCode": "440608",
                                "cityName": "高明区",
                                "parentId": "440600",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "佛山市",
                                "county": "高明区",

                            }
                        ]
                    },
                    {
                        "cityCode": "440700",
                        "cityName": "江门市",
                        "parentId": "440000",
                        "provinceType": "2",
                        "province": "广东省",
                        "city": "江门市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "440703",
                                "cityName": "蓬江区",
                                "parentId": "440700",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "江门市",
                                "county": "蓬江区",

                            },
                            {
                                "cityCode": "440704",
                                "cityName": "江海区",
                                "parentId": "440700",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "江门市",
                                "county": "江海区",

                            },
                            {
                                "cityCode": "440705",
                                "cityName": "新会区",
                                "parentId": "440700",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "江门市",
                                "county": "新会区",

                            },
                            {
                                "cityCode": "440781",
                                "cityName": "台山市",
                                "parentId": "440700",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "江门市",
                                "county": "台山市",

                            },
                            {
                                "cityCode": "440783",
                                "cityName": "开平市",
                                "parentId": "440700",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "江门市",
                                "county": "开平市",

                            },
                            {
                                "cityCode": "440784",
                                "cityName": "鹤山市",
                                "parentId": "440700",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "江门市",
                                "county": "鹤山市",

                            },
                            {
                                "cityCode": "440785",
                                "cityName": "恩平市",
                                "parentId": "440700",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "江门市",
                                "county": "恩平市",

                            }
                        ]
                    },
                    {
                        "cityCode": "440800",
                        "cityName": "湛江市",
                        "parentId": "440000",
                        "provinceType": "2",
                        "province": "广东省",
                        "city": "湛江市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "440802",
                                "cityName": "赤坎区",
                                "parentId": "440800",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "湛江市",
                                "county": "赤坎区",

                            },
                            {
                                "cityCode": "440803",
                                "cityName": "霞山区",
                                "parentId": "440800",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "湛江市",
                                "county": "霞山区",

                            },
                            {
                                "cityCode": "440804",
                                "cityName": "坡头区",
                                "parentId": "440800",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "湛江市",
                                "county": "坡头区",

                            },
                            {
                                "cityCode": "440805",
                                "cityName": "开发区",
                                "parentId": "440800",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "湛江市",
                                "county": "开发区",

                            },
                            {
                                "cityCode": "440806",
                                "cityName": "开发区",
                                "parentId": "440800",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "湛江市",
                                "county": "开发区",

                            },
                            {
                                "cityCode": "440811",
                                "cityName": "麻章区",
                                "parentId": "440800",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "湛江市",
                                "county": "麻章区",

                            },
                            {
                                "cityCode": "440823",
                                "cityName": "遂溪县",
                                "parentId": "440800",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "湛江市",
                                "county": "遂溪县",

                            },
                            {
                                "cityCode": "440825",
                                "cityName": "徐闻县",
                                "parentId": "440800",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "湛江市",
                                "county": "徐闻县",

                            },
                            {
                                "cityCode": "440881",
                                "cityName": "廉江市",
                                "parentId": "440800",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "湛江市",
                                "county": "廉江市",

                            },
                            {
                                "cityCode": "440882",
                                "cityName": "雷州市",
                                "parentId": "440800",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "湛江市",
                                "county": "雷州市",

                            },
                            {
                                "cityCode": "440883",
                                "cityName": "吴川市",
                                "parentId": "440800",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "湛江市",
                                "county": "吴川市",

                            },
                            {
                                "cityCode": "440884",
                                "cityName": "经济开发区",
                                "parentId": "440800",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "湛江市",
                                "county": "经济开发区",

                            }
                        ]
                    },
                    {
                        "cityCode": "440900",
                        "cityName": "茂名市",
                        "parentId": "440000",
                        "provinceType": "2",
                        "province": "广东省",
                        "city": "茂名市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "440901",
                                "cityName": "茂名市",
                                "parentId": "440900",
                                "provinceType": "2",
                                "province": "广东省",
                                "city": "茂名市",
                                "county": "",

                            },
                            {
                                "cityCode": "440902",
                                "cityName": "茂南区",
                                "parentId": "440900",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "茂名市",
                                "county": "茂南区",

                            },
                            {
                                "cityCode": "440903",
                                "cityName": "茂港区",
                                "parentId": "440900",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "茂名市",
                                "county": "茂港区",

                            },
                            {
                                "cityCode": "440904",
                                "cityName": "电白区",
                                "parentId": "440900",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "茂名市",
                                "county": "电白区",

                            },
                            {
                                "cityCode": "440923",
                                "cityName": "电白县",
                                "parentId": "440900",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "茂名市",
                                "county": "电白县",

                            },
                            {
                                "cityCode": "440981",
                                "cityName": "高州市",
                                "parentId": "440900",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "茂名市",
                                "county": "高州市",

                            },
                            {
                                "cityCode": "440982",
                                "cityName": "化州市",
                                "parentId": "440900",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "茂名市",
                                "county": "化州市",

                            },
                            {
                                "cityCode": "440983",
                                "cityName": "信宜市",
                                "parentId": "440900",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "茂名市",
                                "county": "信宜市",

                            }
                        ]
                    },
                    {
                        "cityCode": "441200",
                        "cityName": "肇庆市",
                        "parentId": "440000",
                        "provinceType": "2",
                        "province": "广东省",
                        "city": "肇庆市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "441202",
                                "cityName": "端州区",
                                "parentId": "441200",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "肇庆市",
                                "county": "端州区",

                            },
                            {
                                "cityCode": "441203",
                                "cityName": "鼎湖区",
                                "parentId": "441200",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "肇庆市",
                                "county": "鼎湖区",

                            },
                            {
                                "cityCode": "441204",
                                "cityName": "高要区",
                                "parentId": "441200",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "肇庆市",
                                "county": "高要区",

                            },
                            {
                                "cityCode": "441223",
                                "cityName": "广宁县",
                                "parentId": "441200",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "肇庆市",
                                "county": "广宁县",

                            },
                            {
                                "cityCode": "441224",
                                "cityName": "怀集县",
                                "parentId": "441200",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "肇庆市",
                                "county": "怀集县",

                            },
                            {
                                "cityCode": "441225",
                                "cityName": "封开县",
                                "parentId": "441200",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "肇庆市",
                                "county": "封开县",

                            },
                            {
                                "cityCode": "441226",
                                "cityName": "德庆县",
                                "parentId": "441200",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "肇庆市",
                                "county": "德庆县",

                            },
                            {
                                "cityCode": "441283",
                                "cityName": "高要市",
                                "parentId": "441200",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "肇庆市",
                                "county": "高要市",

                            },
                            {
                                "cityCode": "441284",
                                "cityName": "四会市",
                                "parentId": "441200",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "肇庆市",
                                "county": "四会市",

                            }
                        ]
                    },
                    {
                        "cityCode": "441300",
                        "cityName": "惠州市",
                        "parentId": "440000",
                        "provinceType": "2",
                        "province": "广东省",
                        "city": "惠州市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "441302",
                                "cityName": "惠城区",
                                "parentId": "441300",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "惠州市",
                                "county": "惠城区",

                            },
                            {
                                "cityCode": "441303",
                                "cityName": "惠阳区",
                                "parentId": "441300",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "惠州市",
                                "county": "惠阳区",

                            },
                            {
                                "cityCode": "441322",
                                "cityName": "博罗县",
                                "parentId": "441300",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "惠州市",
                                "county": "博罗县",

                            },
                            {
                                "cityCode": "441323",
                                "cityName": "惠东县",
                                "parentId": "441300",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "惠州市",
                                "county": "惠东县",

                            },
                            {
                                "cityCode": "441324",
                                "cityName": "龙门县",
                                "parentId": "441300",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "惠州市",
                                "county": "龙门县",

                            },
                            {
                                "cityCode": "441325",
                                "cityName": "大亚湾区",
                                "parentId": "441300",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "惠州市",
                                "county": "大亚湾区",

                            }
                        ]
                    },
                    {
                        "cityCode": "441400",
                        "cityName": "梅州市",
                        "parentId": "440000",
                        "provinceType": "2",
                        "province": "广东省",
                        "city": "梅州市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "441402",
                                "cityName": "梅江区",
                                "parentId": "441400",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "梅州市",
                                "county": "梅江区",

                            },
                            {
                                "cityCode": "441403",
                                "cityName": "梅县区",
                                "parentId": "441400",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "梅州市",
                                "county": "梅县区",

                            },
                            {
                                "cityCode": "441421",
                                "cityName": "梅县",
                                "parentId": "441400",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "梅州市",
                                "county": "梅县",

                            },
                            {
                                "cityCode": "441422",
                                "cityName": "大埔县",
                                "parentId": "441400",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "梅州市",
                                "county": "大埔县",

                            },
                            {
                                "cityCode": "441423",
                                "cityName": "丰顺县",
                                "parentId": "441400",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "梅州市",
                                "county": "丰顺县",

                            },
                            {
                                "cityCode": "441424",
                                "cityName": "五华县",
                                "parentId": "441400",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "梅州市",
                                "county": "五华县",

                            },
                            {
                                "cityCode": "441426",
                                "cityName": "平远县",
                                "parentId": "441400",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "梅州市",
                                "county": "平远县",

                            },
                            {
                                "cityCode": "441427",
                                "cityName": "蕉岭县",
                                "parentId": "441400",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "梅州市",
                                "county": "蕉岭县",

                            },
                            {
                                "cityCode": "441481",
                                "cityName": "兴宁市",
                                "parentId": "441400",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "梅州市",
                                "county": "兴宁市",

                            }
                        ]
                    },
                    {
                        "cityCode": "441500",
                        "cityName": "汕尾市",
                        "parentId": "440000",
                        "provinceType": "2",
                        "province": "广东省",
                        "city": "汕尾市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "441502",
                                "cityName": "城区",
                                "parentId": "441500",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "汕尾市",
                                "county": "城区",

                            },
                            {
                                "cityCode": "441503",
                                "cityName": "华侨管理区",
                                "parentId": "441500",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "汕尾市",
                                "county": "华侨管理区",

                            },
                            {
                                "cityCode": "441521",
                                "cityName": "海丰县",
                                "parentId": "441500",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "汕尾市",
                                "county": "海丰县",

                            },
                            {
                                "cityCode": "441523",
                                "cityName": "陆河县",
                                "parentId": "441500",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "汕尾市",
                                "county": "陆河县",

                            },
                            {
                                "cityCode": "441581",
                                "cityName": "陆丰市",
                                "parentId": "441500",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "汕尾市",
                                "county": "陆丰市",

                            },
                            {
                                "cityCode": "441582",
                                "cityName": "红海湾经济开发试验区",
                                "parentId": "441500",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "汕尾市",
                                "county": "红海湾经济开发试验区",

                            }
                        ]
                    },
                    {
                        "cityCode": "441600",
                        "cityName": "河源市",
                        "parentId": "440000",
                        "provinceType": "2",
                        "province": "广东省",
                        "city": "河源市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "441602",
                                "cityName": "源城区",
                                "parentId": "441600",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "河源市",
                                "county": "源城区",

                            },
                            {
                                "cityCode": "441621",
                                "cityName": "紫金县",
                                "parentId": "441600",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "河源市",
                                "county": "紫金县",

                            },
                            {
                                "cityCode": "441622",
                                "cityName": "龙川县",
                                "parentId": "441600",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "河源市",
                                "county": "龙川县",

                            },
                            {
                                "cityCode": "441623",
                                "cityName": "连平县",
                                "parentId": "441600",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "河源市",
                                "county": "连平县",

                            },
                            {
                                "cityCode": "441624",
                                "cityName": "和平县",
                                "parentId": "441600",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "河源市",
                                "county": "和平县",

                            },
                            {
                                "cityCode": "441625",
                                "cityName": "东源县",
                                "parentId": "441600",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "河源市",
                                "county": "东源县",

                            }
                        ]
                    },
                    {
                        "cityCode": "441700",
                        "cityName": "阳江市",
                        "parentId": "440000",
                        "provinceType": "2",
                        "province": "广东省",
                        "city": "阳江市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "441702",
                                "cityName": "江城区",
                                "parentId": "441700",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "阳江市",
                                "county": "江城区",

                            },
                            {
                                "cityCode": "441704",
                                "cityName": "阳东区",
                                "parentId": "441700",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "阳江市",
                                "county": "阳东区",

                            },
                            {
                                "cityCode": "441721",
                                "cityName": "阳西县",
                                "parentId": "441700",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "阳江市",
                                "county": "阳西县",

                            },
                            {
                                "cityCode": "441723",
                                "cityName": "阳东区",
                                "parentId": "441700",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "阳江市",
                                "county": "阳东区",

                            },
                            {
                                "cityCode": "441781",
                                "cityName": "阳春市",
                                "parentId": "441700",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "阳江市",
                                "county": "阳春市",

                            },
                            {
                                "cityCode": "441782",
                                "cityName": "海陵区",
                                "parentId": "441700",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "阳江市",
                                "county": "海陵区",

                            }
                        ]
                    },
                    {
                        "cityCode": "441800",
                        "cityName": "清远市",
                        "parentId": "440000",
                        "provinceType": "2",
                        "province": "广东省",
                        "city": "清远市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "441802",
                                "cityName": "清城区",
                                "parentId": "441800",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "清远市",
                                "county": "清城区",

                            },
                            {
                                "cityCode": "441803",
                                "cityName": "清新区",
                                "parentId": "441800",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "清远市",
                                "county": "清新区",

                            },
                            {
                                "cityCode": "441821",
                                "cityName": "佛冈县",
                                "parentId": "441800",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "清远市",
                                "county": "佛冈县",

                            },
                            {
                                "cityCode": "441823",
                                "cityName": "阳山县",
                                "parentId": "441800",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "清远市",
                                "county": "阳山县",

                            },
                            {
                                "cityCode": "441825",
                                "cityName": "连山壮族瑶族自治县",
                                "parentId": "441800",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "清远市",
                                "county": "连山壮族瑶族自治县",

                            },
                            {
                                "cityCode": "441826",
                                "cityName": "连南瑶族自治县",
                                "parentId": "441800",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "清远市",
                                "county": "连南瑶族自治县",

                            },
                            {
                                "cityCode": "441827",
                                "cityName": "清新县",
                                "parentId": "441800",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "清远市",
                                "county": "清新县",

                            },
                            {
                                "cityCode": "441881",
                                "cityName": "英德市",
                                "parentId": "441800",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "清远市",
                                "county": "英德市",

                            },
                            {
                                "cityCode": "441882",
                                "cityName": "连州市",
                                "parentId": "441800",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "清远市",
                                "county": "连州市",

                            }
                        ]
                    },
                    {
                        "cityCode": "441900",
                        "cityName": "东莞市",
                        "parentId": "440000",
                        "provinceType": "2",
                        "province": "广东省",
                        "city": "东莞市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "441901",
                                "cityName": "莞城区",
                                "parentId": "441900",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "东莞市",
                                "county": "莞城区",

                            },
                            {
                                "cityCode": "441902",
                                "cityName": "南城区",
                                "parentId": "441900",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "东莞市",
                                "county": "南城区",

                            },
                            {
                                "cityCode": "441903",
                                "cityName": "东城区",
                                "parentId": "441900",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "东莞市",
                                "county": "东城区",

                            },
                            {
                                "cityCode": "441904",
                                "cityName": "万江区",
                                "parentId": "441900",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "东莞市",
                                "county": "万江区",

                            },
                            {
                                "cityCode": "441905",
                                "cityName": "石碣镇",
                                "parentId": "441900",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "东莞市",
                                "county": "石碣镇",

                            },
                            {
                                "cityCode": "441906",
                                "cityName": "石龙镇",
                                "parentId": "441900",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "东莞市",
                                "county": "石龙镇",

                            },
                            {
                                "cityCode": "441907",
                                "cityName": "茶山镇",
                                "parentId": "441900",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "东莞市",
                                "county": "茶山镇",

                            },
                            {
                                "cityCode": "441908",
                                "cityName": "石排镇",
                                "parentId": "441900",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "东莞市",
                                "county": "石排镇",

                            },
                            {
                                "cityCode": "441909",
                                "cityName": "企石镇",
                                "parentId": "441900",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "东莞市",
                                "county": "企石镇",

                            },
                            {
                                "cityCode": "441910",
                                "cityName": "横沥镇",
                                "parentId": "441900",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "东莞市",
                                "county": "横沥镇",

                            },
                            {
                                "cityCode": "441911",
                                "cityName": "桥头镇",
                                "parentId": "441900",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "东莞市",
                                "county": "桥头镇",

                            },
                            {
                                "cityCode": "441912",
                                "cityName": "谢岗镇",
                                "parentId": "441900",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "东莞市",
                                "county": "谢岗镇",

                            },
                            {
                                "cityCode": "441913",
                                "cityName": "东坑镇",
                                "parentId": "441900",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "东莞市",
                                "county": "东坑镇",

                            },
                            {
                                "cityCode": "441914",
                                "cityName": "常平镇",
                                "parentId": "441900",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "东莞市",
                                "county": "常平镇",

                            },
                            {
                                "cityCode": "441915",
                                "cityName": "寮步镇",
                                "parentId": "441900",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "东莞市",
                                "county": "寮步镇",

                            },
                            {
                                "cityCode": "441916",
                                "cityName": "大朗镇",
                                "parentId": "441900",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "东莞市",
                                "county": "大朗镇",

                            },
                            {
                                "cityCode": "441917",
                                "cityName": "麻涌镇",
                                "parentId": "441900",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "东莞市",
                                "county": "麻涌镇",

                            },
                            {
                                "cityCode": "441918",
                                "cityName": "中堂镇",
                                "parentId": "441900",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "东莞市",
                                "county": "中堂镇",

                            },
                            {
                                "cityCode": "441919",
                                "cityName": "高埗镇",
                                "parentId": "441900",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "东莞市",
                                "county": "高埗镇",

                            },
                            {
                                "cityCode": "441920",
                                "cityName": "樟木头镇",
                                "parentId": "441900",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "东莞市",
                                "county": "樟木头镇",

                            },
                            {
                                "cityCode": "441921",
                                "cityName": "大岭山镇",
                                "parentId": "441900",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "东莞市",
                                "county": "大岭山镇",

                            },
                            {
                                "cityCode": "441922",
                                "cityName": "望牛墩镇",
                                "parentId": "441900",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "东莞市",
                                "county": "望牛墩镇",

                            },
                            {
                                "cityCode": "441923",
                                "cityName": "黄江镇",
                                "parentId": "441900",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "东莞市",
                                "county": "黄江镇",

                            },
                            {
                                "cityCode": "441924",
                                "cityName": "洪梅镇",
                                "parentId": "441900",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "东莞市",
                                "county": "洪梅镇",

                            },
                            {
                                "cityCode": "441925",
                                "cityName": "清溪镇",
                                "parentId": "441900",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "东莞市",
                                "county": "清溪镇",

                            },
                            {
                                "cityCode": "441926",
                                "cityName": "沙田镇",
                                "parentId": "441900",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "东莞市",
                                "county": "沙田镇",

                            },
                            {
                                "cityCode": "441927",
                                "cityName": "道滘镇",
                                "parentId": "441900",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "东莞市",
                                "county": "道滘镇",

                            },
                            {
                                "cityCode": "441928",
                                "cityName": "塘厦镇",
                                "parentId": "441900",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "东莞市",
                                "county": "塘厦镇",

                            },
                            {
                                "cityCode": "441929",
                                "cityName": "虎门镇",
                                "parentId": "441900",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "东莞市",
                                "county": "虎门镇",

                            },
                            {
                                "cityCode": "441930",
                                "cityName": "厚街镇",
                                "parentId": "441900",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "东莞市",
                                "county": "厚街镇",

                            },
                            {
                                "cityCode": "441931",
                                "cityName": "凤岗镇",
                                "parentId": "441900",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "东莞市",
                                "county": "凤岗镇",

                            },
                            {
                                "cityCode": "441932",
                                "cityName": "长安镇",
                                "parentId": "441900",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "东莞市",
                                "county": "长安镇",

                            },
                            {
                                "cityCode": "441933",
                                "cityName": "松山湖高新区",
                                "parentId": "441900",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "东莞市",
                                "county": "松山湖高新区",

                            }
                        ]
                    },
                    {
                        "cityCode": "441999",
                        "cityName": "东莞市",
                        "parentId": "440000",
                        "provinceType": "3",
                        "province": "广东省",
                        "city": "东莞市",
                        "county": "东莞市",

                    },
                    {
                        "cityCode": "442000",
                        "cityName": "中山市",
                        "parentId": "440000",
                        "provinceType": "2",
                        "province": "广东省",
                        "city": "中山市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "442001",
                                "cityName": "石岐区",
                                "parentId": "442000",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "中山市",
                                "county": "石岐区",

                            },
                            {
                                "cityCode": "442002",
                                "cityName": "东区",
                                "parentId": "442000",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "中山市",
                                "county": "东区",

                            },
                            {
                                "cityCode": "442003",
                                "cityName": "西区",
                                "parentId": "442000",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "中山市",
                                "county": "西区",

                            },
                            {
                                "cityCode": "442004",
                                "cityName": "南区",
                                "parentId": "442000",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "中山市",
                                "county": "南区",

                            },
                            {
                                "cityCode": "442005",
                                "cityName": "五桂山区",
                                "parentId": "442000",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "中山市",
                                "county": "五桂山区",

                            },
                            {
                                "cityCode": "442006",
                                "cityName": "火炬开发区",
                                "parentId": "442000",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "中山市",
                                "county": "火炬开发区",

                            },
                            {
                                "cityCode": "442007",
                                "cityName": "黄圃镇",
                                "parentId": "442000",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "中山市",
                                "county": "黄圃镇",

                            },
                            {
                                "cityCode": "442008",
                                "cityName": "南头镇",
                                "parentId": "442000",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "中山市",
                                "county": "南头镇",

                            },
                            {
                                "cityCode": "442009",
                                "cityName": "东凤镇",
                                "parentId": "442000",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "中山市",
                                "county": "东凤镇",

                            },
                            {
                                "cityCode": "442010",
                                "cityName": "阜沙镇",
                                "parentId": "442000",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "中山市",
                                "county": "阜沙镇",

                            },
                            {
                                "cityCode": "442011",
                                "cityName": "小榄镇",
                                "parentId": "442000",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "中山市",
                                "county": "小榄镇",

                            },
                            {
                                "cityCode": "442012",
                                "cityName": "东升镇",
                                "parentId": "442000",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "中山市",
                                "county": "东升镇",

                            },
                            {
                                "cityCode": "442013",
                                "cityName": "古镇镇",
                                "parentId": "442000",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "中山市",
                                "county": "古镇镇",

                            },
                            {
                                "cityCode": "442014",
                                "cityName": "横栏镇",
                                "parentId": "442000",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "中山市",
                                "county": "横栏镇",

                            },
                            {
                                "cityCode": "442015",
                                "cityName": "三角镇",
                                "parentId": "442000",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "中山市",
                                "county": "三角镇",

                            },
                            {
                                "cityCode": "442016",
                                "cityName": "民众镇",
                                "parentId": "442000",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "中山市",
                                "county": "民众镇",

                            },
                            {
                                "cityCode": "442017",
                                "cityName": "南朗镇",
                                "parentId": "442000",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "中山市",
                                "county": "南朗镇",

                            },
                            {
                                "cityCode": "442018",
                                "cityName": "港口镇",
                                "parentId": "442000",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "中山市",
                                "county": "港口镇",

                            },
                            {
                                "cityCode": "442019",
                                "cityName": "大涌镇",
                                "parentId": "442000",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "中山市",
                                "county": "大涌镇",

                            },
                            {
                                "cityCode": "442020",
                                "cityName": "沙溪镇",
                                "parentId": "442000",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "中山市",
                                "county": "沙溪镇",

                            },
                            {
                                "cityCode": "442021",
                                "cityName": "三乡镇",
                                "parentId": "442000",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "中山市",
                                "county": "三乡镇",

                            },
                            {
                                "cityCode": "442022",
                                "cityName": "板芙镇",
                                "parentId": "442000",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "中山市",
                                "county": "板芙镇",

                            },
                            {
                                "cityCode": "442023",
                                "cityName": "神湾镇",
                                "parentId": "442000",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "中山市",
                                "county": "神湾镇",

                            },
                            {
                                "cityCode": "442024",
                                "cityName": "坦洲镇",
                                "parentId": "442000",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "中山市",
                                "county": "坦洲镇",

                            }
                        ]
                    },
                    {
                        "cityCode": "442099",
                        "cityName": "中山市",
                        "parentId": "440000",
                        "provinceType": "3",
                        "province": "广东省",
                        "city": "中山市",
                        "county": "中山市",

                    },
                    {
                        "cityCode": "445100",
                        "cityName": "潮州市",
                        "parentId": "440000",
                        "provinceType": "2",
                        "province": "广东省",
                        "city": "潮州市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "445101",
                                "cityName": "潮州市",
                                "parentId": "445100",
                                "provinceType": "2",
                                "province": "广东省",
                                "city": "潮州市",
                                "county": "",

                            },
                            {
                                "cityCode": "445102",
                                "cityName": "湘桥区",
                                "parentId": "445100",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "潮州市",
                                "county": "湘桥区",

                            },
                            {
                                "cityCode": "445103",
                                "cityName": "潮安区",
                                "parentId": "445100",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "潮州市",
                                "county": "潮安区",

                            },
                            {
                                "cityCode": "445121",
                                "cityName": "潮安区",
                                "parentId": "445100",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "潮州市",
                                "county": "潮安区",

                            },
                            {
                                "cityCode": "445122",
                                "cityName": "饶平县",
                                "parentId": "445100",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "潮州市",
                                "county": "饶平县",

                            }
                        ]
                    },
                    {
                        "cityCode": "445200",
                        "cityName": "揭阳市",
                        "parentId": "440000",
                        "provinceType": "2",
                        "province": "广东省",
                        "city": "揭阳市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "445201",
                                "cityName": "揭阳市",
                                "parentId": "445200",
                                "provinceType": "2",
                                "province": "广东省",
                                "city": "揭阳市",
                                "county": "",

                            },
                            {
                                "cityCode": "445202",
                                "cityName": "榕城区",
                                "parentId": "445200",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "揭阳市",
                                "county": "榕城区",

                            },
                            {
                                "cityCode": "445203",
                                "cityName": "揭东区",
                                "parentId": "445200",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "揭阳市",
                                "county": "揭东区",

                            },
                            {
                                "cityCode": "445204",
                                "cityName": "经济开发试验区",
                                "parentId": "445200",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "揭阳市",
                                "county": "经济开发试验区",

                            },
                            {
                                "cityCode": "445221",
                                "cityName": "揭东区",
                                "parentId": "445200",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "揭阳市",
                                "county": "揭东区",

                            },
                            {
                                "cityCode": "445222",
                                "cityName": "揭西县",
                                "parentId": "445200",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "揭阳市",
                                "county": "揭西县",

                            },
                            {
                                "cityCode": "445224",
                                "cityName": "惠来县",
                                "parentId": "445200",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "揭阳市",
                                "county": "惠来县",

                            },
                            {
                                "cityCode": "445281",
                                "cityName": "普宁市",
                                "parentId": "445200",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "揭阳市",
                                "county": "普宁市",

                            }
                        ]
                    },
                    {
                        "cityCode": "445300",
                        "cityName": "云浮市",
                        "parentId": "440000",
                        "provinceType": "2",
                        "province": "广东省",
                        "city": "云浮市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "445301",
                                "cityName": "云浮市",
                                "parentId": "445300",
                                "provinceType": "2",
                                "province": "广东省",
                                "city": "云浮市",
                                "county": "",

                            },
                            {
                                "cityCode": "445302",
                                "cityName": "云城区",
                                "parentId": "445300",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "云浮市",
                                "county": "云城区",

                            },
                            {
                                "cityCode": "445303",
                                "cityName": "云安区",
                                "parentId": "445300",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "云浮市",
                                "county": "云安区",

                            },
                            {
                                "cityCode": "445321",
                                "cityName": "新兴县",
                                "parentId": "445300",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "云浮市",
                                "county": "新兴县",

                            },
                            {
                                "cityCode": "445322",
                                "cityName": "郁南县",
                                "parentId": "445300",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "云浮市",
                                "county": "郁南县",

                            },
                            {
                                "cityCode": "445323",
                                "cityName": "云安县",
                                "parentId": "445300",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "云浮市",
                                "county": "云安县",

                            },
                            {
                                "cityCode": "445381",
                                "cityName": "罗定市",
                                "parentId": "445300",
                                "provinceType": "3",
                                "province": "广东省",
                                "city": "云浮市",
                                "county": "罗定市",

                            }
                        ]
                    }
                ]
            },
            {
                "cityCode": "450000",
                "cityName": "广西壮族自治区",
                "parentId": "100000",
                "provinceType": "1",
                "province": "广西壮族自治区",
                "city": "",
                "county": "",
                "counties": [
                    {
                        "cityCode": "450100",
                        "cityName": "南宁市",
                        "parentId": "450000",
                        "provinceType": "2",
                        "province": "广西壮族自治区",
                        "city": "南宁市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "450101",
                                "cityName": "南宁市",
                                "parentId": "450100",
                                "provinceType": "2",
                                "province": "广西壮族自治区",
                                "city": "南宁市",
                                "county": "",

                            },
                            {
                                "cityCode": "450102",
                                "cityName": "兴宁区",
                                "parentId": "450100",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "南宁市",
                                "county": "兴宁区",

                            },
                            {
                                "cityCode": "450103",
                                "cityName": "青秀区",
                                "parentId": "450100",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "南宁市",
                                "county": "青秀区",

                            },
                            {
                                "cityCode": "450105",
                                "cityName": "江南区",
                                "parentId": "450100",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "南宁市",
                                "county": "江南区",

                            },
                            {
                                "cityCode": "450107",
                                "cityName": "西乡塘区",
                                "parentId": "450100",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "南宁市",
                                "county": "西乡塘区",

                            },
                            {
                                "cityCode": "450108",
                                "cityName": "良庆区",
                                "parentId": "450100",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "南宁市",
                                "county": "良庆区",

                            },
                            {
                                "cityCode": "450109",
                                "cityName": "邕宁区",
                                "parentId": "450100",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "南宁市",
                                "county": "邕宁区",

                            },
                            {
                                "cityCode": "450110",
                                "cityName": "武鸣区",
                                "parentId": "450100",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "南宁市",
                                "county": "武鸣区",

                            },
                            {
                                "cityCode": "450122",
                                "cityName": "武鸣县",
                                "parentId": "450100",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "南宁市",
                                "county": "武鸣县",

                            },
                            {
                                "cityCode": "450123",
                                "cityName": "隆安县",
                                "parentId": "450100",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "南宁市",
                                "county": "隆安县",

                            },
                            {
                                "cityCode": "450124",
                                "cityName": "马山县",
                                "parentId": "450100",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "南宁市",
                                "county": "马山县",

                            },
                            {
                                "cityCode": "450125",
                                "cityName": "上林县",
                                "parentId": "450100",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "南宁市",
                                "county": "上林县",

                            },
                            {
                                "cityCode": "450126",
                                "cityName": "宾阳县",
                                "parentId": "450100",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "南宁市",
                                "county": "宾阳县",

                            },
                            {
                                "cityCode": "450127",
                                "cityName": "横县",
                                "parentId": "450100",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "南宁市",
                                "county": "横县",

                            },
                            {
                                "cityCode": "450128",
                                "cityName": "埌东新区",
                                "parentId": "450100",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "南宁市",
                                "county": "埌东新区",

                            }
                        ]
                    },
                    {
                        "cityCode": "450200",
                        "cityName": "柳州市",
                        "parentId": "450000",
                        "provinceType": "2",
                        "province": "广西壮族自治区",
                        "city": "柳州市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "450201",
                                "cityName": "柳州市",
                                "parentId": "450200",
                                "provinceType": "2",
                                "province": "广西壮族自治区",
                                "city": "柳州市",
                                "county": "",

                            },
                            {
                                "cityCode": "450202",
                                "cityName": "城中区",
                                "parentId": "450200",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "柳州市",
                                "county": "城中区",

                            },
                            {
                                "cityCode": "450203",
                                "cityName": "鱼峰区",
                                "parentId": "450200",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "柳州市",
                                "county": "鱼峰区",

                            },
                            {
                                "cityCode": "450204",
                                "cityName": "柳南区",
                                "parentId": "450200",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "柳州市",
                                "county": "柳南区",

                            },
                            {
                                "cityCode": "450205",
                                "cityName": "柳北区",
                                "parentId": "450200",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "柳州市",
                                "county": "柳北区",

                            },
                            {
                                "cityCode": "450206",
                                "cityName": "柳江区",
                                "parentId": "450200",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "柳州市",
                                "county": "柳江区",

                            },
                            {
                                "cityCode": "450221",
                                "cityName": "柳江区",
                                "parentId": "450200",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "柳州市",
                                "county": "柳江区",

                            },
                            {
                                "cityCode": "450222",
                                "cityName": "柳城县",
                                "parentId": "450200",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "柳州市",
                                "county": "柳城县",

                            },
                            {
                                "cityCode": "450223",
                                "cityName": "鹿寨县",
                                "parentId": "450200",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "柳州市",
                                "county": "鹿寨县",

                            },
                            {
                                "cityCode": "450224",
                                "cityName": "融安县",
                                "parentId": "450200",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "柳州市",
                                "county": "融安县",

                            },
                            {
                                "cityCode": "450225",
                                "cityName": "融水苗族自治县",
                                "parentId": "450200",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "柳州市",
                                "county": "融水苗族自治县",

                            },
                            {
                                "cityCode": "450226",
                                "cityName": "三江侗族自治县",
                                "parentId": "450200",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "柳州市",
                                "county": "三江侗族自治县",

                            },
                            {
                                "cityCode": "450227",
                                "cityName": "柳东新区",
                                "parentId": "450200",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "柳州市",
                                "county": "柳东新区",

                            }
                        ]
                    },
                    {
                        "cityCode": "450300",
                        "cityName": "桂林市",
                        "parentId": "450000",
                        "provinceType": "2",
                        "province": "广西壮族自治区",
                        "city": "桂林市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "450301",
                                "cityName": "桂林市",
                                "parentId": "450300",
                                "provinceType": "2",
                                "province": "广西壮族自治区",
                                "city": "桂林市",
                                "county": "",

                            },
                            {
                                "cityCode": "450302",
                                "cityName": "秀峰区",
                                "parentId": "450300",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "桂林市",
                                "county": "秀峰区",

                            },
                            {
                                "cityCode": "450303",
                                "cityName": "叠彩区",
                                "parentId": "450300",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "桂林市",
                                "county": "叠彩区",

                            },
                            {
                                "cityCode": "450304",
                                "cityName": "象山区",
                                "parentId": "450300",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "桂林市",
                                "county": "象山区",

                            },
                            {
                                "cityCode": "450305",
                                "cityName": "七星区",
                                "parentId": "450300",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "桂林市",
                                "county": "七星区",

                            },
                            {
                                "cityCode": "450311",
                                "cityName": "雁山区",
                                "parentId": "450300",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "桂林市",
                                "county": "雁山区",

                            },
                            {
                                "cityCode": "450312",
                                "cityName": "临桂区",
                                "parentId": "450300",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "桂林市",
                                "county": "临桂区",

                            },
                            {
                                "cityCode": "450321",
                                "cityName": "阳朔县",
                                "parentId": "450300",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "桂林市",
                                "county": "阳朔县",

                            },
                            {
                                "cityCode": "450322",
                                "cityName": "临桂区",
                                "parentId": "450300",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "桂林市",
                                "county": "临桂区",

                            },
                            {
                                "cityCode": "450323",
                                "cityName": "灵川县",
                                "parentId": "450300",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "桂林市",
                                "county": "灵川县",

                            },
                            {
                                "cityCode": "450324",
                                "cityName": "全州县",
                                "parentId": "450300",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "桂林市",
                                "county": "全州县",

                            },
                            {
                                "cityCode": "450325",
                                "cityName": "兴安县",
                                "parentId": "450300",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "桂林市",
                                "county": "兴安县",

                            },
                            {
                                "cityCode": "450326",
                                "cityName": "永福县",
                                "parentId": "450300",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "桂林市",
                                "county": "永福县",

                            },
                            {
                                "cityCode": "450327",
                                "cityName": "灌阳县",
                                "parentId": "450300",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "桂林市",
                                "county": "灌阳县",

                            },
                            {
                                "cityCode": "450328",
                                "cityName": "龙胜各族自治县",
                                "parentId": "450300",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "桂林市",
                                "county": "龙胜各族自治县",

                            },
                            {
                                "cityCode": "450329",
                                "cityName": "资源县",
                                "parentId": "450300",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "桂林市",
                                "county": "资源县",

                            },
                            {
                                "cityCode": "450330",
                                "cityName": "平乐县",
                                "parentId": "450300",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "桂林市",
                                "county": "平乐县",

                            },
                            {
                                "cityCode": "450331",
                                "cityName": "荔浦县",
                                "parentId": "450300",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "桂林市",
                                "county": "荔浦县",

                            },
                            {
                                "cityCode": "450332",
                                "cityName": "恭城瑶族自治县",
                                "parentId": "450300",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "桂林市",
                                "county": "恭城瑶族自治县",

                            },
                            {
                                "cityCode": "450381",
                                "cityName": "荔浦市",
                                "parentId": "450300",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "桂林市",
                                "county": "荔浦市",

                            }
                        ]
                    },
                    {
                        "cityCode": "450400",
                        "cityName": "梧州市",
                        "parentId": "450000",
                        "provinceType": "2",
                        "province": "广西壮族自治区",
                        "city": "梧州市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "450401",
                                "cityName": "梧州市",
                                "parentId": "450400",
                                "provinceType": "2",
                                "province": "广西壮族自治区",
                                "city": "梧州市",
                                "county": "",

                            },
                            {
                                "cityCode": "450403",
                                "cityName": "万秀区",
                                "parentId": "450400",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "梧州市",
                                "county": "万秀区",

                            },
                            {
                                "cityCode": "450404",
                                "cityName": "蝶山区",
                                "parentId": "450400",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "梧州市",
                                "county": "蝶山区",

                            },
                            {
                                "cityCode": "450405",
                                "cityName": "长洲区",
                                "parentId": "450400",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "梧州市",
                                "county": "长洲区",

                            },
                            {
                                "cityCode": "450406",
                                "cityName": "龙圩区",
                                "parentId": "450400",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "梧州市",
                                "county": "龙圩区",

                            },
                            {
                                "cityCode": "450421",
                                "cityName": "苍梧县",
                                "parentId": "450400",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "梧州市",
                                "county": "苍梧县",

                            },
                            {
                                "cityCode": "450422",
                                "cityName": "藤县",
                                "parentId": "450400",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "梧州市",
                                "county": "藤县",

                            },
                            {
                                "cityCode": "450423",
                                "cityName": "蒙山县",
                                "parentId": "450400",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "梧州市",
                                "county": "蒙山县",

                            },
                            {
                                "cityCode": "450481",
                                "cityName": "岑溪市",
                                "parentId": "450400",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "梧州市",
                                "county": "岑溪市",

                            }
                        ]
                    },
                    {
                        "cityCode": "450500",
                        "cityName": "北海市",
                        "parentId": "450000",
                        "provinceType": "2",
                        "province": "广西壮族自治区",
                        "city": "北海市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "450501",
                                "cityName": "北海市",
                                "parentId": "450500",
                                "provinceType": "2",
                                "province": "广西壮族自治区",
                                "city": "北海市",
                                "county": "",

                            },
                            {
                                "cityCode": "450502",
                                "cityName": "海城区",
                                "parentId": "450500",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "北海市",
                                "county": "海城区",

                            },
                            {
                                "cityCode": "450503",
                                "cityName": "银海区",
                                "parentId": "450500",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "北海市",
                                "county": "银海区",

                            },
                            {
                                "cityCode": "450512",
                                "cityName": "铁山港区",
                                "parentId": "450500",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "北海市",
                                "county": "铁山港区",

                            },
                            {
                                "cityCode": "450521",
                                "cityName": "合浦县",
                                "parentId": "450500",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "北海市",
                                "county": "合浦县",

                            }
                        ]
                    },
                    {
                        "cityCode": "450600",
                        "cityName": "防城港市",
                        "parentId": "450000",
                        "provinceType": "2",
                        "province": "广西壮族自治区",
                        "city": "防城港市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "450601",
                                "cityName": "防城港市",
                                "parentId": "450600",
                                "provinceType": "2",
                                "province": "广西壮族自治区",
                                "city": "防城港市",
                                "county": "",

                            },
                            {
                                "cityCode": "450602",
                                "cityName": "港口区",
                                "parentId": "450600",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "防城港市",
                                "county": "港口区",

                            },
                            {
                                "cityCode": "450603",
                                "cityName": "防城区",
                                "parentId": "450600",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "防城港市",
                                "county": "防城区",

                            },
                            {
                                "cityCode": "450621",
                                "cityName": "上思县",
                                "parentId": "450600",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "防城港市",
                                "county": "上思县",

                            },
                            {
                                "cityCode": "450681",
                                "cityName": "东兴市",
                                "parentId": "450600",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "防城港市",
                                "county": "东兴市",

                            }
                        ]
                    },
                    {
                        "cityCode": "450700",
                        "cityName": "钦州市",
                        "parentId": "450000",
                        "provinceType": "2",
                        "province": "广西壮族自治区",
                        "city": "钦州市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "450702",
                                "cityName": "钦南区",
                                "parentId": "450700",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "钦州市",
                                "county": "钦南区",

                            },
                            {
                                "cityCode": "450703",
                                "cityName": "钦北区",
                                "parentId": "450700",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "钦州市",
                                "county": "钦北区",

                            },
                            {
                                "cityCode": "450721",
                                "cityName": "灵山县",
                                "parentId": "450700",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "钦州市",
                                "county": "灵山县",

                            },
                            {
                                "cityCode": "450722",
                                "cityName": "浦北县",
                                "parentId": "450700",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "钦州市",
                                "county": "浦北县",

                            }
                        ]
                    },
                    {
                        "cityCode": "450800",
                        "cityName": "贵港市",
                        "parentId": "450000",
                        "provinceType": "2",
                        "province": "广西壮族自治区",
                        "city": "贵港市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "450801",
                                "cityName": "贵港市",
                                "parentId": "450800",
                                "provinceType": "2",
                                "province": "广西壮族自治区",
                                "city": "贵港市",
                                "county": "",

                            },
                            {
                                "cityCode": "450802",
                                "cityName": "港北区",
                                "parentId": "450800",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "贵港市",
                                "county": "港北区",

                            },
                            {
                                "cityCode": "450803",
                                "cityName": "港南区",
                                "parentId": "450800",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "贵港市",
                                "county": "港南区",

                            },
                            {
                                "cityCode": "450804",
                                "cityName": "覃塘区",
                                "parentId": "450800",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "贵港市",
                                "county": "覃塘区",

                            },
                            {
                                "cityCode": "450821",
                                "cityName": "平南县",
                                "parentId": "450800",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "贵港市",
                                "county": "平南县",

                            },
                            {
                                "cityCode": "450881",
                                "cityName": "桂平市",
                                "parentId": "450800",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "贵港市",
                                "county": "桂平市",

                            }
                        ]
                    },
                    {
                        "cityCode": "450900",
                        "cityName": "玉林市",
                        "parentId": "450000",
                        "provinceType": "2",
                        "province": "广西壮族自治区",
                        "city": "玉林市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "450901",
                                "cityName": "玉林市",
                                "parentId": "450900",
                                "provinceType": "2",
                                "province": "广西壮族自治区",
                                "city": "玉林市",
                                "county": "",

                            },
                            {
                                "cityCode": "450902",
                                "cityName": "玉州区",
                                "parentId": "450900",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "玉林市",
                                "county": "玉州区",

                            },
                            {
                                "cityCode": "450903",
                                "cityName": "福绵区",
                                "parentId": "450900",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "玉林市",
                                "county": "福绵区",

                            },
                            {
                                "cityCode": "450904",
                                "cityName": "玉东新区",
                                "parentId": "450900",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "玉林市",
                                "county": "玉东新区",

                            },
                            {
                                "cityCode": "450921",
                                "cityName": "容县",
                                "parentId": "450900",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "玉林市",
                                "county": "容县",

                            },
                            {
                                "cityCode": "450922",
                                "cityName": "陆川县",
                                "parentId": "450900",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "玉林市",
                                "county": "陆川县",

                            },
                            {
                                "cityCode": "450923",
                                "cityName": "博白县",
                                "parentId": "450900",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "玉林市",
                                "county": "博白县",

                            },
                            {
                                "cityCode": "450924",
                                "cityName": "兴业县",
                                "parentId": "450900",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "玉林市",
                                "county": "兴业县",

                            },
                            {
                                "cityCode": "450981",
                                "cityName": "北流市",
                                "parentId": "450900",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "玉林市",
                                "county": "北流市",

                            }
                        ]
                    },
                    {
                        "cityCode": "451000",
                        "cityName": "百色市",
                        "parentId": "450000",
                        "provinceType": "2",
                        "province": "广西壮族自治区",
                        "city": "百色市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "451001",
                                "cityName": "百色市",
                                "parentId": "451000",
                                "provinceType": "2",
                                "province": "广西壮族自治区",
                                "city": "百色市",
                                "county": "",

                            },
                            {
                                "cityCode": "451002",
                                "cityName": "右江区",
                                "parentId": "451000",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "百色市",
                                "county": "右江区",

                            },
                            {
                                "cityCode": "451003",
                                "cityName": "田阳区",
                                "parentId": "451000",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "百色市",
                                "county": "田阳区",

                            },
                            {
                                "cityCode": "451021",
                                "cityName": "田阳县",
                                "parentId": "451000",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "百色市",
                                "county": "田阳县",

                            },
                            {
                                "cityCode": "451022",
                                "cityName": "田东县",
                                "parentId": "451000",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "百色市",
                                "county": "田东县",

                            },
                            {
                                "cityCode": "451023",
                                "cityName": "平果县",
                                "parentId": "451000",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "百色市",
                                "county": "平果县",

                            },
                            {
                                "cityCode": "451024",
                                "cityName": "德保县",
                                "parentId": "451000",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "百色市",
                                "county": "德保县",

                            },
                            {
                                "cityCode": "451025",
                                "cityName": "靖西县",
                                "parentId": "451000",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "百色市",
                                "county": "靖西县",

                            },
                            {
                                "cityCode": "451026",
                                "cityName": "那坡县",
                                "parentId": "451000",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "百色市",
                                "county": "那坡县",

                            },
                            {
                                "cityCode": "451027",
                                "cityName": "凌云县",
                                "parentId": "451000",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "百色市",
                                "county": "凌云县",

                            },
                            {
                                "cityCode": "451028",
                                "cityName": "乐业县",
                                "parentId": "451000",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "百色市",
                                "county": "乐业县",

                            },
                            {
                                "cityCode": "451029",
                                "cityName": "田林县",
                                "parentId": "451000",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "百色市",
                                "county": "田林县",

                            },
                            {
                                "cityCode": "451030",
                                "cityName": "西林县",
                                "parentId": "451000",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "百色市",
                                "county": "西林县",

                            },
                            {
                                "cityCode": "451031",
                                "cityName": "隆林各族自治县",
                                "parentId": "451000",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "百色市",
                                "county": "隆林各族自治县",

                            },
                            {
                                "cityCode": "451081",
                                "cityName": "靖西市",
                                "parentId": "451000",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "百色市",
                                "county": "靖西市",

                            },
                            {
                                "cityCode": "451082",
                                "cityName": "平果市",
                                "parentId": "451000",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "百色市",
                                "county": "平果市",

                            }
                        ]
                    },
                    {
                        "cityCode": "451100",
                        "cityName": "贺州市",
                        "parentId": "450000",
                        "provinceType": "2",
                        "province": "广西壮族自治区",
                        "city": "贺州市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "451101",
                                "cityName": "贺州市",
                                "parentId": "451100",
                                "provinceType": "2",
                                "province": "广西壮族自治区",
                                "city": "贺州市",
                                "county": "",

                            },
                            {
                                "cityCode": "451102",
                                "cityName": "八步区",
                                "parentId": "451100",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "贺州市",
                                "county": "八步区",

                            },
                            {
                                "cityCode": "451103",
                                "cityName": "平桂区",
                                "parentId": "451100",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "贺州市",
                                "county": "平桂区",

                            },
                            {
                                "cityCode": "451121",
                                "cityName": "昭平县",
                                "parentId": "451100",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "贺州市",
                                "county": "昭平县",

                            },
                            {
                                "cityCode": "451122",
                                "cityName": "钟山县",
                                "parentId": "451100",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "贺州市",
                                "county": "钟山县",

                            },
                            {
                                "cityCode": "451123",
                                "cityName": "富川瑶族自治县",
                                "parentId": "451100",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "贺州市",
                                "county": "富川瑶族自治县",

                            }
                        ]
                    },
                    {
                        "cityCode": "451200",
                        "cityName": "河池市",
                        "parentId": "450000",
                        "provinceType": "2",
                        "province": "广西壮族自治区",
                        "city": "河池市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "451201",
                                "cityName": "河池市",
                                "parentId": "451200",
                                "provinceType": "2",
                                "province": "广西壮族自治区",
                                "city": "河池市",
                                "county": "",

                            },
                            {
                                "cityCode": "451202",
                                "cityName": "金城江区",
                                "parentId": "451200",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "河池市",
                                "county": "金城江区",

                            },
                            {
                                "cityCode": "451203",
                                "cityName": "宜州区",
                                "parentId": "451200",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "河池市",
                                "county": "宜州区",

                            },
                            {
                                "cityCode": "451221",
                                "cityName": "南丹县",
                                "parentId": "451200",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "河池市",
                                "county": "南丹县",

                            },
                            {
                                "cityCode": "451222",
                                "cityName": "天峨县",
                                "parentId": "451200",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "河池市",
                                "county": "天峨县",

                            },
                            {
                                "cityCode": "451223",
                                "cityName": "凤山县",
                                "parentId": "451200",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "河池市",
                                "county": "凤山县",

                            },
                            {
                                "cityCode": "451224",
                                "cityName": "东兰县",
                                "parentId": "451200",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "河池市",
                                "county": "东兰县",

                            },
                            {
                                "cityCode": "451225",
                                "cityName": "罗城仫佬族自治县",
                                "parentId": "451200",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "河池市",
                                "county": "罗城仫佬族自治县",

                            },
                            {
                                "cityCode": "451226",
                                "cityName": "环江毛南族自治县",
                                "parentId": "451200",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "河池市",
                                "county": "环江毛南族自治县",

                            },
                            {
                                "cityCode": "451227",
                                "cityName": "巴马瑶族自治县",
                                "parentId": "451200",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "河池市",
                                "county": "巴马瑶族自治县",

                            },
                            {
                                "cityCode": "451228",
                                "cityName": "都安瑶族自治县",
                                "parentId": "451200",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "河池市",
                                "county": "都安瑶族自治县",

                            },
                            {
                                "cityCode": "451229",
                                "cityName": "大化瑶族自治县",
                                "parentId": "451200",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "河池市",
                                "county": "大化瑶族自治县",

                            },
                            {
                                "cityCode": "451281",
                                "cityName": "宜州市",
                                "parentId": "451200",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "河池市",
                                "county": "宜州市",

                            }
                        ]
                    },
                    {
                        "cityCode": "451300",
                        "cityName": "来宾市",
                        "parentId": "450000",
                        "provinceType": "2",
                        "province": "广西壮族自治区",
                        "city": "来宾市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "451301",
                                "cityName": "来宾市",
                                "parentId": "451300",
                                "provinceType": "2",
                                "province": "广西壮族自治区",
                                "city": "来宾市",
                                "county": "",

                            },
                            {
                                "cityCode": "451302",
                                "cityName": "兴宾区",
                                "parentId": "451300",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "来宾市",
                                "county": "兴宾区",

                            },
                            {
                                "cityCode": "451321",
                                "cityName": "忻城县",
                                "parentId": "451300",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "来宾市",
                                "county": "忻城县",

                            },
                            {
                                "cityCode": "451322",
                                "cityName": "象州县",
                                "parentId": "451300",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "来宾市",
                                "county": "象州县",

                            },
                            {
                                "cityCode": "451323",
                                "cityName": "武宣县",
                                "parentId": "451300",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "来宾市",
                                "county": "武宣县",

                            },
                            {
                                "cityCode": "451324",
                                "cityName": "金秀瑶族自治县",
                                "parentId": "451300",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "来宾市",
                                "county": "金秀瑶族自治县",

                            },
                            {
                                "cityCode": "451381",
                                "cityName": "合山市",
                                "parentId": "451300",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "来宾市",
                                "county": "合山市",

                            }
                        ]
                    },
                    {
                        "cityCode": "451400",
                        "cityName": "崇左市",
                        "parentId": "450000",
                        "provinceType": "2",
                        "province": "广西壮族自治区",
                        "city": "崇左市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "451401",
                                "cityName": "崇左市",
                                "parentId": "451400",
                                "provinceType": "2",
                                "province": "广西壮族自治区",
                                "city": "崇左市",
                                "county": "",

                            },
                            {
                                "cityCode": "451402",
                                "cityName": "江州区",
                                "parentId": "451400",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "崇左市",
                                "county": "江州区",

                            },
                            {
                                "cityCode": "451421",
                                "cityName": "扶绥县",
                                "parentId": "451400",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "崇左市",
                                "county": "扶绥县",

                            },
                            {
                                "cityCode": "451422",
                                "cityName": "宁明县",
                                "parentId": "451400",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "崇左市",
                                "county": "宁明县",

                            },
                            {
                                "cityCode": "451423",
                                "cityName": "龙州县",
                                "parentId": "451400",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "崇左市",
                                "county": "龙州县",

                            },
                            {
                                "cityCode": "451424",
                                "cityName": "大新县",
                                "parentId": "451400",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "崇左市",
                                "county": "大新县",

                            },
                            {
                                "cityCode": "451425",
                                "cityName": "天等县",
                                "parentId": "451400",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "崇左市",
                                "county": "天等县",

                            },
                            {
                                "cityCode": "451481",
                                "cityName": "凭祥市",
                                "parentId": "451400",
                                "provinceType": "3",
                                "province": "广西壮族自治区",
                                "city": "崇左市",
                                "county": "凭祥市",

                            }
                        ]
                    }
                ]
            },
            {
                "cityCode": "460000",
                "cityName": "海南省",
                "parentId": "100000",
                "provinceType": "1",
                "province": "海南省",
                "city": "",
                "county": "",
                "counties": [
                    {
                        "cityCode": "460100",
                        "cityName": "海口市",
                        "parentId": "460000",
                        "provinceType": "2",
                        "province": "海南省",
                        "city": "海口市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "460101",
                                "cityName": "海口市",
                                "parentId": "460100",
                                "provinceType": "2",
                                "province": "海南省",
                                "city": "海口市",
                                "county": "",

                            },
                            {
                                "cityCode": "460105",
                                "cityName": "秀英区",
                                "parentId": "460100",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "海口市",
                                "county": "秀英区",

                            },
                            {
                                "cityCode": "460106",
                                "cityName": "龙华区",
                                "parentId": "460100",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "海口市",
                                "county": "龙华区",

                            },
                            {
                                "cityCode": "460107",
                                "cityName": "琼山区",
                                "parentId": "460100",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "海口市",
                                "county": "琼山区",

                            },
                            {
                                "cityCode": "460108",
                                "cityName": "美兰区",
                                "parentId": "460100",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "海口市",
                                "county": "美兰区",

                            }
                        ]
                    },
                    {
                        "cityCode": "460200",
                        "cityName": "三亚市",
                        "parentId": "460000",
                        "provinceType": "2",
                        "province": "海南省",
                        "city": "三亚市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "460201",
                                "cityName": "三亚市",
                                "parentId": "460200",
                                "provinceType": "2",
                                "province": "海南省",
                                "city": "三亚市",
                                "county": "",

                            },
                            {
                                "cityCode": "460202",
                                "cityName": "海棠区",
                                "parentId": "460200",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "三亚",
                                "county": "海棠区",

                            },
                            {
                                "cityCode": "460203",
                                "cityName": "吉阳区",
                                "parentId": "460200",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "三亚",
                                "county": "吉阳区",

                            },
                            {
                                "cityCode": "460204",
                                "cityName": "天涯区",
                                "parentId": "460200",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "三亚",
                                "county": "天涯区",

                            },
                            {
                                "cityCode": "460205",
                                "cityName": "崖州区",
                                "parentId": "460200",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "三亚",
                                "county": "崖州区",

                            }
                        ]
                    },
                    {
                        "cityCode": "460300",
                        "cityName": "三沙市",
                        "parentId": "460000",
                        "provinceType": "2",
                        "province": "海南省",
                        "city": "三沙市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "460321",
                                "cityName": "西沙群岛",
                                "parentId": "460300",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "三沙市",
                                "county": "西沙群岛",

                            },
                            {
                                "cityCode": "460322",
                                "cityName": "南沙群岛",
                                "parentId": "460300",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "三沙市",
                                "county": "南沙群岛",

                            },
                            {
                                "cityCode": "460323",
                                "cityName": "中沙群岛",
                                "parentId": "460300",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "三沙市",
                                "county": "中沙群岛",

                            }
                        ]
                    },
                    {
                        "cityCode": "460400",
                        "cityName": "儋州市",
                        "parentId": "460000",
                        "provinceType": "2",
                        "province": "海南省",
                        "city": "儋州市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "460401",
                                "cityName": "洋浦经济开发区",
                                "parentId": "460400",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "儋州市",
                                "county": "洋浦经济开发区",

                            },
                            {
                                "cityCode": "460402",
                                "cityName": "那大镇",
                                "parentId": "460400",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "儋州市",
                                "county": "那大镇",

                            },
                            {
                                "cityCode": "460403",
                                "cityName": "南丰镇",
                                "parentId": "460400",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "儋州市",
                                "county": "南丰镇",

                            },
                            {
                                "cityCode": "460404",
                                "cityName": "雅星镇",
                                "parentId": "460400",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "儋州市",
                                "county": "雅星镇",

                            },
                            {
                                "cityCode": "460405",
                                "cityName": "和庆镇",
                                "parentId": "460400",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "儋州市",
                                "county": "和庆镇",

                            },
                            {
                                "cityCode": "460406",
                                "cityName": "大成镇",
                                "parentId": "460400",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "儋州市",
                                "county": "大成镇",

                            },
                            {
                                "cityCode": "460407",
                                "cityName": "新州镇",
                                "parentId": "460400",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "儋州市",
                                "county": "新州镇",

                            },
                            {
                                "cityCode": "460408",
                                "cityName": "光村镇",
                                "parentId": "460400",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "儋州市",
                                "county": "光村镇",

                            },
                            {
                                "cityCode": "460409",
                                "cityName": "东成镇",
                                "parentId": "460400",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "儋州市",
                                "county": "东成镇",

                            },
                            {
                                "cityCode": "460410",
                                "cityName": "中和镇",
                                "parentId": "460400",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "儋州市",
                                "county": "中和镇",

                            },
                            {
                                "cityCode": "460411",
                                "cityName": "峨蔓镇",
                                "parentId": "460400",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "儋州市",
                                "county": "峨蔓镇",

                            },
                            {
                                "cityCode": "460412",
                                "cityName": "兰洋镇",
                                "parentId": "460400",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "儋州市",
                                "county": "兰洋镇",

                            },
                            {
                                "cityCode": "460413",
                                "cityName": "王五镇",
                                "parentId": "460400",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "儋州市",
                                "county": "王五镇",

                            },
                            {
                                "cityCode": "460414",
                                "cityName": "排浦镇",
                                "parentId": "460400",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "儋州市",
                                "county": "排浦镇",

                            },
                            {
                                "cityCode": "460415",
                                "cityName": "海头镇",
                                "parentId": "460400",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "儋州市",
                                "county": "海头镇",

                            },
                            {
                                "cityCode": "460416",
                                "cityName": "木棠镇",
                                "parentId": "460400",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "儋州市",
                                "county": "木棠镇",

                            },
                            {
                                "cityCode": "460417",
                                "cityName": "白马井镇",
                                "parentId": "460400",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "儋州市",
                                "county": "白马井镇",

                            },
                            {
                                "cityCode": "460418",
                                "cityName": "三都镇",
                                "parentId": "460400",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "儋州市",
                                "county": "三都镇",

                            },
                            {
                                "cityCode": "460419",
                                "cityName": "西培农场",
                                "parentId": "460400",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "儋州市",
                                "county": "西培农场",

                            },
                            {
                                "cityCode": "460420",
                                "cityName": "西联农场",
                                "parentId": "460400",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "儋州市",
                                "county": "西联农场",

                            },
                            {
                                "cityCode": "460421",
                                "cityName": "蓝洋农场",
                                "parentId": "460400",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "儋州市",
                                "county": "蓝洋农场",

                            },
                            {
                                "cityCode": "460422",
                                "cityName": "八一农场",
                                "parentId": "460400",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "儋州市",
                                "county": "八一农场",

                            },
                            {
                                "cityCode": "460423",
                                "cityName": "西华农场",
                                "parentId": "460400",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "儋州市",
                                "county": "西华农场",

                            },
                            {
                                "cityCode": "460424",
                                "cityName": "西庆农场",
                                "parentId": "460400",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "儋州市",
                                "county": "西庆农场",

                            },
                            {
                                "cityCode": "460425",
                                "cityName": "西流农场",
                                "parentId": "460400",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "儋州市",
                                "county": "西流农场",

                            },
                            {
                                "cityCode": "460426",
                                "cityName": "新盈农场",
                                "parentId": "460400",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "儋州市",
                                "county": "新盈农场",

                            },
                            {
                                "cityCode": "460427",
                                "cityName": "龙山农场",
                                "parentId": "460400",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "儋州市",
                                "county": "龙山农场",

                            },
                            {
                                "cityCode": "460428",
                                "cityName": "红岭农场",
                                "parentId": "460400",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "儋州市",
                                "county": "红岭农场",

                            },
                            {
                                "cityCode": "460450",
                                "cityName": "华南热作学院",
                                "parentId": "460400",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "儋州市",
                                "county": "华南热作学院",

                            }
                        ]
                    },
                    {
                        "cityCode": "469000",
                        "cityName": "省直辖县级行政区划",
                        "parentId": "460000",
                        "provinceType": "2",
                        "province": "海南省",
                        "city": "省直辖县级行政区划",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "469001",
                                "cityName": "五指山市",
                                "parentId": "469000",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "五指山市",
                                "county": "五指山市",
                                "counties": [
                                    {
                                        "cityCode": "469101",
                                        "cityName": "通什镇",
                                        "parentId": "469001",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "五指山市",
                                        "county": "通什镇",

                                    },
                                    {
                                        "cityCode": "469102",
                                        "cityName": "南圣镇",
                                        "parentId": "469001",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "五指山市",
                                        "county": "南圣镇",

                                    },
                                    {
                                        "cityCode": "469103",
                                        "cityName": "毛阳镇",
                                        "parentId": "469001",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "五指山市",
                                        "county": "毛阳镇",

                                    },
                                    {
                                        "cityCode": "469104",
                                        "cityName": "番阳镇",
                                        "parentId": "469001",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "五指山市",
                                        "county": "番阳镇",

                                    },
                                    {
                                        "cityCode": "469105",
                                        "cityName": "畅好乡",
                                        "parentId": "469001",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "五指山市",
                                        "county": "畅好乡",

                                    },
                                    {
                                        "cityCode": "469106",
                                        "cityName": "毛道乡",
                                        "parentId": "469001",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "五指山市",
                                        "county": "毛道乡",

                                    },
                                    {
                                        "cityCode": "469107",
                                        "cityName": "水满乡",
                                        "parentId": "469001",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "五指山市",
                                        "county": "水满乡",

                                    }
                                ]
                            },
                            {
                                "cityCode": "469002",
                                "cityName": "琼海市",
                                "parentId": "469000",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "琼海市",
                                "county": "琼海市",
                                "counties": [
                                    {
                                        "cityCode": "469201",
                                        "cityName": "嘉积镇",
                                        "parentId": "469002",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "琼海市",
                                        "county": "嘉积镇",

                                    },
                                    {
                                        "cityCode": "469202",
                                        "cityName": "万泉镇",
                                        "parentId": "469002",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "琼海市",
                                        "county": "万泉镇",

                                    },
                                    {
                                        "cityCode": "469203",
                                        "cityName": "石壁镇",
                                        "parentId": "469002",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "琼海市",
                                        "county": "石壁镇",

                                    },
                                    {
                                        "cityCode": "469204",
                                        "cityName": "中原镇",
                                        "parentId": "469002",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "琼海市",
                                        "county": "中原镇",

                                    },
                                    {
                                        "cityCode": "469205",
                                        "cityName": "博鳌镇",
                                        "parentId": "469002",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "琼海市",
                                        "county": "博鳌镇",

                                    },
                                    {
                                        "cityCode": "469206",
                                        "cityName": "阳江镇",
                                        "parentId": "469002",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "琼海市",
                                        "county": "阳江镇",

                                    },
                                    {
                                        "cityCode": "469207",
                                        "cityName": "龙江镇",
                                        "parentId": "469002",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "琼海市",
                                        "county": "龙江镇",

                                    },
                                    {
                                        "cityCode": "469208",
                                        "cityName": "潭门镇",
                                        "parentId": "469002",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "琼海市",
                                        "county": "潭门镇",

                                    },
                                    {
                                        "cityCode": "469209",
                                        "cityName": "塔洋镇",
                                        "parentId": "469002",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "琼海市",
                                        "county": "塔洋镇",

                                    },
                                    {
                                        "cityCode": "469210",
                                        "cityName": "长坡镇",
                                        "parentId": "469002",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "琼海市",
                                        "county": "长坡镇",

                                    },
                                    {
                                        "cityCode": "469211",
                                        "cityName": "大路镇",
                                        "parentId": "469002",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "琼海市",
                                        "county": "大路镇",

                                    },
                                    {
                                        "cityCode": "469212",
                                        "cityName": "会山镇",
                                        "parentId": "469002",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "琼海市",
                                        "county": "会山镇",

                                    },
                                    {
                                        "cityCode": "469213",
                                        "cityName": "东太农场",
                                        "parentId": "469002",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "琼海市",
                                        "county": "东太农场",

                                    },
                                    {
                                        "cityCode": "469214",
                                        "cityName": "东红农场",
                                        "parentId": "469002",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "琼海市",
                                        "county": "东红农场",

                                    },
                                    {
                                        "cityCode": "469215",
                                        "cityName": "东升农场",
                                        "parentId": "469002",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "琼海市",
                                        "county": "东升农场",

                                    },
                                    {
                                        "cityCode": "469216",
                                        "cityName": "南俸农场",
                                        "parentId": "469002",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "琼海市",
                                        "county": "南俸农场",

                                    },
                                    {
                                        "cityCode": "469217",
                                        "cityName": "彬村山华侨农场",
                                        "parentId": "469002",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "琼海市",
                                        "county": "彬村山华侨农场",

                                    }
                                ]
                            },
                            {
                                "cityCode": "469005",
                                "cityName": "文昌市",
                                "parentId": "469000",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "文昌市",
                                "county": "文昌市",
                                "counties": [
                                    {
                                        "cityCode": "469501",
                                        "cityName": "文城镇",
                                        "parentId": "469005",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "文昌市",
                                        "county": "文城镇",

                                    },
                                    {
                                        "cityCode": "469502",
                                        "cityName": "重兴镇",
                                        "parentId": "469005",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "文昌市",
                                        "county": "重兴镇",

                                    },
                                    {
                                        "cityCode": "469503",
                                        "cityName": "蓬莱镇",
                                        "parentId": "469005",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "文昌市",
                                        "county": "蓬莱镇",

                                    },
                                    {
                                        "cityCode": "469504",
                                        "cityName": "会文镇",
                                        "parentId": "469005",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "文昌市",
                                        "county": "会文镇",

                                    },
                                    {
                                        "cityCode": "469505",
                                        "cityName": "东路镇",
                                        "parentId": "469005",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "文昌市",
                                        "county": "东路镇",

                                    },
                                    {
                                        "cityCode": "469506",
                                        "cityName": "潭牛镇",
                                        "parentId": "469005",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "文昌市",
                                        "county": "潭牛镇",

                                    },
                                    {
                                        "cityCode": "469507",
                                        "cityName": "东阁镇",
                                        "parentId": "469005",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "文昌市",
                                        "county": "东阁镇",

                                    },
                                    {
                                        "cityCode": "469508",
                                        "cityName": "文教镇",
                                        "parentId": "469005",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "文昌市",
                                        "county": "文教镇",

                                    },
                                    {
                                        "cityCode": "469509",
                                        "cityName": "东郊镇",
                                        "parentId": "469005",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "文昌市",
                                        "county": "东郊镇",

                                    },
                                    {
                                        "cityCode": "469510",
                                        "cityName": "龙楼镇",
                                        "parentId": "469005",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "文昌市",
                                        "county": "龙楼镇",

                                    },
                                    {
                                        "cityCode": "469511",
                                        "cityName": "昌洒镇",
                                        "parentId": "469005",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "文昌市",
                                        "county": "昌洒镇",

                                    },
                                    {
                                        "cityCode": "469512",
                                        "cityName": "翁田镇",
                                        "parentId": "469005",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "文昌市",
                                        "county": "翁田镇",

                                    },
                                    {
                                        "cityCode": "469513",
                                        "cityName": "抱罗镇",
                                        "parentId": "469005",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "文昌市",
                                        "county": "抱罗镇",

                                    },
                                    {
                                        "cityCode": "469514",
                                        "cityName": "冯坡镇",
                                        "parentId": "469005",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "文昌市",
                                        "county": "冯坡镇",

                                    },
                                    {
                                        "cityCode": "469515",
                                        "cityName": "锦山镇",
                                        "parentId": "469005",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "文昌市",
                                        "county": "锦山镇",

                                    },
                                    {
                                        "cityCode": "469516",
                                        "cityName": "铺前镇",
                                        "parentId": "469005",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "文昌市",
                                        "county": "铺前镇",

                                    },
                                    {
                                        "cityCode": "469517",
                                        "cityName": "公坡镇",
                                        "parentId": "469005",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "文昌市",
                                        "county": "公坡镇",

                                    },
                                    {
                                        "cityCode": "469518",
                                        "cityName": "迈号镇",
                                        "parentId": "469005",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "文昌市",
                                        "county": "迈号镇",

                                    },
                                    {
                                        "cityCode": "469519",
                                        "cityName": "清谰镇",
                                        "parentId": "469005",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "文昌市",
                                        "county": "清谰镇",

                                    },
                                    {
                                        "cityCode": "469520",
                                        "cityName": "南阳镇",
                                        "parentId": "469005",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "文昌市",
                                        "county": "南阳镇",

                                    },
                                    {
                                        "cityCode": "469521",
                                        "cityName": "新桥镇",
                                        "parentId": "469005",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "文昌市",
                                        "county": "新桥镇",

                                    },
                                    {
                                        "cityCode": "469522",
                                        "cityName": "头苑镇",
                                        "parentId": "469005",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "文昌市",
                                        "county": "头苑镇",

                                    },
                                    {
                                        "cityCode": "469523",
                                        "cityName": "宝芳乡",
                                        "parentId": "469005",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "文昌市",
                                        "county": "宝芳乡",

                                    },
                                    {
                                        "cityCode": "469524",
                                        "cityName": "龙马乡",
                                        "parentId": "469005",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "文昌市",
                                        "county": "龙马乡",

                                    },
                                    {
                                        "cityCode": "469525",
                                        "cityName": "湖山乡",
                                        "parentId": "469005",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "文昌市",
                                        "county": "湖山乡",

                                    },
                                    {
                                        "cityCode": "469526",
                                        "cityName": "东路农场",
                                        "parentId": "469005",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "文昌市",
                                        "county": "东路农场",

                                    },
                                    {
                                        "cityCode": "469527",
                                        "cityName": "南阳农场",
                                        "parentId": "469005",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "文昌市",
                                        "county": "南阳农场",

                                    },
                                    {
                                        "cityCode": "469528",
                                        "cityName": "罗豆农场",
                                        "parentId": "469005",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "文昌市",
                                        "county": "罗豆农场",

                                    }
                                ]
                            },
                            {
                                "cityCode": "469006",
                                "cityName": "万宁市",
                                "parentId": "469000",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "万宁市",
                                "county": "万宁市",
                                "counties": [
                                    {
                                        "cityCode": "469601",
                                        "cityName": "万城镇",
                                        "parentId": "469006",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "万宁市",
                                        "county": "万城镇",

                                    },
                                    {
                                        "cityCode": "469602",
                                        "cityName": "龙滚镇",
                                        "parentId": "469006",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "万宁市",
                                        "county": "龙滚镇",

                                    },
                                    {
                                        "cityCode": "469603",
                                        "cityName": "和乐镇",
                                        "parentId": "469006",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "万宁市",
                                        "county": "和乐镇",

                                    },
                                    {
                                        "cityCode": "469604",
                                        "cityName": "后安镇",
                                        "parentId": "469006",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "万宁市",
                                        "county": "后安镇",

                                    },
                                    {
                                        "cityCode": "469605",
                                        "cityName": "大茂镇",
                                        "parentId": "469006",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "万宁市",
                                        "county": "大茂镇",

                                    },
                                    {
                                        "cityCode": "469606",
                                        "cityName": "东澳镇",
                                        "parentId": "469006",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "万宁市",
                                        "county": "东澳镇",

                                    },
                                    {
                                        "cityCode": "469607",
                                        "cityName": "礼纪镇",
                                        "parentId": "469006",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "万宁市",
                                        "county": "礼纪镇",

                                    },
                                    {
                                        "cityCode": "469608",
                                        "cityName": "长丰镇",
                                        "parentId": "469006",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "万宁市",
                                        "county": "长丰镇",

                                    },
                                    {
                                        "cityCode": "469609",
                                        "cityName": "山根镇",
                                        "parentId": "469006",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "万宁市",
                                        "county": "山根镇",

                                    },
                                    {
                                        "cityCode": "469610",
                                        "cityName": "北大镇",
                                        "parentId": "469006",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "万宁市",
                                        "county": "北大镇",

                                    },
                                    {
                                        "cityCode": "469611",
                                        "cityName": "南桥镇",
                                        "parentId": "469006",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "万宁市",
                                        "county": "南桥镇",

                                    },
                                    {
                                        "cityCode": "469612",
                                        "cityName": "三更罗镇",
                                        "parentId": "469006",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "万宁市",
                                        "county": "三更罗镇",

                                    },
                                    {
                                        "cityCode": "469613",
                                        "cityName": "东岭农场",
                                        "parentId": "469006",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "万宁市",
                                        "county": "东岭农场",

                                    },
                                    {
                                        "cityCode": "469614",
                                        "cityName": "南林农场",
                                        "parentId": "469006",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "万宁市",
                                        "county": "南林农场",

                                    },
                                    {
                                        "cityCode": "469615",
                                        "cityName": "东兴农场",
                                        "parentId": "469006",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "万宁市",
                                        "county": "东兴农场",

                                    },
                                    {
                                        "cityCode": "469616",
                                        "cityName": "东和农场",
                                        "parentId": "469006",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "万宁市",
                                        "county": "东和农场",

                                    },
                                    {
                                        "cityCode": "469617",
                                        "cityName": "新中农场",
                                        "parentId": "469006",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "万宁市",
                                        "county": "新中农场",

                                    },
                                    {
                                        "cityCode": "469618",
                                        "cityName": "兴隆华侨农场",
                                        "parentId": "469006",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "万宁市",
                                        "county": "兴隆华侨农场",

                                    }
                                ]
                            },
                            {
                                "cityCode": "469007",
                                "cityName": "东方市",
                                "parentId": "469000",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "东方市",
                                "county": "东方市",
                                "counties": [
                                    {
                                        "cityCode": "469701",
                                        "cityName": "八所镇",
                                        "parentId": "469007",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "东方市",
                                        "county": "八所镇",

                                    },
                                    {
                                        "cityCode": "469702",
                                        "cityName": "东河镇",
                                        "parentId": "469007",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "东方市",
                                        "county": "东河镇",

                                    },
                                    {
                                        "cityCode": "469703",
                                        "cityName": "大田镇",
                                        "parentId": "469007",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "东方市",
                                        "county": "大田镇",

                                    },
                                    {
                                        "cityCode": "469704",
                                        "cityName": "感城镇",
                                        "parentId": "469007",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "东方市",
                                        "county": "感城镇",

                                    },
                                    {
                                        "cityCode": "469705",
                                        "cityName": "板桥镇",
                                        "parentId": "469007",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "东方市",
                                        "county": "板桥镇",

                                    },
                                    {
                                        "cityCode": "469706",
                                        "cityName": "三家镇",
                                        "parentId": "469007",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "东方市",
                                        "county": "三家镇",

                                    },
                                    {
                                        "cityCode": "469707",
                                        "cityName": "四更镇",
                                        "parentId": "469007",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "东方市",
                                        "county": "四更镇",

                                    },
                                    {
                                        "cityCode": "469708",
                                        "cityName": "新龙镇",
                                        "parentId": "469007",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "东方市",
                                        "county": "新龙镇",

                                    },
                                    {
                                        "cityCode": "469709",
                                        "cityName": "天安乡",
                                        "parentId": "469007",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "东方市",
                                        "county": "天安乡",

                                    },
                                    {
                                        "cityCode": "469710",
                                        "cityName": "江边乡",
                                        "parentId": "469007",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "东方市",
                                        "county": "江边乡",

                                    },
                                    {
                                        "cityCode": "469711",
                                        "cityName": "广坝农场",
                                        "parentId": "469007",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "东方市",
                                        "county": "广坝农场",

                                    },
                                    {
                                        "cityCode": "469712",
                                        "cityName": "东方华侨农场",
                                        "parentId": "469007",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "东方市",
                                        "county": "东方华侨农场",

                                    }
                                ]
                            },
                            {
                                "cityCode": "469021",
                                "cityName": "定安县",
                                "parentId": "469000",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "省直辖县级行政区划",
                                "county": "定安县",
                                "counties": [
                                    {
                                        "cityCode": "469801",
                                        "cityName": "定城镇",
                                        "parentId": "469021",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "定安县",
                                        "county": "定城镇",

                                    },
                                    {
                                        "cityCode": "469802",
                                        "cityName": "新竹镇",
                                        "parentId": "469021",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "定安县",
                                        "county": "新竹镇",

                                    },
                                    {
                                        "cityCode": "469803",
                                        "cityName": "龙湖镇",
                                        "parentId": "469021",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "定安县",
                                        "county": "龙湖镇",

                                    },
                                    {
                                        "cityCode": "469804",
                                        "cityName": "雷鸣镇",
                                        "parentId": "469021",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "定安县",
                                        "county": "雷鸣镇",

                                    },
                                    {
                                        "cityCode": "469805",
                                        "cityName": "龙门镇",
                                        "parentId": "469021",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "定安县",
                                        "county": "龙门镇",

                                    },
                                    {
                                        "cityCode": "469806",
                                        "cityName": "龙河镇",
                                        "parentId": "469021",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "定安县",
                                        "county": "龙河镇",

                                    },
                                    {
                                        "cityCode": "469807",
                                        "cityName": "岭口镇",
                                        "parentId": "469021",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "定安县",
                                        "county": "岭口镇",

                                    },
                                    {
                                        "cityCode": "469808",
                                        "cityName": "翰林镇",
                                        "parentId": "469021",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "定安县",
                                        "county": "翰林镇",

                                    },
                                    {
                                        "cityCode": "469809",
                                        "cityName": "富文镇",
                                        "parentId": "469021",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "定安县",
                                        "county": "富文镇",

                                    },
                                    {
                                        "cityCode": "469810",
                                        "cityName": "黄竹镇",
                                        "parentId": "469021",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "定安县",
                                        "county": "黄竹镇",

                                    },
                                    {
                                        "cityCode": "469811",
                                        "cityName": "金鸡岭农场",
                                        "parentId": "469021",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "定安县",
                                        "county": "金鸡岭农场",

                                    },
                                    {
                                        "cityCode": "469812",
                                        "cityName": "中瑞农场",
                                        "parentId": "469021",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "定安县",
                                        "county": "中瑞农场",

                                    },
                                    {
                                        "cityCode": "469813",
                                        "cityName": "南海农场",
                                        "parentId": "469021",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "定安县",
                                        "county": "南海农场",

                                    },
                                    {
                                        "cityCode": "469814",
                                        "cityName": "城区",
                                        "parentId": "469021",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "定安县",
                                        "county": "城区",

                                    }
                                ]
                            },
                            {
                                "cityCode": "469022",
                                "cityName": "屯昌县",
                                "parentId": "469000",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "省直辖县级行政区划",
                                "county": "屯昌县",
                                "counties": [
                                    {
                                        "cityCode": "469821",
                                        "cityName": "屯城镇",
                                        "parentId": "469022",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "屯昌县",
                                        "county": "屯城镇",

                                    },
                                    {
                                        "cityCode": "469822",
                                        "cityName": "新兴镇",
                                        "parentId": "469022",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "屯昌县",
                                        "county": "新兴镇",

                                    },
                                    {
                                        "cityCode": "469823",
                                        "cityName": "枫木镇",
                                        "parentId": "469022",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "屯昌县",
                                        "county": "枫木镇",

                                    },
                                    {
                                        "cityCode": "469824",
                                        "cityName": "乌坡镇",
                                        "parentId": "469022",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "屯昌县",
                                        "county": "乌坡镇",

                                    },
                                    {
                                        "cityCode": "469825",
                                        "cityName": "南吕镇",
                                        "parentId": "469022",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "屯昌县",
                                        "county": "南吕镇",

                                    },
                                    {
                                        "cityCode": "469826",
                                        "cityName": "南坤镇",
                                        "parentId": "469022",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "屯昌县",
                                        "county": "南坤镇",

                                    },
                                    {
                                        "cityCode": "469827",
                                        "cityName": "坡心镇",
                                        "parentId": "469022",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "屯昌县",
                                        "county": "坡心镇",

                                    },
                                    {
                                        "cityCode": "469828",
                                        "cityName": "西昌镇",
                                        "parentId": "469022",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "屯昌县",
                                        "county": "西昌镇",

                                    },
                                    {
                                        "cityCode": "469829",
                                        "cityName": "中建农场",
                                        "parentId": "469022",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "屯昌县",
                                        "county": "中建农场",

                                    },
                                    {
                                        "cityCode": "469830",
                                        "cityName": "中坤农场",
                                        "parentId": "469022",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "屯昌县",
                                        "county": "中坤农场",

                                    },
                                    {
                                        "cityCode": "469831",
                                        "cityName": "县城内",
                                        "parentId": "469022",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "屯昌县",
                                        "county": "县城内",

                                    }
                                ]
                            },
                            {
                                "cityCode": "469023",
                                "cityName": "澄迈县",
                                "parentId": "469000",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "省直辖县级行政区划",
                                "county": "澄迈县",
                                "counties": [
                                    {
                                        "cityCode": "469841",
                                        "cityName": "金江镇",
                                        "parentId": "469023",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "澄迈县",
                                        "county": "金江镇",

                                    },
                                    {
                                        "cityCode": "469842",
                                        "cityName": "老城镇",
                                        "parentId": "469023",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "澄迈县",
                                        "county": "老城镇",

                                    },
                                    {
                                        "cityCode": "469843",
                                        "cityName": "瑞溪镇",
                                        "parentId": "469023",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "澄迈县",
                                        "county": "瑞溪镇",

                                    },
                                    {
                                        "cityCode": "469844",
                                        "cityName": "永发镇",
                                        "parentId": "469023",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "澄迈县",
                                        "county": "永发镇",

                                    },
                                    {
                                        "cityCode": "469845",
                                        "cityName": "加乐镇",
                                        "parentId": "469023",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "澄迈县",
                                        "county": "加乐镇",

                                    },
                                    {
                                        "cityCode": "469846",
                                        "cityName": "文儒镇",
                                        "parentId": "469023",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "澄迈县",
                                        "county": "文儒镇",

                                    },
                                    {
                                        "cityCode": "469847",
                                        "cityName": "中兴镇",
                                        "parentId": "469023",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "澄迈县",
                                        "county": "中兴镇",

                                    },
                                    {
                                        "cityCode": "469848",
                                        "cityName": "仁兴镇",
                                        "parentId": "469023",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "澄迈县",
                                        "county": "仁兴镇",

                                    },
                                    {
                                        "cityCode": "469849",
                                        "cityName": "福山镇",
                                        "parentId": "469023",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "澄迈县",
                                        "county": "福山镇",

                                    },
                                    {
                                        "cityCode": "469850",
                                        "cityName": "桥头镇",
                                        "parentId": "469023",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "澄迈县",
                                        "county": "桥头镇",

                                    },
                                    {
                                        "cityCode": "469851",
                                        "cityName": "大丰镇",
                                        "parentId": "469023",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "澄迈县",
                                        "county": "大丰镇",

                                    },
                                    {
                                        "cityCode": "469852",
                                        "cityName": "红光农场",
                                        "parentId": "469023",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "澄迈县",
                                        "county": "红光农场",

                                    },
                                    {
                                        "cityCode": "469853",
                                        "cityName": "西达农场",
                                        "parentId": "469023",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "澄迈县",
                                        "county": "西达农场",

                                    },
                                    {
                                        "cityCode": "469854",
                                        "cityName": "金安农场",
                                        "parentId": "469023",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "澄迈县",
                                        "county": "金安农场",

                                    },
                                    {
                                        "cityCode": "469855",
                                        "cityName": "城区",
                                        "parentId": "469023",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "澄迈县",
                                        "county": "城区",

                                    }
                                ]
                            },
                            {
                                "cityCode": "469024",
                                "cityName": "临高县",
                                "parentId": "469000",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "省直辖县级行政区划",
                                "county": "临高县",
                                "counties": [
                                    {
                                        "cityCode": "469861",
                                        "cityName": "临城镇",
                                        "parentId": "469024",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "临高县",
                                        "county": "临城镇",

                                    },
                                    {
                                        "cityCode": "469862",
                                        "cityName": "波莲镇",
                                        "parentId": "469024",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "临高县",
                                        "county": "波莲镇",

                                    },
                                    {
                                        "cityCode": "469863",
                                        "cityName": "东英镇",
                                        "parentId": "469024",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "临高县",
                                        "county": "东英镇",

                                    },
                                    {
                                        "cityCode": "469864",
                                        "cityName": "博厚镇",
                                        "parentId": "469024",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "临高县",
                                        "county": "博厚镇",

                                    },
                                    {
                                        "cityCode": "469865",
                                        "cityName": "皇桐镇",
                                        "parentId": "469024",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "临高县",
                                        "county": "皇桐镇",

                                    },
                                    {
                                        "cityCode": "469866",
                                        "cityName": "多文镇",
                                        "parentId": "469024",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "临高县",
                                        "county": "多文镇",

                                    },
                                    {
                                        "cityCode": "469867",
                                        "cityName": "和舍镇",
                                        "parentId": "469024",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "临高县",
                                        "county": "和舍镇",

                                    },
                                    {
                                        "cityCode": "469868",
                                        "cityName": "南宝镇",
                                        "parentId": "469024",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "临高县",
                                        "county": "南宝镇",

                                    },
                                    {
                                        "cityCode": "469869",
                                        "cityName": "新盈镇",
                                        "parentId": "469024",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "临高县",
                                        "county": "新盈镇",

                                    },
                                    {
                                        "cityCode": "469870",
                                        "cityName": "调楼镇",
                                        "parentId": "469024",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "临高县",
                                        "county": "调楼镇",

                                    },
                                    {
                                        "cityCode": "469871",
                                        "cityName": "加来镇",
                                        "parentId": "469024",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "临高县",
                                        "county": "加来镇",

                                    },
                                    {
                                        "cityCode": "469872",
                                        "cityName": "红华农场",
                                        "parentId": "469024",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "临高县",
                                        "county": "红华农场",

                                    },
                                    {
                                        "cityCode": "469873",
                                        "cityName": "加来农场",
                                        "parentId": "469024",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "临高县",
                                        "county": "加来农场",

                                    },
                                    {
                                        "cityCode": "469874",
                                        "cityName": "城区",
                                        "parentId": "469024",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "临高县",
                                        "county": "城区",

                                    }
                                ]
                            },
                            {
                                "cityCode": "469025",
                                "cityName": "白沙黎族自治县",
                                "parentId": "469000",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "白沙黎族自治县",
                                "county": "定安县",
                                "counties": [
                                    {
                                        "cityCode": "469881",
                                        "cityName": "牙叉镇",
                                        "parentId": "469025",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "白沙黎族自治县",
                                        "county": "牙叉镇",

                                    },
                                    {
                                        "cityCode": "469882",
                                        "cityName": "七坊镇",
                                        "parentId": "469025",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "白沙黎族自治县",
                                        "county": "七坊镇",

                                    },
                                    {
                                        "cityCode": "469883",
                                        "cityName": "邦溪镇",
                                        "parentId": "469025",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "白沙黎族自治县",
                                        "county": "邦溪镇",

                                    },
                                    {
                                        "cityCode": "469884",
                                        "cityName": "打安镇",
                                        "parentId": "469025",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "白沙黎族自治县",
                                        "county": "打安镇",

                                    },
                                    {
                                        "cityCode": "469885",
                                        "cityName": "细水乡",
                                        "parentId": "469025",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "白沙黎族自治县",
                                        "county": "细水乡",

                                    },
                                    {
                                        "cityCode": "469886",
                                        "cityName": "元门乡",
                                        "parentId": "469025",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "白沙黎族自治县",
                                        "county": "元门乡",

                                    },
                                    {
                                        "cityCode": "469887",
                                        "cityName": "南开乡",
                                        "parentId": "469025",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "白沙黎族自治县",
                                        "county": "南开乡",

                                    },
                                    {
                                        "cityCode": "469888",
                                        "cityName": "阜龙乡",
                                        "parentId": "469025",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "白沙黎族自治县",
                                        "county": "阜龙乡",

                                    },
                                    {
                                        "cityCode": "469889",
                                        "cityName": "青松乡",
                                        "parentId": "469025",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "白沙黎族自治县",
                                        "county": "青松乡",

                                    },
                                    {
                                        "cityCode": "469890",
                                        "cityName": "金波乡",
                                        "parentId": "469025",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "白沙黎族自治县",
                                        "county": "金波乡",

                                    },
                                    {
                                        "cityCode": "469891",
                                        "cityName": "荣邦乡",
                                        "parentId": "469025",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "白沙黎族自治县",
                                        "county": "荣邦乡",

                                    },
                                    {
                                        "cityCode": "469892",
                                        "cityName": "白沙农场",
                                        "parentId": "469025",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "白沙黎族自治县",
                                        "county": "白沙农场",

                                    },
                                    {
                                        "cityCode": "469893",
                                        "cityName": "龙江农场",
                                        "parentId": "469025",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "白沙黎族自治县",
                                        "county": "龙江农场",

                                    },
                                    {
                                        "cityCode": "469894",
                                        "cityName": "邦溪农场",
                                        "parentId": "469025",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "白沙黎族自治县",
                                        "county": "邦溪农场",

                                    },
                                    {
                                        "cityCode": "469895",
                                        "cityName": "城区",
                                        "parentId": "469025",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "白沙黎族自治县",
                                        "county": "城区",

                                    }
                                ]
                            },
                            {
                                "cityCode": "469026",
                                "cityName": "昌江黎族自治县",
                                "parentId": "469000",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "昌江黎族自治县",
                                "county": "昌江黎族自治县",
                                "counties": [
                                    {
                                        "cityCode": "469901",
                                        "cityName": "石碌镇",
                                        "parentId": "469026",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "昌江黎族自治县",
                                        "county": "石碌镇",

                                    },
                                    {
                                        "cityCode": "469902",
                                        "cityName": "叉河镇",
                                        "parentId": "469026",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "昌江黎族自治县",
                                        "county": "叉河镇",

                                    },
                                    {
                                        "cityCode": "469903",
                                        "cityName": "十月田镇",
                                        "parentId": "469026",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "昌江黎族自治县",
                                        "county": "十月田镇",

                                    },
                                    {
                                        "cityCode": "469904",
                                        "cityName": "乌烈镇",
                                        "parentId": "469026",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "昌江黎族自治县",
                                        "county": "乌烈镇",

                                    },
                                    {
                                        "cityCode": "469905",
                                        "cityName": "海尾镇",
                                        "parentId": "469026",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "昌江黎族自治县",
                                        "county": "海尾镇",

                                    },
                                    {
                                        "cityCode": "469906",
                                        "cityName": "南罗镇",
                                        "parentId": "469026",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "昌江黎族自治县",
                                        "county": "南罗镇",

                                    },
                                    {
                                        "cityCode": "469907",
                                        "cityName": "太坡镇",
                                        "parentId": "469026",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "昌江黎族自治县",
                                        "county": "太坡镇",

                                    },
                                    {
                                        "cityCode": "469908",
                                        "cityName": "昌化镇",
                                        "parentId": "469026",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "昌江黎族自治县",
                                        "county": "昌化镇",

                                    },
                                    {
                                        "cityCode": "469909",
                                        "cityName": "七叉镇",
                                        "parentId": "469026",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "昌江黎族自治县",
                                        "county": "七叉镇",

                                    },
                                    {
                                        "cityCode": "469910",
                                        "cityName": "保平乡",
                                        "parentId": "469026",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "昌江黎族自治县",
                                        "county": "保平乡",

                                    },
                                    {
                                        "cityCode": "469911",
                                        "cityName": "昌城乡",
                                        "parentId": "469026",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "昌江黎族自治县",
                                        "county": "昌城乡",

                                    },
                                    {
                                        "cityCode": "469912",
                                        "cityName": "王下乡",
                                        "parentId": "469026",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "昌江黎族自治县",
                                        "county": "王下乡",

                                    },
                                    {
                                        "cityCode": "469913",
                                        "cityName": "霸王岭林场",
                                        "parentId": "469026",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "昌江黎族自治县",
                                        "county": "霸王岭林场",

                                    },
                                    {
                                        "cityCode": "469914",
                                        "cityName": "红林农场",
                                        "parentId": "469026",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "昌江黎族自治县",
                                        "county": "红林农场",

                                    },
                                    {
                                        "cityCode": "469915",
                                        "cityName": "城区",
                                        "parentId": "469026",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "昌江黎族自治县",
                                        "county": "城区",

                                    }
                                ]
                            },
                            {
                                "cityCode": "469027",
                                "cityName": "乐东黎族自治县",
                                "parentId": "469000",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "乐东黎族自治县",
                                "county": "澄迈县",
                                "counties": [
                                    {
                                        "cityCode": "469920",
                                        "cityName": "抱由镇",
                                        "parentId": "469027",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "乐东黎族自治县",
                                        "county": "抱由镇",

                                    },
                                    {
                                        "cityCode": "469921",
                                        "cityName": "万冲镇",
                                        "parentId": "469027",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "乐东黎族自治县",
                                        "county": "万冲镇",

                                    },
                                    {
                                        "cityCode": "469922",
                                        "cityName": "大安镇",
                                        "parentId": "469027",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "乐东黎族自治县",
                                        "county": "大安镇",

                                    },
                                    {
                                        "cityCode": "469923",
                                        "cityName": "志仲镇",
                                        "parentId": "469027",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "乐东黎族自治县",
                                        "county": "志仲镇",

                                    },
                                    {
                                        "cityCode": "469924",
                                        "cityName": "千家镇",
                                        "parentId": "469027",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "乐东黎族自治县",
                                        "county": "千家镇",

                                    },
                                    {
                                        "cityCode": "469925",
                                        "cityName": "九所镇",
                                        "parentId": "469027",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "乐东黎族自治县",
                                        "county": "九所镇",

                                    },
                                    {
                                        "cityCode": "469926",
                                        "cityName": "利国镇",
                                        "parentId": "469027",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "乐东黎族自治县",
                                        "county": "利国镇",

                                    },
                                    {
                                        "cityCode": "469927",
                                        "cityName": "黄流镇",
                                        "parentId": "469027",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "乐东黎族自治县",
                                        "county": "黄流镇",

                                    },
                                    {
                                        "cityCode": "469928",
                                        "cityName": "佛罗镇",
                                        "parentId": "469027",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "乐东黎族自治县",
                                        "county": "佛罗镇",

                                    },
                                    {
                                        "cityCode": "469929",
                                        "cityName": "尖峰镇",
                                        "parentId": "469027",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "乐东黎族自治县",
                                        "county": "尖峰镇",

                                    },
                                    {
                                        "cityCode": "469930",
                                        "cityName": "莺歌海镇",
                                        "parentId": "469027",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "乐东黎族自治县",
                                        "county": "莺歌海镇",

                                    },
                                    {
                                        "cityCode": "469931",
                                        "cityName": "乐中农场",
                                        "parentId": "469027",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "乐东黎族自治县",
                                        "county": "乐中农场",

                                    },
                                    {
                                        "cityCode": "469932",
                                        "cityName": "山荣农场",
                                        "parentId": "469027",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "乐东黎族自治县",
                                        "county": "山荣农场",

                                    },
                                    {
                                        "cityCode": "469933",
                                        "cityName": "乐光农场",
                                        "parentId": "469027",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "乐东黎族自治县",
                                        "county": "乐光农场",

                                    },
                                    {
                                        "cityCode": "469934",
                                        "cityName": "报伦农场",
                                        "parentId": "469027",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "乐东黎族自治县",
                                        "county": "报伦农场",

                                    },
                                    {
                                        "cityCode": "469935",
                                        "cityName": "福报农场",
                                        "parentId": "469027",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "乐东黎族自治县",
                                        "county": "福报农场",

                                    },
                                    {
                                        "cityCode": "469936",
                                        "cityName": "保国农场",
                                        "parentId": "469027",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "乐东黎族自治县",
                                        "county": "保国农场",

                                    },
                                    {
                                        "cityCode": "469937",
                                        "cityName": "保显农场",
                                        "parentId": "469027",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "乐东黎族自治县",
                                        "county": "保显农场",

                                    },
                                    {
                                        "cityCode": "469938",
                                        "cityName": "尖峰岭林业",
                                        "parentId": "469027",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "乐东黎族自治县",
                                        "county": "尖峰岭林业",

                                    },
                                    {
                                        "cityCode": "469939",
                                        "cityName": "莺歌海盐场",
                                        "parentId": "469027",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "乐东黎族自治县",
                                        "county": "莺歌海盐场",

                                    },
                                    {
                                        "cityCode": "469940",
                                        "cityName": "城区",
                                        "parentId": "469027",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "乐东黎族自治县",
                                        "county": "城区",

                                    }
                                ]
                            },
                            {
                                "cityCode": "469028",
                                "cityName": "陵水黎族自治县",
                                "parentId": "469000",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "陵水黎族自治县",
                                "county": "临高县",
                                "counties": [
                                    {
                                        "cityCode": "469941",
                                        "cityName": "椰林镇",
                                        "parentId": "469028",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "陵水黎族自治县",
                                        "county": "椰林镇",

                                    },
                                    {
                                        "cityCode": "469942",
                                        "cityName": "光坡镇",
                                        "parentId": "469028",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "陵水黎族自治县",
                                        "county": "光坡镇",

                                    },
                                    {
                                        "cityCode": "469943",
                                        "cityName": "三才镇",
                                        "parentId": "469028",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "陵水黎族自治县",
                                        "county": "三才镇",

                                    },
                                    {
                                        "cityCode": "469944",
                                        "cityName": "英州镇",
                                        "parentId": "469028",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "陵水黎族自治县",
                                        "county": "英州镇",

                                    },
                                    {
                                        "cityCode": "469945",
                                        "cityName": "隆广镇",
                                        "parentId": "469028",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "陵水黎族自治县",
                                        "county": "隆广镇",

                                    },
                                    {
                                        "cityCode": "469946",
                                        "cityName": "文罗镇",
                                        "parentId": "469028",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "陵水黎族自治县",
                                        "county": "文罗镇",

                                    },
                                    {
                                        "cityCode": "469947",
                                        "cityName": "本号镇",
                                        "parentId": "469028",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "陵水黎族自治县",
                                        "county": "本号镇",

                                    },
                                    {
                                        "cityCode": "469948",
                                        "cityName": "新村镇",
                                        "parentId": "469028",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "陵水黎族自治县",
                                        "county": "新村镇",

                                    },
                                    {
                                        "cityCode": "469949",
                                        "cityName": "黎安镇",
                                        "parentId": "469028",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "陵水黎族自治县",
                                        "county": "黎安镇",

                                    },
                                    {
                                        "cityCode": "469950",
                                        "cityName": "提蒙乡",
                                        "parentId": "469028",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "陵水黎族自治县",
                                        "county": "提蒙乡",

                                    },
                                    {
                                        "cityCode": "469951",
                                        "cityName": "群英乡",
                                        "parentId": "469028",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "陵水黎族自治县",
                                        "county": "群英乡",

                                    },
                                    {
                                        "cityCode": "469952",
                                        "cityName": "岭门农场",
                                        "parentId": "469028",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "陵水黎族自治县",
                                        "county": "岭门农场",

                                    },
                                    {
                                        "cityCode": "469953",
                                        "cityName": "南平农场",
                                        "parentId": "469028",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "陵水黎族自治县",
                                        "county": "南平农场",

                                    },
                                    {
                                        "cityCode": "469954",
                                        "cityName": "城区",
                                        "parentId": "469028",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "陵水黎族自治县",
                                        "county": "城区",

                                    }
                                ]
                            },
                            {
                                "cityCode": "469029",
                                "cityName": "保亭黎族苗族自治县",
                                "parentId": "469000",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "省直辖县级行政区划",
                                "county": "保亭黎族苗族自治县",
                                "counties": [
                                    {
                                        "cityCode": "469961",
                                        "cityName": "保城镇",
                                        "parentId": "469029",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "保亭黎族苗族自治县",
                                        "county": "保城镇",

                                    },
                                    {
                                        "cityCode": "469962",
                                        "cityName": "什玲镇",
                                        "parentId": "469029",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "保亭黎族苗族自治县",
                                        "county": "什玲镇",

                                    },
                                    {
                                        "cityCode": "469963",
                                        "cityName": "加茂镇",
                                        "parentId": "469029",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "保亭黎族苗族自治县",
                                        "county": "加茂镇",

                                    },
                                    {
                                        "cityCode": "469964",
                                        "cityName": "响水镇",
                                        "parentId": "469029",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "保亭黎族苗族自治县",
                                        "county": "响水镇",

                                    },
                                    {
                                        "cityCode": "469965",
                                        "cityName": "新政镇",
                                        "parentId": "469029",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "保亭黎族苗族自治县",
                                        "county": "新政镇",

                                    },
                                    {
                                        "cityCode": "469966",
                                        "cityName": "三道镇",
                                        "parentId": "469029",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "保亭黎族苗族自治县",
                                        "county": "三道镇",

                                    },
                                    {
                                        "cityCode": "469967",
                                        "cityName": "六弓乡",
                                        "parentId": "469029",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "保亭黎族苗族自治县",
                                        "county": "六弓乡",

                                    },
                                    {
                                        "cityCode": "469968",
                                        "cityName": "南林乡",
                                        "parentId": "469029",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "保亭黎族苗族自治县",
                                        "county": "南林乡",

                                    },
                                    {
                                        "cityCode": "469969",
                                        "cityName": "毛感乡",
                                        "parentId": "469029",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "保亭黎族苗族自治县",
                                        "county": "毛感乡",

                                    },
                                    {
                                        "cityCode": "469970",
                                        "cityName": "新星农场",
                                        "parentId": "469029",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "保亭黎族苗族自治县",
                                        "county": "新星农场",

                                    },
                                    {
                                        "cityCode": "469971",
                                        "cityName": "金江农场",
                                        "parentId": "469029",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "保亭黎族苗族自治县",
                                        "county": "金江农场",

                                    },
                                    {
                                        "cityCode": "469972",
                                        "cityName": "三道农场",
                                        "parentId": "469029",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "保亭黎族苗族自治县",
                                        "county": "三道农场",

                                    }
                                ]
                            },
                            {
                                "cityCode": "469030",
                                "cityName": "琼中黎族苗族自治县",
                                "parentId": "469000",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "琼中黎族苗族自治县",
                                "county": "白沙黎族自治县",
                                "counties": [
                                    {
                                        "cityCode": "469981",
                                        "cityName": "营根镇",
                                        "parentId": "469030",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "琼中黎族苗族自治县",
                                        "county": "营根镇",

                                    },
                                    {
                                        "cityCode": "469982",
                                        "cityName": "湾岭镇",
                                        "parentId": "469030",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "琼中黎族苗族自治县",
                                        "county": "湾岭镇",

                                    },
                                    {
                                        "cityCode": "469983",
                                        "cityName": "黎母山镇",
                                        "parentId": "469030",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "琼中黎族苗族自治县",
                                        "county": "黎母山镇",

                                    },
                                    {
                                        "cityCode": "469984",
                                        "cityName": "和平镇",
                                        "parentId": "469030",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "琼中黎族苗族自治县",
                                        "county": "和平镇",

                                    },
                                    {
                                        "cityCode": "469985",
                                        "cityName": "长征镇",
                                        "parentId": "469030",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "琼中黎族苗族自治县",
                                        "county": "长征镇",

                                    },
                                    {
                                        "cityCode": "469986",
                                        "cityName": "红毛镇",
                                        "parentId": "469030",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "琼中黎族苗族自治县",
                                        "county": "红毛镇",

                                    },
                                    {
                                        "cityCode": "469987",
                                        "cityName": "中平镇",
                                        "parentId": "469030",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "琼中黎族苗族自治县",
                                        "county": "中平镇",

                                    },
                                    {
                                        "cityCode": "469988",
                                        "cityName": "上安乡",
                                        "parentId": "469030",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "琼中黎族苗族自治县",
                                        "county": "上安乡",

                                    },
                                    {
                                        "cityCode": "469989",
                                        "cityName": "什运乡",
                                        "parentId": "469030",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "琼中黎族苗族自治县",
                                        "county": "什运乡",

                                    },
                                    {
                                        "cityCode": "469990",
                                        "cityName": "吊罗山乡",
                                        "parentId": "469030",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "琼中黎族苗族自治县",
                                        "county": "吊罗山乡",

                                    },
                                    {
                                        "cityCode": "469991",
                                        "cityName": "阳江农场",
                                        "parentId": "469030",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "琼中黎族苗族自治县",
                                        "county": "阳江农场",

                                    },
                                    {
                                        "cityCode": "469992",
                                        "cityName": "乌石农场",
                                        "parentId": "469030",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "琼中黎族苗族自治县",
                                        "county": "乌石农场",

                                    },
                                    {
                                        "cityCode": "469993",
                                        "cityName": "加钗农场",
                                        "parentId": "469030",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "琼中黎族苗族自治县",
                                        "county": "加钗农场",

                                    },
                                    {
                                        "cityCode": "469994",
                                        "cityName": "长征农场",
                                        "parentId": "469030",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "琼中黎族苗族自治县",
                                        "county": "长征农场",

                                    },
                                    {
                                        "cityCode": "469995",
                                        "cityName": "城区",
                                        "parentId": "469030",
                                        "provinceType": "3",
                                        "province": "海南省",
                                        "city": "琼中黎族苗族自治县",
                                        "county": "城区",

                                    }
                                ]
                            },
                            {
                                "cityCode": "469031",
                                "cityName": "昌江黎族自治县",
                                "parentId": "469000",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "昌江黎族自治县",
                                "county": "昌江黎族自治县",

                            },
                            {
                                "cityCode": "469037",
                                "cityName": "西沙群岛",
                                "parentId": "469000",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "海南直辖",
                                "county": "西沙群岛",

                            },
                            {
                                "cityCode": "469038",
                                "cityName": "南沙群岛",
                                "parentId": "469000",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "海南直辖",
                                "county": "南沙群岛",

                            },
                            {
                                "cityCode": "469039",
                                "cityName": "中沙群岛的岛礁及其海域",
                                "parentId": "469000",
                                "provinceType": "3",
                                "province": "海南省",
                                "city": "海南直辖",
                                "county": "中沙群岛的岛礁及其海域",

                            }
                        ]
                    }
                ]
            },
            {
                "cityCode": "500000",
                "cityName": "重庆市",
                "parentId": "100000",
                "provinceType": "1",
                "province": "重庆市",
                "city": "",
                "county": "",
                "counties": [
                    {
                        "cityCode": "500100",
                        "cityName": "重庆市",
                        "parentId": "500000",
                        "provinceType": "2",
                        "province": "重庆市",
                        "city": "重庆市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "500101",
                                "cityName": "万州区",
                                "parentId": "500100",
                                "provinceType": "3",
                                "province": "重庆市",
                                "city": "重庆市",
                                "county": "万州区",

                            },
                            {
                                "cityCode": "500102",
                                "cityName": "涪陵区",
                                "parentId": "500100",
                                "provinceType": "3",
                                "province": "重庆市",
                                "city": "重庆市",
                                "county": "涪陵区",

                            },
                            {
                                "cityCode": "500103",
                                "cityName": "渝中区",
                                "parentId": "500100",
                                "provinceType": "3",
                                "province": "重庆市",
                                "city": "重庆市",
                                "county": "渝中区",

                            },
                            {
                                "cityCode": "500104",
                                "cityName": "大渡口区",
                                "parentId": "500100",
                                "provinceType": "3",
                                "province": "重庆市",
                                "city": "重庆市",
                                "county": "大渡口区",

                            },
                            {
                                "cityCode": "500105",
                                "cityName": "江北区",
                                "parentId": "500100",
                                "provinceType": "3",
                                "province": "重庆市",
                                "city": "重庆市",
                                "county": "江北区",

                            },
                            {
                                "cityCode": "500106",
                                "cityName": "沙坪坝区",
                                "parentId": "500100",
                                "provinceType": "3",
                                "province": "重庆市",
                                "city": "重庆市",
                                "county": "沙坪坝区",

                            },
                            {
                                "cityCode": "500107",
                                "cityName": "九龙坡区",
                                "parentId": "500100",
                                "provinceType": "3",
                                "province": "重庆市",
                                "city": "重庆市",
                                "county": "九龙坡区",

                            },
                            {
                                "cityCode": "500108",
                                "cityName": "南岸区",
                                "parentId": "500100",
                                "provinceType": "3",
                                "province": "重庆市",
                                "city": "重庆市",
                                "county": "南岸区",

                            },
                            {
                                "cityCode": "500109",
                                "cityName": "北碚区",
                                "parentId": "500100",
                                "provinceType": "3",
                                "province": "重庆市",
                                "city": "重庆市",
                                "county": "北碚区",

                            },
                            {
                                "cityCode": "500110",
                                "cityName": "綦江区",
                                "parentId": "500100",
                                "provinceType": "3",
                                "province": "重庆市",
                                "city": "重庆市",
                                "county": "綦江区",

                            },
                            {
                                "cityCode": "500111",
                                "cityName": "大足区",
                                "parentId": "500100",
                                "provinceType": "3",
                                "province": "重庆市",
                                "city": "重庆市",
                                "county": "大足区",

                            },
                            {
                                "cityCode": "500112",
                                "cityName": "渝北区",
                                "parentId": "500100",
                                "provinceType": "3",
                                "province": "重庆市",
                                "city": "重庆市",
                                "county": "渝北区",

                            },
                            {
                                "cityCode": "500113",
                                "cityName": "巴南区",
                                "parentId": "500100",
                                "provinceType": "3",
                                "province": "重庆市",
                                "city": "重庆市",
                                "county": "巴南区",

                            },
                            {
                                "cityCode": "500114",
                                "cityName": "黔江区",
                                "parentId": "500100",
                                "provinceType": "3",
                                "province": "重庆市",
                                "city": "重庆市",
                                "county": "黔江区",

                            },
                            {
                                "cityCode": "500115",
                                "cityName": "长寿区",
                                "parentId": "500100",
                                "provinceType": "3",
                                "province": "重庆市",
                                "city": "重庆市",
                                "county": "长寿区",

                            },
                            {
                                "cityCode": "500116",
                                "cityName": "江津区",
                                "parentId": "500100",
                                "provinceType": "3",
                                "province": "重庆市",
                                "city": "重庆市",
                                "county": "江津区",

                            },
                            {
                                "cityCode": "500117",
                                "cityName": "合川区",
                                "parentId": "500100",
                                "provinceType": "3",
                                "province": "重庆市",
                                "city": "重庆市",
                                "county": "合川区",

                            },
                            {
                                "cityCode": "500118",
                                "cityName": "永川区",
                                "parentId": "500100",
                                "provinceType": "3",
                                "province": "重庆市",
                                "city": "重庆市",
                                "county": "永川区",

                            },
                            {
                                "cityCode": "500119",
                                "cityName": "南川区",
                                "parentId": "500100",
                                "provinceType": "3",
                                "province": "重庆市",
                                "city": "重庆市",
                                "county": "南川区",

                            },
                            {
                                "cityCode": "500120",
                                "cityName": "璧山区",
                                "parentId": "500100",
                                "provinceType": "3",
                                "province": "重庆市",
                                "city": "重庆市",
                                "county": "璧山区",

                            },
                            {
                                "cityCode": "500151",
                                "cityName": "铜梁区",
                                "parentId": "500100",
                                "provinceType": "3",
                                "province": "重庆市",
                                "city": "重庆市",
                                "county": "铜梁区",

                            },
                            {
                                "cityCode": "500152",
                                "cityName": "潼南区",
                                "parentId": "500100",
                                "provinceType": "3",
                                "province": "重庆市",
                                "city": "重庆市",
                                "county": "潼南区",

                            },
                            {
                                "cityCode": "500153",
                                "cityName": "荣昌区",
                                "parentId": "500100",
                                "provinceType": "3",
                                "province": "重庆市",
                                "city": "重庆市",
                                "county": "荣昌区",

                            },
                            {
                                "cityCode": "500154",
                                "cityName": "开州区",
                                "parentId": "500100",
                                "provinceType": "3",
                                "province": "重庆市",
                                "city": "重庆市",
                                "county": "开州区",

                            },
                            {
                                "cityCode": "500155",
                                "cityName": "梁平区",
                                "parentId": "500100",
                                "provinceType": "3",
                                "province": "重庆市",
                                "city": "重庆市",
                                "county": "梁平区",

                            },
                            {
                                "cityCode": "500156",
                                "cityName": "武隆区",
                                "parentId": "500100",
                                "provinceType": "3",
                                "province": "重庆市",
                                "city": "重庆市",
                                "county": "武隆区",

                            },
                            {
                                "cityCode": "500157",
                                "cityName": "万盛区",
                                "parentId": "500100",
                                "provinceType": "3",
                                "province": "重庆市",
                                "city": "重庆市",
                                "county": "万盛区",

                            },
                            {
                                "cityCode": "500199",
                                "cityName": "渝北区",
                                "parentId": "500100",
                                "provinceType": "3",
                                "province": "重庆市",
                                "city": "重庆市",
                                "county": "渝北区",

                            },
                            {
                                "cityCode": "500222",
                                "cityName": "綦江区",
                                "parentId": "500100",
                                "provinceType": "3",
                                "province": "重庆市",
                                "city": "重庆市",
                                "county": "綦江区",

                            },
                            {
                                "cityCode": "500228",
                                "cityName": "梁平县",
                                "parentId": "500100",
                                "provinceType": "3",
                                "province": "重庆市",
                                "city": "重庆市",
                                "county": "梁平县",

                            },
                            {
                                "cityCode": "500229",
                                "cityName": "城口县",
                                "parentId": "500100",
                                "provinceType": "3",
                                "province": "重庆市",
                                "city": "重庆市",
                                "county": "城口县",

                            },
                            {
                                "cityCode": "500230",
                                "cityName": "丰都县",
                                "parentId": "500100",
                                "provinceType": "3",
                                "province": "重庆市",
                                "city": "重庆市",
                                "county": "丰都县",

                            },
                            {
                                "cityCode": "500231",
                                "cityName": "垫江县",
                                "parentId": "500100",
                                "provinceType": "3",
                                "province": "重庆市",
                                "city": "重庆市",
                                "county": "垫江县",

                            },
                            {
                                "cityCode": "500232",
                                "cityName": "武隆县",
                                "parentId": "500100",
                                "provinceType": "3",
                                "province": "重庆市",
                                "city": "重庆市",
                                "county": "武隆县",

                            },
                            {
                                "cityCode": "500233",
                                "cityName": "忠县",
                                "parentId": "500100",
                                "provinceType": "3",
                                "province": "重庆市",
                                "city": "重庆市",
                                "county": "忠县",

                            },
                            {
                                "cityCode": "500234",
                                "cityName": "开县",
                                "parentId": "500100",
                                "provinceType": "3",
                                "province": "重庆市",
                                "city": "重庆市",
                                "county": "开县",

                            },
                            {
                                "cityCode": "500235",
                                "cityName": "云阳县",
                                "parentId": "500100",
                                "provinceType": "3",
                                "province": "重庆市",
                                "city": "重庆市",
                                "county": "云阳县",

                            },
                            {
                                "cityCode": "500236",
                                "cityName": "奉节县",
                                "parentId": "500100",
                                "provinceType": "3",
                                "province": "重庆市",
                                "city": "重庆市",
                                "county": "奉节县",

                            },
                            {
                                "cityCode": "500237",
                                "cityName": "巫山县",
                                "parentId": "500100",
                                "provinceType": "3",
                                "province": "重庆市",
                                "city": "重庆市",
                                "county": "巫山县",

                            },
                            {
                                "cityCode": "500238",
                                "cityName": "巫溪县",
                                "parentId": "500100",
                                "provinceType": "3",
                                "province": "重庆市",
                                "city": "重庆市",
                                "county": "巫溪县",

                            },
                            {
                                "cityCode": "500240",
                                "cityName": "石柱土家族自治县",
                                "parentId": "500100",
                                "provinceType": "3",
                                "province": "重庆市",
                                "city": "重庆市",
                                "county": "石柱土家族自治县",

                            },
                            {
                                "cityCode": "500241",
                                "cityName": "秀山土家族苗族自治县",
                                "parentId": "500100",
                                "provinceType": "3",
                                "province": "重庆市",
                                "city": "重庆市",
                                "county": "秀山土家族苗族自治县",

                            },
                            {
                                "cityCode": "500242",
                                "cityName": "酉阳土家族苗族自治县",
                                "parentId": "500100",
                                "provinceType": "3",
                                "province": "重庆市",
                                "city": "重庆市",
                                "county": "酉阳土家族苗族自治县",

                            },
                            {
                                "cityCode": "500243",
                                "cityName": "彭水苗族土家族自治县",
                                "parentId": "500100",
                                "provinceType": "3",
                                "province": "重庆市",
                                "city": "重庆市",
                                "county": "彭水苗族土家族自治县",

                            },
                            {
                                "cityCode": "500300",
                                "cityName": "经济技术开发区",
                                "parentId": "500100",
                                "provinceType": "3",
                                "province": "重庆市",
                                "city": "重庆市",
                                "county": "经济技术开发区",

                            },
                            {
                                "cityCode": "500301",
                                "cityName": "高新区",
                                "parentId": "500100",
                                "provinceType": "3",
                                "province": "重庆市",
                                "city": "重庆市",
                                "county": "高新区",

                            },
                            {
                                "cityCode": "500302",
                                "cityName": "璧山高新区",
                                "parentId": "500100",
                                "provinceType": "3",
                                "province": "重庆市",
                                "city": "重庆市",
                                "county": "璧山高新区",

                            },
                            {
                                "cityCode": "500303",
                                "cityName": "双桥区",
                                "parentId": "500100",
                                "provinceType": "3",
                                "province": "重庆市",
                                "city": "重庆市",
                                "county": "双桥区",

                            },
                            {
                                "cityCode": "500304",
                                "cityName": "万盛区",
                                "parentId": "500100",
                                "provinceType": "3",
                                "province": "重庆市",
                                "city": "重庆市",
                                "county": "万盛区",

                            },
                            {
                                "cityCode": "500305",
                                "cityName": "两江新区",
                                "parentId": "500100",
                                "provinceType": "3",
                                "province": "重庆市",
                                "city": "重庆市",
                                "county": "两江新区",

                            }
                        ]
                    },
                    {
                        "cityCode": "500223",
                        "cityName": "潼南县",
                        "parentId": "500000",
                        "provinceType": "3",
                        "province": "重庆市",
                        "city": "重庆市",
                        "county": "潼南县",

                    },
                    {
                        "cityCode": "500224",
                        "cityName": "铜梁县",
                        "parentId": "500000",
                        "provinceType": "3",
                        "province": "重庆市",
                        "city": "重庆市",
                        "county": "铜梁县",

                    },
                    {
                        "cityCode": "500225",
                        "cityName": "大足县",
                        "parentId": "500000",
                        "provinceType": "3",
                        "province": "重庆市",
                        "city": "重庆市",
                        "county": "大足县",

                    },
                    {
                        "cityCode": "500226",
                        "cityName": "荣昌县",
                        "parentId": "500000",
                        "provinceType": "3",
                        "province": "重庆市",
                        "city": "重庆市",
                        "county": "荣昌县",

                    },
                    {
                        "cityCode": "500227",
                        "cityName": "璧山县",
                        "parentId": "500000",
                        "provinceType": "3",
                        "province": "重庆市",
                        "city": "重庆市",
                        "county": "璧山县",

                    }
                ]
            },
            {
                "cityCode": "510000",
                "cityName": "四川省",
                "parentId": "100000",
                "provinceType": "1",
                "province": "四川省",
                "city": "",
                "county": "",
                "counties": [
                    {
                        "cityCode": "510100",
                        "cityName": "成都市",
                        "parentId": "510000",
                        "provinceType": "2",
                        "province": "四川省",
                        "city": "成都市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "510101",
                                "cityName": "成都市",
                                "parentId": "510100",
                                "provinceType": "2",
                                "province": "四川省",
                                "city": "成都市",
                                "county": "",

                            },
                            {
                                "cityCode": "510104",
                                "cityName": "锦江区",
                                "parentId": "510100",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "成都市",
                                "county": "锦江区",

                            },
                            {
                                "cityCode": "510105",
                                "cityName": "青羊区",
                                "parentId": "510100",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "成都市",
                                "county": "青羊区",

                            },
                            {
                                "cityCode": "510106",
                                "cityName": "金牛区",
                                "parentId": "510100",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "成都市",
                                "county": "金牛区",

                            },
                            {
                                "cityCode": "510107",
                                "cityName": "武侯区",
                                "parentId": "510100",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "成都市",
                                "county": "武侯区",

                            },
                            {
                                "cityCode": "510108",
                                "cityName": "成华区",
                                "parentId": "510100",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "成都市",
                                "county": "成华区",

                            },
                            {
                                "cityCode": "510112",
                                "cityName": "龙泉驿区",
                                "parentId": "510100",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "成都市",
                                "county": "龙泉驿区",

                            },
                            {
                                "cityCode": "510113",
                                "cityName": "青白江区",
                                "parentId": "510100",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "成都市",
                                "county": "青白江区",

                            },
                            {
                                "cityCode": "510114",
                                "cityName": "新都区",
                                "parentId": "510100",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "成都市",
                                "county": "新都区",

                            },
                            {
                                "cityCode": "510115",
                                "cityName": "温江区",
                                "parentId": "510100",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "成都市",
                                "county": "温江区",

                            },
                            {
                                "cityCode": "510116",
                                "cityName": "双流区",
                                "parentId": "510100",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "成都市",
                                "county": "双流区",

                            },
                            {
                                "cityCode": "510117",
                                "cityName": "郫都区",
                                "parentId": "510100",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "成都市",
                                "county": "郫都区",

                            },
                            {
                                "cityCode": "510118",
                                "cityName": "新津区",
                                "parentId": "510100",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "成都市",
                                "county": "新津区",

                            },
                            {
                                "cityCode": "510121",
                                "cityName": "金堂县",
                                "parentId": "510100",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "成都市",
                                "county": "金堂县",

                            },
                            {
                                "cityCode": "510122",
                                "cityName": "双流县",
                                "parentId": "510100",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "成都市",
                                "county": "双流县",

                            },
                            {
                                "cityCode": "510124",
                                "cityName": "郫县",
                                "parentId": "510100",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "成都市",
                                "county": "郫县",

                            },
                            {
                                "cityCode": "510129",
                                "cityName": "大邑县",
                                "parentId": "510100",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "成都市",
                                "county": "大邑县",

                            },
                            {
                                "cityCode": "510131",
                                "cityName": "蒲江县",
                                "parentId": "510100",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "成都市",
                                "county": "蒲江县",

                            },
                            {
                                "cityCode": "510132",
                                "cityName": "新津县",
                                "parentId": "510100",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "成都市",
                                "county": "新津县",

                            },
                            {
                                "cityCode": "510180",
                                "cityName": "简阳市",
                                "parentId": "510100",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "成都市",
                                "county": "简阳市",

                            },
                            {
                                "cityCode": "510181",
                                "cityName": "都江堰市",
                                "parentId": "510100",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "成都市",
                                "county": "都江堰市",

                            },
                            {
                                "cityCode": "510182",
                                "cityName": "彭州市",
                                "parentId": "510100",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "成都市",
                                "county": "彭州市",

                            },
                            {
                                "cityCode": "510183",
                                "cityName": "邛崃市",
                                "parentId": "510100",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "成都市",
                                "county": "邛崃市",

                            },
                            {
                                "cityCode": "510184",
                                "cityName": "崇州市",
                                "parentId": "510100",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "成都市",
                                "county": "崇州市",

                            },
                            {
                                "cityCode": "510185",
                                "cityName": "简阳市",
                                "parentId": "510100",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "成都市",
                                "county": "简阳市",

                            },
                            {
                                "cityCode": "510186",
                                "cityName": "天府新区",
                                "parentId": "510100",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "成都市",
                                "county": "天府新区",

                            },
                            {
                                "cityCode": "510187",
                                "cityName": "高新南区",
                                "parentId": "510100",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "成都市",
                                "county": "高新南区",

                            },
                            {
                                "cityCode": "510188",
                                "cityName": "高新西区",
                                "parentId": "510100",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "成都市",
                                "county": "高新西区",

                            }
                        ]
                    },
                    {
                        "cityCode": "510300",
                        "cityName": "自贡市",
                        "parentId": "510000",
                        "provinceType": "2",
                        "province": "四川省",
                        "city": "自贡市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "510301",
                                "cityName": "自贡市",
                                "parentId": "510300",
                                "provinceType": "2",
                                "province": "四川省",
                                "city": "自贡市",
                                "county": "",

                            },
                            {
                                "cityCode": "510302",
                                "cityName": "自流井区",
                                "parentId": "510300",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "自贡市",
                                "county": "自流井区",

                            },
                            {
                                "cityCode": "510303",
                                "cityName": "贡井区",
                                "parentId": "510300",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "自贡市",
                                "county": "贡井区",

                            },
                            {
                                "cityCode": "510304",
                                "cityName": "大安区",
                                "parentId": "510300",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "自贡市",
                                "county": "大安区",

                            },
                            {
                                "cityCode": "510311",
                                "cityName": "沿滩区",
                                "parentId": "510300",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "自贡市",
                                "county": "沿滩区",

                            },
                            {
                                "cityCode": "510321",
                                "cityName": "荣县",
                                "parentId": "510300",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "自贡市",
                                "county": "荣县",

                            },
                            {
                                "cityCode": "510322",
                                "cityName": "富顺县",
                                "parentId": "510300",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "自贡市",
                                "county": "富顺县",

                            }
                        ]
                    },
                    {
                        "cityCode": "510400",
                        "cityName": "攀枝花市",
                        "parentId": "510000",
                        "provinceType": "2",
                        "province": "四川省",
                        "city": "攀枝花市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "510401",
                                "cityName": "攀枝花市",
                                "parentId": "510400",
                                "provinceType": "2",
                                "province": "四川省",
                                "city": "攀枝花市",
                                "county": "",

                            },
                            {
                                "cityCode": "510402",
                                "cityName": "东区",
                                "parentId": "510400",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "攀枝花市",
                                "county": "东区",

                            },
                            {
                                "cityCode": "510403",
                                "cityName": "西区",
                                "parentId": "510400",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "攀枝花市",
                                "county": "西区",

                            },
                            {
                                "cityCode": "510411",
                                "cityName": "仁和区",
                                "parentId": "510400",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "攀枝花市",
                                "county": "仁和区",

                            },
                            {
                                "cityCode": "510421",
                                "cityName": "米易县",
                                "parentId": "510400",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "攀枝花市",
                                "county": "米易县",

                            },
                            {
                                "cityCode": "510422",
                                "cityName": "盐边县",
                                "parentId": "510400",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "攀枝花市",
                                "county": "盐边县",

                            }
                        ]
                    },
                    {
                        "cityCode": "510500",
                        "cityName": "泸州市",
                        "parentId": "510000",
                        "provinceType": "2",
                        "province": "四川省",
                        "city": "泸州市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "510501",
                                "cityName": "泸州市",
                                "parentId": "510500",
                                "provinceType": "2",
                                "province": "四川省",
                                "city": "泸州市",
                                "county": "",

                            },
                            {
                                "cityCode": "510502",
                                "cityName": "江阳区",
                                "parentId": "510500",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "泸州市",
                                "county": "江阳区",

                            },
                            {
                                "cityCode": "510503",
                                "cityName": "纳溪区",
                                "parentId": "510500",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "泸州市",
                                "county": "纳溪区",

                            },
                            {
                                "cityCode": "510504",
                                "cityName": "龙马潭区",
                                "parentId": "510500",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "泸州市",
                                "county": "龙马潭区",

                            },
                            {
                                "cityCode": "510521",
                                "cityName": "泸县",
                                "parentId": "510500",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "泸州市",
                                "county": "泸县",

                            },
                            {
                                "cityCode": "510522",
                                "cityName": "合江县",
                                "parentId": "510500",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "泸州市",
                                "county": "合江县",

                            },
                            {
                                "cityCode": "510524",
                                "cityName": "叙永县",
                                "parentId": "510500",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "泸州市",
                                "county": "叙永县",

                            },
                            {
                                "cityCode": "510525",
                                "cityName": "古蔺县",
                                "parentId": "510500",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "泸州市",
                                "county": "古蔺县",

                            }
                        ]
                    },
                    {
                        "cityCode": "510600",
                        "cityName": "德阳市",
                        "parentId": "510000",
                        "provinceType": "2",
                        "province": "四川省",
                        "city": "德阳市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "510601",
                                "cityName": "德阳市",
                                "parentId": "510600",
                                "provinceType": "2",
                                "province": "四川省",
                                "city": "德阳市",
                                "county": "",

                            },
                            {
                                "cityCode": "510603",
                                "cityName": "旌阳区",
                                "parentId": "510600",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "德阳市",
                                "county": "旌阳区",

                            },
                            {
                                "cityCode": "510604",
                                "cityName": "罗江区",
                                "parentId": "510600",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "德阳市",
                                "county": "罗江区",

                            },
                            {
                                "cityCode": "510623",
                                "cityName": "中江县",
                                "parentId": "510600",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "德阳市",
                                "county": "中江县",

                            },
                            {
                                "cityCode": "510626",
                                "cityName": "罗江县",
                                "parentId": "510600",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "德阳市",
                                "county": "罗江县",

                            },
                            {
                                "cityCode": "510681",
                                "cityName": "广汉市",
                                "parentId": "510600",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "德阳市",
                                "county": "广汉市",

                            },
                            {
                                "cityCode": "510682",
                                "cityName": "什邡市",
                                "parentId": "510600",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "德阳市",
                                "county": "什邡市",

                            },
                            {
                                "cityCode": "510683",
                                "cityName": "绵竹市",
                                "parentId": "510600",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "德阳市",
                                "county": "绵竹市",

                            }
                        ]
                    },
                    {
                        "cityCode": "510700",
                        "cityName": "绵阳市",
                        "parentId": "510000",
                        "provinceType": "2",
                        "province": "四川省",
                        "city": "绵阳市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "510701",
                                "cityName": "绵阳市",
                                "parentId": "510700",
                                "provinceType": "2",
                                "province": "四川省",
                                "city": "绵阳市",
                                "county": "",

                            },
                            {
                                "cityCode": "510703",
                                "cityName": "涪城区",
                                "parentId": "510700",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "绵阳市",
                                "county": "涪城区",

                            },
                            {
                                "cityCode": "510704",
                                "cityName": "游仙区",
                                "parentId": "510700",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "绵阳市",
                                "county": "游仙区",

                            },
                            {
                                "cityCode": "510705",
                                "cityName": "安州区",
                                "parentId": "510700",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "绵阳市",
                                "county": "安州区",

                            },
                            {
                                "cityCode": "510722",
                                "cityName": "三台县",
                                "parentId": "510700",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "绵阳市",
                                "county": "三台县",

                            },
                            {
                                "cityCode": "510723",
                                "cityName": "盐亭县",
                                "parentId": "510700",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "绵阳市",
                                "county": "盐亭县",

                            },
                            {
                                "cityCode": "510724",
                                "cityName": "安县",
                                "parentId": "510700",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "绵阳市",
                                "county": "安县",

                            },
                            {
                                "cityCode": "510725",
                                "cityName": "梓潼县",
                                "parentId": "510700",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "绵阳市",
                                "county": "梓潼县",

                            },
                            {
                                "cityCode": "510726",
                                "cityName": "北川羌族自治县",
                                "parentId": "510700",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "绵阳市",
                                "county": "北川羌族自治县",

                            },
                            {
                                "cityCode": "510727",
                                "cityName": "平武县",
                                "parentId": "510700",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "绵阳市",
                                "county": "平武县",

                            },
                            {
                                "cityCode": "510781",
                                "cityName": "江油市",
                                "parentId": "510700",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "绵阳市",
                                "county": "江油市",

                            },
                            {
                                "cityCode": "510782",
                                "cityName": "高新区",
                                "parentId": "510700",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "绵阳市",
                                "county": "高新区",

                            },
                            {
                                "cityCode": "510783",
                                "cityName": "经开区",
                                "parentId": "510700",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "绵阳市",
                                "county": "经开区",

                            }
                        ]
                    },
                    {
                        "cityCode": "510800",
                        "cityName": "广元市",
                        "parentId": "510000",
                        "provinceType": "2",
                        "province": "四川省",
                        "city": "广元市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "510801",
                                "cityName": "广元市",
                                "parentId": "510800",
                                "provinceType": "2",
                                "province": "四川省",
                                "city": "广元市",
                                "county": "",

                            },
                            {
                                "cityCode": "510802",
                                "cityName": "利州区",
                                "parentId": "510800",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "广元市",
                                "county": "利州区",

                            },
                            {
                                "cityCode": "510811",
                                "cityName": "昭化区",
                                "parentId": "510800",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "广元市",
                                "county": "昭化区",

                            },
                            {
                                "cityCode": "510812",
                                "cityName": "朝天区",
                                "parentId": "510800",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "广元市",
                                "county": "朝天区",

                            },
                            {
                                "cityCode": "510821",
                                "cityName": "旺苍县",
                                "parentId": "510800",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "广元市",
                                "county": "旺苍县",

                            },
                            {
                                "cityCode": "510822",
                                "cityName": "青川县",
                                "parentId": "510800",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "广元市",
                                "county": "青川县",

                            },
                            {
                                "cityCode": "510823",
                                "cityName": "剑阁县",
                                "parentId": "510800",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "广元市",
                                "county": "剑阁县",

                            },
                            {
                                "cityCode": "510824",
                                "cityName": "苍溪县",
                                "parentId": "510800",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "广元市",
                                "county": "苍溪县",

                            }
                        ]
                    },
                    {
                        "cityCode": "510900",
                        "cityName": "遂宁市",
                        "parentId": "510000",
                        "provinceType": "2",
                        "province": "四川省",
                        "city": "遂宁市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "510901",
                                "cityName": "遂宁市",
                                "parentId": "510900",
                                "provinceType": "2",
                                "province": "四川省",
                                "city": "遂宁市",
                                "county": "",

                            },
                            {
                                "cityCode": "510903",
                                "cityName": "船山区",
                                "parentId": "510900",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "遂宁市",
                                "county": "船山区",

                            },
                            {
                                "cityCode": "510904",
                                "cityName": "安居区",
                                "parentId": "510900",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "遂宁市",
                                "county": "安居区",

                            },
                            {
                                "cityCode": "510921",
                                "cityName": "蓬溪县",
                                "parentId": "510900",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "遂宁市",
                                "county": "蓬溪县",

                            },
                            {
                                "cityCode": "510922",
                                "cityName": "射洪县",
                                "parentId": "510900",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "遂宁市",
                                "county": "射洪县",

                            },
                            {
                                "cityCode": "510923",
                                "cityName": "大英县",
                                "parentId": "510900",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "遂宁市",
                                "county": "大英县",

                            },
                            {
                                "cityCode": "510924",
                                "cityName": "经济技术开发区",
                                "parentId": "510900",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "遂宁市",
                                "county": "经济技术开发区",

                            },
                            {
                                "cityCode": "510981",
                                "cityName": "射洪市",
                                "parentId": "510900",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "遂宁市",
                                "county": "射洪市",

                            }
                        ]
                    },
                    {
                        "cityCode": "511000",
                        "cityName": "内江市",
                        "parentId": "510000",
                        "provinceType": "2",
                        "province": "四川省",
                        "city": "内江市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "511001",
                                "cityName": "内江市",
                                "parentId": "511000",
                                "provinceType": "2",
                                "province": "四川省",
                                "city": "内江市",
                                "county": "",

                            },
                            {
                                "cityCode": "511002",
                                "cityName": "市中区",
                                "parentId": "511000",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "内江市",
                                "county": "市中区",

                            },
                            {
                                "cityCode": "511011",
                                "cityName": "东兴区",
                                "parentId": "511000",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "内江市",
                                "county": "东兴区",

                            },
                            {
                                "cityCode": "511024",
                                "cityName": "威远县",
                                "parentId": "511000",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "内江市",
                                "county": "威远县",

                            },
                            {
                                "cityCode": "511025",
                                "cityName": "资中县",
                                "parentId": "511000",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "内江市",
                                "county": "资中县",

                            },
                            {
                                "cityCode": "511028",
                                "cityName": "隆昌县",
                                "parentId": "511000",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "内江市",
                                "county": "隆昌县",

                            },
                            {
                                "cityCode": "511071",
                                "cityName": "内江经济开发区",
                                "parentId": "511000",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "内江市",
                                "county": "内江经济开发区",

                            },
                            {
                                "cityCode": "511083",
                                "cityName": "隆昌市",
                                "parentId": "511000",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "内江市",
                                "county": "隆昌市",

                            }
                        ]
                    },
                    {
                        "cityCode": "511100",
                        "cityName": "乐山市",
                        "parentId": "510000",
                        "provinceType": "2",
                        "province": "四川省",
                        "city": "乐山市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "511101",
                                "cityName": "乐山市",
                                "parentId": "511100",
                                "provinceType": "2",
                                "province": "四川省",
                                "city": "乐山市",
                                "county": "",

                            },
                            {
                                "cityCode": "511102",
                                "cityName": "市中区",
                                "parentId": "511100",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "乐山市",
                                "county": "市中区",

                            },
                            {
                                "cityCode": "511111",
                                "cityName": "沙湾区",
                                "parentId": "511100",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "乐山市",
                                "county": "沙湾区",

                            },
                            {
                                "cityCode": "511112",
                                "cityName": "五通桥区",
                                "parentId": "511100",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "乐山市",
                                "county": "五通桥区",

                            },
                            {
                                "cityCode": "511113",
                                "cityName": "金口河区",
                                "parentId": "511100",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "乐山市",
                                "county": "金口河区",

                            },
                            {
                                "cityCode": "511123",
                                "cityName": "犍为县",
                                "parentId": "511100",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "乐山市",
                                "county": "犍为县",

                            },
                            {
                                "cityCode": "511124",
                                "cityName": "井研县",
                                "parentId": "511100",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "乐山市",
                                "county": "井研县",

                            },
                            {
                                "cityCode": "511126",
                                "cityName": "夹江县",
                                "parentId": "511100",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "乐山市",
                                "county": "夹江县",

                            },
                            {
                                "cityCode": "511129",
                                "cityName": "沐川县",
                                "parentId": "511100",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "乐山市",
                                "county": "沐川县",

                            },
                            {
                                "cityCode": "511132",
                                "cityName": "峨边彝族自治县",
                                "parentId": "511100",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "乐山市",
                                "county": "峨边彝族自治县",

                            },
                            {
                                "cityCode": "511133",
                                "cityName": "马边彝族自治县",
                                "parentId": "511100",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "乐山市",
                                "county": "马边彝族自治县",

                            },
                            {
                                "cityCode": "511181",
                                "cityName": "峨眉山市",
                                "parentId": "511100",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "乐山市",
                                "county": "峨眉山市",

                            }
                        ]
                    },
                    {
                        "cityCode": "511300",
                        "cityName": "南充市",
                        "parentId": "510000",
                        "provinceType": "2",
                        "province": "四川省",
                        "city": "南充市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "511301",
                                "cityName": "南充市",
                                "parentId": "511300",
                                "provinceType": "2",
                                "province": "四川省",
                                "city": "南充市",
                                "county": "",

                            },
                            {
                                "cityCode": "511302",
                                "cityName": "顺庆区",
                                "parentId": "511300",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "南充市",
                                "county": "顺庆区",

                            },
                            {
                                "cityCode": "511303",
                                "cityName": "高坪区",
                                "parentId": "511300",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "南充市",
                                "county": "高坪区",

                            },
                            {
                                "cityCode": "511304",
                                "cityName": "嘉陵区",
                                "parentId": "511300",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "南充市",
                                "county": "嘉陵区",

                            },
                            {
                                "cityCode": "511321",
                                "cityName": "南部县",
                                "parentId": "511300",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "南充市",
                                "county": "南部县",

                            },
                            {
                                "cityCode": "511322",
                                "cityName": "营山县",
                                "parentId": "511300",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "南充市",
                                "county": "营山县",

                            },
                            {
                                "cityCode": "511323",
                                "cityName": "蓬安县",
                                "parentId": "511300",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "南充市",
                                "county": "蓬安县",

                            },
                            {
                                "cityCode": "511324",
                                "cityName": "仪陇县",
                                "parentId": "511300",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "南充市",
                                "county": "仪陇县",

                            },
                            {
                                "cityCode": "511325",
                                "cityName": "西充县",
                                "parentId": "511300",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "南充市",
                                "county": "西充县",

                            },
                            {
                                "cityCode": "511381",
                                "cityName": "阆中市",
                                "parentId": "511300",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "南充市",
                                "county": "阆中市",

                            }
                        ]
                    },
                    {
                        "cityCode": "511400",
                        "cityName": "眉山市",
                        "parentId": "510000",
                        "provinceType": "2",
                        "province": "四川省",
                        "city": "眉山市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "511401",
                                "cityName": "眉山市",
                                "parentId": "511400",
                                "provinceType": "2",
                                "province": "四川省",
                                "city": "眉山市",
                                "county": "",

                            },
                            {
                                "cityCode": "511402",
                                "cityName": "东坡区",
                                "parentId": "511400",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "眉山市",
                                "county": "东坡区",

                            },
                            {
                                "cityCode": "511403",
                                "cityName": "彭山区",
                                "parentId": "511400",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "眉山市",
                                "county": "彭山区",

                            },
                            {
                                "cityCode": "511421",
                                "cityName": "仁寿县",
                                "parentId": "511400",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "眉山市",
                                "county": "仁寿县",

                            },
                            {
                                "cityCode": "511422",
                                "cityName": "彭山县",
                                "parentId": "511400",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "眉山市",
                                "county": "彭山县",

                            },
                            {
                                "cityCode": "511423",
                                "cityName": "洪雅县",
                                "parentId": "511400",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "眉山市",
                                "county": "洪雅县",

                            },
                            {
                                "cityCode": "511424",
                                "cityName": "丹棱县",
                                "parentId": "511400",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "眉山市",
                                "county": "丹棱县",

                            },
                            {
                                "cityCode": "511425",
                                "cityName": "青神县",
                                "parentId": "511400",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "眉山市",
                                "county": "青神县",

                            }
                        ]
                    },
                    {
                        "cityCode": "511500",
                        "cityName": "宜宾市",
                        "parentId": "510000",
                        "provinceType": "2",
                        "province": "四川省",
                        "city": "宜宾市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "511501",
                                "cityName": "宜宾市",
                                "parentId": "511500",
                                "provinceType": "2",
                                "province": "四川省",
                                "city": "宜宾市",
                                "county": "",

                            },
                            {
                                "cityCode": "511502",
                                "cityName": "翠屏区",
                                "parentId": "511500",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "宜宾市",
                                "county": "翠屏区",

                            },
                            {
                                "cityCode": "511503",
                                "cityName": "南溪区",
                                "parentId": "511500",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "宜宾市",
                                "county": "南溪区",

                            },
                            {
                                "cityCode": "511504",
                                "cityName": "叙州区",
                                "parentId": "511500",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "宜宾市",
                                "county": "叙州区",

                            },
                            {
                                "cityCode": "511521",
                                "cityName": "宜宾县",
                                "parentId": "511500",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "宜宾市",
                                "county": "宜宾县",

                            },
                            {
                                "cityCode": "511522",
                                "cityName": "南溪县",
                                "parentId": "511500",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "宜宾市",
                                "county": "南溪县",

                            },
                            {
                                "cityCode": "511523",
                                "cityName": "江安县",
                                "parentId": "511500",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "宜宾市",
                                "county": "江安县",

                            },
                            {
                                "cityCode": "511524",
                                "cityName": "长宁县",
                                "parentId": "511500",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "宜宾市",
                                "county": "长宁县",

                            },
                            {
                                "cityCode": "511525",
                                "cityName": "高县",
                                "parentId": "511500",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "宜宾市",
                                "county": "高县",

                            },
                            {
                                "cityCode": "511526",
                                "cityName": "珙县",
                                "parentId": "511500",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "宜宾市",
                                "county": "珙县",

                            },
                            {
                                "cityCode": "511527",
                                "cityName": "筠连县",
                                "parentId": "511500",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "宜宾市",
                                "county": "筠连县",

                            },
                            {
                                "cityCode": "511528",
                                "cityName": "兴文县",
                                "parentId": "511500",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "宜宾市",
                                "county": "兴文县",

                            },
                            {
                                "cityCode": "511529",
                                "cityName": "屏山县",
                                "parentId": "511500",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "宜宾市",
                                "county": "屏山县",

                            }
                        ]
                    },
                    {
                        "cityCode": "511600",
                        "cityName": "广安市",
                        "parentId": "510000",
                        "provinceType": "2",
                        "province": "四川省",
                        "city": "广安市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "511601",
                                "cityName": "广安市",
                                "parentId": "511600",
                                "provinceType": "2",
                                "province": "四川省",
                                "city": "广安市",
                                "county": "",

                            },
                            {
                                "cityCode": "511602",
                                "cityName": "广安区",
                                "parentId": "511600",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "广安市",
                                "county": "广安区",

                            },
                            {
                                "cityCode": "511603",
                                "cityName": "前锋区",
                                "parentId": "511600",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "广安市",
                                "county": "前锋区",

                            },
                            {
                                "cityCode": "511621",
                                "cityName": "岳池县",
                                "parentId": "511600",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "广安市",
                                "county": "岳池县",

                            },
                            {
                                "cityCode": "511622",
                                "cityName": "武胜县",
                                "parentId": "511600",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "广安市",
                                "county": "武胜县",

                            },
                            {
                                "cityCode": "511623",
                                "cityName": "邻水县",
                                "parentId": "511600",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "广安市",
                                "county": "邻水县",

                            },
                            {
                                "cityCode": "511681",
                                "cityName": "华蓥市",
                                "parentId": "511600",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "广安市",
                                "county": "华蓥市",

                            }
                        ]
                    },
                    {
                        "cityCode": "511700",
                        "cityName": "达州市",
                        "parentId": "510000",
                        "provinceType": "2",
                        "province": "四川省",
                        "city": "达州市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "511701",
                                "cityName": "达州市",
                                "parentId": "511700",
                                "provinceType": "2",
                                "province": "四川省",
                                "city": "达州市",
                                "county": "",

                            },
                            {
                                "cityCode": "511702",
                                "cityName": "通川区",
                                "parentId": "511700",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "达州市",
                                "county": "通川区",

                            },
                            {
                                "cityCode": "511703",
                                "cityName": "达川区",
                                "parentId": "511700",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "达州市",
                                "county": "达川区",

                            },
                            {
                                "cityCode": "511721",
                                "cityName": "达县",
                                "parentId": "511700",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "达州市",
                                "county": "达县",

                            },
                            {
                                "cityCode": "511722",
                                "cityName": "宣汉县",
                                "parentId": "511700",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "达州市",
                                "county": "宣汉县",

                            },
                            {
                                "cityCode": "511723",
                                "cityName": "开江县",
                                "parentId": "511700",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "达州市",
                                "county": "开江县",

                            },
                            {
                                "cityCode": "511724",
                                "cityName": "大竹县",
                                "parentId": "511700",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "达州市",
                                "county": "大竹县",

                            },
                            {
                                "cityCode": "511725",
                                "cityName": "渠县",
                                "parentId": "511700",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "达州市",
                                "county": "渠县",

                            },
                            {
                                "cityCode": "511771",
                                "cityName": "达州经济开发区",
                                "parentId": "511700",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "达州市",
                                "county": "达州经济开发区",

                            },
                            {
                                "cityCode": "511781",
                                "cityName": "万源市",
                                "parentId": "511700",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "达州市",
                                "county": "万源市",

                            }
                        ]
                    },
                    {
                        "cityCode": "511800",
                        "cityName": "雅安市",
                        "parentId": "510000",
                        "provinceType": "2",
                        "province": "四川省",
                        "city": "雅安市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "511801",
                                "cityName": "雅安市",
                                "parentId": "511800",
                                "provinceType": "2",
                                "province": "四川省",
                                "city": "雅安市",
                                "county": "",

                            },
                            {
                                "cityCode": "511802",
                                "cityName": "雨城区",
                                "parentId": "511800",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "雅安市",
                                "county": "雨城区",

                            },
                            {
                                "cityCode": "511803",
                                "cityName": "名山区",
                                "parentId": "511800",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "雅安市",
                                "county": "名山区",

                            },
                            {
                                "cityCode": "511821",
                                "cityName": "名山县",
                                "parentId": "511800",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "雅安市",
                                "county": "名山县",

                            },
                            {
                                "cityCode": "511822",
                                "cityName": "荥经县",
                                "parentId": "511800",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "雅安市",
                                "county": "荥经县",

                            },
                            {
                                "cityCode": "511823",
                                "cityName": "汉源县",
                                "parentId": "511800",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "雅安市",
                                "county": "汉源县",

                            },
                            {
                                "cityCode": "511824",
                                "cityName": "石棉县",
                                "parentId": "511800",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "雅安市",
                                "county": "石棉县",

                            },
                            {
                                "cityCode": "511825",
                                "cityName": "天全县",
                                "parentId": "511800",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "雅安市",
                                "county": "天全县",

                            },
                            {
                                "cityCode": "511826",
                                "cityName": "芦山县",
                                "parentId": "511800",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "雅安市",
                                "county": "芦山县",

                            },
                            {
                                "cityCode": "511827",
                                "cityName": "宝兴县",
                                "parentId": "511800",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "雅安市",
                                "county": "宝兴县",

                            }
                        ]
                    },
                    {
                        "cityCode": "511900",
                        "cityName": "巴中市",
                        "parentId": "510000",
                        "provinceType": "2",
                        "province": "四川省",
                        "city": "巴中市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "511901",
                                "cityName": "巴中市",
                                "parentId": "511900",
                                "provinceType": "2",
                                "province": "四川省",
                                "city": "巴中市",
                                "county": "",

                            },
                            {
                                "cityCode": "511902",
                                "cityName": "巴州区",
                                "parentId": "511900",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "巴中市",
                                "county": "巴州区",

                            },
                            {
                                "cityCode": "511903",
                                "cityName": "恩阳区",
                                "parentId": "511900",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "巴中市",
                                "county": "恩阳区",

                            },
                            {
                                "cityCode": "511921",
                                "cityName": "通江县",
                                "parentId": "511900",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "巴中市",
                                "county": "通江县",

                            },
                            {
                                "cityCode": "511922",
                                "cityName": "南江县",
                                "parentId": "511900",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "巴中市",
                                "county": "南江县",

                            },
                            {
                                "cityCode": "511923",
                                "cityName": "平昌县",
                                "parentId": "511900",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "巴中市",
                                "county": "平昌县",

                            },
                            {
                                "cityCode": "511971",
                                "cityName": "巴中经济开发区",
                                "parentId": "511900",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "巴中市",
                                "county": "巴中经济开发区",

                            }
                        ]
                    },
                    {
                        "cityCode": "512000",
                        "cityName": "资阳市",
                        "parentId": "510000",
                        "provinceType": "2",
                        "province": "四川省",
                        "city": "资阳市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "512001",
                                "cityName": "资阳市",
                                "parentId": "512000",
                                "provinceType": "2",
                                "province": "四川省",
                                "city": "资阳市",
                                "county": "",

                            },
                            {
                                "cityCode": "512002",
                                "cityName": "雁江区",
                                "parentId": "512000",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "资阳市",
                                "county": "雁江区",

                            },
                            {
                                "cityCode": "512021",
                                "cityName": "安岳县",
                                "parentId": "512000",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "资阳市",
                                "county": "安岳县",

                            },
                            {
                                "cityCode": "512022",
                                "cityName": "乐至县",
                                "parentId": "512000",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "资阳市",
                                "county": "乐至县",

                            },
                            {
                                "cityCode": "512081",
                                "cityName": "资阳市",
                                "parentId": "512000",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "资阳市",
                                "county": "简阳市",

                            }
                        ]
                    },
                    {
                        "cityCode": "513200",
                        "cityName": "阿坝藏族羌族自治州",
                        "parentId": "510000",
                        "provinceType": "2",
                        "province": "四川省",
                        "city": "阿坝藏族羌族自治州",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "513201",
                                "cityName": "马尔康市",
                                "parentId": "513200",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "阿坝藏族羌族自治州",
                                "county": "马尔康市",

                            },
                            {
                                "cityCode": "513221",
                                "cityName": "汶川县",
                                "parentId": "513200",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "阿坝藏族羌族自治州",
                                "county": "汶川县",

                            },
                            {
                                "cityCode": "513222",
                                "cityName": "理县",
                                "parentId": "513200",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "阿坝藏族羌族自治州",
                                "county": "理县",

                            },
                            {
                                "cityCode": "513223",
                                "cityName": "茂县",
                                "parentId": "513200",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "阿坝藏族羌族自治州",
                                "county": "茂县",

                            },
                            {
                                "cityCode": "513224",
                                "cityName": "松潘县",
                                "parentId": "513200",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "阿坝藏族羌族自治州",
                                "county": "松潘县",

                            },
                            {
                                "cityCode": "513225",
                                "cityName": "九寨沟县",
                                "parentId": "513200",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "阿坝藏族羌族自治州",
                                "county": "九寨沟县",

                            },
                            {
                                "cityCode": "513226",
                                "cityName": "金川县",
                                "parentId": "513200",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "阿坝藏族羌族自治州",
                                "county": "金川县",

                            },
                            {
                                "cityCode": "513227",
                                "cityName": "小金县",
                                "parentId": "513200",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "阿坝藏族羌族自治州",
                                "county": "小金县",

                            },
                            {
                                "cityCode": "513228",
                                "cityName": "黑水县",
                                "parentId": "513200",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "阿坝藏族羌族自治州",
                                "county": "黑水县",

                            },
                            {
                                "cityCode": "513229",
                                "cityName": "马尔康县",
                                "parentId": "513200",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "阿坝藏族羌族自治州",
                                "county": "马尔康县",

                            },
                            {
                                "cityCode": "513230",
                                "cityName": "壤塘县",
                                "parentId": "513200",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "阿坝藏族羌族自治州",
                                "county": "壤塘县",

                            },
                            {
                                "cityCode": "513231",
                                "cityName": "阿坝县",
                                "parentId": "513200",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "阿坝藏族羌族自治州",
                                "county": "阿坝县",

                            },
                            {
                                "cityCode": "513232",
                                "cityName": "若尔盖县",
                                "parentId": "513200",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "阿坝藏族羌族自治州",
                                "county": "若尔盖县",

                            },
                            {
                                "cityCode": "513233",
                                "cityName": "红原县",
                                "parentId": "513200",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "阿坝藏族羌族自治州",
                                "county": "红原县",

                            }
                        ]
                    },
                    {
                        "cityCode": "513300",
                        "cityName": "甘孜藏族自治州",
                        "parentId": "510000",
                        "provinceType": "2",
                        "province": "四川省",
                        "city": "甘孜藏族自治州",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "513301",
                                "cityName": "康定市",
                                "parentId": "513300",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "甘孜藏族自治州",
                                "county": "康定市",

                            },
                            {
                                "cityCode": "513321",
                                "cityName": "康定市",
                                "parentId": "513300",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "甘孜藏族自治州",
                                "county": "康定市",

                            },
                            {
                                "cityCode": "513322",
                                "cityName": "泸定县",
                                "parentId": "513300",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "甘孜藏族自治州",
                                "county": "泸定县",

                            },
                            {
                                "cityCode": "513323",
                                "cityName": "丹巴县",
                                "parentId": "513300",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "甘孜藏族自治州",
                                "county": "丹巴县",

                            },
                            {
                                "cityCode": "513324",
                                "cityName": "九龙县",
                                "parentId": "513300",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "甘孜藏族自治州",
                                "county": "九龙县",

                            },
                            {
                                "cityCode": "513325",
                                "cityName": "雅江县",
                                "parentId": "513300",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "甘孜藏族自治州",
                                "county": "雅江县",

                            },
                            {
                                "cityCode": "513326",
                                "cityName": "道孚县",
                                "parentId": "513300",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "甘孜藏族自治州",
                                "county": "道孚县",

                            },
                            {
                                "cityCode": "513327",
                                "cityName": "炉霍县",
                                "parentId": "513300",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "甘孜藏族自治州",
                                "county": "炉霍县",

                            },
                            {
                                "cityCode": "513328",
                                "cityName": "甘孜县",
                                "parentId": "513300",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "甘孜藏族自治州",
                                "county": "甘孜县",

                            },
                            {
                                "cityCode": "513329",
                                "cityName": "新龙县",
                                "parentId": "513300",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "甘孜藏族自治州",
                                "county": "新龙县",

                            },
                            {
                                "cityCode": "513330",
                                "cityName": "德格县",
                                "parentId": "513300",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "甘孜藏族自治州",
                                "county": "德格县",

                            },
                            {
                                "cityCode": "513331",
                                "cityName": "白玉县",
                                "parentId": "513300",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "甘孜藏族自治州",
                                "county": "白玉县",

                            },
                            {
                                "cityCode": "513332",
                                "cityName": "石渠县",
                                "parentId": "513300",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "甘孜藏族自治州",
                                "county": "石渠县",

                            },
                            {
                                "cityCode": "513333",
                                "cityName": "色达县",
                                "parentId": "513300",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "甘孜藏族自治州",
                                "county": "色达县",

                            },
                            {
                                "cityCode": "513334",
                                "cityName": "理塘县",
                                "parentId": "513300",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "甘孜藏族自治州",
                                "county": "理塘县",

                            },
                            {
                                "cityCode": "513335",
                                "cityName": "巴塘县",
                                "parentId": "513300",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "甘孜藏族自治州",
                                "county": "巴塘县",

                            },
                            {
                                "cityCode": "513336",
                                "cityName": "乡城县",
                                "parentId": "513300",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "甘孜藏族自治州",
                                "county": "乡城县",

                            },
                            {
                                "cityCode": "513337",
                                "cityName": "稻城县",
                                "parentId": "513300",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "甘孜藏族自治州",
                                "county": "稻城县",

                            },
                            {
                                "cityCode": "513338",
                                "cityName": "得荣县",
                                "parentId": "513300",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "甘孜藏族自治州",
                                "county": "得荣县",

                            }
                        ]
                    },
                    {
                        "cityCode": "513400",
                        "cityName": "凉山彝族自治州",
                        "parentId": "510000",
                        "provinceType": "2",
                        "province": "四川省",
                        "city": "凉山彝族自治州",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "513401",
                                "cityName": "西昌市",
                                "parentId": "513400",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "凉山彝族自治州",
                                "county": "西昌市",

                            },
                            {
                                "cityCode": "513422",
                                "cityName": "木里藏族自治县",
                                "parentId": "513400",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "凉山彝族自治州",
                                "county": "木里藏族自治县",

                            },
                            {
                                "cityCode": "513423",
                                "cityName": "盐源县",
                                "parentId": "513400",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "凉山彝族自治州",
                                "county": "盐源县",

                            },
                            {
                                "cityCode": "513424",
                                "cityName": "德昌县",
                                "parentId": "513400",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "凉山彝族自治州",
                                "county": "德昌县",

                            },
                            {
                                "cityCode": "513425",
                                "cityName": "会理县",
                                "parentId": "513400",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "凉山彝族自治州",
                                "county": "会理县",

                            },
                            {
                                "cityCode": "513426",
                                "cityName": "会东县",
                                "parentId": "513400",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "凉山彝族自治州",
                                "county": "会东县",

                            },
                            {
                                "cityCode": "513427",
                                "cityName": "宁南县",
                                "parentId": "513400",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "凉山彝族自治州",
                                "county": "宁南县",

                            },
                            {
                                "cityCode": "513428",
                                "cityName": "普格县",
                                "parentId": "513400",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "凉山彝族自治州",
                                "county": "普格县",

                            },
                            {
                                "cityCode": "513429",
                                "cityName": "布拖县",
                                "parentId": "513400",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "凉山彝族自治州",
                                "county": "布拖县",

                            },
                            {
                                "cityCode": "513430",
                                "cityName": "金阳县",
                                "parentId": "513400",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "凉山彝族自治州",
                                "county": "金阳县",

                            },
                            {
                                "cityCode": "513431",
                                "cityName": "昭觉县",
                                "parentId": "513400",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "凉山彝族自治州",
                                "county": "昭觉县",

                            },
                            {
                                "cityCode": "513432",
                                "cityName": "喜德县",
                                "parentId": "513400",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "凉山彝族自治州",
                                "county": "喜德县",

                            },
                            {
                                "cityCode": "513433",
                                "cityName": "冕宁县",
                                "parentId": "513400",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "凉山彝族自治州",
                                "county": "冕宁县",

                            },
                            {
                                "cityCode": "513434",
                                "cityName": "越西县",
                                "parentId": "513400",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "凉山彝族自治州",
                                "county": "越西县",

                            },
                            {
                                "cityCode": "513435",
                                "cityName": "甘洛县",
                                "parentId": "513400",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "凉山彝族自治州",
                                "county": "甘洛县",

                            },
                            {
                                "cityCode": "513436",
                                "cityName": "美姑县",
                                "parentId": "513400",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "凉山彝族自治州",
                                "county": "美姑县",

                            },
                            {
                                "cityCode": "513437",
                                "cityName": "雷波县",
                                "parentId": "513400",
                                "provinceType": "3",
                                "province": "四川省",
                                "city": "凉山彝族自治州",
                                "county": "雷波县",

                            }
                        ]
                    }
                ]
            },
            {
                "cityCode": "520000",
                "cityName": "贵州省",
                "parentId": "100000",
                "provinceType": "1",
                "province": "贵州省",
                "city": "",
                "county": "",
                "counties": [
                    {
                        "cityCode": "520100",
                        "cityName": "贵阳市",
                        "parentId": "520000",
                        "provinceType": "2",
                        "province": "贵州省",
                        "city": "贵阳市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "520101",
                                "cityName": "贵阳市",
                                "parentId": "520100",
                                "provinceType": "2",
                                "province": "贵州省",
                                "city": "贵阳市",
                                "county": "",

                            },
                            {
                                "cityCode": "520102",
                                "cityName": "南明区",
                                "parentId": "520100",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "贵阳市",
                                "county": "南明区",

                            },
                            {
                                "cityCode": "520103",
                                "cityName": "云岩区",
                                "parentId": "520100",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "贵阳市",
                                "county": "云岩区",

                            },
                            {
                                "cityCode": "520111",
                                "cityName": "花溪区",
                                "parentId": "520100",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "贵阳市",
                                "county": "花溪区",

                            },
                            {
                                "cityCode": "520112",
                                "cityName": "乌当区",
                                "parentId": "520100",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "贵阳市",
                                "county": "乌当区",

                            },
                            {
                                "cityCode": "520113",
                                "cityName": "白云区",
                                "parentId": "520100",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "贵阳市",
                                "county": "白云区",

                            },
                            {
                                "cityCode": "520114",
                                "cityName": "小河区",
                                "parentId": "520100",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "贵阳市",
                                "county": "小河区",

                            },
                            {
                                "cityCode": "520115",
                                "cityName": "观山湖区",
                                "parentId": "520100",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "贵阳市",
                                "county": "观山湖区",

                            },
                            {
                                "cityCode": "520121",
                                "cityName": "开阳县",
                                "parentId": "520100",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "贵阳市",
                                "county": "开阳县",

                            },
                            {
                                "cityCode": "520122",
                                "cityName": "息烽县",
                                "parentId": "520100",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "贵阳市",
                                "county": "息烽县",

                            },
                            {
                                "cityCode": "520123",
                                "cityName": "修文县",
                                "parentId": "520100",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "贵阳市",
                                "county": "修文县",

                            },
                            {
                                "cityCode": "520181",
                                "cityName": "清镇市",
                                "parentId": "520100",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "贵阳市",
                                "county": "清镇市",

                            },
                            {
                                "cityCode": "520182",
                                "cityName": "贵安新区",
                                "parentId": "520100",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "贵阳市",
                                "county": "贵安新区",

                            },
                            {
                                "cityCode": "520183",
                                "cityName": "高新区",
                                "parentId": "520100",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "贵阳市",
                                "county": "高新区",

                            },
                            {
                                "cityCode": "529900",
                                "cityName": "花溪区",
                                "parentId": "520100",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "贵阳市",
                                "county": "花溪区",

                            }
                        ]
                    },
                    {
                        "cityCode": "520200",
                        "cityName": "六盘水市",
                        "parentId": "520000",
                        "provinceType": "2",
                        "province": "贵州省",
                        "city": "六盘水市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "520201",
                                "cityName": "钟山区",
                                "parentId": "520200",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "六盘水市",
                                "county": "钟山区",

                            },
                            {
                                "cityCode": "520203",
                                "cityName": "六枝特区",
                                "parentId": "520200",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "六盘水市",
                                "county": "六枝特区",

                            },
                            {
                                "cityCode": "520204",
                                "cityName": "水城区",
                                "parentId": "520200",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "六盘水市",
                                "county": "水城区",

                            },
                            {
                                "cityCode": "520222",
                                "cityName": "盘县",
                                "parentId": "520200",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "六盘水市",
                                "county": "盘县",

                            },
                            {
                                "cityCode": "520281",
                                "cityName": "盘州市",
                                "parentId": "520200",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "六盘水市",
                                "county": "盘州市",

                            }
                        ]
                    },
                    {
                        "cityCode": "520300",
                        "cityName": "遵义市",
                        "parentId": "520000",
                        "provinceType": "2",
                        "province": "贵州省",
                        "city": "遵义市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "520301",
                                "cityName": "遵义市",
                                "parentId": "520300",
                                "provinceType": "2",
                                "province": "贵州省",
                                "city": "遵义市",
                                "county": "",

                            },
                            {
                                "cityCode": "520302",
                                "cityName": "红花岗区",
                                "parentId": "520300",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "遵义市",
                                "county": "红花岗区",

                            },
                            {
                                "cityCode": "520303",
                                "cityName": "汇川区",
                                "parentId": "520300",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "遵义市",
                                "county": "汇川区",

                            },
                            {
                                "cityCode": "520304",
                                "cityName": "播州区",
                                "parentId": "520300",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "遵义市",
                                "county": "播州区",

                            },
                            {
                                "cityCode": "520321",
                                "cityName": "遵义县",
                                "parentId": "520300",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "遵义市",
                                "county": "遵义县",

                            },
                            {
                                "cityCode": "520322",
                                "cityName": "桐梓县",
                                "parentId": "520300",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "遵义市",
                                "county": "桐梓县",

                            },
                            {
                                "cityCode": "520323",
                                "cityName": "绥阳县",
                                "parentId": "520300",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "遵义市",
                                "county": "绥阳县",

                            },
                            {
                                "cityCode": "520324",
                                "cityName": "正安县",
                                "parentId": "520300",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "遵义市",
                                "county": "正安县",

                            },
                            {
                                "cityCode": "520325",
                                "cityName": "道真仡佬族苗族自治县",
                                "parentId": "520300",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "遵义市",
                                "county": "道真仡佬族苗族自治县",

                            },
                            {
                                "cityCode": "520326",
                                "cityName": "务川仡佬族苗族自治县",
                                "parentId": "520300",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "遵义市",
                                "county": "务川仡佬族苗族自治县",

                            },
                            {
                                "cityCode": "520327",
                                "cityName": "凤冈县",
                                "parentId": "520300",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "遵义市",
                                "county": "凤冈县",

                            },
                            {
                                "cityCode": "520328",
                                "cityName": "湄潭县",
                                "parentId": "520300",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "遵义市",
                                "county": "湄潭县",

                            },
                            {
                                "cityCode": "520329",
                                "cityName": "余庆县",
                                "parentId": "520300",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "遵义市",
                                "county": "余庆县",

                            },
                            {
                                "cityCode": "520330",
                                "cityName": "习水县",
                                "parentId": "520300",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "遵义市",
                                "county": "习水县",

                            },
                            {
                                "cityCode": "520381",
                                "cityName": "赤水市",
                                "parentId": "520300",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "遵义市",
                                "county": "赤水市",

                            },
                            {
                                "cityCode": "520382",
                                "cityName": "仁怀市",
                                "parentId": "520300",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "遵义市",
                                "county": "仁怀市",

                            }
                        ]
                    },
                    {
                        "cityCode": "520400",
                        "cityName": "安顺市",
                        "parentId": "520000",
                        "provinceType": "2",
                        "province": "贵州省",
                        "city": "安顺市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "520401",
                                "cityName": "安顺市",
                                "parentId": "520400",
                                "provinceType": "2",
                                "province": "贵州省",
                                "city": "安顺市",
                                "county": "",

                            },
                            {
                                "cityCode": "520402",
                                "cityName": "西秀区",
                                "parentId": "520400",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "安顺市",
                                "county": "西秀区",

                            },
                            {
                                "cityCode": "520403",
                                "cityName": "平坝区",
                                "parentId": "520400",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "安顺市",
                                "county": "平坝区",

                            },
                            {
                                "cityCode": "520421",
                                "cityName": "平坝区",
                                "parentId": "520400",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "安顺市",
                                "county": "平坝区",

                            },
                            {
                                "cityCode": "520422",
                                "cityName": "普定县",
                                "parentId": "520400",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "安顺市",
                                "county": "普定县",

                            },
                            {
                                "cityCode": "520423",
                                "cityName": "镇宁布依族苗族自治县",
                                "parentId": "520400",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "安顺市",
                                "county": "镇宁布依族苗族自治县",

                            },
                            {
                                "cityCode": "520424",
                                "cityName": "关岭布依族苗族自治县",
                                "parentId": "520400",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "安顺市",
                                "county": "关岭布依族苗族自治县",

                            },
                            {
                                "cityCode": "520425",
                                "cityName": "紫云苗族布依族自治县",
                                "parentId": "520400",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "安顺市",
                                "county": "紫云苗族布依族自治县",

                            }
                        ]
                    },
                    {
                        "cityCode": "520500",
                        "cityName": "毕节市",
                        "parentId": "520000",
                        "provinceType": "2",
                        "province": "贵州省",
                        "city": "毕节市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "520501",
                                "cityName": "市辖区",
                                "parentId": "520500",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "毕节市",
                                "county": "市辖区",

                            },
                            {
                                "cityCode": "520502",
                                "cityName": "七星关区",
                                "parentId": "520500",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "毕节地区",
                                "county": "七星关区",

                            },
                            {
                                "cityCode": "520521",
                                "cityName": "大方县",
                                "parentId": "520500",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "毕节市",
                                "county": "大方县",

                            },
                            {
                                "cityCode": "520523",
                                "cityName": "金沙县",
                                "parentId": "520500",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "毕节市",
                                "county": "金沙县",

                            },
                            {
                                "cityCode": "520524",
                                "cityName": "织金县",
                                "parentId": "520500",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "毕节市",
                                "county": "织金县",

                            },
                            {
                                "cityCode": "520525",
                                "cityName": "纳雍县",
                                "parentId": "520500",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "毕节市",
                                "county": "纳雍县",

                            },
                            {
                                "cityCode": "520526",
                                "cityName": "威宁彝族回族苗族自治县",
                                "parentId": "520500",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "毕节市",
                                "county": "威宁彝族回族苗族自治县",

                            },
                            {
                                "cityCode": "520527",
                                "cityName": "赫章县",
                                "parentId": "520500",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "毕节市",
                                "county": "赫章县",

                            },
                            {
                                "cityCode": "520581",
                                "cityName": "黔西市",
                                "parentId": "520500",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "毕节市",
                                "county": "黔西市",

                            }
                        ]
                    },
                    {
                        "cityCode": "520600",
                        "cityName": "铜仁市",
                        "parentId": "520000",
                        "provinceType": "2",
                        "province": "贵州省",
                        "city": "铜仁市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "520602",
                                "cityName": "碧江区",
                                "parentId": "520600",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "铜仁地区",
                                "county": "碧江区",

                            },
                            {
                                "cityCode": "520603",
                                "cityName": "万山区",
                                "parentId": "520600",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "铜仁地区",
                                "county": "万山区",

                            },
                            {
                                "cityCode": "520621",
                                "cityName": "江口县",
                                "parentId": "520600",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "铜仁市",
                                "county": "江口县",

                            },
                            {
                                "cityCode": "520622",
                                "cityName": "玉屏侗族自治县",
                                "parentId": "520600",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "铜仁市",
                                "county": "玉屏侗族自治县",

                            },
                            {
                                "cityCode": "520623",
                                "cityName": "石阡县",
                                "parentId": "520600",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "铜仁市",
                                "county": "石阡县",

                            },
                            {
                                "cityCode": "520624",
                                "cityName": "思南县",
                                "parentId": "520600",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "铜仁市",
                                "county": "思南县",

                            },
                            {
                                "cityCode": "520625",
                                "cityName": "印江土家族苗族自治县",
                                "parentId": "520600",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "铜仁市",
                                "county": "印江土家族苗族自治县",

                            },
                            {
                                "cityCode": "520626",
                                "cityName": "德江县",
                                "parentId": "520600",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "铜仁市",
                                "county": "德江县",

                            },
                            {
                                "cityCode": "520627",
                                "cityName": "沿河土家族自治县",
                                "parentId": "520600",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "铜仁市",
                                "county": "沿河土家族自治县",

                            },
                            {
                                "cityCode": "520628",
                                "cityName": "松桃苗族自治县",
                                "parentId": "520600",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "铜仁市",
                                "county": "松桃苗族自治县",

                            }
                        ]
                    },
                    {
                        "cityCode": "522300",
                        "cityName": "黔西南布依族苗族自治州",
                        "parentId": "520000",
                        "provinceType": "2",
                        "province": "贵州省",
                        "city": "黔西南布依族苗族自治州",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "522301",
                                "cityName": "兴义市",
                                "parentId": "522300",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "黔西南布依族苗族自治州",
                                "county": "兴义市",

                            },
                            {
                                "cityCode": "522302",
                                "cityName": "兴仁县",
                                "parentId": "522300",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "黔西南布依族苗族自治州",
                                "county": "兴仁县",

                            },
                            {
                                "cityCode": "522322",
                                "cityName": "兴仁县",
                                "parentId": "522300",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "黔西南布依族苗族自治州",
                                "county": "兴仁县",

                            },
                            {
                                "cityCode": "522323",
                                "cityName": "普安县",
                                "parentId": "522300",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "黔西南布依族苗族自治州",
                                "county": "普安县",

                            },
                            {
                                "cityCode": "522324",
                                "cityName": "晴隆县",
                                "parentId": "522300",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "黔西南布依族苗族自治州",
                                "county": "晴隆县",

                            },
                            {
                                "cityCode": "522325",
                                "cityName": "贞丰县",
                                "parentId": "522300",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "黔西南布依族苗族自治州",
                                "county": "贞丰县",

                            },
                            {
                                "cityCode": "522326",
                                "cityName": "望谟县",
                                "parentId": "522300",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "黔西南布依族苗族自治州",
                                "county": "望谟县",

                            },
                            {
                                "cityCode": "522327",
                                "cityName": "册亨县",
                                "parentId": "522300",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "黔西南布依族苗族自治州",
                                "county": "册亨县",

                            },
                            {
                                "cityCode": "522328",
                                "cityName": "安龙县",
                                "parentId": "522300",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "黔西南布依族苗族自治州",
                                "county": "安龙县",

                            }
                        ]
                    },
                    {
                        "cityCode": "522600",
                        "cityName": "黔东南苗族侗族自治州",
                        "parentId": "520000",
                        "provinceType": "2",
                        "province": "贵州省",
                        "city": "黔东南苗族侗族自治州",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "522601",
                                "cityName": "凯里市",
                                "parentId": "522600",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "黔东南苗族侗族自治州",
                                "county": "凯里市",

                            },
                            {
                                "cityCode": "522622",
                                "cityName": "黄平县",
                                "parentId": "522600",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "黔东南苗族侗族自治州",
                                "county": "黄平县",

                            },
                            {
                                "cityCode": "522623",
                                "cityName": "施秉县",
                                "parentId": "522600",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "黔东南苗族侗族自治州",
                                "county": "施秉县",

                            },
                            {
                                "cityCode": "522624",
                                "cityName": "三穗县",
                                "parentId": "522600",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "黔东南苗族侗族自治州",
                                "county": "三穗县",

                            },
                            {
                                "cityCode": "522625",
                                "cityName": "镇远县",
                                "parentId": "522600",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "黔东南苗族侗族自治州",
                                "county": "镇远县",

                            },
                            {
                                "cityCode": "522626",
                                "cityName": "岑巩县",
                                "parentId": "522600",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "黔东南苗族侗族自治州",
                                "county": "岑巩县",

                            },
                            {
                                "cityCode": "522627",
                                "cityName": "天柱县",
                                "parentId": "522600",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "黔东南苗族侗族自治州",
                                "county": "天柱县",

                            },
                            {
                                "cityCode": "522628",
                                "cityName": "锦屏县",
                                "parentId": "522600",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "黔东南苗族侗族自治州",
                                "county": "锦屏县",

                            },
                            {
                                "cityCode": "522629",
                                "cityName": "剑河县",
                                "parentId": "522600",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "黔东南苗族侗族自治州",
                                "county": "剑河县",

                            },
                            {
                                "cityCode": "522630",
                                "cityName": "台江县",
                                "parentId": "522600",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "黔东南苗族侗族自治州",
                                "county": "台江县",

                            },
                            {
                                "cityCode": "522631",
                                "cityName": "黎平县",
                                "parentId": "522600",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "黔东南苗族侗族自治州",
                                "county": "黎平县",

                            },
                            {
                                "cityCode": "522632",
                                "cityName": "榕江县",
                                "parentId": "522600",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "黔东南苗族侗族自治州",
                                "county": "榕江县",

                            },
                            {
                                "cityCode": "522633",
                                "cityName": "从江县",
                                "parentId": "522600",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "黔东南苗族侗族自治州",
                                "county": "从江县",

                            },
                            {
                                "cityCode": "522634",
                                "cityName": "雷山县",
                                "parentId": "522600",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "黔东南苗族侗族自治州",
                                "county": "雷山县",

                            },
                            {
                                "cityCode": "522635",
                                "cityName": "麻江县",
                                "parentId": "522600",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "黔东南苗族侗族自治州",
                                "county": "麻江县",

                            },
                            {
                                "cityCode": "522636",
                                "cityName": "丹寨县",
                                "parentId": "522600",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "黔东南苗族侗族自治州",
                                "county": "丹寨县",

                            }
                        ]
                    },
                    {
                        "cityCode": "522700",
                        "cityName": "黔南布依族苗族自治州",
                        "parentId": "520000",
                        "provinceType": "2",
                        "province": "贵州省",
                        "city": "黔南布依族苗族自治州",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "522701",
                                "cityName": "都匀市",
                                "parentId": "522700",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "黔南布依族苗族自治州",
                                "county": "都匀市",

                            },
                            {
                                "cityCode": "522702",
                                "cityName": "福泉市",
                                "parentId": "522700",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "黔南布依族苗族自治州",
                                "county": "福泉市",

                            },
                            {
                                "cityCode": "522722",
                                "cityName": "荔波县",
                                "parentId": "522700",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "黔南布依族苗族自治州",
                                "county": "荔波县",

                            },
                            {
                                "cityCode": "522723",
                                "cityName": "贵定县",
                                "parentId": "522700",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "黔南布依族苗族自治州",
                                "county": "贵定县",

                            },
                            {
                                "cityCode": "522725",
                                "cityName": "瓮安县",
                                "parentId": "522700",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "黔南布依族苗族自治州",
                                "county": "瓮安县",

                            },
                            {
                                "cityCode": "522726",
                                "cityName": "独山县",
                                "parentId": "522700",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "黔南布依族苗族自治州",
                                "county": "独山县",

                            },
                            {
                                "cityCode": "522727",
                                "cityName": "平塘县",
                                "parentId": "522700",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "黔南布依族苗族自治州",
                                "county": "平塘县",

                            },
                            {
                                "cityCode": "522728",
                                "cityName": "罗甸县",
                                "parentId": "522700",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "黔南布依族苗族自治州",
                                "county": "罗甸县",

                            },
                            {
                                "cityCode": "522729",
                                "cityName": "长顺县",
                                "parentId": "522700",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "黔南布依族苗族自治州",
                                "county": "长顺县",

                            },
                            {
                                "cityCode": "522730",
                                "cityName": "龙里县",
                                "parentId": "522700",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "黔南布依族苗族自治州",
                                "county": "龙里县",

                            },
                            {
                                "cityCode": "522731",
                                "cityName": "惠水县",
                                "parentId": "522700",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "黔南布依族苗族自治州",
                                "county": "惠水县",

                            },
                            {
                                "cityCode": "522732",
                                "cityName": "三都水族自治县",
                                "parentId": "522700",
                                "provinceType": "3",
                                "province": "贵州省",
                                "city": "黔南布依族苗族自治州",
                                "county": "三都水族自治县",

                            }
                        ]
                    }
                ]
            },
            {
                "cityCode": "530000",
                "cityName": "云南省",
                "parentId": "100000",
                "provinceType": "1",
                "province": "云南省",
                "city": "",
                "county": "",
                "counties": [
                    {
                        "cityCode": "530100",
                        "cityName": "昆明市",
                        "parentId": "530000",
                        "provinceType": "2",
                        "province": "云南省",
                        "city": "昆明市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "530101",
                                "cityName": "昆明市",
                                "parentId": "530100",
                                "provinceType": "2",
                                "province": "云南省",
                                "city": "昆明市",
                                "county": "",

                            },
                            {
                                "cityCode": "530102",
                                "cityName": "五华区",
                                "parentId": "530100",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "昆明市",
                                "county": "五华区",

                            },
                            {
                                "cityCode": "530103",
                                "cityName": "盘龙区",
                                "parentId": "530100",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "昆明市",
                                "county": "盘龙区",

                            },
                            {
                                "cityCode": "530111",
                                "cityName": "官渡区",
                                "parentId": "530100",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "昆明市",
                                "county": "官渡区",

                            },
                            {
                                "cityCode": "530112",
                                "cityName": "西山区",
                                "parentId": "530100",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "昆明市",
                                "county": "西山区",

                            },
                            {
                                "cityCode": "530113",
                                "cityName": "东川区",
                                "parentId": "530100",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "昆明市",
                                "county": "东川区",

                            },
                            {
                                "cityCode": "530114",
                                "cityName": "呈贡区",
                                "parentId": "530100",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "昆明市",
                                "county": "呈贡区",

                            },
                            {
                                "cityCode": "530115",
                                "cityName": "晋宁区",
                                "parentId": "530100",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "昆明市",
                                "county": "晋宁区",

                            },
                            {
                                "cityCode": "530121",
                                "cityName": "呈贡县",
                                "parentId": "530100",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "昆明市",
                                "county": "呈贡县",

                            },
                            {
                                "cityCode": "530122",
                                "cityName": "晋宁县",
                                "parentId": "530100",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "昆明市",
                                "county": "晋宁县",

                            },
                            {
                                "cityCode": "530124",
                                "cityName": "富民县",
                                "parentId": "530100",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "昆明市",
                                "county": "富民县",

                            },
                            {
                                "cityCode": "530125",
                                "cityName": "宜良县",
                                "parentId": "530100",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "昆明市",
                                "county": "宜良县",

                            },
                            {
                                "cityCode": "530126",
                                "cityName": "石林彝族自治县",
                                "parentId": "530100",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "昆明市",
                                "county": "石林彝族自治县",

                            },
                            {
                                "cityCode": "530127",
                                "cityName": "嵩明县",
                                "parentId": "530100",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "昆明市",
                                "county": "嵩明县",

                            },
                            {
                                "cityCode": "530128",
                                "cityName": "禄劝彝族苗族自治县",
                                "parentId": "530100",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "昆明市",
                                "county": "禄劝彝族苗族自治县",

                            },
                            {
                                "cityCode": "530129",
                                "cityName": "寻甸回族彝族自治县",
                                "parentId": "530100",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "昆明市",
                                "county": "寻甸回族彝族自治县",

                            },
                            {
                                "cityCode": "530181",
                                "cityName": "安宁市",
                                "parentId": "530100",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "昆明市",
                                "county": "安宁市",

                            },
                            {
                                "cityCode": "530182",
                                "cityName": "滇中新区",
                                "parentId": "530100",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "昆明市",
                                "county": "滇中新区",

                            },
                            {
                                "cityCode": "530183",
                                "cityName": "高新区",
                                "parentId": "530100",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "昆明市",
                                "county": "高新区",

                            }
                        ]
                    },
                    {
                        "cityCode": "530300",
                        "cityName": "曲靖市",
                        "parentId": "530000",
                        "provinceType": "2",
                        "province": "云南省",
                        "city": "曲靖市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "530301",
                                "cityName": "曲靖市",
                                "parentId": "530300",
                                "provinceType": "2",
                                "province": "云南省",
                                "city": "曲靖市",
                                "county": "",

                            },
                            {
                                "cityCode": "530302",
                                "cityName": "麒麟区",
                                "parentId": "530300",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "曲靖市",
                                "county": "麒麟区",

                            },
                            {
                                "cityCode": "530303",
                                "cityName": "沾益区",
                                "parentId": "530300",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "曲靖市",
                                "county": "沾益区",

                            },
                            {
                                "cityCode": "530304",
                                "cityName": "马龙区",
                                "parentId": "530300",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "曲靖市",
                                "county": "马龙区",

                            },
                            {
                                "cityCode": "530321",
                                "cityName": "马龙县",
                                "parentId": "530300",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "曲靖市",
                                "county": "马龙县",

                            },
                            {
                                "cityCode": "530322",
                                "cityName": "陆良县",
                                "parentId": "530300",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "曲靖市",
                                "county": "陆良县",

                            },
                            {
                                "cityCode": "530323",
                                "cityName": "师宗县",
                                "parentId": "530300",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "曲靖市",
                                "county": "师宗县",

                            },
                            {
                                "cityCode": "530324",
                                "cityName": "罗平县",
                                "parentId": "530300",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "曲靖市",
                                "county": "罗平县",

                            },
                            {
                                "cityCode": "530325",
                                "cityName": "富源县",
                                "parentId": "530300",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "曲靖市",
                                "county": "富源县",

                            },
                            {
                                "cityCode": "530326",
                                "cityName": "会泽县",
                                "parentId": "530300",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "曲靖市",
                                "county": "会泽县",

                            },
                            {
                                "cityCode": "530328",
                                "cityName": "沾益县",
                                "parentId": "530300",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "曲靖市",
                                "county": "沾益县",

                            },
                            {
                                "cityCode": "530381",
                                "cityName": "宣威市",
                                "parentId": "530300",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "曲靖市",
                                "county": "宣威市",

                            }
                        ]
                    },
                    {
                        "cityCode": "530400",
                        "cityName": "玉溪市",
                        "parentId": "530000",
                        "provinceType": "2",
                        "province": "云南省",
                        "city": "玉溪市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "530401",
                                "cityName": "玉溪市",
                                "parentId": "530400",
                                "provinceType": "2",
                                "province": "云南省",
                                "city": "玉溪市",
                                "county": "",

                            },
                            {
                                "cityCode": "530402",
                                "cityName": "红塔区",
                                "parentId": "530400",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "玉溪市",
                                "county": "红塔区",

                            },
                            {
                                "cityCode": "530403",
                                "cityName": "江川区",
                                "parentId": "530400",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "玉溪市",
                                "county": "江川区",

                            },
                            {
                                "cityCode": "530421",
                                "cityName": "江川县",
                                "parentId": "530400",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "玉溪市",
                                "county": "江川县",

                            },
                            {
                                "cityCode": "530422",
                                "cityName": "澄江县",
                                "parentId": "530400",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "玉溪市",
                                "county": "澄江县",

                            },
                            {
                                "cityCode": "530423",
                                "cityName": "通海县",
                                "parentId": "530400",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "玉溪市",
                                "county": "通海县",

                            },
                            {
                                "cityCode": "530424",
                                "cityName": "华宁县",
                                "parentId": "530400",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "玉溪市",
                                "county": "华宁县",

                            },
                            {
                                "cityCode": "530425",
                                "cityName": "易门县",
                                "parentId": "530400",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "玉溪市",
                                "county": "易门县",

                            },
                            {
                                "cityCode": "530426",
                                "cityName": "峨山彝族自治县",
                                "parentId": "530400",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "玉溪市",
                                "county": "峨山彝族自治县",

                            },
                            {
                                "cityCode": "530427",
                                "cityName": "新平彝族傣族自治县",
                                "parentId": "530400",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "玉溪市",
                                "county": "新平彝族傣族自治县",

                            },
                            {
                                "cityCode": "530428",
                                "cityName": "元江哈尼族彝族傣族自治县",
                                "parentId": "530400",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "玉溪市",
                                "county": "元江哈尼族彝族傣族自治县",

                            },
                            {
                                "cityCode": "530481",
                                "cityName": "澄江市",
                                "parentId": "530400",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "玉溪市",
                                "county": "澄江市",

                            }
                        ]
                    },
                    {
                        "cityCode": "530500",
                        "cityName": "保山市",
                        "parentId": "530000",
                        "provinceType": "2",
                        "province": "云南省",
                        "city": "保山市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "530501",
                                "cityName": "保山市",
                                "parentId": "530500",
                                "provinceType": "2",
                                "province": "云南省",
                                "city": "保山市",
                                "county": "",

                            },
                            {
                                "cityCode": "530502",
                                "cityName": "隆阳区",
                                "parentId": "530500",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "保山市",
                                "county": "隆阳区",

                            },
                            {
                                "cityCode": "530521",
                                "cityName": "施甸县",
                                "parentId": "530500",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "保山市",
                                "county": "施甸县",

                            },
                            {
                                "cityCode": "530522",
                                "cityName": "腾冲县",
                                "parentId": "530500",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "保山市",
                                "county": "腾冲县",

                            },
                            {
                                "cityCode": "530523",
                                "cityName": "龙陵县",
                                "parentId": "530500",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "保山市",
                                "county": "龙陵县",

                            },
                            {
                                "cityCode": "530524",
                                "cityName": "昌宁县",
                                "parentId": "530500",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "保山市",
                                "county": "昌宁县",

                            },
                            {
                                "cityCode": "530581",
                                "cityName": "腾冲市",
                                "parentId": "530500",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "保山市",
                                "county": "腾冲市",

                            }
                        ]
                    },
                    {
                        "cityCode": "530600",
                        "cityName": "昭通市",
                        "parentId": "530000",
                        "provinceType": "2",
                        "province": "云南省",
                        "city": "昭通市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "530601",
                                "cityName": "昭通市",
                                "parentId": "530600",
                                "provinceType": "2",
                                "province": "云南省",
                                "city": "昭通市",
                                "county": "",

                            },
                            {
                                "cityCode": "530602",
                                "cityName": "昭阳区",
                                "parentId": "530600",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "昭通市",
                                "county": "昭阳区",

                            },
                            {
                                "cityCode": "530621",
                                "cityName": "鲁甸县",
                                "parentId": "530600",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "昭通市",
                                "county": "鲁甸县",

                            },
                            {
                                "cityCode": "530622",
                                "cityName": "巧家县",
                                "parentId": "530600",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "昭通市",
                                "county": "巧家县",

                            },
                            {
                                "cityCode": "530623",
                                "cityName": "盐津县",
                                "parentId": "530600",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "昭通市",
                                "county": "盐津县",

                            },
                            {
                                "cityCode": "530624",
                                "cityName": "大关县",
                                "parentId": "530600",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "昭通市",
                                "county": "大关县",

                            },
                            {
                                "cityCode": "530625",
                                "cityName": "永善县",
                                "parentId": "530600",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "昭通市",
                                "county": "永善县",

                            },
                            {
                                "cityCode": "530626",
                                "cityName": "绥江县",
                                "parentId": "530600",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "昭通市",
                                "county": "绥江县",

                            },
                            {
                                "cityCode": "530627",
                                "cityName": "镇雄县",
                                "parentId": "530600",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "昭通市",
                                "county": "镇雄县",

                            },
                            {
                                "cityCode": "530628",
                                "cityName": "彝良县",
                                "parentId": "530600",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "昭通市",
                                "county": "彝良县",

                            },
                            {
                                "cityCode": "530629",
                                "cityName": "威信县",
                                "parentId": "530600",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "昭通市",
                                "county": "威信县",

                            },
                            {
                                "cityCode": "530630",
                                "cityName": "水富县",
                                "parentId": "530600",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "昭通市",
                                "county": "水富县",

                            },
                            {
                                "cityCode": "530681",
                                "cityName": "水富市",
                                "parentId": "530600",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "昭通市",
                                "county": "水富市",

                            }
                        ]
                    },
                    {
                        "cityCode": "530700",
                        "cityName": "丽江市",
                        "parentId": "530000",
                        "provinceType": "2",
                        "province": "云南省",
                        "city": "丽江市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "530701",
                                "cityName": "丽江市",
                                "parentId": "530700",
                                "provinceType": "2",
                                "province": "云南省",
                                "city": "丽江市",
                                "county": "",

                            },
                            {
                                "cityCode": "530702",
                                "cityName": "古城区",
                                "parentId": "530700",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "丽江市",
                                "county": "古城区",

                            },
                            {
                                "cityCode": "530721",
                                "cityName": "玉龙纳西族自治县",
                                "parentId": "530700",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "丽江市",
                                "county": "玉龙纳西族自治县",

                            },
                            {
                                "cityCode": "530722",
                                "cityName": "永胜县",
                                "parentId": "530700",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "丽江市",
                                "county": "永胜县",

                            },
                            {
                                "cityCode": "530723",
                                "cityName": "华坪县",
                                "parentId": "530700",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "丽江市",
                                "county": "华坪县",

                            },
                            {
                                "cityCode": "530724",
                                "cityName": "宁蒗彝族自治县",
                                "parentId": "530700",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "丽江市",
                                "county": "宁蒗彝族自治县",

                            }
                        ]
                    },
                    {
                        "cityCode": "530800",
                        "cityName": "普洱市",
                        "parentId": "530000",
                        "provinceType": "2",
                        "province": "云南省",
                        "city": "普洱市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "530801",
                                "cityName": "普洱市",
                                "parentId": "530800",
                                "provinceType": "2",
                                "province": "云南省",
                                "city": "普洱市",
                                "county": "",

                            },
                            {
                                "cityCode": "530802",
                                "cityName": "思茅区",
                                "parentId": "530800",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "普洱市",
                                "county": "思茅区",

                            },
                            {
                                "cityCode": "530821",
                                "cityName": "宁洱哈尼族彝族自治县",
                                "parentId": "530800",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "普洱市",
                                "county": "宁洱哈尼族彝族自治县",

                            },
                            {
                                "cityCode": "530822",
                                "cityName": "墨江哈尼族自治县",
                                "parentId": "530800",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "普洱市",
                                "county": "墨江哈尼族自治县",

                            },
                            {
                                "cityCode": "530823",
                                "cityName": "景东彝族自治县",
                                "parentId": "530800",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "普洱市",
                                "county": "景东彝族自治县",

                            },
                            {
                                "cityCode": "530824",
                                "cityName": "景谷傣族彝族自治县",
                                "parentId": "530800",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "普洱市",
                                "county": "景谷傣族彝族自治县",

                            },
                            {
                                "cityCode": "530825",
                                "cityName": "镇沅彝族哈尼族拉祜族自治县",
                                "parentId": "530800",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "普洱市",
                                "county": "镇沅彝族哈尼族拉祜族自治县",

                            },
                            {
                                "cityCode": "530826",
                                "cityName": "江城哈尼族彝族自治县",
                                "parentId": "530800",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "普洱市",
                                "county": "江城哈尼族彝族自治县",

                            },
                            {
                                "cityCode": "530827",
                                "cityName": "孟连傣族拉祜族佤族自治县",
                                "parentId": "530800",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "普洱市",
                                "county": "孟连傣族拉祜族佤族自治县",

                            },
                            {
                                "cityCode": "530828",
                                "cityName": "澜沧拉祜族自治县",
                                "parentId": "530800",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "普洱市",
                                "county": "澜沧拉祜族自治县",

                            },
                            {
                                "cityCode": "530829",
                                "cityName": "西盟佤族自治县",
                                "parentId": "530800",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "普洱市",
                                "county": "西盟佤族自治县",

                            }
                        ]
                    },
                    {
                        "cityCode": "530900",
                        "cityName": "临沧市",
                        "parentId": "530000",
                        "provinceType": "2",
                        "province": "云南省",
                        "city": "临沧市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "530901",
                                "cityName": "临沧市",
                                "parentId": "530900",
                                "provinceType": "2",
                                "province": "云南省",
                                "city": "临沧市",
                                "county": "",

                            },
                            {
                                "cityCode": "530902",
                                "cityName": "临翔区",
                                "parentId": "530900",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "临沧市",
                                "county": "临翔区",

                            },
                            {
                                "cityCode": "530921",
                                "cityName": "凤庆县",
                                "parentId": "530900",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "临沧市",
                                "county": "凤庆县",

                            },
                            {
                                "cityCode": "530922",
                                "cityName": "云县",
                                "parentId": "530900",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "临沧市",
                                "county": "云县",

                            },
                            {
                                "cityCode": "530923",
                                "cityName": "永德县",
                                "parentId": "530900",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "临沧市",
                                "county": "永德县",

                            },
                            {
                                "cityCode": "530924",
                                "cityName": "镇康县",
                                "parentId": "530900",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "临沧市",
                                "county": "镇康县",

                            },
                            {
                                "cityCode": "530925",
                                "cityName": "双江拉祜族佤族布朗族傣族自治县",
                                "parentId": "530900",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "临沧市",
                                "county": "双江拉祜族佤族布朗族傣族自治县",

                            },
                            {
                                "cityCode": "530926",
                                "cityName": "耿马傣族佤族自治县",
                                "parentId": "530900",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "临沧市",
                                "county": "耿马傣族佤族自治县",

                            },
                            {
                                "cityCode": "530927",
                                "cityName": "沧源佤族自治县",
                                "parentId": "530900",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "临沧市",
                                "county": "沧源佤族自治县",

                            }
                        ]
                    },
                    {
                        "cityCode": "532300",
                        "cityName": "楚雄彝族自治州",
                        "parentId": "530000",
                        "provinceType": "2",
                        "province": "云南省",
                        "city": "楚雄彝族自治州",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "532301",
                                "cityName": "楚雄市",
                                "parentId": "532300",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "楚雄彝族自治州",
                                "county": "楚雄市",

                            },
                            {
                                "cityCode": "532302",
                                "cityName": "禄丰市",
                                "parentId": "532300",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "楚雄彝族自治州",
                                "county": "禄丰市",

                            },
                            {
                                "cityCode": "532322",
                                "cityName": "双柏县",
                                "parentId": "532300",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "楚雄彝族自治州",
                                "county": "双柏县",

                            },
                            {
                                "cityCode": "532323",
                                "cityName": "牟定县",
                                "parentId": "532300",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "楚雄彝族自治州",
                                "county": "牟定县",

                            },
                            {
                                "cityCode": "532324",
                                "cityName": "南华县",
                                "parentId": "532300",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "楚雄彝族自治州",
                                "county": "南华县",

                            },
                            {
                                "cityCode": "532325",
                                "cityName": "姚安县",
                                "parentId": "532300",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "楚雄彝族自治州",
                                "county": "姚安县",

                            },
                            {
                                "cityCode": "532326",
                                "cityName": "大姚县",
                                "parentId": "532300",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "楚雄彝族自治州",
                                "county": "大姚县",

                            },
                            {
                                "cityCode": "532327",
                                "cityName": "永仁县",
                                "parentId": "532300",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "楚雄彝族自治州",
                                "county": "永仁县",

                            },
                            {
                                "cityCode": "532328",
                                "cityName": "元谋县",
                                "parentId": "532300",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "楚雄彝族自治州",
                                "county": "元谋县",

                            },
                            {
                                "cityCode": "532329",
                                "cityName": "武定县",
                                "parentId": "532300",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "楚雄彝族自治州",
                                "county": "武定县",

                            }
                        ]
                    },
                    {
                        "cityCode": "532500",
                        "cityName": "红河哈尼族彝族自治州",
                        "parentId": "530000",
                        "provinceType": "2",
                        "province": "云南省",
                        "city": "红河哈尼族彝族自治州",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "532501",
                                "cityName": "个旧市",
                                "parentId": "532500",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "红河哈尼族彝族自治州",
                                "county": "个旧市",

                            },
                            {
                                "cityCode": "532502",
                                "cityName": "开远市",
                                "parentId": "532500",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "红河哈尼族彝族自治州",
                                "county": "开远市",

                            },
                            {
                                "cityCode": "532503",
                                "cityName": "蒙自市",
                                "parentId": "532500",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "红河哈尼族彝族自治州",
                                "county": "蒙自市",

                            },
                            {
                                "cityCode": "532504",
                                "cityName": "弥勒市",
                                "parentId": "532500",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "红河哈尼族彝族自治州",
                                "county": "弥勒市",

                            },
                            {
                                "cityCode": "532522",
                                "cityName": "蒙自县",
                                "parentId": "532500",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "红河哈尼族彝族自治州",
                                "county": "蒙自县",

                            },
                            {
                                "cityCode": "532523",
                                "cityName": "屏边苗族自治县",
                                "parentId": "532500",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "红河哈尼族彝族自治州",
                                "county": "屏边苗族自治县",

                            },
                            {
                                "cityCode": "532524",
                                "cityName": "建水县",
                                "parentId": "532500",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "红河哈尼族彝族自治州",
                                "county": "建水县",

                            },
                            {
                                "cityCode": "532525",
                                "cityName": "石屏县",
                                "parentId": "532500",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "红河哈尼族彝族自治州",
                                "county": "石屏县",

                            },
                            {
                                "cityCode": "532526",
                                "cityName": "弥勒县",
                                "parentId": "532500",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "红河哈尼族彝族自治州",
                                "county": "弥勒县",

                            },
                            {
                                "cityCode": "532527",
                                "cityName": "泸西县",
                                "parentId": "532500",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "红河哈尼族彝族自治州",
                                "county": "泸西县",

                            },
                            {
                                "cityCode": "532528",
                                "cityName": "元阳县",
                                "parentId": "532500",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "红河哈尼族彝族自治州",
                                "county": "元阳县",

                            },
                            {
                                "cityCode": "532529",
                                "cityName": "红河县",
                                "parentId": "532500",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "红河哈尼族彝族自治州",
                                "county": "红河县",

                            },
                            {
                                "cityCode": "532530",
                                "cityName": "金平苗族瑶族傣族自治县",
                                "parentId": "532500",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "红河哈尼族彝族自治州",
                                "county": "金平苗族瑶族傣族自治县",

                            },
                            {
                                "cityCode": "532531",
                                "cityName": "绿春县",
                                "parentId": "532500",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "红河哈尼族彝族自治州",
                                "county": "绿春县",

                            },
                            {
                                "cityCode": "532532",
                                "cityName": "河口瑶族自治县",
                                "parentId": "532500",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "红河哈尼族彝族自治州",
                                "county": "河口瑶族自治县",

                            }
                        ]
                    },
                    {
                        "cityCode": "532600",
                        "cityName": "文山壮族苗族自治州",
                        "parentId": "530000",
                        "provinceType": "2",
                        "province": "云南省",
                        "city": "文山壮族苗族自治州",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "532601",
                                "cityName": "文山市",
                                "parentId": "532600",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "文山壮族苗族自治州",
                                "county": "文山市",

                            },
                            {
                                "cityCode": "532621",
                                "cityName": "文山县",
                                "parentId": "532600",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "文山壮族苗族自治州",
                                "county": "文山县",

                            },
                            {
                                "cityCode": "532622",
                                "cityName": "砚山县",
                                "parentId": "532600",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "文山壮族苗族自治州",
                                "county": "砚山县",

                            },
                            {
                                "cityCode": "532623",
                                "cityName": "西畴县",
                                "parentId": "532600",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "文山壮族苗族自治州",
                                "county": "西畴县",

                            },
                            {
                                "cityCode": "532624",
                                "cityName": "麻栗坡县",
                                "parentId": "532600",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "文山壮族苗族自治州",
                                "county": "麻栗坡县",

                            },
                            {
                                "cityCode": "532625",
                                "cityName": "马关县",
                                "parentId": "532600",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "文山壮族苗族自治州",
                                "county": "马关县",

                            },
                            {
                                "cityCode": "532626",
                                "cityName": "丘北县",
                                "parentId": "532600",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "文山壮族苗族自治州",
                                "county": "丘北县",

                            },
                            {
                                "cityCode": "532627",
                                "cityName": "广南县",
                                "parentId": "532600",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "文山壮族苗族自治州",
                                "county": "广南县",

                            },
                            {
                                "cityCode": "532628",
                                "cityName": "富宁县",
                                "parentId": "532600",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "文山壮族苗族自治州",
                                "county": "富宁县",

                            }
                        ]
                    },
                    {
                        "cityCode": "532800",
                        "cityName": "西双版纳傣族自治州",
                        "parentId": "530000",
                        "provinceType": "2",
                        "province": "云南省",
                        "city": "西双版纳傣族自治州",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "532801",
                                "cityName": "景洪市",
                                "parentId": "532800",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "西双版纳傣族自治州",
                                "county": "景洪市",

                            },
                            {
                                "cityCode": "532822",
                                "cityName": "勐海县",
                                "parentId": "532800",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "西双版纳傣族自治州",
                                "county": "勐海县",

                            },
                            {
                                "cityCode": "532823",
                                "cityName": "勐腊县",
                                "parentId": "532800",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "西双版纳傣族自治州",
                                "county": "勐腊县",

                            }
                        ]
                    },
                    {
                        "cityCode": "532900",
                        "cityName": "大理白族自治州",
                        "parentId": "530000",
                        "provinceType": "2",
                        "province": "云南省",
                        "city": "大理白族自治州",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "532901",
                                "cityName": "大理市",
                                "parentId": "532900",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "大理白族自治州",
                                "county": "大理市",

                            },
                            {
                                "cityCode": "532922",
                                "cityName": "漾濞彝族自治县",
                                "parentId": "532900",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "大理白族自治州",
                                "county": "漾濞彝族自治县",

                            },
                            {
                                "cityCode": "532923",
                                "cityName": "祥云县",
                                "parentId": "532900",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "大理白族自治州",
                                "county": "祥云县",

                            },
                            {
                                "cityCode": "532924",
                                "cityName": "宾川县",
                                "parentId": "532900",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "大理白族自治州",
                                "county": "宾川县",

                            },
                            {
                                "cityCode": "532925",
                                "cityName": "弥渡县",
                                "parentId": "532900",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "大理白族自治州",
                                "county": "弥渡县",

                            },
                            {
                                "cityCode": "532926",
                                "cityName": "南涧彝族自治县",
                                "parentId": "532900",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "大理白族自治州",
                                "county": "南涧彝族自治县",

                            },
                            {
                                "cityCode": "532927",
                                "cityName": "巍山彝族回族自治县",
                                "parentId": "532900",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "大理白族自治州",
                                "county": "巍山彝族回族自治县",

                            },
                            {
                                "cityCode": "532928",
                                "cityName": "永平县",
                                "parentId": "532900",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "大理白族自治州",
                                "county": "永平县",

                            },
                            {
                                "cityCode": "532929",
                                "cityName": "云龙县",
                                "parentId": "532900",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "大理白族自治州",
                                "county": "云龙县",

                            },
                            {
                                "cityCode": "532930",
                                "cityName": "洱源县",
                                "parentId": "532900",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "大理白族自治州",
                                "county": "洱源县",

                            },
                            {
                                "cityCode": "532931",
                                "cityName": "剑川县",
                                "parentId": "532900",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "大理白族自治州",
                                "county": "剑川县",

                            },
                            {
                                "cityCode": "532932",
                                "cityName": "鹤庆县",
                                "parentId": "532900",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "大理白族自治州",
                                "county": "鹤庆县",

                            }
                        ]
                    },
                    {
                        "cityCode": "533100",
                        "cityName": "德宏傣族景颇族自治州",
                        "parentId": "530000",
                        "provinceType": "2",
                        "province": "云南省",
                        "city": "德宏傣族景颇族自治州",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "533102",
                                "cityName": "瑞丽市",
                                "parentId": "533100",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "德宏傣族景颇族自治州",
                                "county": "瑞丽市",

                            },
                            {
                                "cityCode": "533103",
                                "cityName": "芒市",
                                "parentId": "533100",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "德宏傣族",
                                "county": "芒市",

                            },
                            {
                                "cityCode": "533122",
                                "cityName": "梁河县",
                                "parentId": "533100",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "德宏傣族景颇族自治州",
                                "county": "梁河县",

                            },
                            {
                                "cityCode": "533123",
                                "cityName": "盈江县",
                                "parentId": "533100",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "德宏傣族景颇族自治州",
                                "county": "盈江县",

                            },
                            {
                                "cityCode": "533124",
                                "cityName": "陇川县",
                                "parentId": "533100",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "德宏傣族景颇族自治州",
                                "county": "陇川县",

                            }
                        ]
                    },
                    {
                        "cityCode": "533300",
                        "cityName": "怒江傈僳族自治州",
                        "parentId": "530000",
                        "provinceType": "2",
                        "province": "云南省",
                        "city": "怒江傈僳族自治州",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "533301",
                                "cityName": "泸水市",
                                "parentId": "533300",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "怒江傈僳族自治州",
                                "county": "泸水市",

                            },
                            {
                                "cityCode": "533321",
                                "cityName": "泸水县",
                                "parentId": "533300",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "怒江傈僳族自治州",
                                "county": "泸水县",

                            },
                            {
                                "cityCode": "533323",
                                "cityName": "福贡县",
                                "parentId": "533300",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "怒江傈僳族自治州",
                                "county": "福贡县",

                            },
                            {
                                "cityCode": "533324",
                                "cityName": "贡山独龙族怒族自治县",
                                "parentId": "533300",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "怒江傈僳族自治州",
                                "county": "贡山独龙族怒族自治县",

                            },
                            {
                                "cityCode": "533325",
                                "cityName": "兰坪白族普米族自治县",
                                "parentId": "533300",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "怒江傈僳族自治州",
                                "county": "兰坪白族普米族自治县",

                            }
                        ]
                    },
                    {
                        "cityCode": "533400",
                        "cityName": "迪庆藏族自治州",
                        "parentId": "530000",
                        "provinceType": "2",
                        "province": "云南省",
                        "city": "迪庆藏族自治州",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "533401",
                                "cityName": "香格里拉市",
                                "parentId": "533400",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "迪庆藏族自治州",
                                "county": "香格里拉市",

                            },
                            {
                                "cityCode": "533421",
                                "cityName": "香格里拉市",
                                "parentId": "533400",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "迪庆藏族自治州",
                                "county": "香格里拉市",

                            },
                            {
                                "cityCode": "533422",
                                "cityName": "德钦县",
                                "parentId": "533400",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "迪庆藏族自治州",
                                "county": "德钦县",

                            },
                            {
                                "cityCode": "533423",
                                "cityName": "维西傈僳族自治县",
                                "parentId": "533400",
                                "provinceType": "3",
                                "province": "云南省",
                                "city": "迪庆藏族自治州",
                                "county": "维西傈僳族自治县",

                            }
                        ]
                    }
                ]
            },
            {
                "cityCode": "540000",
                "cityName": "西藏自治区",
                "parentId": "100000",
                "provinceType": "1",
                "province": "西藏自治区",
                "city": "",
                "county": "",
                "counties": [
                    {
                        "cityCode": "540100",
                        "cityName": "拉萨市",
                        "parentId": "540000",
                        "provinceType": "2",
                        "province": "西藏自治区",
                        "city": "拉萨市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "540101",
                                "cityName": "拉萨市",
                                "parentId": "540100",
                                "provinceType": "2",
                                "province": "西藏自治区",
                                "city": "拉萨市",
                                "county": "",

                            },
                            {
                                "cityCode": "540102",
                                "cityName": "城关区",
                                "parentId": "540100",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "拉萨市",
                                "county": "城关区",

                            },
                            {
                                "cityCode": "540103",
                                "cityName": "堆龙德庆区",
                                "parentId": "540100",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "拉萨市",
                                "county": "堆龙德庆区",

                            },
                            {
                                "cityCode": "540104",
                                "cityName": "达孜区",
                                "parentId": "540100",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "拉萨市",
                                "county": "达孜区",

                            },
                            {
                                "cityCode": "540121",
                                "cityName": "林周县",
                                "parentId": "540100",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "拉萨市",
                                "county": "林周县",

                            },
                            {
                                "cityCode": "540122",
                                "cityName": "当雄县",
                                "parentId": "540100",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "拉萨市",
                                "county": "当雄县",

                            },
                            {
                                "cityCode": "540123",
                                "cityName": "尼木县",
                                "parentId": "540100",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "拉萨市",
                                "county": "尼木县",

                            },
                            {
                                "cityCode": "540124",
                                "cityName": "曲水县",
                                "parentId": "540100",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "拉萨市",
                                "county": "曲水县",

                            },
                            {
                                "cityCode": "540125",
                                "cityName": "堆龙德庆县",
                                "parentId": "540100",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "拉萨市",
                                "county": "堆龙德庆县",

                            },
                            {
                                "cityCode": "540126",
                                "cityName": "达孜县",
                                "parentId": "540100",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "拉萨市",
                                "county": "达孜县",

                            },
                            {
                                "cityCode": "540127",
                                "cityName": "墨竹工卡县",
                                "parentId": "540100",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "拉萨市",
                                "county": "墨竹工卡县",

                            },
                            {
                                "cityCode": "540171",
                                "cityName": "格尔木藏青工业园区",
                                "parentId": "540100",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "拉萨市",
                                "county": "格尔木藏青工业园区",

                            },
                            {
                                "cityCode": "540172",
                                "cityName": "拉萨经济技术开发区",
                                "parentId": "540100",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "拉萨市",
                                "county": "拉萨经济技术开发区",

                            },
                            {
                                "cityCode": "540173",
                                "cityName": "西藏文化旅游创意园区",
                                "parentId": "540100",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "拉萨市",
                                "county": "西藏文化旅游创意园区",

                            },
                            {
                                "cityCode": "540174",
                                "cityName": "达孜工业园区",
                                "parentId": "540100",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "拉萨市",
                                "county": "达孜工业园区",

                            }
                        ]
                    },
                    {
                        "cityCode": "540200",
                        "cityName": "日喀则市",
                        "parentId": "540000",
                        "provinceType": "2",
                        "province": "西藏自治区",
                        "city": "日喀则市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "540202",
                                "cityName": "桑珠孜区",
                                "parentId": "540200",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "日喀则地区",
                                "county": "桑珠孜区",

                            },
                            {
                                "cityCode": "540221",
                                "cityName": "南木林县",
                                "parentId": "540200",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "日喀则市",
                                "county": "南木林县",

                            },
                            {
                                "cityCode": "540222",
                                "cityName": "江孜县",
                                "parentId": "540200",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "日喀则市",
                                "county": "江孜县",

                            },
                            {
                                "cityCode": "540223",
                                "cityName": "定日县",
                                "parentId": "540200",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "日喀则市",
                                "county": "定日县",

                            },
                            {
                                "cityCode": "540224",
                                "cityName": "萨迦县",
                                "parentId": "540200",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "日喀则市",
                                "county": "萨迦县",

                            },
                            {
                                "cityCode": "540225",
                                "cityName": "拉孜县",
                                "parentId": "540200",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "日喀则市",
                                "county": "拉孜县",

                            },
                            {
                                "cityCode": "540226",
                                "cityName": "昂仁县",
                                "parentId": "540200",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "日喀则市",
                                "county": "昂仁县",

                            },
                            {
                                "cityCode": "540227",
                                "cityName": "谢通门县",
                                "parentId": "540200",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "日喀则市",
                                "county": "谢通门县",

                            },
                            {
                                "cityCode": "540228",
                                "cityName": "白朗县",
                                "parentId": "540200",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "日喀则市",
                                "county": "白朗县",

                            },
                            {
                                "cityCode": "540229",
                                "cityName": "仁布县",
                                "parentId": "540200",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "日喀则市",
                                "county": "仁布县",

                            },
                            {
                                "cityCode": "540230",
                                "cityName": "康马县",
                                "parentId": "540200",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "日喀则市",
                                "county": "康马县",

                            },
                            {
                                "cityCode": "540231",
                                "cityName": "定结县",
                                "parentId": "540200",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "日喀则市",
                                "county": "定结县",

                            },
                            {
                                "cityCode": "540232",
                                "cityName": "仲巴县",
                                "parentId": "540200",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "日喀则市",
                                "county": "仲巴县",

                            },
                            {
                                "cityCode": "540233",
                                "cityName": "亚东县",
                                "parentId": "540200",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "日喀则市",
                                "county": "亚东县",

                            },
                            {
                                "cityCode": "540234",
                                "cityName": "吉隆县",
                                "parentId": "540200",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "日喀则市",
                                "county": "吉隆县",

                            },
                            {
                                "cityCode": "540235",
                                "cityName": "聂拉木县",
                                "parentId": "540200",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "日喀则市",
                                "county": "聂拉木县",

                            },
                            {
                                "cityCode": "540236",
                                "cityName": "萨嘎县",
                                "parentId": "540200",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "日喀则市",
                                "county": "萨嘎县",

                            },
                            {
                                "cityCode": "540237",
                                "cityName": "岗巴县",
                                "parentId": "540200",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "日喀则市",
                                "county": "岗巴县",

                            }
                        ]
                    },
                    {
                        "cityCode": "540300",
                        "cityName": "昌都市",
                        "parentId": "540000",
                        "provinceType": "2",
                        "province": "西藏自治区",
                        "city": "昌都市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "540302",
                                "cityName": "卡若区",
                                "parentId": "540300",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "昌都地区",
                                "county": "卡若区",

                            },
                            {
                                "cityCode": "540321",
                                "cityName": "江达县",
                                "parentId": "540300",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "昌都市",
                                "county": "江达县",

                            },
                            {
                                "cityCode": "540322",
                                "cityName": "贡觉县",
                                "parentId": "540300",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "昌都市",
                                "county": "贡觉县",

                            },
                            {
                                "cityCode": "540323",
                                "cityName": "类乌齐县",
                                "parentId": "540300",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "昌都市",
                                "county": "类乌齐县",

                            },
                            {
                                "cityCode": "540324",
                                "cityName": "丁青县",
                                "parentId": "540300",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "昌都市",
                                "county": "丁青县",

                            },
                            {
                                "cityCode": "540325",
                                "cityName": "察雅县",
                                "parentId": "540300",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "昌都市",
                                "county": "察雅县",

                            },
                            {
                                "cityCode": "540326",
                                "cityName": "八宿县",
                                "parentId": "540300",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "昌都市",
                                "county": "八宿县",

                            },
                            {
                                "cityCode": "540327",
                                "cityName": "左贡县",
                                "parentId": "540300",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "昌都市",
                                "county": "左贡县",

                            },
                            {
                                "cityCode": "540328",
                                "cityName": "芒康县",
                                "parentId": "540300",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "昌都市",
                                "county": "芒康县",

                            },
                            {
                                "cityCode": "540329",
                                "cityName": "洛隆县",
                                "parentId": "540300",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "昌都市",
                                "county": "洛隆县",

                            },
                            {
                                "cityCode": "540330",
                                "cityName": "边坝县",
                                "parentId": "540300",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "昌都市",
                                "county": "边坝县",

                            }
                        ]
                    },
                    {
                        "cityCode": "540400",
                        "cityName": "林芝市",
                        "parentId": "540000",
                        "provinceType": "2",
                        "province": "西藏自治区",
                        "city": "林芝市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "540402",
                                "cityName": "巴宜区",
                                "parentId": "540400",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "林芝市",
                                "county": "巴宜区",

                            },
                            {
                                "cityCode": "540421",
                                "cityName": "工布江达县",
                                "parentId": "540400",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "林芝市",
                                "county": "工布江达县",

                            },
                            {
                                "cityCode": "540422",
                                "cityName": "米林县",
                                "parentId": "540400",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "林芝市",
                                "county": "米林县",

                            },
                            {
                                "cityCode": "540423",
                                "cityName": "墨脱县",
                                "parentId": "540400",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "林芝市",
                                "county": "墨脱县",

                            },
                            {
                                "cityCode": "540424",
                                "cityName": "波密县",
                                "parentId": "540400",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "林芝市",
                                "county": "波密县",

                            },
                            {
                                "cityCode": "540425",
                                "cityName": "察隅县",
                                "parentId": "540400",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "林芝市",
                                "county": "察隅县",

                            },
                            {
                                "cityCode": "540426",
                                "cityName": "朗县",
                                "parentId": "540400",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "林芝市",
                                "county": "朗县",

                            }
                        ]
                    },
                    {
                        "cityCode": "540500",
                        "cityName": "山南市",
                        "parentId": "540000",
                        "provinceType": "2",
                        "province": "西藏自治区",
                        "city": "山南市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "540501",
                                "cityName": "市辖区",
                                "parentId": "540500",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "山南市",
                                "county": "市辖区",

                            },
                            {
                                "cityCode": "540502",
                                "cityName": "乃东区",
                                "parentId": "540500",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "山南市",
                                "county": "乃东区",

                            },
                            {
                                "cityCode": "540521",
                                "cityName": "扎囊县",
                                "parentId": "540500",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "山南市",
                                "county": "扎囊县",

                            },
                            {
                                "cityCode": "540522",
                                "cityName": "贡嘎县",
                                "parentId": "540500",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "山南市",
                                "county": "贡嘎县",

                            },
                            {
                                "cityCode": "540523",
                                "cityName": "桑日县",
                                "parentId": "540500",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "山南市",
                                "county": "桑日县",

                            },
                            {
                                "cityCode": "540524",
                                "cityName": "琼结县",
                                "parentId": "540500",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "山南市",
                                "county": "琼结县",

                            },
                            {
                                "cityCode": "540525",
                                "cityName": "曲松县",
                                "parentId": "540500",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "山南市",
                                "county": "曲松县",

                            },
                            {
                                "cityCode": "540526",
                                "cityName": "措美县",
                                "parentId": "540500",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "山南市",
                                "county": "措美县",

                            },
                            {
                                "cityCode": "540527",
                                "cityName": "洛扎县",
                                "parentId": "540500",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "山南市",
                                "county": "洛扎县",

                            },
                            {
                                "cityCode": "540528",
                                "cityName": "加查县",
                                "parentId": "540500",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "山南市",
                                "county": "加查县",

                            },
                            {
                                "cityCode": "540529",
                                "cityName": "隆子县",
                                "parentId": "540500",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "山南市",
                                "county": "隆子县",

                            },
                            {
                                "cityCode": "540530",
                                "cityName": "错那县",
                                "parentId": "540500",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "山南市",
                                "county": "错那县",

                            },
                            {
                                "cityCode": "540531",
                                "cityName": "浪卡子县",
                                "parentId": "540500",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "山南市",
                                "county": "浪卡子县",

                            }
                        ]
                    },
                    {
                        "cityCode": "542400",
                        "cityName": "那曲地区",
                        "parentId": "540000",
                        "provinceType": "2",
                        "province": "西藏自治区",
                        "city": "那曲地区",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "540600",
                                "cityName": "那曲市",
                                "parentId": "542400",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "那曲地区",
                                "county": "那曲县",

                            },
                            {
                                "cityCode": "540602",
                                "cityName": "色尼区",
                                "parentId": "542400",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "那曲地区",
                                "county": "色尼区",

                            },
                            {
                                "cityCode": "540621",
                                "cityName": "嘉黎县",
                                "parentId": "542400",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "那曲地区",
                                "county": "嘉黎县",

                            },
                            {
                                "cityCode": "540622",
                                "cityName": "比如县",
                                "parentId": "542400",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "那曲地区",
                                "county": "比如县",

                            },
                            {
                                "cityCode": "540623",
                                "cityName": "聂荣县",
                                "parentId": "542400",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "那曲地区",
                                "county": "聂荣县",

                            },
                            {
                                "cityCode": "540624",
                                "cityName": "安多县",
                                "parentId": "542400",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "那曲地区",
                                "county": "安多县",

                            },
                            {
                                "cityCode": "540625",
                                "cityName": "申扎县",
                                "parentId": "542400",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "那曲地区",
                                "county": "申扎县",

                            },
                            {
                                "cityCode": "540626",
                                "cityName": "索县",
                                "parentId": "542400",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "那曲地区",
                                "county": "索县",

                            },
                            {
                                "cityCode": "540627",
                                "cityName": "班戈县",
                                "parentId": "542400",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "那曲地区",
                                "county": "班戈县",

                            },
                            {
                                "cityCode": "540628",
                                "cityName": "巴青县",
                                "parentId": "542400",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "那曲地区",
                                "county": "巴青县",

                            },
                            {
                                "cityCode": "540629",
                                "cityName": "尼玛县",
                                "parentId": "542400",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "那曲地区",
                                "county": "尼玛县",

                            },
                            {
                                "cityCode": "540630",
                                "cityName": "双湖县",
                                "parentId": "542400",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "那曲地区",
                                "county": "双湖县",

                            },
                            {
                                "cityCode": "542421",
                                "cityName": "那曲县",
                                "parentId": "542400",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "那曲地区",
                                "county": "那曲县",

                            },
                            {
                                "cityCode": "542422",
                                "cityName": "嘉黎县",
                                "parentId": "542400",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "那曲地区",
                                "county": "嘉黎县",

                            },
                            {
                                "cityCode": "542423",
                                "cityName": "比如县",
                                "parentId": "542400",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "那曲地区",
                                "county": "比如县",

                            },
                            {
                                "cityCode": "542424",
                                "cityName": "聂荣县",
                                "parentId": "542400",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "那曲地区",
                                "county": "聂荣县",

                            },
                            {
                                "cityCode": "542425",
                                "cityName": "安多县",
                                "parentId": "542400",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "那曲地区",
                                "county": "安多县",

                            },
                            {
                                "cityCode": "542426",
                                "cityName": "申扎县",
                                "parentId": "542400",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "那曲地区",
                                "county": "申扎县",

                            },
                            {
                                "cityCode": "542427",
                                "cityName": "索县",
                                "parentId": "542400",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "那曲地区",
                                "county": "索县",

                            },
                            {
                                "cityCode": "542428",
                                "cityName": "班戈县",
                                "parentId": "542400",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "那曲地区",
                                "county": "班戈县",

                            },
                            {
                                "cityCode": "542429",
                                "cityName": "巴青县",
                                "parentId": "542400",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "那曲地区",
                                "county": "巴青县",

                            },
                            {
                                "cityCode": "542430",
                                "cityName": "尼玛县",
                                "parentId": "542400",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "那曲地区",
                                "county": "尼玛县",

                            },
                            {
                                "cityCode": "542431",
                                "cityName": "双湖县",
                                "parentId": "542400",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "那曲地区",
                                "county": "双湖县",

                            }
                        ]
                    },
                    {
                        "cityCode": "542500",
                        "cityName": "阿里地区",
                        "parentId": "540000",
                        "provinceType": "2",
                        "province": "西藏自治区",
                        "city": "阿里地区",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "542521",
                                "cityName": "普兰县",
                                "parentId": "542500",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "阿里地区",
                                "county": "普兰县",

                            },
                            {
                                "cityCode": "542522",
                                "cityName": "札达县",
                                "parentId": "542500",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "阿里地区",
                                "county": "札达县",

                            },
                            {
                                "cityCode": "542523",
                                "cityName": "噶尔县",
                                "parentId": "542500",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "阿里地区",
                                "county": "噶尔县",

                            },
                            {
                                "cityCode": "542524",
                                "cityName": "日土县",
                                "parentId": "542500",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "阿里地区",
                                "county": "日土县",

                            },
                            {
                                "cityCode": "542525",
                                "cityName": "革吉县",
                                "parentId": "542500",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "阿里地区",
                                "county": "革吉县",

                            },
                            {
                                "cityCode": "542526",
                                "cityName": "改则县",
                                "parentId": "542500",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "阿里地区",
                                "county": "改则县",

                            },
                            {
                                "cityCode": "542527",
                                "cityName": "措勤县",
                                "parentId": "542500",
                                "provinceType": "3",
                                "province": "西藏自治区",
                                "city": "阿里地区",
                                "county": "措勤县",

                            }
                        ]
                    }
                ]
            },
            {
                "cityCode": "610000",
                "cityName": "陕西省",
                "parentId": "100000",
                "provinceType": "1",
                "province": "陕西省",
                "city": "",
                "county": "",
                "counties": [
                    {
                        "cityCode": "610100",
                        "cityName": "西安市",
                        "parentId": "610000",
                        "provinceType": "2",
                        "province": "陕西省",
                        "city": "西安市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "610101",
                                "cityName": "西安市",
                                "parentId": "610100",
                                "provinceType": "2",
                                "province": "陕西省",
                                "city": "西安市",
                                "county": "",

                            },
                            {
                                "cityCode": "610102",
                                "cityName": "新城区",
                                "parentId": "610100",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "西安市",
                                "county": "新城区",

                            },
                            {
                                "cityCode": "610103",
                                "cityName": "碑林区",
                                "parentId": "610100",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "西安市",
                                "county": "碑林区",

                            },
                            {
                                "cityCode": "610104",
                                "cityName": "莲湖区",
                                "parentId": "610100",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "西安市",
                                "county": "莲湖区",

                            },
                            {
                                "cityCode": "610111",
                                "cityName": "灞桥区",
                                "parentId": "610100",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "西安市",
                                "county": "灞桥区",

                            },
                            {
                                "cityCode": "610112",
                                "cityName": "未央区",
                                "parentId": "610100",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "西安市",
                                "county": "未央区",

                            },
                            {
                                "cityCode": "610113",
                                "cityName": "雁塔区",
                                "parentId": "610100",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "西安市",
                                "county": "雁塔区",

                            },
                            {
                                "cityCode": "610114",
                                "cityName": "阎良区",
                                "parentId": "610100",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "西安市",
                                "county": "阎良区",

                            },
                            {
                                "cityCode": "610115",
                                "cityName": "临潼区",
                                "parentId": "610100",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "西安市",
                                "county": "临潼区",

                            },
                            {
                                "cityCode": "610116",
                                "cityName": "长安区",
                                "parentId": "610100",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "西安市",
                                "county": "长安区",

                            },
                            {
                                "cityCode": "610117",
                                "cityName": "高陵区",
                                "parentId": "610100",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "西安市",
                                "county": "高陵区",

                            },
                            {
                                "cityCode": "610118",
                                "cityName": "鄠邑区",
                                "parentId": "610100",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "西安市",
                                "county": "鄠邑区",

                            },
                            {
                                "cityCode": "610120",
                                "cityName": "高新区",
                                "parentId": "610100",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "西安市",
                                "county": "高新区",

                            },
                            {
                                "cityCode": "610122",
                                "cityName": "蓝田县",
                                "parentId": "610100",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "西安市",
                                "county": "蓝田县",

                            },
                            {
                                "cityCode": "610124",
                                "cityName": "周至县",
                                "parentId": "610100",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "西安市",
                                "county": "周至县",

                            },
                            {
                                "cityCode": "610125",
                                "cityName": "户县",
                                "parentId": "610100",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "西安市",
                                "county": "户县",

                            },
                            {
                                "cityCode": "610126",
                                "cityName": "高陵县",
                                "parentId": "610100",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "西安市",
                                "county": "高陵县",

                            },
                            {
                                "cityCode": "610127",
                                "cityName": "曲江新区",
                                "parentId": "610100",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "西安市",
                                "county": "曲江新区",

                            }
                        ]
                    },
                    {
                        "cityCode": "610200",
                        "cityName": "铜川市",
                        "parentId": "610000",
                        "provinceType": "2",
                        "province": "陕西省",
                        "city": "铜川市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "610201",
                                "cityName": "铜川市",
                                "parentId": "610200",
                                "provinceType": "2",
                                "province": "陕西省",
                                "city": "铜川市",
                                "county": "",

                            },
                            {
                                "cityCode": "610202",
                                "cityName": "王益区",
                                "parentId": "610200",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "铜川市",
                                "county": "王益区",

                            },
                            {
                                "cityCode": "610203",
                                "cityName": "印台区",
                                "parentId": "610200",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "铜川市",
                                "county": "印台区",

                            },
                            {
                                "cityCode": "610204",
                                "cityName": "耀州区",
                                "parentId": "610200",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "铜川市",
                                "county": "耀州区",

                            },
                            {
                                "cityCode": "610222",
                                "cityName": "宜君县",
                                "parentId": "610200",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "铜川市",
                                "county": "宜君县",

                            }
                        ]
                    },
                    {
                        "cityCode": "610300",
                        "cityName": "宝鸡市",
                        "parentId": "610000",
                        "provinceType": "2",
                        "province": "陕西省",
                        "city": "宝鸡市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "610301",
                                "cityName": "宝鸡市",
                                "parentId": "610300",
                                "provinceType": "2",
                                "province": "陕西省",
                                "city": "宝鸡市",
                                "county": "",

                            },
                            {
                                "cityCode": "610302",
                                "cityName": "渭滨区",
                                "parentId": "610300",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "宝鸡市",
                                "county": "渭滨区",

                            },
                            {
                                "cityCode": "610303",
                                "cityName": "金台区",
                                "parentId": "610300",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "宝鸡市",
                                "county": "金台区",

                            },
                            {
                                "cityCode": "610304",
                                "cityName": "陈仓区",
                                "parentId": "610300",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "宝鸡市",
                                "county": "陈仓区",

                            },
                            {
                                "cityCode": "610322",
                                "cityName": "凤翔县",
                                "parentId": "610300",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "宝鸡市",
                                "county": "凤翔县",

                            },
                            {
                                "cityCode": "610323",
                                "cityName": "岐山县",
                                "parentId": "610300",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "宝鸡市",
                                "county": "岐山县",

                            },
                            {
                                "cityCode": "610324",
                                "cityName": "扶风县",
                                "parentId": "610300",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "宝鸡市",
                                "county": "扶风县",

                            },
                            {
                                "cityCode": "610326",
                                "cityName": "眉县",
                                "parentId": "610300",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "宝鸡市",
                                "county": "眉县",

                            },
                            {
                                "cityCode": "610327",
                                "cityName": "陇县",
                                "parentId": "610300",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "宝鸡市",
                                "county": "陇县",

                            },
                            {
                                "cityCode": "610328",
                                "cityName": "千阳县",
                                "parentId": "610300",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "宝鸡市",
                                "county": "千阳县",

                            },
                            {
                                "cityCode": "610329",
                                "cityName": "麟游县",
                                "parentId": "610300",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "宝鸡市",
                                "county": "麟游县",

                            },
                            {
                                "cityCode": "610330",
                                "cityName": "凤县",
                                "parentId": "610300",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "宝鸡市",
                                "county": "凤县",

                            },
                            {
                                "cityCode": "610331",
                                "cityName": "太白县",
                                "parentId": "610300",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "宝鸡市",
                                "county": "太白县",

                            },
                            {
                                "cityCode": "610332",
                                "cityName": "高新区",
                                "parentId": "610300",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "宝鸡市",
                                "county": "高新区",

                            }
                        ]
                    },
                    {
                        "cityCode": "610400",
                        "cityName": "咸阳市",
                        "parentId": "610000",
                        "provinceType": "2",
                        "province": "陕西省",
                        "city": "咸阳市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "610401",
                                "cityName": "咸阳市",
                                "parentId": "610400",
                                "provinceType": "2",
                                "province": "陕西省",
                                "city": "咸阳市",
                                "county": "",

                            },
                            {
                                "cityCode": "610402",
                                "cityName": "秦都区",
                                "parentId": "610400",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "咸阳市",
                                "county": "秦都区",

                            },
                            {
                                "cityCode": "610403",
                                "cityName": "杨陵区",
                                "parentId": "610400",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "咸阳市",
                                "county": "杨陵区",

                            },
                            {
                                "cityCode": "610404",
                                "cityName": "渭城区",
                                "parentId": "610400",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "咸阳市",
                                "county": "渭城区",

                            },
                            {
                                "cityCode": "610422",
                                "cityName": "三原县",
                                "parentId": "610400",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "咸阳市",
                                "county": "三原县",

                            },
                            {
                                "cityCode": "610423",
                                "cityName": "泾阳县",
                                "parentId": "610400",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "咸阳市",
                                "county": "泾阳县",

                            },
                            {
                                "cityCode": "610424",
                                "cityName": "乾县",
                                "parentId": "610400",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "咸阳市",
                                "county": "乾县",

                            },
                            {
                                "cityCode": "610425",
                                "cityName": "礼泉县",
                                "parentId": "610400",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "咸阳市",
                                "county": "礼泉县",

                            },
                            {
                                "cityCode": "610426",
                                "cityName": "永寿县",
                                "parentId": "610400",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "咸阳市",
                                "county": "永寿县",

                            },
                            {
                                "cityCode": "610427",
                                "cityName": "彬县",
                                "parentId": "610400",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "咸阳市",
                                "county": "彬县",

                            },
                            {
                                "cityCode": "610428",
                                "cityName": "长武县",
                                "parentId": "610400",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "咸阳市",
                                "county": "长武县",

                            },
                            {
                                "cityCode": "610429",
                                "cityName": "旬邑县",
                                "parentId": "610400",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "咸阳市",
                                "county": "旬邑县",

                            },
                            {
                                "cityCode": "610430",
                                "cityName": "淳化县",
                                "parentId": "610400",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "咸阳市",
                                "county": "淳化县",

                            },
                            {
                                "cityCode": "610431",
                                "cityName": "武功县",
                                "parentId": "610400",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "咸阳市",
                                "county": "武功县",

                            },
                            {
                                "cityCode": "610481",
                                "cityName": "兴平市",
                                "parentId": "610400",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "咸阳市",
                                "county": "兴平市",

                            },
                            {
                                "cityCode": "610482",
                                "cityName": "高新区",
                                "parentId": "610400",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "咸阳市",
                                "county": "高新区",

                            },
                            {
                                "cityCode": "619100",
                                "cityName": "杨陵区",
                                "parentId": "610400",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "咸阳市",
                                "county": "杨陵区",

                            }
                        ]
                    },
                    {
                        "cityCode": "610500",
                        "cityName": "渭南市",
                        "parentId": "610000",
                        "provinceType": "2",
                        "province": "陕西省",
                        "city": "渭南市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "610501",
                                "cityName": "渭南市",
                                "parentId": "610500",
                                "provinceType": "2",
                                "province": "陕西省",
                                "city": "渭南市",
                                "county": "",

                            },
                            {
                                "cityCode": "610502",
                                "cityName": "临渭区",
                                "parentId": "610500",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "渭南市",
                                "county": "临渭区",

                            },
                            {
                                "cityCode": "610503",
                                "cityName": "华州区",
                                "parentId": "610500",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "渭南市",
                                "county": "华州区",

                            },
                            {
                                "cityCode": "610521",
                                "cityName": "华县",
                                "parentId": "610500",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "渭南市",
                                "county": "华县",

                            },
                            {
                                "cityCode": "610522",
                                "cityName": "潼关县",
                                "parentId": "610500",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "渭南市",
                                "county": "潼关县",

                            },
                            {
                                "cityCode": "610523",
                                "cityName": "大荔县",
                                "parentId": "610500",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "渭南市",
                                "county": "大荔县",

                            },
                            {
                                "cityCode": "610524",
                                "cityName": "合阳县",
                                "parentId": "610500",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "渭南市",
                                "county": "合阳县",

                            },
                            {
                                "cityCode": "610525",
                                "cityName": "澄城县",
                                "parentId": "610500",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "渭南市",
                                "county": "澄城县",

                            },
                            {
                                "cityCode": "610526",
                                "cityName": "蒲城县",
                                "parentId": "610500",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "渭南市",
                                "county": "蒲城县",

                            },
                            {
                                "cityCode": "610527",
                                "cityName": "白水县",
                                "parentId": "610500",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "渭南市",
                                "county": "白水县",

                            },
                            {
                                "cityCode": "610528",
                                "cityName": "富平县",
                                "parentId": "610500",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "渭南市",
                                "county": "富平县",

                            },
                            {
                                "cityCode": "610581",
                                "cityName": "韩城市",
                                "parentId": "610500",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "渭南市",
                                "county": "韩城市",

                            },
                            {
                                "cityCode": "610582",
                                "cityName": "华阴市",
                                "parentId": "610500",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "渭南市",
                                "county": "华阴市",

                            }
                        ]
                    },
                    {
                        "cityCode": "610600",
                        "cityName": "延安市",
                        "parentId": "610000",
                        "provinceType": "2",
                        "province": "陕西省",
                        "city": "延安市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "610601",
                                "cityName": "延安市",
                                "parentId": "610600",
                                "provinceType": "2",
                                "province": "陕西省",
                                "city": "延安市",
                                "county": "",

                            },
                            {
                                "cityCode": "610602",
                                "cityName": "宝塔区",
                                "parentId": "610600",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "延安市",
                                "county": "宝塔区",

                            },
                            {
                                "cityCode": "610603",
                                "cityName": "安塞区",
                                "parentId": "610600",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "延安市",
                                "county": "安塞区",

                            },
                            {
                                "cityCode": "610621",
                                "cityName": "延长县",
                                "parentId": "610600",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "延安市",
                                "county": "延长县",

                            },
                            {
                                "cityCode": "610622",
                                "cityName": "延川县",
                                "parentId": "610600",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "延安市",
                                "county": "延川县",

                            },
                            {
                                "cityCode": "610623",
                                "cityName": "子长县",
                                "parentId": "610600",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "延安市",
                                "county": "子长县",

                            },
                            {
                                "cityCode": "610624",
                                "cityName": "安塞区",
                                "parentId": "610600",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "延安市",
                                "county": "安塞区",

                            },
                            {
                                "cityCode": "610625",
                                "cityName": "志丹县",
                                "parentId": "610600",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "延安市",
                                "county": "志丹县",

                            },
                            {
                                "cityCode": "610626",
                                "cityName": "吴起县",
                                "parentId": "610600",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "延安市",
                                "county": "吴起县",

                            },
                            {
                                "cityCode": "610627",
                                "cityName": "甘泉县",
                                "parentId": "610600",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "延安市",
                                "county": "甘泉县",

                            },
                            {
                                "cityCode": "610628",
                                "cityName": "富县",
                                "parentId": "610600",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "延安市",
                                "county": "富县",

                            },
                            {
                                "cityCode": "610629",
                                "cityName": "洛川县",
                                "parentId": "610600",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "延安市",
                                "county": "洛川县",

                            },
                            {
                                "cityCode": "610630",
                                "cityName": "宜川县",
                                "parentId": "610600",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "延安市",
                                "county": "宜川县",

                            },
                            {
                                "cityCode": "610631",
                                "cityName": "黄龙县",
                                "parentId": "610600",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "延安市",
                                "county": "黄龙县",

                            },
                            {
                                "cityCode": "610632",
                                "cityName": "黄陵县",
                                "parentId": "610600",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "延安市",
                                "county": "黄陵县",

                            },
                            {
                                "cityCode": "610681",
                                "cityName": "子长市",
                                "parentId": "610600",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "延安市",
                                "county": "子长市",

                            }
                        ]
                    },
                    {
                        "cityCode": "610700",
                        "cityName": "汉中市",
                        "parentId": "610000",
                        "provinceType": "2",
                        "province": "陕西省",
                        "city": "汉中市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "610701",
                                "cityName": "汉中市",
                                "parentId": "610700",
                                "provinceType": "2",
                                "province": "陕西省",
                                "city": "汉中市",
                                "county": "",

                            },
                            {
                                "cityCode": "610702",
                                "cityName": "汉台区",
                                "parentId": "610700",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "汉中市",
                                "county": "汉台区",

                            },
                            {
                                "cityCode": "610703",
                                "cityName": "南郑区",
                                "parentId": "610700",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "汉中市",
                                "county": "南郑区",

                            },
                            {
                                "cityCode": "610721",
                                "cityName": "南郑县",
                                "parentId": "610700",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "汉中市",
                                "county": "南郑县",

                            },
                            {
                                "cityCode": "610722",
                                "cityName": "城固县",
                                "parentId": "610700",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "汉中市",
                                "county": "城固县",

                            },
                            {
                                "cityCode": "610723",
                                "cityName": "洋县",
                                "parentId": "610700",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "汉中市",
                                "county": "洋县",

                            },
                            {
                                "cityCode": "610724",
                                "cityName": "西乡县",
                                "parentId": "610700",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "汉中市",
                                "county": "西乡县",

                            },
                            {
                                "cityCode": "610725",
                                "cityName": "勉县",
                                "parentId": "610700",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "汉中市",
                                "county": "勉县",

                            },
                            {
                                "cityCode": "610726",
                                "cityName": "宁强县",
                                "parentId": "610700",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "汉中市",
                                "county": "宁强县",

                            },
                            {
                                "cityCode": "610727",
                                "cityName": "略阳县",
                                "parentId": "610700",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "汉中市",
                                "county": "略阳县",

                            },
                            {
                                "cityCode": "610728",
                                "cityName": "镇巴县",
                                "parentId": "610700",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "汉中市",
                                "county": "镇巴县",

                            },
                            {
                                "cityCode": "610729",
                                "cityName": "留坝县",
                                "parentId": "610700",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "汉中市",
                                "county": "留坝县",

                            },
                            {
                                "cityCode": "610730",
                                "cityName": "佛坪县",
                                "parentId": "610700",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "汉中市",
                                "county": "佛坪县",

                            }
                        ]
                    },
                    {
                        "cityCode": "610800",
                        "cityName": "榆林市",
                        "parentId": "610000",
                        "provinceType": "2",
                        "province": "陕西省",
                        "city": "榆林市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "610801",
                                "cityName": "榆林市",
                                "parentId": "610800",
                                "provinceType": "2",
                                "province": "陕西省",
                                "city": "榆林市",
                                "county": "",

                            },
                            {
                                "cityCode": "610802",
                                "cityName": "榆阳区",
                                "parentId": "610800",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "榆林市",
                                "county": "榆阳区",

                            },
                            {
                                "cityCode": "610803",
                                "cityName": "横山区",
                                "parentId": "610800",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "榆林市",
                                "county": "横山区",

                            },
                            {
                                "cityCode": "610821",
                                "cityName": "神木县",
                                "parentId": "610800",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "榆林市",
                                "county": "神木县",

                            },
                            {
                                "cityCode": "610822",
                                "cityName": "府谷县",
                                "parentId": "610800",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "榆林市",
                                "county": "府谷县",

                            },
                            {
                                "cityCode": "610823",
                                "cityName": "横山县",
                                "parentId": "610800",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "榆林市",
                                "county": "横山县",

                            },
                            {
                                "cityCode": "610824",
                                "cityName": "靖边县",
                                "parentId": "610800",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "榆林市",
                                "county": "靖边县",

                            },
                            {
                                "cityCode": "610825",
                                "cityName": "定边县",
                                "parentId": "610800",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "榆林市",
                                "county": "定边县",

                            },
                            {
                                "cityCode": "610826",
                                "cityName": "绥德县",
                                "parentId": "610800",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "榆林市",
                                "county": "绥德县",

                            },
                            {
                                "cityCode": "610827",
                                "cityName": "米脂县",
                                "parentId": "610800",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "榆林市",
                                "county": "米脂县",

                            },
                            {
                                "cityCode": "610828",
                                "cityName": "佳县",
                                "parentId": "610800",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "榆林市",
                                "county": "佳县",

                            },
                            {
                                "cityCode": "610829",
                                "cityName": "吴堡县",
                                "parentId": "610800",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "榆林市",
                                "county": "吴堡县",

                            },
                            {
                                "cityCode": "610830",
                                "cityName": "清涧县",
                                "parentId": "610800",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "榆林市",
                                "county": "清涧县",

                            },
                            {
                                "cityCode": "610831",
                                "cityName": "子洲县",
                                "parentId": "610800",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "榆林市",
                                "county": "子洲县",

                            },
                            {
                                "cityCode": "610881",
                                "cityName": "神木市",
                                "parentId": "610800",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "榆林市",
                                "county": "神木市",

                            }
                        ]
                    },
                    {
                        "cityCode": "610900",
                        "cityName": "安康市",
                        "parentId": "610000",
                        "provinceType": "2",
                        "province": "陕西省",
                        "city": "安康市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "610901",
                                "cityName": "安康市",
                                "parentId": "610900",
                                "provinceType": "2",
                                "province": "陕西省",
                                "city": "安康市",
                                "county": "",

                            },
                            {
                                "cityCode": "610902",
                                "cityName": "汉滨区",
                                "parentId": "610900",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "安康市",
                                "county": "汉滨区",

                            },
                            {
                                "cityCode": "610921",
                                "cityName": "汉阴县",
                                "parentId": "610900",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "安康市",
                                "county": "汉阴县",

                            },
                            {
                                "cityCode": "610922",
                                "cityName": "石泉县",
                                "parentId": "610900",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "安康市",
                                "county": "石泉县",

                            },
                            {
                                "cityCode": "610923",
                                "cityName": "宁陕县",
                                "parentId": "610900",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "安康市",
                                "county": "宁陕县",

                            },
                            {
                                "cityCode": "610924",
                                "cityName": "紫阳县",
                                "parentId": "610900",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "安康市",
                                "county": "紫阳县",

                            },
                            {
                                "cityCode": "610925",
                                "cityName": "岚皋县",
                                "parentId": "610900",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "安康市",
                                "county": "岚皋县",

                            },
                            {
                                "cityCode": "610926",
                                "cityName": "平利县",
                                "parentId": "610900",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "安康市",
                                "county": "平利县",

                            },
                            {
                                "cityCode": "610927",
                                "cityName": "镇坪县",
                                "parentId": "610900",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "安康市",
                                "county": "镇坪县",

                            },
                            {
                                "cityCode": "610928",
                                "cityName": "旬阳县",
                                "parentId": "610900",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "安康市",
                                "county": "旬阳县",

                            },
                            {
                                "cityCode": "610929",
                                "cityName": "白河县",
                                "parentId": "610900",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "安康市",
                                "county": "白河县",

                            }
                        ]
                    },
                    {
                        "cityCode": "611000",
                        "cityName": "商洛市",
                        "parentId": "610000",
                        "provinceType": "2",
                        "province": "陕西省",
                        "city": "商洛市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "611001",
                                "cityName": "商洛市",
                                "parentId": "611000",
                                "provinceType": "2",
                                "province": "陕西省",
                                "city": "商洛市",
                                "county": "",

                            },
                            {
                                "cityCode": "611002",
                                "cityName": "商州区",
                                "parentId": "611000",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "商洛市",
                                "county": "商州区",

                            },
                            {
                                "cityCode": "611021",
                                "cityName": "洛南县",
                                "parentId": "611000",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "商洛市",
                                "county": "洛南县",

                            },
                            {
                                "cityCode": "611022",
                                "cityName": "丹凤县",
                                "parentId": "611000",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "商洛市",
                                "county": "丹凤县",

                            },
                            {
                                "cityCode": "611023",
                                "cityName": "商南县",
                                "parentId": "611000",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "商洛市",
                                "county": "商南县",

                            },
                            {
                                "cityCode": "611024",
                                "cityName": "山阳县",
                                "parentId": "611000",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "商洛市",
                                "county": "山阳县",

                            },
                            {
                                "cityCode": "611025",
                                "cityName": "镇安县",
                                "parentId": "611000",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "商洛市",
                                "county": "镇安县",

                            },
                            {
                                "cityCode": "611026",
                                "cityName": "柞水县",
                                "parentId": "611000",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "商洛市",
                                "county": "柞水县",

                            }
                        ]
                    },
                    {
                        "cityCode": "611100",
                        "cityName": "西咸新区",
                        "parentId": "610000",
                        "provinceType": "2",
                        "province": "陕西省",
                        "city": "西咸新区",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "611101",
                                "cityName": "空港新城",
                                "parentId": "611100",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "西咸新区",
                                "county": "空港新城",

                            },
                            {
                                "cityCode": "611102",
                                "cityName": "沣东新城",
                                "parentId": "611100",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "西咸新区",
                                "county": "沣东新城",

                            },
                            {
                                "cityCode": "611103",
                                "cityName": "秦汉新城",
                                "parentId": "611100",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "西咸新区",
                                "county": "秦汉新城",

                            },
                            {
                                "cityCode": "611104",
                                "cityName": "沣西新城",
                                "parentId": "611100",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "西咸新区",
                                "county": "沣西新城",

                            },
                            {
                                "cityCode": "611105",
                                "cityName": "泾河新城",
                                "parentId": "611100",
                                "provinceType": "3",
                                "province": "陕西省",
                                "city": "西咸新区",
                                "county": "泾河新城",

                            }
                        ]
                    }
                ]
            },
            {
                "cityCode": "620000",
                "cityName": "甘肃省",
                "parentId": "100000",
                "provinceType": "1",
                "province": "甘肃省",
                "city": "",
                "county": "",
                "counties": [
                    {
                        "cityCode": "620100",
                        "cityName": "兰州市",
                        "parentId": "620000",
                        "provinceType": "2",
                        "province": "甘肃省",
                        "city": "兰州市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "620101",
                                "cityName": "兰州市",
                                "parentId": "620100",
                                "provinceType": "2",
                                "province": "甘肃省",
                                "city": "兰州市",
                                "county": "",

                            },
                            {
                                "cityCode": "620102",
                                "cityName": "城关区",
                                "parentId": "620100",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "兰州市",
                                "county": "城关区",

                            },
                            {
                                "cityCode": "620103",
                                "cityName": "七里河区",
                                "parentId": "620100",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "兰州市",
                                "county": "七里河区",

                            },
                            {
                                "cityCode": "620104",
                                "cityName": "西固区",
                                "parentId": "620100",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "兰州市",
                                "county": "西固区",

                            },
                            {
                                "cityCode": "620105",
                                "cityName": "安宁区",
                                "parentId": "620100",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "兰州市",
                                "county": "安宁区",

                            },
                            {
                                "cityCode": "620111",
                                "cityName": "红古区",
                                "parentId": "620100",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "兰州市",
                                "county": "红古区",

                            },
                            {
                                "cityCode": "620121",
                                "cityName": "永登县",
                                "parentId": "620100",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "兰州市",
                                "county": "永登县",

                            },
                            {
                                "cityCode": "620122",
                                "cityName": "皋兰县",
                                "parentId": "620100",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "兰州市",
                                "county": "皋兰县",

                            },
                            {
                                "cityCode": "620123",
                                "cityName": "榆中县",
                                "parentId": "620100",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "兰州市",
                                "county": "榆中县",

                            },
                            {
                                "cityCode": "620125",
                                "cityName": "高新区",
                                "parentId": "620100",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "兰州市",
                                "county": "高新区",

                            },
                            {
                                "cityCode": "620126",
                                "cityName": "经济开发区",
                                "parentId": "620100",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "兰州市",
                                "county": "经济开发区",

                            },
                            {
                                "cityCode": "620171",
                                "cityName": "兰州新区",
                                "parentId": "620100",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "兰州市",
                                "county": "兰州新区",

                            }
                        ]
                    },
                    {
                        "cityCode": "620200",
                        "cityName": "嘉峪关市",
                        "parentId": "620000",
                        "provinceType": "2",
                        "province": "甘肃省",
                        "city": "嘉峪关市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "620201",
                                "cityName": "市辖区",
                                "parentId": "620200",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "嘉峪关市",
                                "county": "市辖区",

                            },
                            {
                                "cityCode": "620202",
                                "cityName": "长城区",
                                "parentId": "620200",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "嘉峪关市",
                                "county": "长城区",

                            },
                            {
                                "cityCode": "620203",
                                "cityName": "镜铁区",
                                "parentId": "620200",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "嘉峪关市",
                                "county": "镜铁区",

                            },
                            {
                                "cityCode": "620204",
                                "cityName": "新城镇",
                                "parentId": "620200",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "嘉峪关市",
                                "county": "新城镇",

                            },
                            {
                                "cityCode": "620205",
                                "cityName": "峪泉镇",
                                "parentId": "620200",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "嘉峪关市",
                                "county": "峪泉镇",

                            },
                            {
                                "cityCode": "620206",
                                "cityName": "文殊镇",
                                "parentId": "620200",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "嘉峪关市",
                                "county": "文殊镇",

                            }
                        ]
                    },
                    {
                        "cityCode": "620300",
                        "cityName": "金昌市",
                        "parentId": "620000",
                        "provinceType": "2",
                        "province": "甘肃省",
                        "city": "金昌市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "620301",
                                "cityName": "金昌市",
                                "parentId": "620300",
                                "provinceType": "2",
                                "province": "甘肃省",
                                "city": "金昌市",
                                "county": "",

                            },
                            {
                                "cityCode": "620302",
                                "cityName": "金川区",
                                "parentId": "620300",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "金昌市",
                                "county": "金川区",

                            },
                            {
                                "cityCode": "620321",
                                "cityName": "永昌县",
                                "parentId": "620300",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "金昌市",
                                "county": "永昌县",

                            }
                        ]
                    },
                    {
                        "cityCode": "620400",
                        "cityName": "白银市",
                        "parentId": "620000",
                        "provinceType": "2",
                        "province": "甘肃省",
                        "city": "白银市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "620401",
                                "cityName": "白银市",
                                "parentId": "620400",
                                "provinceType": "2",
                                "province": "甘肃省",
                                "city": "白银市",
                                "county": "",

                            },
                            {
                                "cityCode": "620402",
                                "cityName": "白银区",
                                "parentId": "620400",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "白银市",
                                "county": "白银区",

                            },
                            {
                                "cityCode": "620403",
                                "cityName": "平川区",
                                "parentId": "620400",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "白银市",
                                "county": "平川区",

                            },
                            {
                                "cityCode": "620421",
                                "cityName": "靖远县",
                                "parentId": "620400",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "白银市",
                                "county": "靖远县",

                            },
                            {
                                "cityCode": "620422",
                                "cityName": "会宁县",
                                "parentId": "620400",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "白银市",
                                "county": "会宁县",

                            },
                            {
                                "cityCode": "620423",
                                "cityName": "景泰县",
                                "parentId": "620400",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "白银市",
                                "county": "景泰县",

                            }
                        ]
                    },
                    {
                        "cityCode": "620500",
                        "cityName": "天水市",
                        "parentId": "620000",
                        "provinceType": "2",
                        "province": "甘肃省",
                        "city": "天水市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "620501",
                                "cityName": "天水市",
                                "parentId": "620500",
                                "provinceType": "2",
                                "province": "甘肃省",
                                "city": "天水市",
                                "county": "",

                            },
                            {
                                "cityCode": "620502",
                                "cityName": "秦州区",
                                "parentId": "620500",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "天水市",
                                "county": "秦州区",

                            },
                            {
                                "cityCode": "620503",
                                "cityName": "麦积区",
                                "parentId": "620500",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "天水市",
                                "county": "麦积区",

                            },
                            {
                                "cityCode": "620521",
                                "cityName": "清水县",
                                "parentId": "620500",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "天水市",
                                "county": "清水县",

                            },
                            {
                                "cityCode": "620522",
                                "cityName": "秦安县",
                                "parentId": "620500",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "天水市",
                                "county": "秦安县",

                            },
                            {
                                "cityCode": "620523",
                                "cityName": "甘谷县",
                                "parentId": "620500",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "天水市",
                                "county": "甘谷县",

                            },
                            {
                                "cityCode": "620524",
                                "cityName": "武山县",
                                "parentId": "620500",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "天水市",
                                "county": "武山县",

                            },
                            {
                                "cityCode": "620525",
                                "cityName": "张家川回族自治县",
                                "parentId": "620500",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "天水市",
                                "county": "张家川回族自治县",

                            }
                        ]
                    },
                    {
                        "cityCode": "620600",
                        "cityName": "武威市",
                        "parentId": "620000",
                        "provinceType": "2",
                        "province": "甘肃省",
                        "city": "武威市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "620601",
                                "cityName": "武威市",
                                "parentId": "620600",
                                "provinceType": "2",
                                "province": "甘肃省",
                                "city": "武威市",
                                "county": "",

                            },
                            {
                                "cityCode": "620602",
                                "cityName": "凉州区",
                                "parentId": "620600",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "武威市",
                                "county": "凉州区",

                            },
                            {
                                "cityCode": "620621",
                                "cityName": "民勤县",
                                "parentId": "620600",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "武威市",
                                "county": "民勤县",

                            },
                            {
                                "cityCode": "620622",
                                "cityName": "古浪县",
                                "parentId": "620600",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "武威市",
                                "county": "古浪县",

                            },
                            {
                                "cityCode": "620623",
                                "cityName": "天祝藏族自治县",
                                "parentId": "620600",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "武威市",
                                "county": "天祝藏族自治县",

                            }
                        ]
                    },
                    {
                        "cityCode": "620700",
                        "cityName": "张掖市",
                        "parentId": "620000",
                        "provinceType": "2",
                        "province": "甘肃省",
                        "city": "张掖市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "620701",
                                "cityName": "张掖市",
                                "parentId": "620700",
                                "provinceType": "2",
                                "province": "甘肃省",
                                "city": "张掖市",
                                "county": "",

                            },
                            {
                                "cityCode": "620702",
                                "cityName": "甘州区",
                                "parentId": "620700",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "张掖市",
                                "county": "甘州区",

                            },
                            {
                                "cityCode": "620721",
                                "cityName": "肃南裕固族自治县",
                                "parentId": "620700",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "张掖市",
                                "county": "肃南裕固族自治县",

                            },
                            {
                                "cityCode": "620722",
                                "cityName": "民乐县",
                                "parentId": "620700",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "张掖市",
                                "county": "民乐县",

                            },
                            {
                                "cityCode": "620723",
                                "cityName": "临泽县",
                                "parentId": "620700",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "张掖市",
                                "county": "临泽县",

                            },
                            {
                                "cityCode": "620724",
                                "cityName": "高台县",
                                "parentId": "620700",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "张掖市",
                                "county": "高台县",

                            },
                            {
                                "cityCode": "620725",
                                "cityName": "山丹县",
                                "parentId": "620700",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "张掖市",
                                "county": "山丹县",

                            }
                        ]
                    },
                    {
                        "cityCode": "620800",
                        "cityName": "平凉市",
                        "parentId": "620000",
                        "provinceType": "2",
                        "province": "甘肃省",
                        "city": "平凉市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "620801",
                                "cityName": "平凉市",
                                "parentId": "620800",
                                "provinceType": "2",
                                "province": "甘肃省",
                                "city": "平凉市",
                                "county": "",

                            },
                            {
                                "cityCode": "620802",
                                "cityName": "崆峒区",
                                "parentId": "620800",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "平凉市",
                                "county": "崆峒区",

                            },
                            {
                                "cityCode": "620821",
                                "cityName": "泾川县",
                                "parentId": "620800",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "平凉市",
                                "county": "泾川县",

                            },
                            {
                                "cityCode": "620822",
                                "cityName": "灵台县",
                                "parentId": "620800",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "平凉市",
                                "county": "灵台县",

                            },
                            {
                                "cityCode": "620823",
                                "cityName": "崇信县",
                                "parentId": "620800",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "平凉市",
                                "county": "崇信县",

                            },
                            {
                                "cityCode": "620824",
                                "cityName": "华亭县",
                                "parentId": "620800",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "平凉市",
                                "county": "华亭县",

                            },
                            {
                                "cityCode": "620825",
                                "cityName": "庄浪县",
                                "parentId": "620800",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "平凉市",
                                "county": "庄浪县",

                            },
                            {
                                "cityCode": "620826",
                                "cityName": "静宁县",
                                "parentId": "620800",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "平凉市",
                                "county": "静宁县",

                            },
                            {
                                "cityCode": "620881",
                                "cityName": "华亭市",
                                "parentId": "620800",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "平凉市",
                                "county": "华亭市",

                            }
                        ]
                    },
                    {
                        "cityCode": "620900",
                        "cityName": "酒泉市",
                        "parentId": "620000",
                        "provinceType": "2",
                        "province": "甘肃省",
                        "city": "酒泉市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "620901",
                                "cityName": "酒泉市",
                                "parentId": "620900",
                                "provinceType": "2",
                                "province": "甘肃省",
                                "city": "酒泉市",
                                "county": "",

                            },
                            {
                                "cityCode": "620902",
                                "cityName": "肃州区",
                                "parentId": "620900",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "酒泉市",
                                "county": "肃州区",

                            },
                            {
                                "cityCode": "620921",
                                "cityName": "金塔县",
                                "parentId": "620900",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "酒泉市",
                                "county": "金塔县",

                            },
                            {
                                "cityCode": "620922",
                                "cityName": "瓜州县",
                                "parentId": "620900",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "酒泉市",
                                "county": "瓜州县",

                            },
                            {
                                "cityCode": "620923",
                                "cityName": "肃北蒙古族自治县",
                                "parentId": "620900",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "酒泉市",
                                "county": "肃北蒙古族自治县",

                            },
                            {
                                "cityCode": "620924",
                                "cityName": "阿克塞哈萨克族自治县",
                                "parentId": "620900",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "酒泉市",
                                "county": "阿克塞哈萨克族自治县",

                            },
                            {
                                "cityCode": "620981",
                                "cityName": "玉门市",
                                "parentId": "620900",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "酒泉市",
                                "county": "玉门市",

                            },
                            {
                                "cityCode": "620982",
                                "cityName": "敦煌市",
                                "parentId": "620900",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "酒泉市",
                                "county": "敦煌市",

                            }
                        ]
                    },
                    {
                        "cityCode": "621000",
                        "cityName": "庆阳市",
                        "parentId": "620000",
                        "provinceType": "2",
                        "province": "甘肃省",
                        "city": "庆阳市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "621001",
                                "cityName": "庆阳市",
                                "parentId": "621000",
                                "provinceType": "2",
                                "province": "甘肃省",
                                "city": "庆阳市",
                                "county": "",

                            },
                            {
                                "cityCode": "621002",
                                "cityName": "西峰区",
                                "parentId": "621000",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "庆阳市",
                                "county": "西峰区",

                            },
                            {
                                "cityCode": "621021",
                                "cityName": "庆城县",
                                "parentId": "621000",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "庆阳市",
                                "county": "庆城县",

                            },
                            {
                                "cityCode": "621022",
                                "cityName": "环县",
                                "parentId": "621000",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "庆阳市",
                                "county": "环县",

                            },
                            {
                                "cityCode": "621023",
                                "cityName": "华池县",
                                "parentId": "621000",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "庆阳市",
                                "county": "华池县",

                            },
                            {
                                "cityCode": "621024",
                                "cityName": "合水县",
                                "parentId": "621000",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "庆阳市",
                                "county": "合水县",

                            },
                            {
                                "cityCode": "621025",
                                "cityName": "正宁县",
                                "parentId": "621000",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "庆阳市",
                                "county": "正宁县",

                            },
                            {
                                "cityCode": "621026",
                                "cityName": "宁县",
                                "parentId": "621000",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "庆阳市",
                                "county": "宁县",

                            },
                            {
                                "cityCode": "621027",
                                "cityName": "镇原县",
                                "parentId": "621000",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "庆阳市",
                                "county": "镇原县",

                            }
                        ]
                    },
                    {
                        "cityCode": "621100",
                        "cityName": "定西市",
                        "parentId": "620000",
                        "provinceType": "2",
                        "province": "甘肃省",
                        "city": "定西市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "621101",
                                "cityName": "定西市",
                                "parentId": "621100",
                                "provinceType": "2",
                                "province": "甘肃省",
                                "city": "定西市",
                                "county": "",

                            },
                            {
                                "cityCode": "621102",
                                "cityName": "安定区",
                                "parentId": "621100",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "定西市",
                                "county": "安定区",

                            },
                            {
                                "cityCode": "621121",
                                "cityName": "通渭县",
                                "parentId": "621100",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "定西市",
                                "county": "通渭县",

                            },
                            {
                                "cityCode": "621122",
                                "cityName": "陇西县",
                                "parentId": "621100",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "定西市",
                                "county": "陇西县",

                            },
                            {
                                "cityCode": "621123",
                                "cityName": "渭源县",
                                "parentId": "621100",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "定西市",
                                "county": "渭源县",

                            },
                            {
                                "cityCode": "621124",
                                "cityName": "临洮县",
                                "parentId": "621100",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "定西市",
                                "county": "临洮县",

                            },
                            {
                                "cityCode": "621125",
                                "cityName": "漳县",
                                "parentId": "621100",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "定西市",
                                "county": "漳县",

                            },
                            {
                                "cityCode": "621126",
                                "cityName": "岷县",
                                "parentId": "621100",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "定西市",
                                "county": "岷县",

                            }
                        ]
                    },
                    {
                        "cityCode": "621200",
                        "cityName": "陇南市",
                        "parentId": "620000",
                        "provinceType": "2",
                        "province": "甘肃省",
                        "city": "陇南市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "621201",
                                "cityName": "陇南市",
                                "parentId": "621200",
                                "provinceType": "2",
                                "province": "甘肃省",
                                "city": "陇南市",
                                "county": "",

                            },
                            {
                                "cityCode": "621202",
                                "cityName": "武都区",
                                "parentId": "621200",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "陇南市",
                                "county": "武都区",

                            },
                            {
                                "cityCode": "621221",
                                "cityName": "成县",
                                "parentId": "621200",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "陇南市",
                                "county": "成县",

                            },
                            {
                                "cityCode": "621222",
                                "cityName": "文县",
                                "parentId": "621200",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "陇南市",
                                "county": "文县",

                            },
                            {
                                "cityCode": "621223",
                                "cityName": "宕昌县",
                                "parentId": "621200",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "陇南市",
                                "county": "宕昌县",

                            },
                            {
                                "cityCode": "621224",
                                "cityName": "康县",
                                "parentId": "621200",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "陇南市",
                                "county": "康县",

                            },
                            {
                                "cityCode": "621225",
                                "cityName": "西和县",
                                "parentId": "621200",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "陇南市",
                                "county": "西和县",

                            },
                            {
                                "cityCode": "621226",
                                "cityName": "礼县",
                                "parentId": "621200",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "陇南市",
                                "county": "礼县",

                            },
                            {
                                "cityCode": "621227",
                                "cityName": "徽县",
                                "parentId": "621200",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "陇南市",
                                "county": "徽县",

                            },
                            {
                                "cityCode": "621228",
                                "cityName": "两当县",
                                "parentId": "621200",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "陇南市",
                                "county": "两当县",

                            }
                        ]
                    },
                    {
                        "cityCode": "622900",
                        "cityName": "临夏回族自治州",
                        "parentId": "620000",
                        "provinceType": "2",
                        "province": "甘肃省",
                        "city": "临夏回族自治州",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "622901",
                                "cityName": "临夏市",
                                "parentId": "622900",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "临夏回族自治州",
                                "county": "临夏市",

                            },
                            {
                                "cityCode": "622921",
                                "cityName": "临夏县",
                                "parentId": "622900",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "临夏回族自治州",
                                "county": "临夏县",

                            },
                            {
                                "cityCode": "622922",
                                "cityName": "康乐县",
                                "parentId": "622900",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "临夏回族自治州",
                                "county": "康乐县",

                            },
                            {
                                "cityCode": "622923",
                                "cityName": "永靖县",
                                "parentId": "622900",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "临夏回族自治州",
                                "county": "永靖县",

                            },
                            {
                                "cityCode": "622924",
                                "cityName": "广河县",
                                "parentId": "622900",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "临夏回族自治州",
                                "county": "广河县",

                            },
                            {
                                "cityCode": "622925",
                                "cityName": "和政县",
                                "parentId": "622900",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "临夏回族自治州",
                                "county": "和政县",

                            },
                            {
                                "cityCode": "622926",
                                "cityName": "东乡族自治县",
                                "parentId": "622900",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "临夏回族自治州",
                                "county": "东乡族自治县",

                            },
                            {
                                "cityCode": "622927",
                                "cityName": "积石山保安族东乡族撒拉族自治县",
                                "parentId": "622900",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "临夏回族自治州",
                                "county": "积石山保安族东乡族撒拉族自治县",

                            }
                        ]
                    },
                    {
                        "cityCode": "623000",
                        "cityName": "甘南藏族自治州",
                        "parentId": "620000",
                        "provinceType": "2",
                        "province": "甘肃省",
                        "city": "甘南藏族自治州",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "623001",
                                "cityName": "合作市",
                                "parentId": "623000",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "甘南藏族自治州",
                                "county": "合作市",

                            },
                            {
                                "cityCode": "623021",
                                "cityName": "临潭县",
                                "parentId": "623000",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "甘南藏族自治州",
                                "county": "临潭县",

                            },
                            {
                                "cityCode": "623022",
                                "cityName": "卓尼县",
                                "parentId": "623000",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "甘南藏族自治州",
                                "county": "卓尼县",

                            },
                            {
                                "cityCode": "623023",
                                "cityName": "舟曲县",
                                "parentId": "623000",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "甘南藏族自治州",
                                "county": "舟曲县",

                            },
                            {
                                "cityCode": "623024",
                                "cityName": "迭部县",
                                "parentId": "623000",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "甘南藏族自治州",
                                "county": "迭部县",

                            },
                            {
                                "cityCode": "623025",
                                "cityName": "玛曲县",
                                "parentId": "623000",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "甘南藏族自治州",
                                "county": "玛曲县",

                            },
                            {
                                "cityCode": "623026",
                                "cityName": "碌曲县",
                                "parentId": "623000",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "甘南藏族自治州",
                                "county": "碌曲县",

                            },
                            {
                                "cityCode": "623027",
                                "cityName": "夏河县",
                                "parentId": "623000",
                                "provinceType": "3",
                                "province": "甘肃省",
                                "city": "甘南藏族自治州",
                                "county": "夏河县",

                            }
                        ]
                    }
                ]
            },
            {
                "cityCode": "630000",
                "cityName": "青海省",
                "parentId": "100000",
                "provinceType": "1",
                "province": "青海省",
                "city": "",
                "county": "",
                "counties": [
                    {
                        "cityCode": "630100",
                        "cityName": "西宁市",
                        "parentId": "630000",
                        "provinceType": "2",
                        "province": "青海省",
                        "city": "西宁市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "630101",
                                "cityName": "西宁市",
                                "parentId": "630100",
                                "provinceType": "2",
                                "province": "青海省",
                                "city": "西宁市",
                                "county": "",

                            },
                            {
                                "cityCode": "630102",
                                "cityName": "城东区",
                                "parentId": "630100",
                                "provinceType": "3",
                                "province": "青海省",
                                "city": "西宁市",
                                "county": "城东区",

                            },
                            {
                                "cityCode": "630103",
                                "cityName": "城中区",
                                "parentId": "630100",
                                "provinceType": "3",
                                "province": "青海省",
                                "city": "西宁市",
                                "county": "城中区",

                            },
                            {
                                "cityCode": "630104",
                                "cityName": "城西区",
                                "parentId": "630100",
                                "provinceType": "3",
                                "province": "青海省",
                                "city": "西宁市",
                                "county": "城西区",

                            },
                            {
                                "cityCode": "630105",
                                "cityName": "城北区",
                                "parentId": "630100",
                                "provinceType": "3",
                                "province": "青海省",
                                "city": "西宁市",
                                "county": "城北区",

                            },
                            {
                                "cityCode": "630106",
                                "cityName": "湟中区",
                                "parentId": "630100",
                                "provinceType": "3",
                                "province": "青海省",
                                "city": "西宁市",
                                "county": "湟中区",

                            },
                            {
                                "cityCode": "630121",
                                "cityName": "大通回族土族自治县",
                                "parentId": "630100",
                                "provinceType": "3",
                                "province": "青海省",
                                "city": "西宁市",
                                "county": "大通回族土族自治县",

                            },
                            {
                                "cityCode": "630122",
                                "cityName": "湟中县",
                                "parentId": "630100",
                                "provinceType": "3",
                                "province": "青海省",
                                "city": "西宁市",
                                "county": "湟中县",

                            },
                            {
                                "cityCode": "630123",
                                "cityName": "湟源县",
                                "parentId": "630100",
                                "provinceType": "3",
                                "province": "青海省",
                                "city": "西宁市",
                                "county": "湟源县",

                            }
                        ]
                    },
                    {
                        "cityCode": "630200",
                        "cityName": "海东市",
                        "parentId": "630000",
                        "provinceType": "2",
                        "province": "青海省",
                        "city": "海东市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "630202",
                                "cityName": "乐都区",
                                "parentId": "630200",
                                "provinceType": "3",
                                "province": "青海省",
                                "city": "海东地区",
                                "county": "乐都区",

                            },
                            {
                                "cityCode": "630203",
                                "cityName": "平安区",
                                "parentId": "630200",
                                "provinceType": "3",
                                "province": "青海省",
                                "city": "海东市",
                                "county": "平安区",

                            },
                            {
                                "cityCode": "630222",
                                "cityName": "民和回族土族自治县",
                                "parentId": "630200",
                                "provinceType": "3",
                                "province": "青海省",
                                "city": "海东市",
                                "county": "民和回族土族自治县",

                            },
                            {
                                "cityCode": "630223",
                                "cityName": "互助土族自治县",
                                "parentId": "630200",
                                "provinceType": "3",
                                "province": "青海省",
                                "city": "海东市",
                                "county": "互助土族自治县",

                            },
                            {
                                "cityCode": "630224",
                                "cityName": "化隆回族自治县",
                                "parentId": "630200",
                                "provinceType": "3",
                                "province": "青海省",
                                "city": "海东市",
                                "county": "化隆回族自治县",

                            },
                            {
                                "cityCode": "630225",
                                "cityName": "循化撒拉族自治县",
                                "parentId": "630200",
                                "provinceType": "3",
                                "province": "青海省",
                                "city": "海东市",
                                "county": "循化撒拉族自治县",

                            }
                        ]
                    },
                    {
                        "cityCode": "632200",
                        "cityName": "海北藏族自治州",
                        "parentId": "630000",
                        "provinceType": "2",
                        "province": "青海省",
                        "city": "海北藏族自治州",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "632221",
                                "cityName": "门源回族自治县",
                                "parentId": "632200",
                                "provinceType": "3",
                                "province": "青海省",
                                "city": "海北藏族自治州",
                                "county": "门源回族自治县",

                            },
                            {
                                "cityCode": "632222",
                                "cityName": "祁连县",
                                "parentId": "632200",
                                "provinceType": "3",
                                "province": "青海省",
                                "city": "海北藏族自治州",
                                "county": "祁连县",

                            },
                            {
                                "cityCode": "632223",
                                "cityName": "海晏县",
                                "parentId": "632200",
                                "provinceType": "3",
                                "province": "青海省",
                                "city": "海北藏族自治州",
                                "county": "海晏县",

                            },
                            {
                                "cityCode": "632224",
                                "cityName": "刚察县",
                                "parentId": "632200",
                                "provinceType": "3",
                                "province": "青海省",
                                "city": "海北藏族自治州",
                                "county": "刚察县",

                            }
                        ]
                    },
                    {
                        "cityCode": "632300",
                        "cityName": "黄南藏族自治州",
                        "parentId": "630000",
                        "provinceType": "2",
                        "province": "青海省",
                        "city": "黄南藏族自治州",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "632301",
                                "cityName": "同仁市",
                                "parentId": "632300",
                                "provinceType": "3",
                                "province": "青海省",
                                "city": "黄南藏族自治州",
                                "county": "同仁市",

                            },
                            {
                                "cityCode": "632321",
                                "cityName": "同仁县",
                                "parentId": "632300",
                                "provinceType": "3",
                                "province": "青海省",
                                "city": "黄南藏族自治州",
                                "county": "同仁县",

                            },
                            {
                                "cityCode": "632322",
                                "cityName": "尖扎县",
                                "parentId": "632300",
                                "provinceType": "3",
                                "province": "青海省",
                                "city": "黄南藏族自治州",
                                "county": "尖扎县",

                            },
                            {
                                "cityCode": "632323",
                                "cityName": "泽库县",
                                "parentId": "632300",
                                "provinceType": "3",
                                "province": "青海省",
                                "city": "黄南藏族自治州",
                                "county": "泽库县",

                            },
                            {
                                "cityCode": "632324",
                                "cityName": "河南蒙古族自治县",
                                "parentId": "632300",
                                "provinceType": "3",
                                "province": "青海省",
                                "city": "黄南藏族自治州",
                                "county": "河南蒙古族自治县",

                            }
                        ]
                    },
                    {
                        "cityCode": "632500",
                        "cityName": "海南藏族自治州",
                        "parentId": "630000",
                        "provinceType": "2",
                        "province": "青海省",
                        "city": "海南藏族自治州",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "632521",
                                "cityName": "共和县",
                                "parentId": "632500",
                                "provinceType": "3",
                                "province": "青海省",
                                "city": "海南藏族自治州",
                                "county": "共和县",

                            },
                            {
                                "cityCode": "632522",
                                "cityName": "同德县",
                                "parentId": "632500",
                                "provinceType": "3",
                                "province": "青海省",
                                "city": "海南藏族自治州",
                                "county": "同德县",

                            },
                            {
                                "cityCode": "632523",
                                "cityName": "贵德县",
                                "parentId": "632500",
                                "provinceType": "3",
                                "province": "青海省",
                                "city": "海南藏族自治州",
                                "county": "贵德县",

                            },
                            {
                                "cityCode": "632524",
                                "cityName": "兴海县",
                                "parentId": "632500",
                                "provinceType": "3",
                                "province": "青海省",
                                "city": "海南藏族自治州",
                                "county": "兴海县",

                            },
                            {
                                "cityCode": "632525",
                                "cityName": "贵南县",
                                "parentId": "632500",
                                "provinceType": "3",
                                "province": "青海省",
                                "city": "海南藏族自治州",
                                "county": "贵南县",

                            }
                        ]
                    },
                    {
                        "cityCode": "632600",
                        "cityName": "果洛藏族自治州",
                        "parentId": "630000",
                        "provinceType": "2",
                        "province": "青海省",
                        "city": "果洛藏族自治州",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "632621",
                                "cityName": "玛沁县",
                                "parentId": "632600",
                                "provinceType": "3",
                                "province": "青海省",
                                "city": "果洛藏族自治州",
                                "county": "玛沁县",

                            },
                            {
                                "cityCode": "632622",
                                "cityName": "班玛县",
                                "parentId": "632600",
                                "provinceType": "3",
                                "province": "青海省",
                                "city": "果洛藏族自治州",
                                "county": "班玛县",

                            },
                            {
                                "cityCode": "632623",
                                "cityName": "甘德县",
                                "parentId": "632600",
                                "provinceType": "3",
                                "province": "青海省",
                                "city": "果洛藏族自治州",
                                "county": "甘德县",

                            },
                            {
                                "cityCode": "632624",
                                "cityName": "达日县",
                                "parentId": "632600",
                                "provinceType": "3",
                                "province": "青海省",
                                "city": "果洛藏族自治州",
                                "county": "达日县",

                            },
                            {
                                "cityCode": "632625",
                                "cityName": "久治县",
                                "parentId": "632600",
                                "provinceType": "3",
                                "province": "青海省",
                                "city": "果洛藏族自治州",
                                "county": "久治县",

                            },
                            {
                                "cityCode": "632626",
                                "cityName": "玛多县",
                                "parentId": "632600",
                                "provinceType": "3",
                                "province": "青海省",
                                "city": "果洛藏族自治州",
                                "county": "玛多县",

                            }
                        ]
                    },
                    {
                        "cityCode": "632700",
                        "cityName": "玉树藏族自治州",
                        "parentId": "630000",
                        "provinceType": "2",
                        "province": "青海省",
                        "city": "玉树藏族自治州",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "632701",
                                "cityName": "玉树市",
                                "parentId": "632700",
                                "provinceType": "3",
                                "province": "青海省",
                                "city": "玉树藏族自治州",
                                "county": "玉树市",

                            },
                            {
                                "cityCode": "632721",
                                "cityName": "玉树县",
                                "parentId": "632700",
                                "provinceType": "3",
                                "province": "青海省",
                                "city": "玉树藏族自治州",
                                "county": "玉树县",

                            },
                            {
                                "cityCode": "632722",
                                "cityName": "杂多县",
                                "parentId": "632700",
                                "provinceType": "3",
                                "province": "青海省",
                                "city": "玉树藏族自治州",
                                "county": "杂多县",

                            },
                            {
                                "cityCode": "632723",
                                "cityName": "称多县",
                                "parentId": "632700",
                                "provinceType": "3",
                                "province": "青海省",
                                "city": "玉树藏族自治州",
                                "county": "称多县",

                            },
                            {
                                "cityCode": "632724",
                                "cityName": "治多县",
                                "parentId": "632700",
                                "provinceType": "3",
                                "province": "青海省",
                                "city": "玉树藏族自治州",
                                "county": "治多县",

                            },
                            {
                                "cityCode": "632725",
                                "cityName": "囊谦县",
                                "parentId": "632700",
                                "provinceType": "3",
                                "province": "青海省",
                                "city": "玉树藏族自治州",
                                "county": "囊谦县",

                            },
                            {
                                "cityCode": "632726",
                                "cityName": "曲麻莱县",
                                "parentId": "632700",
                                "provinceType": "3",
                                "province": "青海省",
                                "city": "玉树藏族自治州",
                                "county": "曲麻莱县",

                            }
                        ]
                    },
                    {
                        "cityCode": "632800",
                        "cityName": "海西蒙古族藏族自治州",
                        "parentId": "630000",
                        "provinceType": "2",
                        "province": "青海省",
                        "city": "海西蒙古族藏族自治州",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "632801",
                                "cityName": "格尔木市",
                                "parentId": "632800",
                                "provinceType": "3",
                                "province": "青海省",
                                "city": "海西蒙古族藏族自治州",
                                "county": "格尔木市",

                            },
                            {
                                "cityCode": "632802",
                                "cityName": "德令哈市",
                                "parentId": "632800",
                                "provinceType": "3",
                                "province": "青海省",
                                "city": "海西蒙古族藏族自治州",
                                "county": "德令哈市",

                            },
                            {
                                "cityCode": "632803",
                                "cityName": "茫崖市",
                                "parentId": "632800",
                                "provinceType": "3",
                                "province": "青海省",
                                "city": "海西蒙古族藏族自治州",
                                "county": "茫崖市",

                            },
                            {
                                "cityCode": "632821",
                                "cityName": "乌兰县",
                                "parentId": "632800",
                                "provinceType": "3",
                                "province": "青海省",
                                "city": "海西蒙古族藏族自治州",
                                "county": "乌兰县",

                            },
                            {
                                "cityCode": "632822",
                                "cityName": "都兰县",
                                "parentId": "632800",
                                "provinceType": "3",
                                "province": "青海省",
                                "city": "海西蒙古族藏族自治州",
                                "county": "都兰县",

                            },
                            {
                                "cityCode": "632823",
                                "cityName": "天峻县",
                                "parentId": "632800",
                                "provinceType": "3",
                                "province": "青海省",
                                "city": "海西蒙古族藏族自治州",
                                "county": "天峻县",

                            },
                            {
                                "cityCode": "632824",
                                "cityName": "大柴旦",
                                "parentId": "632800",
                                "provinceType": "3",
                                "province": "青海省",
                                "city": "海西蒙古族藏族自治州",
                                "county": "大柴旦",

                            },
                            {
                                "cityCode": "632825",
                                "cityName": "冷湖",
                                "parentId": "632800",
                                "provinceType": "3",
                                "province": "青海省",
                                "city": "海西蒙古族藏族自治州",
                                "county": "冷湖",

                            },
                            {
                                "cityCode": "632826",
                                "cityName": "茫崖",
                                "parentId": "632800",
                                "provinceType": "3",
                                "province": "青海省",
                                "city": "海西蒙古族藏族自治州",
                                "county": "茫崖",

                            },
                            {
                                "cityCode": "632857",
                                "cityName": "大柴旦行政委员会",
                                "parentId": "632800",
                                "provinceType": "3",
                                "province": "青海省",
                                "city": "海西蒙古族藏族自治州",
                                "county": "大柴旦行政委员会",

                            },
                            {
                                "cityCode": "632858",
                                "cityName": "冷湖行政委员会",
                                "parentId": "632800",
                                "provinceType": "3",
                                "province": "青海省",
                                "city": "海西蒙古族藏族自治州",
                                "county": "冷湖行政委员会",

                            },
                            {
                                "cityCode": "632859",
                                "cityName": "茫崖行政委员会",
                                "parentId": "632800",
                                "provinceType": "3",
                                "province": "青海省",
                                "city": "海西蒙古族藏族自治州",
                                "county": "茫崖行政委员会",

                            }
                        ]
                    }
                ]
            },
            {
                "cityCode": "640000",
                "cityName": "宁夏回族自治区",
                "parentId": "100000",
                "provinceType": "1",
                "province": "宁夏回族自治区",
                "city": "",
                "county": "",
                "counties": [
                    {
                        "cityCode": "640100",
                        "cityName": "银川市",
                        "parentId": "640000",
                        "provinceType": "2",
                        "province": "宁夏回族自治区",
                        "city": "银川市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "640101",
                                "cityName": "银川市",
                                "parentId": "640100",
                                "provinceType": "2",
                                "province": "宁夏回族自治区",
                                "city": "银川市",
                                "county": "",

                            },
                            {
                                "cityCode": "640104",
                                "cityName": "兴庆区",
                                "parentId": "640100",
                                "provinceType": "3",
                                "province": "宁夏回族自治区",
                                "city": "银川市",
                                "county": "兴庆区",

                            },
                            {
                                "cityCode": "640105",
                                "cityName": "西夏区",
                                "parentId": "640100",
                                "provinceType": "3",
                                "province": "宁夏回族自治区",
                                "city": "银川市",
                                "county": "西夏区",

                            },
                            {
                                "cityCode": "640106",
                                "cityName": "金凤区",
                                "parentId": "640100",
                                "provinceType": "3",
                                "province": "宁夏回族自治区",
                                "city": "银川市",
                                "county": "金凤区",

                            },
                            {
                                "cityCode": "640121",
                                "cityName": "永宁县",
                                "parentId": "640100",
                                "provinceType": "3",
                                "province": "宁夏回族自治区",
                                "city": "银川市",
                                "county": "永宁县",

                            },
                            {
                                "cityCode": "640122",
                                "cityName": "贺兰县",
                                "parentId": "640100",
                                "provinceType": "3",
                                "province": "宁夏回族自治区",
                                "city": "银川市",
                                "county": "贺兰县",

                            },
                            {
                                "cityCode": "640181",
                                "cityName": "灵武市",
                                "parentId": "640100",
                                "provinceType": "3",
                                "province": "宁夏回族自治区",
                                "city": "银川市",
                                "county": "灵武市",

                            },
                            {
                                "cityCode": "640182",
                                "cityName": "经济开发区",
                                "parentId": "640100",
                                "provinceType": "3",
                                "province": "宁夏回族自治区",
                                "city": "银川市",
                                "county": "经济开发区",

                            }
                        ]
                    },
                    {
                        "cityCode": "640200",
                        "cityName": "石嘴山市",
                        "parentId": "640000",
                        "provinceType": "2",
                        "province": "宁夏回族自治区",
                        "city": "石嘴山市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "640201",
                                "cityName": "石嘴山市",
                                "parentId": "640200",
                                "provinceType": "2",
                                "province": "宁夏回族自治区",
                                "city": "石嘴山市",
                                "county": "",

                            },
                            {
                                "cityCode": "640202",
                                "cityName": "大武口区",
                                "parentId": "640200",
                                "provinceType": "3",
                                "province": "宁夏回族自治区",
                                "city": "石嘴山市",
                                "county": "大武口区",

                            },
                            {
                                "cityCode": "640205",
                                "cityName": "惠农区",
                                "parentId": "640200",
                                "provinceType": "3",
                                "province": "宁夏回族自治区",
                                "city": "石嘴山市",
                                "county": "惠农区",

                            },
                            {
                                "cityCode": "640221",
                                "cityName": "平罗县",
                                "parentId": "640200",
                                "provinceType": "3",
                                "province": "宁夏回族自治区",
                                "city": "石嘴山市",
                                "county": "平罗县",

                            },
                            {
                                "cityCode": "640222",
                                "cityName": "经济开发区",
                                "parentId": "640200",
                                "provinceType": "3",
                                "province": "宁夏回族自治区",
                                "city": "石嘴山市",
                                "county": "经济开发区",

                            }
                        ]
                    },
                    {
                        "cityCode": "640300",
                        "cityName": "吴忠市",
                        "parentId": "640000",
                        "provinceType": "2",
                        "province": "宁夏回族自治区",
                        "city": "吴忠市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "640301",
                                "cityName": "吴忠市",
                                "parentId": "640300",
                                "provinceType": "2",
                                "province": "宁夏回族自治区",
                                "city": "吴忠市",
                                "county": "",

                            },
                            {
                                "cityCode": "640302",
                                "cityName": "利通区",
                                "parentId": "640300",
                                "provinceType": "3",
                                "province": "宁夏回族自治区",
                                "city": "吴忠市",
                                "county": "利通区",

                            },
                            {
                                "cityCode": "640303",
                                "cityName": "红寺堡区",
                                "parentId": "640300",
                                "provinceType": "3",
                                "province": "宁夏回族自治区",
                                "city": "吴忠市",
                                "county": "红寺堡区",

                            },
                            {
                                "cityCode": "640323",
                                "cityName": "盐池县",
                                "parentId": "640300",
                                "provinceType": "3",
                                "province": "宁夏回族自治区",
                                "city": "吴忠市",
                                "county": "盐池县",

                            },
                            {
                                "cityCode": "640324",
                                "cityName": "同心县",
                                "parentId": "640300",
                                "provinceType": "3",
                                "province": "宁夏回族自治区",
                                "city": "吴忠市",
                                "county": "同心县",

                            },
                            {
                                "cityCode": "640381",
                                "cityName": "青铜峡市",
                                "parentId": "640300",
                                "provinceType": "3",
                                "province": "宁夏回族自治区",
                                "city": "吴忠市",
                                "county": "青铜峡市",

                            },
                            {
                                "cityCode": "640398",
                                "cityName": "太阳山区",
                                "parentId": "640300",
                                "provinceType": "3",
                                "province": "宁夏回族自治区",
                                "city": "吴忠市",
                                "county": "太阳山区",

                            }
                        ]
                    },
                    {
                        "cityCode": "640400",
                        "cityName": "固原市",
                        "parentId": "640000",
                        "provinceType": "2",
                        "province": "宁夏回族自治区",
                        "city": "固原市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "640401",
                                "cityName": "固原市",
                                "parentId": "640400",
                                "provinceType": "2",
                                "province": "宁夏回族自治区",
                                "city": "固原市",
                                "county": "",

                            },
                            {
                                "cityCode": "640402",
                                "cityName": "原州区",
                                "parentId": "640400",
                                "provinceType": "3",
                                "province": "宁夏回族自治区",
                                "city": "固原市",
                                "county": "原州区",

                            },
                            {
                                "cityCode": "640422",
                                "cityName": "西吉县",
                                "parentId": "640400",
                                "provinceType": "3",
                                "province": "宁夏回族自治区",
                                "city": "固原市",
                                "county": "西吉县",

                            },
                            {
                                "cityCode": "640423",
                                "cityName": "隆德县",
                                "parentId": "640400",
                                "provinceType": "3",
                                "province": "宁夏回族自治区",
                                "city": "固原市",
                                "county": "隆德县",

                            },
                            {
                                "cityCode": "640424",
                                "cityName": "泾源县",
                                "parentId": "640400",
                                "provinceType": "3",
                                "province": "宁夏回族自治区",
                                "city": "固原市",
                                "county": "泾源县",

                            },
                            {
                                "cityCode": "640425",
                                "cityName": "彭阳县",
                                "parentId": "640400",
                                "provinceType": "3",
                                "province": "宁夏回族自治区",
                                "city": "固原市",
                                "county": "彭阳县",

                            }
                        ]
                    },
                    {
                        "cityCode": "640500",
                        "cityName": "中卫市",
                        "parentId": "640000",
                        "provinceType": "2",
                        "province": "宁夏回族自治区",
                        "city": "中卫市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "640501",
                                "cityName": "中卫市",
                                "parentId": "640500",
                                "provinceType": "2",
                                "province": "宁夏回族自治区",
                                "city": "中卫市",
                                "county": "",

                            },
                            {
                                "cityCode": "640502",
                                "cityName": "沙坡头区",
                                "parentId": "640500",
                                "provinceType": "3",
                                "province": "宁夏回族自治区",
                                "city": "中卫市",
                                "county": "沙坡头区",

                            },
                            {
                                "cityCode": "640521",
                                "cityName": "中宁县",
                                "parentId": "640500",
                                "provinceType": "3",
                                "province": "宁夏回族自治区",
                                "city": "中卫市",
                                "county": "中宁县",

                            },
                            {
                                "cityCode": "640522",
                                "cityName": "海原县",
                                "parentId": "640500",
                                "provinceType": "3",
                                "province": "宁夏回族自治区",
                                "city": "中卫市",
                                "county": "海原县",

                            }
                        ]
                    }
                ]
            },
            {
                "cityCode": "650000",
                "cityName": "新疆维吾尔自治区",
                "parentId": "100000",
                "provinceType": "1",
                "province": "新疆维吾尔自治区",
                "city": "",
                "county": "",
                "counties": [
                    {
                        "cityCode": "650100",
                        "cityName": "乌鲁木齐市",
                        "parentId": "650000",
                        "provinceType": "2",
                        "province": "新疆维吾尔自治区",
                        "city": "乌鲁木齐市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "650101",
                                "cityName": "乌鲁木齐市",
                                "parentId": "650100",
                                "provinceType": "2",
                                "province": "新疆维吾尔自治区",
                                "city": "乌鲁木齐市",
                                "county": "",

                            },
                            {
                                "cityCode": "650102",
                                "cityName": "天山区",
                                "parentId": "650100",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "乌鲁木齐市",
                                "county": "天山区",

                            },
                            {
                                "cityCode": "650103",
                                "cityName": "沙依巴克区",
                                "parentId": "650100",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "乌鲁木齐市",
                                "county": "沙依巴克区",

                            },
                            {
                                "cityCode": "650104",
                                "cityName": "新市区",
                                "parentId": "650100",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "乌鲁木齐市",
                                "county": "新市区",

                            },
                            {
                                "cityCode": "650105",
                                "cityName": "水磨沟区",
                                "parentId": "650100",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "乌鲁木齐市",
                                "county": "水磨沟区",

                            },
                            {
                                "cityCode": "650106",
                                "cityName": "头屯河区",
                                "parentId": "650100",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "乌鲁木齐市",
                                "county": "头屯河区",

                            },
                            {
                                "cityCode": "650107",
                                "cityName": "达坂城区",
                                "parentId": "650100",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "乌鲁木齐市",
                                "county": "达坂城区",

                            },
                            {
                                "cityCode": "650109",
                                "cityName": "米东区",
                                "parentId": "650100",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "乌鲁木齐市",
                                "county": "米东区",

                            },
                            {
                                "cityCode": "650121",
                                "cityName": "乌鲁木齐县",
                                "parentId": "650100",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "乌鲁木齐市",
                                "county": "乌鲁木齐县",

                            },
                            {
                                "cityCode": "650171",
                                "cityName": "乌鲁木齐经济技术开发区",
                                "parentId": "650100",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "乌鲁木齐市",
                                "county": "乌鲁木齐经济技术开发区",

                            },
                            {
                                "cityCode": "650172",
                                "cityName": "乌鲁木齐高新技术产业开发区",
                                "parentId": "650100",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "乌鲁木齐市",
                                "county": "乌鲁木齐高新技术产业开发区",

                            }
                        ]
                    },
                    {
                        "cityCode": "650200",
                        "cityName": "克拉玛依市",
                        "parentId": "650000",
                        "provinceType": "2",
                        "province": "新疆维吾尔自治区",
                        "city": "克拉玛依市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "650201",
                                "cityName": "克拉玛依市",
                                "parentId": "650200",
                                "provinceType": "2",
                                "province": "新疆维吾尔自治区",
                                "city": "克拉玛依市",
                                "county": "",

                            },
                            {
                                "cityCode": "650202",
                                "cityName": "独山子区",
                                "parentId": "650200",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "克拉玛依市",
                                "county": "独山子区",

                            },
                            {
                                "cityCode": "650203",
                                "cityName": "克拉玛依区",
                                "parentId": "650200",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "克拉玛依市",
                                "county": "克拉玛依区",

                            },
                            {
                                "cityCode": "650204",
                                "cityName": "白碱滩区",
                                "parentId": "650200",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "克拉玛依市",
                                "county": "白碱滩区",

                            },
                            {
                                "cityCode": "650205",
                                "cityName": "乌尔禾区",
                                "parentId": "650200",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "克拉玛依市",
                                "county": "乌尔禾区",

                            }
                        ]
                    },
                    {
                        "cityCode": "650400",
                        "cityName": "吐鲁番市",
                        "parentId": "650000",
                        "provinceType": "2",
                        "province": "新疆维吾尔自治区",
                        "city": "吐鲁番市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "650302",
                                "cityName": "高昌区",
                                "parentId": "650400",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "吐鲁番市",
                                "county": "高昌区",

                            },
                            {
                                "cityCode": "650402",
                                "cityName": "高昌区",
                                "parentId": "650400",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "吐鲁番市",
                                "county": "高昌区",

                            },
                            {
                                "cityCode": "650421",
                                "cityName": "鄯善县",
                                "parentId": "650400",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "吐鲁番市",
                                "county": "鄯善县",

                            },
                            {
                                "cityCode": "650422",
                                "cityName": "托克逊县",
                                "parentId": "650400",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "吐鲁番市",
                                "county": "托克逊县",

                            },
                            {
                                "cityCode": "652101",
                                "cityName": "吐鲁番市",
                                "parentId": "650400",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "吐鲁番市",
                                "county": "吐鲁番市",

                            }
                        ]
                    },
                    {
                        "cityCode": "650500",
                        "cityName": "哈密市",
                        "parentId": "650000",
                        "provinceType": "2",
                        "province": "新疆维吾尔自治区",
                        "city": "哈密市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "650502",
                                "cityName": "伊州区",
                                "parentId": "650500",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "哈密市",
                                "county": "伊州区",

                            },
                            {
                                "cityCode": "650521",
                                "cityName": "巴里坤哈萨克自治县",
                                "parentId": "650500",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "哈密市",
                                "county": "巴里坤哈萨克自治县",

                            },
                            {
                                "cityCode": "650522",
                                "cityName": "伊吾县",
                                "parentId": "650500",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "哈密市",
                                "county": "伊吾县",

                            }
                        ]
                    },
                    {
                        "cityCode": "652300",
                        "cityName": "昌吉回族自治州",
                        "parentId": "650000",
                        "provinceType": "2",
                        "province": "新疆维吾尔自治区",
                        "city": "昌吉回族自治州",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "652301",
                                "cityName": "昌吉市",
                                "parentId": "652300",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "昌吉回族自治州",
                                "county": "昌吉市",

                            },
                            {
                                "cityCode": "652302",
                                "cityName": "阜康市",
                                "parentId": "652300",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "昌吉回族自治州",
                                "county": "阜康市",

                            },
                            {
                                "cityCode": "652323",
                                "cityName": "呼图壁县",
                                "parentId": "652300",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "昌吉回族自治州",
                                "county": "呼图壁县",

                            },
                            {
                                "cityCode": "652324",
                                "cityName": "玛纳斯县",
                                "parentId": "652300",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "昌吉回族自治州",
                                "county": "玛纳斯县",

                            },
                            {
                                "cityCode": "652325",
                                "cityName": "奇台县",
                                "parentId": "652300",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "昌吉回族自治州",
                                "county": "奇台县",

                            },
                            {
                                "cityCode": "652327",
                                "cityName": "吉木萨尔县",
                                "parentId": "652300",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "昌吉回族自治州",
                                "county": "吉木萨尔县",

                            },
                            {
                                "cityCode": "652328",
                                "cityName": "木垒哈萨克自治县",
                                "parentId": "652300",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "昌吉回族自治州",
                                "county": "木垒哈萨克自治县",

                            }
                        ]
                    },
                    {
                        "cityCode": "652700",
                        "cityName": "博尔塔拉蒙古自治州",
                        "parentId": "650000",
                        "provinceType": "2",
                        "province": "新疆维吾尔自治区",
                        "city": "博尔塔拉蒙古自治州",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "652701",
                                "cityName": "博乐市",
                                "parentId": "652700",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "博尔塔拉蒙古自治州",
                                "county": "博乐市",

                            },
                            {
                                "cityCode": "652702",
                                "cityName": "阿拉山口市",
                                "parentId": "652700",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "博尔塔拉",
                                "county": "阿拉山口市",

                            },
                            {
                                "cityCode": "652722",
                                "cityName": "精河县",
                                "parentId": "652700",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "博尔塔拉蒙古自治州",
                                "county": "精河县",

                            },
                            {
                                "cityCode": "652723",
                                "cityName": "温泉县",
                                "parentId": "652700",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "博尔塔拉蒙古自治州",
                                "county": "温泉县",

                            }
                        ]
                    },
                    {
                        "cityCode": "652800",
                        "cityName": "巴音郭楞蒙古自治州",
                        "parentId": "650000",
                        "provinceType": "2",
                        "province": "新疆维吾尔自治区",
                        "city": "巴音郭楞蒙古自治州",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "652801",
                                "cityName": "库尔勒市",
                                "parentId": "652800",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "巴音郭楞蒙古自治州",
                                "county": "库尔勒市",

                            },
                            {
                                "cityCode": "652822",
                                "cityName": "轮台县",
                                "parentId": "652800",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "巴音郭楞蒙古自治州",
                                "county": "轮台县",

                            },
                            {
                                "cityCode": "652823",
                                "cityName": "尉犁县",
                                "parentId": "652800",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "巴音郭楞蒙古自治州",
                                "county": "尉犁县",

                            },
                            {
                                "cityCode": "652824",
                                "cityName": "若羌县",
                                "parentId": "652800",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "巴音郭楞蒙古自治州",
                                "county": "若羌县",

                            },
                            {
                                "cityCode": "652825",
                                "cityName": "且末县",
                                "parentId": "652800",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "巴音郭楞蒙古自治州",
                                "county": "且末县",

                            },
                            {
                                "cityCode": "652826",
                                "cityName": "焉耆回族自治县",
                                "parentId": "652800",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "巴音郭楞蒙古自治州",
                                "county": "焉耆回族自治县",

                            },
                            {
                                "cityCode": "652827",
                                "cityName": "和静县",
                                "parentId": "652800",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "巴音郭楞蒙古自治州",
                                "county": "和静县",

                            },
                            {
                                "cityCode": "652828",
                                "cityName": "和硕县",
                                "parentId": "652800",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "巴音郭楞蒙古自治州",
                                "county": "和硕县",

                            },
                            {
                                "cityCode": "652829",
                                "cityName": "博湖县",
                                "parentId": "652800",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "巴音郭楞蒙古自治州",
                                "county": "博湖县",

                            },
                            {
                                "cityCode": "652871",
                                "cityName": "库尔勒经济技术开发区",
                                "parentId": "652800",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "巴音郭楞蒙古自治州",
                                "county": "库尔勒经济技术开发区",

                            }
                        ]
                    },
                    {
                        "cityCode": "652900",
                        "cityName": "阿克苏地区",
                        "parentId": "650000",
                        "provinceType": "2",
                        "province": "新疆维吾尔自治区",
                        "city": "阿克苏地区",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "652901",
                                "cityName": "阿克苏市",
                                "parentId": "652900",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "阿克苏地区",
                                "county": "阿克苏市",

                            },
                            {
                                "cityCode": "652902",
                                "cityName": "库车市",
                                "parentId": "652900",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "阿克苏地区",
                                "county": "库车市",

                            },
                            {
                                "cityCode": "652922",
                                "cityName": "温宿县",
                                "parentId": "652900",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "阿克苏地区",
                                "county": "温宿县",

                            },
                            {
                                "cityCode": "652923",
                                "cityName": "库车县",
                                "parentId": "652900",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "阿克苏地区",
                                "county": "库车县",

                            },
                            {
                                "cityCode": "652924",
                                "cityName": "沙雅县",
                                "parentId": "652900",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "阿克苏地区",
                                "county": "沙雅县",

                            },
                            {
                                "cityCode": "652925",
                                "cityName": "新和县",
                                "parentId": "652900",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "阿克苏地区",
                                "county": "新和县",

                            },
                            {
                                "cityCode": "652926",
                                "cityName": "拜城县",
                                "parentId": "652900",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "阿克苏地区",
                                "county": "拜城县",

                            },
                            {
                                "cityCode": "652927",
                                "cityName": "乌什县",
                                "parentId": "652900",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "阿克苏地区",
                                "county": "乌什县",

                            },
                            {
                                "cityCode": "652928",
                                "cityName": "阿瓦提县",
                                "parentId": "652900",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "阿克苏地区",
                                "county": "阿瓦提县",

                            },
                            {
                                "cityCode": "652929",
                                "cityName": "柯坪县",
                                "parentId": "652900",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "阿克苏地区",
                                "county": "柯坪县",

                            }
                        ]
                    },
                    {
                        "cityCode": "653000",
                        "cityName": "克孜勒苏柯尔克孜自治州",
                        "parentId": "650000",
                        "provinceType": "2",
                        "province": "新疆维吾尔自治区",
                        "city": "克孜勒苏柯尔克孜自治州",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "653001",
                                "cityName": "阿图什市",
                                "parentId": "653000",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "克孜勒苏柯尔克孜自治州",
                                "county": "阿图什市",

                            },
                            {
                                "cityCode": "653022",
                                "cityName": "阿克陶县",
                                "parentId": "653000",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "克孜勒苏柯尔克孜自治州",
                                "county": "阿克陶县",

                            },
                            {
                                "cityCode": "653023",
                                "cityName": "阿合奇县",
                                "parentId": "653000",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "克孜勒苏柯尔克孜自治州",
                                "county": "阿合奇县",

                            },
                            {
                                "cityCode": "653024",
                                "cityName": "乌恰县",
                                "parentId": "653000",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "克孜勒苏柯尔克孜自治州",
                                "county": "乌恰县",

                            }
                        ]
                    },
                    {
                        "cityCode": "653100",
                        "cityName": "喀什地区",
                        "parentId": "650000",
                        "provinceType": "2",
                        "province": "新疆维吾尔自治区",
                        "city": "喀什地区",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "653101",
                                "cityName": "喀什市",
                                "parentId": "653100",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "喀什地区",
                                "county": "喀什市",

                            },
                            {
                                "cityCode": "653121",
                                "cityName": "疏附县",
                                "parentId": "653100",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "喀什地区",
                                "county": "疏附县",

                            },
                            {
                                "cityCode": "653122",
                                "cityName": "疏勒县",
                                "parentId": "653100",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "喀什地区",
                                "county": "疏勒县",

                            },
                            {
                                "cityCode": "653123",
                                "cityName": "英吉沙县",
                                "parentId": "653100",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "喀什地区",
                                "county": "英吉沙县",

                            },
                            {
                                "cityCode": "653124",
                                "cityName": "泽普县",
                                "parentId": "653100",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "喀什地区",
                                "county": "泽普县",

                            },
                            {
                                "cityCode": "653125",
                                "cityName": "莎车县",
                                "parentId": "653100",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "喀什地区",
                                "county": "莎车县",

                            },
                            {
                                "cityCode": "653126",
                                "cityName": "叶城县",
                                "parentId": "653100",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "喀什地区",
                                "county": "叶城县",

                            },
                            {
                                "cityCode": "653127",
                                "cityName": "麦盖提县",
                                "parentId": "653100",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "喀什地区",
                                "county": "麦盖提县",

                            },
                            {
                                "cityCode": "653128",
                                "cityName": "岳普湖县",
                                "parentId": "653100",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "喀什地区",
                                "county": "岳普湖县",

                            },
                            {
                                "cityCode": "653129",
                                "cityName": "伽师县",
                                "parentId": "653100",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "喀什地区",
                                "county": "伽师县",

                            },
                            {
                                "cityCode": "653130",
                                "cityName": "巴楚县",
                                "parentId": "653100",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "喀什地区",
                                "county": "巴楚县",

                            },
                            {
                                "cityCode": "653131",
                                "cityName": "塔什库尔干塔吉克自治县",
                                "parentId": "653100",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "喀什地区",
                                "county": "塔什库尔干塔吉克自治县",

                            }
                        ]
                    },
                    {
                        "cityCode": "653200",
                        "cityName": "和田地区",
                        "parentId": "650000",
                        "provinceType": "2",
                        "province": "新疆维吾尔自治区",
                        "city": "和田地区",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "653201",
                                "cityName": "和田市",
                                "parentId": "653200",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "和田地区",
                                "county": "和田市",

                            },
                            {
                                "cityCode": "653221",
                                "cityName": "和田县",
                                "parentId": "653200",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "和田地区",
                                "county": "和田县",

                            },
                            {
                                "cityCode": "653222",
                                "cityName": "墨玉县",
                                "parentId": "653200",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "和田地区",
                                "county": "墨玉县",

                            },
                            {
                                "cityCode": "653223",
                                "cityName": "皮山县",
                                "parentId": "653200",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "和田地区",
                                "county": "皮山县",

                            },
                            {
                                "cityCode": "653224",
                                "cityName": "洛浦县",
                                "parentId": "653200",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "和田地区",
                                "county": "洛浦县",

                            },
                            {
                                "cityCode": "653225",
                                "cityName": "策勒县",
                                "parentId": "653200",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "和田地区",
                                "county": "策勒县",

                            },
                            {
                                "cityCode": "653226",
                                "cityName": "于田县",
                                "parentId": "653200",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "和田地区",
                                "county": "于田县",

                            },
                            {
                                "cityCode": "653227",
                                "cityName": "民丰县",
                                "parentId": "653200",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "和田地区",
                                "county": "民丰县",

                            }
                        ]
                    },
                    {
                        "cityCode": "654000",
                        "cityName": "伊犁哈萨克自治州",
                        "parentId": "650000",
                        "provinceType": "2",
                        "province": "新疆维吾尔自治区",
                        "city": "伊犁哈萨克自治州",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "654002",
                                "cityName": "伊宁市",
                                "parentId": "654000",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "伊犁哈萨克自治州",
                                "county": "伊宁市",

                            },
                            {
                                "cityCode": "654003",
                                "cityName": "奎屯市",
                                "parentId": "654000",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "伊犁哈萨克自治州",
                                "county": "奎屯市",

                            },
                            {
                                "cityCode": "654004",
                                "cityName": "霍尔果斯市",
                                "parentId": "654000",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "伊犁哈萨克自治州",
                                "county": "霍尔果斯市",

                            },
                            {
                                "cityCode": "654021",
                                "cityName": "伊宁县",
                                "parentId": "654000",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "伊犁哈萨克自治州",
                                "county": "伊宁县",

                            },
                            {
                                "cityCode": "654022",
                                "cityName": "察布查尔锡伯自治县",
                                "parentId": "654000",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "伊犁哈萨克自治州",
                                "county": "察布查尔锡伯自治县",

                            },
                            {
                                "cityCode": "654023",
                                "cityName": "霍城县",
                                "parentId": "654000",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "伊犁哈萨克自治州",
                                "county": "霍城县",

                            },
                            {
                                "cityCode": "654024",
                                "cityName": "巩留县",
                                "parentId": "654000",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "伊犁哈萨克自治州",
                                "county": "巩留县",

                            },
                            {
                                "cityCode": "654025",
                                "cityName": "新源县",
                                "parentId": "654000",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "伊犁哈萨克自治州",
                                "county": "新源县",

                            },
                            {
                                "cityCode": "654026",
                                "cityName": "昭苏县",
                                "parentId": "654000",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "伊犁哈萨克自治州",
                                "county": "昭苏县",

                            },
                            {
                                "cityCode": "654027",
                                "cityName": "特克斯县",
                                "parentId": "654000",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "伊犁哈萨克自治州",
                                "county": "特克斯县",

                            },
                            {
                                "cityCode": "654028",
                                "cityName": "尼勒克县",
                                "parentId": "654000",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "伊犁哈萨克自治州",
                                "county": "尼勒克县",

                            }
                        ]
                    },
                    {
                        "cityCode": "654200",
                        "cityName": "塔城地区",
                        "parentId": "650000",
                        "provinceType": "2",
                        "province": "新疆维吾尔自治区",
                        "city": "塔城地区",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "654201",
                                "cityName": "塔城市",
                                "parentId": "654200",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "塔城地区",
                                "county": "塔城市",

                            },
                            {
                                "cityCode": "654202",
                                "cityName": "乌苏市",
                                "parentId": "654200",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "塔城地区",
                                "county": "乌苏市",

                            },
                            {
                                "cityCode": "654221",
                                "cityName": "额敏县",
                                "parentId": "654200",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "塔城地区",
                                "county": "额敏县",

                            },
                            {
                                "cityCode": "654223",
                                "cityName": "沙湾县",
                                "parentId": "654200",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "塔城地区",
                                "county": "沙湾县",

                            },
                            {
                                "cityCode": "654224",
                                "cityName": "托里县",
                                "parentId": "654200",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "塔城地区",
                                "county": "托里县",

                            },
                            {
                                "cityCode": "654225",
                                "cityName": "裕民县",
                                "parentId": "654200",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "塔城地区",
                                "county": "裕民县",

                            },
                            {
                                "cityCode": "654226",
                                "cityName": "和布克赛尔蒙古自治县",
                                "parentId": "654200",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "塔城地区",
                                "county": "和布克赛尔蒙古自治县",

                            }
                        ]
                    },
                    {
                        "cityCode": "654300",
                        "cityName": "阿勒泰地区",
                        "parentId": "650000",
                        "provinceType": "2",
                        "province": "新疆维吾尔自治区",
                        "city": "阿勒泰地区",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "654301",
                                "cityName": "阿勒泰市",
                                "parentId": "654300",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "阿勒泰地区",
                                "county": "阿勒泰市",

                            },
                            {
                                "cityCode": "654321",
                                "cityName": "布尔津县",
                                "parentId": "654300",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "阿勒泰地区",
                                "county": "布尔津县",

                            },
                            {
                                "cityCode": "654322",
                                "cityName": "富蕴县",
                                "parentId": "654300",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "阿勒泰地区",
                                "county": "富蕴县",

                            },
                            {
                                "cityCode": "654323",
                                "cityName": "福海县",
                                "parentId": "654300",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "阿勒泰地区",
                                "county": "福海县",

                            },
                            {
                                "cityCode": "654324",
                                "cityName": "哈巴河县",
                                "parentId": "654300",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "阿勒泰地区",
                                "county": "哈巴河县",

                            },
                            {
                                "cityCode": "654325",
                                "cityName": "青河县",
                                "parentId": "654300",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "阿勒泰地区",
                                "county": "青河县",

                            },
                            {
                                "cityCode": "654326",
                                "cityName": "吉木乃县",
                                "parentId": "654300",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "阿勒泰地区",
                                "county": "吉木乃县",

                            },
                            {
                                "cityCode": "654327",
                                "cityName": "北屯镇",
                                "parentId": "654300",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "阿勒泰地区",
                                "county": "北屯镇",

                            },
                            {
                                "cityCode": "659010",
                                "cityName": "胡杨河市",
                                "parentId": "654300",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "阿勒泰地区",
                                "county": "胡杨河市",

                            }
                        ]
                    },
                    {
                        "cityCode": "659000",
                        "cityName": "自治区直辖县级行政区划",
                        "parentId": "650000",
                        "provinceType": "2",
                        "province": "新疆维吾尔自治区",
                        "city": "自治区直辖县级行政区划",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "652329",
                                "cityName": "五家渠市",
                                "parentId": "659000",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "五家渠市",
                                "county": "五家渠市",

                            },
                            {
                                "cityCode": "659001",
                                "cityName": "石河子市",
                                "parentId": "659000",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "石河子市",
                                "county": "石河子市",
                                "counties": [
                                    {
                                        "cityCode": "659101",
                                        "cityName": "新城街道",
                                        "parentId": "659001",
                                        "provinceType": "3",
                                        "province": "新疆维吾尔自治区",
                                        "city": "石河子市",
                                        "county": "新城街道",

                                    },
                                    {
                                        "cityCode": "659102",
                                        "cityName": "向阳街道",
                                        "parentId": "659001",
                                        "provinceType": "3",
                                        "province": "新疆维吾尔自治区",
                                        "city": "石河子市",
                                        "county": "向阳街道",

                                    },
                                    {
                                        "cityCode": "659103",
                                        "cityName": "红山街道",
                                        "parentId": "659001",
                                        "provinceType": "3",
                                        "province": "新疆维吾尔自治区",
                                        "city": "石河子市",
                                        "county": "红山街道",

                                    },
                                    {
                                        "cityCode": "659104",
                                        "cityName": "老街街道",
                                        "parentId": "659001",
                                        "provinceType": "3",
                                        "province": "新疆维吾尔自治区",
                                        "city": "石河子市",
                                        "county": "老街街道",

                                    },
                                    {
                                        "cityCode": "659105",
                                        "cityName": "东城街道",
                                        "parentId": "659001",
                                        "provinceType": "3",
                                        "province": "新疆维吾尔自治区",
                                        "city": "石河子市",
                                        "county": "东城街道",

                                    },
                                    {
                                        "cityCode": "659106",
                                        "cityName": "北泉镇",
                                        "parentId": "659001",
                                        "provinceType": "3",
                                        "province": "新疆维吾尔自治区",
                                        "city": "石河子市",
                                        "county": "北泉镇",

                                    },
                                    {
                                        "cityCode": "659107",
                                        "cityName": "石河子乡",
                                        "parentId": "659001",
                                        "provinceType": "3",
                                        "province": "新疆维吾尔自治区",
                                        "city": "石河子市",
                                        "county": "石河子乡",

                                    },
                                    {
                                        "cityCode": "659108",
                                        "cityName": "一五二团",
                                        "parentId": "659001",
                                        "provinceType": "3",
                                        "province": "新疆维吾尔自治区",
                                        "city": "石河子市",
                                        "county": "一五二团",

                                    }
                                ]
                            },
                            {
                                "cityCode": "659002",
                                "cityName": "阿拉尔市",
                                "parentId": "659000",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "阿拉尔市",
                                "county": "阿拉尔市",
                                "counties": [
                                    {
                                        "cityCode": "659201",
                                        "cityName": "幸福路街道",
                                        "parentId": "659002",
                                        "provinceType": "3",
                                        "province": "新疆维吾尔自治区",
                                        "city": "阿拉尔市",
                                        "county": "幸福路街道",

                                    },
                                    {
                                        "cityCode": "659202",
                                        "cityName": "金银川路街道",
                                        "parentId": "659002",
                                        "provinceType": "3",
                                        "province": "新疆维吾尔自治区",
                                        "city": "阿拉尔市",
                                        "county": "金银川路街道",

                                    },
                                    {
                                        "cityCode": "659203",
                                        "cityName": "青松路街道",
                                        "parentId": "659002",
                                        "provinceType": "3",
                                        "province": "新疆维吾尔自治区",
                                        "city": "阿拉尔市",
                                        "county": "青松路街道",

                                    },
                                    {
                                        "cityCode": "659204",
                                        "cityName": "南口街道",
                                        "parentId": "659002",
                                        "provinceType": "3",
                                        "province": "新疆维吾尔自治区",
                                        "city": "阿拉尔市",
                                        "county": "南口街道",

                                    },
                                    {
                                        "cityCode": "659205",
                                        "cityName": "托喀依乡",
                                        "parentId": "659002",
                                        "provinceType": "3",
                                        "province": "新疆维吾尔自治区",
                                        "city": "阿拉尔市",
                                        "county": "托喀依乡",

                                    },
                                    {
                                        "cityCode": "659206",
                                        "cityName": "金银川镇",
                                        "parentId": "659002",
                                        "provinceType": "3",
                                        "province": "新疆维吾尔自治区",
                                        "city": "阿拉尔市",
                                        "county": "金银川镇",

                                    }
                                ]
                            },
                            {
                                "cityCode": "659003",
                                "cityName": "图木舒克市",
                                "parentId": "659000",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "图木舒克市",
                                "county": "图木舒克市",
                                "counties": [
                                    {
                                        "cityCode": "659300",
                                        "cityName": "图木舒克市区",
                                        "parentId": "659003",
                                        "provinceType": "3",
                                        "province": "新疆维吾尔自治区",
                                        "city": "图木舒克市",
                                        "county": "图木舒克市区",

                                    },
                                    {
                                        "cityCode": "659301",
                                        "cityName": "图木舒克市区",
                                        "parentId": "659003",
                                        "provinceType": "3",
                                        "province": "新疆维吾尔自治区",
                                        "city": "图木舒克市",
                                        "county": "图木舒克市区",

                                    },
                                    {
                                        "cityCode": "659302",
                                        "cityName": "兵团四十四团",
                                        "parentId": "659003",
                                        "provinceType": "3",
                                        "province": "新疆维吾尔自治区",
                                        "city": "图木舒克市",
                                        "county": "兵团四十四团",

                                    },
                                    {
                                        "cityCode": "659303",
                                        "cityName": "兵团四十九团",
                                        "parentId": "659003",
                                        "provinceType": "3",
                                        "province": "新疆维吾尔自治区",
                                        "city": "图木舒克市",
                                        "county": "兵团四十九团",

                                    },
                                    {
                                        "cityCode": "659304",
                                        "cityName": "兵团五十团",
                                        "parentId": "659003",
                                        "provinceType": "3",
                                        "province": "新疆维吾尔自治区",
                                        "city": "图木舒克市",
                                        "county": "兵团五十团",

                                    },
                                    {
                                        "cityCode": "659305",
                                        "cityName": "兵团五十一团",
                                        "parentId": "659003",
                                        "provinceType": "3",
                                        "province": "新疆维吾尔自治区",
                                        "city": "图木舒克市",
                                        "county": "兵团五十一团",

                                    },
                                    {
                                        "cityCode": "659306",
                                        "cityName": "兵团五十二团",
                                        "parentId": "659003",
                                        "provinceType": "3",
                                        "province": "新疆维吾尔自治区",
                                        "city": "图木舒克市",
                                        "county": "兵团五十二团",

                                    },
                                    {
                                        "cityCode": "659307",
                                        "cityName": "兵团五十三团",
                                        "parentId": "659003",
                                        "provinceType": "3",
                                        "province": "新疆维吾尔自治区",
                                        "city": "图木舒克市",
                                        "county": "兵团五十三团",

                                    },
                                    {
                                        "cityCode": "659308",
                                        "cityName": "喀拉拜勒镇",
                                        "parentId": "659003",
                                        "provinceType": "3",
                                        "province": "新疆维吾尔自治区",
                                        "city": "图木舒克市",
                                        "county": "喀拉拜勒镇",

                                    },
                                    {
                                        "cityCode": "659309",
                                        "cityName": "永安坝",
                                        "parentId": "659003",
                                        "provinceType": "3",
                                        "province": "新疆维吾尔自治区",
                                        "city": "图木舒克市",
                                        "county": "永安坝",

                                    }
                                ]
                            },
                            {
                                "cityCode": "659004",
                                "cityName": "五家渠市",
                                "parentId": "659000",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "五家渠市",
                                "county": "五家渠市",
                                "counties": [
                                    {
                                        "cityCode": "659401",
                                        "cityName": "城区",
                                        "parentId": "659004",
                                        "provinceType": "3",
                                        "province": "新疆维吾尔自治区",
                                        "city": "五家渠市",
                                        "county": "城区",

                                    },
                                    {
                                        "cityCode": "659402",
                                        "cityName": "一零一团",
                                        "parentId": "659004",
                                        "provinceType": "3",
                                        "province": "新疆维吾尔自治区",
                                        "city": "五家渠市",
                                        "county": "一零一团",

                                    },
                                    {
                                        "cityCode": "659403",
                                        "cityName": "一零二团",
                                        "parentId": "659004",
                                        "provinceType": "3",
                                        "province": "新疆维吾尔自治区",
                                        "city": "五家渠市",
                                        "county": "一零二团",

                                    },
                                    {
                                        "cityCode": "659404",
                                        "cityName": "一零三团",
                                        "parentId": "659004",
                                        "provinceType": "3",
                                        "province": "新疆维吾尔自治区",
                                        "city": "五家渠市",
                                        "county": "一零三团",

                                    }
                                ]
                            },
                            {
                                "cityCode": "659005",
                                "cityName": "北屯市",
                                "parentId": "659000",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "北屯市",
                                "county": "北屯市",
                                "counties": [
                                    {
                                        "cityCode": "659501",
                                        "cityName": "新城区",
                                        "parentId": "659005",
                                        "provinceType": "3",
                                        "province": "新疆维吾尔自治区",
                                        "city": "北屯市",
                                        "county": "新城区",

                                    },
                                    {
                                        "cityCode": "659502",
                                        "cityName": "老城区",
                                        "parentId": "659005",
                                        "provinceType": "3",
                                        "province": "新疆维吾尔自治区",
                                        "city": "北屯市",
                                        "county": "老城区",

                                    },
                                    {
                                        "cityCode": "659503",
                                        "cityName": "工业园区",
                                        "parentId": "659005",
                                        "provinceType": "3",
                                        "province": "新疆维吾尔自治区",
                                        "city": "北屯市",
                                        "county": "工业园区",

                                    },
                                    {
                                        "cityCode": "659504",
                                        "cityName": "海川镇",
                                        "parentId": "659005",
                                        "provinceType": "3",
                                        "province": "新疆维吾尔自治区",
                                        "city": "北屯市",
                                        "county": "海川镇",

                                    },
                                    {
                                        "cityCode": "659505",
                                        "cityName": "丰庆镇",
                                        "parentId": "659005",
                                        "provinceType": "3",
                                        "province": "新疆维吾尔自治区",
                                        "city": "北屯市",
                                        "county": "丰庆镇",

                                    },
                                    {
                                        "cityCode": "659506",
                                        "cityName": "锡伯渡镇",
                                        "parentId": "659005",
                                        "provinceType": "3",
                                        "province": "新疆维吾尔自治区",
                                        "city": "北屯市",
                                        "county": "锡伯渡镇",

                                    }
                                ]
                            },
                            {
                                "cityCode": "659006",
                                "cityName": "铁门关市",
                                "parentId": "659000",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "铁门关市",
                                "county": "铁门关市",
                                "counties": [
                                    {
                                        "cityCode": "659601",
                                        "cityName": "二十九团场",
                                        "parentId": "659006",
                                        "provinceType": "3",
                                        "province": "新疆维吾尔自治区",
                                        "city": "铁门关市",
                                        "county": "二十九团场",

                                    },
                                    {
                                        "cityCode": "659602",
                                        "cityName": "库西经济工业园",
                                        "parentId": "659006",
                                        "provinceType": "3",
                                        "province": "新疆维吾尔自治区",
                                        "city": "铁门关市",
                                        "county": "库西经济工业园",

                                    },
                                    {
                                        "cityCode": "659603",
                                        "cityName": "博古其镇",
                                        "parentId": "659006",
                                        "provinceType": "3",
                                        "province": "新疆维吾尔自治区",
                                        "city": "铁门关市",
                                        "county": "博古其镇",

                                    },
                                    {
                                        "cityCode": "659604",
                                        "cityName": "双丰镇",
                                        "parentId": "659006",
                                        "provinceType": "3",
                                        "province": "新疆维吾尔自治区",
                                        "city": "铁门关市",
                                        "county": "双丰镇",

                                    }
                                ]
                            },
                            {
                                "cityCode": "659007",
                                "cityName": "双河市",
                                "parentId": "659000",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "双河市",
                                "county": "双河市",
                                "counties": [
                                    {
                                        "cityCode": "659701",
                                        "cityName": "八十一团",
                                        "parentId": "659007",
                                        "provinceType": "3",
                                        "province": "新疆维吾尔自治区",
                                        "city": "双河市",
                                        "county": "八十一团",

                                    },
                                    {
                                        "cityCode": "659702",
                                        "cityName": "八十四团",
                                        "parentId": "659007",
                                        "provinceType": "3",
                                        "province": "新疆维吾尔自治区",
                                        "city": "双河市",
                                        "county": "八十四团",

                                    },
                                    {
                                        "cityCode": "659703",
                                        "cityName": "八十五团",
                                        "parentId": "659007",
                                        "provinceType": "3",
                                        "province": "新疆维吾尔自治区",
                                        "city": "双河市",
                                        "county": "八十五团",

                                    },
                                    {
                                        "cityCode": "659704",
                                        "cityName": "八十六团",
                                        "parentId": "659007",
                                        "provinceType": "3",
                                        "province": "新疆维吾尔自治区",
                                        "city": "双河市",
                                        "county": "八十六团",

                                    },
                                    {
                                        "cityCode": "659705",
                                        "cityName": "八十九团",
                                        "parentId": "659007",
                                        "provinceType": "3",
                                        "province": "新疆维吾尔自治区",
                                        "city": "双河市",
                                        "county": "八十九团",

                                    },
                                    {
                                        "cityCode": "659706",
                                        "cityName": "九十团",
                                        "parentId": "659007",
                                        "provinceType": "3",
                                        "province": "新疆维吾尔自治区",
                                        "city": "双河市",
                                        "county": "九十团",

                                    }
                                ]
                            },
                            {
                                "cityCode": "659008",
                                "cityName": "可克达拉市",
                                "parentId": "659000",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "可克达拉市",
                                "county": "可克达拉市",
                                "counties": [
                                    {
                                        "cityCode": "659801",
                                        "cityName": "63团",
                                        "parentId": "659008",
                                        "provinceType": "3",
                                        "province": "新疆维吾尔自治区",
                                        "city": "可克达拉市",
                                        "county": "63团",

                                    },
                                    {
                                        "cityCode": "659802",
                                        "cityName": "64团",
                                        "parentId": "659008",
                                        "provinceType": "3",
                                        "province": "新疆维吾尔自治区",
                                        "city": "可克达拉市",
                                        "county": "64团",

                                    },
                                    {
                                        "cityCode": "659803",
                                        "cityName": "66团",
                                        "parentId": "659008",
                                        "provinceType": "3",
                                        "province": "新疆维吾尔自治区",
                                        "city": "可克达拉市",
                                        "county": "66团",

                                    },
                                    {
                                        "cityCode": "659804",
                                        "cityName": "67团",
                                        "parentId": "659008",
                                        "provinceType": "3",
                                        "province": "新疆维吾尔自治区",
                                        "city": "可克达拉市",
                                        "county": "67团",

                                    },
                                    {
                                        "cityCode": "659805",
                                        "cityName": "68团",
                                        "parentId": "659008",
                                        "provinceType": "3",
                                        "province": "新疆维吾尔自治区",
                                        "city": "可克达拉市",
                                        "county": "68团",

                                    }
                                ]
                            },
                            {
                                "cityCode": "659009",
                                "cityName": "昆玉市",
                                "parentId": "659000",
                                "provinceType": "3",
                                "province": "新疆维吾尔自治区",
                                "city": "昆玉市",
                                "county": "昆玉市",
                                "counties": [
                                    {
                                        "cityCode": "659901",
                                        "cityName": "皮山农场",
                                        "parentId": "659009",
                                        "provinceType": "3",
                                        "province": "新疆维吾尔自治区",
                                        "city": "昆玉市",
                                        "county": "皮山农场",

                                    },
                                    {
                                        "cityCode": "659902",
                                        "cityName": "二二四团",
                                        "parentId": "659009",
                                        "provinceType": "3",
                                        "province": "新疆维吾尔自治区",
                                        "city": "昆玉市",
                                        "county": "二二四团",

                                    },
                                    {
                                        "cityCode": "659903",
                                        "cityName": "四十七团",
                                        "parentId": "659009",
                                        "provinceType": "3",
                                        "province": "新疆维吾尔自治区",
                                        "city": "昆玉市",
                                        "county": "四十七团",

                                    },
                                    {
                                        "cityCode": "659904",
                                        "cityName": "一牧场",
                                        "parentId": "659009",
                                        "provinceType": "3",
                                        "province": "新疆维吾尔自治区",
                                        "city": "昆玉市",
                                        "county": "一牧场",

                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                "cityCode": "710000",
                "cityName": "台湾",
                "parentId": "100000",
                "provinceType": "-1",
                "province": "",
                "city": "",
                "county": "",
                "counties": [
                    {
                        "cityCode": "710100",
                        "cityName": "台北市",
                        "parentId": "710000",
                        "provinceType": "2",
                        "province": "台湾",
                        "city": "台北市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "710101",
                                "cityName": "松山区",
                                "parentId": "710100",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台北市",
                                "county": "松山区",

                            },
                            {
                                "cityCode": "710102",
                                "cityName": "信义区",
                                "parentId": "710100",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台北市",
                                "county": "信义区",

                            },
                            {
                                "cityCode": "710103",
                                "cityName": "大安区",
                                "parentId": "710100",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台北市",
                                "county": "大安区",

                            },
                            {
                                "cityCode": "710104",
                                "cityName": "中山区",
                                "parentId": "710100",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台北市",
                                "county": "中山区",

                            },
                            {
                                "cityCode": "710105",
                                "cityName": "中正区",
                                "parentId": "710100",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台北市",
                                "county": "中正区",

                            },
                            {
                                "cityCode": "710106",
                                "cityName": "大同区",
                                "parentId": "710100",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台北市",
                                "county": "大同区",

                            },
                            {
                                "cityCode": "710107",
                                "cityName": "万华区",
                                "parentId": "710100",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台北市",
                                "county": "万华区",

                            },
                            {
                                "cityCode": "710108",
                                "cityName": "文山区",
                                "parentId": "710100",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台北市",
                                "county": "文山区",

                            },
                            {
                                "cityCode": "710109",
                                "cityName": "南港区",
                                "parentId": "710100",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台北市",
                                "county": "南港区",

                            },
                            {
                                "cityCode": "710110",
                                "cityName": "内湖区",
                                "parentId": "710100",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台北市",
                                "county": "内湖区",

                            },
                            {
                                "cityCode": "710111",
                                "cityName": "士林区",
                                "parentId": "710100",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台北市",
                                "county": "士林区",

                            },
                            {
                                "cityCode": "710112",
                                "cityName": "北投区",
                                "parentId": "710100",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台北市",
                                "county": "北投区",

                            }
                        ]
                    },
                    {
                        "cityCode": "710200",
                        "cityName": "高雄市",
                        "parentId": "710000",
                        "provinceType": "2",
                        "province": "台湾",
                        "city": "高雄市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "710201",
                                "cityName": "盐埕区",
                                "parentId": "710200",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "高雄市",
                                "county": "盐埕区",

                            },
                            {
                                "cityCode": "710202",
                                "cityName": "鼓山区",
                                "parentId": "710200",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "高雄市",
                                "county": "鼓山区",

                            },
                            {
                                "cityCode": "710203",
                                "cityName": "左营区",
                                "parentId": "710200",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "高雄市",
                                "county": "左营区",

                            },
                            {
                                "cityCode": "710204",
                                "cityName": "楠梓区",
                                "parentId": "710200",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "高雄市",
                                "county": "楠梓区",

                            },
                            {
                                "cityCode": "710205",
                                "cityName": "三民区",
                                "parentId": "710200",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "高雄市",
                                "county": "三民区",

                            },
                            {
                                "cityCode": "710206",
                                "cityName": "新兴区",
                                "parentId": "710200",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "高雄市",
                                "county": "新兴区",

                            },
                            {
                                "cityCode": "710207",
                                "cityName": "前金区",
                                "parentId": "710200",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "高雄市",
                                "county": "前金区",

                            },
                            {
                                "cityCode": "710208",
                                "cityName": "苓雅区",
                                "parentId": "710200",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "高雄市",
                                "county": "苓雅区",

                            },
                            {
                                "cityCode": "710209",
                                "cityName": "前镇区",
                                "parentId": "710200",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "高雄市",
                                "county": "前镇区",

                            },
                            {
                                "cityCode": "710210",
                                "cityName": "旗津区",
                                "parentId": "710200",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "高雄市",
                                "county": "旗津区",

                            },
                            {
                                "cityCode": "710211",
                                "cityName": "小港区",
                                "parentId": "710200",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "高雄市",
                                "county": "小港区",

                            },
                            {
                                "cityCode": "710212",
                                "cityName": "凤山区",
                                "parentId": "710200",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "高雄市",
                                "county": "凤山区",

                            },
                            {
                                "cityCode": "710213",
                                "cityName": "林园区",
                                "parentId": "710200",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "高雄市",
                                "county": "林园区",

                            },
                            {
                                "cityCode": "710214",
                                "cityName": "大寮区",
                                "parentId": "710200",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "高雄市",
                                "county": "大寮区",

                            },
                            {
                                "cityCode": "710215",
                                "cityName": "大树区",
                                "parentId": "710200",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "高雄市",
                                "county": "大树区",

                            },
                            {
                                "cityCode": "710216",
                                "cityName": "大社区",
                                "parentId": "710200",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "高雄市",
                                "county": "大社区",

                            },
                            {
                                "cityCode": "710217",
                                "cityName": "仁武区",
                                "parentId": "710200",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "高雄市",
                                "county": "仁武区",

                            },
                            {
                                "cityCode": "710218",
                                "cityName": "鸟松区",
                                "parentId": "710200",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "高雄市",
                                "county": "鸟松区",

                            },
                            {
                                "cityCode": "710219",
                                "cityName": "冈山区",
                                "parentId": "710200",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "高雄市",
                                "county": "冈山区",

                            },
                            {
                                "cityCode": "710220",
                                "cityName": "桥头区",
                                "parentId": "710200",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "高雄市",
                                "county": "桥头区",

                            },
                            {
                                "cityCode": "710221",
                                "cityName": "燕巢区",
                                "parentId": "710200",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "高雄市",
                                "county": "燕巢区",

                            },
                            {
                                "cityCode": "710222",
                                "cityName": "田寮区",
                                "parentId": "710200",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "高雄市",
                                "county": "田寮区",

                            },
                            {
                                "cityCode": "710223",
                                "cityName": "阿莲区",
                                "parentId": "710200",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "高雄市",
                                "county": "阿莲区",

                            },
                            {
                                "cityCode": "710224",
                                "cityName": "路竹区",
                                "parentId": "710200",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "高雄市",
                                "county": "路竹区",

                            },
                            {
                                "cityCode": "710225",
                                "cityName": "湖内区",
                                "parentId": "710200",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "高雄市",
                                "county": "湖内区",

                            },
                            {
                                "cityCode": "710226",
                                "cityName": "茄萣区",
                                "parentId": "710200",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "高雄市",
                                "county": "茄萣区",

                            },
                            {
                                "cityCode": "710227",
                                "cityName": "永安区",
                                "parentId": "710200",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "高雄市",
                                "county": "永安区",

                            },
                            {
                                "cityCode": "710228",
                                "cityName": "弥陀区",
                                "parentId": "710200",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "高雄市",
                                "county": "弥陀区",

                            },
                            {
                                "cityCode": "710229",
                                "cityName": "梓官区",
                                "parentId": "710200",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "高雄市",
                                "county": "梓官区",

                            },
                            {
                                "cityCode": "710230",
                                "cityName": "旗山区",
                                "parentId": "710200",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "高雄市",
                                "county": "旗山区",

                            },
                            {
                                "cityCode": "710231",
                                "cityName": "美浓区",
                                "parentId": "710200",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "高雄市",
                                "county": "美浓区",

                            },
                            {
                                "cityCode": "710232",
                                "cityName": "六龟区",
                                "parentId": "710200",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "高雄市",
                                "county": "六龟区",

                            },
                            {
                                "cityCode": "710233",
                                "cityName": "甲仙区",
                                "parentId": "710200",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "高雄市",
                                "county": "甲仙区",

                            },
                            {
                                "cityCode": "710234",
                                "cityName": "杉林区",
                                "parentId": "710200",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "高雄市",
                                "county": "杉林区",

                            },
                            {
                                "cityCode": "710235",
                                "cityName": "内门区",
                                "parentId": "710200",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "高雄市",
                                "county": "内门区",

                            },
                            {
                                "cityCode": "710236",
                                "cityName": "茂林区",
                                "parentId": "710200",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "高雄市",
                                "county": "茂林区",

                            },
                            {
                                "cityCode": "710237",
                                "cityName": "桃源区",
                                "parentId": "710200",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "高雄市",
                                "county": "桃源区",

                            },
                            {
                                "cityCode": "710238",
                                "cityName": "那玛夏区",
                                "parentId": "710200",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "高雄市",
                                "county": "那玛夏区",

                            }
                        ]
                    },
                    {
                        "cityCode": "710300",
                        "cityName": "基隆市",
                        "parentId": "710000",
                        "provinceType": "2",
                        "province": "台湾",
                        "city": "基隆市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "710301",
                                "cityName": "中正区",
                                "parentId": "710300",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "基隆市",
                                "county": "中正区",

                            },
                            {
                                "cityCode": "710302",
                                "cityName": "七堵区",
                                "parentId": "710300",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "基隆市",
                                "county": "七堵区",

                            },
                            {
                                "cityCode": "710303",
                                "cityName": "暖暖区",
                                "parentId": "710300",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "基隆市",
                                "county": "暖暖区",

                            },
                            {
                                "cityCode": "710304",
                                "cityName": "仁爱区",
                                "parentId": "710300",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "基隆市",
                                "county": "仁爱区",

                            },
                            {
                                "cityCode": "710305",
                                "cityName": "中山区",
                                "parentId": "710300",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "基隆市",
                                "county": "中山区",

                            },
                            {
                                "cityCode": "710306",
                                "cityName": "安乐区",
                                "parentId": "710300",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "基隆市",
                                "county": "安乐区",

                            },
                            {
                                "cityCode": "710307",
                                "cityName": "信义区",
                                "parentId": "710300",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "基隆市",
                                "county": "信义区",

                            }
                        ]
                    },
                    {
                        "cityCode": "710400",
                        "cityName": "台中市",
                        "parentId": "710000",
                        "provinceType": "2",
                        "province": "台湾",
                        "city": "台中市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "710401",
                                "cityName": "中区",
                                "parentId": "710400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台中市",
                                "county": "中区",

                            },
                            {
                                "cityCode": "710402",
                                "cityName": "东区",
                                "parentId": "710400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台中市",
                                "county": "东区",

                            },
                            {
                                "cityCode": "710403",
                                "cityName": "南区",
                                "parentId": "710400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台中市",
                                "county": "南区",

                            },
                            {
                                "cityCode": "710404",
                                "cityName": "西区",
                                "parentId": "710400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台中市",
                                "county": "西区",

                            },
                            {
                                "cityCode": "710405",
                                "cityName": "北区",
                                "parentId": "710400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台中市",
                                "county": "北区",

                            },
                            {
                                "cityCode": "710406",
                                "cityName": "西屯区",
                                "parentId": "710400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台中市",
                                "county": "西屯区",

                            },
                            {
                                "cityCode": "710407",
                                "cityName": "南屯区",
                                "parentId": "710400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台中市",
                                "county": "南屯区",

                            },
                            {
                                "cityCode": "710408",
                                "cityName": "北屯区",
                                "parentId": "710400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台中市",
                                "county": "北屯区",

                            },
                            {
                                "cityCode": "710409",
                                "cityName": "丰原区",
                                "parentId": "710400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台中市",
                                "county": "丰原区",

                            },
                            {
                                "cityCode": "710410",
                                "cityName": "东势区",
                                "parentId": "710400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台中市",
                                "county": "东势区",

                            },
                            {
                                "cityCode": "710411",
                                "cityName": "大甲区",
                                "parentId": "710400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台中市",
                                "county": "大甲区",

                            },
                            {
                                "cityCode": "710412",
                                "cityName": "清水区",
                                "parentId": "710400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台中市",
                                "county": "清水区",

                            },
                            {
                                "cityCode": "710413",
                                "cityName": "沙鹿区",
                                "parentId": "710400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台中市",
                                "county": "沙鹿区",

                            },
                            {
                                "cityCode": "710414",
                                "cityName": "梧栖区",
                                "parentId": "710400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台中市",
                                "county": "梧栖区",

                            },
                            {
                                "cityCode": "710415",
                                "cityName": "后里区",
                                "parentId": "710400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台中市",
                                "county": "后里区",

                            },
                            {
                                "cityCode": "710416",
                                "cityName": "神冈区",
                                "parentId": "710400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台中市",
                                "county": "神冈区",

                            },
                            {
                                "cityCode": "710417",
                                "cityName": "潭子区",
                                "parentId": "710400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台中市",
                                "county": "潭子区",

                            },
                            {
                                "cityCode": "710418",
                                "cityName": "大雅区",
                                "parentId": "710400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台中市",
                                "county": "大雅区",

                            },
                            {
                                "cityCode": "710419",
                                "cityName": "新社区",
                                "parentId": "710400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台中市",
                                "county": "新社区",

                            },
                            {
                                "cityCode": "710420",
                                "cityName": "石冈区",
                                "parentId": "710400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台中市",
                                "county": "石冈区",

                            },
                            {
                                "cityCode": "710421",
                                "cityName": "外埔区",
                                "parentId": "710400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台中市",
                                "county": "外埔区",

                            },
                            {
                                "cityCode": "710422",
                                "cityName": "大安区",
                                "parentId": "710400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台中市",
                                "county": "大安区",

                            },
                            {
                                "cityCode": "710423",
                                "cityName": "乌日区",
                                "parentId": "710400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台中市",
                                "county": "乌日区",

                            },
                            {
                                "cityCode": "710424",
                                "cityName": "大肚区",
                                "parentId": "710400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台中市",
                                "county": "大肚区",

                            },
                            {
                                "cityCode": "710425",
                                "cityName": "龙井区",
                                "parentId": "710400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台中市",
                                "county": "龙井区",

                            },
                            {
                                "cityCode": "710426",
                                "cityName": "雾峰区",
                                "parentId": "710400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台中市",
                                "county": "雾峰区",

                            },
                            {
                                "cityCode": "710427",
                                "cityName": "太平区",
                                "parentId": "710400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台中市",
                                "county": "太平区",

                            },
                            {
                                "cityCode": "710428",
                                "cityName": "大里区",
                                "parentId": "710400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台中市",
                                "county": "大里区",

                            },
                            {
                                "cityCode": "710429",
                                "cityName": "和平区",
                                "parentId": "710400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台中市",
                                "county": "和平区",

                            }
                        ]
                    },
                    {
                        "cityCode": "710500",
                        "cityName": "台南市",
                        "parentId": "710000",
                        "provinceType": "2",
                        "province": "台湾",
                        "city": "台南市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "710501",
                                "cityName": "东区",
                                "parentId": "710500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台南市",
                                "county": "东区",

                            },
                            {
                                "cityCode": "710502",
                                "cityName": "南区",
                                "parentId": "710500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台南市",
                                "county": "南区",

                            },
                            {
                                "cityCode": "710504",
                                "cityName": "北区",
                                "parentId": "710500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台南市",
                                "county": "北区",

                            },
                            {
                                "cityCode": "710506",
                                "cityName": "安南区",
                                "parentId": "710500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台南市",
                                "county": "安南区",

                            },
                            {
                                "cityCode": "710507",
                                "cityName": "安平区",
                                "parentId": "710500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台南市",
                                "county": "安平区",

                            },
                            {
                                "cityCode": "710508",
                                "cityName": "中西区",
                                "parentId": "710500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台南市",
                                "county": "中西区",

                            },
                            {
                                "cityCode": "710509",
                                "cityName": "新营区",
                                "parentId": "710500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台南市",
                                "county": "新营区",

                            },
                            {
                                "cityCode": "710510",
                                "cityName": "盐水区",
                                "parentId": "710500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台南市",
                                "county": "盐水区",

                            },
                            {
                                "cityCode": "710511",
                                "cityName": "白河区",
                                "parentId": "710500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台南市",
                                "county": "白河区",

                            },
                            {
                                "cityCode": "710512",
                                "cityName": "柳营区",
                                "parentId": "710500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台南市",
                                "county": "柳营区",

                            },
                            {
                                "cityCode": "710513",
                                "cityName": "后壁区",
                                "parentId": "710500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台南市",
                                "county": "后壁区",

                            },
                            {
                                "cityCode": "710514",
                                "cityName": "东山区",
                                "parentId": "710500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台南市",
                                "county": "东山区",

                            },
                            {
                                "cityCode": "710515",
                                "cityName": "麻豆区",
                                "parentId": "710500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台南市",
                                "county": "麻豆区",

                            },
                            {
                                "cityCode": "710516",
                                "cityName": "下营区",
                                "parentId": "710500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台南市",
                                "county": "下营区",

                            },
                            {
                                "cityCode": "710517",
                                "cityName": "六甲区",
                                "parentId": "710500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台南市",
                                "county": "六甲区",

                            },
                            {
                                "cityCode": "710518",
                                "cityName": "官田区",
                                "parentId": "710500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台南市",
                                "county": "官田区",

                            },
                            {
                                "cityCode": "710519",
                                "cityName": "大内区",
                                "parentId": "710500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台南市",
                                "county": "大内区",

                            },
                            {
                                "cityCode": "710520",
                                "cityName": "佳里区",
                                "parentId": "710500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台南市",
                                "county": "佳里区",

                            },
                            {
                                "cityCode": "710521",
                                "cityName": "学甲区",
                                "parentId": "710500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台南市",
                                "county": "学甲区",

                            },
                            {
                                "cityCode": "710522",
                                "cityName": "西港区",
                                "parentId": "710500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台南市",
                                "county": "西港区",

                            },
                            {
                                "cityCode": "710523",
                                "cityName": "七股区",
                                "parentId": "710500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台南市",
                                "county": "七股区",

                            },
                            {
                                "cityCode": "710524",
                                "cityName": "将军区",
                                "parentId": "710500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台南市",
                                "county": "将军区",

                            },
                            {
                                "cityCode": "710525",
                                "cityName": "北门区",
                                "parentId": "710500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台南市",
                                "county": "北门区",

                            },
                            {
                                "cityCode": "710526",
                                "cityName": "新化区",
                                "parentId": "710500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台南市",
                                "county": "新化区",

                            },
                            {
                                "cityCode": "710527",
                                "cityName": "善化区",
                                "parentId": "710500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台南市",
                                "county": "善化区",

                            },
                            {
                                "cityCode": "710528",
                                "cityName": "新市区",
                                "parentId": "710500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台南市",
                                "county": "新市区",

                            },
                            {
                                "cityCode": "710529",
                                "cityName": "安定区",
                                "parentId": "710500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台南市",
                                "county": "安定区",

                            },
                            {
                                "cityCode": "710530",
                                "cityName": "山上区",
                                "parentId": "710500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台南市",
                                "county": "山上区",

                            },
                            {
                                "cityCode": "710531",
                                "cityName": "玉井区",
                                "parentId": "710500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台南市",
                                "county": "玉井区",

                            },
                            {
                                "cityCode": "710532",
                                "cityName": "楠西区",
                                "parentId": "710500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台南市",
                                "county": "楠西区",

                            },
                            {
                                "cityCode": "710533",
                                "cityName": "南化区",
                                "parentId": "710500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台南市",
                                "county": "南化区",

                            },
                            {
                                "cityCode": "710534",
                                "cityName": "左镇区",
                                "parentId": "710500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台南市",
                                "county": "左镇区",

                            },
                            {
                                "cityCode": "710535",
                                "cityName": "仁德区",
                                "parentId": "710500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台南市",
                                "county": "仁德区",

                            },
                            {
                                "cityCode": "710536",
                                "cityName": "归仁区",
                                "parentId": "710500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台南市",
                                "county": "归仁区",

                            },
                            {
                                "cityCode": "710537",
                                "cityName": "关庙区",
                                "parentId": "710500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台南市",
                                "county": "关庙区",

                            },
                            {
                                "cityCode": "710538",
                                "cityName": "龙崎区",
                                "parentId": "710500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台南市",
                                "county": "龙崎区",

                            },
                            {
                                "cityCode": "710539",
                                "cityName": "永康区",
                                "parentId": "710500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台南市",
                                "county": "永康区",

                            }
                        ]
                    },
                    {
                        "cityCode": "710600",
                        "cityName": "新竹市",
                        "parentId": "710000",
                        "provinceType": "2",
                        "province": "台湾",
                        "city": "新竹市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "710601",
                                "cityName": "东区",
                                "parentId": "710600",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "新竹市",
                                "county": "东区",

                            },
                            {
                                "cityCode": "710602",
                                "cityName": "北区",
                                "parentId": "710600",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "新竹市",
                                "county": "北区",

                            },
                            {
                                "cityCode": "710603",
                                "cityName": "香山区",
                                "parentId": "710600",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "新竹市",
                                "county": "香山区",

                            }
                        ]
                    },
                    {
                        "cityCode": "710700",
                        "cityName": "嘉义市",
                        "parentId": "710000",
                        "provinceType": "2",
                        "province": "台湾",
                        "city": "嘉义市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "710701",
                                "cityName": "东区",
                                "parentId": "710700",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "嘉义市",
                                "county": "东区",

                            },
                            {
                                "cityCode": "710702",
                                "cityName": "西区",
                                "parentId": "710700",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "嘉义市",
                                "county": "西区",

                            }
                        ]
                    },
                    {
                        "cityCode": "710800",
                        "cityName": "新北市",
                        "parentId": "710000",
                        "provinceType": "2",
                        "province": "台湾",
                        "city": "新北市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "710801",
                                "cityName": "板桥区",
                                "parentId": "710800",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "新北市",
                                "county": "板桥区",

                            },
                            {
                                "cityCode": "710802",
                                "cityName": "三重区",
                                "parentId": "710800",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "新北市",
                                "county": "三重区",

                            },
                            {
                                "cityCode": "710803",
                                "cityName": "中和区",
                                "parentId": "710800",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "新北市",
                                "county": "中和区",

                            },
                            {
                                "cityCode": "710804",
                                "cityName": "永和区",
                                "parentId": "710800",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "新北市",
                                "county": "永和区",

                            },
                            {
                                "cityCode": "710805",
                                "cityName": "新庄区",
                                "parentId": "710800",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "新北市",
                                "county": "新庄区",

                            },
                            {
                                "cityCode": "710806",
                                "cityName": "新店区",
                                "parentId": "710800",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "新北市",
                                "county": "新店区",

                            },
                            {
                                "cityCode": "710807",
                                "cityName": "树林区",
                                "parentId": "710800",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "新北市",
                                "county": "树林区",

                            },
                            {
                                "cityCode": "710808",
                                "cityName": "莺歌区",
                                "parentId": "710800",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "新北市",
                                "county": "莺歌区",

                            },
                            {
                                "cityCode": "710809",
                                "cityName": "三峡区",
                                "parentId": "710800",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "新北市",
                                "county": "三峡区",

                            },
                            {
                                "cityCode": "710810",
                                "cityName": "淡水区",
                                "parentId": "710800",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "新北市",
                                "county": "淡水区",

                            },
                            {
                                "cityCode": "710811",
                                "cityName": "汐止区",
                                "parentId": "710800",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "新北市",
                                "county": "汐止区",

                            },
                            {
                                "cityCode": "710812",
                                "cityName": "瑞芳区",
                                "parentId": "710800",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "新北市",
                                "county": "瑞芳区",

                            },
                            {
                                "cityCode": "710813",
                                "cityName": "土城区",
                                "parentId": "710800",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "新北市",
                                "county": "土城区",

                            },
                            {
                                "cityCode": "710814",
                                "cityName": "芦洲区",
                                "parentId": "710800",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "新北市",
                                "county": "芦洲区",

                            },
                            {
                                "cityCode": "710815",
                                "cityName": "五股区",
                                "parentId": "710800",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "新北市",
                                "county": "五股区",

                            },
                            {
                                "cityCode": "710816",
                                "cityName": "泰山区",
                                "parentId": "710800",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "新北市",
                                "county": "泰山区",

                            },
                            {
                                "cityCode": "710817",
                                "cityName": "林口区",
                                "parentId": "710800",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "新北市",
                                "county": "林口区",

                            },
                            {
                                "cityCode": "710818",
                                "cityName": "深坑区",
                                "parentId": "710800",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "新北市",
                                "county": "深坑区",

                            },
                            {
                                "cityCode": "710819",
                                "cityName": "石碇区",
                                "parentId": "710800",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "新北市",
                                "county": "石碇区",

                            },
                            {
                                "cityCode": "710820",
                                "cityName": "坪林区",
                                "parentId": "710800",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "新北市",
                                "county": "坪林区",

                            },
                            {
                                "cityCode": "710821",
                                "cityName": "三芝区",
                                "parentId": "710800",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "新北市",
                                "county": "三芝区",

                            },
                            {
                                "cityCode": "710822",
                                "cityName": "石门区",
                                "parentId": "710800",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "新北市",
                                "county": "石门区",

                            },
                            {
                                "cityCode": "710823",
                                "cityName": "八里区",
                                "parentId": "710800",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "新北市",
                                "county": "八里区",

                            },
                            {
                                "cityCode": "710824",
                                "cityName": "平溪区",
                                "parentId": "710800",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "新北市",
                                "county": "平溪区",

                            },
                            {
                                "cityCode": "710825",
                                "cityName": "双溪区",
                                "parentId": "710800",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "新北市",
                                "county": "双溪区",

                            },
                            {
                                "cityCode": "710826",
                                "cityName": "贡寮区",
                                "parentId": "710800",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "新北市",
                                "county": "贡寮区",

                            },
                            {
                                "cityCode": "710827",
                                "cityName": "金山区",
                                "parentId": "710800",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "新北市",
                                "county": "金山区",

                            },
                            {
                                "cityCode": "710828",
                                "cityName": "万里区",
                                "parentId": "710800",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "新北市",
                                "county": "万里区",

                            },
                            {
                                "cityCode": "710829",
                                "cityName": "乌来区",
                                "parentId": "710800",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "新北市",
                                "county": "乌来区",

                            }
                        ]
                    },
                    {
                        "cityCode": "712200",
                        "cityName": "宜兰县",
                        "parentId": "710000",
                        "provinceType": "2",
                        "province": "台湾",
                        "city": "宜兰县",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "712201",
                                "cityName": "宜兰市",
                                "parentId": "712200",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "宜兰县",
                                "county": "宜兰市",

                            },
                            {
                                "cityCode": "712221",
                                "cityName": "罗东镇",
                                "parentId": "712200",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "宜兰县",
                                "county": "罗东镇",

                            },
                            {
                                "cityCode": "712222",
                                "cityName": "苏澳镇",
                                "parentId": "712200",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "宜兰县",
                                "county": "苏澳镇",

                            },
                            {
                                "cityCode": "712223",
                                "cityName": "头城镇",
                                "parentId": "712200",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "宜兰县",
                                "county": "头城镇",

                            },
                            {
                                "cityCode": "712224",
                                "cityName": "礁溪乡",
                                "parentId": "712200",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "宜兰县",
                                "county": "礁溪乡",

                            },
                            {
                                "cityCode": "712225",
                                "cityName": "壮围乡",
                                "parentId": "712200",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "宜兰县",
                                "county": "壮围乡",

                            },
                            {
                                "cityCode": "712226",
                                "cityName": "员山乡",
                                "parentId": "712200",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "宜兰县",
                                "county": "员山乡",

                            },
                            {
                                "cityCode": "712227",
                                "cityName": "冬山乡",
                                "parentId": "712200",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "宜兰县",
                                "county": "冬山乡",

                            },
                            {
                                "cityCode": "712228",
                                "cityName": "五结乡",
                                "parentId": "712200",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "宜兰县",
                                "county": "五结乡",

                            },
                            {
                                "cityCode": "712229",
                                "cityName": "三星乡",
                                "parentId": "712200",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "宜兰县",
                                "county": "三星乡",

                            },
                            {
                                "cityCode": "712230",
                                "cityName": "大同乡",
                                "parentId": "712200",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "宜兰县",
                                "county": "大同乡",

                            },
                            {
                                "cityCode": "712231",
                                "cityName": "南澳乡",
                                "parentId": "712200",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "宜兰县",
                                "county": "南澳乡",

                            }
                        ]
                    },
                    {
                        "cityCode": "712300",
                        "cityName": "桃园市",
                        "parentId": "710000",
                        "provinceType": "2",
                        "province": "台湾",
                        "city": "桃园市",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "712301",
                                "cityName": "桃园市",
                                "parentId": "712300",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "桃园市",
                                "county": "桃园市",

                            },
                            {
                                "cityCode": "712302",
                                "cityName": "中坜市",
                                "parentId": "712300",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "桃园市",
                                "county": "中坜市",

                            },
                            {
                                "cityCode": "712303",
                                "cityName": "平镇市",
                                "parentId": "712300",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "桃园市",
                                "county": "平镇市",

                            },
                            {
                                "cityCode": "712304",
                                "cityName": "八德市",
                                "parentId": "712300",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "桃园市",
                                "county": "八德市",

                            },
                            {
                                "cityCode": "712305",
                                "cityName": "杨梅市",
                                "parentId": "712300",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "桃园市",
                                "county": "杨梅市",

                            },
                            {
                                "cityCode": "712306",
                                "cityName": "芦竹市",
                                "parentId": "712300",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "桃园市",
                                "county": "芦竹市",

                            },
                            {
                                "cityCode": "712321",
                                "cityName": "大溪镇",
                                "parentId": "712300",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "桃园市",
                                "county": "大溪镇",

                            },
                            {
                                "cityCode": "712324",
                                "cityName": "大园乡",
                                "parentId": "712300",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "桃园市",
                                "county": "大园乡",

                            },
                            {
                                "cityCode": "712325",
                                "cityName": "龟山乡",
                                "parentId": "712300",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "桃园市",
                                "county": "龟山乡",

                            },
                            {
                                "cityCode": "712327",
                                "cityName": "龙潭乡",
                                "parentId": "712300",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "桃园市",
                                "county": "龙潭乡",

                            },
                            {
                                "cityCode": "712329",
                                "cityName": "新屋乡",
                                "parentId": "712300",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "桃园市",
                                "county": "新屋乡",

                            },
                            {
                                "cityCode": "712330",
                                "cityName": "观音乡",
                                "parentId": "712300",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "桃园市",
                                "county": "观音乡",

                            },
                            {
                                "cityCode": "712331",
                                "cityName": "复兴乡",
                                "parentId": "712300",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "桃园市",
                                "county": "复兴乡",

                            }
                        ]
                    },
                    {
                        "cityCode": "712400",
                        "cityName": "新竹县",
                        "parentId": "710000",
                        "provinceType": "2",
                        "province": "台湾",
                        "city": "新竹县",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "712401",
                                "cityName": "竹北市",
                                "parentId": "712400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "新竹县",
                                "county": "竹北市",

                            },
                            {
                                "cityCode": "712421",
                                "cityName": "竹东镇",
                                "parentId": "712400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "新竹县",
                                "county": "竹东镇",

                            },
                            {
                                "cityCode": "712422",
                                "cityName": "新埔镇",
                                "parentId": "712400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "新竹县",
                                "county": "新埔镇",

                            },
                            {
                                "cityCode": "712423",
                                "cityName": "关西镇",
                                "parentId": "712400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "新竹县",
                                "county": "关西镇",

                            },
                            {
                                "cityCode": "712424",
                                "cityName": "湖口乡",
                                "parentId": "712400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "新竹县",
                                "county": "湖口乡",

                            },
                            {
                                "cityCode": "712425",
                                "cityName": "新丰乡",
                                "parentId": "712400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "新竹县",
                                "county": "新丰乡",

                            },
                            {
                                "cityCode": "712426",
                                "cityName": "芎林乡",
                                "parentId": "712400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "新竹县",
                                "county": "芎林乡",

                            },
                            {
                                "cityCode": "712427",
                                "cityName": "横山乡",
                                "parentId": "712400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "新竹县",
                                "county": "横山乡",

                            },
                            {
                                "cityCode": "712428",
                                "cityName": "北埔乡",
                                "parentId": "712400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "新竹县",
                                "county": "北埔乡",

                            },
                            {
                                "cityCode": "712429",
                                "cityName": "宝山乡",
                                "parentId": "712400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "新竹县",
                                "county": "宝山乡",

                            },
                            {
                                "cityCode": "712430",
                                "cityName": "峨眉乡",
                                "parentId": "712400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "新竹县",
                                "county": "峨眉乡",

                            },
                            {
                                "cityCode": "712431",
                                "cityName": "尖石乡",
                                "parentId": "712400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "新竹县",
                                "county": "尖石乡",

                            },
                            {
                                "cityCode": "712432",
                                "cityName": "五峰乡",
                                "parentId": "712400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "新竹县",
                                "county": "五峰乡",

                            }
                        ]
                    },
                    {
                        "cityCode": "712500",
                        "cityName": "苗栗县",
                        "parentId": "710000",
                        "provinceType": "2",
                        "province": "台湾",
                        "city": "苗栗县",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "712501",
                                "cityName": "苗栗市",
                                "parentId": "712500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "苗栗县",
                                "county": "苗栗市",

                            },
                            {
                                "cityCode": "712521",
                                "cityName": "苑里镇",
                                "parentId": "712500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "苗栗县",
                                "county": "苑里镇",

                            },
                            {
                                "cityCode": "712522",
                                "cityName": "通霄镇",
                                "parentId": "712500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "苗栗县",
                                "county": "通霄镇",

                            },
                            {
                                "cityCode": "712523",
                                "cityName": "竹南镇",
                                "parentId": "712500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "苗栗县",
                                "county": "竹南镇",

                            },
                            {
                                "cityCode": "712524",
                                "cityName": "头份市",
                                "parentId": "712500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "苗栗县",
                                "county": "头份市",

                            },
                            {
                                "cityCode": "712525",
                                "cityName": "后龙镇",
                                "parentId": "712500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "苗栗县",
                                "county": "后龙镇",

                            },
                            {
                                "cityCode": "712526",
                                "cityName": "卓兰镇",
                                "parentId": "712500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "苗栗县",
                                "county": "卓兰镇",

                            },
                            {
                                "cityCode": "712527",
                                "cityName": "大湖乡",
                                "parentId": "712500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "苗栗县",
                                "county": "大湖乡",

                            },
                            {
                                "cityCode": "712528",
                                "cityName": "公馆乡",
                                "parentId": "712500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "苗栗县",
                                "county": "公馆乡",

                            },
                            {
                                "cityCode": "712529",
                                "cityName": "铜锣乡",
                                "parentId": "712500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "苗栗县",
                                "county": "铜锣乡",

                            },
                            {
                                "cityCode": "712530",
                                "cityName": "南庄乡",
                                "parentId": "712500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "苗栗县",
                                "county": "南庄乡",

                            },
                            {
                                "cityCode": "712531",
                                "cityName": "头屋乡",
                                "parentId": "712500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "苗栗县",
                                "county": "头屋乡",

                            },
                            {
                                "cityCode": "712532",
                                "cityName": "三义乡",
                                "parentId": "712500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "苗栗县",
                                "county": "三义乡",

                            },
                            {
                                "cityCode": "712533",
                                "cityName": "西湖乡",
                                "parentId": "712500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "苗栗县",
                                "county": "西湖乡",

                            },
                            {
                                "cityCode": "712534",
                                "cityName": "造桥乡",
                                "parentId": "712500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "苗栗县",
                                "county": "造桥乡",

                            },
                            {
                                "cityCode": "712535",
                                "cityName": "三湾乡",
                                "parentId": "712500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "苗栗县",
                                "county": "三湾乡",

                            },
                            {
                                "cityCode": "712536",
                                "cityName": "狮潭乡",
                                "parentId": "712500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "苗栗县",
                                "county": "狮潭乡",

                            },
                            {
                                "cityCode": "712537",
                                "cityName": "泰安乡",
                                "parentId": "712500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "苗栗县",
                                "county": "泰安乡",

                            }
                        ]
                    },
                    {
                        "cityCode": "712700",
                        "cityName": "彰化县",
                        "parentId": "710000",
                        "provinceType": "2",
                        "province": "台湾",
                        "city": "彰化县",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "712701",
                                "cityName": "彰化市",
                                "parentId": "712700",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "彰化县",
                                "county": "彰化市",

                            },
                            {
                                "cityCode": "712721",
                                "cityName": "鹿港镇",
                                "parentId": "712700",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "彰化县",
                                "county": "鹿港镇",

                            },
                            {
                                "cityCode": "712722",
                                "cityName": "和美镇",
                                "parentId": "712700",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "彰化县",
                                "county": "和美镇",

                            },
                            {
                                "cityCode": "712723",
                                "cityName": "线西乡",
                                "parentId": "712700",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "彰化县",
                                "county": "线西乡",

                            },
                            {
                                "cityCode": "712724",
                                "cityName": "伸港乡",
                                "parentId": "712700",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "彰化县",
                                "county": "伸港乡",

                            },
                            {
                                "cityCode": "712725",
                                "cityName": "福兴乡",
                                "parentId": "712700",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "彰化县",
                                "county": "福兴乡",

                            },
                            {
                                "cityCode": "712726",
                                "cityName": "秀水乡",
                                "parentId": "712700",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "彰化县",
                                "county": "秀水乡",

                            },
                            {
                                "cityCode": "712727",
                                "cityName": "花坛乡",
                                "parentId": "712700",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "彰化县",
                                "county": "花坛乡",

                            },
                            {
                                "cityCode": "712728",
                                "cityName": "芬园乡",
                                "parentId": "712700",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "彰化县",
                                "county": "芬园乡",

                            },
                            {
                                "cityCode": "712729",
                                "cityName": "员林市",
                                "parentId": "712700",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "彰化县",
                                "county": "员林市",

                            },
                            {
                                "cityCode": "712730",
                                "cityName": "溪湖镇",
                                "parentId": "712700",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "彰化县",
                                "county": "溪湖镇",

                            },
                            {
                                "cityCode": "712731",
                                "cityName": "田中镇",
                                "parentId": "712700",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "彰化县",
                                "county": "田中镇",

                            },
                            {
                                "cityCode": "712732",
                                "cityName": "大村乡",
                                "parentId": "712700",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "彰化县",
                                "county": "大村乡",

                            },
                            {
                                "cityCode": "712733",
                                "cityName": "埔盐乡",
                                "parentId": "712700",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "彰化县",
                                "county": "埔盐乡",

                            },
                            {
                                "cityCode": "712734",
                                "cityName": "埔心乡",
                                "parentId": "712700",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "彰化县",
                                "county": "埔心乡",

                            },
                            {
                                "cityCode": "712735",
                                "cityName": "永靖乡",
                                "parentId": "712700",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "彰化县",
                                "county": "永靖乡",

                            },
                            {
                                "cityCode": "712736",
                                "cityName": "社头乡",
                                "parentId": "712700",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "彰化县",
                                "county": "社头乡",

                            },
                            {
                                "cityCode": "712737",
                                "cityName": "二水乡",
                                "parentId": "712700",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "彰化县",
                                "county": "二水乡",

                            },
                            {
                                "cityCode": "712738",
                                "cityName": "北斗镇",
                                "parentId": "712700",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "彰化县",
                                "county": "北斗镇",

                            },
                            {
                                "cityCode": "712739",
                                "cityName": "二林镇",
                                "parentId": "712700",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "彰化县",
                                "county": "二林镇",

                            },
                            {
                                "cityCode": "712740",
                                "cityName": "田尾乡",
                                "parentId": "712700",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "彰化县",
                                "county": "田尾乡",

                            },
                            {
                                "cityCode": "712741",
                                "cityName": "埤头乡",
                                "parentId": "712700",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "彰化县",
                                "county": "埤头乡",

                            },
                            {
                                "cityCode": "712742",
                                "cityName": "芳苑乡",
                                "parentId": "712700",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "彰化县",
                                "county": "芳苑乡",

                            },
                            {
                                "cityCode": "712743",
                                "cityName": "大城乡",
                                "parentId": "712700",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "彰化县",
                                "county": "大城乡",

                            },
                            {
                                "cityCode": "712744",
                                "cityName": "竹塘乡",
                                "parentId": "712700",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "彰化县",
                                "county": "竹塘乡",

                            },
                            {
                                "cityCode": "712745",
                                "cityName": "溪州乡",
                                "parentId": "712700",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "彰化县",
                                "county": "溪州乡",

                            }
                        ]
                    },
                    {
                        "cityCode": "712800",
                        "cityName": "南投县",
                        "parentId": "710000",
                        "provinceType": "2",
                        "province": "台湾",
                        "city": "南投县",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "712801",
                                "cityName": "南投市",
                                "parentId": "712800",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "南投县",
                                "county": "南投市",

                            },
                            {
                                "cityCode": "712821",
                                "cityName": "埔里镇",
                                "parentId": "712800",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "南投县",
                                "county": "埔里镇",

                            },
                            {
                                "cityCode": "712822",
                                "cityName": "草屯镇",
                                "parentId": "712800",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "南投县",
                                "county": "草屯镇",

                            },
                            {
                                "cityCode": "712823",
                                "cityName": "竹山镇",
                                "parentId": "712800",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "南投县",
                                "county": "竹山镇",

                            },
                            {
                                "cityCode": "712824",
                                "cityName": "集集镇",
                                "parentId": "712800",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "南投县",
                                "county": "集集镇",

                            },
                            {
                                "cityCode": "712825",
                                "cityName": "名间乡",
                                "parentId": "712800",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "南投县",
                                "county": "名间乡",

                            },
                            {
                                "cityCode": "712826",
                                "cityName": "鹿谷乡",
                                "parentId": "712800",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "南投县",
                                "county": "鹿谷乡",

                            },
                            {
                                "cityCode": "712827",
                                "cityName": "中寮乡",
                                "parentId": "712800",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "南投县",
                                "county": "中寮乡",

                            },
                            {
                                "cityCode": "712828",
                                "cityName": "鱼池乡",
                                "parentId": "712800",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "南投县",
                                "county": "鱼池乡",

                            },
                            {
                                "cityCode": "712829",
                                "cityName": "国姓乡",
                                "parentId": "712800",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "南投县",
                                "county": "国姓乡",

                            },
                            {
                                "cityCode": "712830",
                                "cityName": "水里乡",
                                "parentId": "712800",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "南投县",
                                "county": "水里乡",

                            },
                            {
                                "cityCode": "712831",
                                "cityName": "信义乡",
                                "parentId": "712800",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "南投县",
                                "county": "信义乡",

                            },
                            {
                                "cityCode": "712832",
                                "cityName": "仁爱乡",
                                "parentId": "712800",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "南投县",
                                "county": "仁爱乡",

                            }
                        ]
                    },
                    {
                        "cityCode": "712900",
                        "cityName": "云林县",
                        "parentId": "710000",
                        "provinceType": "2",
                        "province": "台湾",
                        "city": "云林县",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "712901",
                                "cityName": "斗六市",
                                "parentId": "712900",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "云林县",
                                "county": "斗六市",

                            },
                            {
                                "cityCode": "712921",
                                "cityName": "斗南镇",
                                "parentId": "712900",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "云林县",
                                "county": "斗南镇",

                            },
                            {
                                "cityCode": "712922",
                                "cityName": "虎尾镇",
                                "parentId": "712900",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "云林县",
                                "county": "虎尾镇",

                            },
                            {
                                "cityCode": "712923",
                                "cityName": "西螺镇",
                                "parentId": "712900",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "云林县",
                                "county": "西螺镇",

                            },
                            {
                                "cityCode": "712924",
                                "cityName": "土库镇",
                                "parentId": "712900",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "云林县",
                                "county": "土库镇",

                            },
                            {
                                "cityCode": "712925",
                                "cityName": "北港镇",
                                "parentId": "712900",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "云林县",
                                "county": "北港镇",

                            },
                            {
                                "cityCode": "712926",
                                "cityName": "古坑乡",
                                "parentId": "712900",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "云林县",
                                "county": "古坑乡",

                            },
                            {
                                "cityCode": "712927",
                                "cityName": "大埤乡",
                                "parentId": "712900",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "云林县",
                                "county": "大埤乡",

                            },
                            {
                                "cityCode": "712928",
                                "cityName": "莿桐乡",
                                "parentId": "712900",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "云林县",
                                "county": "莿桐乡",

                            },
                            {
                                "cityCode": "712929",
                                "cityName": "林内乡",
                                "parentId": "712900",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "云林县",
                                "county": "林内乡",

                            },
                            {
                                "cityCode": "712930",
                                "cityName": "二仑乡",
                                "parentId": "712900",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "云林县",
                                "county": "二仑乡",

                            },
                            {
                                "cityCode": "712931",
                                "cityName": "仑背乡",
                                "parentId": "712900",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "云林县",
                                "county": "仑背乡",

                            },
                            {
                                "cityCode": "712932",
                                "cityName": "麦寮乡",
                                "parentId": "712900",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "云林县",
                                "county": "麦寮乡",

                            },
                            {
                                "cityCode": "712933",
                                "cityName": "东势乡",
                                "parentId": "712900",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "云林县",
                                "county": "东势乡",

                            },
                            {
                                "cityCode": "712934",
                                "cityName": "褒忠乡",
                                "parentId": "712900",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "云林县",
                                "county": "褒忠乡",

                            },
                            {
                                "cityCode": "712935",
                                "cityName": "台西乡",
                                "parentId": "712900",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "云林县",
                                "county": "台西乡",

                            },
                            {
                                "cityCode": "712936",
                                "cityName": "元长乡",
                                "parentId": "712900",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "云林县",
                                "county": "元长乡",

                            },
                            {
                                "cityCode": "712937",
                                "cityName": "四湖乡",
                                "parentId": "712900",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "云林县",
                                "county": "四湖乡",

                            },
                            {
                                "cityCode": "712938",
                                "cityName": "口湖乡",
                                "parentId": "712900",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "云林县",
                                "county": "口湖乡",

                            },
                            {
                                "cityCode": "712939",
                                "cityName": "水林乡",
                                "parentId": "712900",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "云林县",
                                "county": "水林乡",

                            }
                        ]
                    },
                    {
                        "cityCode": "713000",
                        "cityName": "嘉义县",
                        "parentId": "710000",
                        "provinceType": "2",
                        "province": "台湾",
                        "city": "嘉义县",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "713001",
                                "cityName": "太保市",
                                "parentId": "713000",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "嘉义县",
                                "county": "太保市",

                            },
                            {
                                "cityCode": "713002",
                                "cityName": "朴子市",
                                "parentId": "713000",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "嘉义县",
                                "county": "朴子市",

                            },
                            {
                                "cityCode": "713023",
                                "cityName": "布袋镇",
                                "parentId": "713000",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "嘉义县",
                                "county": "布袋镇",

                            },
                            {
                                "cityCode": "713024",
                                "cityName": "大林镇",
                                "parentId": "713000",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "嘉义县",
                                "county": "大林镇",

                            },
                            {
                                "cityCode": "713025",
                                "cityName": "民雄乡",
                                "parentId": "713000",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "嘉义县",
                                "county": "民雄乡",

                            },
                            {
                                "cityCode": "713026",
                                "cityName": "溪口乡",
                                "parentId": "713000",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "嘉义县",
                                "county": "溪口乡",

                            },
                            {
                                "cityCode": "713027",
                                "cityName": "新港乡",
                                "parentId": "713000",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "嘉义县",
                                "county": "新港乡",

                            },
                            {
                                "cityCode": "713028",
                                "cityName": "六脚乡",
                                "parentId": "713000",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "嘉义县",
                                "county": "六脚乡",

                            },
                            {
                                "cityCode": "713029",
                                "cityName": "东石乡",
                                "parentId": "713000",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "嘉义县",
                                "county": "东石乡",

                            },
                            {
                                "cityCode": "713030",
                                "cityName": "义竹乡",
                                "parentId": "713000",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "嘉义县",
                                "county": "义竹乡",

                            },
                            {
                                "cityCode": "713031",
                                "cityName": "鹿草乡",
                                "parentId": "713000",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "嘉义县",
                                "county": "鹿草乡",

                            },
                            {
                                "cityCode": "713032",
                                "cityName": "水上乡",
                                "parentId": "713000",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "嘉义县",
                                "county": "水上乡",

                            },
                            {
                                "cityCode": "713033",
                                "cityName": "中埔乡",
                                "parentId": "713000",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "嘉义县",
                                "county": "中埔乡",

                            },
                            {
                                "cityCode": "713034",
                                "cityName": "竹崎乡",
                                "parentId": "713000",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "嘉义县",
                                "county": "竹崎乡",

                            },
                            {
                                "cityCode": "713035",
                                "cityName": "梅山乡",
                                "parentId": "713000",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "嘉义县",
                                "county": "梅山乡",

                            },
                            {
                                "cityCode": "713036",
                                "cityName": "番路乡",
                                "parentId": "713000",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "嘉义县",
                                "county": "番路乡",

                            },
                            {
                                "cityCode": "713037",
                                "cityName": "大埔乡",
                                "parentId": "713000",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "嘉义县",
                                "county": "大埔乡",

                            },
                            {
                                "cityCode": "713038",
                                "cityName": "阿里山乡",
                                "parentId": "713000",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "嘉义县",
                                "county": "阿里山乡",

                            }
                        ]
                    },
                    {
                        "cityCode": "713300",
                        "cityName": "屏东县",
                        "parentId": "710000",
                        "provinceType": "2",
                        "province": "台湾",
                        "city": "屏东县",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "713301",
                                "cityName": "屏东市",
                                "parentId": "713300",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "屏东县",
                                "county": "屏东市",

                            },
                            {
                                "cityCode": "713321",
                                "cityName": "潮州镇",
                                "parentId": "713300",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "屏东县",
                                "county": "潮州镇",

                            },
                            {
                                "cityCode": "713322",
                                "cityName": "东港镇",
                                "parentId": "713300",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "屏东县",
                                "county": "东港镇",

                            },
                            {
                                "cityCode": "713323",
                                "cityName": "恒春镇",
                                "parentId": "713300",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "屏东县",
                                "county": "恒春镇",

                            },
                            {
                                "cityCode": "713324",
                                "cityName": "万丹乡",
                                "parentId": "713300",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "屏东县",
                                "county": "万丹乡",

                            },
                            {
                                "cityCode": "713325",
                                "cityName": "长治乡",
                                "parentId": "713300",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "屏东县",
                                "county": "长治乡",

                            },
                            {
                                "cityCode": "713326",
                                "cityName": "麟洛乡",
                                "parentId": "713300",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "屏东县",
                                "county": "麟洛乡",

                            },
                            {
                                "cityCode": "713327",
                                "cityName": "九如乡",
                                "parentId": "713300",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "屏东县",
                                "county": "九如乡",

                            },
                            {
                                "cityCode": "713328",
                                "cityName": "里港乡",
                                "parentId": "713300",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "屏东县",
                                "county": "里港乡",

                            },
                            {
                                "cityCode": "713329",
                                "cityName": "盐埔乡",
                                "parentId": "713300",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "屏东县",
                                "county": "盐埔乡",

                            },
                            {
                                "cityCode": "713330",
                                "cityName": "高树乡",
                                "parentId": "713300",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "屏东县",
                                "county": "高树乡",

                            },
                            {
                                "cityCode": "713331",
                                "cityName": "万峦乡",
                                "parentId": "713300",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "屏东县",
                                "county": "万峦乡",

                            },
                            {
                                "cityCode": "713332",
                                "cityName": "内埔乡",
                                "parentId": "713300",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "屏东县",
                                "county": "内埔乡",

                            },
                            {
                                "cityCode": "713333",
                                "cityName": "竹田乡",
                                "parentId": "713300",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "屏东县",
                                "county": "竹田乡",

                            },
                            {
                                "cityCode": "713334",
                                "cityName": "新埤乡",
                                "parentId": "713300",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "屏东县",
                                "county": "新埤乡",

                            },
                            {
                                "cityCode": "713335",
                                "cityName": "枋寮乡",
                                "parentId": "713300",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "屏东县",
                                "county": "枋寮乡",

                            },
                            {
                                "cityCode": "713336",
                                "cityName": "新园乡",
                                "parentId": "713300",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "屏东县",
                                "county": "新园乡",

                            },
                            {
                                "cityCode": "713337",
                                "cityName": "崁顶乡",
                                "parentId": "713300",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "屏东县",
                                "county": "崁顶乡",

                            },
                            {
                                "cityCode": "713338",
                                "cityName": "林边乡",
                                "parentId": "713300",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "屏东县",
                                "county": "林边乡",

                            },
                            {
                                "cityCode": "713339",
                                "cityName": "南州乡",
                                "parentId": "713300",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "屏东县",
                                "county": "南州乡",

                            },
                            {
                                "cityCode": "713340",
                                "cityName": "佳冬乡",
                                "parentId": "713300",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "屏东县",
                                "county": "佳冬乡",

                            },
                            {
                                "cityCode": "713341",
                                "cityName": "琉球乡",
                                "parentId": "713300",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "屏东县",
                                "county": "琉球乡",

                            },
                            {
                                "cityCode": "713342",
                                "cityName": "车城乡",
                                "parentId": "713300",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "屏东县",
                                "county": "车城乡",

                            },
                            {
                                "cityCode": "713343",
                                "cityName": "满州乡",
                                "parentId": "713300",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "屏东县",
                                "county": "满州乡",

                            },
                            {
                                "cityCode": "713344",
                                "cityName": "枋山乡",
                                "parentId": "713300",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "屏东县",
                                "county": "枋山乡",

                            },
                            {
                                "cityCode": "713345",
                                "cityName": "三地门乡",
                                "parentId": "713300",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "屏东县",
                                "county": "三地门乡",

                            },
                            {
                                "cityCode": "713346",
                                "cityName": "雾台乡",
                                "parentId": "713300",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "屏东县",
                                "county": "雾台乡",

                            },
                            {
                                "cityCode": "713347",
                                "cityName": "玛家乡",
                                "parentId": "713300",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "屏东县",
                                "county": "玛家乡",

                            },
                            {
                                "cityCode": "713348",
                                "cityName": "泰武乡",
                                "parentId": "713300",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "屏东县",
                                "county": "泰武乡",

                            },
                            {
                                "cityCode": "713349",
                                "cityName": "来义乡",
                                "parentId": "713300",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "屏东县",
                                "county": "来义乡",

                            },
                            {
                                "cityCode": "713350",
                                "cityName": "春日乡",
                                "parentId": "713300",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "屏东县",
                                "county": "春日乡",

                            },
                            {
                                "cityCode": "713351",
                                "cityName": "狮子乡",
                                "parentId": "713300",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "屏东县",
                                "county": "狮子乡",

                            },
                            {
                                "cityCode": "713352",
                                "cityName": "牡丹乡",
                                "parentId": "713300",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "屏东县",
                                "county": "牡丹乡",

                            }
                        ]
                    },
                    {
                        "cityCode": "713400",
                        "cityName": "台东县",
                        "parentId": "710000",
                        "provinceType": "2",
                        "province": "台湾",
                        "city": "台东县",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "713401",
                                "cityName": "台东市",
                                "parentId": "713400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台东县",
                                "county": "台东市",

                            },
                            {
                                "cityCode": "713421",
                                "cityName": "成功镇",
                                "parentId": "713400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台东县",
                                "county": "成功镇",

                            },
                            {
                                "cityCode": "713422",
                                "cityName": "关山镇",
                                "parentId": "713400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台东县",
                                "county": "关山镇",

                            },
                            {
                                "cityCode": "713423",
                                "cityName": "卑南乡",
                                "parentId": "713400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台东县",
                                "county": "卑南乡",

                            },
                            {
                                "cityCode": "713424",
                                "cityName": "鹿野乡",
                                "parentId": "713400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台东县",
                                "county": "鹿野乡",

                            },
                            {
                                "cityCode": "713425",
                                "cityName": "池上乡",
                                "parentId": "713400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台东县",
                                "county": "池上乡",

                            },
                            {
                                "cityCode": "713426",
                                "cityName": "东河乡",
                                "parentId": "713400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台东县",
                                "county": "东河乡",

                            },
                            {
                                "cityCode": "713427",
                                "cityName": "长滨乡",
                                "parentId": "713400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台东县",
                                "county": "长滨乡",

                            },
                            {
                                "cityCode": "713428",
                                "cityName": "太麻里乡",
                                "parentId": "713400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台东县",
                                "county": "太麻里乡",

                            },
                            {
                                "cityCode": "713429",
                                "cityName": "大武乡",
                                "parentId": "713400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台东县",
                                "county": "大武乡",

                            },
                            {
                                "cityCode": "713430",
                                "cityName": "绿岛乡",
                                "parentId": "713400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台东县",
                                "county": "绿岛乡",

                            },
                            {
                                "cityCode": "713431",
                                "cityName": "海端乡",
                                "parentId": "713400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台东县",
                                "county": "海端乡",

                            },
                            {
                                "cityCode": "713432",
                                "cityName": "延平乡",
                                "parentId": "713400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台东县",
                                "county": "延平乡",

                            },
                            {
                                "cityCode": "713433",
                                "cityName": "金峰乡",
                                "parentId": "713400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台东县",
                                "county": "金峰乡",

                            },
                            {
                                "cityCode": "713434",
                                "cityName": "达仁乡",
                                "parentId": "713400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台东县",
                                "county": "达仁乡",

                            },
                            {
                                "cityCode": "713435",
                                "cityName": "兰屿乡",
                                "parentId": "713400",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "台东县",
                                "county": "兰屿乡",

                            }
                        ]
                    },
                    {
                        "cityCode": "713500",
                        "cityName": "花莲县",
                        "parentId": "710000",
                        "provinceType": "2",
                        "province": "台湾",
                        "city": "花莲县",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "713501",
                                "cityName": "花莲市",
                                "parentId": "713500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "花莲县",
                                "county": "花莲市",

                            },
                            {
                                "cityCode": "713521",
                                "cityName": "凤林镇",
                                "parentId": "713500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "花莲县",
                                "county": "凤林镇",

                            },
                            {
                                "cityCode": "713522",
                                "cityName": "玉里镇",
                                "parentId": "713500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "花莲县",
                                "county": "玉里镇",

                            },
                            {
                                "cityCode": "713523",
                                "cityName": "新城乡",
                                "parentId": "713500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "花莲县",
                                "county": "新城乡",

                            },
                            {
                                "cityCode": "713524",
                                "cityName": "吉安乡",
                                "parentId": "713500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "花莲县",
                                "county": "吉安乡",

                            },
                            {
                                "cityCode": "713525",
                                "cityName": "寿丰乡",
                                "parentId": "713500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "花莲县",
                                "county": "寿丰乡",

                            },
                            {
                                "cityCode": "713526",
                                "cityName": "光复乡",
                                "parentId": "713500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "花莲县",
                                "county": "光复乡",

                            },
                            {
                                "cityCode": "713527",
                                "cityName": "丰滨乡",
                                "parentId": "713500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "花莲县",
                                "county": "丰滨乡",

                            },
                            {
                                "cityCode": "713528",
                                "cityName": "瑞穗乡",
                                "parentId": "713500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "花莲县",
                                "county": "瑞穗乡",

                            },
                            {
                                "cityCode": "713529",
                                "cityName": "富里乡",
                                "parentId": "713500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "花莲县",
                                "county": "富里乡",

                            },
                            {
                                "cityCode": "713530",
                                "cityName": "秀林乡",
                                "parentId": "713500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "花莲县",
                                "county": "秀林乡",

                            },
                            {
                                "cityCode": "713531",
                                "cityName": "万荣乡",
                                "parentId": "713500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "花莲县",
                                "county": "万荣乡",

                            },
                            {
                                "cityCode": "713532",
                                "cityName": "卓溪乡",
                                "parentId": "713500",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "花莲县",
                                "county": "卓溪乡",

                            }
                        ]
                    },
                    {
                        "cityCode": "713600",
                        "cityName": "澎湖县",
                        "parentId": "710000",
                        "provinceType": "2",
                        "province": "台湾",
                        "city": "澎湖县",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "713601",
                                "cityName": "马公市",
                                "parentId": "713600",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "澎湖县",
                                "county": "马公市",

                            },
                            {
                                "cityCode": "713621",
                                "cityName": "湖西乡",
                                "parentId": "713600",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "澎湖县",
                                "county": "湖西乡",

                            },
                            {
                                "cityCode": "713622",
                                "cityName": "白沙乡",
                                "parentId": "713600",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "澎湖县",
                                "county": "白沙乡",

                            },
                            {
                                "cityCode": "713623",
                                "cityName": "西屿乡",
                                "parentId": "713600",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "澎湖县",
                                "county": "西屿乡",

                            },
                            {
                                "cityCode": "713624",
                                "cityName": "望安乡",
                                "parentId": "713600",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "澎湖县",
                                "county": "望安乡",

                            },
                            {
                                "cityCode": "713625",
                                "cityName": "七美乡",
                                "parentId": "713600",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "澎湖县",
                                "county": "七美乡",

                            }
                        ]
                    },
                    {
                        "cityCode": "713700",
                        "cityName": "金门县",
                        "parentId": "710000",
                        "provinceType": "2",
                        "province": "台湾",
                        "city": "金门县",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "713701",
                                "cityName": "金城镇",
                                "parentId": "713700",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "金门县",
                                "county": "金城镇",

                            },
                            {
                                "cityCode": "713702",
                                "cityName": "金湖镇",
                                "parentId": "713700",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "金门县",
                                "county": "金湖镇",

                            },
                            {
                                "cityCode": "713703",
                                "cityName": "金沙镇",
                                "parentId": "713700",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "金门县",
                                "county": "金沙镇",

                            },
                            {
                                "cityCode": "713704",
                                "cityName": "金宁乡",
                                "parentId": "713700",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "金门县",
                                "county": "金宁乡",

                            },
                            {
                                "cityCode": "713705",
                                "cityName": "烈屿乡",
                                "parentId": "713700",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "金门县",
                                "county": "烈屿乡",

                            },
                            {
                                "cityCode": "713706",
                                "cityName": "乌丘乡",
                                "parentId": "713700",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "金门县",
                                "county": "乌丘乡",

                            }
                        ]
                    },
                    {
                        "cityCode": "713800",
                        "cityName": "连江县",
                        "parentId": "710000",
                        "provinceType": "2",
                        "province": "台湾",
                        "city": "连江县",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "713801",
                                "cityName": "南竿乡",
                                "parentId": "713800",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "连江县",
                                "county": "南竿乡",

                            },
                            {
                                "cityCode": "713802",
                                "cityName": "北竿乡",
                                "parentId": "713800",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "连江县",
                                "county": "北竿乡",

                            },
                            {
                                "cityCode": "713803",
                                "cityName": "莒光乡",
                                "parentId": "713800",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "连江县",
                                "county": "莒光乡",

                            },
                            {
                                "cityCode": "713804",
                                "cityName": "东引乡",
                                "parentId": "713800",
                                "provinceType": "3",
                                "province": "台湾",
                                "city": "连江县",
                                "county": "东引乡",

                            }
                        ]
                    }
                ]
            },
            {
                "cityCode": "810000",
                "cityName": "香港特别行政区",
                "parentId": "100000",
                "provinceType": "-1",
                "province": "香港",
                "city": "",
                "county": "",
                "counties": [
                    {
                        "cityCode": "810100",
                        "cityName": "香港岛",
                        "parentId": "810000",
                        "provinceType": "2",
                        "province": "香港特别行政区",
                        "city": "香港岛",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "810101",
                                "cityName": "中西区",
                                "parentId": "810100",
                                "provinceType": "3",
                                "province": "香港特别行政区",
                                "city": "香港岛",
                                "county": "中西区",

                            },
                            {
                                "cityCode": "810102",
                                "cityName": "湾仔区",
                                "parentId": "810100",
                                "provinceType": "3",
                                "province": "香港特别行政区",
                                "city": "香港岛",
                                "county": "湾仔区",

                            },
                            {
                                "cityCode": "810103",
                                "cityName": "东区",
                                "parentId": "810100",
                                "provinceType": "3",
                                "province": "香港特别行政区",
                                "city": "香港岛",
                                "county": "东区",

                            },
                            {
                                "cityCode": "810104",
                                "cityName": "南区",
                                "parentId": "810100",
                                "provinceType": "3",
                                "province": "香港特别行政区",
                                "city": "香港岛",
                                "county": "南区",

                            }
                        ]
                    },
                    {
                        "cityCode": "810200",
                        "cityName": "九龙",
                        "parentId": "810000",
                        "provinceType": "2",
                        "province": "香港特别行政区",
                        "city": "九龙",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "810201",
                                "cityName": "油尖旺区",
                                "parentId": "810200",
                                "provinceType": "3",
                                "province": "香港特别行政区",
                                "city": "九龙",
                                "county": "油尖旺区",

                            },
                            {
                                "cityCode": "810202",
                                "cityName": "深水埗区",
                                "parentId": "810200",
                                "provinceType": "3",
                                "province": "香港特别行政区",
                                "city": "九龙",
                                "county": "深水埗区",

                            },
                            {
                                "cityCode": "810203",
                                "cityName": "九龙城区",
                                "parentId": "810200",
                                "provinceType": "3",
                                "province": "香港特别行政区",
                                "city": "九龙",
                                "county": "九龙城区",

                            },
                            {
                                "cityCode": "810204",
                                "cityName": "黄大仙区",
                                "parentId": "810200",
                                "provinceType": "3",
                                "province": "香港特别行政区",
                                "city": "九龙",
                                "county": "黄大仙区",

                            },
                            {
                                "cityCode": "810205",
                                "cityName": "观塘区",
                                "parentId": "810200",
                                "provinceType": "3",
                                "province": "香港特别行政区",
                                "city": "九龙",
                                "county": "观塘区",

                            }
                        ]
                    },
                    {
                        "cityCode": "810300",
                        "cityName": "新界",
                        "parentId": "810000",
                        "provinceType": "2",
                        "province": "香港特别行政区",
                        "city": "新界",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "810301",
                                "cityName": "荃湾区",
                                "parentId": "810300",
                                "provinceType": "3",
                                "province": "香港特别行政区",
                                "city": "新界",
                                "county": "荃湾区",

                            },
                            {
                                "cityCode": "810302",
                                "cityName": "屯门区",
                                "parentId": "810300",
                                "provinceType": "3",
                                "province": "香港特别行政区",
                                "city": "新界",
                                "county": "屯门区",

                            },
                            {
                                "cityCode": "810303",
                                "cityName": "元朗区",
                                "parentId": "810300",
                                "provinceType": "3",
                                "province": "香港特别行政区",
                                "city": "新界",
                                "county": "元朗区",

                            },
                            {
                                "cityCode": "810304",
                                "cityName": "北区",
                                "parentId": "810300",
                                "provinceType": "3",
                                "province": "香港特别行政区",
                                "city": "新界",
                                "county": "北区",

                            },
                            {
                                "cityCode": "810305",
                                "cityName": "大埔区",
                                "parentId": "810300",
                                "provinceType": "3",
                                "province": "香港特别行政区",
                                "city": "新界",
                                "county": "大埔区",

                            },
                            {
                                "cityCode": "810306",
                                "cityName": "西贡区",
                                "parentId": "810300",
                                "provinceType": "3",
                                "province": "香港特别行政区",
                                "city": "新界",
                                "county": "西贡区",

                            },
                            {
                                "cityCode": "810307",
                                "cityName": "沙田区",
                                "parentId": "810300",
                                "provinceType": "3",
                                "province": "香港特别行政区",
                                "city": "新界",
                                "county": "沙田区",

                            },
                            {
                                "cityCode": "810308",
                                "cityName": "葵青区",
                                "parentId": "810300",
                                "provinceType": "3",
                                "province": "香港特别行政区",
                                "city": "新界",
                                "county": "葵青区",

                            },
                            {
                                "cityCode": "810309",
                                "cityName": "离岛区",
                                "parentId": "810300",
                                "provinceType": "3",
                                "province": "香港特别行政区",
                                "city": "新界",
                                "county": "离岛区",

                            }
                        ]
                    }
                ]
            },
            {
                "cityCode": "820000",
                "cityName": "澳门特别行政区",
                "parentId": "100000",
                "provinceType": "-1",
                "province": "澳门",
                "city": "",
                "county": "",
                "counties": [
                    {
                        "cityCode": "820100",
                        "cityName": "澳门半岛",
                        "parentId": "820000",
                        "provinceType": "2",
                        "province": "澳门特别行政区",
                        "city": "澳门半岛",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "820101",
                                "cityName": "花地玛堂区",
                                "parentId": "820100",
                                "provinceType": "3",
                                "province": "澳门特别行政区",
                                "city": "澳门半岛",
                                "county": "花地玛堂区",

                            },
                            {
                                "cityCode": "820102",
                                "cityName": "圣安多尼堂区",
                                "parentId": "820100",
                                "provinceType": "3",
                                "province": "澳门特别行政区",
                                "city": "澳门半岛",
                                "county": "圣安多尼堂区",

                            },
                            {
                                "cityCode": "820103",
                                "cityName": "大堂区",
                                "parentId": "820100",
                                "provinceType": "3",
                                "province": "澳门特别行政区",
                                "city": "澳门半岛",
                                "county": "大堂区",

                            },
                            {
                                "cityCode": "820104",
                                "cityName": "望德堂区",
                                "parentId": "820100",
                                "provinceType": "3",
                                "province": "澳门特别行政区",
                                "city": "澳门半岛",
                                "county": "望德堂区",

                            },
                            {
                                "cityCode": "820105",
                                "cityName": "风顺堂区",
                                "parentId": "820100",
                                "provinceType": "3",
                                "province": "澳门特别行政区",
                                "city": "澳门半岛",
                                "county": "风顺堂区",

                            }
                        ]
                    },
                    {
                        "cityCode": "820200",
                        "cityName": "氹仔岛",
                        "parentId": "820000",
                        "provinceType": "2",
                        "province": "澳门特别行政区",
                        "city": "氹仔岛",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "820201",
                                "cityName": "嘉模堂区",
                                "parentId": "820200",
                                "provinceType": "3",
                                "province": "澳门特别行政区",
                                "city": "氹仔岛",
                                "county": "嘉模堂区",

                            }
                        ]
                    },
                    {
                        "cityCode": "820300",
                        "cityName": "路环岛",
                        "parentId": "820000",
                        "provinceType": "2",
                        "province": "澳门特别行政区",
                        "city": "路环岛",
                        "county": "",
                        "counties": [
                            {
                                "cityCode": "820301",
                                "cityName": "圣方济各堂区",
                                "parentId": "820300",
                                "provinceType": "3",
                                "province": "澳门特别行政区",
                                "city": "路环岛",
                                "county": "圣方济各堂区",

                            }
                        ]
                    }
                ]
            },
            {
                "cityCode": "900000",
                "cityName": "钓鱼岛",
                "parentId": "100000",
                "provinceType": "-1",
                "province": "",
                "city": "",
                "county": "",

            }
        ]
    },
    {
        "cityCode": "469033",
        "cityName": "乐东黎族自治县",
        "parentId": "493300",
        "provinceType": "3",
        "province": "海南省",
        "city": "乐东黎族自治县",
        "county": "乐东黎族自治县",

    },
    {
        "cityCode": "469034",
        "cityName": "陵水黎族自治县",
        "parentId": "493400",
        "provinceType": "3",
        "province": "海南省",
        "city": "陵水黎族自治县",
        "county": "陵水黎族自治县",

    },
    {
        "cityCode": "469035",
        "cityName": "保亭黎族苗族自治县",
        "parentId": "493500",
        "provinceType": "3",
        "province": "海南省",
        "city": "保亭黎族苗族自治县",
        "county": "保亭黎族苗族自治县",

    },
    {
        "cityCode": "469036",
        "cityName": "琼中黎族苗族自治县",
        "parentId": "493600",
        "provinceType": "3",
        "province": "海南省",
        "city": "琼中黎族苗族自治县",
        "county": "琼中黎族苗族自治县",

    },
    {
        "cityCode": "999999",
        "cityName": "全国中心",
        "parentId": "999000",
        "provinceType": "3",
        "province": "全国中心",
        "city": "全国直辖",
        "county": "全国中心",

    }
]
export default cityMap
