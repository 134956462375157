//  一件事申报中展示或填写的信息
export default {
    basicInfo: {
        preApasinfo: {
            acceptTime: '',
            address: '',
            certificateSno: '',
            applyCardnumber: '',
            applyCardtype: '',
            applyPropertiy: '',
            applyType: '',
            applyfrom: '',
            applyname: '',
            corpName: '',
            areacode: '',
            belongsystem: '',
            belongto: '',
            busMode: '',
            busModeDesc: '',
            busType: '',
            contactman: '',
            contactmanCardnumber: '',
            contactmanCardnumber2: '',
            contactmanCardtype: '',
            createTime: '',
            datastate: '',
            dataversion: '',
            deptid: '',
            deptname: '',
            exBatch: '',
            extend: '',
            flowXml: '',
            handlestate: '',
            infotype: '',
            isChecked: '',
            isManubrium: '',
            lastUpdateTime: '',
            legalman: '',
            postcode: '',
            projectname: '',
            projid: '',
            projpwd: '',
            promiseeTime: '',
            qlKind: '',
            receiveName: '',
            receiveUseid: '',
            receivetime: '',
            relBusId: '',
            servicecode: '',
            servicename: '',
            serviceversion: '',
            ssOrgcode: '',
            syncStatus: '',
            telphone: '',
            transactTime: '',
        },
        preApasinfoExt: {
            applyCardtypeCode: '',
            applyerTypeCode: '',
            belongsystem: '',
            catalogCode: '',
            contactmanCardtypeCode: '',
            createTime: '',
            dataversion: '',
            exBatch: '',
            extend: '',
            gbProjectNo: '',
            id: '',
            isChecked: '',
            isDeliveryResult: '',
            lastUpdateTime: '',
            localCatalogCode: '',
            projid: '',
            remark: '',
            resultAccess: '',
            resultCetrCode: '',
            resultCetrName: '',
            satisfaction: '',
            syncStatus: '',
            taskCode: '',
            taskHandleItem: '',
            unid: ''
        },
        preApasinfoRelation: {
            acceptTime: '',
            createTime: '',
            flowUnid: '',
            formUnid: '',
            isurging: '',
            legalDays: '',
            projid: '',
            promiseDays: '',
            servicecode: '',
            transactTime: '',
            unid: '',
            updateTime: '',
            userName: '一件事网厅收件',
            username: '一件事网厅收件',
            isJgyd: '0',  //  是否结果邮寄  默认否0
            isJgydStatus: false,  //  是否结果邮寄状态默认false
        },
        preAttrs: [

        ],
        preNode: {
            action: '',
            areacode: '',
            belongsystem: '',
            createTime: '',
            dataversion: 0,
            endTime: '',
            exBatch: '',
            extend: '',
            handerDeptid: '',
            handerDeptname: '',
            isChecked: '',
            lastUpdateTime: '',
            name: '',
            nextNodeName: '',
            nextNodeUserName: '',
            nodeName: '',
            opinion: '',
            phaseCode: '',
            phaseName: '',
            projid: '',
            remark: '',
            startTime: '',
            syncStatus: '',
            taskId: '',
            unid: ''
        },
        userName: '',
        addressee:{ //  收件区划 省市县
            sendProvince: '',
            sendCity: '',
            sendCounty: '',
            regionCode: '', //  区划编码
        },
        preExpress: {
            projid: '',
            products: '',
            takePostno: '',
            postUnitname: '',
            sendUnitname: '',
            sendUser: '',
            sendPhone: '',
            receiveName: '',
            receivePhone: '',
            receiveAddr: '',
            receivePost: '',
            remark: '',
            belongsystem: '',
            areacode: '',
            extend: '',
            createTime: '',
            syncStatus: '',
            dataversion: '',
            isChecked: '',
            exBatch: '',
            lastUpdateTime: '',
        },
        yyData: {  //  配置中心的预约
            availableTimeSelected: '',  //  预约时间段的id
            availableTimeSelectedDate: '',  //  预约时间段的日期
            availableTimeSelectedTime: '',  //  预约时间段的时间段
            numberOfPeople: '', //  预约办理的人数
            startPerson: '',    //  业务下一处理人账号
            startPersonName: '',    //  业务下一处理人名称
            isyy: '',   //  是否预约 存‘预约’两个字 否为空
            grfr: '',   //  个人还是法人（企业）
            isOverrun: '', //  是否超限预约  1是 0否
            retirementTimeSelected: '', //  退休日期
        },
        other: { //  其他参数
            swzt: '',     //  身亡一件事选择状态,  为2则业务变为预约
            sgDate: '',     //  身亡一件事身故时间
            guideId: '',  //  配置情形答案id
        },
        both: {
            BirthCode: '',  // 婴儿出生证明编号
            MomIdCode: '',  // 母亲身份证号
            MomName: '',  // 母亲姓名
        },
        powerConsNo: '',
        waterConsNo: '',
        marry: {
            dateRange: [],  //  预约日期范围
            selectedArea: '',//  选择的区划（区划后面已经+0101）
            selectTime: '',  //  选择的日期
            selectDateRange: '',  //  选择的时间段  (这个是时间段的起始时间点；  比如，时间段为9:00-11:00  则这个字段为9:00)    //  用于不同的接口存储 这个用于预约系统存储
            selectDateRange2: '', //  选择的时间段2 (这个是时间段；  比如，时间段为9:00-11:00  则这个字段为9:00-11:00)    //  用于不同的接口存储 这个用于本系统预约存储
            yyid: '',  //  预约机的预约号
            deptName: '',  //  预约机构（某某婚姻登记所）
        },
        appointment: {
            cardId: '',
            content: '',
            deptId: '',
            eamil: '',
            id: '',
            modTime: '',
            modifyId: '',
            phone: '',
            projid: '',
            remard: '',
            status: '',
            sxId: '',
            userId: '',
            userName: '',
            wsEnd: '',
            yyId: '',
            yyRq: '',
            yySjd: '',
            deptName: '',
            areaCode: '',
            areaName: '',
            formDataId: '',
        }

    },
    // 基本信息 data数据

    guide: [],
    pname: '1',
    protime: '',
    modelCode: '',
    formUrl: '',
    answerInfo: {
        areaCode: '',
        answerJson: '',
        createBy: '',
        createTime: '',
        modelId: '',
        onethingUnid: '',
        sceneUnid: '',
        serviceUnid: '',
        modelunid: '',  //  modelunid
        serviceNameArray: '',
        tenantId: '',
        unid: '',
        updateBy: '',
        updateTime: '',
    },
    oneThing: {
        areaCode: '',
        enableFlag: '',
        enableFlag_dictText: '',
        hangyeType: '',
        hangyeType_dictText: '',
        modelClass: '',
        modelClassName: '',
        modelClass_dictText: '',
        modelCode: '',
        modelName: '',
        unid: '',
        version: '',
        handlingConditions: ''
    },

    formJson: {
        fullFormData: '',
        applyCardnumber: '',
        createBy: '',
        createTime: '',
        formData: '',
        formId: '',
        id: '',
        projid: '',
        serviceCode: '',
        sysOrgCode: '',
        updateBy: '',
        updateTime: ''
    },
    selectServiceCode: '',   //  情形选择的事项
}