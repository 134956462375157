//  单事项申报中展示或填写的信息
const form = {
    basicInfo: {
        preApasinfo: {
            acceptTime: '',
            address: '',
            certificateSno: '',
            corpName: '',
            applyCardnumber: '',
            applyCardtype: '',
            applyPropertiy: '',
            applyType: '',
            applyfrom: '',
            applyname: '',
            areacode: '',
            belongsystem: '',
            belongto: '',
            busMode: '',
            busModeDesc: '',
            busType: '',
            contactman: '',
            contactmanCardnumber: '',
            contactmanCardtype: '',
            createTime: '',
            datastate: '',
            dataversion: '',
            deptid: '',
            deptname: '',
            exBatch: '',
            extend: '',
            flowXml: '',
            handlestate: '',
            infotype: '',
            isChecked: '',
            isManubrium: '',
            lastUpdateTime: '',
            legalman: '',
            postcode: '',
            projectname: '',
            projid: '',
            projpwd: '',
            promiseeTime: '',
            qlKind: '',
            receiveName: '',
            receiveUseid: '',
            receivetime: '',
            relBusId: '',
            servicecode: '',
            servicename: '',
            serviceversion: '',
            ssOrgcode: '',
            syncStatus: '',
            telphone: '',
            transactTime: '',
            userType: ''
        },
        preApasinfoExt: {
            applyCardtypeCode: '',
            applyerTypeCode: '',
            belongsystem: '',
            catalogCode: '',
            contactmanCardtypeCode: '',
            createTime: '',
            dataversion: '',
            exBatch: '',
            extend: '',
            gbProjectNo: '',
            id: '',
            isChecked: '',
            isDeliveryResult: '',
            lastUpdateTime: '',
            localCatalogCode: '',
            projid: '',
            remark: '',
            resultAccess: '',
            resultCetrCode: '',
            resultCetrName: '',
            satisfaction: '',
            syncStatus: '',
            taskCode: '',
            taskHandleItem: '',
            unid: ''
        },
        preApasinfoRelation: {
            acceptTime: '',
            createTime: '',
            flowUnid: '',
            formUnid: '',
            isurging: '',
            legalDays: '',
            projid: '',
            promiseDays: '',
            servicecode: '',
            transactTime: '',
            unid: '',
            updateTime: '',
            sfmptg: '',
            isJgyd: '0',  //  是否结果邮寄  默认否0
            isJgydStatus: false,  //  是否结果邮寄状态默认false
        },
        preAttrs: [

        ],
        preNode: {
            action: '',
            areacode: '',
            belongsystem: '',
            createTime: '',
            dataversion: 0,
            endTime: '',
            exBatch: '',
            extend: '',
            handerDeptid: '',
            handerDeptname: '',
            isChecked: '',
            lastUpdateTime: '',
            name: '',
            nextNodeName: '',
            nextNodeUserName: '',
            nodeName: '',
            opinion: '',
            phaseCode: '',
            phaseName: '',
            projid: '',
            remark: '',
            startTime: '',
            syncStatus: '',
            taskId: '',
            unid: ''
        }, 
        userName: '',
        addressee:{ //  收件区划 省市县
            sendProvince: '',
            sendCity: '',
            sendCounty: '',
            regionCode: '', //  区划编码
            areaCode: '',
        },
        preExpress: {
            projid: '',
            products: '',
            takePostno: '',
            postUnitname: '',
            sendUnitname: '',
            sendUser: '',
            sendPhone: '',
            receiveName: '',
            receivePhone: '',
            receiveAddr: '',
            receivePost: '',
            remark: '',
            belongsystem: '',
            areacode: '',
            extend: '',
            createTime: '',
            syncStatus: '',
            dataversion: '',
            isChecked: '',
            exBatch: '',
            lastUpdateTime: '',
            receiveProvince: '',
            receiveCity: '',
            receiveCounty: '',
        }
    },
    // 基本信息 data数据

    // materials: [],
    pname: '',
    protime: '',
    formUrl: '',
    formJson: {
        fullFormData: '',
        applyCardnumber: '',
        createBy: '',
        createTime: '',
        formData: '',
        formId: '',
        id: '',
        projid: '',
        serviceCode: '',
        sysOrgCode: '',
        updateBy: '',
        updateTime: ''
    },
    momentMessage: '',

}
export default form





