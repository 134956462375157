import { createStore } from 'vuex'
import { getGovLoginUser, logOut, getNewsList } from '@/config/request'

const USER_INFOMATION = 'userInfomation'

export default createStore({
  state: {
    modelValue: {} as any,
    userInfomation: {} as any,
    cutDarkValue: 1,
  },
  mutations: {
    setCutDarkValue(state, value){
      state.cutDarkValue = value
    },
    setModelValue(state, value){
      state.modelValue = value
    },
    getUserInfo(state){
      if(window.localStorage.getItem(USER_INFOMATION)){
        let userMessage = {}
        try {
          userMessage = JSON.parse(window.localStorage.getItem(USER_INFOMATION) as any)
        } catch (error) {
          console.log('localStorage保存信息出错');
          window.localStorage.setItem(USER_INFOMATION, '')
        }
        console.log('window.localStorage.getItem(USER_INFOMATION)',window.localStorage.getItem(USER_INFOMATION));

        state.userInfomation = userMessage
      }else{
        state.userInfomation = {}
      }
    },
    updateUserInfo(state,userInfo){
      if(userInfo){
        state.userInfomation = userInfo.result
        window.localStorage.setItem(USER_INFOMATION, JSON.stringify(state.userInfomation))
      }else{
        console.log('退出了');

        state.userInfomation = {}
        window.localStorage.setItem(USER_INFOMATION, '')
      }

    }
  },
  actions: {
    async getNewsType(context){
      let res = await getNewsList('') as any
      let newsTypeList = res.result?.records || {}
      return newsTypeList
    },
    getLogin(context){
      context.commit('getUserInfo')
      return context.state.userInfomation
    },
    async login(context,data){    //  获取登录信息
      let userInfo = await getGovLoginUser('')
      console.log('获取的用户信息：',userInfo)
      context.commit('updateUserInfo',userInfo)
      return userInfo
    },
    async logout(context,data){
      let userInfo = await logOut('')
      console.log('退出用户信息：',userInfo)
      context.commit('updateUserInfo',null)
      return {}
    }
  },
  modules: {
  }
})
