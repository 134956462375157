import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router'
import { clearPending } from "@/config/axios"
import store from '@/store/index'
import Home from '@/views/home/index.vue'
import Page404 from '@/components/page404/index.vue'
import WalkIntoZmq from '@/views/walk-into-zmq/index.vue'
import GovernmentAffairs from '@/views/government-affairs/index.vue'
import DoThingsOnline from '@/views/do-things-online/index.vue'
import SecondBatch from '@/views/do-things-online/second-batch.vue'
import WorkGuide from '@/views/work-guide/index.vue'
import Declaration from '@/views/declaration/index.vue'
import Notic from '@/views/declaration/notic.vue'
import Reservation from '@/views/do-things-online/reservation/reservation-list.vue'
import ReservationDetail from '@/views/do-things-online/reservation/reservation-detail.vue'
import TaxCenter from "@/views/tax-center/index.vue"
import PartyBuilding from "@/views/party-building/index.vue"
import OneThing from "@/views/one-thing/index.vue"
import OneThingItemList from "@/views/one-thing/item-list/index.vue"
import onlineReportItemList from "@/views/online-report/item-list/index.vue"
import OneThingDeclaration from "@/views/one-thing/declaration/index.vue"
import OneThingSelectionSituation from "@/views/one-thing/selection-situation/index.vue"
import OneThingWorkGuide from "@/views/one-thing/work-guide/index.vue"
import OneThingNotic from "@/views/one-thing/notic/index.vue"
import UserCenter from "@/views/user-center/index.vue"
import NewsDetail from '@/views/government-affairs/news-detail.vue'
import publicity from '@/views/government-affairs/Publicity.vue'
import onlineReportIndex from '@/views/online-report/index.vue'
import onlineReport from '@/views/online-report/reportIndex.vue'
import creditPublicity from '@/views/online-report/creditPublicity.vue'
import NewsList from '@/views/government-affairs/news-list.vue'
import FriendlyNewsList from '@/views/other-page/eco-friendly/friendly-news-list.vue'
import FriendlyNewsDetail from '@/views/other-page/eco-friendly/friendly-news-detail.vue'
import LoginLoading from '@/views/login-loading/index.vue'
import Hcp from '@/views/hcp/index.vue'
import Inquire from '@/views/other-page/inquire/index.vue'
import Consult from '@/views/other-page/consult/index.vue'
import Complain from '@/views/other-page/complain/index.vue'
import OneCodeIntegration from '@/views/one-code-integration/index.vue'
import EcoFriendly from '@/views/other-page/eco-friendly/index.vue'
import EcoFriendlySecond from '@/views/other-page/eco-friendly/second.vue'
import Iframe from '@/views/other-page/iframe/index.vue'
import oneStopService from '@/views/other-page/oneStopService/index.vue'
import projectInfo from '@/views/online-report/projectInfo.vue'
import publicityIndex from '@/views/online-report/index.vue'
import VRDemo from '@/views/vr/VR.vue'
import VRList from '@/views/vr/VRList.vue'
import cloudExplorationHandle from '@/views/ykt/CloudExplorationHandle.vue'
import signature from '@/views/ykt/signature.vue'
import conjunctiveQuery from '@/views/online-report/conjunctiveQuery.vue'
import qrcodeQuery from '@/views/online-report/qrcodeQuery.vue'
import { LOGIN_URL } from '@/config/const'
import { ElMessage } from 'element-plus'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: Home
  },
  {
    path: '/walk-into-zmq',
    component: WalkIntoZmq
  },
  {
    path: '/projectInfo',
    component: projectInfo
  },
  {
    path: '/qrcodeQuery',
    component: qrcodeQuery
  },
  {
    path: '/publicityIndex',
    component: publicityIndex
  },
  {
    path: '/conjunctiveQuery',
    component: conjunctiveQuery
  },
  {
    path: '/government-affairs',
    component: GovernmentAffairs
  },
  {
    path: '/do-things-online',
    component: DoThingsOnline,
  },
  {
    path: '/oneStopService',
    component: oneStopService
  },
  {
    path: '/second-batch',
    component: SecondBatch,
  },
  {
    path: '/do-things-online/work-guide',
    component: WorkGuide
  },
  {
    path: '/do-things-online/notic',
    component: Notic
  },
  {
    path: '/do-things-online/reservation',
    component: Reservation
  },
  {
    path: '/do-things-online/reservation-detail',
    component: ReservationDetail,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/do-things-online/declaration',
    component: Declaration,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/tax-center',
    component: TaxCenter
  },
  {
    path: '/party-building',
    component: PartyBuilding
  },
  {
    path: '/news-detail',
    component: NewsDetail
  },{
    path: '/publicity',
    component: publicity
  },{
    path: '/onlineReportIndex',
    component: onlineReportIndex
  },
  {
    path: '/onlineReport',
    component: onlineReport,
    redirect:'/onlineReport/item-list',
    children:[
      {
        path: 'item-list',
        component: onlineReportItemList,
      },
      {
        path: 'selection-situation',
        component: OneThingSelectionSituation,
      },
      {
        path: 'work-guide',
        component: OneThingWorkGuide,
      },
      {
        path: 'notic',
        component: OneThingNotic,
      },
      {
        path: 'declaration',
        component: OneThingDeclaration,
        meta: {
          requiresAuth: true
        },
      },
    ]
  },{
    path: '/creditPublicity',
    component: creditPublicity
  },
  {
    path: '/news-list',
    component: NewsList
  },
  {
    path: '/friendly-news-list',
    component: FriendlyNewsList
  },
  {
    path: '/friendly-news-detail',
    component: FriendlyNewsDetail
  },
  {
    path: '/login-loading',
    component: LoginLoading,
  },
  {
    path: '/hcp',
    component: Hcp,
  },
  {
    path: '/one-code-integration',
    component: OneCodeIntegration,
  },
  {
    path: '/eco-friendly',
    component: EcoFriendly,
  },
  {
    path: '/eco-friendly/second',
    component: EcoFriendlySecond,
  },
  {
    path: '/other-page/inquire',
    component: Inquire,
  },
  {
    path: '/other-page/complain',
    component: Complain,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/other-page/consult',
    component: Consult,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/user-center',
    component: UserCenter,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/iframe',
    component: Iframe,
  },
  {
    path: '/one-thing',
    component: OneThing,
    redirect:'/one-thing/item-list',
    children:[
      {
        path: 'item-list',
        component: OneThingItemList,
      },
      {
        path: 'selection-situation',
        component: OneThingSelectionSituation,
      },
      {
        path: 'work-guide',
        component: OneThingWorkGuide,
      },
      {
        path: 'notic',
        component: OneThingNotic,
      },
      {
        path: 'declaration',
        component: OneThingDeclaration,
        meta: {
          requiresAuth: true
        },
      },
    ]
  },
  {
    path: '/:catchAll(.*)',
    component: Page404
  },
  {
    path: '/VRDemo',
    component: VRDemo
  },
  {
    path: '/cloudExplorationHandle',
    component: cloudExplorationHandle
  },
  {
    path: '/signature',
    component: signature
  },
  {
    path: '/VRList',
    component: VRList,
    meta: {
      requiresAuth: true
    },
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior () {
    return { top: 0 }
  }
})

const login = () => {
  let finalUrl = window.location.href
  window.sessionStorage.setItem('thisHref',finalUrl);
  window.location.href = LOGIN_URL + finalUrl
}

router.beforeEach((to, from, next) => {
  store.dispatch('getLogin')
  if (to.meta.requiresAuth && !store.state.userInfomation?.certNo) {
    ElMessage.warning('用户登陆信息已过期或未登陆，请先登录')
    setTimeout(()=>{
      login()
    },2000)
    return false
  }else if(store.state.userInfomation?.certNo){
    console.log('这个页面需要登录,有用户信息：', store.state.userInfomation);
  }
  //在跳转路由之前，先清除所有的请求
  clearPending()
  next()
})

export default router
