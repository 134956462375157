<template>
    <div class="liuchengtu">
        <div class="block_icon">
            <div class="lctbj_green">
                <span class="lct_icon"><img src="/images/lcicon1.png"></span><br>
                基本信息
            </div>
            <span class="jiantou_green"></span>
            <div class="lctbj_green">
                <span class="lct_icon"><img src="/images/lcicon2.png"></span><br>
                表单信息
            </div>
            <span class="jiantou_green"></span>
            <div class="lctbj_green">
                <span class="lct_icon"><img src="/images/lcicon3.png"></span><br>
                上传材料
            </div>
            <span class="jiantou_blue"></span>
            <div class="lctbj_blue">
                <span class="lct_icon"><img src="/images/lcicon4.png"></span><br>
                网上申请承诺
            </div>
            <span class="jiantou_black"></span>
            <div class="lctbj_black">
                <span class="lct_icon"><img src="/images/lcicon5.png"></span><br>
                申报成功
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
</script>
<style lang="scss" scoped>
</style>
